<div class="card cr-card-brdr no-border">
    <div class="card-body cr-card-body">
        <div class="row">
            <div class="col-md-12">
                <p class="high-section-textt cr-label">
                    {{componentName.value}}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="form-group frm-margin-btm col-md-12">
                <textarea class="form-control" [(ngModel)]="comment" placeholder="Comment" (keyup)="updateComment()" required></textarea>
            </div>
        </div>
    </div>
</div>