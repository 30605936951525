<div id="wrapper">
    <app-side-nav></app-side-nav>

    <!--  Content Wrapper -->
    <div id="content-wrapper" class="d-flex flex-column">
        <div id="content">
            <app-nav-bar></app-nav-bar>
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse"
                [fullScreen]="true">
                <p style="color: white"> Loading... </p>
                </ngx-spinner>
                <!-- <ngx-spinner></ngx-spinner> -->
                <router-outlet></router-outlet>
                <app-footer></app-footer>
        </div>
        
    </div>

</div>