import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { CategoryConfig, CustomAccordianConfig } from 'src/app/shared/components/custom-accordian/custom-accordian.component.type';
import { createNSNDropdownConfig, NSNDropdownConfig } from 'src/app/shared/components/nsn-dropdown/nsn-dropdown.component.types';
import { createNsnGridConfig, NsnGridConfig } from 'src/app/shared/components/nsn-grid/nsn-grid.component.types';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { action, activeQueue, batchCaseReview, checkbox, httpGetRequestMethod, httpPOSTRequestMethod, na, NameConstant, quickAccess } from 'src/app/shared/models/name-constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { PatientService } from 'src/app/shared/state/patient/patient.service';

@Component({
  selector: 'app-batch-review-cases',
  templateUrl: './batch-review-cases.component.html',
  styleUrls: ['./batch-review-cases.component.css']
})
export class BatchReviewCasesComponent implements OnInit {

  componentName: NameConstant = batchCaseReview;
  batchNameDropdownConfig: NSNDropdownConfig;
  batchDropDownName: string;
  coloumns: ColDef[] = [];
  nsnGridConfigSubject: BehaviorSubject<NsnGridConfig>;
  initNsnGridConfig: NsnGridConfig;

  shouldShowQAOPFormModal: boolean = false;
  shouldShowQAICFormModal: boolean = false;
  shouldShowQAATFormModal: boolean = false;
  shouldShowToolsModal: boolean = false;
  shouldShowCaseRequestFormModal: boolean = false;
  formData: any = null;

  pagenumber: number = 1;
  pageSize: number = 10;
  searchValue: string = "";
  displayNsnGrid: boolean = false;
  displayAccordian: boolean = true;

  customAccordianConfig: CustomAccordianConfig;
  customAccordianConfigSubject: BehaviorSubject<CustomAccordianConfig>;
  constructor(private patientService: PatientService,
    private commonUtil: CommonService,
    ) { }

  ngOnInit(): void {
    this.initBatchDropdownConfig();
    this.coloumns = this.buildColDef();
    this.initNsnGridConfig = createNsnGridConfig({ 
      api: "ReviewCase/BindGridData",
      apiBody: { pageNum: this.pagenumber, "pageSize": this.pageSize, "searchValue":  this.searchValue, "ReviewedStatus":  this.componentName.commandName },
      method: httpPOSTRequestMethod,
      isTotalItemKeyPresentInDataSource: true,
      totalItemKey: 'totalcount',
      responseRowKey: 'bdosid'
    });
    this.buildCustomAccordianConfig();
    this.nsnGridConfigSubject = new BehaviorSubject<NsnGridConfig>(this.initNsnGridConfig);
    this.customAccordianConfigSubject = new BehaviorSubject<CustomAccordianConfig>(this.customAccordianConfig);
    this.updateAccordian(this.customAccordianConfig);
    this.commonUtil.setTitle(this.componentName.value);

  }

  buildCustomAccordianConfig(){
   const categoryConfig : CategoryConfig = {
     httpRequestType: httpPOSTRequestMethod,
     api: "ReviewCase/BindReviewDropdown",
     body: { "ReviewedStatus": this.componentName.commandName },
   }
    this.customAccordianConfig = { 
      categoryConfig: categoryConfig,
      httpRequestType: httpPOSTRequestMethod,
      api: "ReviewCase/BindGridDataById",
      bodyAttributeMap: new Map([ 
                                ["ReviewId", { isInputProvided: false,  value: "batchCaseId" }],
                                ["ReviewedStatus", { isInputProvided: true, value: this.componentName.commandName} ],
                              ]),
      accordianCountAttrKey: "totalcount",
      accordianNameAttrKeyMap: new Map([ ["Batch", "reviewName"], [ "Batch ID", "batchCaseId"], ["Created by", "assignee"]]),
      accordianIdAttrKey: "ReviewId",
      accordianRankAttrKey: na.id,
      searchValue: this.searchValue,
    }
  }
  buildColDef(): ColDef[]{
    return [
      createColDef({ name: 'reviewName', displayName: 'Batch Name', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'client', displayName: 'Facility', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'mrn', displayName: 'MRN', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'name', displayName: 'Patient', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'dateOfSurgery', displayName: 'DoS', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: true, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'insuranceProvider', displayName: 'Insurance', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: activeQueue.id, displayName: 'Active Queue', isIdCol: false, isCheckbox: false, isDataCol: false, isDataTypeDate: false, isActiveQueue: true, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'timeCol', displayName: 'Age', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'lastNote', displayName: 'Comment', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      //createColDef({ name: quickAccess.id, displayName: 'Quick Access', isIdCol: false, isCheckbox: false, isDataCol: false, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: true, isAction: false }),
      createColDef({ name: action.id, displayName: 'Action', isIdCol: false, isCheckbox: false, isDataCol: false, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: true }),
    ]; 
  }
  onBatchNameChange(event){
    console.log("onBatchNameChange ", event);
    if(event){
      this.displayNsnGrid = true;
      this.displayAccordian = false;
      const nsnGridConfig = createNsnGridConfig({ 
        api: "ReviewCase/BindGridDataById",
        apiBody: { pageNum: this.pagenumber, "pageSize": this.pageSize, "searchValue": this.searchValue, "ReviewId": event.id, "ReviewedStatus": this.componentName.commandName },
        method: httpPOSTRequestMethod,
        isTotalItemKeyPresentInDataSource: true,
        totalItemKey: 'totalcount',
        responseRowKey: 'bdosid'
      } );
      this.updateGrid(nsnGridConfig);
    }else{
      this.displayNsnGrid = false;
      this.displayAccordian = true;
    }
  }

  updateGrid(nsnGridConfig: NsnGridConfig) {
    this.nsnGridConfigSubject.next(nsnGridConfig);
  }

  updateAccordian(customAccordianConfig: CustomAccordianConfig) {
    this.customAccordianConfigSubject.next(customAccordianConfig);
  }

  initBatchDropdownConfig(){
    this.batchNameDropdownConfig = createNSNDropdownConfig({
      sourceApi: 'ReviewCase/BindReviewDropdown',
      sourceApiBody: { "ReviewedStatus": this.componentName.commandName },
      key: 'batchCaseId',
      value: 'reviewName',
      httpRequestType: httpPOSTRequestMethod
    });
    this.batchDropDownName = "Batch Name";
  }

  showQAOPFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAOPFormModal) {
      this.shouldShowQAOPFormModal = false;
    }
    else {
      this.shouldShowQAOPFormModal = true;
    }
  }

  showQAICFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAICFormModal) {
      this.shouldShowQAICFormModal = false;
    }
    else {
      this.shouldShowQAICFormModal = true;
    }
  }

  showQAATFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAATFormModal) {
      this.shouldShowQAATFormModal = false;
    }
    else {
      this.shouldShowQAATFormModal = true;
    }
  }

  showToolsFormModal(data) {
    this.formData = data;
    this.patientService.setAndActivate([data]);
    if (this.shouldShowToolsModal) {
      this.shouldShowToolsModal = false;
    }
    else {
      this.shouldShowToolsModal = true;
    }
  }

  showCaseRequestFormModal(data) {
    console.log("patient selected", data);
    this.patientService.setAndActivate([data]);
    this.formData = data;
    if (this.shouldShowCaseRequestFormModal) {
      this.shouldShowCaseRequestFormModal = false;
    }
    else {
      this.shouldShowCaseRequestFormModal = true;
    }
  }


  onCloseFormModal(){
    this.shouldShowQAOPFormModal = false;
    this.shouldShowQAICFormModal = false;
    this.shouldShowQAATFormModal = false;
    this.shouldShowToolsModal = false;
    this.shouldShowCaseRequestFormModal = false;
    this.search();
  }

  pageChange(data){
    this.pagenumber = data;
    const nsnGridConfig = createNsnGridConfig({ 
      api: "ReviewCase/BindGridData",
      apiBody: { pageNum: this.pagenumber, "pageSize": this.pageSize,"searchValue": this.searchValue, "ReviewedStatus":  this.componentName.commandName },
      method: httpPOSTRequestMethod,
      isTotalItemKeyPresentInDataSource: true,
      totalItemKey: 'totalcount',
      responseRowKey: 'bdosid'
    });
    this.updateGrid(nsnGridConfig);
  }

  search(){
    console.log("search value", this.searchValue);
    this.displayNsnGrid = false;
    this.displayAccordian = true;
    this.buildCustomAccordianConfig();
    this.updateAccordian(this.customAccordianConfig);
  }
}
