import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { AccessService } from 'src/app/shared/services/access.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { createNsnYear, NsnYear } from './new-dashboard.types';
import {
  implantLog,
  invoice,
  NameConstant,
} from 'src/app/shared/models/name-constant';
import { any } from 'cypress/types/bluebird';
import { color } from 'html2canvas/dist/types/css/types/color';

declare var $: any;
@Component({
  selector: 'app-board',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css'],
})
export class NewDashboardComponent implements OnInit {
  @ViewChild('content', { static: true }) content: TemplateRef<any>;
  timeLeft: number = 1;
  interval;
  activeFacilityId: string;
  clientrtrnFacility = 0;
  avgDaysFacility = 0;
  monthNumber: number;
  yearNumber: number;
  monthName: string;
  DashboardArry: any;
  DashboardHeaderArry: any[];
  selectedIndex = -1;
  endDate: any;
  eom: number;
  pcFacilityId: number = 0;
  piFacilityId: number = 0;
  dlFacilityId: number = 0;
  glbFacilityId: any;
  wsFacilityId: number = 0;

  pcDataArry: any;
  piDataArry: any;
  dFlowArry: any;
  EOD:any;

  FacilityList: any[];

  dataBatchCase: number = 0;
  criticalItem: number;
  casesBilled: number = 0;
  casesPerformed: number = 0;
  avgDystoBill: number = 0;
  avgDystoBillIntrnl: number = 0;
  ttlBilledMtd: number = 0;
  dataAmntfrdy: number = 0;
  dataAmntfrmnth: number = 0;
  pendingClients: number = 0;
  priorityItems: number = 0;
  dailyFlow: number = 0;
  casesBilledToday: number = 0;

  totalPriorityItems: number = 0;
  totalActionItems: number = 0;
  role: any;
  checkRole: boolean = true;
  width: any;

  dat = new Date();

  avgDateFromValue = new Date(
    new Date().getMonth() + 1 + '/01/' + new Date().getFullYear()
  );
  avgDateToValue = new Date();

  clientrtrnDateFromValue = new Date(
    new Date().getMonth() + 1 + '/01/' + new Date().getFullYear()
  );
  clientrtrnDateToValue = new Date();

  // public pndngClientChartLabels: Label[] = [];
  // public pndngClientChartData: number[] = [];

  public priorityItemChartLabels: Label[] = [];
  public priorityItemChartData: number[] = [];

  public dailyFlowChartLabels: Label[] = [];
  public dailyFlowChartData: number[] = [];

  public avgDaysChartLabels: Label[] = [];
  public avgDaysChartData: ChartDataSets[] = [{}];

  public clntretrnDaysChartLabels: Label[] = [];
  public clntretrnDaysChartData: ChartDataSets[] = [{}];

  // Temp Declaration
  public pndngClientBarChartLabels: Label[] = [];
  public pndngClientBarChartData: ChartDataSets[] = [{}];

  public priorityItemBarChartLabels: Label[] = [];
  public priorityItemBarChartData: ChartDataSets[] = [{}];

  public dailyFlowBarChartLabels: Label[] = [];
  public dailyFlowBarChartData: ChartDataSets[] = [{}];

  public avgDaysPieChartLabels: Label[] = [];
  public avgDaysPieChartData: number[] = [];

  public clntretrnDaysPieChartLabels: Label[] = [];
  public clntretrnDaysPieChartData: number[] = [];

  // Temp Declaration Ends

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          for (let d of dataArr) {
            sum += Number(d);
          }
          let percentage = ((value * 100) / sum).toFixed(0) + '%';
          return percentage;
        },
        // anchor: 'end',
        // offset: 10,
        // rotation: 45,
        color: '#000',
      },
    },
  };
  public pieChartLabels: Label[] = [
    'Cash Out Of Balance',
    'Enrollment',
    'Coding Issue',
  ];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieChartColors = [
    {
      backgroundColor: [
        '#fec001',
        '#4474c5',
        '#5b9ad5',
        '#72ab49',
        '#9f460d',
        '#a4a3aa',
        '#4b77a9',
        '#5f255f',
      ],
    },
  ];

  public barChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          for (let d of dataArr) {
            sum += Number(d);
          }
          let percentage = ((value * 100) / sum).toFixed(0) + '%';
          return percentage;
        },
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  public dysbarChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            max: 20,
          },
        },
      ],
    },
  };

  public dyspieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        color: '#000',
      },
    },
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  public barChartColors = [
    // {
    //   backgroundColor: ['#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5']
    // },
    {
      backgroundColor: [
        '#fec001',
        '#4474c5',
        '#72ab49',
        '#9f460d',
        '#a4a3aa',
        '#4b77a9',
        '#5b9ad5',
        '#5f255f',
      ],
    },
    // {
    //   backgroundColor: ['#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5', '#5b9ad5'],
    // },
  ];
  userForm: FormGroup;
  closeResult: string;
  submitted: boolean = false;
  showModal: boolean = false;
  username: any;

  nsnYearList: NsnYear[] = [];

  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  constructor(
    private getApiService: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private spinner: NgxSpinnerService,
    private AccessService: AccessService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    
    this.getFacilityInfo();
    this.checkAndSetDefaultFacility();
    console.log(this.dFlowArry?.eoD, 'EOD');
    this.startTimer();
    this.role = localStorage.getItem('currentUser');
    console.log(this.role, 'role');

    console.log('username240', localStorage.getItem('loggedUserName'));
    this.nsnYearList = this.buildNsnYeaList();
    let name = localStorage.getItem('loggedUserName');
    this.username = localStorage.getItem('loggedUser');

    if (name == 'null' || name == '') {
      this.showModal = true;
    } else {
      this.showModal = false;
    }

    this.activeFacilityId = localStorage.getItem('activeFacilityId');
    console.log('Active Facility Id');
    console.log(this.activeFacilityId);
    this.initForm();

    //  this.modalService.open(this.content);
    this.commonUtil.setTitle('Dashboard');
    this.monthNumber = new Date().getMonth() + 1;
    this.yearNumber = new Date().getFullYear();
    // this.modalService.open(this.content);

    //this.getFacilityInfo();   //Setting up Facility drop down

    //this.showModal = true;

    this.getSummaryData();
    //this.getPieGraphData();
    //this.getAtAGlanceData();
  }
  setwidth(data) {
    
      this.width = (data / 35) * 100;
    

    localStorage.setItem('progress', this.width);
    
  }
  
  get f() {
    return this.userForm.controls;
  }
  initForm() {
    this.userForm = this.fb.group({
      FullName: ['', [Validators.required]],
    });
  }
  addUser() {
    console.log('submit');
    this.submitted = true;
    let fullName: string;
    fullName = this.userForm.controls['FullName'].value;
    let data = {
      FullName: this.userForm.controls['FullName'].value,
      UserName: this.username,
    };

    console.log(data);
    this.getApiService.post('ApplicationUser/UpdateUser', data).subscribe(
      (resp: any) => {
        if (resp.status == 'Success') {
          console.log(resp);
          localStorage.setItem('loggedUserName', fullName);
          this.toastr.success(resp.message, 'Success!');
          window.location.reload();
          // this.router.navigate(['/dashboard']);
          // this.CategoryMasterForm.reset();
          // this.closeEmitter.emit();
        } else {
          this.toastr.success(resp.message, 'Error!');
        }
        // this.addedEmitter.emit({
        //   'status': true,
        //   'data': resp
        // })
      },
      (err) => {
        console.log(err);
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getPieGraphData() {
    this.getMonthName();

    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: '0',
      Fdate: strtdt,
      Tdate: enddt,
    };

    this.getApiService.post('BillingNew/GetQueueData', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          console.log('here calling');
          this.pcDataArry = res.dataAll[0];
          this.piDataArry = res.dataAll[0];
          this.dFlowArry = res.dataAll[0];
          localStorage.setItem("EOD",this.dFlowArry?.eoD)
          this.width = localStorage.getItem('progress');
          this.EOD = localStorage.getItem("EOD")

          this.casesBilled = res.dataAll[0].completed;
          this.casesPerformed =
            Number(res.dataAll[0].new) + Number(res.dataAll[0].inCoding);

          this.criticalItem = res.dataCriticalItems;
          let pcChartData = [];
          let pcChartLabel = [];

          if (res.dataAll[0].opReport != 0) {
            pcChartData.push(res.dataAll[0].opReport);
            pcChartLabel.push('Op Note');
          }
          if (res.dataAll[0].high$$$ != 0) {
            pcChartData.push(res.dataAll[0].high$$$);
            pcChartLabel.push('High$$$');
          }
          if (res.dataAll[0].pathology != 0) {
            pcChartData.push(res.dataAll[0].pathology);
            pcChartLabel.push('Path');
          }
          if (res.dataAll[0].block != 0) {
            pcChartData.push(res.dataAll[0].block);
            pcChartLabel.push('Block');
          }
          if (res.dataAll[0].coding != 0) {
            pcChartData.push(res.dataAll[0].coding);
            pcChartLabel.push('Coding');
          }
          if (res.dataAll[0].variance != 0) {
            pcChartData.push(res.dataAll[0].variance);
            pcChartLabel.push('Variance');
          }
          if (res.dataAll[0].pricing != 0) {
            pcChartData.push(res.dataAll[0].pricing);
            pcChartLabel.push('Pricing');
          }
          if (res.dataAll[0].otherMisc != 0) {
            pcChartData.push(res.dataAll[0].otherMisc);
            pcChartLabel.push('Other/Misc');
          }

          this.pndngClientBarChartData = pcChartData;
          this.pndngClientBarChartLabels = pcChartLabel;

          let piChartData = [];
          let piChartLabel = [];

          if (res.dataAll[0].returned != 0) {
            piChartData.push(res.dataAll[0].returned);
            piChartLabel.push('Returned from client');
          }
          if (res.dataAll[0].unassigned != 0) {
            piChartData.push(res.dataAll[0].unassigned);
            piChartLabel.push('Unassigned');
          }
          if (res.dataAll[0].highApprovalInternal != 0) {
            piChartData.push(res.dataAll[0].highApprovalInternal);
            piChartLabel.push('High$$$ cases>48Hours');
          }
          if (res.dataAll[0].overdue != 0) {
            piChartData.push(res.dataAll[0].overdue);
            piChartLabel.push('Pass Due Coding >36 Hours');
          }
          if (res.dataAll[0].exceptions != 0) {
            piChartData.push(res.dataAll[0].exceptions);
            piChartLabel.push('Exceptions');
          }

          this.priorityItemChartData = piChartData;
          this.priorityItemChartLabels = piChartLabel;

          let boh: number =
            res.dataAll[0].codingExpert +
            res.dataAll[0].baq +
            res.dataAll[0].authReview +
            res.dataAll[0].returnedCodingRFI;

          let dfChartData = [];
          let dfChartLabel = [];

          if (res.dataAll[0].codingReturned != 0) {
            dfChartData.push(res.dataAll[0].codingReturned);
            dfChartLabel.push('Ready to Bill');
          }
          if (boh != 0) {
            dfChartData.push(boh);
            dfChartLabel.push('Biller on Hold');
          }
          if (res.dataAll[0].eoD != 0) {
            dfChartData.push(res.dataAll[0].eoD);
            dfChartLabel.push('Regular EoD');
          }

          this.dailyFlowChartData = dfChartData;
          this.dailyFlowChartLabels = dfChartLabel;

          // Temp Graph Data Starts

          this.pndngClientBarChartData = [
            { data: pcChartData, label: 'Pending Client' },
          ];
          this.pndngClientBarChartLabels = pcChartLabel;

          this.priorityItemBarChartData = [
            { data: piChartData, label: 'Priority Items' },
          ];
          this.priorityItemBarChartLabels = piChartLabel;

          this.dailyFlowBarChartData = [
            { data: dfChartData, label: 'Daily Flow' },
          ];
          this.dailyFlowBarChartLabels = dfChartLabel;

          // Temp Graph Data End

          this.priorityItems = 0;
          this.dailyFlow = 0;
          this.pendingClients = 0;
          for (let c of pcChartData) {
            this.pendingClients += c;
          }

          for (let d of this.priorityItemChartData) {
            this.priorityItems += d;
          }

          for (let e of this.dailyFlowChartData) {
            this.dailyFlow += e;
          }
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }

  getAtAGlanceData() {
    this.getMonthName();

    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: '0',
      Fdate: strtdt,
      Tdate: enddt,
    };

    this.getApiService.post('BillingNew/GetAtaGlanceData', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          let avgData = [];
          let avgLabel = [];
          let clntRtrnData = [];
          let clntRtrnLabel = [];

          for (let x of res.dataAvgClientReturnBarGraph) {
            avgData.push(x.avg);
            avgLabel.push(x.abbr);
          }

          for (let y of res.dataAvgDaysToBillBarGraph) {
            clntRtrnData.push(y.day);
            clntRtrnLabel.push(y.abbr);
          }

          this.avgDaysChartData = [
            {
              data: avgData,
              label: 'Avg Days',
              barThickness: 16,
              barPercentage: 0.5,
            },
          ];
          this.avgDaysChartLabels = avgLabel;

          this.clntretrnDaysChartData = [
            {
              data: clntRtrnData,
              label: 'Days to Bill',
              barThickness: 20,
              barPercentage: 0.5,
            },
          ];
          this.clntretrnDaysChartLabels = clntRtrnLabel;

          // Temp Graph Data Starts

          this.avgDaysPieChartData = avgData;
          this.avgDaysPieChartLabels = avgLabel;

          this.clntretrnDaysPieChartData = clntRtrnData;
          this.clntretrnDaysPieChartLabels = clntRtrnLabel;

          // Temp Graph Data Ends
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }

  checkAndSetDefaultFacility() {
    const defaultFacilityID = localStorage.getItem('dashboardFacilityId');
    console.log('133==>', defaultFacilityID);
    if (defaultFacilityID && defaultFacilityID != '0') {
      this.glbFacilityId = defaultFacilityID;
      this.getDataByFacility();
    } else {
      this.glbFacilityId = '0';
      localStorage.setItem('dashboardFacilityId', this.glbFacilityId);
    }
  }
  getFacilityInfo() {
    this.getApiService.get('CreatePatient/BindDashboardFacilityData').subscribe(
      (res) => {
        if (res.status == 'Success') {
          this.FacilityList = res.data;
          console.log(this.FacilityList);
        } else if (res.status == 'Error') {
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.toastr.error(error, 'Error!');
      }
    );
  }
  getSummaryData() {
    this.getDataByFacility();
    console.log(this.glbFacilityId, '612');
    this.spinner.show();
    this.getMonthName();
    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;
    // localStorage.setItem("activeFacilityId",this.glbFacilityId.toString());
    console.log(this.glbFacilityId, 'in fn 580');

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      FacilityQueueId: this.glbFacilityId,
      Fdate: strtdt,
      Tdate: enddt,
    };
    console.log('testing line 584 START', Date());
    this.GetDashboardPriorityItems(data);
    this.GetDashboardDailyFlow(data);
    this.GetDashboardPendingClient(data);
    // this.getFacilityInfo()

    this.getApiService.post('BillingNew/GetCompleteDashboard', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          this.spinner.hide();
          // this.dataBatchCase  = res.dataBatchCase;
          // this.casesBilled = res.dataAll[0].completed;

          //this.FacilityList = res.facilityDt;
          //this.retainFacilityId();

          // this.FacilityList = res.facilityDt;

          // console.log(this.FacilityList,"facility list")
          // console.log(this.glbFacilityId,"in fn 608")
          // this.retainFacilityId();
          // console.log(this.glbFacilityId,"in fn 610")

          //this.FacilityList = res.facilityDt;
          //this.retainFacilityId();

          this.criticalItem = res.dataCriticalItems;
         
          //this.getApiService.post("BillingNew/GetHeaderDataNew"
          this.avgDystoBill = res.dataAvgDaysTobill;
          this.avgDystoBillIntrnl = res.dataAvgDaysTobillInt;
          this.dataAmntfrdy = Math.round(res.dataAmountForDay || 0);
          this.dataAmntfrmnth = Math.round(res.dataAmountForMonth || 0);
          this.casesBilled = res.dataCompletedCase;
          this.casesBilledToday = res.casesBilledToday
          this.setwidth(this.casesBilledToday);
          // console.log("at the end of first",Date());
          this.spinner.hide();
          this.pauseTimer();
        } else {
          this.spinner.hide();
          this.toastr.error('Failed to Get Data!!', 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
    console.log(
      'facility ID at the bottom',
      typeof this.glbFacilityId,
      this.glbFacilityId
    );
  }
  GetDashboardPriorityItems(data) {
    this.getApiService
      .post('BillingNew/GetDashboardPriorityItems', data)
      .subscribe(
        (res) => {
          if (res.status == 'Success') {
            let dataAll = res.priorityItems[0];
            this.piDataArry = dataAll;
            this.priorityItems = 0;
            for (let key in dataAll) {
              console.log('each number >' + dataAll[key]);
              this.priorityItems += dataAll[key];
            }
            
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error, 'Error!');
        }
      );
  }
  GetDashboardDailyFlow(data) {
    this.getApiService.post('BillingNew/GetDashboardDailyFlow', data).subscribe(
      (res) => {
        console.log(data, 'here');
        if (res.status == 'Success') {
          this.dailyFlow = 0;
          let dataAll = res.dailyFlow[0];
          this.dFlowArry = dataAll;
          this.width = localStorage.getItem('progress');
          this.casesPerformed = Number(dataAll.new) + Number(dataAll.inCoding);
          for (let key in dataAll) {
            if (key != 'new' && key != 'inCoding') {
              this.dailyFlow += dataAll[key];
              
            }//End of if
          }//End of forloop
          // console.log("----------------------This is priorityItems item count------------------------");
          //   console.log(this.dailyFlow);
        }

        //this.casesBilled = Number(this.dFlowArry?.completed) + Number(this.dFlowArry?.eoD);
        //console.log('cased billed==>', this.casesBilled);
        //this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }
  GetDashboardPendingClient(data) {
    this.getApiService
      .post('BillingNew/GetDashboardPendingClient', data)
      .subscribe(
        (res) => {
          if (res.status == 'Success') {
            this.pendingClients = 0;
            let dataAll = res.pendingClient[0];
            this.pcDataArry = dataAll;
            for (let key in dataAll) {
              this.pendingClients += dataAll[key];
            }
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error, 'Error!');
        }
      );
  }
  del_getDasboardData(data) {
    this.getApiService.post('BillingNew/GetQueueDataNew', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          let dataAll = res.dataAll[0];
          //this.dataBatchCase  = res.dataBatchCase;
          this.pcDataArry = dataAll;
          this.piDataArry = dataAll;
          this.dFlowArry = dataAll;
          this.width = localStorage.getItem('progress');
          this.EOD = localStorage.getItem("EOD")

          this.casesBilled = dataAll.completed;
          this.casesPerformed = Number(dataAll.new) + Number(dataAll.inCoding);

          // this.criticalItem = res.dataCriticalItems;

          let pcChartData = [];
          let pcChartLabel = [];

          if (dataAll.opReport != 0) {
            pcChartData.push(dataAll.opReport);
            pcChartLabel.push('Op Note');
          }
          if (dataAll.high$$$ != 0) {
            pcChartData.push(dataAll.high$$$);
            pcChartLabel.push('High$$$');
          }
          if (dataAll.pathology != 0) {
            pcChartData.push(dataAll.pathology);
            pcChartLabel.push('Path');
          }
          if (dataAll.block != 0) {
            pcChartData.push(dataAll.block);
            pcChartLabel.push('Block');
          }
          if (dataAll.coding != 0) {
            pcChartData.push(dataAll.coding);
            pcChartLabel.push('Coding');
          }
          if (dataAll.variance != 0) {
            pcChartData.push(dataAll.variance);
            pcChartLabel.push('Variance');
          }
          if (dataAll.pricing != 0) {
            pcChartData.push(dataAll.pricing);
            pcChartLabel.push('Pricing');
          }
          if (dataAll.otherMisc != 0) {
            pcChartData.push(dataAll.otherMisc);
            pcChartLabel.push('Other/Misc');
          }
          if (dataAll.implantLog != 0) {
            pcChartData.push(dataAll.implantLog);
            pcChartLabel.push('implantLog');
          }
          if (dataAll.invoice != 0) {
            pcChartData.push(dataAll.invoice);
            pcChartLabel.push('invoice');
          }

          this.pndngClientBarChartData = [
            { data: pcChartData, label: 'Pending Client' },
          ];
          this.pndngClientBarChartLabels = pcChartLabel;

          let piChartData = [];
          let piChartLabel = [];

          if (dataAll.returned != 0) {
            piChartData.push(dataAll.returned);
            piChartLabel.push('Returned from client');
          }
          if (dataAll.unassigned != 0) {
            piChartData.push(dataAll.unassigned);
            piChartLabel.push('Unassigned');
          }
          if (dataAll.highApprovalInternal != 0) {
            piChartData.push(dataAll.highApprovalInternal);
            piChartLabel.push('High$$$ cases>48Hours');
          }
          if (dataAll.overdue != 0) {
            piChartData.push(dataAll.overdue);
            piChartLabel.push('Pass Due Coding >36 Hours');
          }
          if (dataAll.exceptions != 0) {
            piChartData.push(dataAll.exceptions);
            piChartLabel.push('Exceptions');
          }

          this.priorityItemChartData = piChartData;
          this.priorityItemChartLabels = piChartLabel;

          let boh: number =
            dataAll.codingExpert +
            dataAll.baq +
            dataAll.authReview +
            dataAll.returnedCodingRFI;

          let dfChartData = [];
          let dfChartLabel = [];

          if (dataAll.codingReturned != 0) {
            dfChartData.push(dataAll.codingReturned);
            dfChartLabel.push('Ready to Bill');
          }
          if (boh != 0) {
            dfChartData.push(boh);
            dfChartLabel.push('Biller on Hold');
          }
          if (dataAll.eoD != 0) {
            dfChartData.push(dataAll.eoD);
            dfChartLabel.push('Regular EoD');
          }

          this.dailyFlowChartData = dfChartData;
          this.dailyFlowChartLabels = dfChartLabel;

          this.priorityItems = 0;
          this.dailyFlow = 0;
          this.pendingClients = 0;

          for (let c of pcChartData) {
            this.pendingClients += c;
          }

          for (let d of this.priorityItemChartData) {
            this.priorityItems += d;
          }

          for (let e of this.dailyFlowChartData) {
            this.dailyFlow += e;
          }

          let avgData = [];
          let avgLabel = [];
          let clntRtrnData = [];
          let clntRtrnLabel = [];

          this.avgDaysChartData = [
            {
              data: avgData,
              label: 'Avg Days',
              barThickness: 16,
              barPercentage: 0.5,
            },
          ];
          this.avgDaysChartLabels = avgLabel;

          this.clntretrnDaysChartData = [
            {
              data: clntRtrnData,
              label: 'Days to Bill',
              barThickness: 20,
              barPercentage: 0.5,
            },
          ];
          this.clntretrnDaysChartLabels = clntRtrnLabel;
        }
        console.log('at the end of Second==>', Date());
        //this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }
  del_getPendingClientData(event) {
    this.spinner.show();
    let dt = new Date();

    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;

    let id = event.target.value;

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: id,
      Fdate: strtdt,
      Tdate: enddt,
    };

    this.getApiService
      .post('BillingNew/GetPendingClientGraphData', data)
      .subscribe(
        (res) => {
          if (res.status == 'Success') {
            let dataAll = res.datatAll[0];

            this.pcDataArry = dataAll;

            let pcChartData = [];
            let pcChartLabel = [];

            if (dataAll.opReport != 0) {
              pcChartData.push(dataAll.opReport);
              pcChartLabel.push('Op Note');
            }
            if (dataAll.high$$$ != 0) {
              pcChartData.push(dataAll.high$$$);
              pcChartLabel.push('High$$$');
            }
            if (dataAll.pathology != 0) {
              pcChartData.push(dataAll.pathology);
              pcChartLabel.push('Path');
            }
            if (dataAll.block != 0) {
              pcChartData.push(dataAll.block);
              pcChartLabel.push('Block');
            }
            if (dataAll.coding != 0) {
              pcChartData.push(dataAll.coding);
              pcChartLabel.push('Coding');
            }
            if (dataAll.variance != 0) {
              pcChartData.push(dataAll.variance);
              pcChartLabel.push('Variance');
            }
            if (dataAll.pricing != 0) {
              pcChartData.push(dataAll.pricing);
              pcChartLabel.push('Pricing');
            }
            if (dataAll.otherMisc != 0) {
              pcChartData.push(dataAll.otherMisc);
              pcChartLabel.push('Other/Misc');
            }
            if (dataAll.implantLog != 0) {
              pcChartData.push(dataAll.implantLog);
              pcChartLabel.push('implantLog');
            }
            if (dataAll.invoice != 0) {
              pcChartData.push(dataAll.invoice);
              pcChartLabel.push('invoice');
            }

            this.pndngClientBarChartData = [
              { data: pcChartData, label: 'Pending Client' },
            ];
            this.pndngClientBarChartLabels = pcChartLabel;

            this.pendingClients = 0;
            for (let c of pcChartData) {
              this.pendingClients += c;
            }

            this.spinner.hide();
          } else if (res.status == 'Error') {
            this.spinner.hide();
            if (res.message == 'Invalid User') {
              localStorage.clear();
              window.location.href = '/login';
            }
            this.toastr.error(res.message, 'Error!');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error, 'Error!');
        }
      );
  }

  del_getAvgDaysData() {
    this.spinner.show();
    let id = this.avgDaysFacility;

    let avgstrtdt = new Date(this.avgDateFromValue).toLocaleDateString();
    let avgenddt = new Date(this.avgDateToValue).toLocaleDateString();

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: id,
      Fdate: avgstrtdt,
      Tdate: avgenddt,
    };

    this.getApiService
      .post('BillingNew/GetAVGClientReturnData', data)
      .subscribe(
        (res) => {
          if (res.status == 'Success') {
            let avgData = [];
            let avgLabel = [];

            for (let x of res.dataAvgClientReturnBarGraph) {
              avgData.push(x.avg);
              avgLabel.push(x.abbr);
            }

            this.avgDaysChartData = [
              {
                data: avgData,
                label: 'Avg Days',
                barThickness: 20,
                barPercentage: 0.5,
              },
            ];
            this.avgDaysChartLabels = avgLabel;

            this.spinner.hide();
          } else if (res.status == 'Error') {
            this.spinner.hide();
            if (res.message == 'Invalid User') {
              localStorage.clear();
              window.location.href = '/login';
            }
            this.toastr.error(res.message, 'Error!');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error, 'Error!');
        }
      );
  }

  del_getClientReturnData() {
    this.spinner.show();

    let id = this.clientrtrnFacility;

    let crdstrtdt = new Date(this.clientrtrnDateFromValue).toLocaleDateString();
    let crdenddt = new Date(this.clientrtrnDateToValue).toLocaleDateString();

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: id,
      Fdate: crdstrtdt,
      Tdate: crdenddt,
    };

    this.getApiService.post('BillingNew/GetDaysToBill', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          let clntRtrnData = [];
          let clntRtrnLabel = [];

          for (let y of res.dataDaysToBill) {
            clntRtrnData.push(y.day);
            clntRtrnLabel.push(y.abbr);
          }

          this.clntretrnDaysChartData = [
            {
              data: clntRtrnData,
              label: 'Days to Bill',
              barThickness: 20,
              barPercentage: 0.5,
            },
          ];
          this.clntretrnDaysChartLabels = clntRtrnLabel;

          this.spinner.hide();
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }

  del_getPriorityItemData(event) {
    this.spinner.show();
    let dt = new Date();

    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;

    let id = event.target.value;

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: id,
      Fdate: strtdt,
      Tdate: enddt,
    };

    this.getApiService
      .post('BillingNew/GetPriorityItemsGraphData', data)
      .subscribe(
        (res) => {
          if (res.status == 'Success') {
            let dataAll = res.datatAll[0];
            this.piDataArry = dataAll;

            let piChartData = [];
            let piChartLabel = [];

            if (dataAll.returned != 0) {
              piChartData.push(dataAll.returned);
              piChartLabel.push('Returned from client');
            }
            if (dataAll.unassigned != 0) {
              piChartData.push(dataAll.unassigned);
              piChartLabel.push('Unassigned');
            }
            if (dataAll.highApprovalInternal != 0) {
              piChartData.push(dataAll.highApprovalInternal);
              piChartLabel.push('High$$$ cases>48Hours');
            }
            if (dataAll.overdue != 0) {
              piChartData.push(dataAll.overdue);
              piChartLabel.push('Pass Due Coding >36 Hours');
            }
            if (dataAll.exceptions != 0) {
              piChartData.push(dataAll.exceptions);
              piChartLabel.push('Exceptions');
            }

            this.priorityItemChartData = piChartData;
            this.priorityItemChartLabels = piChartLabel;

            this.priorityItems = 0;

            for (let d of this.priorityItemChartData) {
              this.priorityItems += d;
            }

            this.spinner.hide();
          } else if (res.status == 'Error') {
            this.spinner.hide();
            if (res.message == 'Invalid User') {
              localStorage.clear();
              window.location.href = '/login';
            }
            this.toastr.error(res.message, 'Error!');
          }
        },
        (error) => {
          this.spinner.hide();
          this.toastr.error(error, 'Error!');
        }
      );
  }

  del_getDailyFlowData(event) {
    this.spinner.show();
    let dt = new Date();

    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;

    let id = event.target.value;

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      ClientFacilityId: id,
      Fdate: strtdt,
      Tdate: enddt,
    };

    this.getApiService.post('BillingNew/GetDailyGraphData', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          let dataAll = res.datatAll[0];

          this.dFlowArry = dataAll;
          this.width = localStorage.getItem('progress');
          this.EOD = localStorage.getItem("EOD")

          let boh: number =
            dataAll.codingExpert +
            dataAll.baq +
            dataAll.authReview +
            dataAll.returnedCodingRFI;

          let dfChartData = [];
          let dfChartLabel = [];

          if (dataAll.codingReturned != 0) {
            dfChartData.push(dataAll.codingReturned);
            dfChartLabel.push('Ready to Bill');
          }
          if (boh != 0) {
            dfChartData.push(boh);
            dfChartLabel.push('Biller on Hold');
          }
          if (dataAll.eoD != 0) {
            dfChartData.push(dataAll.eoD);
            dfChartLabel.push('Regular EoD');
          }

          this.dailyFlowChartData = dfChartData;
          this.dailyFlowChartLabels = dfChartLabel;

          this.dailyFlow = 0;

          for (let e of this.dailyFlowChartData) {
            this.dailyFlow += e;
          }

          this.spinner.hide();
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }

  getMonthName() {
    var month = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    this.monthName = month[this.monthNumber - 1];

    this.getEndDate();
  }

  getEndDate() {
    let curMonth = new Date().getMonth() + 1;

    if (curMonth == this.monthNumber) {
      this.endDate = new Date().getDate();
    } else {
      let dt = new Date(this.yearNumber, this.monthNumber, 0).toString();
      this.endDate = new Date(dt).getDate();
    }
  }

  // this method is not used
  del_getGlobalData() {
    this.spinner.show();

    this.getMonthName();

    this.piFacilityId = this.glbFacilityId;
    this.pcFacilityId = this.glbFacilityId;
    this.dlFacilityId = this.glbFacilityId;
    this.avgDaysFacility = this.glbFacilityId;
    this.clientrtrnFacility = this.glbFacilityId;
    this.wsFacilityId = this.glbFacilityId;

    let strtdt = this.monthNumber + '/01/' + this.yearNumber;
    let enddt = this.monthNumber + '/' + this.endDate + '/' + this.yearNumber;

    let data = {
      MonthNumber: this.monthNumber,
      YearNumber: this.yearNumber,
      FacilityQueueId: this.glbFacilityId,
      Fdate: strtdt,
      Tdate: enddt,
    };

    //this.getSummaryData();

    this.getApiService.post('BillingNew/GetQueueDataNew', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          let dataAll = res.dataAll[0];
          this.dataBatchCase = res.dataBatchCase;
          this.pcDataArry = dataAll;
          this.piDataArry = dataAll;
          this.dFlowArry = dataAll;
          this.width = localStorage.getItem('progress');
          this.EOD = localStorage.getItem("EOD")

          this.casesBilled = dataAll.completed;
          this.casesPerformed = Number(dataAll.new) + Number(dataAll.inCoding);

          // this.criticalItem = res.dataCriticalItems;

          let pcChartData = [];
          let pcChartLabel = [];

          if (dataAll.opReport != 0) {
            pcChartData.push(dataAll.opReport);
            pcChartLabel.push('Op Note');
          }
          if (dataAll.high$$$ != 0) {
            pcChartData.push(dataAll.high$$$);
            pcChartLabel.push('High$$$');
          }
          if (dataAll.pathology != 0) {
            pcChartData.push(dataAll.pathology);
            pcChartLabel.push('Path');
          }
          if (dataAll.block != 0) {
            pcChartData.push(dataAll.block);
            pcChartLabel.push('Block');
          }
          if (dataAll.coding != 0) {
            pcChartData.push(dataAll.coding);
            pcChartLabel.push('Coding');
          }
          if (dataAll.variance != 0) {
            pcChartData.push(dataAll.variance);
            pcChartLabel.push('Variance');
          }
          if (dataAll.pricing != 0) {
            pcChartData.push(dataAll.pricing);
            pcChartLabel.push('Pricing');
          }
          if (dataAll.otherMisc != 0) {
            pcChartData.push(dataAll.otherMisc);
            pcChartLabel.push('Other/Misc');
          }
          if (dataAll.implantLog != 0) {
            pcChartData.push(dataAll.implantLog);
            pcChartLabel.push('implantLog');
          }
          if (dataAll.invoice != 0) {
            pcChartData.push(dataAll.invoice);
            pcChartLabel.push('invoice');
          }

          // this.pndngClientChartData = pcChartData;
          // this.pndngClientChartLabels = pcChartLabel;
          this.pndngClientBarChartData = [
            { data: pcChartData, label: 'Pending Client' },
          ];
          this.pndngClientBarChartLabels = pcChartLabel;

          let piChartData = [];
          let piChartLabel = [];

          if (dataAll.returned != 0) {
            piChartData.push(dataAll.returned);
            piChartLabel.push('Returned from client');
          }
          if (dataAll.unassigned != 0) {
            piChartData.push(dataAll.unassigned);
            piChartLabel.push('Unassigned');
          }
          if (dataAll.highApprovalInternal != 0) {
            piChartData.push(dataAll.highApprovalInternal);
            piChartLabel.push('High$$$ cases>48Hours');
          }
          if (dataAll.overdue != 0) {
            piChartData.push(dataAll.overdue);
            piChartLabel.push('Pass Due Coding >36 Hours');
          }
          if (dataAll.exceptions != 0) {
            piChartData.push(dataAll.exceptions);
            piChartLabel.push('Exceptions');
          }

          this.priorityItemChartData = piChartData;
          this.priorityItemChartLabels = piChartLabel;

          let boh: number =
            dataAll.codingExpert +
            dataAll.baq +
            dataAll.authReview +
            dataAll.returnedCodingRFI;

          let dfChartData = [];
          let dfChartLabel = [];

          if (dataAll.codingReturned != 0) {
            dfChartData.push(dataAll.codingReturned);
            dfChartLabel.push('Ready to Bill');
          }
          if (boh != 0) {
            dfChartData.push(boh);
            dfChartLabel.push('Biller on Hold');
          }
          if (dataAll.eoD != 0) {
            dfChartData.push(dataAll.eoD);
            dfChartLabel.push('Regular EoD');
          }

          this.dailyFlowChartData = dfChartData;
          this.dailyFlowChartLabels = dfChartLabel;

          this.priorityItems = 0;
          this.dailyFlow = 0;
          this.pendingClients = 0;

          for (let c of pcChartData) {
            this.pendingClients += c;
          }

          for (let d of this.priorityItemChartData) {
            this.priorityItems += d;
          }

          for (let e of this.dailyFlowChartData) {
            this.dailyFlow += e;
          }

          let avgData = [];
          let avgLabel = [];
          let clntRtrnData = [];
          let clntRtrnLabel = [];

          // for (let x of res.dataAvgClientReturnBarGraph) {
          //   avgData.push(x.avg);
          //   avgLabel.push(x.abbr);
          // }

          // for (let y of res.dataAvgDaysToBillBarGraph) {
          //   clntRtrnData.push(y.day);
          //   clntRtrnLabel.push(y.abbr);
          // }

          this.avgDaysChartData = [
            {
              data: avgData,
              label: 'Avg Days',
              barThickness: 16,
              barPercentage: 0.5,
            },
          ];
          this.avgDaysChartLabels = avgLabel;

          this.clntretrnDaysChartData = [
            {
              data: clntRtrnData,
              label: 'Days to Bill',
              barThickness: 20,
              barPercentage: 0.5,
            },
          ];
          this.clntretrnDaysChartLabels = clntRtrnLabel;

          // this.spinner.hide();
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
  }

  private buildNsnYeaList() {
    const currentYear = new Date().getFullYear();
    const range = (start, stop, step) =>
      Array.from(
        { length: (stop - start) / step + 1 },
        (_, i) => start + i * step
      );
    const listOfNumber = range(currentYear - 10, currentYear, 1);
    const sortedList = listOfNumber.sort(function (a, b) {
      return b - a;
    });
    return sortedList.map((l) =>
      createNsnYear({
        value: l,
        displayValue: l.toString(),
      })
    );
  }

  changeToInt(val) {
    return parseInt(val);
  }

  startTimer() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft++;
      } else {
        this.timeLeft = 0;
      }
    }, 1000);
  }

  pauseTimer() {
    clearInterval(this.interval);
  }

  //new
  getDataByFacility() {
    console.log('----------------------Id----------------');
    console.log(this.glbFacilityId, 'in1440');
    localStorage.setItem('dashboardFacilityId', this.glbFacilityId);
  }
}
