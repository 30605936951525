<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Email Notifications</h1>
                    <!-- <a href="javascript:void(0);" (click)='showFormModal()' id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add Notification
                    </a> -->
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="getENArryList()"
                                        placeholder="To/CC"></label></div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Event</th>
                                    <th>To</th>
                                    <th>CC</th>
                                    <th>BCC</th>
                                    <th>Last Updated</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Event</th>
                                    <th>To</th>
                                    <th>CC</th>
                                    <th>BCC</th>
                                    <th>Last Updated</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of ENArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>
                                        {{(i+1) + ((p-1)*pageValue)}} 
                                        <!-- {{i+1}} -->
                                    </td>
                                    <td>{{data.emailEvent}}</td>
                                    <td>{{data.emailTo}}</td>
                                    <td>{{data.emailCC}}</td>
                                    <td>{{data.emailBCC}}</td>
                                    <td *ngIf="data.updatedDate != null">{{data.updatedDate | date:'MM/dd/yyyy HH:mm' }}
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" (click)="editEN(data)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>
                                        <!-- <a href="javascript:void(0)" (click)="delEN(data)"
                                            class="btn btn-danger icon-btn"><i class="fa fa-trash-alt" title="Delete"></i></a> -->
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-email-notification-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (editEmitter)="onEditRecord($event)">
</app-email-notification-form>
<!-- Sidenav-->