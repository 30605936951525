import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-test-side-nav',
  templateUrl: './test-side-nav.component.html',
  styleUrls: ['./test-side-nav.component.css']
})
export class TestSideNavComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  onClickClose() {
    this.closeEmitter.emit();
  }


}
