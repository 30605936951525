import { Injectable } from "@angular/core";

export interface sharedData {
   data: any;
}

@Injectable({
    providedIn: 'root'
  })
export class ShareDataService {
  private data: sharedData;

  share(data: any){
    this.data = {
        data: data
    };
  }
  
  get(): sharedData {
    return this.data;
  } 
// billerrole(data:boolean):boolean {
//   return data;
// }

} 
