import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { CaseRequest, createCaseRequest } from './case-request.model';
import { CaseRequestQuery } from './case-request.query';
import { CaseRequestStore } from './case-request.store';
import * as caseRequestList from '../../enums/case-request-name';
import { CaseRequestHelper } from '../../helper/case-request.helper';
import { na } from 'src/app/shared/models/name-constant';

@Injectable({ providedIn: 'root' })
export class CaseRequestService {

  private caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();

  constructor(private caseRequestStore: CaseRequestStore, private caseRequestQuery: CaseRequestQuery) {
  }


  init(): void{
    
    const orderCaseRequestList = [
      caseRequestList.operatingReport,
      caseRequestList.pathology,
      caseRequestList.pricing,
      caseRequestList.invoice,
      caseRequestList.implantLog,
      caseRequestList.blockReport,
      caseRequestList.codingQuery,
      caseRequestList.highApproval,
      caseRequestList.variance,
      caseRequestList.otherMisc,
      caseRequestList.inCoding,
      //caseRequestList.highApprovalInternal,
      caseRequestList.codingExpert,
      caseRequestList.authReview
    ]
    const caseRequests = orderCaseRequestList.map(cr => createCaseRequest({
      id: cr.id,
      value: cr.value,
      displayName: cr.displayName,
      type: this.caseRequestHelper.getRequstType(cr.id),
      hasIndicator: false,
      alreadySubmitted: false,
      isHigh$$$orVarRequestStatus: na.id,
      bulkActionQueueName: cr.bulkActionQueueName
      }));
    this.caseRequestStore.set(caseRequests);
  }

  setActive(ids: ID[]){
    this.caseRequestStore.setActive(ids);
  }
  addActive(id: ID){
    this.caseRequestStore.addActive(id);
  }
  toggleActive(id: ID){
    this.caseRequestStore.toggleActive(id);
  }

  toggleAllActive(id: ID[]){
    this.caseRequestStore.toggleActive(id);
  }

  add(caseRequest: CaseRequest) {
    this.caseRequestStore.add(caseRequest);
  }

  update(id, caseRequest: Partial<CaseRequest>) {
    this.caseRequestStore.update(id, caseRequest);
  }
  reset(){
    this.caseRequestStore.set([]);
  }
}
