export interface Payer {
  id: number | string;
  sourceSystemName:string; // naming convention is not correct using the exising response format we get from api
  payer_SystemKey: string;
  payer_Name: string;
  payer_Type: string;
  payer_FinancialClass: string;
  active: 1,
  importDate: Date;
  is_electronic: number;
  is_paper: number;
  updateDate: Date;
}

export function createPayer(params: Partial<Payer>) {
  return {
    id: params.id,
    sourceSystemName: params.sourceSystemName,
    payer_SystemKey: params.payer_SystemKey,
    payer_Name: params.payer_Name,
    payer_Type: params.payer_Type,
    payer_FinancialClass: params.payer_FinancialClass,
    active: params.active,
    importDate: params.importDate,
    is_electronic: params.is_electronic,
    is_paper: params.is_paper,
    updateDate: params.updateDate
  } as Payer;
}
