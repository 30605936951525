import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeeScheduleComponent } from './fee-schedule.component';
import { FeeScheduleFormComponent } from '../fee-schedule/fee-schedule-form/fee-schedule-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FeeScheduleEditComponent } from './fee-schedule-edit/fee-schedule-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FacilityDetailsComponent } from './facility-details/facility-details.component';
//import { FacilityDetailsComponent } from '../../masters/facility-master/facility-details/facility-details.component';
@NgModule({
  declarations: [
    FeeScheduleFormComponent,
    FeeScheduleComponent,
    FeeScheduleEditComponent,
    FacilityDetailsComponent,
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [FeeScheduleFormComponent]
})
export class FeeScheduleModule { }
