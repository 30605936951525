import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-import-patients',
  templateUrl: './import-patients.component.html',
  styleUrls: ['./import-patients.component.css']
})
export class ImportPatientsComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  importPatientList: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  privileges:any;
  searchValue: string = "";

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private accessServices:AccessService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.privileges = this.accessServices.navbar_access
    this.commonUtil.setTitle("Import Patients");
    this.getImportPatientList();
  }

  onEditRecord(data) {
    this.shouldShowFormModal = true;
    this.formData = data;
  }

  showFormModal() {
    this.formData = null;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getImportPatientList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }

  }

  getImportPatientList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };


    this.getApiService.post("Import/BindGrid", data).subscribe(res => {
      if (res.status == "Success") {
        this.importPatientList = res.data;
        this.totalRows = res.data[0].totalcount;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  downloadfile(filename) {

    let filetodownload = filename.split('-')[0];

    let downloadUrl = this.getApiService.baseUrl + "api/Import/DownloadFile?filename=" + filetodownload;

    window.open(downloadUrl, "_blank");
    
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.importPatientList = [];
    this.getImportPatientList();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getImportPatientList();
  }

}
