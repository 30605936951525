import { Component, EventEmitter, Output, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { ShareDataService } from 'src/app/shared/services/share-data.service';
import { Payer, PayerData } from './model/payer.model';

@Component({
  selector: 'app-payer-data',
  templateUrl: './payer-data.component.html',
  styleUrls: ['./payer-data.component.css']
})
export class PayerDataComponent implements OnInit, OnDestroy {
  @Output() closeEmitter = new EventEmitter();
  showTableData: boolean = false;
  payerDataHavingFinancialClass$: Observable<PayerData>;
  payers: Payer[];
  selectedPayerWithFinancialClass: Payer;
  pageValue: number = 10;
  totalRows: number;
  pagenumber: any = 1;
  p: number = 1;
  subscription: Subscription = new Subscription();
  facilityName: string;
  searchValue: string = "";

  constructor(
    private getApiService: ApiService,
    private shareDataService: ShareDataService,
    private toastr: ToastrService,
    private ref: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.loadFinancialClasses();
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }
  onClickClose() {
    this.closeEmitter.emit();
  }
  showTable() {
    this.pagenumber = 1;
    this.p = 1;
    this.loadPayersByFinancialClass();
  }

  loadPayersByFinancialClass() {
    
    const rquestData = {
      pageNum: this.pagenumber,
      pageSize: this.pageValue,
      SearchValue: this.searchValue,
      facilityID: this.shareDataService.get().data.id,
      financialClass: this.selectedPayerWithFinancialClass.payer_FinancialClass
    };
    console.log('rquestData', rquestData);
    const subscription = this.getApiService.post("FacilityPayer/GetPayerBYFinancialClass", rquestData)
      .subscribe(res => {
        if (res.status === "Success") {
          this.payers = res.data;
          this.showTableData = this.hasPayersData();
          if (res.data.length == 0) {
            this.toastr.success('No records found!!', 'Success!');
          }
          if (res.data[0])
            this.totalRows = res.data[0].totalcount;

        } else if (res.status === "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
    this.subscription.add(subscription);
  }

  loadFinancialClasses() {
    const facility = this.shareDataService.get().data;
    this.facilityName = facility.name;
    this.payerDataHavingFinancialClass$ = this.getApiService.post("FacilityPayer/GetFinancialClass", { facilityId: facility.id });
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.payers = [];
    this.loadPayersByFinancialClass();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.loadPayersByFinancialClass();
  }

  hasPayersData() {
    return this.payers && this.payers.length > 0;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  updateElectronicType($event, payer: Payer) {
    this.updateClaimType("electronic", $event.target.checked, payer);
  }

  updatePaperType($event, payer: Payer) {
    this.updateClaimType("paper", $event.target.checked, payer);
  }

  private updateClaimType(claimType: string, value: boolean, payer: Payer) {
    let isElectronic;
    let isPaper;

    if (claimType === "electronic") {
      isElectronic = value ? 1 : 0;
      isPaper = payer.is_paper;
      payer.is_electronic = isElectronic;
    }
    if (claimType === "paper") {
      isPaper = value ? 1 : 0;
      isElectronic = payer.is_electronic;
      payer.is_paper = isPaper;
    }

    const requestData = {
      payername: payer.payer_Name,
      financialClass: payer.payer_FinancialClass,
      paperSystemKey: payer.payer_SystemKey,
      is_electronic: isElectronic,
      is_paper: isPaper
    };
    console.log('requestData', requestData);
    this.getApiService.post("FacilityPayer/UpdateClaimTypeByPayer", requestData).subscribe(res => {
      if (res.status === "Success") {
        this.toastr.success('Claim type updated', 'Success!');
      } else if (res.status === "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });;

  }

  search() {
    this.pagenumber = 1;
    this.p = 1;
    this.loadPayersByFinancialClass();
  }
}
