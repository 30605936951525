import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { google } from 'google-maps';
import { DatePipe } from '@angular/common';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { CurrencyPipe } from '@angular/common';
import { formatCurrency } from "@angular/common";
@Component({
  selector: 'app-patients-form',
  templateUrl: './patients-form.component.html',
  styleUrls: ['./patients-form.component.css']
})
export class PatientsFormComponent implements OnInit {
  
  submit: any = "Submit"
  config = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }

  @ViewChild('addresstext') addresstext: any;

  PatientsForm: FormGroup;
  facilityList: [];
  tempValue: string;
  submitted = false;
  phone: any;
  model: any;
  searching = false;
  searchFailed = false;
  notFound = false;
  submitButton: string = "Submit";
  submitButtonStatus: boolean = false;
  cancelButtonStatus = false;
  FacilityId: any = "0";
  dosValue: any;
  public phoneCodeMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public ssnCodeMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();

  InsuranceListForm: FormArray;
  CPTCodeListForm: FormArray;

  formFlag: string = "Add";
  _formData: any;
  facilityName: any = "";
  valChange: any;
  myDateValue = { month: new Date().getMonth() + 1, day: new Date().getDate(), year: new Date().getFullYear(), };
  @Input() set formData(value: any) {

    if (value != null && value != undefined) {
      this._formData = value;
      this.formFlag = "Edit";
      this.editForm();
    }
    else {
      this.formFlag = "Add";
      // this.initForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private currencyPipe: CurrencyPipe,
    private getApiService: ApiService,
    public datepipe: DatePipe,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    console.log(this.FacilityId)
    this.initForm();
    this.InsuranceListForm = <FormArray>this.PatientsForm.controls.InsuranceList;
    this.CPTCodeListForm = <FormArray>this.PatientsForm.controls.CPTCodeList;
    this.getFacilityMasterList();
    this.phone = this.PatientsForm.get('phoneNo').value;
    this.enableSubmitButton();
  }

  get f() { return this.PatientsForm.controls; }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  onDateChange(newDate) {
    console.log(newDate, "date")
    let dt = newDate.month + "/" + newDate.day + "/" + newDate.year;
    this.PatientsForm.controls["DOS"].setValue(dt);
  }

  onChangeValue(id): void {

    let fid;

    if (this.formFlag == "Add") {
      fid = this.PatientsForm.value.FacilityId.id;
      fid = this.PatientsForm.controls['FacilityId'].value;
    } else {
      fid = this.PatientsForm.controls['FacilityId'].value;
    }


    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    let cpt = frmCntrl.value.CPTCode.trim();
    if (fid != undefined) {
      let data = { "CPTCode": cpt, "FacilityId": fid };
      let relativeUrl = "CreatePatient/GetCPT";

      this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
        if (resp.status == "Success") {
          if (resp.dataCPTInfo.length > 0) {
            frmCntrl.controls["CPTDesc"].setValue(resp.dataCPTInfo[0].cptDesc);
            frmCntrl.controls["Rate"].setValue(resp.dataCPTInfo[0].rate);
          }
          else {
            frmCntrl.controls["CPTCode"].setValue("");
            frmCntrl.controls["CPTDesc"].setValue("");
            frmCntrl.controls["Rate"].setValue("");
          }
        }
        else {
          frmCntrl.controls["CPTCode"].setValue("");
          frmCntrl.controls["CPTDesc"].setValue("");
          frmCntrl.controls["Rate"].setValue("");
        }

      }, (err) => {
        console.log(err);
        frmCntrl.controls["CPTCode"].setValue("");
        frmCntrl.controls["CPTDesc"].setValue("");
        frmCntrl.controls["Rate"].setValue("");
      })
    }
    else {
      frmCntrl.controls["CPTCode"].setValue("");
      frmCntrl.controls["CPTDesc"].setValue("");
      frmCntrl.controls["Rate"].setValue("");
    }
  }

  onCancel() {
    this.closeEmitter.emit();
  }


  initForm() {
    this.PatientsForm = this.fb.group({
      patientId: ["",],
      FacilityId: ["", [Validators.required]],
      FirstName: ["", Validators.required],
      LastName: ["", [Validators.required]],
      MRN: ["", Validators.required],
      caseId: ["", Validators.required],
      DOS: ["", [Validators.required]],
      Gender: [""],
      phoneNo: [""],
      physicianFirstName: ['', Validators.required],
      physicianLastName: ['', Validators.required],
      Add1: ["",],
      Add2: ["",],
      city: ["",],
      state: ["",],
      ZIP: ["",],
      InsuranceList: this.fb.array([
        this.getInsuranceList()
      ]),
      CPTCodeList: this.fb.array([
        this.getCptCodeList()
      ])
    })
  }

  editForm() {

    let relativeUrl = "CreatePatient/GetPatientDetails";

    let data = { "patientId": this._formData.patientId, "BDOSId": this._formData.dosId }

    this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
      if (resp.status == "Success") {

        let patientName = resp.dataPatientInfo[0].patientName.split(',');
        this.facilityName = resp.dataPatientInfo[0].facilityName;

        // let dosVal = new Date(resp.dataPatientInfo[0].dateOfSurgery).toISOString().slice(0, 10);

        this.PatientsForm.controls['patientId'].setValue(resp.dataPatientInfo[0].patientId);
        this.PatientsForm.controls['FacilityId'].setValue(resp.dataPatientInfo[0].facilityID);
        this.PatientsForm.controls['FirstName'].setValue(patientName[1].toString().trim());
        this.PatientsForm.controls['LastName'].setValue(patientName[0]);
        this.PatientsForm.controls['MRN'].setValue(resp.dataPatientInfo[0].mrn);
        this.PatientsForm.controls['caseId'].setValue(resp.dataPatientInfo[0].caseId);
        this.PatientsForm.controls['DOS'].setValue(resp.dataPatientInfo[0].dateOfSurgery);
        this.PatientsForm.controls['Gender'].setValue(resp.dataPatientInfo[0].gender);
        this.PatientsForm.controls['phoneNo'].setValue(resp.dataPatientInfo[0].phonenumber);
        this.PatientsForm.controls['physicianLastName'].setValue('');
        //this.PatientsForm.controls['Add1'].setValue(resp.dataPatientInfo[0].address1 + " " + resp.dataPatientInfo[0].address2);
        this.PatientsForm.controls['Add1'].setValue(resp.dataPatientInfo[0].address1);
        this.PatientsForm.controls['Add2'].setValue(resp.dataPatientInfo[0].address2);
        this.PatientsForm.controls['city'].setValue(resp.dataPatientInfo[0].city);
        this.PatientsForm.controls['state'].setValue(resp.dataPatientInfo[0].state);
        this.PatientsForm.controls['ZIP'].setValue(resp.dataPatientInfo[0].zip);
        this.getInsuranceListwithData(resp);
        this.getCptCodeListwithData(resp);



        let vlP = this.PatientsForm.controls['DOS'].value;
        let vp = vlP.split('-');

        const dt = { year: parseInt(vp[0], 10), month: parseInt(vp[1], 10), day: parseInt(vp[2].substring(0, 2), 10) };
        this.myDateValue = dt;


        let OldDos = parseInt(vp[1], 10) + "/" + parseInt(vp[2].substring(0, 2), 10) + "/" + parseInt(vp[0], 10);
        this.PatientsForm.controls["DOS"].setValue(OldDos);

      }

    }, (err) => {
      console.log(err);
    })
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getInsuranceList() {
    return this.fb.group({
      InsuranceType: ['', Validators.required],
      Provider: ['', Validators.required],
      Group: [''],
      InsuranceId: [''],
    });
  }

  getCptCodeList() {
    return this.fb.group({
      cpt: ['', Validators.required],
      CPTCode: ['', Validators.required],
      Rate: ['', Validators.required],
      CPTDesc: ['', Validators.required],
      Physician: ['',],
      InsuranceType: ['',],
      Provider: ['',],
      Group: ['',],
      InsuranceId: ['',],
    });
  }


  getInsuranceListwithData(data) {
    var loop = data.dataCPTInfo.length;
    const control = <FormArray>this.InsuranceListForm;

    if (loop > 0 || data.dataInsurance.length > 0) {
      control.removeAt(0);
    }

    for (let v = 0; v < loop; v++) {
      if (data.dataCPTInfo[v].insuranceType == "Primary") {
        control.push(this.fb.group({
          id: [data.dataCPTInfo[v].id, Validators.required],
          patientID: [data.dataCPTInfo[v].patientID, Validators.required],
          bdosid: [data.dataCPTInfo[v].bdosid, Validators.required],
          InsuranceType: [data.dataCPTInfo[v].insuranceType, Validators.required],
          Provider: [data.dataCPTInfo[v].insuranceProvider, Validators.required],
          Group: [data.dataCPTInfo[v].groupNumber],
          InsuranceId: [data.dataCPTInfo[v].insuranceID],
        }));
      }
      loop = v;
    }

    if (data.dataInsurance.length > 0) {

      for (let x = 0; x < data.dataInsurance.length; x++) {
        if (data.dataInsurance[x].insuranceProvider != "" || data.dataInsurance[x].groupNumber != "") {
          control.push(this.fb.group({
            id: [data.dataInsurance[x].id, Validators.required],
            patientID: [data.dataInsurance[x].patientID, Validators.required],
            bdosid: [data.dataInsurance[x].bdosid, Validators.required],
            InsuranceType: [data.dataInsurance[x].insuranceType, Validators.required],
            Provider: [data.dataInsurance[x].insuranceProvider, Validators.required],
            Group: [data.dataInsurance[x].groupNumber],
            InsuranceId: [data.dataInsurance[x].insuranceID],
          }));
        }
      }
    }
  }

  getCptCodeListwithData(data) {
    if (data.dataCPTInfo.length > 0) {

      for (let v = 0; v < data.dataCPTInfo.length; v++) {
        const control = <FormArray>this.CPTCodeListForm;
        if (v == 0) {
          control.removeAt(0);
          let physicianName = data.dataCPTInfo[0].physician.split(',');
          //this.PatientsForm.controls['CASE'].setValue(data.dataCPTInfo[0].bdosid);
          this.PatientsForm.controls['physicianFirstName'].setValue(physicianName[1]);
          this.PatientsForm.controls['physicianLastName'].setValue(physicianName[0]);
        }

        control.push(this.fb.group({
          id: [data.dataCPTInfo[v].id, Validators.required],
          patientID: [data.dataCPTInfo[v].patientID, Validators.required],
          bdosid: [data.dataCPTInfo[v].bdosid, Validators.required],
          cpt: [{ 'cpt': data.dataCPTInfo[v].cptCode }, Validators.required],
          CPTCode: [data.dataCPTInfo[v].cptCode, Validators.required],
          Rate: [data.dataCPTInfo[v].rate, Validators.required],
          CPTDesc: [data.dataCPTInfo[v].surgeryDescription, Validators.required],
          Physician: [data.dataCPTInfo[v].physician,],
          InsuranceType: [data.dataCPTInfo[v].insuranceType,],
          Provider: [data.dataCPTInfo[v].insuranceProvider,],
          Group: [data.dataCPTInfo[v].groupNumber,],
          InsuranceId: [data.dataCPTInfo[v].insuranceID,],
        }));
      }
    }
  }

  enableSubmitButton() {
    this.submitButtonStatus = false;
    if (this.formFlag == "Add") {
      this.submitButton = "Add";
    } else if (this.formFlag == "Edit") {
      this.submitButton = "Update";
    }
  }

  disableSubmitButton() {
    this.submitButtonStatus = true;
    if (this.formFlag == "Add") {
      this.submitButton = "Adding....";
    } else if (this.formFlag == "Edit") {
      this.submitButton = "Updating....";
    }
  }


  addInsuranceList() {
    this.disableSubmitButton();
    const control = <FormArray>this.PatientsForm.controls['InsuranceList'];
    control.push(this.getInsuranceList());
    this.enableSubmitButton();
  }

  removeInsuranceList(id: number) {
    this.disableSubmitButton();
    const control = <FormArray>this.PatientsForm.controls['InsuranceList'];
    control.removeAt(id);
    this.enableSubmitButton();
  }

  addCptCodeList() {
    this.disableSubmitButton();
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    control.push(this.getCptCodeList());
    this.enableSubmitButton();
  }

  removeCptCodeList(id: number) {
    this.disableSubmitButton();
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    control.removeAt(id);
    this.enableSubmitButton();
  }

  fetchDetails(id) {
    this.disableSubmitButton();
    this.onChangeValue(id);
    this.enableSubmitButton();
  }

  getFacilityMasterList() {
    let data = {};

    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.facilityList = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  
  disableDate(){
    return false;
}  

  onSubmit() {
    this.cancelButtonStatus = true;
    this.submitted = true;
    this.disableSubmitButton();
    if (this.formFlag == "Add") {
      this.addRecord();
    } else if (this.formFlag == "Edit") {
      this.editRecord();
    }

  }

  addRecord() {
    let dos = this.PatientsForm.controls['DOS'].value
    // console.log(dos);
    // return;
    let formattedDate = this.datepipe.transform(dos, 'MM/dd/yyyy')
    let PhysicianName = this.PatientsForm.value.physicianLastName + ',' + this.PatientsForm.value.physicianFirstName;
    let d = 0;
    if (this.PatientsForm.valid) {
      this.PatientsForm.controls["DOS"].setValue(formattedDate)
      let c = this.InsuranceListForm.length;

      for (let i = 0; i < c; i++) {
        const insFrm = <FormArray>this.InsuranceListForm.controls[i];
        /*
        if (insFrm.value.InsuranceType == "Primary") {

          for (let b = 0; b < this.CPTCodeListForm.length; b++) {
            const cptFrm = <FormArray>this.CPTCodeListForm.controls[b];
            cptFrm.controls["InsuranceType"].setValue(insFrm.value.InsuranceType);
            cptFrm.controls["Provider"].setValue(insFrm.value.Provider);
            cptFrm.controls["Group"].setValue(insFrm.value.Group);
            cptFrm.controls["InsuranceId"].setValue(insFrm.value.InsuranceId);
            cptFrm.controls["Physician"].setValue(PhysicianName);
            console.log(cptFrm);
          }
          this.InsuranceListForm.removeAt(i);
          c = i;
          d = 1;

        }
        */
      }

      if (d == 0) {
        for (let b = 0; b < this.CPTCodeListForm.length; b++) {
          const cptFrm = <FormArray>this.CPTCodeListForm.controls[b];
          cptFrm.controls["Physician"].setValue(PhysicianName);
        }
      }

      let FacilityId = this.PatientsForm.value.FacilityId;

      let phoneNo: number = this.PatientsForm.value.phoneNo;


      this.PatientsForm.removeControl["phoneNo"];
      this.PatientsForm.removeControl["FacilityId"];

      this.PatientsForm.controls['FacilityId'].setValue(FacilityId);
      this.PatientsForm.controls['phoneNo'].setValue(phoneNo);

      const formdata = this.PatientsForm.value;


      delete formdata["patientId"];
      delete formdata["physicianFirstName"];
      delete formdata["physicianLastName"];

      let relativeUrl = "CreatePatient/Save";
      let data = formdata;

      this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
        if (resp.status == "Success") {
          this.PatientsForm.reset();

        }
        this.addedEmitter.emit({
          'status': true,
          'data': resp
        })
        this.enableSubmitButton();
      }, (err) => {
        console.log(err);
        this.enableSubmitButton();
      })
    }
    else {
      this.enableSubmitButton();
      this.toastr.error("Please fill mandatory fields!!", 'Error!');
      return;
    }

  }

  editRecord() {
    let dos = this.PatientsForm.controls['DOS'].value
    let formattedDate = this.datepipe.transform(dos, 'MM/dd/yyyy')

    if (this.PatientsForm.valid) {
      let PhysicianName = this.PatientsForm.value.physicianLastName + ',' + this.PatientsForm.value.physicianFirstName;
      this.PatientsForm.removeControl["physicianFirstName"];
      this.PatientsForm.removeControl["physicianLastName"];
      this.PatientsForm.controls["DOS"].setValue(formattedDate)

      let phoneNo: number = this.PatientsForm.value.phoneNo;

      let c = this.InsuranceListForm.length;
      let d = 0;
      this.PatientsForm.removeControl["phoneNo"];
      this.PatientsForm.controls['phoneNo'].setValue(phoneNo);

      for (let i = 0; i < c; i++) {
        const insFrm = <FormArray>this.InsuranceListForm.controls[i];
        /*
        if (insFrm.value.InsuranceType == "Primary") {
          this.submit = "Submiting"
          for (let b = 0; b < this.CPTCodeListForm.length; b++) {
            const cptFrm = <FormArray>this.CPTCodeListForm.controls[b];
            cptFrm.controls["InsuranceType"].setValue(insFrm.value.InsuranceType);
            cptFrm.controls["Provider"].setValue(insFrm.value.Provider);
            cptFrm.controls["Group"].setValue(insFrm.value.Group);
            cptFrm.controls["InsuranceId"].setValue(insFrm.value.InsuranceId);
            cptFrm.controls["Physician"].setValue(PhysicianName);
          }
          
          this.InsuranceListForm.removeAt(i);
          c = i;
          d = 1;
        }
        */
      }

      if (d == 0) {
        for (let b = 0; b < this.CPTCodeListForm.length; b++) {
          const cptFrm = <FormArray>this.CPTCodeListForm.controls[b];
          cptFrm.controls["Physician"].setValue(PhysicianName);
        }
      }


      const formdata = this.PatientsForm.value;

      delete formdata["physicianFirstName"];
      delete formdata["physicianLastName"];

      let relativeUrl = "CreatePatient/Update";
      let data = formdata;

      this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
        if (resp.status == "Success") {
          this.PatientsForm.reset();
          this.submit = "Submiting"
        }
        this.editEmitter.emit({
          'status': true,
          'data': resp
        });
        this.enableSubmitButton();
      }, (err) => {
        console.log(err);
        this.enableSubmitButton();
      })
    }
    else {
      this.enableSubmitButton();
      this.toastr.error("Please fill mandatory fields!!", 'Error!');
      return;
    }

  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: ['geocode']  // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      console.log(place)
      let emitData = {}
      for (let i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        console.log("----------------- Google Maps ---------------------------------------");
        console.log(place.address_components[i]);
        switch (addressType) {
          case 'street_number':
            /*case 'administrative_area_level_2':*/
            emitData['address1'] = place.address_components[i]['long_name'];
            break;
          case 'route':
            emitData['address2'] = place.address_components[i]['long_name'];
            break;
          case 'locality':
            emitData['city'] = place.address_components[i]['long_name'];
            break;
          case 'postal_code':
            emitData['zip'] = place.address_components[i]['short_name'];
            break;
          case 'administrative_area_level_1':
            emitData['state'] = place.address_components[i]['short_name'];
            break;
        }
      }
      if (emitData['address1'] != undefined && emitData['address2'] != undefined) {
        this.PatientsForm.controls['Add1'].setValue(emitData['address1'] + " " + emitData['address2'])
      } else if (emitData['address1'] != undefined) {
        this.PatientsForm.controls['Add1'].setValue(emitData['address1'])
      } else if (emitData['address2'] != undefined) {
        this.PatientsForm.controls['Add1'].setValue(emitData['address2'])
      }

      // this.PatientsForm.controls['Add2'].setValue(emitData['address2'])
      this.PatientsForm.controls['city'].setValue(emitData['city'])
      this.PatientsForm.controls['ZIP'].setValue(emitData['zip'])
      this.PatientsForm.controls['state'].setValue(emitData['state'])

    })
  }

  elmBlankVal(frmcntrl) {
    let val = this.PatientsForm.controls[frmcntrl].value;
    this.PatientsForm.controls[frmcntrl].setValue(val.trim());
  }

  elmInsBlankVal(frmcntrl, cnt) {
    const cntrl = <FormArray>this.PatientsForm.controls['InsuranceList'];
    let control = <FormArray>cntrl.controls[cnt];

    let val = control.controls[frmcntrl].value;
    control.controls[frmcntrl].setValue(val.trim());
  }

  chckMrn(): void {
    let fid;
    const frmCntrlVal = this.PatientsForm.controls["MRN"].value.trim();

    let data = {};

    if (this.formFlag == "Add") {
      fid = Number(this.PatientsForm.value.FacilityId);
      data = { "MRN": frmCntrlVal, "FacilityId": fid };
    } else {
      fid = this.PatientsForm.controls['FacilityId'].value;
      let pid = this.PatientsForm.controls['patientId'].value;
      data = { "MRN": frmCntrlVal, "FacilityId": fid, "patientId": pid };
    }

    if (fid != undefined) {

      let relativeUrl = "CreatePatient/CheckMRN";

      this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
        if (resp.status == "Success") {
          this.PatientsForm.controls['MRN'].setErrors(null);
        }
        else {
          this.PatientsForm.controls['MRN'].setErrors({
            unique: true
          });
        }
      }, (err) => {
        console.log(err);
      })

    } else {
    }
    this.enableSubmitButton();
  }



  searchVal(term: string) {
    if (term == '') {
      return of([]);
    }

    let fid;

    if (this.formFlag == "Add") {
      fid = this.PatientsForm.value.FacilityId.id;
      fid = this.PatientsForm.controls['FacilityId'].value;
    } else {
      fid = this.PatientsForm.controls['FacilityId'].value;
    }

    let data = { "CPTCode": term, "FacilityId": fid };

    return this.getApiService
      .post("CreatePatient/GetCPT", data).pipe(
        map(response => response.dataCPTInfo)
      );
  }//End of search

  formatter = (x: { cpt: string }) => x.cpt;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.searchVal(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  onSelectItem(event, id) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    frmCntrl.controls["CPTCode"].setValue(event.item.cpt);
    frmCntrl.controls["CPTDesc"].setValue(event.item.cptDesc);
    frmCntrl.controls["Rate"].setValue(event.item.rate);
    console.log(event);
  }

  chng(id) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    let vl = frmCntrl.controls["cpt"].value;
    try {
      if (vl.cpt == undefined) {

        this.searching = false;
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: true
        });
      }
      else {
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: false
        });
        frmCntrl.controls["CPTCode"].setValue(vl.cpt);
      }
    }
    catch {
      console.log("catch");
      frmCntrl.controls["CPTCode"].setValue("");
      frmCntrl.controls["CPTCode"].setErrors({
        notFound: true
      });
    }
  }

  

  transformAmount(event, index) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
    let amount = frmCntrl.controls["Rate"].value;
    if (isNaN(amount) && !(amount.match(/^\$/))) {
      frmCntrl.controls['Rate'].setValue("");
      frmCntrl.controls['Rate'].setErrors({ 'incorrect': true });
      // return false;
    } else {
      let formattedAmount = this.formatAmount(amount);
      // Remove or comment this line if you dont want to show the formatted amount in the textbox.
      event.target.value = formattedAmount;
      // this.chngRate(formattedAmount, index);
    }
  }
  getAmountFromFormattedCurrency(amount){
    const str =  (amount+"").toString().replace("$", "").replace(",", "");
    return Number(str);
  }
  formatAmount(amount){
    if(isNaN(amount)){
      const strAmount = this.getAmountFromFormattedCurrency(amount);
      return formatCurrency(strAmount, 'en-US', '$');  
    }
    return formatCurrency(amount, 'en-US', '$');
  }



}
