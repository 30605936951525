<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h6 class="m-0 font-weight-bold text-primary">Add Coding Status</h6>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <form class="pb-3 form-fields" [formGroup]="AddCodingStatusForm" 
            *ngIf="AddCodingStatusForm">
                
                <div class="row">
                    <div class="form-group col-6">
                        <label for="assignAll">
                            <label>Inquiry Status</label>
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="inquirystatus" placeholder="Inquiry Status"
                                required [ngClass]="{'is-invalid': submitted && f.inquirystatus.errors }">
                                <span *ngIf="submitted && f.inquirystatus.errors && f.inquirystatus.errors.required" class="invalid-feedback">
                                    Inquiry Status is mandatory!!
                                </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="priority">
                            <label>Priority</label>
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="priority" placeholder="Priority"
                                required [ngClass]="{'is-invalid': submitted && f.priority.errors }">
                                <span *ngIf="submitted && f.priority.errors && f.priority.errors.required" class="invalid-feedback">
                                    Priority is mandatory!!
                                </span>
                        </div>
                    </div>
                </div>
                <div class="text-right" style="margin: 75px 0px;">
                    <button type="button" (click)="onClickClose()"
                        class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                    <button type="button" (click)="onSubmit()" [disabled]="loading"
                        class="btn btn-primary btn-patient-box">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>