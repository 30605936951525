<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-700  active">
    <div
        class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
       
        <div class="row case-row">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-4">
                        <p class="head-name-case">MRN - </p>
                    </div>
                    <div class="col-md-8">
                        <p class="head-name-case">{{PatientSummaryDtls?.dataPatient[0]?.mrn}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 head-det-case">
                        <p class="head-det-case"><i class="fa fa-user mr-1"></i>
                            <span>{{PatientSummaryDtls?.dataPatient[0]?.patientName}}<span
                                    *ngIf="PatientSummaryDtls?.dataPatient[0]?.gender != null">({{PatientSummaryDtls?.dataPatient[0]?.gender}})</span>
                            </span>
                        </p>
                    </div>
                </div>
                <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'">
                    <div class="row">
                        <div class="col-md-12 head-det-case">
                            <p class="head-det-case">{{PatientSummaryDtls?.dataPatient[0]?.facilityName}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="head-det-case">Primary CPT:</p>
                        </div>
                        <div class="col-md-6">
                            <p class="head-det-case">{{primaryCPT }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 head-det-case">
                            <p class="head-det-case">Related CPTs:</p>
                        </div>
                        <div class="col-md-6 head-det-case">
                            <p class="head-det-case">
                                <span *ngFor="let d of patientCPTs">
                                    <span *ngIf="d != primaryCPT">
                                        {{d}},
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 head-det-case">
                            <p class="head-det-case"> <i class="fas fa-tools mr-1"></i> Tools and Templates</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-name-case">Personal Information</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Address</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.address1}}
                                {{PatientSummaryDtls?.dataPatient[0]?.address2}}
                            </span>
                        </p>
                    </div>

                </div>

                <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">City</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.city}}</span></p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">State</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.state}}
                                    {{PatientSummaryDtls?.dataPatient[0]?.zip}}</span></p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Phone No</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.phonenumber}}</span></p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">DOB</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.dob | date: "MM/dd/yyyy"}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">SSN No</span><span
                                    *ngIf="PatientSummaryDtls?.dataPatient[0]?.ssnNo != null"
                                    class="head-det-cse1">#{{PatientSummaryDtls?.dataPatient[0]?.ssnNo}}</span></p>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-name-case">Insurance</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2" style="margin-bottom: 20px;"><span
                                class="head-det-cse2">Primary</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.insuranceProvider}}</span>
                        </p>
                    </div>

                </div>

                <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Group #</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.groupNumber}}</span>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Insurance Id</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.insuranceId}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Physician</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.physician}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Date Of Surgery</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.dateOfSurgery}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Billed</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.billed}}</span></p>
                        </div>

                    </div>
                </div>
            </div>
            <p class="advance-icon-text plus-sign-bx1 expand-data"><i (click)="showPatientDetail()"
                    [ngClass]="smryIsToggled ? 'fa fa-minus showhideicn' : 'fa fa-plus showhideicn'"></i></p>
        </div>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav closeAction" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body pb-3 form-fields">
        <form [formGroup]="PatientsForm" (ngSubmit)="onSubmit()">
            <div class="container tab-remove">
                <div class="row">
                    <div class="col-md-3">

                        <ul class="nav">
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('OP Note', 'operatingReport', 'CONT1')"
                                    [checked]="dataQueueInfo['operatingReport']"
                                    [disabled]="dataQueueDtl['operatingReport'] ? true : null || dataQueueInfoTemp['operatingReport'] ? true : null">
                                OP Note </li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Pathology', 'pathology', 'CONT1')"
                                    [checked]="dataQueueInfo['pathology']"
                                    [disabled]="dataQueueDtl['pathology'] ? true : null || dataQueueInfoTemp['pathology'] ? true : null">Pathology
                            </li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Pricing', 'pricing', 'CONT1')"
                                    [checked]="dataQueueInfo['pricing']" [disabled]="dataQueueDtl['pricing']"
                                    [disabled]="dataQueueDtl['pricing'] ? true : null || dataQueueInfoTemp['pricing'] ? true : null">Pricing
                            </li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Block Report', 'blockReport', 'CONT1')"
                                    [checked]="dataQueueInfo['blockReport']"
                                    [disabled]="dataQueueDtl['blockReport'] ? true : null || dataQueueInfoTemp['blockReport'] ? true : null">Block
                                Report</li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Coding Query', 'codingQuery', 'CONT1')"
                                    [checked]="dataQueueInfo['codingQuery']"
                                    [disabled]="dataQueueDtl['codingQuery'] ? true : null || dataQueueInfoTemp['codingQuery'] ? true : null">Coding
                                Query</li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Other/Misc', 'otherMisc', 'CONT1')"
                                    [checked]="dataQueueInfo['otherMisc']"
                                    [disabled]="dataQueueDtl['otherMisc'] ? true : null || dataQueueInfoTemp['otherMisc'] ? true : null">Other/Misc
                            </li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Client High$$$', 'highApproval', 'CONT2')"
                                    [checked]="dataQueueInfo['highApproval']"
                                    [disabled]="dataQueueDtl['highApproval'] ? true : null || dataQueueInfoTemp['highApproval'] ? true : null">Client
                                High$$$
                                <span class="small_i">
                                    <!-- <i (click)="fetchDollarData('Client High$$$', 'highApproval', 'CONT2')"
                                        [style.display]="clntNsnInfo ? 'block' : 'none'" class="fa fa-info-circle"></i> -->
                                    <a href="javascript:void(0)"
                                        (click)="fetchDollarData('Client High$$$', 'highApproval', 'CONT2')"
                                        *ngIf="stat != '' && highApprovalinternal == 0" class="test_a">{{stat}}</a>
                                </span></li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Coding Variance Review', 'variance', 'CONT3')"
                                    [checked]="dataQueueInfo['variance']"
                                    [disabled]="dataQueueDtl['variance'] ? true : null || dataQueueInfoTemp['variance'] ? true : null">Variance
                                <span class="small_i">
                                    <!-- <i [style.display]="varianceInfo ? 'block' : 'none'" class="fa fa-info-circle"></i> -->
                                    <a href="javascript:void(0)"
                                        (click)="fetchDollarData('Coding Variance Review', 'variance', 'CONT3')"
                                        *ngIf="varianceInfo" class="test_a">{{varStat}}</a>
                                </span>
                            </li>
                            <li class="casetab mt-3">
                                <input type="checkbox" (click)="setTab('In Coding', 'inCoding', 'CONT1')"
                                    [checked]="dataQueueInfo['inCoding']"
                                    [disabled]="dataQueueDtl['inCoding'] ? true : null || dataQueueInfoTemp['inCoding'] ? true : null">In
                                Coding</li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab(' High$$$', 'highApprovalInternal', 'CONT2')"
                                    [checked]="dataQueueInfo['highApprovalInternal']"
                                    [disabled]="dataQueueDtl['highApprovalInternal'] ? true : null || dataQueueInfoTemp['highApprovalInternal'] ? true : null">
                                High$$$
                                <span class="small_i">
                                    <!-- <i [style.display]="highNsnInfo ? 'block' : 'none'" class="fa fa-info-circle"></i> -->
                                    <a href="javascript:void(0)"
                                        (click)="fetchDollarData(' High$$$', 'highApprovalInternal', 'CONT2')"
                                        *ngIf="stat != '' && highApprovalinternal == 1" class="test_a">{{stat}}</a>
                                </span>
                            </li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Auth Review', 'authReview', 'CONT1')"
                                    [checked]="dataQueueInfo['authReview']"
                                    [disabled]="dataQueueDtl['authReview'] ? true : null || dataQueueInfoTemp['authReview'] ? true : null">Auth
                                Review</li>
                            <li class="casetab">
                                <input type="checkbox" (click)="setTab('Coding Expert', 'codingExpert', 'CONT1')"
                                    [checked]="dataQueueInfo['codingExpert']"
                                    [disabled]="dataQueueDtl['codingExpert'] ? true : null || dataQueueInfoTemp['codingExpert'] ? true : null">Coding
                                Expert</li>
                        </ul>
                        <div class="row">
                            <div class="col-md-6"><a href="javascript:void(0)" (click)="unlockReq = true"
                                    class="caselink">Override</a></div>
                            <div class="col-md-6"><a href="javascript:void(0)" (click)="unlockRequest()"
                                    *ngIf="unlockReq" class="caselink">Unlock</a></div>
                        </div>

                    </div>
                    <div class="col-md-9">
                        <div class="tab-content">
                            <div *ngIf="this.tabVal['CONT3'] == true" id="CONT3" class="tab-pane fade in active show">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="high-section-textt"> {{tabHead}}</p>
                                    </div>

                                </div>
                                <div class="row highrow">
                                    <div class="col-lg-5 col-md-6">
                                        <p class="ext-case-pad"><strong> Coding</strong></p>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <p><strong>Physician Coding</strong></p>
                                    </div>
                                </div>
                                <div class="row highrow">
                                    <div class="col-md-5 row">
                                        <div class="col-lg-4 col-md-6">
                                            <p>CPT</p>
                                        </div>
                                        <div class="col-lg-8 col-md-6">
                                            <p>Description</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6 row">
                                        <div class="col-lg-4 col-md-6">
                                            <p>CPT</p>
                                        </div>
                                        <div class="col-lg-8 col-md-6">
                                            <p>Description</p>
                                        </div>
                                    </div>
                                </div>
                                <div formArrayName="VarianceList">
                                    <div *ngFor="let vl of VarianceListForm.controls; let c=index">
                                        <div class="row" [formGroupName]="c">
                                            <div class="col-lg-5 row">
                                                <div
                                                    class="form-group remove-botttom-space align-input-user col-lg-4 col-md-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            [class.is-invalid]="searchFailed" [ngbTypeahead]="search"
                                                            placeholder="CPT Code" formControlName="nsn_cpt"
                                                            [resultFormatter]="formatter" [inputFormatter]="formatter"
                                                            (selectItem)="onVarSelectItem($event, c, 'nsn_cpt')"
                                                            (change)="chngVariance(c, 'nsn_cpt')" /><br>

                                                    </div>
                                                    <small *ngIf="searching"
                                                        class="form-text text-muted">searching...</small>
                                                    <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                                                        suggestions
                                                        could not
                                                        be
                                                        loaded.</div>
                                                </div>
                                                <div
                                                    class="form-group remove-botttom-space align-input-user col-lg-8 col-md-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="nsn_Desc" placeholder="CPT Desc">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-5 row">
                                                <div
                                                    class="form-group remove-botttom-space align-input-user col-lg-4 col-md-6">
                                                    <div class="input-group">
                                                        <!-- <input id="physician_cptt" type="text" class="form-control"
                                                            placeholder="CPT Code" formControlName="physician_cpt"> -->
                                                        <div class="input-group">
                                                            <input type="text" class="form-control"
                                                                [class.is-invalid]="searchFailed"
                                                                [ngbTypeahead]="search" placeholder="CPT Code"
                                                                formControlName="physician_cpt"
                                                                [resultFormatter]="formatter"
                                                                [inputFormatter]="formatter"
                                                                (selectItem)="onVarSelectItem($event, c, 'physician_cpt')"
                                                                (change)="chngVariance(c, 'physician_cpt')" /><br>
                                                            <!--   -->
                                                        </div>
                                                        <small *ngIf="searching"
                                                            class="form-text text-muted">searching...</small>
                                                        <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                                                            suggestions
                                                            could not
                                                            be
                                                            loaded.</div>

                                                        <!-- <input id="typeahead-http" type="text" class="form-control"
                                                            [class.is-invalid]="searchFailed" [ngbTypeahead]="search"
                                                            placeholder="CPT Code" formControlName=""
                                                            [resultFormatter]="formatter" [inputFormatter]="formatter"
                                                            (selectItem)="onSelectItem($event, b)"
                                                            (change)="chng(b)" /><br> -->
                                                    </div>
                                                    <!-- <small *ngIf="searching"
                                                        class="form-text text-muted">searching...</small>
                                                    <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                                                        suggestions
                                                        could not
                                                        be
                                                        loaded.</div> -->
                                                </div>
                                                <div
                                                    class="form-group remove-botttom-space align-input-user col-lg-8 col-md-6">
                                                    <div class="input-group">
                                                        <input type="text" class="form-control"
                                                            formControlName="physician_Desc" placeholder="CPT Desc">
                                                    </div>
                                                </div>

                                            </div>

                                            <div
                                                class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                                                <div class="input-group">
                                                    <button type="button" (click)="addVarianceList()"
                                                        class="btn btn-primary plus-btn btn-add-user btn-patient-box mr-1">
                                                        <i class="fas fa-plus"></i></button>
                                                    <button *ngIf="c > 0" type="button" (click)="removeVarianceList(c)"
                                                        class="btn btn-primary btn-color minus-btn btn-patient-box">
                                                        <i class="fas fa-minus"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group frm-margin-btm col-md-12" style="padding-right: 5px;">
                                        <label for="inputFirstName" class="lbl-font-600">Variance
                                            Request</label>
                                        <textarea class="form-control" aria-describedby="FirstNameHelp"
                                            formControlName="txtNote" placeholder="Comment" required></textarea>
                                    </div>
                                    <div class="form-group frm-margin-btm col-md-12">
                                        <input type="file" formControlName="reqFile" (change)="fileChanged($event)"
                                            class="form-control-file py-1">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 frm-margin-btm btn-remove-pdng">
                                        <div class="text-right">
                                            <button type="button" (click)="reqCancel()"
                                                class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                                            <button type="button" (click)="setRequest()"
                                                class="btn btn-primary btn-width-cmn btn-patient-box">Request</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div *ngIf="varianceInfo == true" class="form-group frm-margin-btm col-md-12"
                                        style="padding-right: 5px;">
                                        <label for="inputFirstName" class="lbl-font-600">Approval Comments</label>
                                        <textarea class="form-control" [(ngModel)]="txtApproval"
                                            [ngModelOptions]="{standalone: true}" placeholder="Comment"
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="row" *ngIf="varianceInfo == true">
                                    <div class="col-md-12 frm-margin-btm btn-remove-pdng">
                                        <div class="text-right">
                                            <button type="button" (click)="setStatus('Rejected')"
                                                class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Reject</button>
                                            <button type="button" (click)="setStatus('Approved')"
                                                class="btn btn-primary btn-width-cmn btn-patient-box">Approve</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="this.tabVal['CONT2'] == true" id="CONT2" class="tab-pane fade in active show">
                                <div class="row">
                                    <div class="col-md-8">
                                        <p class="high-section-textt"> {{tabHead}} Total</p>
                                    </div>
                                    <div class="col-md-4">
                                        <p class="high-sect-amount">${{totalCost}}</p>
                                    </div>
                                </div>
                                <div class="row highrow">
                                    <div class="col-lg-3 col-md-6">
                                        <p class="ext-case-pad">CPT</p>
                                    </div>
                                    <div class="col-lg-5 col-md-6">
                                        <p>CPT Descrption</p>
                                    </div>
                                    <div class="col-lg-2 col-md-6">
                                        <p>$$$</p>
                                    </div>
                                </div>
                                <div formArrayName="CPTCodeList">
                                    <div *ngFor="let af of CPTCodeListForm.controls; let b=index">
                                        <div class="row" [formGroupName]="b">
                                            <div
                                                class="form-group remove-botttom-space align-input-user col-lg-3 col-md-6">
                                                <div class="input-group">
                                                    <label class="case-aug-num">#{{b+1}}</label>
                                                    <input type="text" class="form-control"
                                                        [class.is-invalid]="searchFailed" [ngbTypeahead]="search"
                                                        placeholder="CPT Code" formControlName="cpt"
                                                        [resultFormatter]="formatter" [inputFormatter]="formatter"
                                                        (selectItem)="onSelectItem($event, b)" (change)="chng(b)" /><br>
                                                </div>
                                                <small *ngIf="searching"
                                                    class="form-text text-muted">searching...</small>
                                                <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                                                    suggestions
                                                    could not
                                                    be
                                                    loaded.</div>
                                                <div *ngIf="af.controls.CPTCode.errors?.notFound"
                                                    class="invalid-feedback">
                                                    <p>Invalid
                                                        CPT code.</p>
                                                </div>
                                            </div>
                                            <div
                                                class="form-group remove-botttom-space align-input-user col-lg-5 col-md-6">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" formControlName="CPTDesc"
                                                        placeholder="CPT Desc">
                                                </div>
                                            </div>
                                            <div
                                                class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" formControlName="Rate"
                                                        placeholder="$0.00">
                                                </div>
                                            </div>
                                            <div
                                                class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                                                <div class="input-group">
                                                    <button type="button" (click)="addCptCodeList()"
                                                        class="btn btn-primary plus-btn btn-add-user btn-patient-box mr-1">
                                                        <i class="fas fa-plus"></i></button>
                                                    <button *ngIf="b > 0" type="button" (click)="removeCptCodeList(b)"
                                                        class="btn btn-primary btn-color minus-btn btn-patient-box">
                                                        <i class="fas fa-minus"></i></button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group frm-margin-btm col-md-12" style="padding-right: 5px;">
                                        <label for="inputFirstName" class="lbl-font-600">{{tabHead}}
                                            Request</label>
                                        <textarea class="form-control" aria-describedby="FirstNameHelp"
                                            formControlName="txtNote" placeholder="Comment" required></textarea>
                                    </div>
                                    <div class="form-group frm-margin-btm col-md-12">
                                        <input type="file" formControlName="reqFile" (change)="fileChanged($event)"
                                            class="form-control-file py-1">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 frm-margin-btm btn-remove-pdng">
                                        <div class="text-right">
                                            <button type="button" (click)="reqCancel()"
                                                class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                                            <button type="button" (click)="setRequest()"
                                                class="btn btn-primary btn-width-cmn btn-patient-box">Request</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div *ngIf="(clntNsnInfo == true || highNsnInfo == true) && stat == 'Pending'"
                                        class="form-group frm-margin-btm col-md-12" style="padding-right: 5px;">
                                        <label for="inputFirstName" class="lbl-font-600">Approval Comments</label>
                                        <textarea class="form-control" [(ngModel)]="txtApproval"
                                            [ngModelOptions]="{standalone: true}" placeholder="Comment"
                                            required></textarea>
                                    </div>
                                </div>
                                <div class="row">
                                    <div *ngIf="(clntNsnInfo == true || highNsnInfo == true) && stat == 'Pending'"
                                        class="col-md-12 frm-margin-btm btn-remove-pdng">
                                        <div class="text-right">
                                            <button type="button" (click)="setStatus('Rejected')"
                                                class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Reject</button>
                                            <button type="button" (click)="setStatus('Approved')"
                                                class="btn btn-primary btn-width-cmn btn-patient-box">Approve</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.tabVal['CONT1'] == true" id="CONT1" class="tab-pane fade in active show">
                                <div class="row">
                                    <div class="col-md-5">
                                        <p class="high-section-textt" *ngIf="tabHead != ''">
                                            {{tabHead}} Request
                                        </p>
                                    </div>
                                    <div class="col-md-7" *ngIf="tabHead == 'In Coding'">
                                        <span class="mr-2"> <input type="checkbox" (change)="fileSuffix = '_OP'"
                                                class="mr-1">_OP</span>
                                        <span class="mr-2"> <input type="checkbox" (change)="fileSuffix = '_PATH'"
                                                class="mr-1">_PATH</span>
                                        <span class="mr-2"> <input type="checkbox" (change)="fileSuffix = '_QUE'"
                                                class="mr-1">_QUE</span>
                                        <span class="mr-2"> <input type="checkbox" (change)="fileSuffix = '_AOP'"
                                                class="mr-1">_AOP</span>
                                    </div>

                                </div>
                                <div class="row  mb-2" *ngIf="tabHead == 'Coding Expert'">
                                    <div class="col-md-6"><input type="checkbox" class="mr-1">LCD Review</div>
                                    <div class="col-md-6"><input type="checkbox" class="mr-1">SCS Review</div>
                                    <div class="col-md-6"><input type="checkbox" class="mr-1">Unlisted Review
                                    </div>
                                    <div class="col-md-6"><input type="checkbox" class="mr-1">Other/Misc Review
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group frm-margin-btm col-md-12" style="padding-right: 5px;">
                                        <textarea class="form-control" formControlName="txtNote" placeholder="Comment"
                                            required></textarea>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <input type="file" formControlName="reqFile" (change)="fileChanged($event)"
                                            class="form-control-file py-1">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 frm-margin-btm btn-remove-pdng">
                                        <div class="text-right">
                                            <button type="button" (click)="reqCancel()"
                                                class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                                            <button type="button" (click)="setRequest()"
                                                class="btn btn-primary btn-width-cmn btn-patient-box">Request</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="this.tabVal['CONT0'] == true" id="CONT0" class="tab-pane fade in active show">

                                <p *ngIf="(dataQueueDtl | json) == '{}'">There are no pending requests for this case.
                                </p>


                                <p>Select your request from the list on the left.</p>
                            </div>

                        </div>
                        <div *ngIf="requestArry?.length > 0 && (requestArry | json) != '{}'"
                            class="row biller-pending-brder">
                            <hr>
                            <div class="col-md-12">
                                <p class="high-section-textt">
                                    Pending Changes
                                </p>
                            </div>
                            <hr>

                            <div class="col-md-3" *ngFor="let m of requestArry">
                                <div *ngIf="(requestArry | json) != null">
                                    <p class="history-head">Request {{m?._requestType}}
                                        <button type="button" (click)="delChanges(m?._requestType)"
                                            class="btn btn-primary btn-color minus-btn-request btn-patient-box"> <i
                                                class="fas fa-minus"></i></button> </p>
                                    <p class="history-detailed file-overlap-name">{{m?._requestFileName}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row mt-2">
                <div class="col-md-7">
                    <div class="d-flex biller3btn text-left ">
                        <!-- <button [style.display]="(_commandType =='casebilled' || _commandType =='Completed') ? 'none' : ''"  type="button" data-toggle="modal" data-target="#deleteModal"
                            class="btn btn-secondary btn-patient-box patient-btn-color1 mr-1"><i class="fa fa-trash" title="Delete"
                                aria-hidden="true"></i>
                            DELETE CASE</button>
                        <button [style.display]="(_commandType =='casebilled' || _commandType =='Completed') ? 'none' : ''"  type="button" data-toggle="modal" data-target="#cancelModal"
                            class="btn btn-secondary btn-patient-box patient-btn-color-case closeMySidenav mr-1"><i
                                class="fa fa-times" aria-hidden="true"></i>
                            CASE CANCELLED</button>
                        <button [style.display]="(_commandType =='casebilled' || _commandType =='Completed') ? 'none' : ''" type="button" *ngIf="!billedInfo" data-toggle="modal" (click)='showSetBilled()' 
                            class="btn btn-primary btn-patient-box patient-btn-color2 mr-1"><i class="fa fa-check"
                                aria-hidden="true"></i>
                            SET BILLED</button>
                        <button [style.display]="(_commandType =='casebilled' || _commandType =='Completed') ? 'none' : ''" type="button" *ngIf="billedInfo" (click)="showUnSetBilled()"
                            class="btn btn-primary btn-patient-box patient-btn-color2 mr-1"><i class="fa fa-check"
                                aria-hidden="true"></i>
                            UNDO SET BILLED</button> -->
                            <button type="button" [disabled]="todaydate >= newdate" data-toggle="modal" data-target="#deleteModal"
                            class="btn btn-secondary btn-patient-box patient-btn-color1 mr-1"><i class="fa fa-trash" title="Delete"
                                aria-hidden="true"></i>
                            DELETE CASE</button>
                        <button  type="button" [disabled]="todaydate >= newdate" data-toggle="modal" data-target="#cancelModal"
                            class="btn btn-secondary btn-patient-box patient-btn-color-case closeMySidenav mr-1"><i
                                class="fa fa-times" aria-hidden="true"></i>
                            CASE CANCELLED</button>
                        <button *ngIf="privileges.dSetBilled && !billedInfo" type="button" [style.display]="(_commandType =='casebilled' || _commandType =='Completed') ? 'none' : ''"  data-toggle="modal" (click)='showSetBilled()' 
                            class="btn btn-primary btn-patient-box patient-btn-color2 mr-1"><i class="fa fa-check"
                                aria-hidden="true"></i>
                            SET BILLED</button>
                        <button *ngIf=" privileges.dUndoSetBilled && billedInfo" type="button" [disabled]="todaydate >= newdate"  [style.display]="(_commandType =='casebilled' || _commandType =='Completed') ? '' : 'none'" (click)="showUnSetBilled()"
                            class="btn btn-secondary btn-patient-box patient-btn-color-case closeMySidenav mr-1"><i class="fa fa-times"
                                aria-hidden="true"></i>
                            UNDO SET BILLED</button>
                    </div>
                </div>
                <div class="col-md-5 btn-remove-pdng">
                    <div class="text-right">
                        <button type="button" (click)="onClickClose()"
                            class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                        <button type="submit" class="btn btn-primary btn-width-cmn btn-patient-box">Submit</button>
                    </div>
                </div>
            </div>
        </form>
        <div class="row">
            <div class="col-md-4">
                <!-- <div class="row"> -->
                <div class="form-group frm-margin-btm">
                    <label for="inputFirstName">Notes</label>
                    <textarea class="form-control form-control1" [(ngModel)]="note" required rows="4"
                        cols="3"></textarea>
                </div>
            </div>
            <div class="form-group frm-margin-btm variance-lbl frm-padng-top col-md-3">
                <input type="radio" id="hold" name="note" value="Private" [(ngModel)]="noteType">
                <label for="private">Private</label>
                <input type="radio" id="reject" name="note" value="Public" [(ngModel)]="noteType">
                <label for="public">Public</label>
            </div>
            <div class="form-group frm-margin-btm casecattab col-md-5">
                <div class="input-group">

                    <select class="custom-select form-control" [(ngModel)]="noteSelect">
                        <option selected Value="General Note">General Note</option>
                        <ng-container *ngFor="let d of dataQueue">
                            <option *ngIf="dataQueueInfo[d.value] == true" [value]="d.key">{{d.key}}</option>
                        </ng-container>


                    </select>
                </div>
                <button type="button" (click)="addNotes()" class="btn btn-primary btn-patient-box">Add</button>
            </div>
        </div>
        <div *ngIf="PatientSummaryDtls?.dataFileHistoryInfo.length > 0;else other_content">
            <div *ngFor="let d of PatientSummaryDtls?.dataFileHistoryInfo">
                <div class="row casehistory" *ngIf="d.billerNote != '' || d.clientNote != ''">
                    <div class="col-md-8">
                        <!-- <p class="history-head">{{(d.billerName || d.clientName)| titlecase}}</p> -->
                        <p class="history-head">{{(d.billerName || d.clientName)}}</p>
                        <p class="history-dttttime">{{d.billerTime || d.clientTime}}</p>
                    </div>
                    <div class="col-md-4">
                        <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i>
                            {{d.auditEventType}}<br>
                            <strong> {{d.auditEventDescription}}</strong><br>
                            <a href="javascript:void(0)"
                                (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a>
                        </p>
                    </div>
                    <div class="col-md-12">
                        <p class="history-detailed">
                            <strong
                                *ngIf="d.billerNote != '' || d.clientNote != ''">"{{d.billerNote || d.clientNote}}"</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <ng-template #other_content>
            <div class="row casehistory">
                <div class="col-md-8">
                    <p class="history-head"></p>
                    <p class="history-dttttime"></p>
                </div>
            </div>
        </ng-template>
        <div *ngIf="privileges.dUpload" class="row" style="padding-top: 20px;">
            <div class="col-md-4">
                <p class="comment-heading-case">Attachments</p>
                <h4 class="">Upload File</h4>
                <input type="file" [(ngModel)]="uploadFile" (change)="fileAttachChanged($event)"
                    class="form-control-file py-1 file-overlap-name">
            </div>
            <div class="form-group frm-margin-btm caseatselect col-md-8">
                <div class="input-group">
                    <input type="text" [(ngModel)]="uploadFileText" class="form-control" style="margin-right: 10px;">
                    <!-- <select class="custom-select form-control" style="margin-right: 10px;">
                        <option selected>File Type</option>
                        <option Value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                        <option value="4">Four</option>
                        <option value="5">Five</option>
                        <option value="6">Six</option>
                        <option value="7">Seven</option>
                        <option value="8">Eight</option>
                        <option value="9">Nine</option>
                        <option value="10">Ten</option>
                    </select> -->
                    <button type="button" (click)="uploadAttachment()"
                        class="btn btn-primary btn-patient-box">Upload</button>
                </div>
            </div>
        </div>
        <div class="row" style="padding-top: 20px;">
            <div class="col-md-12">
                <div class="row back-color-comnt1">
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">Date</p>
                    </div>
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">File Type</p>
                    </div>
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">User</p>
                    </div>
                    <div class="form-group1 col-md-4">
                        <p class="patient-tab-above">File Details</p>
                    </div>
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">Status</p>
                    </div>
                </div>
                <div class="patient-center-space">

                    <div class="row" *ngFor="let d of PatientSummaryDtls?.dataFileHistoryInfo">
                        <div *ngIf="d.contentFileName != null" class="form-group col-md-12 frm-margin-btm data-bottom">
                            <p class="ipt2-1">{{d.billerTime|| d.clientTime}}</p>
                            <p class="ipt2-1">{{d.auditEventDescription}} </p>
                            <p class="ipt2-1">{{d.billerName || d.clientName}}</p>
                            <p class="ipt2-2"><a href="javascript:void(0)"
                                    (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a></p>
                            <p class="ipt2-4">{{d.auditEventType}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->

<div class="modal fade" id="cancelModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirm Cancelled Case?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">Are you sure you would like to cancel this case?</div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Wait</button>
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="caseCancelled()">Confirm</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="setBilledModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">SPECIFY TYPE</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <h6>Please specify the case type</h6>
                <div class="row">
                    <div class="col-md-4 text-right mt-2">
                        <label><strong>Case Charge $</strong></label>
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" [(ngModel)]="billAmount" placeholder="0.00">
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="setBilled('Electronic')">Electronic</button>
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="setBilled('Paper')">Paper</button>
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="setBilled('SelfPay')">Self-Pay</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Delete This Case?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <h6>Are you sure you would like to delete this case?</h6>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-dismiss="modal"
                    (click)="deleteCase()">Confirm</button>
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
</div>

<app-unset-billed-action [formData]="_formData" *ngIf="showUnSetBilledForm"  (closeEmitter)="onCloseFormModal()"
(addedEmitter)="onAddRecord($event)"></app-unset-billed-action>

<app-claim-type-action [formData]="_formData" *ngIf="showSetBilledForm"  (closeEmitter)="onCloseFormModal()"
(addedEmitter)="onAddRecord($event)">
</app-claim-type-action>
