import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BillReason } from './bill-reason.model';

export interface BillReasonState extends EntityState<BillReason, number>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bill-reason' })
export class BillReasonStore extends EntityStore<BillReasonState> {

  constructor() {
    super();
  }

}
