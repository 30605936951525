import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { GroupsQuery } from 'src/app/shared/state/groups/groups.query';
import { Observable } from 'rxjs';
import { createFacility, Facility } from 'src/app/shared/state/facilities/facility.model';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { createPayer, Payer } from 'src/app/shared/state/payers/payer.model';
import { createGroup, Group } from 'src/app/shared/state/groups/group.model';
import { PayersQuery } from 'src/app/shared/state/payers/payers.query';
import { PhysicianQuery } from 'src/app/shared/state/physician/physician.query';
import { createPhysician, Physician } from 'src/app/shared/state/physician/physician.model';
import { PhysicianService } from 'src/app/shared/state/physician/physician.service';
import { createCpt, Cpt } from 'src/app/shared/state/cpt/cpt.model';
import { CptService } from 'src/app/shared/state/cpt/cpt.service';
import { CptQuery } from 'src/app/shared/state/cpt/cpt.query';

@Component({
  selector: 'app-billing-rules-form',
  templateUrl: './billing-rules-form.component.html',
  styleUrls: ['./billing-rules-form.component.css']
})
export class BillingRulesFormComponent implements OnInit {
  billingRulesForm: FormGroup;
  
  facilitiesOfgroup$: Observable<Facility[]>;
  payerOfFacility$: Observable<Payer[]>;
  physicianOfFacility$: Observable<Physician[]>;
  financialClass$: Observable<Payer[]>;
  cptOfFacility$: Observable<Cpt[]>;

  @Output() editEmitter = new EventEmitter();
  @Output() addedEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();

  formFlag: string = "Add";
  _formData: any;
  submitted = false;
  facilityGroupArry: Group[];
  @Input() set formData(value: any) {

    if (!isNullOrUndefined(value)) {
      console.log('edit', value);
      this._formData = value;
      this.formFlag = "Edit";
      this.groupService.setActive([this._formData.groupid]);
      //initializing facility store and making active
      this.facilityService.setFacilityGroup([this._formData.groupid]);
      this.facilityService.setActive([this._formData.facilityId]);

      //initializing the payer store and making assigned as active
      if(this._formData.payer_FinancialClass){
        this.payerService.setPayerFinancialClassAndFacility(this._formData.facilityId, this._formData.payer_FinancialClass);
      }else{
      this.payerService.setPayerFacility([this._formData.facilityId]);
      }
      this.payerService.setActive([this._formData.payerId]);

      // initialising physician store and making assigned as active
      this.physicianService.setPhysicianFacility([this._formData.facilityId]);
      this.physicianService.setActive([this._formData.physicianId]);

      //initializing financial class
      this.financialClass$ = this.payerService.getPayerData(this._formData.facilityId);

      //initializing cpt store by facility class
      this.cptService.setCptFacility([this._formData.facilityId]);
      this.editForm();
    }
    else {
      this.formFlag = "Add";
      this.initForm();
    }
  }

  dataRegionList: any[];
  dataCenterList=[];
  Electronic:boolean = false;
  Paper:boolean = false;
  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private groupsQuery: GroupsQuery,
    private groupService: GroupsService, 
    private facilityService: FacilityService,
    private facilityQuery: FacilityQuery,
    private payerService: PayersService,
    private payerQuery: PayersQuery,
    private physicianService: PhysicianService,
    private physicianQuery: PhysicianQuery,
    private cptService: CptService,
    private cptQuery: CptQuery
    ) { }

  ngOnInit(): void {
    this.getfacilityGroup();
    this.facilitiesOfgroup$ = this.facilityQuery.selectAll();
    this.payerOfFacility$ = this.payerQuery.selectAll();
    this.physicianOfFacility$ = this.physicianQuery.selectAll();
    this.cptOfFacility$ = this.cptQuery.selectAll();
  }

  get f() { return this.billingRulesForm.controls; }

  addRecord() {
    this.submitted = true;
    if (this.billingRulesForm.invalid) {
      return;
    }
    let data = this.billingRulesForm.getRawValue();
 
    if(this.formFlag == "Edit"){
      data = {
        "ruleName":this.billingRulesForm.controls["RuleName"].value,
        "FacilityId":this.billingRulesForm.controls["Facility"].value.id,
        "PayerId":this.billingRulesForm.controls["Payer"].value.id,
        "PhysicianId":this.billingRulesForm.controls["Physician"].value.id,
        "CPTId":this.billingRulesForm.controls["CPT"].value.cpt,
        "ruleText":this.billingRulesForm.controls["RuleText"].value,
        "groupid": this.billingRulesForm.controls["Group"].value.id,
        "id" : this.billingRulesForm.controls["RuleId"].value
      }
    }else{
    data = {
      "ruleName":this.billingRulesForm.controls["RuleName"].value,
      "FacilityId":this.billingRulesForm.controls["Facility"].value.id,
      "PayerId":this.billingRulesForm.controls["Payer"].value.id,
      "PhysicianId":this.billingRulesForm.controls["Physician"].value.id,
      "CPTId":this.billingRulesForm.controls["CPT"].value.cpt,
      "ruleText":this.billingRulesForm.controls["RuleText"].value,
      "groupid": this.billingRulesForm.controls["Group"].value.id
    
    }
  }
  console.log('Save Rule Request', data);
    this.getApiService.post("Rules/SaveRules", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.billingRulesForm.reset();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })

  }



  onCancel() {
    this.onClickClose();
  }

  onClickClose() {
    this.closeEmitter.emit();
    this.groupService.inActiveAll();
    this.facilityService.inActiveAll();
  }


  initForm() {
    this.billingRulesForm = this.fb.group({
      RuleName: ["", [Validators.required]],
      Group: [""],
      Facility: ["",],
      FinancialClass: [""],
      Payer: ["",],
      Physician: ["",],
      CPT: ["",],
      RuleText: ["", Validators.required],
      TypeElectronic: ["",],
      TypePaper: ["",],
    })
  }

  editForm() {
    console.log('Edit Form ', this._formData);
    const group = createGroup({ id: this._formData.groupid, groupName: this._formData.groupName});
    const physician = createPhysician({ id: this._formData.physicianId, fullName: this._formData.physicianName });
    const payer = createPayer({ id: this._formData.payerId, payer_Name: this._formData.payerName });
    const facility = createFacility({ id: this._formData.facilityId, name: this._formData.facilityName });
    const financialClassPayer = createPayer({ payer_FinancialClass: this._formData.payer_FinancialClass });
    const cpt = createCpt({ cpt: this._formData.cptId, cptDesc: this._formData.cptName });

    this.billingRulesForm = this.fb.group({
      RuleId: [this._formData.id,],
      RuleName: [this._formData.ruleName, [Validators.required]],
      Group: [group],
      Facility: [facility],
      FinancialClass: [financialClassPayer],
      Payer: [payer],
      Physician: [physician],
      CPT: [cpt],
      RuleText: [this._formData.ruleText, Validators.required],
      TypeElectronic: [this._formData.electronic,],
      TypePaper: [this._formData.paper,],
    });

     console.log('FORM:: ', this.billingRulesForm);

    if(this._formData.paper == 1){
      this.Paper= true;
    }else{
      this.Paper = false;
    }
    if(this._formData.electronic == 1){
      this.Electronic= true;
    }else{
      this.Electronic = false;
    }


  }

  compare(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.id === val2.id;
  }

  compareFinancialClass(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.payer_FinancialClass === val2.payer_FinancialClass;
  }

  compareCPT(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.cpt === val2.cpt;
  }
  
  getfacilityGroup() {
    this.facilityGroupArry = this.groupsQuery.getAll();
  }

  onGroupChange($event){
    const group: Group = this.billingRulesForm.controls['Group'].value;
    console.log('onGroupChange -> ', group);
    this.groupService.setActive([group.id]);
    this.facilityService.setFacilityGroup([group.id]);
  }

  onFacilityChange($event){
    const facility: Facility = this.billingRulesForm.controls['Facility'].value;
    console.log('onFacilityChange -> ', facility);
    this.facilityService.setActive([facility.id]);
    this.financialClass$ = this.payerService.getPayerData(facility.id);
    this.payerService.setPayerFacility([facility.id]);
    this.physicianService.setPhysicianFacility([facility.id]);
    this.cptService.setCptFacility([facility.id]);
  }

  onFinancialClassChange($event){
    const payerFinancialClass: Payer = this.billingRulesForm.controls['FinancialClass'].value;
    console.log('onFinancialClassChange -> ', payerFinancialClass);
    let facilityId = this.facilityQuery.getActiveId();
    console.log('onFinancialClassChange facility id-> ', facilityId);
    let fid;
    if(Array.isArray(facilityId))
     fid = facilityId[0];
    else
    fid = facilityId;

    this.payerService.setPayerFinancialClassAndFacility(fid, payerFinancialClass.payer_FinancialClass);
  }

  onPayerChange($event){
    const payer: Payer = this.billingRulesForm.controls['Payer'].value;
    console.log('onPayerChange -> ', payer);
    this.payerService.setActive([payer.id]);
  }

  onPhysicianChange($event){
    const physician: Physician = this.billingRulesForm.controls['Physician'].value;
    console.log('onPhysicianChange -> ', physician);
    this.payerService.setActive([physician.id]);
  }

  onCptChange(){
    const cpt: Cpt = this.billingRulesForm.controls['CPT'].value;
    console.log('onCptChange -> ', cpt);
    this.cptService.setActive([cpt.cpt]);
  }
}
