<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Fee Schedule Import</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="form-group col-12 row">
            <label for="selectFacility" class="required">Facility</label>
            <div class="input-group">
                <select [(ngModel)]="facility" class="custom-select form-control">
                    <option selected value="" disabled>Select Facility</option>
                    <option value="0">All Facilities</option>
                    <option *ngFor="let data of facilityList" value="{{data.id}}">{{data.name}}</option>
                </select>
                <div *ngIf="submitted && facility == ''" class="invalid-feedback">
                    <p>*mandatory</p>
                </div>
            </div>
        </div>
        <div class="pb-3 form-fields">
            <h4 class="my-3">Upload File</h4>
            <!-- <form action=""> -->
            <div class="row">
                <div class="form-group col-12">
                    <input type="file" accept=".csv" class="form-control-file py-1" #feeScheduleFile
                        (change)="uploadFile($event)">
                </div>
                <div *ngIf="submitted && facility == ''" class="invalid-feedback">
                    <p>*Facility Name is mandatory</p>
                </div>
                <div *ngIf="fileFormat" class="invalid-feedback">
                    <p>*File format not supported</p>
                </div>
            </div>
            <!-- </form> -->
            <div class="csv-file-text">
                <p>
                    <span class="head-csv">CSV File Import Format</span>
                    CPT Code, CPT Description,Rate,Date Valid From, Status
                </p>
                <p class="download-csv-file">
                    <a href="javascript:void(0)" (click)="downloadfile()"><i class="fa fa-file-csv"></i>
                        Download Sample File</a>
                </p>
            </div>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav ">Cancel</button>
                <button type="button" (click)="addRecord()" class="btn btn-primary btn-patient-box">Submit</button>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->
</div>