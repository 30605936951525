import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { DashDetailHelper } from 'src/app/modules/billing-dashboard/dash-detail/helpers/dash-detail.helper';
import { ApiService } from 'src/app/shared/services/api.service';
import { CategoryItem, createCategoryItem } from './category-item.model';
import { CategoryItemStore } from './category-item.store';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({ providedIn: 'root' })
export class CategoryItemService {

  constructor(private store: CategoryItemStore,private spinner:NgxSpinnerService, private apiService: ApiService, private toastr: ToastrService) {
  }

  loadCategoryItem(facilityId: string | number){
    this.store.setLoading(true);
    // this.spinner.show();
    
    let data = { facilityId: facilityId, MonthNumber: new Date().getMonth() + 1, YearNumber: new Date().getFullYear(), CategoryId: 2 };
    console.log('Loading category with request data', data);
    return this.apiService.post('StatusChange/BindAllCategoryItems', data).pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to load category list', e.message);
        this.store.setLoading(false);
        this.store.setError('Failed to load category list');
        return;
      }
      this.store.setLoading(false);
      this.store.set(e.data);

    }));
  }
  loadCommandTypes(facilityId: string | number, categoryId: string): Observable<CategoryItem[]>{
    let data = {
      "facilityId": facilityId,
      "MonthNumber": new Date().getMonth() + 1,
      "YearNumber": new Date().getFullYear(),
      "CategoryId": categoryId
    }
    return this.apiService.post("StatusChange/BindCategoryItems", data).pipe(map(res => {
      if (res.status == "Success") {
          return res.data?.map(c => createCategoryItem({ name: c.name, value: c.value }));
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }));
  }

  loadCategoryItemsByParams(facilityId: string | number, categoryId: string, month: string, year: string){
    let data = {
      "facilityId": facilityId,
      "MonthNumber": new Date().getMonth() + 1,
      "YearNumber": new Date().getFullYear(),
      "CategoryId": categoryId
    }
    this.apiService.post("StatusChange/BindCategoryItems", data).subscribe(res => {
      if (res.status == "Success") {
        this.store.set(res.data);
        this.store.setLoading(false);
      }
      else if (res.status == "Error") {
        this.store.setError(res.message);
        this.toastr.error(res.message, 'Error!');
        this.store.setLoading(false);
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
      this.store.setLoading(false);
      this.store.setError(error);
    });
  }

  loadCategoryItemByFacilityAndCategory(facilityId: string | number, categoryId: string){
    this.store.setLoading(true);
    let data = {
      "facilityId": facilityId,
      "MonthNumber": new Date().getMonth() + 1,
      "YearNumber": new Date().getFullYear(),
      "CategoryId": categoryId
    }
    this.apiService.post("StatusChange/BindCategoryItems", data).subscribe(res => {
      if (res.status == "Success") {
        const data = [new DashDetailHelper().getDefaultCategoryItem(), ...res.data];
        this.store.set(data);
        this.store.setLoading(false);
      }
      else if (res.status == "Error") {
        this.store.setError(res.message);
        this.toastr.error(res.message, 'Error!');
        this.store.setLoading(false);
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
      this.store.setLoading(false);
      this.store.setError(error);
    });
  }

  active(id: ID){
    console.log('Activating category item ', id);
    this.store.setActive(id);
  }
}
