<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">

        <app-patient-info-header [bdosid]="bdosid" (closeEmitter)="onHeaderClickClose()"></app-patient-info-header>
        <div class="container container-pad-lf container-pad-tp-one">
            <div class="card-body">
                    <div class="row">
                        <div class="form-group col-6">
                            <label for="assignAll">
                                <label>Assign Status:</label>
                            </label>
                        </div>
                        <div class="form-group col-6">
                            <div>
                                <app-nsn-dropdown 
                                [callerId]="componentName" 
                                [config]="statusDropdownConfig" 
                                [name]="'Status'"
                                [isMultiple]="false" 
                                (onChangeEmitter)="onStatusChange($event)">
                                </app-nsn-dropdown>
                                <span *ngIf="submitted && !selectedStatus" class="invalid-feedback">
                                    Status is mandatory!!
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-6">
                            <label for="assignAll">
                                <label>Comment:</label>
                            </label>
                        </div>
                        <div class="form-group col-6">
                            <div>
                                <textarea class="form-control" [(ngModel)]="comment" placeholder="Comment" required></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-12">
                        <input type="file" class="form-control-file py-1" (change)="fileChanged($event)">
                    </div>
                    <div class="form-group col-md-12" *ngIf="isAttachedFileVisible">
                        <!-- <a href="javascript:void(0);"  (click)="downloadFile()">{{fileName}}</a> -->
                        <a href="javascript:void(0);"  (click)="downloadFile()">fileName</a>
                    </div>
                    <div class="text-right" style="margin: 75px 0px;">
                        <button type="button" (click)="onClickClose()"
                            class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                        <button type="button" (click)="onSubmit()" [disabled]="loading"
                            class="btn btn-primary btn-patient-box">Submit
                            <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                        </button>
                    </div>
               
            </div>
        </div>
    </div>
    
</div>