import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { createCodingStatus } from '../state/coding-status.model';
import { CodingStatusService } from '../state/coding-status.service';

@Component({
  selector: 'app-coding-status-add',
  templateUrl: './coding-status-add.component.html',
  styleUrls: ['./coding-status-add.component.css']
})
export class CodingStatusAddComponent implements OnInit, OnDestroy {
  AddCodingStatusForm: FormGroup;
  loading: boolean;
  submitted: boolean;
  @Output() closeEmitter = new EventEmitter();

  addSub: Subscription;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(){
    if(this.addSub){
      this.addSub.unsubscribe();
    }
  }

  initForm() {
    this.AddCodingStatusForm = this.fb.group({
      inquirystatus: ['', [Validators.required]],
      priority: [ '', [Validators.required]],    
    });
  }
  get f() { return this.AddCodingStatusForm.controls; }


  onClickClose() {
    this.closeEmitter.emit();
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    console.log("on submit", this.AddCodingStatusForm);
    if(this.AddCodingStatusForm.invalid){
      this.loading = false;
      return;
    }

    const inquirystatus = this.AddCodingStatusForm.get("inquirystatus").value;
    const priority = this.AddCodingStatusForm.get("priority").value;

    let requestBody = {
      InquiryStatus: inquirystatus,
      Priority: priority
    }
    this.addSub = this.apiService.post("InquiryStatus/SaveInquiryStatus", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.loading = false;
        this.submitted = false;
        this.toastr.success("Status Saved!!")
        this.onClickClose();
      }else{
        this.loading = false;
        this.submitted = false;
        this.toastr.error("Server Error");
      }
    }, error => {
      this.loading = false;
      this.submitted = false;
      this.toastr.error("Unaexpected Error");
    });

  }
}
