<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-560 active">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h6 class="m-0 font-weight-bold text-primary">{{ formFlag }} Custom Queue</h6>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <form class="pb-3 form-fields" [formGroup]="customQueueEditForm" (ngSubmit)="onSubmit()">
                <div class="row hr">
                    <div class="form-group remove-botttom-space col-12">
                        <label for="queueName" class="required">Queue Name</label>
                        <input type="text" class="form-control" formControlName="queueName" placeholder="Queue Name" 
                        required [ngClass]="{'is-invalid': submitted && f.queueName.errors }">
                    <div *ngIf="submitted && f.queueName.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                    </div>
                </div>
                <div class="row hr">
                    <div class="form-group remove-botttom-space col-12">
                        <label >Description</label>
                        <input type="text" class="form-control" formControlName="description" placeholder="Description" required>
    
                    </div>
                </div>
                <div class="container tab-remove" style="padding-top: 37.98px;">
                    <ul class="nav nav-tabs nav-border">
                        <li class="user-menu-style"><a class="active" data-toggle="tab" href="#Groups">Group</a>
                        </li>
                        <li class="user-menu-style"><a  data-toggle="tab" href="#Facilities">Facilities</a>
                        </li>
                        <li class="user-menu-style"><a data-toggle="tab" href="#Physicians">Physicians</a></li>
                        <li class="user-menu-style"><a data-toggle="tab" href="#Payers">Payers</a></li>
                        <li class="user-menu-style"><a data-toggle="tab" href="#Cpt">CPT Code</a></li>
                    </ul>
    
                    <div class="tab-content">
                        <div id="Groups" class="tab-pane fade in active show">
                            <div class="row">
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Available Groups</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedGroupToAdd"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let group of (availableGroups$ | async)" [ngValue]="group">
                                                {{group.groupName}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-2 remove-button-spce">
                                    <div style="height:44px;"></div>
                                    <button type="button" (click)="assign($event)" id="AddGroup"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="assignAll($event)" id="AddAllGroup"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="unassign($event)" id="RemoveGroup"
                                        class="btn btn-secondary mr-3 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                    <button type="button" (click)="unassignAll($event)" id="RemoveAllGroup"
                                        class="btn btn-secondary mr-3 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                                </div>
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Assigned Groups</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedGroupToRemove"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let group of (activeGroups$ | async)" [ngValue]="group">
                                                {{group.groupName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div id="Facilities" class="tab-pane fade">
                            <div class="row">
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Available Facilities</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFacilityToAdd"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let facility of (availableFacilities$ | async)" [ngValue]="facility">
                                                {{facility.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-2 remove-button-spce">
                                    <div class="tab-height"></div>
                                    <button type="button" (click)="assign($event)" id="AddFacility"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="assignAll($event)" id="AddAllFacility"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="unassign($event)" id="RemoveFacility"
                                        class="btn btn-secondary mr-3 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                    <button type="button" (click)="unassignAll($event)" id="RemoveAllFacility"
                                        class="btn btn-secondary mr-1 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                                </div>
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Assigned Facilities</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFacilityToRemove"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let facility of (activeFacilities$ | async)" [ngValue]="facility">
                                                {{facility.name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div id="Physicians" class="tab-pane fade">
                            <div class="row">
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Available Physicians</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPhysicianToAdd"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let physician of (availablePhysicians$ | async)" [ngValue]="physician">
                                                {{physician.fullName}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-2 remove-button-spce">
                                    <div class="tab-height"></div>
                                    <button type="button" (click)="assign($event)" id="AddPhysician"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="assignAll($event)" id="AddAllPhysician"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="unassign($event)" id="RemovePhysician"
                                        class="btn btn-secondary mr-3 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                    <button type="button" (click)="unassignAll($event)" id="RemoveAllPhysician"
                                        class="btn btn-secondary mr-1 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                                </div>
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Assigned Physicians</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPhysicianToRemove"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let physician of (activePhysicians$ | async)" [ngValue]="physician">
                                                {{physician.fullName}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Payers" class="tab-pane fade">
                            <div class="row">
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Available Payers</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPayerToAdd"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let payer of (availablePayers$ | async)" [ngValue]="payer">
                                                {{payer.payer_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-2 remove-button-spce">
                                    <div class="tab-height"></div>
                                    <button type="button" (click)="assign($event)" id="AddPayer"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="assignAll($event)" id="AddAllPayer"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="unassign($event)" id="RemovePayer"
                                        class="btn btn-secondary mr-3 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                    <button type="button" (click)="unassignAll($event)" id="RemoveAllPayer"
                                        class="btn btn-secondary mr-1 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                                </div>
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Assigned Payers</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPayerToRemove"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let payer of (activePayers$ | async)" [ngValue]="payer">
                                                {{payer.payer_Name}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="Cpt" class="tab-pane fade">
                            <div class="row">
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Available CPT Code</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCptToAdd"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let cpt of (availableCpts$ | async)" [ngValue]="cpt">
                                                {{cpt.cpt}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group col-md-2 remove-button-spce">
                                    <div class="tab-height"></div>
                                    <button type="button" (click)="assign($event)" id="AddCpt"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="assignAll($event)" id="AddAllCpt"
                                        class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                            class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                    <button type="button" (click)="unassign($event)" id="RemoveCpt"
                                        class="btn btn-secondary mr-3 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                    <button type="button" (click)="unassignAll($event)" id="RemoveAllCpt"
                                        class="btn btn-secondary mr-1 user-mid-button-left"><i
                                            class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                                </div>
                                <div class="form-group facil-space col-md-5">
                                    <p class="user-tab-weight">Assigned CPT Code</p>
                                    <div class="input-group input-selc-box">
                                        <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCptToRemove"
                                            class="custom-select form-control multiple-select-design" multiple>
                                            <option *ngFor="let cpt of (activeCpts$ | async)" [ngValue]="cpt">
                                                {{cpt.cpt}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-right">
                    <button type="button" (click)="onClickClose()"
                        class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                    <!-- <button type="submit" class="btn btn-primary btn-patient-box" [disabled]="!customQueueMasterForm.valid">Submit</button> -->
                    <button type="submit" class="btn btn-primary btn-patient-box" >Submit</button>
                    <!-- (click)="onSubmit()" -->
                </div>
            </form>
        </div>
        </div>
        <!-- </div> -->
        <!-- Sidenav-->