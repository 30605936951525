import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { createNSNDropdownConfig, NSNDropdownConfig } from 'src/app/shared/components/nsn-dropdown/nsn-dropdown.component.types';
import { createNsnGridConfig, NsnGridConfig } from 'src/app/shared/components/nsn-grid/nsn-grid.component.types';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { createBatchCaseReview, httpGetRequestMethod, httpPOSTRequestMethod, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { SessionQuery } from '../../login/session/session.query';
import { createAssignReviewCase } from './create-batch-review-cases.types';

@Component({
  selector: 'app-create-batch-review-cases',
  templateUrl: './create-batch-review-cases.component.html',
  styleUrls: ['./create-batch-review-cases.component.css']
})
export class CreateBatchReviewCasesComponent implements OnInit {
  componentName: NameConstant = createBatchCaseReview;
  CreateBatchReviewCasesForm: FormGroup;
  userDropdownConfig: NSNDropdownConfig;
  activePatientList: Patient[];
  loading: boolean;
  submitted: boolean;
  @Output() closeEmitter = new EventEmitter();
  coloumns: ColDef[] = [];
  selectedUser;
  selfAssigning: boolean;
  constructor(
    private patientQuery: PatientQuery,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private sessionQuery: SessionQuery
  ) { }

  ngOnInit(): void {
    this.initPatientList();
    this.initUserDropdownConfig();
    this.initColumns();
    this.initForm();
  }

  initPatientList(){
    this.activePatientList = this.patientQuery.getActive();
  }

  initUserDropdownConfig(){
    this.userDropdownConfig = createNSNDropdownConfig({
      sourceApi: 'ApplicationUser/BindNsnUser',
      sourceApiBody: undefined,
      httpRequestType: httpGetRequestMethod,
      key: 'userid',
      value: 'email',
      height: '300px'
    });
  }

  initColumns(){
    this.coloumns = [
      createColDef({ name: 'mrn', displayName: 'MRN', isDataCol: true }),
      createColDef({ name: 'name', displayName: 'Patient', isDataCol: true }),
      createColDef({ name: 'dateOfSurgery', displayName: 'DoS', isDataCol: true }),
      createColDef({ name: 'insuranceProvider', displayName: 'Insurance', isDataCol: true }),
    ]
  }
  initForm() {
    this.CreateBatchReviewCasesForm = this.fb.group({
      batchName: ['', [Validators.required]],
      comment: [ '', [Validators.required]],    
    });
  }
  get f() { return this.CreateBatchReviewCasesForm.controls; }

  onUserChange(event$){
    console.log("onUserChange", event$);
    this.selectedUser = event$?.obj;
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    console.log("on submit", this.CreateBatchReviewCasesForm);
    console.log('onUserChange', this.selectedUser);
    if(this.CreateBatchReviewCasesForm.invalid){
      this.loading = false;
      return;
    }
    if(!this.selectedUser){
      this.loading = false;
      return;
    }

    if(this.selectedUser.email === this.sessionQuery.getValue().email){
      this.loading = false;
      this.selfAssigning = true;
      return;
    }else{
      this.selfAssigning = false;
    }
    const userId = this.selectedUser.userid;
    const comment = this.CreateBatchReviewCasesForm.get("comment").value;
    const batchName = this.CreateBatchReviewCasesForm.get("batchName").value;
    
    const AssignReviewCaseList = this.activePatientList.map(patient => createAssignReviewCase({ BDOSID: patient.bdosid, AssignedTo: userId, AssignedComments: comment }));
    const requestBody = {
      "ReviewName": batchName,
      "AssignReviewCases": AssignReviewCaseList
    };

    this.apiService.post("ReviewCase/SaveReviewCase", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.toastr.success('Success!!', res?.message);
        this.onClickClose();
      }else{
        this.toastr.error("Server returned error!!", res?.message);  
        this.onClickClose();
      }
    }, 
    err => {
      this.toastr.error("Unexpected error!!", err?.message);
      this.onClickClose();
    });
  }
}
