
import { pendingclient, priority } from "src/app/shared/models/name-constant";
import { CategoryItem, createCategoryItem } from "src/app/shared/state/category-item/category-item.model";
import { CommandTypeHelper } from "./command-config.helper";
import { formatCurrency } from "@angular/common";
export class DashDetailHelper {

  private map: Map<string, string> = new Map();
  private criticalItemAccordianOrderingMap: Map<string, number> = new Map();
  constructor() {
    this.initializeMap();
    this.initializeCriticalItemAccordinOrderingMap();
  }

  private initializeMap() {
    this.map.set("codingexpert", "BillerOnHold");
    this.map.set("baq", "BillerOnHold");
    this.map.set("authreview", "BillerOnHold");
    this.map.set("returnedcodingrfi", "BillerOnHold");
    this.map.set("billeronhold", "BillerOnHold");
  }

  private initializeCriticalItemAccordinOrderingMap() {
    this.criticalItemAccordianOrderingMap.set("returnedclaim", 1);
    this.criticalItemAccordianOrderingMap.set("followup", 3);
    this.criticalItemAccordianOrderingMap.set("current", 2);
  }

  isBillerOnHold(commandType: string) {
    if (!commandType || commandType === null) {
      return false;
    }
    commandType = commandType.toString().toLowerCase();
    let commandValue = this.map.get(commandType);

    return commandValue === "BillerOnHold";
  }

  loadNSNAccordianComponent(commandType: string): boolean{
    return this.isBillerOnHold(commandType) || commandType === priority.commandName || commandType === pendingclient.commandName;
  }
  isCaseBilled(commandType: string){
    if (!commandType || commandType === null) {
      return false;
    }
    commandType = commandType.toString().toLowerCase();
    
    return commandType === "casebilled";
  }

  buildRequest(catId, cmdType: string, facilityID: number, monthNumber: any, yearNumber: any, pagenumber: number, pageValue: any, defCategoryArry: any) {
    let relUrl = "";
    let data = {};

    switch (cmdType) {
      case ("caseperformed"): {
        relUrl = "CasesPerformedPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "MonthNumber": monthNumber,
          "YearNumber": yearNumber,
          "pageNum": pagenumber,
          "pageSize": pageValue,
        };

        defCategoryArry["data"] = { "value": cmdType, "name": 'Cases Performed' };;
        break;
      }
      case ("casebilled"): {
        relUrl = "CasesBilledPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "MonthNumber": monthNumber,
          "YearNumber": yearNumber,
          "pageNum": pagenumber,
          "pageSize": pageValue,
        };
        defCategoryArry["data"] = { "value": cmdType, "name": 'Cases Billed' };
        break;
      }
      case ("avgDystoBill"): {
        relUrl = "AvgDaysToBillPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "MonthNumber": monthNumber,
          "YearNumber": yearNumber,
          "pageNum": pagenumber,
          "pageSize": pageValue,
        };
        defCategoryArry["data"] = { "value": cmdType, "name": 'Avg Days To Bill' };
        break;
      }
      case ("avgDystoBillIntrnl"): {
        relUrl = "AvgDaysToBillInternalPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "MonthNumber": monthNumber,
          "YearNumber": yearNumber,
          "pageNum": pagenumber,
          "pageSize": pageValue,
        };
        defCategoryArry["data"] = { "value": cmdType, "name": 'Avg Days To Bill Internal' };
        break;
      }
      case ("priority"): {
        relUrl = "PriorityItemsPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "pageNum": pagenumber,
          "pageSize": pageValue,

        };
        defCategoryArry["data"] = { "value": "priority", "name": 'Priority Items' };
        break;
      }
      case ("pendingclient"): {
        relUrl = "PendingClientPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "pageNum": pagenumber,
          "pageSize": pageValue,

        };
        defCategoryArry["data"] = { "value": "pendingclient", "name": 'Pending Client' };
        break;
      }
      case ("dailyflow"): {
        relUrl = "DailyFlowPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "pageNum": pagenumber,
          "pageSize": pageValue,

        };
        defCategoryArry["data"] = { "value": "dailyflow", "name": 'Daily Flow' };
        break;
      }
      case ("criticalitem"): {
        relUrl = "CriticalitemsPatientList";
        data = {
          "FacilityQueueId": facilityID,
          "pageNum": pagenumber,
          "pageSize": pageValue
        };
        defCategoryArry["data"] = { "value": cmdType, "name": 'Criticalitems' };
        break;
      }
      default: {
        relUrl = "GetPatientsByStatusNew";

        if (catId != 7) {
          data = {
            "FacilityQueueId": facilityID,
            "CommandName": cmdType,
            "pageNum": pagenumber,
            "pageSize": pageValue,
            "Isassigned": 1
          }
          if (catId == 2) {
            defCategoryArry["data"] = { "value": "priority", "name": 'Priority Items' };
          }
          else if (catId == 3) {
            defCategoryArry["data"] = { "value": "pendingclient", "name": 'Pending Client' };
          }
          else if (catId == 5) {
            defCategoryArry["data"] = { "value": "dailyflow", "name": 'Daily Flow' };
          }
        }
        else {
          data = {
            "FacilityQueueId": facilityID,
            "CommandName": cmdType,
            "MonthNumber": monthNumber,
            "YearNumber": yearNumber,
            "pageNum": pagenumber,
            "pageSize": pageValue,
            "Isassigned": 1
          }
        }
        break;
      }
    }
    return { relUrl: relUrl, data: data, defCategoryArry: defCategoryArry };
  }

  getDefaultCategoryItem(): CategoryItem {
    return createCategoryItem({ name: 'All Critical Items', value: '8' })
  }

  getAccordianOrder(categoryItem: string): number {
    console.log("getAccordianOrder", categoryItem);
    return this.criticalItemAccordianOrderingMap.get(categoryItem);
  }

  getAccordianOrderByParentCommandName(categoryItem: string, parentCommandName: string): number {
  
    const parentCommandConfig = CommandTypeHelper.map.get(parentCommandName.toLowerCase());
    if(!parentCommandConfig){
      console.log("command config is not defined so setting default order as 100", categoryItem);
      return 100;
    }

    if(!parentCommandConfig.childAccordianOrder){
      console.log("childAccordianOrder map is not defined in command config so setting default order as 100", categoryItem);
      return 100;
    }

    if(!parentCommandConfig.childAccordianOrder.get(categoryItem.toLowerCase())){
      console.log("childAccordianOrder map does not have entery for "+ categoryItem+" in command config so setting default order as 100", categoryItem);
      return 100;
    }

   return parentCommandConfig.childAccordianOrder.get(categoryItem.toLowerCase());
  }

  getLoggedInRole(): string | undefined{
    var val = localStorage.getItem('currentUser');
    if (val != "" && val != undefined) {
      let v = JSON.parse(val);
      return v.role;
    }
    return undefined;
  }
  getAmountFromFormattedCurrency(amount){
    const str =  (amount+"").toString().replace("$", "").replace(",", "");
    return Number(str);
}

formatAmount(amount){
  if(isNaN(amount)){
    const strAmount = this.getAmountFromFormattedCurrency(amount);
    return formatCurrency(strAmount, 'en-US', '$');  
  }
  return formatCurrency(amount, 'en-US', '$');
}

}