import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CategoryConfig, CustomAccordianConfig } from 'src/app/shared/components/custom-accordian/custom-accordian.component.type';
import { createNSNDropdownConfig, NSNDropdownConfig } from 'src/app/shared/components/nsn-dropdown/nsn-dropdown.component.types';
import { createNsnGridConfig, NsnGridConfig } from 'src/app/shared/components/nsn-grid/nsn-grid.component.types';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { action, activeQueue, codingReviewed, httpGetRequestMethod, httpPOSTRequestMethod, NameConstant } from 'src/app/shared/models/name-constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { PatientService } from 'src/app/shared/state/patient/patient.service';

@Component({
  selector: 'app-coding-reviewed',
  templateUrl: './coding-reviewed.component.html',
  styleUrls: ['./coding-reviewed.component.css']
})
export class CodingReviewedComponent implements OnInit {
  componentName: NameConstant = codingReviewed;
  inquirystatusDropdownConfig: NSNDropdownConfig;
  inquirystatusDropDownName: string;
  shouldShowQAOPFormModal: boolean = false;
  shouldShowQAICFormModal: boolean = false;
  shouldShowQAATFormModal: boolean = false;
  shouldShowToolsModal: boolean = false;
  shouldShowCaseRequestFormModal: boolean = false;
  formData: any = null;
  coloumns: ColDef[] = [];
  nsnGridConfigSubject: BehaviorSubject<NsnGridConfig>;
  initNsnGridConfig: NsnGridConfig;
  selectDropdownId;

  pagenumber: number = 1;
  pageSize: number = 10;
  searchValue: string = "";
  displayNsnGrid: boolean = false;
  displayAccordian: boolean = true;
  customAccordianConfig: CustomAccordianConfig;
  customAccordianConfigSubject: BehaviorSubject<CustomAccordianConfig>;
  
  constructor(
    private commonUtil: CommonService,
    private patientService: PatientService,
  ) { }

  ngOnInit(): void {
    this.commonUtil.setTitle(this.componentName.value);
    this.initInquiryStatusDropdownConfig();
    this.coloumns = this.buildColDef();
    this.buildCustomAccordianConfig();
    this.customAccordianConfigSubject = new BehaviorSubject<CustomAccordianConfig>(this.customAccordianConfig);
    this.updateAccordian(this.customAccordianConfig);
    this.nsnGridConfigSubject = new BehaviorSubject<NsnGridConfig>(this.initNsnGridConfig);
    
  }

  buildColDef(): ColDef[]{
    return [
      createColDef({ name: 'inquirystatus', displayName: 'Inquiry Status', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'client', displayName: 'Facility', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'mrn', displayName: 'MRN', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'name', displayName: 'Patient', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'dateOfSurgery', displayName: 'DoS', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: true, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'insuranceProvider', displayName: 'Insurance', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: activeQueue.id, displayName: 'Active Queue', isIdCol: false, isCheckbox: false, isDataCol: false, isDataTypeDate: false, isActiveQueue: true, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'timeCol', displayName: 'Age', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      createColDef({ name: 'lastNote', displayName: 'Comment', isIdCol: false, isCheckbox: false, isDataCol: true, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: false }),
      //createColDef({ name: quickAccess.id, displayName: 'Quick Access', isIdCol: false, isCheckbox: false, isDataCol: false, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: true, isAction: false }),
      createColDef({ name: action.id, displayName: 'Action', isIdCol: false, isCheckbox: false, isDataCol: false, isDataTypeDate: false, isActiveQueue: false, isQuickAccess: false, isAction: true }),
    ]; 
  }
  buildCustomAccordianConfig(){
    const categoryConfig : CategoryConfig = {
      httpRequestType: httpGetRequestMethod,
      api: "InquiryStatus/BindInquiryStatusDropdown",
      body: { },
    }

     this.customAccordianConfig = { 
       categoryConfig: categoryConfig,
       httpRequestType: httpPOSTRequestMethod,
       api: "CodingInquiry/BindGridDataById",
       bodyAttributeMap: new Map([ 
                                 ["InquiryStatusId", { isInputProvided: false,  value: "id" }],
                                 ["ReviewedStatus", { isInputProvided: true,  value: this.componentName.commandName }]
                               ]),
       accordianCountAttrKey: "totalcount",
       accordianNameAttrKeyMap: new Map([ ["name", "inquirystatus"]]),
       accordianIdAttrKey: "InquiryStatusId",
       searchValue: this.searchValue,
       accordianRankAttrKey: 'priority'
     }
   }

   updateAccordian(customAccordianConfig: CustomAccordianConfig) {
    this.customAccordianConfigSubject.next(customAccordianConfig);
  }

  updateGrid(nsnGridConfig: NsnGridConfig) {
    this.nsnGridConfigSubject.next(nsnGridConfig);
  }
   showQAOPFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAOPFormModal) {
      this.shouldShowQAOPFormModal = false;
    }
    else {
      this.shouldShowQAOPFormModal = true;
    }
  }

  showQAICFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAICFormModal) {
      this.shouldShowQAICFormModal = false;
    }
    else {
      this.shouldShowQAICFormModal = true;
    }
  }

  showQAATFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAATFormModal) {
      this.shouldShowQAATFormModal = false;
    }
    else {
      this.shouldShowQAATFormModal = true;
    }
  }

  showToolsFormModal(data) {
    this.formData = data;
    this.patientService.setAndActivate([data]);
    if (this.shouldShowToolsModal) {
      this.shouldShowToolsModal = false;
    }
    else {
      this.shouldShowToolsModal = true;
    }
  }

  showCaseRequestFormModal(data) {
    console.log("patient selected", data);
    this.patientService.setAndActivate([data]);
    this.formData = data;
    if (this.shouldShowCaseRequestFormModal) {
      this.shouldShowCaseRequestFormModal = false;
    }
    else {
      this.shouldShowCaseRequestFormModal = true;
    }
  }


  onCloseFormModal(){
    this.shouldShowQAOPFormModal = false;
    this.shouldShowQAICFormModal = false;
    this.shouldShowQAATFormModal = false;
    this.shouldShowToolsModal = false;
    this.shouldShowCaseRequestFormModal = false;
    this.search();
  }

  pageChange(data){
    this.pagenumber = data;
    const nsnGridConfig = createNsnGridConfig({ 
      api: "CodingInquiry/BindGridDataById",
      apiBody: { pageNum: this.pagenumber, "pageSize": this.pageSize,"searchValue": this.searchValue, 
                "InquiryStatusId":  this.selectDropdownId ,"ReviewedStatus": this.componentName.commandName },
      method: httpPOSTRequestMethod,
      isTotalItemKeyPresentInDataSource: true,
      totalItemKey: 'totalcount',
      responseRowKey: 'bdosid'
    });
    this.updateGrid(nsnGridConfig);
  }

  search(){
    console.log("search value", this.searchValue);
    this.displayNsnGrid = false;
    this.displayAccordian = true;
    this.buildCustomAccordianConfig();
    this.updateAccordian(this.customAccordianConfig);
  }

  initInquiryStatusDropdownConfig(){
    this.inquirystatusDropdownConfig = createNSNDropdownConfig({
      sourceApi: 'InquiryStatus/BindInquiryStatusDropdown',
      sourceApiBody: { },
      key: 'id',
      value: 'inquirystatus',
      httpRequestType: httpGetRequestMethod
    });
    this.inquirystatusDropDownName = "Inquiry Status";
  }

  oninquirystatusChange(event){
    console.log("oninquirystatusChange ", event);
    if(event){
      this.displayNsnGrid = true;
      this.displayAccordian = false;
      this.selectDropdownId = event.id;

      let nsnGridConfig;
      if(event.id === 'CodingExpert' || event.id === 'InCoding'){
        nsnGridConfig = createNsnGridConfig({ 
          api: "BillingNew/GetPatientsByStatusNew",
          apiBody: { pageNum: this.pagenumber, "pageSize": this.pageSize, "searchValue": this.searchValue, "CommandName": event.id, "ClientFacilityId": '0' },
          method: httpPOSTRequestMethod,
          isTotalItemKeyPresentInDataSource: true,
          totalItemKey: 'totalcount',
          responseRowKey: 'bdosid'
        } );
      }else{
        nsnGridConfig = createNsnGridConfig({ 
          api: "CodingInquiry/BindGridDataById",
          apiBody: { pageNum: this.pagenumber, "pageSize": this.pageSize, "searchValue": this.searchValue, "InquiryStatusId": event.id, "ReviewedStatus": this.componentName.commandName },
          method: httpPOSTRequestMethod,
          isTotalItemKeyPresentInDataSource: true,
          totalItemKey: 'totalcount',
          responseRowKey: 'bdosid'
        } );
      }
      this.updateGrid(nsnGridConfig);
    }else{
      this.displayNsnGrid = false;
      this.displayAccordian = true;
    }
  }

}
