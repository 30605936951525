import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-eod-form',
  templateUrl: './eod-form.component.html',
  styleUrls: ['./eod-form.component.css']
})
export class EodFormComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();

  PatientSummaryDtls: any;
  _formData: any;

  patientCPTs: any = [];
  primaryCPT: number;

  eodHoldReason: any[];
  smryIsToggled = false;
  fileAttachName: any;
  facilityId: number = 0;
  facilityName: string = "";
  dataQueueInfo: any = {};
  dataQueueDtl: any = {};
  uploadFile: any;
  chckAccept = false;
  chckHold = false;
  chckClaim = false;
  chckSelfPay = false;
  holdReason = "";
  claimID = "";

  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
    }
  }

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getHoldReason();
    this.getPatientHeaderData();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getHoldReason() {

    let type = this._formData.type;
    console.log("this is get Hold Resone---------------------");
    console.log(this._formData);
    if (type == 'Self' || type == 'Self-Pay') {
      type = "SelfPay";
    }

    let data = {
      "EODType": type
    }

    this.getApiService.post("EOD/GetEODHoldReasons", data).subscribe(res => {
      if (res.status == "Success") {
        this.eodHoldReason = res.dataGrid;
        console.log(this.eodHoldReason);
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

  getPatientHeaderData() {
    this.spinner.show();
    let data = {
      "Bdosid": this._formData.bdosid
    }

    this.getApiService.post("BillingNew/GetPatientsInfo", data).subscribe(res => {
      this.spinner.hide();
      if (res.status == "Success") {

        this.PatientSummaryDtls = res;

        this.facilityId = res.dataPatient[0].facilityId;
        this.facilityName = res.dataPatient[0].facilityName;

        this.dataQueueInfo = res.dataQueueInfo[0];

        if (res.dataCPTDetails.length > 0) {
          this.primaryCPT = res.dataCPTDetails[0].cptCode;
        }

        for (let d of res.dataCPTDetails) {
          if (this.patientCPTs.indexOf(d.cptCode) == -1) {
            this.patientCPTs.push(d.cptCode);
          }
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  slctchkBx(targetChckBx) {
    if (targetChckBx == 'chckAccept') {
      this.chckAccept = true;
      this.chckHold = false;
      this.chckClaim = false;
      this.chckSelfPay = false;
    }
    else if (targetChckBx == 'chckHold') {
      this.chckAccept = false;
      this.chckHold = true;
      this.chckClaim = false;
      this.chckSelfPay = false;
    }
    else if (targetChckBx == 'chckClaim') {
      this.chckAccept = false;
      this.chckHold = false;
      this.chckClaim = true;
      this.chckSelfPay = false;
    }
    else if (targetChckBx == 'chckSelfPay') {
      this.chckAccept = false;
      this.chckHold = false;
      this.chckClaim = false;
      this.chckSelfPay = true;
    }
  }

  fileAttachChanged(event) {
    this.fileAttachName = event.target.files[0];
    this.chckClaim = true;
  }

  onSubmit() {
    let date = new Date();
    let cdt = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();

    if (this._formData.type == "Electronic") {
      if (this.chckAccept == false && this.chckHold == false && this.claimID == "") {
        return;
      }
      else if (this.chckAccept == true && this.claimID == "") {
        return;
      }
      else if (this.chckHold == true && this.holdReason == "") {
        return;
      }
    }
    else if (this._formData.type == "Paper") {
      if (this.chckClaim == false && this.chckHold == false) {
        return;
      }
      else if (this.chckClaim == false && this.fileAttachName != undefined) {
        return;
      }
      else if (this.chckHold == true && this.holdReason == "") {
        return;
      }
    }
    else if (this._formData.type == "Self") {
      if (this.chckSelfPay == false && this.chckHold == false) {
        return;
      }
      else if (this.chckHold == true && this.holdReason == "") {
        return;
      }
    }

    let formData = new FormData();

    formData.append("EODType", this._formData.type);
    formData.append("EODID", this._formData.id);
    formData.append("Accepted", this.chckAccept.toString());
    formData.append("VerifySelfPay", this.chckSelfPay.toString());
    formData.append("ClaimValue", this.claimID);
    formData.append("HoldReason", this.holdReason);
    formData.append("BDOSID", this._formData.bdosid);
    formData.append("chkHold", this.chckHold.toString());
    formData.append("EODdate", cdt);
    formData.append("facilityId", this._formData.facilityID);
    if (this.fileAttachName != undefined && this.fileAttachName.name != undefined) {
      formData.append("file", this.fileAttachName, this.fileAttachName.name);
      formData.append("Filename", this.fileAttachName.name);
    }

    this.getApiService.uploadFiles("EOD/SaveEOD", formData).subscribe(res => {
      if (res.status == "Success") {
        this.closeEmitter.emit();
        this.toastr.success("Eod updated sucessfully", 'Success!');
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
}
