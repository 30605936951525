import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientsComponent } from './patients.component';
import { PatientsFormComponent } from './patients-form/patients-form.component';
import { Routes, RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientViewComponent } from './patient-view/patient-view.component';
import { PatientBillingComponent } from './patient-billing/patient-billing.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FacilityDetailsComponent } from './facility-details/facility-details.component';
import { PatientActionComponent } from './patient-action/patient-action.component'
import { ClaimTypeActionComponent } from './patient-action/claim-type-action/claim-type-action.component'
import { CurrencyPipe } from '@angular/common'
import { PatientRoutingModule } from './patients-routing.module';
import { AssignCaseComponent } from './assign-cases/assign-case/assign-case.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AssignCaseFormComponent } from './assign-cases/assign-case-form/assign-case-form.component';
import { BillingDashboardModule } from '../billing-dashboard/billing-dashboard.module';
import { CptCodesFormComponent } from './cpt-codes-form/cpt-codes-form.component';
import { DatepickerModule, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
@NgModule({
  declarations: [
    PatientsComponent,
    PatientActionComponent,
    ClaimTypeActionComponent, 
    PatientsFormComponent, 
    PatientViewComponent, 
    PatientBillingComponent, 
    FacilityDetailsComponent,
    AssignCaseComponent,
    AssignCaseFormComponent,
    CptCodesFormComponent
  ],
  imports: [
    CommonModule,
    PatientRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    TextMaskModule,
    SharedModule,
    NgbModule,
    BillingDashboardModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot() 
  ],
  providers: [CurrencyPipe],
  exports: [RouterModule, PatientsFormComponent]
})
export class PatientsModule { }
