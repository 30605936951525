<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size  ml-4">Billing Rules</h1>
                    <a *ngIf="privileges.billingRulesAdd" href="javascript:void(0);" (click)='showFormModal()' id="closeFileSidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add Billing Rules
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="getBillingRulesList()"
                                        placeholder="Rule Group/Insurance/Physician/CPT"></label>                               
                            </div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list appointments-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th>Rule Name</th>
                                    <!-- <th>Region</th> -->
                                    <th>Group</th>
                                    <th>Facility</th>
                                    <!-- <th>Claim Type</th> -->
                                    <th>Payer</th>
                                    <th>Physician</th>
                                    <th>CPT</th>
                                    <th style="width: 30%;">Billing Requirement</th>
                                    <th>Created On</th>
                                    <th *ngIf="privileges.billingRulesEdit || privileges.billingRulesDelete">Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th>Rule Name</th>
                                    <!-- <th>Region</th> -->
                                    <th>Group</th>
                                    <th>Facility</th>
                                    <!-- <th>Claim Type</th> -->
                                    <th>Payer</th>
                                    <th>Physician</th>
                                    <th>CPT</th>
                                    <th>Billing Requirement</th>
                                    <th>Created On</th>
                                    <th *ngIf="privileges.billingRulesEdit || privileges.billingRulesDelete">Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of billingRulesList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <!-- <td>
                                      
                                        {{(i+1) + ((p-1)*pageValue)}} 
                                    </td> -->
                                    <td>{{data.ruleName}}</td>
                                    <td>{{data.groupName}}</td>
                                    <!-- <td>{{data.region}}</td> -->
                                    <td><a href="javascript:void(0)" (click)='showDetailModal(data)'>{{data.facilityName}}</a></td>
                                    <!-- <td ><p *ngIf='data.electronic != 0;else second'>Electronic</p><p #second *ngIf='data.paper != 0 else third'>Paper</p><p #third>Paper</p></td> -->
                                    <td>{{data.payerName}}</td>
                                    <td>{{data.physicianName}}</td>
                                    <td>{{data.cptName}}</td>
                                    <td>{{data.ruleText}}</td>
                                    <td>{{data.createdDate | date:'MM/dd/yyyy HH:mm'}}</td>
                                    <td *ngIf="privileges.billingRulesEdit || privileges.billingRulesDelete" class="d-flex">
                                        <a *ngIf="privileges.billingRulesEdit" href="javascript:void(0)" (click)="editbillingRules(data)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>
                                        <a *ngIf="privileges.billingRulesDelete" href="javascript:void(0)" (click)="delbillingRules(data.ruleName)"
                                            class="btn btn-danger icon-btn"><i class="fa fa-trash-alt" title="Delete"></i></a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<!-- <app-facility-details  [facility_id]="facility_id" *ngIf="shouldShowDetailModal" (closeEmitter)="onCloseFormModal()">
</app-facility-details> -->
<app-billing-rules-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)">
</app-billing-rules-form>
<!-- Sidenav-->