import * as name from '../../../../../shared/models/name-constant';

export const operatingReport: CaseRequestName = { id: name.operatingReport.id, value: name.operatingReport.value, displayName: "OP Note", bulkActionQueueName: name.operatingReport.bulkActionQueueName};
export const pathology: CaseRequestName = { id: name.pathology.id, value: name.pathology.value, displayName: "Pathology", bulkActionQueueName: name.pathology.bulkActionQueueName};
export const pricing: CaseRequestName = { id: name.pricing.id, value: name.pricing.value, displayName: "Pricing", bulkActionQueueName: name.pricing.bulkActionQueueName};
export const blockReport: CaseRequestName = { id: name.blockReport.id, value: name.blockReport.value, displayName: "Block Report", bulkActionQueueName: name.blockReport.bulkActionQueueName};
export const codingQuery: CaseRequestName = { id: name.codingQuery.id, value: name.codingQuery.value, displayName: "Coding Query", bulkActionQueueName: name.codingQuery.bulkActionQueueName};
export const otherMisc: CaseRequestName = { id: name.otherMisc.id, value: name.otherMisc.value, displayName: "Other/Misc", bulkActionQueueName: name.otherMisc.bulkActionQueueName};
export const highApproval: CaseRequestName = { id: name.highApproval.id, value: name.highApproval.value, displayName: "Client High$$$", bulkActionQueueName: name.highApproval.bulkActionQueueName};
export const variance: CaseRequestName = { id: name.variance.id, value: name.variance.value, displayName: name.variance.bulkActionQueueName, bulkActionQueueName: name.variance.bulkActionQueueName};
export const implantLog: CaseRequestName = { id: name.implantLog.id, value: name.implantLog.value, displayName: "Implant Log", bulkActionQueueName: name.implantLog.bulkActionQueueName};
export const invoice: CaseRequestName = { id: name.invoice.id, value: name.invoice.value, displayName: "Invoice", bulkActionQueueName: name.invoice.bulkActionQueueName};
export const inCoding: CaseRequestName = { id: name.inCoding.id, value: name.inCoding.value, displayName: "In Coding", bulkActionQueueName: name.inCoding.bulkActionQueueName};
export const highApprovalInternal: CaseRequestName = { id: name.highApprovalInternal.id, value: name.highApprovalInternal.value, displayName: "High$$$", bulkActionQueueName: name.highApprovalInternal.bulkActionQueueName};
export const authReview: CaseRequestName = { id: name.authReview.id, value: name.authReview.value, displayName: "Auth Review", bulkActionQueueName: name.authReview.bulkActionQueueName};
export const codingExpert: CaseRequestName = { id: name.codingExpert.id, value: name.codingExpert.value, displayName: "Coding Expert", bulkActionQueueName: name.codingExpert.bulkActionQueueName};


export interface CaseRequestName{
    id: number | string;
    value: string;
    displayName: string;
    bulkActionQueueName?: string;
}