<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Facility List</h1>
                    <a href="javascript:void(0);" routerLink="/masters/add-facility" id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add Facility
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="onSearch()"
                                        placeholder="Name/Short Name/Abbr"></label></div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <!-- <th>#</th> -->
                                    
                                    <th>Name<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('name','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('name','desc')"></i></th>
                                        
                                    <th>Short Name<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('shortName','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('shortName','desc')"></i></th>
                                    <th>Region<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('region','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListFacility('region','desc')"></i></th>
                                    <th>Group<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('groupName','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListFacility('groupName','desc')"></i></th>
                                    <th>Abbr<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('abbr','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('abbr','desc')"></i></th>
                                    <th>Acronym<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('abbr','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('acrnym','desc')"></i></th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                   <!-- <th>#</th>  -->
                                    
                                    <th>Name<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('name','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('name','desc')"></i></th>
                                    <th>Short Name<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('shortName','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('shortName','desc')"></i></th>
                                    <th>Region<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('region','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('region','desc')"></i></th>
                                    <th>Group<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('groupName','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('groupName','desc')"></i></th>
                                    <th>Abbr<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('abbr','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('abbr','desc')"></i></th>
                                    <th>Acronym<i class="fas fa-arrow-up mr-1" (click)="sortListFacility('abbr','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListFacility('acrnym','desc')"></i></th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of FacilityMasterArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                   <!-- <td >{{(i+1) + ((p-1)*pageValue)}} 
                                    </td>                                 -->
                                    
                                    <td>{{data.name}}</td>
                                    <td>{{data.shortName}}</td>
                                    <td>{{data.region}}</td>
                                    <td>{{data.groupname}}</td>
                                    <td>{{data.abbr}}</td>
                                    <td>{{data.acrnym}}</td>
                                    <td>
                                        <label class="custom-switch">
                                            <input type="checkbox" (click)="delFacilityMaster(data)"
                                                [checked]="!data.deleted">
                                            <span class="slider"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" routerLink="/masters/facility-master/facility-pricing/{{data.id}}"
                                            class="btn btn-sm btn-default icon-btn"><b><i
                                                class="fa fa-dollar-sign" title="Pricing" ></i></b></a>&nbsp;
                                        <a href="javascript:void(0)" (click)="showFormModal(data)"
                                            class="btn btn-primary icon-btn openMySidenav"><i class="fas fa-parking" title="Payer Management"></i></a>&nbsp;
                                        <a href="javascript:void(0)" routerLink="/masters/add-facility/{{data.id}}"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>&nbsp;
                                                
                                        <!-- <a href="javascript:void(0)" class="btn btn-danger icon-btn"><i
                                                *ngIf="data.deleted == false" class="fa fa-toggle-on"
                                                aria-hidden="true"></i>
                                            <i *ngIf="data.deleted == true" class="fa fa-toggle-off"
                                                aria-hidden="true"></i>
                                        </a> -->
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<!-- <app-payer-data [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onClickClose()"
(editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)">
</app-payer-data> -->
<app-payer-data *ngIf=shouldShowFormModal (closeEmitter)="onClickClose()">
</app-payer-data>
<!-- <app-facility-master-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)">
</app-facility-master-form> -->
<!-- Sidenav-->