import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { Group } from './group.model';
import { GroupsQuery } from './groups.query';
import { GroupsStore } from './groups.store';

@Injectable({ providedIn: 'root' })
export class GroupsService {

  constructor(private store: GroupsStore, private apiService: ApiService, private query: GroupsQuery) {
  }


  get() {
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };
    return this.apiService.post("FacilityGroup/BindGridData", data).pipe(tap(e => {
      const entities: Group[] = e.data;
      this.store.set(entities);
    }));
  }

  add(group: Group) {
    this.store.add(group);
  }

  update(id, group: Partial<Group>) {
    this.store.update(id, group);
  }

  setActive(ids: ID[]){
    this.store.setActive(ids);
  }
  active(ids: ID[]) {
    this.store.addActive(ids);
  }
  inActiveAll() {
    const ids = this.query.getActiveId();
    this.inActive(ids)
  }

  inActive(ids: ID[]) {
    const activeIds = this.query.getActiveId();
    const idsTobeMadeInactive = ids.filter(id => activeIds.includes(id));
    this.store.toggleActive(idsTobeMadeInactive);
  }
}
