import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Patient } from './patient.model';
import { PatientStore, PatientState } from './patient.store';

@Injectable({ providedIn: 'root' })
export class PatientQuery extends QueryEntity<PatientState> {

  constructor(protected store: PatientStore) {
    super(store);
  }

  findPatientByStatus(status: string): Observable<Patient[]>{
   return this.selectAll().pipe(map(res => res.filter(r => r.status === status)));
  }
}
