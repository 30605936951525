import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { createAttachment } from '../state/attachment.model';
import { AttachmentService } from '../state/attachment.service';
import { AccessService } from 'src/app/shared/services/access.service';
import {ToastrService} from 'ngx-toastr';
// import { ConsoleReporter } from 'jasmine';
@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.css'],
})
export class AddAttachmentComponent implements OnInit {
  @Input() label: string;
  @Input() callerId: string;
  @Input() showCancel: boolean;
  @Output() onUpload = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  // @Output() comment = new EventEmitter<string>();
@Output() onComment = new EventEmitter<any>();
onCommentValue:any;
  privileges: any;
  file: any;
  fileName: string;
  
  constructor(
    private attachmentService: AttachmentService,
    private accessServices: AccessService,
    public toastr: ToastrService
  ) {}

 

  ngOnInit(): void {
    console.log(this.callerId,"callerID")
    this.privileges = this.accessServices.navbar_access
  }

  fileAttachChanged(event) {
    console.log('AddAttachmentComponent', event.target.files);
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    this.onComment.emit(this.onCommentValue)
  }

  uploadAttachment() {
    
      if(this.file == null){
        this.toastr.error("Oops. Please upload a file!", 'Error!');
      return;
      }
    
    const attachment = createAttachment({
      id: this.file.name,
      file: this.file,
      callerId: this.callerId, 
    });
    console.log(attachment);
    this.attachmentService.add(attachment);
    this.onComment.emit(this.onCommentValue);
    this.onUpload.emit();
  //   this.toastr.success("Data Uploaded Successfully", 'Success!');
  //  if(this.toastr.success){
  //   window.location.pathname=("patient/manage-patient")
  //  }
 }
   
    // this.onComment.emit(this.onCommentValue)
  

    



  onClickClose() {
    this.onCancel.emit();
  }
}
