import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { combineAll, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { createFacility, Facility } from './facility.model';
import { FacilityStore } from './facility.store';
import { ApiService } from 'src/app/shared/services/api.service';
import { FacilityQuery } from './facility.query';
import { GroupsQuery } from '../groups/groups.query';
import { forkJoin, Observable, of } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class FacilityService {

  constructor(
    private store: FacilityStore, 
    private apiService: ApiService, 
    private query: FacilityQuery,
    private toastr: ToastrService,
    private groupsQuery: GroupsQuery) {
  }

reset(){
  this.store.set([]);
}
  get() {
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };
    return this.apiService.post("FacilityMaster/BindGridData", data).pipe(tap(e => {
      const entities: Facility[] = e.data;
      this.store.set(entities);
    }));
  }

  add(facility: Facility) {
    this.store.add(facility);
  }

  update(id, facility: Partial<Facility>) {
    this.store.update(id, facility);
  }
  setActive(ids: ID[]){
    this.store.setActive(ids);
  }

  getActive(){
    return this.store;
  }

  active(ids: ID[]) {
    this.store.addActive(ids);
  }
  inActiveAll() {
    const ids = this.query.getActiveId();
    this.inActive(ids)
  }

  inActive(ids: ID[]) {
    const activeIds = this.query.getActiveId();
    const idsTobeMadeInactive = ids.filter(id => activeIds.includes(id));
    this.store.toggleActive(idsTobeMadeInactive);
  }

  setFacilitiesofActiveGroup(){
    this.store.set([]);
    let activeId =this.groupsQuery.getActiveId();
    if(!Array.isArray(activeId)){
      activeId = [activeId];
    }
    
     const request$ = activeId.map(id => this.getFacilitiesByGroup(id));
     request$.forEach(req => {
       req.subscribe(res => {
         this.store.add(res);
       })
     });
   }

   getFacilitiesByGroup(id: ID): Observable<Facility[]>{ 
    return this.apiService.get("FacilityGroup/GetGroupFacilities?GroupId="+id)
    .pipe(map(res => res.data));
  }

  set(res){
    this.store.set(res);
  }

  setFacilityGroup(ids: ID[]){
    let data = { "Group": ids };
    this.apiService.post("FacilityGroup/GetFacilityGroup", data).subscribe(res => {
      if(res.status === 'Success'){
       this.set(res.data);
      }else{
        this.toastr.error('Error', res.message);
      }
    })
  }

  fetchAndStoreFacilityForPatient(){
    this.store.setLoading(true);
    return this.apiService.get("CreatePatient/BindFacilityData").pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Faciled to load facility list', e.message);
        return of();
      }
      const entities: Facility[] = e.data;
      this.store.set(entities);
      this.store.setLoading(false);
    }));
  }
  
  bindDashboardFacilityData(){
    this.store.setLoading(true);
    return this.apiService.get("CreatePatient/BindDashboardFacilityData").pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Faciled to load facility list', e.message);
        return of();
      }
      const entities: Facility[] = e.data;
      this.store.set(entities);
      this.store.setLoading(false);
    }));
  }

  fetchAndStoreFacilityForPatientWithAllOption(){
    this.store.setLoading(true);
    return this.apiService.get("CreatePatient/BindDashboardFacilityData").pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Faciled to load facility list', e.message);
        return of();
      }
      const entities: Facility[] = [createFacility({id: "0", name: 'All Facilities'}), ...e.data];
      this.store.set(entities);
      this.store.setLoading(false);
    }));
  }
}
