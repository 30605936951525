import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { KbRoutingModule } from './kb-routing.module';
import { KbComponent } from './kb.component';
import { KbFormComponent } from './kb-form/kb-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TextMaskModule } from 'angular2-text-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [KbComponent, KbFormComponent],
  imports: [
    CommonModule,
    KbRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    TextMaskModule,
    NgbModule
  ]
})
export class KbModule { }
