import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as fileSaver from 'file-saver';
import { environment } from 'src/environments/environment';
import { AccessService } from 'src/app/shared/services/access.service';
import { implantLog, invoice, NameConstant } from 'src/app/shared/models/name-constant';
import { ChangeDetectorRef } from '@angular/core';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-facility-details',
  templateUrl: './facility-details.component.html',
  styleUrls: ['./facility-details.component.css']
})
export class FacilityDetailsComponent implements OnInit {

 
  p1: number = 1;
  @Output() closeEmitter = new EventEmitter();
  _facility_id: any;
  facility_data:any;
  categoryAttachment:any;
  fieldArr=[];
  facilityfields:any;
  pageValue1: any = 10;
  totalRows2: any;
  totalRows:any;
  pagenumber1: any = 1;
  snoList1: any = 1;
  searchValue1: string = "";
  contactDetails:any;
  notificationDetails:any;
  pricing:boolean =false;
  facilityPricingArry:any;
  baseUrl: string = environment.baseUrl;
  imageUrl:string = "";
  @Input() set facility_id(value: any) {
    if (value) {
      this._facility_id = value;
    }
    console.log(this._facility_id,"formdata");
  }
  constructor(
    private getApiService: ApiService,
    private toastr: ToastrService,
    private accessService :AccessService,
    private cdRef:ChangeDetectorRef
  ) {
    console.log("inside child constructor")
    // this.getfacilityPricing();
   }

  ngOnInit(): void {
    // this._facility_id = this.accessService.facility;
    console.log(this._facility_id)
    console.log("inside child");
    this.getData(); 
    this.getFacilityCustomData();
    this.getFacilityAttachmentData();
    this.getNotificationDetails();
    this.getContactDetails();
    // this.getfacilityPricing();
    
    
  }
  
  sortListPricing(orderBy,sortDir){
    let  data = { "FacilityId":this.accessService.facility,"pageNum": this.pagenumber1, "pageSize": this.pageValue1, "SearchValue": this.searchValue1,"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("Pricing/BindGrid", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityPricingArry = resp.data;
        // this.totalRows2 = resp.data[0]?.totalcount;
        this.totalRows = resp.data[0]?.totalcount;
       console.log(this.facilityPricingArry,"facilityGroupArry");
        // this.selectedFacilities = resp.data;

      }
  }, (err) => {
    console.log(err);
  })
}
sortListContactDetails(orderBy,sortDir){
  let  data = { "FacilityId":this.accessService.facility,"OrderBy":orderBy,  "SortDir":sortDir };
  this.getApiService.post("FacilityContact/BindGridData", data).subscribe((resp: any) => {

    if (resp.status == "Success") {
      this.contactDetails = resp.data;

    }
}, (err) => {
  console.log(err);
})
}
sortListNotificationDetails(orderBy,sortDir){
  let data = { "facilityID": this.accessService.facility,"OrderBy":orderBy,  "SortDir":sortDir };

  this.getApiService.post("FacilityNotification/BindGridData", data).subscribe((resp: any) => {
    // console.log(resp,'resp');
    if (resp.status == "Success") {
      this.notificationDetails = resp.data;
     

    }

  }, (err) => {
    console.log(err);
  })
}
sortListFacilityCustomData(orderBy,sortDir){
  let data = { "FacilityId":this._facility_id,"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("CustomField/GetFacilityCustomField", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityfields = resp.data;
      
      }

    }, (err) => {
      console.log(err);
    })
}
sortFacilityAttachmentData(orderBy,sortDir){
  let data = { "FacilityId":this._facility_id,"OrderBy":orderBy,  "SortDir":sortDir };
  this.getApiService.post("FacilityMaster/GetFacilityAttachment", data).subscribe((resp: any) => {

    if (resp.status == "Success") {
      this.categoryAttachment = resp.data;
   
    }

  }, (err) => {
    console.log(err);
  })
}
  getData() {
    let data = { "id": this.accessService.facility };
     this.getApiService.post("FacilityMaster/GetFacilityDetails", data).subscribe((resp: any) => {
       console.log(resp,"response");
       if (resp.status == "Success") {
        
        this.facility_data = resp.data;
        
        if( this.facility_data[0].fileName != null){
          this.imageUrl = this.baseUrl+"/FacilityLogo/" + this.facility_data[0].fileName;
        }else if(this.facility_data[0].logo != null && this.facility_data[0].fileName != null){
        
          this.imageUrl = this.baseUrl+"/FacilityLogo/" + this.facility_data[0].fileName;
        }else if(this.facility_data[0].logo != null && this.facility_data[0].fileName == null){
         
          this.imageUrl = this.baseUrl+"/FacilityLogo/" + this.accessService.facility + ".jpg";
        }else{
        
          this.imageUrl ='';
        }
        
       
       
      }
    }, (err) => {
      console.log(err);
    })
  }
  showPricing(){
    this.pricing =true;
    this.getfacilityPricing();
  }
  getfacilityPricing() {
    if (this.searchValue1 != '' && this.pagenumber1 != 1) {
      this.pagenumber1 = 1;
      this.p1 = 1;
    }
    
    // let  data = { "FacilityId":this._facility_id,"pageNum": this.pagenumber1, "pageSize": this.pageValue1, "SearchValue": this.searchValue1 };

    // this.getApiService.post("Pricing/BindGrid", data).subscribe((resp: any) => {
      let  data = { "FacilityId":this.accessService.facility,"pageNum": this.pagenumber1, "pageSize": this.pageValue1, "SearchValue": this.searchValue1 };

     this.getApiService.post("Pricing/BindGrid", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityPricingArry = resp.data;
        
        console.log(this.facilityPricingArry,"facilityPricingArry");
        // this.totalRows2 = resp.data[0].totalcount;
        this.totalRows = resp.data[0]?.totalcount;
        // this.selectedFacilities = resp.data;
      }
    }, (err) => {
      console.log(err);
    })
  }

//   ngAfterViewChecked()
// {
//   console.log( "! changement de la date du composant !" );
//   // this.totalRows2 
//   console.log(this.totalRows2)
  
//   this.cdRef.detectChanges();
// }
  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue1 = value;
    this.pagenumber1 = 1;
    this.facilityPricingArry = [];
    this.getfacilityPricing();
  }

  pageChange(event) {
    this.pagenumber1 = event;
    this.getfacilityPricing();
  }
  downloadImplantLogFile(data: any){
    this.getFile(implantLog, data);
  }

  downloadInvoiceFile(data: any){
    this.getFile(invoice, data);
  }
  private getFile(name: NameConstant, pdata: any){
    let data = {
      "facilityId": pdata.facilityid,
      "CPT": pdata.cpt,
      "fileType": name.value
    }
    this.getApiService.post4File("BillingNew/DownloadPricingFile", data).subscribe(res => {
      saveAs(res, name.value);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  getContactDetails() {
    let data = { "facilityID": this.accessService.facility };

    this.getApiService.post("FacilityContact/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.contactDetails = resp.data;
       

      }

    }, (err) => {
      console.log(err);
    })
  }
  getNotificationDetails(){
    let data = { "facilityID": this.accessService.facility };

    this.getApiService.post("FacilityNotification/BindGridData", data).subscribe((resp: any) => {
      // console.log(resp,'resp');
      if (resp.status == "Success") {
        this.notificationDetails = resp.data;
       

      }

    }, (err) => {
      console.log(err);
    })
  }
  getFacilityCustomData() {
    let data = { "FacilityId": this.accessService.facility };
   // console.log("edit id",this.edit_id);
    this.getApiService.post("CustomField/GetFacilityCustomField", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityfields = resp.data;
       

      }

    }, (err) => {
      console.log(err);
    })
  }
  getFacilityAttachmentData() {
    let data = { "FacilityId": this.accessService.facility};
   // console.log("edit id",this._facility_id);
    this.getApiService.post("FacilityMaster/GetFacilityAttachment", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.categoryAttachment = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  downloadFile(data){
    this.getApiService.downloadFile("FacilityMaster/DownloadFile?filename="+data.fileName).subscribe(response => {
			let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
			//window.open(url);
			//window.location.href = response.url;
			fileSaver.saveAs(blob, data.fileName);
		}), error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
   
    
  }
  onClickClose(){
    this.closeEmitter.emit();
  }
  onsel(){
    this.getfacilityPricing();

  }

}

