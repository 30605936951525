<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
    <p class="bulk-action-loader"> Loading... </p>
</ngx-spinner>
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active" *ngIf="(bulkActionSelection$ | async) === ''">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h6 class="m-0 font-weight-bold text-primary">Bulk Action</h6>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <form class="pb-3 form-fields" [formGroup]="BulkActionForm" *ngIf="BulkActionForm">
                <div class="row" *ngIf="!isClientRole">
                    <div class="form-group col-6">
                        <label for="assignAll">
                            <h3>Move To</h3>
                        </label>
                    </div>
                    <div class="form-group col-6">
                        <div class="input-group">
                            <ngx-select-dropdown [config]="configForQueue" (change)="onQueueChange($event)"
                                [options]="caseRequestList$ | async" style="width: 100%;" autoClearSearch=" true"
                                [multiple]="false">
                            </ngx-select-dropdown>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="isClientRole">
                    <div class="form-group col-6">
                        <label for="assignAll">
                            <h3>Add comment to all</h3>
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="input-group">
                            <textarea class="form-control" formControlName="globalComment" (keyup)="updateComment(globalCommentFormControl.value)" placeholder="Comment"></textarea>
                        </div>
                    </div>
                </div>
                <div class="table-responsive" style="min-height: 22rem;">
                    <table class="table" id="dataTable" width="auto" cellspacing="0">
                        <thead>
                            <tr style="color: #333;">
                                <th *ngFor="let col of bulkActionColDefList">{{col.colDisplayName}}</th>
                                <th *ngIf="!isClientRole">Move To</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr style="color: #333;">
                                <th *ngFor="let col of bulkActionColDefList">{{col.colDisplayName}}</th>
                                <th *ngIf="!isClientRole">Move To</th>
                                <th>Action</th>
                            </tr>
                        </tfoot>
                        <tbody>
                            <tr *ngFor="let elem of activePatient" style="color: #333;
                        font-weight: 400;">
                                <td>{{elem[bulkActionColDefList[0].colId]}}</td>
                                <td>{{elem[bulkActionColDefList[1].colId]}}</td>
                                <td>{{elem[bulkActionColDefList[2].colId]}}</td>
                                <td>{{elem[bulkActionColDefList[3].colId]}}</td>
                                <td style="width: 25%;" *ngIf="!isClientRole">
                                    <ngx-select-dropdown [config]="configForQueue" [options]="caseRequestList$ | async"
                                        formControlName="{{elem.bdosid}}" (change)="onRowQueueChange($event, elem)"
                                        autoClearSearch="true" [multiple]="false" >
                                    </ngx-select-dropdown>
                                    <span *ngIf="hasFormControlError(elem.bdosid)" class="invalid-feedback">
                                        {{getFormControlError(elem.bdosid)}}
                                    </span>
                                </td>
                                <td>
                                    <a *ngIf="!isClientRole" href="javascript:void(0);" (click)="removeActive(elem)" class="closeFileSidenav">
                                        <i class="fas fa-times fa-sm"></i>
                                    </a>
                                    <span *ngIf="isClientRole && hasFormControlError(elem.bdosid)" class="invalid-feedback">
                                        {{getFormControlError(elem.bdosid)}}
                                    </span>
                                        <a *ngIf="getRequestStatus(elem.bdosid) === defaultStatus" href="javascript:void(0)" class="icon-color-orange openMySidenav"
                                             style="padding-left: 10px;">
                                            <i title="Action" class="fas fa-comment-alt" 
                                            [ngClass]="{'icon-color-darkgreen': isCommentOrAttachmentAdded(elem.bdosid)}"
                                            (click)="onClickAction(elem)"></i>
                                        </a>
                                        <a *ngIf="getRequestStatus(elem.bdosid) === pendingStatus" href="javascript:void(0)" class="icon-color-orange openMySidenav"
                                           style="padding-left: 10px;">
                                            <i class="fas fa-hourglass-start" 
                                            [ngClass]="{'icon-color-darkgreen': isCommentOrAttachmentAdded(elem.bdosid)}"
                                            (click)="onClickAction(elem)"></i>
                                        </a>
                                        <a  *ngIf="getRequestStatus(elem.bdosid) === approvedStatus" href="javascript:void(0)" class="icon-color-orange openMySidenav"
                                            style="padding-left: 10px;">
                                            <i class="fas fa-check" 
                                            [ngClass]="{'icon-color-darkgreen': isCommentOrAttachmentAdded(elem.bdosid)}"
                                            (click)="onClickAction(elem)"></i>
                                        </a>
                                        <a *ngIf="getRequestStatus(elem.bdosid) === rejectStatus" href="javascript:void(0)" class="icon-color-orange openMySidenav"
                                            style="padding-left: 10px;">
                                            <i class="fas fa-ban" 
                                            [ngClass]="{'icon-color-darkgreen': isCommentOrAttachmentAdded(elem.bdosid)}"
                                            (click)="onClickAction(elem)"></i>
                                        </a>
                                        
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-right" style="margin: 75px 0px;">
                    <button type="button" (click)="onClickClose()"
                        class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                    <button type="button" (click)="onBulkSubmit()" [disabled]="loading"
                    class="btn btn-primary btn-patient-box">Submit
                    <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
    <div id="mySidenav" class="sidenav shadow width-700  active" *ngIf="(bulkActionSelection$ | async) !== ''">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h6 class="m-0 font-weight-bold text-primary">Bulk Action</h6>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <app-patient-info-header  [callerId]="componentId" [bdosid]="selectedPatient.bdosid" (closeEmitter)="backToBulkScreen()">
        </app-patient-info-header>
        <div class="card-body bulk-action-body-act-comp">
            <container-element [ngSwitch]="(bulkActionSelection$ | async)">
                <!-- the same view can be shown in more than one case -->
                <app-cr-op-note *ngSwitchCase="operatingReport.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-op-note>
                <app-cr-pathology *ngSwitchCase="pathology.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-pathology>
                <app-cr-pricing *ngSwitchCase="pricing.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-pricing>
                <app-cr-block-report *ngSwitchCase="blockReport.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-block-report>
                <app-cr-coding-query *ngSwitchCase="codingQuery.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-coding-query>
                <app-cr-other-misc *ngSwitchCase="otherMisc.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-other-misc>
                <app-cr-auth-review *ngSwitchCase="authReview.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-auth-review>
                <app-cr-client-high *ngSwitchCase="clientHighApproval.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-client-high>
                <app-cr-nsn-high *ngSwitchCase="nsnHighApproval.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-nsn-high>
                <app-cr-coding-expert *ngSwitchCase="codingExpert.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-coding-expert>
                <app-cr-in-coding *ngSwitchCase="inCoding.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-in-coding>
                <app-cr-variance *ngSwitchCase="variance.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-variance>
                <app-cr-invoice *ngSwitchCase="invoice.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-invoice>
                <app-cr-implant-log *ngSwitchCase="implantLog.id" [callerId]="componentId" [bdosid]="selectedPatient.bdosid"></app-cr-implant-log>

                <!--default case when there are no matches -->
                <div class="card-body" *ngSwitchDefault>
                    No Component Found for id {{(bulkActionSelection$ | async)}}
                </div>
            </container-element>
        </div>
    </div>
</div>