import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { caseQueueFacilityProductivityReportLine, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CaseQueueFacilityReport } from '../../state/case-queue-facility-report/case-queue-facility-report.model';
import { CaseQueueFacilityReportQuery } from '../../state/case-queue-facility-report/case-queue-facility-report.query';
import { CaseQueueFacilityReportService } from '../../state/case-queue-facility-report/case-queue-facility-report.service';
import { CaseQueueFacilityReportLine, createCaseQueueFacilityReportLine } from './case-queue-facility-report-line';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-case-queue-facility-report-line',
  templateUrl: './case-queue-facility-report-line.component.html',
  styleUrls: ['./case-queue-facility-report-line.component.css']
})
export class CaseQueueFacilityReportLineComponent implements OnInit {
  componentName: NameConstant = caseQueueFacilityProductivityReportLine;

  @Input() selFromDate;
  @Input() selToDate;
  coloumns: ColDef[] = [];
  activeEntity: CaseQueueFacilityReport;
  activeEntityId$: Observable<number | string>;

  datasource$: Observable<CaseQueueFacilityReportLine[]>;
  loading: boolean;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  destroy$ = new Subject();
  constructor(
    private caseQueueFacilityReportService: CaseQueueFacilityReportService,
    private caseQueueFacilityReportQuery: CaseQueueFacilityReportQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
  ) { }

  ngOnInit(): void {
    this.initColoumns();
    this.initDataSource();
    this.activeEntityId$ = this.caseQueueFacilityReportQuery.selectActiveId();
    this.commonUtil.setTitle(this.componentName.value);
  }

  ngOnDestroy(){
    this.destroy$.next(true);
  }
  back(){
    const activeId = this.caseQueueFacilityReportQuery.getActiveId();
    this.caseQueueFacilityReportService.toggleActive(String(activeId));
  }

  initDataSource(){
    this.loading = true;
    this.activeEntity = this.caseQueueFacilityReportQuery.getActive();
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "pageNum": this.currentPage,
      "pageSize": this.itemsPerPage,
      "facilityId": this.activeEntity.facilityId
  }
    this.datasource$ = this.apiService.post("Reports/CaseQueueReportByFacilityId", requestBody)
    .pipe(map(res => {
      if(res.status === 'Success'){
        this.loading = false;
        const entities: CaseQueueFacilityReportLine[] = res.data.map(r => createCaseQueueFacilityReportLine({
          facilityName: r.facilityName,
          mrn: r.mrn,
          importDate: r.importDate,
          setBilledDate: r.setBilledDate,
          totalcount: r.totalcount
        }));
        return entities;
      }else{
        this.loading = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.toaster.error("Unexpected Error!!", err);
    }));

    this.datasource$.pipe(takeUntil(this.destroy$)).subscribe( entities=> {
      this.totalRows = entities.find(e => true)?.totalcount;
      console.log("totalRows", this.totalRows);
    })
  }

  initColoumns(){
    this.coloumns = [...this.coloumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "mrn", displayName: "MRN", isDataCol: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "importDate", displayName: "Loaded to Navigator", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "setBilledDate", displayName: "Set Billed Date", isDataTypeDate: true })];
   // this.coloumns = [...this.coloumns, createColDef({ name: "", displayName: "Action", isDataCol: false, isDataTypeDate: false, isAction: true })];
  }
  
  pageChange(event$){
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch(){
    this.initDataSource();
  }


  onExport(){
    this.loading = true;
    this.activeEntity = this.caseQueueFacilityReportQuery.getActive();
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "facilityId": this.activeEntity.facilityId
  }

    this.apiService.post4File("Reports/DownloadCaseQueueReportByFacilityId", requestBody).subscribe(res => {
     // saveAs(res, this.componentName.value+".xlsx");
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
			saveAs(blob, this.activeEntity.facilityName+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }
}
