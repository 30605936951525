import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { batchCaseReview, codingReview } from '../../models/name-constant';
import { ApiService } from '../../services/api.service';
import { Comment, createComment } from './comment.model';
import { CommentsStore } from './comments.store';

@Injectable({ providedIn: 'root' })
export class CommentsService {

  constructor(private commentsStore: CommentsStore, private apiService: ApiService, private toastr: ToastrService) {
  }


  get(callerId): Observable<any>{
    console.log("initialising the comment store for ", callerId);
    if(callerId && callerId === batchCaseReview){
      this.commentsStore.set([createComment({ id: batchCaseReview.id, note: "" })]);
      return of();
    }else if(callerId && callerId === codingReview){
      this.commentsStore.set([createComment({ id: codingReview.id, note: "" })]);
      return of();
    }else{
      return this.apiService.post('PreformattedNotes/BindGridData', {}).pipe(tap(entities => {
        if(entities.status === "Success"){
  
          const commetsList = entities.data.map(d => createComment({ id: d.id, reqType: d.reqType, note: d.note, userid: d.userid, createddate: d.createddate }));
          this.commentsStore.set(commetsList);
        }else{
          this.toastr.error("Unable to load comments from api, please try again");
        }
      }));
    }
  }

  reset(){
    this.commentsStore.reset();
  }

  destroy(){
    this.commentsStore.destroy();
  }

  add(comment: Comment) {
    this.commentsStore.add(comment);
  }

  update(id, comment: Partial<Comment>) {
    this.commentsStore.update(id, comment);
  }

  remove(id: ID) {
    this.commentsStore.remove(id);
  }

}
