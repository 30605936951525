import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { ShareDataService } from 'src/app/shared/services/share-data.service';

@Component({
  selector: 'app-facility-master',
  templateUrl: './facility-master.component.html',
  styleUrls: ['./facility-master.component.css']
})
export class FacilityMasterComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  FacilityMasterArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private shareDataService: ShareDataService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Facility Master");
    this.pagenumber = 1;
    this.pageValue = 10;
    this.p = 1;
    this.searchValue = "";
    this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
  }

  sortListFacility(orderBy,sortDir){
    let  data = {"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("FacilityMaster/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.FacilityMasterArry = res.data;
        this.totalRows = res.data[0]? res.data[0].totalcount : 0;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showFormModal(data) {
    this.formData = data;
    this.shareDataService.share(data);
    
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.pagenumber = 1;
      this.pageValue = 10;
      this.p = 1;
      this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }


  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  getFacilityMasterList(pageNumber: number, pageSize: number, searchValue: string) {
    let data: any;
    // if (this.searchValue != '' && this.pagenumber != 1) {
    //   this.pagenumber = 1;
    //   this.p = 1;
    // }

    data = { "pageNum": pageNumber, "pageSize": pageSize, "SearchValue": searchValue };

    this.getApiService.post("FacilityMaster/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.FacilityMasterArry = res.data;
        this.totalRows = res.data[0]?.totalcount;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  editFacilityMaster(data) {
    this.formData = data;
    //this.router.navigate(['',this.formData])
    // this.router.navigateByUrl('/dynamic', { state: this.product });
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.p = 1;
    this.FacilityMasterArry = [];
    this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
  }

  delFacilityMaster(delRow) {

    let data = { "id": delRow.id, "deleted": !delRow.deleted };

    this.getApiService.post("FacilityMaster/DeactivateFacilities", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    console.log('Page chnage', this.pagenumber, event);
    this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
  }

  onClickClose() {
    this.shouldShowFormModal = false;
    this.closeEmitter.emit();
  }

  onSearch(){
    this.pagenumber = 1;
    this.p = 1;
    this.getFacilityMasterList(this.pagenumber, this.pageValue, this.searchValue );
  }
}
