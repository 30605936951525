import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-eob-aran-form',
  templateUrl: './eob-aran-form.component.html',
  styleUrls: ['./eob-aran-form.component.css']
})
export class EobAranFormComponent implements OnInit {
  submit:any="submit";
  @Output() closeEmitter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  onClickClose() {
    this.submit="Submiting"
    this.closeEmitter.emit();
  }
}
