import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, forkJoin, Observable, of, Subscription, zip } from 'rxjs';
import { concatMap, last, map, skipUntil, switchMap, take } from 'rxjs/operators';
import { billerOnHold, implantLog, invoice, NameConstant } from 'src/app/shared/models/name-constant';
import { CategoryItem } from 'src/app/shared/state/category-item/category-item.model';
import { CategoryItemQuery } from 'src/app/shared/state/category-item/category-item.query';
import { CategoryItemService } from 'src/app/shared/state/category-item/category-item.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { CommandTypeHelper } from '../../helpers/command-config.helper';
import { DashDetailHelper } from '../../helpers/dash-detail.helper';
import { AccordianConfig } from './nsn-accordian-config';
import { createNSNAccordian, NSNAccordian } from './state/nsn-accordian.model';
import { NSNAccordianQuery } from './state/nsn-accordian.query';
import { NSNAccordianService } from './state/nsn-accordian.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { saveAs } from 'file-saver';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nsn-accordian',
  templateUrl: './nsn-accordian.component.html',
  styleUrls: ['./nsn-accordian.component.css'],
  providers: [NgxSpinnerService]
})
export class NsnAccordianComponent implements OnInit, OnDestroy {
  @Input("skipCategory") skipCategory: string;
  @Input("callerId") callerId: string;
  @Input("isChildAccordian") isChildAccordian: boolean;

  @Input("accordianConfig") accordianConfig: AccordianConfig;
  private accordianConfigSubscription: Subscription;
  facilitySub: Subscription;
  patientSub: Subscription;
  categoryItemSub: Subscription;

  nsnAccordians$: Observable<NSNAccordian[]>;
  categoryItems$: Observable<CategoryItem[]>;
  patients$: Observable<Patient[]>;
  activeAccordian$: Observable<NSNAccordian>;
  searchValue = "";
  p;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: number = 1;
  shouldShowReturnActionFormModal: boolean = false;
  shouldShowFormModal: boolean = false;
  shouldShowCodingModal: boolean = false;
  shouldShowCaseRequestModal: boolean = false;
  shouldShowDetailModal: boolean = false;
  shouldShowQAOPFormModal: boolean = false;
  shouldShowQAICFormModal: boolean = false;
  shouldShowQAATFormModal: boolean = false;
  shouldShowToolsModal: boolean = false;
  SelectAllCheckboxForGrid: boolean = false;
  gridCheckboxStatus:boolean = false;
  formData: any = null;
  dashDetailHelper: DashDetailHelper;
  activeFacilityId$: Observable<string | number>;

  facility_id; // used to open facilitity dertail component only to pass the click facility id

  sortType;
  sortOrder;

  isAccordianLoading$: Observable<boolean>;

  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private patientService: PatientService,
    private patientQuery: PatientQuery,
    private facilityQuery: FacilityQuery,
    private categoryItemQuery: CategoryItemQuery,
    private categoryItemService: CategoryItemService,
    private nsnAccordianService: NSNAccordianService,
    private nsnAccordianQuery: NSNAccordianQuery,
    private selectionService: SelectionService,
    private route: ActivatedRoute
  ) {
    this.dashDetailHelper = new DashDetailHelper();
  }

  ngOnInit(): void {
    if(localStorage.getItem("pageValue")!=null){
      this.pageValue=localStorage.getItem("pageValue")
    }else{
      this.pageValue=10;
    }
    this.patientService.reset();
    this.nsnAccordianService.reset();
    this.activeAccordian$ = this.nsnAccordianQuery.selectActive();
    this.nsnAccordians$ = this.nsnAccordianQuery.selectAll();
    console.log("NSN Accordian caller id", this.callerId);
    if (this.callerId === billerOnHold.id) {
      console.log(this.accordianConfig);
      this.loadAccordianByConfig(this.accordianConfig);
    } else {
      this.categoryItems$ = this.categoryItemQuery.selectAll().pipe(map(res => {
        return res.filter(r => r.value !== this.skipCategory);
      }));
      this.loadAccordian();
    }
  }

  private loadAccordianByConfig(pAccordianConfig: AccordianConfig) {
   
    if (!pAccordianConfig) {
      console.log('loadAccordianByConfig: config is missing', pAccordianConfig);
      return;
    }

    if (!pAccordianConfig.facilityId) {
      console.log('loadAccordianByConfig: facility id is missing', pAccordianConfig.facilityId);
      return;
    }

    if (!pAccordianConfig.catId) {
      console.log('loadAccordianByConfig: catId  is missing', pAccordianConfig.catId);
      return;
    }

    this.categoryItemSub = this.categoryItemService.loadCommandTypes(pAccordianConfig.facilityId, pAccordianConfig.catId.toString()).subscribe(res => {
console.log(res,"response1")
      let requestList$ = [];
      for (let index = 0; index < res.length; index++) {
        const cat = res[index];
        const commandTypeConfig = CommandTypeHelper.map.get(cat.value.toLocaleLowerCase());
        if (commandTypeConfig) {
          requestList$ = [...requestList$, this.patientService.loadPatientByConfig(pAccordianConfig, commandTypeConfig, cat)];
        } else {
          console.log("command type conifg is missing please add for ", cat.value);
        }
      }
      forkJoin(requestList$).subscribe(responseList => {
        console.log("res",res);
        console.log("server responce",responseList);
        for (let index = 0; index < responseList.length; index++) {
          
          console.log("----------------hI");
          console.log(index);
          console.log(res[index]);
          console.log(responseList[index]);


          const patientList = responseList[index] as any;
          const cat = res[index];
          const totalcount = patientList.data[0]?.totalcount;
          
          const count = totalcount ? totalcount : 0;

          if(count ==  0){continue;}

          const type = patientList.data[0]?.queueName;
          var typeObject;
        
          for(let y = 0;y < res.length ;y++){
            if(res[y].value == type){
              typeObject = res[y];
             
            }
          }

          const nsnAccord = createNSNAccordian({
            //name: cat.name.toString(),
           // value: cat.value,
           name : typeObject.name.toString(),
           value : typeObject.value,
            count: count,
            order: index,
            facilityId: pAccordianConfig.facilityId,
            searchValue: pAccordianConfig.searchValue
          });
          this.nsnAccordianService.add(nsnAccord);
          
        }


      });
    });

  }


  ngOnDestroy() {
    this.nsnAccordianService.reset();
    this.patientService.reset();
    if (this.accordianConfigSubscription) {
      this.accordianConfigSubscription.unsubscribe();
    }
    if (this.facilitySub) {
      this.facilitySub.unsubscribe();
    }
    if (this.patientSub) {
      this.patientSub.unsubscribe();
    }
    if (this.categoryItemSub) {
      this.categoryItemSub.unsubscribe();
    }
  }

  private loadAccordian() {
    this.activeFacilityId$ = this.facilityQuery.selectActiveId().pipe(map(res => res[0]));
    this.facilitySub = this.activeFacilityId$.subscribe(activeFacId => {
      this.patientService.reset();
      this.nsnAccordianService.reset();
      this.categoryItemSub = this.categoryItems$.subscribe(res => {
        res.forEach(r => {
          this.patientSub = this.patientService.loadCriticalitemsPatientList(activeFacId, r.value, this.pagenumber, this.pageValue, this.searchValue).subscribe(pres => {
         
            const count = pres.data[0]?.totalcount;
            const nsnAccord = createNSNAccordian({ name: r.name.toString(), value: r.value, count: count, order: this.dashDetailHelper.getAccordianOrder(r.value) });
            this.nsnAccordianService.add(nsnAccord);
          });
        });
      });
    });
  }
  // this search grid is called from critical item as this is handled seperatly not generic
  searchGrid(searchValue: string) {
    console.log('Search Called', searchValue);
    this.searchValue = searchValue;
    this.pagenumber = 1;
    //this.pageValue = 10;
    this.p = undefined;
    this.loadAccordian();
  }
  active(obj: NSNAccordian) {
    console.log("active accordian.. search value", this.searchValue);
    this.pagenumber = 1;
    //this.pageValue = 10;
    //this.pageValue = 50;
    console.log('nsn-accordian page value');
    console.log(this.pageValue);

    this.p = undefined;
    const mayHavefacId = this.facilityQuery.getActiveId().find(f => true);
    const facId = mayHavefacId ? mayHavefacId : 0;
    this.nsnAccordianService.setActive(obj.value);
    if (this.callerId === billerOnHold.id) {
      this.patientService.reset();
      this.patientSub = this.patientService.loadPatientByStatus(obj.facilityId, obj.value, this.pagenumber, this.pageValue, obj.searchValue)
        .subscribe(res => this.totalRows = res.data[0]?.totalcount);
      this.patients$ = this.patientQuery.selectAll();
    } else {
      this.patientSub = this.patientService.loadCriticalitemsPatientList(facId, obj.value, this.pagenumber, this.pageValue, this.searchValue)
        .subscribe(res => this.totalRows = res.data[0]?.totalcount);
      this.patients$ = this.patientQuery.findPatientByStatus(obj.value);
    }
  }

  inactive(obj: NSNAccordian) {
    this.nsnAccordianService.setInactive(obj.value);
    this.pagenumber = 1;
    //this.pageValue = 10;
    this.p = undefined;
    this.patientService.reset();
  }

  sortList(orderby, sortby) {
    this.sortType = sortby;
    this.sortOrder = orderby;
    this.patientService.reset();
    const facId = this.facilityQuery.getActiveId()[0];
    const activeAccordian = this.nsnAccordianQuery.getActive();
    this.pagenumber = 1;
    //this.pageValue = 10;
    this.p = undefined;
    if (this.callerId === billerOnHold.id) {
      this.patientSub = this.patientService.loadPatientByStatus(facId, activeAccordian.value, this.pagenumber, this.pageValue, this.searchValue)
        .subscribe(res => this.totalRows = res.data[0]?.totalcount);
    } else {
      this.patientSub = this.patientService.loadCriticalitemsPatientListWithSorting(
        facId, activeAccordian.value, this.pagenumber,
        this.pageValue, this.searchValue, this.sortType, this.sortOrder)
        .subscribe(res => this.totalRows = res.data[0]?.totalcount);
    }
  }

  showReturnMailActionOrCriticalItemActionFormModal(data) {
    this.formData = data;
    this.patientService.setActive([data.bdosid]);
    
    const activeAccordian = this.nsnAccordianQuery.getActive();
    if (activeAccordian && activeAccordian.value === 'returnedclaim') {
      this.shouldShowReturnActionFormModal = true;
      return;
    }
    this.shouldShowFormModal = !this.shouldShowFormModal;
  }

  showFormModal(data) {
    this.formData = data;
    this.patientService.setActive([data.bdosid]);
    if (this.shouldShowCaseRequestModal) {
      this.shouldShowCaseRequestModal = false;
    }
    else {
      this.shouldShowCaseRequestModal = true;
    }

    

    // const activeAccordian = this.nsnAccordianQuery.getActive();

    // if (activeAccordian && activeAccordian.value === 'returnedclaim') {
    //   this.shouldShowReturnActionFormModal = true;
    //   return;
    // }

    // if (this.callerId === billerOnHold.id) {

    //   if (this.shouldShowCaseRequestModal) {
    //     this.shouldShowCaseRequestModal = false;
    //   }
    //   else {
    //     this.shouldShowCaseRequestModal = true;
    //   }
    // } else {
    //   if (this.shouldShowFormModal) {
    //     this.shouldShowFormModal = false;
    //   }
    //   else {
    //     this.shouldShowFormModal = true;
    //   }
    // }
  }
  showQAOPFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAOPFormModal) {
      this.shouldShowQAOPFormModal = false;
    }
    else {
      this.shouldShowQAOPFormModal = true;
    }
  }

  showCodingModal(data) {
    this.patientService.setAndActivate([data]);
    this.formData = data;
    if (this.shouldShowCodingModal) {
      this.shouldShowCodingModal = false;
    }
    else {
      this.shouldShowCodingModal = true;
    }
  }

  showQAICFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAICFormModal) {
      this.shouldShowQAICFormModal = false;
    }
    else {
      this.shouldShowQAICFormModal = true;
    }
  }

  showQAATFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAATFormModal) {
      this.shouldShowQAATFormModal = false;
    }
    else {
      this.shouldShowQAATFormModal = true;
    }
  }

  showToolsFormModal(data) {
    this.formData = data;
    this.patientService.setActive([data.bdosid]);
    if (this.shouldShowToolsModal) {
      this.shouldShowToolsModal = false;
    }
    else {
      this.shouldShowToolsModal = true;
    }
  }

  showDetailModal(data) {
    console.log(data);
    this.formData = data;

    this.facility_id = data.facilityId;
    if (this.shouldShowDetailModal) {
      this.shouldShowDetailModal = false;
    }
    else {
      this.shouldShowDetailModal = true;
    }
  }

  onCloseFormModal() {
    console.log("Called in accordian");
    this.patientService.toggleAllActive();
    this.shouldShowCodingModal = false;
    this.shouldShowFormModal = false;
    this.shouldShowCaseRequestModal = false;
    this.shouldShowQAOPFormModal = false;
    this.shouldShowQAICFormModal = false;
    this.shouldShowQAATFormModal = false;
    this.shouldShowToolsModal = false;
    this.shouldShowDetailModal = false;
    this.shouldShowReturnActionFormModal = false;
    this.SelectAllCheckboxForGrid = false;
    location.reload();
  }

  pageChange(event) {
    this.pagenumber = event;
    const facId = this.facilityQuery.getActiveId()[0];
    const value = this.nsnAccordianQuery.getActiveId();
    if (this.callerId === billerOnHold.id) {
      this.patientService.reset();
      this.patientSub = this.patientService.loadPatientByStatus(facId, value, this.pagenumber, this.pageValue, this.searchValue)
        .subscribe(res => this.totalRows = res.data[0]?.totalcount);
      this.patients$ = this.patientQuery.selectAll();
    } else {
      this.patientSub = this.patientService.loadCriticalitemsPatientList(facId, value.toString(), this.pagenumber, this.pageValue, this.searchValue)
        .subscribe(res => this.totalRows = res.data[0]?.totalcount);
    }
  }

  onSubmitSuccess() {
    this.shouldShowFormModal = false;
    this.shouldShowCaseRequestModal = false;
    if (this.callerId === billerOnHold.id) {
      console.log(this.accordianConfig);
      this.loadAccordianByConfig(this.accordianConfig);
    } else {
      this.loadAccordian();
    }
  }
  /** Bulk Action methods */
  selectRow(data) {
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    this.patientService.toggleActive(bdosid);
    if(this.isRowActive(data)){
      this.updateSelectAllCheckboxforGridToTrue();
    } else {
      this.updateSelectAllCheckboxforGridToFalse();
    }
  }
  isRowActive(data): boolean {
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    return this.patientQuery.hasActive(bdosid);
  }
  updateSelectAllCheckboxforGridToFalse() {
    let allCheckboxes;
    let unchecked = false;
    var items:any = document.getElementsByClassName('gridAllCheckboxes') as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < items.length; i++) {
        let isChecked = items[i].checked;
        if(!isChecked) {  
          unchecked = true;
        } 
    }
    if(unchecked){
      this.SelectAllCheckboxForGrid = false;
    }
  }
  updateSelectAllCheckboxforGridToTrue() {
    let allCheckboxes;
    let checkedCount = 0;
    var items:any = document.getElementsByClassName('gridAllCheckboxes') as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < items.length; i++) {
        let isChecked = items[i].checked;
        if(isChecked) {  
          checkedCount++;
        } 
    }
    if(checkedCount == items.length){
      this.SelectAllCheckboxForGrid = true;
    }
  }
  checkAll(){

    //console.log(">>>"+this.SelectAllCheckboxForGrid);
    if(!this.SelectAllCheckboxForGrid) {
      this.gridCheckboxStatus = true;
    }    
    else {
      this.gridCheckboxStatus = false;      
    }
    let rowCount = document.getElementsByClassName("gridAllCheckboxes").length;
    let allCheckboxes;
    let evt = new MouseEvent('click', { bubbles: true,cancelable: true,view: window}); 
    for (let i = 0; i < rowCount; i++) {
      allCheckboxes = document.getElementsByClassName("gridAllCheckboxes")[i] as HTMLElement;
      allCheckboxes.dispatchEvent(evt);
    }

  }

  getFile(fileId, fileName){
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
      //console.log(res);
      //var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
      //console.log(JSON.stringify(res));
      //console.log(this.getApiService.webUrl + 'ClientImport/' +  res.demoFile);
      //window.open( this.getApiService.webUrl + 'ClientImport/' +  res.demoFile, "_blank", strWindowFeatures);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

}


