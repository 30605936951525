import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { FacilityQuery } from '../../../../../shared/state/facilities/facility.query';
import { FacilityService } from '../../../../../shared/state/facilities/facility.service'
import { Facility } from '../../../../../shared/state/facilities/facility.model';
import { Group } from 'src/app/shared/state/groups/group.model';
import { Payer } from 'src/app/shared/state/payers/payer.model';
import { GroupsQuery } from 'src/app/shared/state/groups/groups.query';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { PayersQuery } from 'src/app/shared/state/payers/payers.query';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { CustomQueueService } from '../../state/custom-queue.service';
import { createCptCode, createCustomQueue, createFacilityId, createGroupId, createPayerId, createPhysicianId, CustomQueue } from '../../state/custom-queue.model';
import { Physician } from 'src/app/shared/state/physician/physician.model';
import { PhysicianQuery } from 'src/app/shared/state/physician/physician.query';
import { PhysicianService } from 'src/app/shared/state/physician/physician.service';
import { Cpt } from 'src/app/shared/state/cpt/cpt.model';
import { CptService } from 'src/app/shared/state/cpt/cpt.service';
import { CptQuery } from 'src/app/shared/state/cpt/cpt.query';
@Component({
  selector: 'app-custom-queue-form',
  templateUrl: './custom-queue-form.component.html',
  styleUrls: ['./custom-queue-form.component.css'],
  providers: [NgxSpinnerService]
})
export class CustomQueueFormComponent implements OnInit {

  customQueueAddForm: FormGroup;
  filetoUpload;
  formFlag: string = "Add";
  reasonString: string = "Reason";
  typeString: string = "Type";
  istemplateVisible: boolean = false;
  _formData: any;
submit:any="Submit"
  usernameList$: Observable<any[]>;

  availableGroups$: Observable<Group[]>;
  activeGroups$: Observable<Group[]>;
  selectedGroupToAdd;
  selectedGroupToRemove;

  availableFacilities$: Observable<Facility[]>;
  activeFacilities$: Observable<Facility[]>;
  selectedFacilityToAdd;
  selectedFacilityToRemove;

  availablePhysicians$: Observable<Physician[]>;
  activePhysicians$: Observable<Physician[]>;
  selectedPhysicianToAdd;
  selectedPhysicianToRemove;

  availablePayers$: Observable<Payer[]>;
  activePayers$: Observable<Payer[]>;
  selectedPayerToAdd;
  selectedPayerToRemove;

  availableCpts$: Observable<Cpt[]>;
  activeCpts$: Observable<Cpt[]>;
  selectedCptToAdd;
  selectedCptToRemove;

  submitted: boolean = false;

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private groupQuery: GroupsQuery,
    private groupService: GroupsService,
    private payerQuery: PayersQuery,
    private payersService: PayersService,
    private facilityQuery: FacilityQuery,
    private facilityService: FacilityService,
    private selectionService: SelectionService,
    private customQueueService: CustomQueueService,
    private physicianQuery: PhysicianQuery,
    private physicianService: PhysicianService,
    private cptService: CptService,
    private cptQuery: CptQuery
  ) { }

  ngOnInit(): void {
    this.availableGroups$ = this.groupQuery.selectAllInactive();
    this.activeGroups$ = this.groupQuery.selectActive();
    
    this.availableFacilities$ = this.facilityQuery.selectAllInactive();
    this.activeFacilities$ = this.facilityQuery.selectActive();

    this.availablePhysicians$ = this.physicianQuery.selectAllInactive();
    this.activePhysicians$ = this.physicianQuery.selectActive();

    this.availablePayers$ = this.payerQuery.selectAllInactive();
    this.activePayers$ = this.payerQuery.selectActive();

    this.availableCpts$ = this.cptQuery.selectAllInactive();
    this.activeCpts$ = this.cptQuery.selectActive();
    
    this.initForm();
  }

  onSubmit() {
    this.submitted = true;
    if(!this.customQueueAddForm.valid){
      
      return;
    }
    else{
      this.submit="Submiting"
    }

    const customQueue = this.buildCustomQueueObj();
    this.customQueueService.add(customQueue, 1, 1000, "");
    this.onClickClose();
  }

  private buildCustomQueueObj(): CustomQueue{
    const queue = createCustomQueue({
      queueName: this.customQueueAddForm.controls["queueName"]?.value,
      id: this.customQueueAddForm.controls["id"]?.value,
      description: this.customQueueAddForm.controls["description"]?.value
    });
    queue.addedGroups = this.groupQuery.getActive().map(f => createGroupId({
      GroupId: f.id
    }));
    queue.addedFacilities = this.facilityQuery.getActive().map(f => createFacilityId({
      FacilityId: f.id
    }));
    queue.addedPhysicians = this.physicianQuery.getActive().map(p => createPhysicianId({
      PhysicianId: p.id
    }));

    queue.addedPayers = this.payerQuery.getActive().map(p => createPayerId({
      PayerId: p.id
    }));

    queue.addedCpt = this.cptQuery.getActive().map(c => createCptCode({
      CptCode: c.cpt
    }));
    
    return queue;
  }

  onCancel() {
    this.onClickClose();
  }

  onClickClose() {
    this.groupService.inActiveAll();
    this.facilityService.set([]);
    this.physicianService.set([]);
    this.payersService.set([]);
    this.cptService.set([]);
    this.selectionService.changeSelection("");
  }

  initForm() {
    this.customQueueAddForm = this.fb.group({
      queueName: ["", [Validators.required]],
      description: ["", Validators.required],
      addedGroups: ["",],
      addedFacilities: ["",],
      addedPhysicians: ["",],
      addedPayers: ["",],
      addedCpts: ["",]
    })
  }

  assign(tabName) {
    console.log('**assign - **',tabName);
    switch (tabName) {
      case 'AddGroup': if (this.selectedGroupToAdd === undefined) {
        this.toastr.warning('please select group to add');
        return;
      }
        this.groupService.active(this.selectedGroupToAdd.map(e => e.id));
        const groupIds = this.groupQuery.getActiveId();
        this.facilityService.setFacilityGroup(groupIds);
        break;
      case 'AddFacility': if (this.selectedFacilityToAdd === undefined) {
        this.toastr.warning('please select facility to add');
        return;
      }
        this.facilityService.active(this.selectedFacilityToAdd.map(e => e.id));
        const facilityIds = this.facilityQuery.getActiveId();
        this.physicianService.setPhysicianFacility(facilityIds);
        this.payersService.setPayerFacility(facilityIds);
        this.cptService.setCptFacility(facilityIds);
        break;
      case 'AddPhysician': if (this.selectedPhysicianToAdd === undefined) {
        this.toastr.warning('please select physician to add');
        return;
      }
        this.physicianService.active(this.selectedPhysicianToAdd.map(e => e.id));
        break;
      case 'AddPayer': if (this.selectedPayerToAdd === undefined) {
        this.toastr.warning('please select group to add');
        return;
      }
        this.payersService.active(this.selectedPayerToAdd.map(e => e.id));
        break;
      case 'AddCpt': if (this.selectedCptToAdd === undefined) {
        this.toastr.warning('please select CPT Code to add');
        return;
      }
        this.cptService.active(this.selectedCptToAdd.map(e => e.cpt));
        break;
    }
  }

  assignAll(tabName) {
    console.log('**assignAll - **',tabName);
    switch (tabName) {
      case 'AddAllGroup': const inactiveGroups = this.groupQuery.getAllInactive();
        if (inactiveGroups.length === 0) {
          this.toastr.warning('no group to add');
          return;
        }
        this.groupService.active(inactiveGroups.map(g => g.id));
        const groupIds = this.groupQuery.getActiveId();
        this.facilityService.setFacilityGroup(groupIds);
        break;
      case 'AddAllFacility': const inactiveFacilities = this.facilityQuery.getAllInactive();
        if (inactiveFacilities.length === 0) {
          this.toastr.warning('no facility to add');
          return;
        }
        this.facilityService.active(inactiveFacilities.map(g => g.id));
        const facilityIds = this.facilityQuery.getActiveId();
        this.physicianService.setPhysicianFacility(facilityIds);
        this.payersService.setPayerFacility(facilityIds);
        this.cptService.setCptFacility(facilityIds);
        break;
      case 'AddAllPhysician': const inactivePhysicians = this.physicianQuery.getAllInactive();
        if (inactivePhysicians.length === 0) {
          this.toastr.warning('no physician to add');
          return;
        }
        this.physicianService.active(inactivePhysicians.map(g => g.id));
        break;  
      case 'AddAllPayer': const inactivePayers = this.payerQuery.getAllInactive();
        if (inactivePayers.length === 0) {
          this.toastr.warning('no payer to add');
          return;
        }
        this.payersService.active(inactivePayers.map(g => g.id));
        break;
      case 'AddAllCpt': const inactiveCpts = this.cptQuery.getAllInactive();
        if (inactiveCpts.length === 0) {
          this.toastr.warning('no CPT Code to add');
          return;
        }
        this.cptService.active(inactiveCpts.map(g => g.cpt));
        break;
      
    }
  }

  unassign(tabName) {
    console.log('**unassign - **',tabName);
    switch (tabName) {
      case 'RemoveGroup': if (this.selectedGroupToRemove === undefined) {
        this.toastr.warning('please select group to remove');
        return;
      }
        this.groupService.inActive(this.selectedGroupToRemove.map(e => e.id));
        const groupIds = this.groupQuery.getActiveId();
        this.facilityService.setFacilityGroup(groupIds);
        break;
      case 'RemoveFacility': if (this.selectedFacilityToRemove === undefined) {
        this.toastr.warning('please select facility to remove');
        return;
      }
        this.facilityService.inActive(this.selectedFacilityToRemove.map(e => e.id));
        const facilityIds = this.facilityQuery.getActiveId();
        this.physicianService.setPhysicianFacility(facilityIds);
        this.payersService.setPayerFacility(facilityIds);
        this.cptService.setCptFacility(facilityIds);
        break;
      case 'RemovePhysician': if (this.selectedPhysicianToRemove === undefined) {
        this.toastr.warning('please select physcian to remove');
        return;
      }
        this.physicianService.inActive(this.selectedPhysicianToRemove.map(e => e.id));
        break;
      case 'RemovePayer': if (this.selectedPayerToRemove === undefined) {
        this.toastr.warning('please select payer to remove');
        return;
      }
        this.payersService.inActive(this.selectedPayerToRemove.map(e => e.id));
        break;
      case 'RemoveCpt': if (this.selectedCptToRemove === undefined) {
        this.toastr.warning('please select CPT Code to remove');
        return;
      }
        this.cptService.inActive(this.selectedCptToRemove.map(e => e.cpt));
        break;  
      
    }
  }

  unassignAll(tabName) {
    console.log('**unassign - **',tabName);
    switch (tabName) {
      case 'RemoveAllGroup': if (!this.groupQuery.hasActive()) {
        this.toastr.warning('no group to remove');
        return;
      }
        this.groupService.inActiveAll();
        this.facilityService.set([]);
        break;
      case 'RemoveAllFacility': if (!this.facilityQuery.hasActive()) {
        this.toastr.warning('no facility to remove');
        return;
      }
        this.facilityService.inActiveAll();
        this.physicianService.set([]);
        this.payersService.set([]);
        this.cptService.set([]);
        break;
      case 'RemoveAllPhysician': if (!this.physicianQuery.hasActive()) {
        this.toastr.warning('no physician to remove');
        return;
      }
        this.physicianService.inActiveAll();
        break;
      case 'RemoveAllPayer': if (!this.payerQuery.hasActive()) {
        this.toastr.warning('no payer to remove');
        return;
      }
        this.payersService.inActiveAll();
        break;
      case 'RemoveAllCpt': if (!this.cptQuery.hasActive()) {
        this.toastr.warning('no CPT Code to remove');
        return;
      }
        this.cptService.inActiveAll();
        break;
      
    }

  }
  get f() { return this.customQueueAddForm.controls; }
}
