import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CurrentMonthCasesComponent } from './current-month-cases/current-month-cases.component';
import { EodComponent } from './eod/eod.component';
import { EodHoldComponent } from './eod/eod-hold/eod-hold.component';
import { EodArchiveComponent } from './eod/eod-archive/eod-archive.component';
import { CloseOfDayComponent } from './eod/close-of-day/close-of-day.component';
import { DashDetailComponent } from './dash-detail/dash-detail.component';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { UndoCloseOfDayComponent } from './eod/undo-close-of-day/undo-close-of-day.component';
import { ExceptionComponent } from './exceptions/exception/exception.component';
import { AuditLogComponent } from './exceptions/audit-log/audit-log.component';
import { CriticalItemComponent } from './dash-detail/critical-item/critical-item.component';
//import { FacilityDetailsComponent} from '../../modules/masters/facility-master/facility-details/facility-details.component'

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/currentmonthcases',
        pathMatch: 'full'
      },
      {
        path: 'currentmonthcases/:id/:type',
        component: CurrentMonthCasesComponent
      },
      {
        path: 'patientDetails/:id/:type/:cat/:month/:year',
        component: DashDetailComponent
      },
      {
        path: 'patientDetails/criticalitem',
        component: CriticalItemComponent
      },
      {
        path: 'eod/:id',
        component: EodComponent
      },
      {
        path: 'eodHold/:id',
        component: EodHoldComponent
      },
      {
        path: 'eodArchive/:id',
        component: EodArchiveComponent
      },
      {
        path: 'closeday/:id',
        component: CloseOfDayComponent
      },
      {
        path: 'undocloseday/:id',
        component: UndoCloseOfDayComponent
      },
      {
        path: 'patientDtls/:id/:mrn',
        component: PatientDetailComponent
      },
      {
        path: 'exceptions/:id',
        component: ExceptionComponent
      },
      {
        path: 'auditlog/:id',
        component: AuditLogComponent
      },
      // {
      //   path: 'facility-details',
      //   component: FacilityDetailsComponent
      // },
    ],
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BillingDashboardRoutingModule { }
