export interface AccordianConfig{
 facilityId: string | number;
 commandName: string;
 searchValue: string;
 catId: string | number;
 monthNumber: number;
 yearNumber: number;
 pageNum: number;
 pageSize: number;
}

export function createAccordianConfig(params: Partial<AccordianConfig>) {
    return {
      ...params
    } as AccordianConfig;
  }