import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-payer-form',
  templateUrl: './import-payer-form.component.html',
  styleUrls: ['./import-payer-form.component.css']
})
export class ImportPayerFormComponent implements OnInit {
  @Output() addedEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  filetoUpload;
  constructor( private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  addRecord() {
    let formData = new FormData();
    formData.append("file", this.filetoUpload, this.filetoUpload.name);

    this.getApiService.uploadFiles('FacilityPayer/UploadFile', formData).subscribe((resp: any) => {

      if(resp === null || resp === undefined){
        this.toastr.error('Response is empty', resp);
        return;
      }

      if(resp.status === 'Success' || resp.status === 'success'){
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      });
    }else if(resp.status === 'Error' || resp.status === 'error'){
      this.toastr.error(resp.messsage);
    }else{
      this.toastr.error('Unknown status of response', resp.status);
    }
    }, (err) => {
      console.log(err);
    })
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  uploadFile(fileInput) {
    this.filetoUpload = fileInput.target.files[0];
  }

  downloadfile() {
    try{
    let downloadUrl = this.getApiService.webUrl + "uploads/ImportPayer.csv";
    window.location.href=downloadUrl;
    }catch(e){
      console.log(e);
    }
  }

}
