import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { FacilityReportStore, FacilityReportState } from './facility-report.store';

@Injectable({ providedIn: 'root' })
export class FacilityReportQuery extends QueryEntity<FacilityReportState> {

  constructor(protected store: FacilityReportStore) {
    super(store);
  }

}
