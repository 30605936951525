import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { FileHistoryInfo } from '../state/case-request-info/case-request-info.model'
import { CaseRequestInfoQuery } from '../state/case-request-info/case-request-info.query';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-case-request-history',
  templateUrl: './case-request-history.component.html',
  styleUrls: ['./case-request-history.component.css']
})
export class CaseRequestHistoryComponent implements OnInit {

  fileHistoryInfo$ : Observable<FileHistoryInfo[]>;
  previewFileHistoryInfo$: Observable<FileHistoryInfo[]>;
  expandAll: boolean = false;
  constructor(
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private getApiService: ApiService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.fileHistoryInfo$ = this.caseRequestInfoQuery.getCaseRequestHistoryWhenClientNoteOrBillerNoteIsPresent();
    this.previewFileHistoryInfo$ = this.fileHistoryInfo$.pipe(map(res => res && res.slice(0, 2)));
    
    //this.previewFileHistoryInfo$ = this.fileHistoryInfo$;
    console.log('--- Preview -----');
    console.log(this.fileHistoryInfo$)
  }

  toggleExpand(){
    console.log('expandAll',this.expandAll);
    this.expandAll = this.expandAll ? false : true;
  }
  getFile(fileId, fileName) {
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
}
