import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CodingStatusStore, CodingStatusState } from './coding-status.store';

@Injectable({ providedIn: 'root' })
export class CodingStatusQuery extends QueryEntity<CodingStatusState> {

  constructor(protected store: CodingStatusStore) {
    super(store);
  }

}
