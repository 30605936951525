import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-patient-view',
  templateUrl: './patient-view.component.html',
  styleUrls: ['./patient-view.component.css']
})
export class PatientViewComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }
}
