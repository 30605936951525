<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
        
    <app-patient-info-header (closeEmitter)="onClickClose()"></app-patient-info-header>
    <div class="card-body pb-3 form-fields">
        <!-- [formGroup]="PatientsForm" (ngSubmit)="onSubmit()" -->

        <div class="row mt-3">
            <div class="col-md-12">
                <select class="custom-select dashboard-calnedar-patient1 mr-3" [(ngModel)]="planSelct" (change)="onPlanChange()">
                    <!-- [(ngModel)]="facilityID"
                        (change)="onFacilityChange()" -->
                    <option value="1">Payment Plan</option>
                    <option value="2">Coding Review Request</option>
                    <option value="3">Coding Exception</option>
                    <option value="4">{{implantLog.value}}</option>
                </select>
            </div>
        </div>

        <div *ngIf="planSelct == 1">
            <div class="container tab-remove">
                <div class="row mt-3">
                    <div class="col-md-5">

                        <ul class="nav">
                            <li class="casetab">
                                <input type="checkbox" value="Payment Plan Agreement" [checked]="ppa == true"
                                    (click)="slctchkBx($event)">
                                Payment Plan Agreement </li>
                            <li class="casetab">
                                <input type="checkbox" value="Promissory Agreement" [checked]="pa == true"
                                    (click)="slctchkBx($event)">Promissory Agreement
                            </li>
                            <li class="casetab">
                                <input type="checkbox" value="Proposed Payment Plan" [checked]="pppa == true"
                                    (click)="slctchkBx($event)">Proposed Payment Plan
                                Agreement
                            </li>

                        </ul>
                    </div>
                    <div class="col-md-7">
                        <div class="row">
                            <div class="col-md-12" *ngIf="ppa == true || pa == true || pppa == true">
                                <div class="row">
                                    <div class="col-md-6 text-right mt-2"> <label>Patient Balance:</label></div>
                                    <div class="col-md-6"><input type="number" [(ngModel)]="patientbal"
                                            class="form-control"></div>
                                </div>
                                <div class="row" *ngIf="ppa == true">
                                    <div class="col-md-6 text-right mt-2"> <label>Today's Payment:</label></div>
                                    <div class="col-md-6"><input type="number" [(ngModel)]="todayPayment"
                                            class="form-control mt-1"></div>
                                </div>
                                <div class="row" *ngIf="ppa == true">
                                    <div class="col-md-6 text-right mt-2"> <label>New Balance:</label></div>
                                    <div class="col-md-6"><input type="number" [(ngModel)]="newBalance"
                                            class="form-control mt-1"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 text-right mt-2"> <label># of Months:</label></div>
                                    <div class="col-md-6"><input type="number" [(ngModel)]="noofMonths"
                                            class="form-control mt-1"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 text-right mt-2"> <label>Monthly Payment:</label></div>
                                    <div class="col-md-6"><input type="number" [(ngModel)]="monthlyPayment"
                                            class="form-control mt-1"></div>
                                </div>
                                <div class="row" *ngIf="pppa == false">
                                    <div class="col-md-6 text-right mt-2"> <label>Date of First Payment:</label></div>
                                    <div class="col-md-6"><input type="text" #dp="bsDatepicker" bsDatepicker
                                            [bsConfig]="{ showWeekNumbers:false }" [(ngModel)]="paymentDate"
                                            class="form-control mt-1"></div>
                                </div>
                                <div class="row" *ngIf="pppa == false">
                                    <div class="col-md-6 text-right mt-2"> <label>Payment Method:</label></div>
                                    <div class="col-md-6"><select class="form-control mt-1" [(ngModel)]="paymentMethod">
                                            <option value="Visa">Visa</option>
                                            <option value="Mastercard">Mastercard</option>
                                            <option value="Amex">Amex</option>
                                            <option value="Discover">Discover</option>
                                            <option value="Cheque">Cheque</option>
                                            <option value="Money Order">Money Order</option>
                                            <option value="Credit Card">Credit Card</option>
                                            <option value="Cash">Cash</option>
                                        </select></div>
                                </div>
                                <div class="row" *ngIf="haserror == true">
                                    <div class="col-md-12 text-center">
                                        <i>*All fields are Mandatory </i>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="row mt-3" *ngIf="ppa == true || pa == true || pppa == true">
                <div class="col-md-7"></div>
                <div class="col-md-5 btn-remove-pdng">
                    <div class="text-right">
                        <button type="button" (click)="onClickClose(); reloadCurrentPage()"
                            class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1" >Cancel</button>
                        <button type="button" (click)="savePaymentPlan()" data-toggle="modal"
                            data-target="#paymentModal" class="btn btn-primary btn-patient-box">Save</button>
                    </div>
                </div>
            </div>

            <div class="row" style="padding-top: 20px;">
                <div class="col-md-12">
                    <div class="row back-color-comnt1">
                        <div class="form-group1 col-md-12">
                            <p class="patient-tab-above">Recommended Time Frames</p>
                        </div>
                    </div>
                    <div class="patient-center-space">

                        <div class="row">
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <p class="ipt2-1">$0-$500</p>
                                <p class="ipt2-1">3 Months</p>
                            </div>
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <p class="ipt2-1">$501-$2,000</p>
                                <p class="ipt2-1">6 Months</p>
                            </div>
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <p class="ipt2-1">$2001-$3,500</p>
                                <p class="ipt2-1">12 Months</p>
                            </div>
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <p class="ipt2-1">$3,501-$5,000</p>
                                <p class="ipt2-1">18 Months</p>
                            </div>
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <p class="ipt2-1">$5,000 and up</p>
                                <p class="ipt2-1">24 Months</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="planSelct == 2">
            <div class="container tab-remove">
                <div class="row mt-3">
                    <div class="col-md-12">
                        <h4>Issue to be reviewed: </h4>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [(ngModel)]="issue"></textarea>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-3">
                        <h4>Attachments: </h4>
                    </div>
                    <div class="col-md-5">

                        <ul class="nav">
                            <li class="casetab">
                                <input type="checkbox" value="Op Report" [(ngModel)]="opreport">
                                Op Report </li>
                            <li class="casetab">
                                <input type="checkbox" value="Original Claim / Coding Sheet"
                                    [(ngModel)]="originalClaim">Original Claim / Coding Sheet
                            </li>
                            <li class="casetab">
                                <input type="checkbox" value="EOB" [(ngModel)]="eob">EOB
                                Agreement
                            </li>

                        </ul>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-7"></div>
                <div class="col-md-5 btn-remove-pdng">
                    <div class="text-right">
                        <button type="button" (click)="onClickClose();reloadCurrentPage()"
                            class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                        <button type="button" *ngIf="checkValue('crr') == true" data-toggle="modal"
                            data-target="#reviewModal" (click)="saveCodingreview()"
                            class="btn btn-primary btn-patient-box">Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="planSelct == 3">
            <div class="container tab-remove">
                <div class="row mt-3">
                    <div class="col-md-12">
                        <h4>Client understands that there is a difference of opinion regarding the coding of
                            the following procedure(s): </h4>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [(ngModel)]="cef_txt1"></textarea>
                    </div>
                </div>
                <div class="row  mt-3">
                    <div class="col-md-12">
                        <h4>Client requests that above described procedure(s) are coded with the
                            following CPT/HCPC code(s): </h4>
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control" [(ngModel)]="cef_txt2"></textarea>
                    </div>
                </div>

            </div>

            <div class="row mt-3">
                <div class="col-md-7"></div>
                <div class="col-md-5 btn-remove-pdng">
                    <div class="text-right">
                        <button type="button" (click)="onClickClose(); reloadCurrentPage()"
                            class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                        <button *ngIf="checkValue('ce') == true" type="button" data-toggle="modal"
                            data-target="#exceptionModal" (click)="saveCodingException()"
                            class="btn btn-primary btn-patient-box">Save</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="planSelct == 4">
            <app-tools-qa-implant-log></app-tools-qa-implant-log>
        </div>
    </div>
</div>

</div>
<!-- Sidenav-->



<!-- The Modal -->
<div id="exceptionModal" class="modal fade">
    <div class="modal-dialog modal-lg" style="overflow: overlay !important;">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header">

                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <section class="cod_sec" id="exceptionContent">
                    <div class="container mt-5">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <img style="width:100px !important;" src="assets/img/download.jpg"></div>
                                    <div class="col-sm-9">
                                        <div class="row">
                                            <p class="textbold"
                                                style="font-weight: bold !important;font-size: 20px !important">
                                                ClientName:</p>
                                            <p style="font-size: 20px !important;font-weight: 500 !important;"
                                                class="ml-3"> {{dataException.facilityName}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 mt-5">
                                <p class="textbold" style="font-weight: bold !important;font-size: 20px !important">
                                    Client understands that there is a difference of opinion regarding the coding of
                                    thefollowing procedure(s):</p>
                                <p style="font-size: 20px !important;font-weight: 500 !important;">
                                    {{dataException.inpuT1}}
                                </p>
                            </div>
                            <div class="col-12  mt-5">
                                <p class="textbold" style="font-weight: bold !important;font-size: 20px !important">
                                    Client requests that above described procedure(s) are coded with the</p>
                                <p style="font-size: 20px !important;font-weight: 500 !important;">following
                                    CPT/HCPCcode(s):</p>
                                <p style="font-size: 20px !important;font-weight: 500 !important;">
                                    {{dataException.inpuT2}}
                                </p>
                            </div>
                            <div class="col-12  mt-5 mb-5">
                                <p style="font-size: 20px !important;font-weight: 500 !important;">Client agrees to
                                    not hold liable for any future negative impact as a result of following
                                    the<br>above requested coding format.</p>
                            </div>
                            <div class="col-12  mt-5">
                                <div class="row">
                                    <div class="col-7 pt-4 bor_top" style="border-top: 2px solid black !important;">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                            AuthorizedClient Signature</p>
                                    </div>
                                    <div class="col-2 ml-auto  pt-4 bor_top"
                                        style="border-top: 2px solid black !important;">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">Date</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-4 pt-4  mt-4 bor_top" style="border-top: 2px solid black !important;">
                                <p style="font-size: 20px !important;font-weight: 500 !important;">Title</p>

                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="getPdf()" data-dismiss="modal">Download</button>
            </div>

        </div>
    </div>
</div>

<style type="text/css">
    .req_sec input[type="checkbox"]+label:before {
        border: 2px solid #000 !important;
        content: "\00a0" !important;
        display: inline-block !important;
        font: 22px/1em sans-serif !important;
        height: 26px !important;
        margin: 0 1em 0 0 !important;
        padding: 0 !important;
        vertical-align: top !important;
        width: 26px !important;
        font-weight: bold !important;
    }

    .req_sec input[type="checkbox"]:checked+label:before {
        background: #fff !important;
        color: #000 !important;
        content: "\2713" !important;
        text-align: center !important;
    }

    .req_sec input[type="checkbox"]:checked+label:after {
        font-weight: bold !important;
    }

    .req_sec input[type="checkbox"]:focus+label::before {
        outline: rgb(59, 153, 252) auto 5p !important;
    }
</style>

<div class="modal fade" id="reviewModal">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <section class="req_sec" id="reviewContent">
                    <div class="container mt-5">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="row">
                                    <div class="col-sm-6 ">
                                        <img style="width:100px !important;" src="assets/img/download.jpg">
                                    </div>
                                    <div class="col-sm-6 mt-auto">
                                        <h1 style="font-weight: bold !important;font-size: 25px !important;">Coding
                                            Review Request</h1>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 mt-5">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Center Name:</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                            {{dataReview.facilityName}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Patient Name / MRN:</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                            {{dataReview.name}}
                                            / {{dataReview.mrn}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Date of Service:</p>
                                    </div>
                                    <div class="col-sm-2">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                            {{dataReview.dateOfSurgery | date: 'MM/dd/yyyy'}}
                                        </p>
                                    </div>
                                    <div class="col-sm-3">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Date of Request:</p>
                                    </div>
                                    <div class="col-sm-2">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                            {{dataReview.dateOfRequest | date: 'MM/dd/yyyy'}}
                                        </p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Issue to be reviewed:</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12">
                                <p style="font-size: 20px !important;font-weight: 500 !important;">
                                    {{dataReview.issueReviewed}}
                                </p>
                            </div>

                            <div class="col-sm-12 mt-4">
                                <div class="row">
                                    <div class="col-sm-2">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Attachments:</p>
                                    </div>
                                    <div class="col-sm-10 pl-5">
                                        <div class="content">
                                            <div class="box">
                                                <p style="font-size: 20px !important;font-weight: 500 !important;">
                                                    <input
                                                        style="zoom:2 !important; visibility: hidden !important;display: none !important;"
                                                        type="checkbox" id="c1" name="cb"
                                                        [checked]="dataReview.opReport">
                                                    <label
                                                        style="font-size:18px !important;font-weight: 500 !important;cursor: pointer !important;margin-left: 0 !important;"
                                                        for="c1">Op Report</label>
                                                </p>

                                                <p style="font-size: 20px !important;font-weight: 500 !important;">
                                                    <input
                                                        style="zoom:2 !important; visibility: hidden !important;display: none !important;"
                                                        type="checkbox" id="c2" name="cb"
                                                        [checked]="dataReview.originalClaim">
                                                    <label
                                                        style="font-size:18px !important;font-weight: 500 !important;cursor: pointer !important;margin-left: 0 !important;"
                                                        for="c2">Original Claim / Coding Sheet</label>
                                                </p>
                                                <p style="font-size: 20px !important;font-weight: 500 !important;">
                                                    <input
                                                        style="zoom:2 !important; visibility: hidden !important;display: none !important;"
                                                        type="checkbox" id="c3" name="cb" [checked]="dataReview.eob">
                                                    <label
                                                        style="font-size:18px !important;font-weight: 500 !important;cursor: pointer !important;margin-left: 0 !important;"
                                                        for="c3">EOB</label>
                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-sm-12 mt-4">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <p class="textbold" style="font-weight: bold !important;
                                    font-size: 20px !important">Request submitted by:</p>
                                    </div>
                                    <div class="col-sm-8">
                                        <p style="font-size: 20px !important;font-weight: 500 !important;">
                                            {{dataReview.requestSubmittedBy}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" (click)="getPdf()" data-dismiss="modal">Download</button>
            </div>
        </div>
    </div>
</div>

<div *ngIf="haserror == false" class="modal fade" id="paymentModal">
    <div class="modal-dialog  modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <section id="paymentContent">
                    <div class="container mt-5">
                        <div class="row">
                            <div class="col-12 ">
                                <div class="row">
                                    <div class="col-sm-12 text-center">
                                        <img style="width:100px !important;" src="{{imgSrc}}">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div [innerHtml]="resphtml">

                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" (click)="getPdf()" data-dismiss="modal">Download</button>
            </div>
        </div>
    </div>
</div>
