import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { getNameConstantMap, globalComment, NameConstant } from '../../models/name-constant';
import { CommentsStore, CommentsState } from './comments.store';

@Injectable({ providedIn: 'root' })
export class CommentsQuery extends QueryEntity<CommentsState> {

  constructor(protected store: CommentsStore) {
    super(store);
  }

  getGlobalComment(): string | undefined {
    return this.getEntity(globalComment.id)?.note;
  }

  getCommentForRequest(requestId: string): string | undefined{
    const name: NameConstant = getNameConstantMap().get(requestId);
    if(!name){
      return undefined;
    }
    return this.getEntity(name.serverKey)?.note;
  }
}
