import { Injectable } from '@angular/core';
import { ID, transaction } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { Physician } from './physician.model';
import { PhysicianQuery } from './physician.query';
import { PhysicianStore } from './physician.store';

@Injectable({ providedIn: 'root' })
export class PhysicianService {

  constructor(
    private store: PhysicianStore, 
    private apiService: ApiService, 
    private toastr: ToastrService,
    private query: PhysicianQuery) {
  }

  get() {
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };
    return this.apiService.post("Physician/BindGrid", data).pipe(tap(e => {
      const entities: Physician[] = e.data;
      this.store.set(entities);
    }));
  }

  add(physician: Physician) {
    this.store.add(physician);
  }

  update(id, physician: Partial<Physician>) {
    this.store.update(id, physician);
  }
  active(ids: ID[]) {
    this.store.addActive(ids);
  }

  setActive(ids: ID[]){
    this.store.setActive(ids);
  }

  
  inActiveAll() {
    const ids = this.query.getActiveId();
    this.inActive(ids)
  }

  inActive(ids: ID[]) {
    const activeIds = this.query.getActiveId();
    const idsTobeMadeInactive = ids.filter(id => activeIds.includes(id));
    this.store.toggleActive(idsTobeMadeInactive);
  }

  set(res){
    this.store.set(res);
  }
  reset(){
    this.store.set([]);
  }
  @transaction()
  setPhysicianFacility(ids: ID[]){
    let data = { "Facility": ids };
    this.apiService.post("Physician/GetPhysicianFacility", data).subscribe(res => {
      if(res.status === 'Success'){
       this.add(res.data);
      }else{
        this.toastr.error('Error', res.message);
      }
    })
  }
  setPhysicianFacilitySequentialCall(ids: ID[]){
    let batch: ID[] = [];
    ids.forEach(id => {
      batch = [...batch, id];
      if(batch.length === 10){
        this.setPhysicianFacility(batch);
        batch = [];
      }
    });

    if(batch.length !==0){
      this.setPhysicianFacility(batch);
      batch = [];
    }
  }

}
