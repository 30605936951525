<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-body" id="component1">
                    <p class="eodlist_summry"
                        style="color: #0d509c;font-weight: bold;font-size: 24px;margin-bottom: 30px;">Biller End of
                        Day Summary
                        <!-- <span style="width: 30%;"><select class="custom-select form-control"
                                style="width:30%;" [(ngModel)]="facilityID" (change)="getSummary()">
                                <option selected value="0">Select Facility</option>
                                <option [value]="f.id" *ngFor="let f of FacilityList">{{f.name}}</option>
                            </select></span> -->
                    </p>
                    <p class="eodlist_summry">{{facilityName}}</p><br>
                    <div class="row">
                        <div class="col-md-4">
                            <p class="eodlist_summry"> Total Cases Billed</p>
                        </div>
                        <div class="col-md-2">
                            <p class="eodlist_summry text-center">
                                {{(dataEODSummary[0]?.eClaims || 0) + (dataEODSummary[0]?.pClaims || 0) + (dataEODSummary[0]?.sClaims || 0) + (dataEODSummary[0]?.onHoldCount  || 0)}}
                            </p>
                        </div>
                    </div>
                    <div class="row" style="border-bottom: 1px solid #ccc;padding-bottom: 10px;">
                        <div class="col-md-4">
                            <p class="eodlist_summry"> Total Charges Billed</p>
                        </div>
                        <div class="col-md-2">
                            <p class="eodlist_summry text-center">
                                ${{(dataEODSummary[0]?.eTotal || 0) + (dataEODSummary[0]?.pTotal || 0) + (dataEODSummary[0]?.sTotal || 0) + (dataEODSummary[0]?.onHoldTotal  || 0) | number:'1.2-3'}}
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <p class="claim1">Claim Summary</p>
                        <div class="col-md-12 smmry_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="elec_summary_head"><i class="fab fa-edge-legacy chkbx"></i>Electronic
                                        Claims:</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Claims#</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.eTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                            <div *ngFor="let d of dataEODSummaryDetails">
                                <div class="row" *ngIf="d.type == 'Electronic'">
                                    <div class="col-md-4">
                                        <span class="elec_summary_head1 ">{{d.name || ""}}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="elec_summary_head1">{{d.fieldTwo || ""}}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span
                                            class="elec_summary_head1">${{(d.claimValue || 0) | number:'1.2-3'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="border-top: 1px solid #ccc;padding-bottom: 10px;">
                                <div class="col-md-4">
                                    <span class="elec_summary_head" style="padding-left: 14px;">Total Electronic
                                        Claims</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">{{dataEODSummary[0]?.eClaims || 0}}</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.eTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 smmry_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="elec_summary_head1"><i class="fab fa-pinterest-p chkbx1"></i>Paper
                                        Claims:</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Attachment</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.pTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                            <div *ngFor="let d of dataEODSummaryDetails">
                                <div class="row" *ngIf="d.type == 'Paper'">
                                    <div class="col-md-4">
                                        <span class="elec_summary_head1 ">{{d.name || ""}}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="elec_summary_head1">
                                            <a href="javascript:void(0)"
                                                (click)="getFile(d.bdosid, d.fieldTwo)">{{d.fieldTwo || ""}}</a></span>
                                    </div>
                                    <div class="col-md-4">
                                        <span
                                            class="elec_summary_head1">${{(d.claimValue || 0) | number:'1.2-3'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="border-top: 1px solid #ccc;padding-bottom: 10px;">
                                <div class="col-md-4">
                                    <span class="elec_summary_head1" style="padding-left: 14px;">Total Paper
                                        Claims</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">{{dataEODSummary[0]?.pClaims || 0}}</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.pTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 smmry_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="elec_summary_head2"><i class="fas fa-user chkbx2"></i>Self-Pay
                                        Claims:</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Verified</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.sTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                            <div *ngFor="let d of dataEODSummaryDetails">
                                <div class="row"
                                    *ngIf="d.type == 'Self' || d.type == 'SelfPay' || d.type == 'Self-Pay'">
                                    <div class="col-md-4">
                                        <span class="elec_summary_head2 ">{{d.name || ""}}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="elec_summary_head2"><i class="fa fa-check-circle"></i></span>
                                    </div>
                                    <div class="col-md-4">
                                        <span
                                            class="elec_summary_head2">Chgs: ${{(d.claimValue || 0) | number:'1.2-3'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="border-top: 1px solid #ccc;padding-bottom: 10px;">
                                <div class="col-md-4">
                                    <span class="elec_summary_head2" style="padding-left: 14px;">Total Self-Pay
                                        Claims</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs: {{dataEODSummary[0]?.sClaims || 0}}</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.sTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 smmry_box">
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="elec_summary_txt"><i class="fas fa-hand-holding-usd chkbx3"></i>Claims
                                        on hold</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Reason</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.onHoldTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                            <div *ngFor="let e of dataEODSummaryOnHold">
                                <div class="row">
                                    <div class="col-md-4">
                                        <span class="elec_summary_head1 ">{{e.name}}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span class="elec_summary_head1">{{e.holdReason}}</span>
                                    </div>
                                    <div class="col-md-4">
                                        <span
                                            class="elec_summary_head1">${{(e.claimValue || 0) | number:'1.2-3'}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="border-top: 1px solid #ccc;padding-bottom: 10px;">
                                <div class="col-md-4">
                                    <span class="elec_summary_txt" style="padding-left: 14px;">Total On Hold
                                        Claims</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">{{dataEODSummary[0]?.onHoldCount || 0}}</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.onHoldTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12 smmry_box">

                            <div class="row">
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">HDR Cases on Hold</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">{{dataEODSummary[0]?.highDollarCount || 0}}</span>
                                </div>
                                <div class="col-md-4">
                                    <span class="elec_summary_txt">Chgs:
                                        ${{(dataEODSummary[0]?.highDollarTotal || 0) | number:'1.2-3'}}</span>
                                </div>
                            </div>
                            <div class="row" style="border: 1px solid #ccc;margin-top: 30px;">
                                <div class="col-md-12">
                                    <p class="smrytxt">Summary</p>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="smry_txt">Electronic Claims</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">{{dataEODSummary[0]?.eClaims || 0}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">Chgs:
                                                ${{(dataEODSummary[0]?.eTotal || 0) | number:'1.2-3'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="smry_txt">Paper Claims</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">{{dataEODSummary[0]?.pClaims || 0}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">Chgs:
                                                ${{(dataEODSummary[0]?.pTotal || 0) | number:'1.2-3'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="smry_txt">Self-Pay Claims</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">{{dataEODSummary[0]?.sClaims || 0}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">Chgs:
                                                ${{(dataEODSummary[0]?.sTotal || 0)  | number:'1.2-3'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="smry_txt">On Hold Claims</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">{{dataEODSummary[0]?.onHoldCount || 0}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">Chgs:
                                                ${{(dataEODSummary[0]?.onHoldTotal  || 0) | number:'1.2-3'}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="smry_txt">HDR Claims</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">{{dataEODSummary[0]?.highDollarCount || 0}}</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">Chgs:
                                                ${{(dataEODSummary[0]?.highDollarTotal || 0) | number:'1.2-3'}}</p>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12" style="margin-top: 30px;">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <p class="smry_txt">Summary Totals</p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">
                                                {{(dataEODSummary[0]?.eClaims || 0) + (dataEODSummary[0]?.pClaims || 0) + (dataEODSummary[0]?.sClaims || 0) + (dataEODSummary[0]?.onHoldCount  || 0)}}
                                            </p>
                                        </div>
                                        <div class="col-md-4">
                                            <p class="smry_txt">Chgs:
                                                ${{(dataEODSummary[0]?.eTotal || 0) + (dataEODSummary[0]?.pTotal || 0) + (dataEODSummary[0]?.sTotal || 0) + (dataEODSummary[0]?.onHoldTotal  || 0) | number:'1.2-3'}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 20px;">
                                <div class="col-md-4">
                                    <p class="smry_txt">Total Cases MC>48 hours</p>
                                </div>
                                <div class="col-md-4">
                                    <!-- <p class="smry_txt smry_txt_brder">17</p> -->
                                    <input type="text" style="width: 50px;" class="form-control"
                                        value="{{dataEODSummary[0]?.totalMC || 0}}" (change)="calcVariance($event)"
                                        required>
                                </div>
                                <div class="col-md-4">

                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="smry_txt">Total Cases Pending>48 hours
                                        <i *ngIf="dataEODSummary[0]?.totalPending > 0" (click)="showPatientDetail()"
                                            [ngClass]="smryIsToggled ? 'fa fa-minus showhideicn' : 'fa fa-plus showhideicn'"></i>

                                    </p>
                                </div>
                                <div class="col-md-4">
                                    <p class="smry_txt">{{dataEODSummary[0]?.totalPending || 0}}
                                    </p>
                                </div>
                                <div class="col-md-4">

                                </div>
                            </div>
                            <div class="row" [style.display]="smryIsToggled ? 'block' : 'none'">
                                <table class="table" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Patient Name</th>
                                            <th>DoS</th>
                                            <th>Pending Queue</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Patient Name</th>
                                            <th>DoS</th>
                                            <th>Pending Queue</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr *ngFor='let data of PendingPatientList; let i = index'>
                                            <!-- | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index -->
                                            <td> {{i+1}} </td>
                                            <td>{{data?.patientName}}</td>
                                            <td>{{data?.dos | date: 'MM/dd/yyyy'}}</td>
                                            <td>
                                                <i *ngIf="data?.pricingPending > 0">Pricing</i>
                                                <i *ngIf="data?.opReportPending > 0">Op Note</i>
                                                <i *ngIf="data?.pathologyPending > 0">Pathology</i>
                                                <i *ngIf="data?.blockReportPending > 0">Block</i>
                                                <i *ngIf="data?.inCodingPending > 0">In Coding</i>
                                                <i *ngIf="data?.codingReturned > 0">Returned Coding</i>
                                                <i *ngIf="data?.returnedCodingRFIPending > 0">Returned</i>
                                                <i *ngIf="data?.codingQueryPending > 0">Coding</i>
                                                <i *ngIf="data?.variancePending > 0">Variance</i>
                                                <i *ngIf="data?.overduePending > 0">
                                                    < 48 hours</i>
                                                        <i *ngIf="data?.otherMiscPending > 0">Other Misc</i>
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                            <div class="row" style="margin-top: 30px;">
                                <div class="col-md-4">
                                    <p class="smry_txt">Variance</p>
                                </div>
                                <div class="col-md-4">
                                    <p class="smry_txt">{{variancettl}}</p>
                                </div>
                                <div class="col-md-4">

                                </div>

                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="smry_txt">Variance Reasons:</p>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="form-control" placeholder="Claims on Hold"
                                        (change)="checkText()" required [(ngModel)]="varReason" style="width: 48%;">
                                    <div *ngIf="submitted && varMandatory" class="invalid-feedback">
                                        *Reason is required
                                    </div>
                                </div>


                            </div>
                            <div class="row">
                                <p class="smry_brder"></p>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="inputFirstName">Comment</label>

                                </div>
                                <div class="col-md-4">
                                    <input type="text" class="form-control" required [(ngModel)]="confComments"
                                        (change)="checkText()">
                                    <div *ngIf="submitted && commentMandatory" class="invalid-feedback">
                                        *Comment is required
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="text-right  no-print">
                                        <button [useExistingCss]="true" type="button" printSectionId="component1"
                                            ngxPrint class="btn btn-primary btn-patient-box mr-1">Print</button>
                                        <button type="button" routerLink="/d/eod/0"
                                            class="btn btn-secondary btn-patient-box mr-1">Cancel</button>
                                        <button type="button" (click)="confirmCOD()"
                                            class="btn btn-primary btn-patient-box mr-1">Confirm</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</div>