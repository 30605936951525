import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { UserProductivity } from './user-productivity.model';
import { UserProductivityStore } from './user-productivity.store';

@Injectable({ providedIn: 'root' })
export class UserProductivityService {

  constructor(private userProductivityStore: UserProductivityStore, private http: HttpClient) {
  }


  set(entities: UserProductivity[]) {
   this.userProductivityStore.set(entities);
  }

  setActive(id: ID){
    this.userProductivityStore.setActive(id);
  }

  toggleActive(id: ID){
    this.userProductivityStore.toggleActive(id);
  }
  add(userProductivity: UserProductivity) {
    this.userProductivityStore.add(userProductivity);
  }

  update(id, userProductivity: Partial<UserProductivity>) {
    this.userProductivityStore.update(id, userProductivity);
  }

  remove(id: ID) {
    this.userProductivityStore.remove(id);
  }

}
