import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { na, variance } from 'src/app/shared/models/name-constant';
import { CaseRequestHelper } from '../../helper/case-request.helper';
import { CaseRequest } from '../case-request/case-request.model';
import { CaseRequestCpt } from './case-request-cpt.model';
import { CaseRequestCptStore, CaseRequestCptState } from './case-request-cpt.store';

@Injectable({ providedIn: 'root' })
export class CaseRequestCptQuery extends QueryEntity<CaseRequestCptState> {
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();

  constructor(protected store: CaseRequestCptStore) {
    super(store);
  }

  hasCptAtUIIndex(index: number, requestId: string | number): boolean {
    const entities = this.getAll({
      filterBy: entity => entity.uiIndex === index && entity.requestId === requestId
    });
    return entities && entities.length > 0;
  }


  findCptByRequestId(id: string): CaseRequestCpt[]{
    return this.getAll({
      filterBy: entity => entity.id.toString().startsWith(id)
    })
  }

  findCptOfRequestNotSubmitted(id: string): CaseRequestCpt[]{
    return this.getAll({
      filterBy: entity => entity.requestId === id && !entity.alreadySubmitted
    });
  }

  findAllCptNotSubmitted(): CaseRequestCpt[]{
    return this.getAll({
      filterBy: entity => !entity.alreadySubmitted
    });
  }

  findCptForVariance(prefix: string){
     const requestId = prefix.split("_")[0];
     const cptCode = prefix.split("_")[1];
    return this.getAll({
      filterBy: entity => entity.requestId === requestId && entity.cptCode === cptCode
    })
  }

  findCptForVarianceOfPatient(prefix: string){
    const requestId = prefix.split("_")[0];
    const cptCode = prefix.split("_")[1];
    const bdosid = prefix.split("_")[3];
   return this.getAll({
     filterBy: entity => entity.requestId === requestId && entity.cptCode === cptCode && entity.bdosid === bdosid
   })
 }

 getCptByRequestAtUIIndex(requestId: string | number, index: number):CaseRequestCpt[]{
  return this.getAll({
    filterBy: entity => entity.requestId === requestId && entity.uiIndex === index
  });
 }
 getCptByRequestAtUIIndexForPatient(requestId: string | number, index: number, bdosid: string | number):CaseRequestCpt[]{
  return this.getAll({
    filterBy: entity => entity.requestId === requestId && entity.uiIndex === index && entity.bdosid === bdosid
  });
 }
 getCptByRequestAndTypeAtUIIndex(requestId: string | number, index: number, type: string):CaseRequestCpt[]{
  return this.getAll({
    filterBy: entity => entity.requestId === requestId && entity.uiIndex === index && entity.codingType === type
  });
 }
 getCptByRequestAndTypeAtUIIndexForPatient(requestId: string | number, index: number, type: string, bdosid: string | number):CaseRequestCpt[]{
  return this.getAll({
    filterBy: entity => entity.requestId === requestId && entity.uiIndex === index && entity.bdosid === bdosid && entity.codingType === type
  });
 }

  findCptByComponentId(id: string){
    return this.getAll({
      filterBy: entity => entity.requestId === id
    });
  }

  selectCptByComponentId(id: string): Observable<CaseRequestCpt[]>{
    return this.selectAll({ 
      filterBy: entity => entity.requestId === id
    });
  }

  groupAllVarianceByUIIndex(): Map<number, CaseRequestCpt[]>{
    const caseRequestCptList = this.findCptByComponentId(variance.id);
    return this.groupAllVarianceByUIIndexForGivenCptList(caseRequestCptList);
  }

  groupAllVarianceByUIIndexForPatient(bdosid: string | number): Map<number, CaseRequestCpt[]>{
    const caseRequestCptList = this.getCptByRequestAndPatientId(variance.id, bdosid);
    return this.groupAllVarianceByUIIndexForGivenCptList(caseRequestCptList);
  }

  private groupAllVarianceByUIIndexForGivenCptList(caseRequestCptList: CaseRequestCpt[]): Map<number, CaseRequestCpt[]>{
    let map: Map<number, CaseRequestCpt[]> = new Map();
    for (let index = 0; index < caseRequestCptList.length; index++) {
      const element = caseRequestCptList[index];
      if(map.has(element.uiIndex)){
        let values = map.get(element.uiIndex);
        values = [...values, element];
        map.set(element.uiIndex, values);
      }else{
        map.set(element.uiIndex, [element]);
      }
    }
    return map;
  }
  getAllCpt(){
    return this.getAll({
      filterBy: entity => entity.codingType === na.id
    });
  }

  getAllVariance(){
    return this.getAll({
      filterBy: entity => entity.codingType !== na.id
    });
  }

  getAllVarianceForPatient(bdosid: string | number){
    return this.getAll({
      filterBy: entity => entity.requestId === variance.id && entity.bdosid === bdosid
    });
  }

  selectTotalCostFor(id: string | number): Observable<string>{
    return this.selectAll({
      filterBy: entity => entity.requestId === id
    })
    .pipe(map(res => res && res.reduce((sum, current) => sum + current.rate, 0)))
    .pipe(map(res => this.caseRequestHelper.formatAmount(res)));
  }

  selectTotalCostByRequestAndPatient(id: string | number, bdosid: string | number): Observable<string>{
    return this.selectAll({
      filterBy: entity => entity.requestId === id && entity.bdosid === bdosid
    })
    .pipe(map(res => res && res.reduce((sum, current) => sum + current.rate, 0)))
    .pipe(map(res => this.caseRequestHelper.formatAmount(res)));
  }

  getCptByRequestAndPatientId(requestId: string | number, bdosid: string | number): CaseRequestCpt[]{
    return this.getAll({
      filterBy: entity => entity.requestId === requestId && entity.bdosid === bdosid
    })
  }

  selectCptByRequestAndPatientId(requestId: string | number, bdosid: string | number): Observable<CaseRequestCpt[]>{
    return this.selectAll({
      filterBy: entity => entity.requestId === requestId && entity.bdosid === bdosid
    })
  }

  hasAnyEntityByRequestForPatient(requestId: string | number, bdosid: string | number): boolean{
    const entities = this.getCptByRequestAndPatientId(requestId, bdosid);
    return entities && entities.length > 0;
  }
}
