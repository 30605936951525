<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="top-action-container">
                        <div class="item-review-cases">
                            <label for="fromDate" class="pad-right-lbl">From Date:</label>
                            <input type="date" id="fromDate" name="fromDate" [(ngModel)]="selFromDate">
                            <span *ngIf="submitted && !selFromDate" class="invalid-feedback">
                                From Date is mandatory!!
                            </span>

                        </div>
                        <div class="item-month">
                            <label for="toDate" class="pad-right-lbl">To Date:</label>
                            <input type="date" id="toDate" name="toDate" [(ngModel)]="selToDate">
                            <span *ngIf="submitted && !selToDate" class="invalid-feedback">
                                To Date is mandatory!!
                            </span>
                        </div>

                        <div class="item-facility">
                            <ngx-select-dropdown 
                            [config]="facilityDropdownConfig" 
                            [options]="facilitySourceList"
                            (change)="userFacilityChanged($event)"
                            autoClearSearch="true"
                            [multiple]="false"
                            [(ngModel)]="selFacility"
                            >
                            </ngx-select-dropdown>
                            <span *ngIf="submitted && !selFacility " class="invalid-feedback">
                                please select facility!!
                            </span>
                        </div>
                        <div class="item-year">
                            
                            <button type="button" (click)="onSearch()" style="width: 120px;"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav"><i class="fa fa-search fa-fw"></i>Search</button>
                   
                        </div>
                        <div class="item-blank"></div>
                        <div class="item-search">
                        
                        </div>
                        <div class="item-export">
                            <div class="row" style="float: right;">
                                <div class="col">
                                    <button type="button" (click)="onExport()"
                                    class="btn btn-primary mr-3 btn-patient-box closeMySidenav">Export</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="table-responsive">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr style="color: #333;">
                                    <th>Facility Name</th>
                                    <th colspan="3" style="text-align:center;">Average Time in Days</th>
                                    <th class="txt-align-right">Total # of Cases Billed</th>
                                    <th class="txt-align-right">Total Charges Billed</th>
                                    <th colspan="3" style="text-align:center;">Top 3 Pending Client Status</th>
                                    <th colspan="3" style="text-align:center;">Top 3 Primary CPT Codes</th>
                                </tr>
                                <tr style="color: #333;">
                                    <th></th>
                                    <th class="txt-align-right">Pending Client</th>
                                    <th class="txt-align-right">In Coding</th>
                                    <th class="txt-align-right">All other Status</th>
                                    <th></th>
                                    <th></th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.firstQueueReq | camelToSentance}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.secondQueueReq | camelToSentance}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.thirdQueueReq | camelToSentance}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.firstCptId}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.secondCptId}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.thirdCptId}}</th>
                                </tr>
                                <tr style="color: #333;">
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.firstTotalCpt | number}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.secondTotalCpt | number}}</th>
                                    <th class="txt-align-right">{{(datasource$ | async)?.thirdTotalCpt | number}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr style="color: #333;">
                                    <td>{{(datasource$ | async)?.facilityName}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.pendingClient}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.inCoding}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.otherStats}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.totalCases | number}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.totalChargeBill | currency}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.firstQueueVal | number}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.secondQueueVal | number}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.thirdQueueVal | number}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.firstCptCost | currency}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.secondCptCost | currency}}</td>
                                    <td class="txt-align-right">{{(datasource$ | async)?.thirdCptCost | currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div>
                    <div class="table-responsive">
                        <app-facility-report-line *ngIf="activeReportLineId$ | async" [selFromDate]="selFromDate" [selToDate]="selToDate"></app-facility-report-line>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
