<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Pricing</h1>

                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="getfacilityPricing()"
                                        placeholder="CPT search"></label></div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th># </th>
                                    <th>Date <i class="fas fa-arrow-up mr-1"
                                            (click)="sortListPricing('createddate','asc')"></i>
                                        <i class="fas fa-arrow-down"
                                            (click)="sortListPricing('createddate','desc')"></i>
                                    </th>
                                    <th>CPT <i class="fas fa-arrow-up mr-1" (click)="sortListPricing('cpt','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListPricing('cpt','desc')"></i>
                                    </th>
                                    <th>Implant Log</th>
                                    <th>Invoice</th>
                                </tr>
                            </thead>

                            <tfoot>
                                <tr>
                                    <th># </th>
                                    <th>Date <i class="fas fa-arrow-up mr-1"></i>
                                        <i class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>CPT <i class="fas fa-arrow-up mr-1" (click)="sortListPricing('cpt','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListPricing('cpt','desc')"></i>
                                    </th>
                                    <th>Implant Log</th>
                                    <th>Invoice</th>
                                </tr>
                            </tfoot>
                            <tbody>

                                <tr
                                    *ngFor='let data of facilityPricingArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>{{i+1}}</td>
                                    <td>{{data.createddate | date:'MM/dd/yyyy'}}</td>
                                    <td>{{data.cpt}}</td>
                                    <td><a href="javascript:void(0)" class="icon-color-green mr-1" (click)="downloadImplantLogFile(data)">
                                            <i *ngIf="data.implantlogname" class="fas fa-download" title="Download"></i>&nbsp;
                                            {{data.implantlogname}}</a></td>
                                    <td><a href="javascript:void(0)" class="icon-color-green mr-1" (click)="downloadInvoiceFile(data)">
                                            <i *ngIf="data.invoicename" class="fas fa-download" title="Download"></i>&nbsp;
                                            {{data.invoicename}}</a>
                                    </td>
                                </tr>

                            </tbody>

                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- /.container-fluid -->