<!-- Begin Page Content -->
<div class="container-fluid width-index">
    <div class="container1">
        <!-- Daily Progress-->
        <div class="row daily-progress">
            <div class="col-md-4">
                <div class="border-progress">
                    <p class="progress-text">Daily Progress </p>
                    <!-- <img src="assets/img/dashboard-Progress-logo.png" alt="" class="dashboard-logo-side"> -->
                    <p class="progress-bar-text">Cases Billed</p>

                    <div class="progress " *ngIf="width < 100">
                        <div class="progress-bar widthAnimation" role="progressbar" aria-valuenow="90" aria-valuemin="0"
                            aria-valuemax="100" [style.width.%]="width">
                        </div> 
                    </div>
                    <div class="progress " *ngIf="width >= 100">
                        <div class="progress-bar1 widthAnimation" role="progressbar" aria-valuenow="90" aria-valuemin="0"
                            aria-valuemax="100" [style.width.%]="width">
                        </div> 
                    </div>
                    <span  class="">{{width | number: '1.0-0'}}% ({{casesBilledToday | number}}/35) Completed </span>
                    <p class="progress-bar-text-second">Billed Amount for the day</p>
                    <div class="billed-value">
                        <p>${{dataAmntfrdy | number}}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                
                <div class="row">
                    <div class="dashboard-top-row-flex-container">
                        <div class="item-monthly-performance">
                            <p class="progress-text-right">
                                Monthly Performance (<b>{{timeLeft}} Seconds </b>)
                            </p>
                            
                        </div>
                        <div class="item-facility">
                            <select class="dashboard-calnedar1 mr-1" [(ngModel)]="glbFacilityId" (change)="getSummaryData()">
                                <option  value="0">All Facilities</option>
                                <option *ngFor="let d of FacilityList" [value]="d.id">{{d.name}}</option>
                            </select>
                        </div>
                        <div class="item-month">
                            <select class="dashboard-calnedar1 mr-1" [(ngModel)]="monthNumber" (change)="getSummaryData()">
                                <option selected disabled>Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="item-year">
                            <select class="dashboard-calnedar2" [(ngModel)]="yearNumber" (change)="getSummaryData()">
                                <option selected disabled>Year</option>
                                <option *ngFor="let y of nsnYearList" value="{{y.value}}"> {{ y.displayValue}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                
                    <!-- <span class="progress-text-date">{{monthName}} 1st - {{monthName}} {{endDate}}</span> -->
                
                <p class="monthly-icon-text"><a
                        href="/d/patientDetails/{{glbFacilityId}}/priority/2/{{monthNumber}}/{{yearNumber}}"><i
                            class="fa fa-star fa-color-blue"></i>{{( changeToInt(criticalItem) + changeToInt(priorityItems) + changeToInt(dailyFlow)) | number }}
                        <span class="monthly-progress-box1">Pending</span></a>
                </p>
                <p class="monthly-icon-text"><a
                    href="/d/patientDetails/{{pcFacilityId}}/pendingclient/3/{{monthNumber}}/{{yearNumber}}"><i
                        class="fa fa-star fa-color-blue"></i>{{pendingClients | number}}
                    <span class="monthly-progress-box1">Pending Client</span></a>
                </p>
                <p class="monthly-icon-text"><a
                        href="/d/patientDetails/{{glbFacilityId}}/dailyflow/5/{{monthNumber}}/{{yearNumber}}"><i
                            class="fa fa-list-alt fa-color-blue"></i>{{dailyFlow | number}}
                        <span class="monthly-progress-box1">Daily Flow</span></a>
                </p>
                <p class="monthly-icon-text"><a
                        href="/d/patientDetails/{{glbFacilityId}}/caseperformed/6/{{monthNumber}}/{{yearNumber}}"><i
                            class="fa fa-file-invoice fa-color-blue"></i>{{casesPerformed | number}}
                        <span class="monthly-progress-box1">Cases Performed</span></a>
                </p>
                <p class="monthly-icon-text"><a
                        href="/d/patientDetails/{{glbFacilityId}}/casebilled/7/{{monthNumber}}/{{yearNumber}}"><i
                            class="fa fa-file-invoice fa-color-blue"></i>{{casesBilled | number}}
                        <span class="monthly-progress-box1">Cases Billed</span></a>
                </p>
                <p class="monthly-icon-text">
                    <a href="/d/patientDetails/{{glbFacilityId}}/avgDystoBill/0/{{monthNumber}}/{{yearNumber}}"><i
                            class="fa fa-calendar-alt fa-color-blue"></i>{{avgDystoBill | number}}
                        <span class="monthly-progress-box1">Avg Days to Bill</span></a>
                </p>
                <!-- <p class="monthly-icon-text">
                    <a href="/d/patientDetails/{{glbFacilityId}}/avgDystoBillIntrnl/0/{{monthNumber}}/{{yearNumber}}"><i
                            class="fa fa-calendar-alt fa-color-blue"></i>{{avgDystoBillIntrnl}}
                        <span class="monthly-progress-box1">Avg Days to Bill (Internal)</span></a>
                </p> -->
                <p class="monthly-icon-text monthly-icon-text-billed"><i
                        class="fa fa-file-invoice-dollar fa-color-blue"></i>${{dataAmntfrmnth | number}}
                    <span class="monthly-progress-box1">Total Billed MTD</span>
                </p>
            </div>
        </div>
        <!-- daily Progreess End-->
    </div>
    <!-- container end-->
    <!-- /.container-fluid -->
</div>

<div class="container-fluid case-mnth-margin-row">
    <div class="row">
        <!-- at a glance column-->
        <div class="col">
            <div class="heading">
                <p>Critical Items</p>
            </div>
            <div class="center-number" style="height: 275px;">
                <!-- <p class="value-numb"><a
                        href="/d/patientDetails/{{glbFacilityId}}/criticalitem/8/{{monthNumber}}/{{yearNumber}}">
                        {{criticalItem}}</a>
                </p> -->
                <p class="value-numb"><a
                    href="/d/patientDetails/criticalitem">
                    {{criticalItem | number}}</a>
            </p>
                <p class="text-cent">Items 15 Days Past DoS</p>
            </div>

        </div>
        <div class="col">
            <div class="heading">
                <p><a href="/d/patientDetails/{{piFacilityId}}/priority/2/{{monthNumber}}/{{yearNumber}}">Priority Items
                        -
                        <span>{{priorityItems | number}}</span></a>
                </p>
            </div>
            <div class="detail_pr">

                <div class="detail">
                    <!-- <div class="input-group index-input">
                        <select class="dashboard-calnedar-patient1" (change)="getPriorityItemData($event)"
                            [(ngModel)]="piFacilityId">
                            <option selected value="0">All Facilities</option>
                            <option *ngFor="let d of FacilityList" [value]="d.id">{{d.name}}</option>
                        </select>
                    </div> -->
                    <p class="priorty-detail">
                        <!-- *ngIf="piDataArry?.returned != 0"> -->
                        <a href="/d/patientDetails/{{piFacilityId}}/Returned/2/{{monthNumber}}/{{yearNumber}}">
                            <i class="fa fa-caret-square-left mr-1" style="color: #fec001!important"></i> <span
                                class="pr-text">Returned Request:</span><span
                                class="pr-numb">{{piDataArry?.returned | number}}</span>
                        </a>
                    </p>
                    <p class="priorty-detail">
                        <!-- *ngIf="piDataArry?.unassigned != 0"> -->
                        <a href="/d/patientDetails/{{piFacilityId}}/Unassigned/2/{{monthNumber}}/{{yearNumber}}">
                            <i class="fa fa-concierge-bell mr-1" style="color: #4474c5!important"></i>
                            <span class="pr-text">Unassigned:</span><span
                                class="pr-numb">{{piDataArry?.unassigned | number}}</span>
                        </a>
                    </p>
                    <p class="priorty-detail">
                        <!-- *ngIf="piDataArry?.highApprovalInternal != 0"> -->
                        <a href="/d/patientDetails/{{piFacilityId}}/NSN High$$$/2/{{monthNumber}}/{{yearNumber}}">
                            <i class="fa fa-dollar-sign mr-1" style="color: #5b9ad5!important"></i>
                            <span class="pr-text"> High $$$ Cases:</span>
                            <span class="pr-numb">{{piDataArry?.highApprovalInternal | number}}</span>
                        </a>
                    </p>
                    <p class="priorty-detail">
                        <!-- *ngIf="piDataArry?.overdue != 0"> -->
                        <a href="/d/patientDetails/{{piFacilityId}}/Overdue/2/{{monthNumber}}/{{yearNumber}}">
                            <i class="fa fa-calendar mr-1" style="color: #72ab49!important"></i> <span
                                class="pr-text">Past Due Coding
                                > 36
                                Hours:</span><span class="pr-numb">{{piDataArry?.overdue | number}}</span>
                        </a>
                    </p>
                    <p class="priorty-detail">
                        <!-- *ngIf="piDataArry?.exceptions != 0"> -->
                        <a href="/d/exceptions/{{piFacilityId}}">
                            <i class="fa fa-exclamation-circle mr-1" style="color: #9f460d!important"></i> <span
                                class="pr-text">Exceptions:</span><span
                                class="pr-numb">{{piDataArry?.exceptions | number}}</span>
                        </a>
                    </p>
                </div>
                <!-- <div class="detail_right1">
                    <canvas width="100%" height="90%" baseChart [data]="priorityItemChartData"
                        [labels]="priorityItemChartLabels" [chartType]="pieChartType" [options]="pieChartOptions"
                        [colors]="pieChartColors" [legend]="false">
                    </canvas>
                </div>
                <div style="clear:both;"></div> -->
            </div>
        </div>
        <div class="col">
            <div class="heading">
                <p><a href="/d/patientDetails/{{dlFacilityId}}/dailyflow/5/{{monthNumber}}/{{yearNumber}}">Daily Flow -
                        <span>{{dailyFlow | number}}</span></a>
                </p>
            </div>
            <div class="detail_pr">

                <div class="detail">
                    <!-- <div class="input-group index-input">
                        <select class="dashboard-calnedar-patient1" (change)="getDailyFlowData($event)"
                            [(ngModel)]="dlFacilityId">
                            <option selected value="0">All Facilities</option>
                            <option *ngFor="let d of FacilityList" [value]="d.id">{{d.name}}</option>
                        </select>
                    </div> -->
                    <div style="clear:both;"></div>
                    <p class="priorty-detail">
                        <!-- *ngIf="dFlowArry?.codingReturned != 0"> -->
                        <a href="/d/patientDetails/{{dlFacilityId}}/CodingReturned/5/{{monthNumber}}/{{yearNumber}}">
                            <i class="fas fa-undo-alt mr-1" style="color: #fec001!important"></i>
                            <span class="pr-text">Ready to
                                Bill:</span><span class="pr-numb">{{dFlowArry?.codingReturned | number}}</span>
                        </a>
                    </p>
                    <p class="priorty-detail"><i class="fa fa-window-close mr-1" style="color: #4474c5!important"></i>
                        <span class="pr-text">Rejections:</span><span class="pr-numb">0</span></p>
                    <p class="priorty-detail">
                        <!-- *ngIf="(dFlowArry?.codingExpert + dFlowArry?.baq + dFlowArry?.authReview + dFlowArry?.returnedCodingRFI) != 0"> -->
                        <a href="/d/patientDetails/{{dlFacilityId}}/BillerOnHold/5/{{monthNumber}}/{{yearNumber}}">
                            <i class="fa fa-chess-queen mr-1" style="color: #5b9ad5!important"></i>
                            <span class="pr-text">Biller on
                                Hold:</span><span
                                class="pr-numb">{{(dFlowArry?.codingExpert + dFlowArry?.baq + dFlowArry?.authReview + dFlowArry?.returnedCodingRFI) | number}}</span>
                        </a>
                    </p>
                    <p class="priorty-detail"><i class="fa fa-business-time mr-1" style="color: #72ab49!important"></i>
                        <span class="pr-text">Biller Inquiry
                            (SoS):</span><span class="pr-numb">0</span></p>
                    <p class="priorty-detail">
                        <!-- *ngIf="dFlowArry?.eoD != 0"> -->
                        <a href="/d/eod/{{dlFacilityId}}">
                            <i class="fa fa-stopwatch mr-1" style="color: #9f460d!important"></i>
                            <span class="pr-text">Regular
                                EoD:</span><span class="pr-numb">{{dFlowArry?.eoD | number}}</span>
                        </a></p>
                </div>
                <!-- <div class="detail_right1">
                    <canvas width="100%" height="90%" baseChart [data]="dailyFlowChartData"
                        [labels]="dailyFlowChartLabels" [chartType]="pieChartType" [options]="pieChartOptions"
                        [colors]="pieChartColors" [legend]="false">
                    </canvas>
                </div>
                <div style="clear:both;"></div> -->
            </div>

        </div>
        <div class="col" id="pendingClientColId">
            <div class="heading">
                <p><a href="/d/patientDetails/{{pcFacilityId}}/pendingclient/3/{{monthNumber}}/{{yearNumber}}">Pending
                        Client - <span>{{pendingClients | number}}</span></a>
                </p>
            </div>
            <div class="detail_pr" style="min-height: 195px; height: auto;">
                <div class="detail">
                    <!-- <div class="input-group index-input">
                        <select class="dashboard-calnedar-patient1" (change)="getPendingClientData($event)"
                            [(ngModel)]="pcFacilityId">
                            <option selected value="0">All Facilities</option>
                            <option *ngFor="let d of FacilityList" [value]="d.id">{{d.name}}</option>
                        </select>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.opReport !=0"> -->
                                <a id="pendingClientOpNoteIdLink" href="/d/patientDetails/{{pcFacilityId}}/OpReport/3/{{monthNumber}}/{{yearNumber}}"><i
                                        class="fa fa-envelope-open-text mr-1" style="color: #fec001!important"></i><span id="pendingClientOpNoteId"
                                        class="pr-text">Op Note:</span>
                                    <span class="pr-numb">{{pcDataArry?.opReport | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.pathology !=0"> -->
                                <a href="/d/patientDetails/{{pcFacilityId}}/Pathology/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-clipboard-check mr-1" style="color: #5b9ad5!important"></i><span id="pendingClientPathId"
                                        class="pr-text">Path:</span>
                                    <span class="pr-numb">{{pcDataArry?.pathology | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.coding !=0"> -->
                                <a href="/d/patientDetails/{{pcFacilityId}}/Coding/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-laptop-code mr-1" style="color: #9f460d!important"></i><span id="pendingClientCodingId"
                                        class="pr-text">Coding:</span>
                                    <span class="pr-numb">{{pcDataArry?.coding | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.pricing !=0"> -->
                                <a href="/d/patientDetails/{{pcFacilityId}}/Pricing/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-file-invoice-dollar mr-1" style="color: #4b77a9!important"></i><span id="pendingClientPricingId"
                                        class="pr-text">Pricing:</span>
                                    <span class="pr-numb">{{pcDataArry?.pricing | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.high$$$ !=0"> -->
                                <a
                                    href="/d/patientDetails/{{pcFacilityId}}/Client High$$$/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-dollar-sign mr-1" style="color: #4474c5!important"></i><span id="pendingClientHighApprovalId"
                                        class="pr-text">High$$$:</span>
                                    <span class="pr-numb">{{pcDataArry?.high$$$ | number}}</span>
                                </a>
                            </p>

                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.block !=0"> -->
                                <a href="/d/patientDetails/{{pcFacilityId}}/Block/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-stop-circle mr-1" style="color: #72ab49!important"></i><span id="pendingClientBlockId"
                                        class="pr-text">On Hold:</span>
                                    <span class="pr-numb">{{pcDataArry?.block | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.variance !=0"> -->
                                <a href="/d/patientDetails/{{pcFacilityId}}/Variance/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-exclamation-triangle mr-1"
                                        style="color: #a4a3aa!important"></i><span id="pendingClientVarianceId"
                                        class="pr-text">Variance:</span>
                                    <span class="pr-numb">{{pcDataArry?.variance | number}}</span>
                                </a>
                            </p>

                            <p class="priorty-detail">
                                <!-- *ngIf=" pcDataArry?.otherMisc !=0"> -->
                                <a href="/d/patientDetails/{{pcFacilityId}}/OtherMisc/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-info-circle mr-1" style="color: #5f255f!important"></i><span id="pendingClientOtherMiscId"
                                        class="pr-text">Other/Misc:</span>
                                    <span class="pr-numb">{{pcDataArry?.otherMisc | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <a href="/d/patientDetails/{{pcFacilityId}}/{{implantLog.commandName}}/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fas fa-clipboard-list mr-1" style="color: #5f255f!important"></i><span id="pendingClientImplantLogId"
                                        class="pr-text">{{implantLog.commandName}}:</span>
                                    <span class="pr-numb">{{pcDataArry?.implantLog | number}}</span>
                                </a>
                            </p>
                            <p class="priorty-detail">
                                <a href="/d/patientDetails/{{pcFacilityId}}/{{invoice.commandName}}/3/{{monthNumber}}/{{yearNumber}}">
                                    <i class="fa fa-file-invoice-dollar mr-1" style="color: #5f255f!important"></i><span id="pendingClientinvoiceId"
                                        class="pr-text">{{invoice.commandName}}:</span>
                                    <span class="pr-numb">{{pcDataArry?.invoice | number}}</span>
                                </a>
                            </p>
                        </div>
                    </div>

                </div>

            </div>

        </div>
        <div class="col">
            <div class="heading">
                <p>Waystar Rejections - <span>0</span></p>
            </div>
            <div class="detail_pr">
                <div class="detail">
                    <!-- <div class="input-group index-input">
                        <select class="dashboard-calnedar-patient1" [(ngModel)]="wsFacilityId">
                            <option selected value="0">All Facilities</option>
                            <option *ngFor="let d of FacilityList" [value]="d.id">{{d.name}}</option>
                        </select>
                    </div> -->
                
                    <div class="row">
                        <div class="col-md-12">
                            <p class="priorty-detail"><i class="fa fa-envelope-open-text mr-1"
                                    style="color: #fec001!important"></i><span class="pr-text">Cash Out Of
                                    Balance:</span>
                                <span class="pr-numb">0</span></p>

                            <p class="priorty-detail"><i class="fa fa-dollar-sign mr-1"
                                    style="color: #4474c5!important"></i><span class="pr-text">Enrollment:</span>
                                <span class="pr-numb">0</span></p>

                            <p class="priorty-detail"><i class="fa fa-clipboard-check mr-1"
                                    style="color: #5b9ad5!important"></i><span class="pr-text">Coding Issue:</span>
                                <span class="pr-numb">0</span></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<!-- Sugery Center Section End-->
<div style="height: 50px;"></div>
<div [ngClass]="{'bg-blur': showModal}">
    <div id="centerModal" *ngIf=showModal class="sidenav shadow width-700  active" >
        <div
            class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
            <div class="row case-row m-0 font-weight-bold text-primary">
                <h3>Please enter your name to continue</h3>
            </div>
            <!-- <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
                <i class="fas fa-times fa-sm"></i>
            </a> -->
        </div>
        <div class="card-body">
            <div class="centerText">
                <form class="pb-12 form-fields" [formGroup]="userForm" (ngSubmit)="addUser()">
                    <div class="row col-12 hr" >
                        <div class="remove-botttom-space col-6">
                                    <div class="form-group">
                                        <label>Full Name</label><br/>
                                        <input type="text" class="form-control" formControlName="FullName" placeholder="Enter Full Name"
                                        [ngClass]="{'is-invalid': submitted && f.FullName.errors }">
                                            <!-- [ngClass]="{'is-invalid': submitted && f.CASE.errors }" -->
                                    </div>
                                    <div *ngIf="submitted && f.FullName.errors" class="invalid-feedback">
                                        <p>*mandatory</p>
                                    </div>
                                    <div >
                                        <!-- <button type="button" 
                                            class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button> -->
                                        <button type="submit" class="btn btn-primary btn-patient-box" [disabled]="!userForm.valid">Submit</button>
                                        <!-- (click)="onSubmit()" -->
                                    </div>
                                </div>
                            
                        
        
                    </div>
                </form>   

            </div>
        </div>
    </div>
</div>