import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-facility-group',
  templateUrl: './facility-group.component.html',
  styleUrls: ['./facility-group.component.css']
})
export class FacilityGroupComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  facilityGroupArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Facility Group");
    this.getfacilityGroup();
  }
  sortListGroup(orderBy,sortDir){
    //let  data = { "FacilityId":this.edit_id,"pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue,"OrderBy":orderBy,  "SortDir":sortDir };
    let  data = {"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("FacilityGroup/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityGroupArry = resp.data;
        this.totalRows = resp.data[0].totalcount;
      //  console.log(this.facilityGroupArry,"facilityGroupArry");
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
}
  showFormModal() {
    this.formData = null;

    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }
  editGroupMaster(data) {
  
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
      this.getfacilityGroup();
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.facilityGroupArry = [];
    this.getfacilityGroup();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getfacilityGroup();
  }
  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getfacilityGroup();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }


  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getfacilityGroup();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }
  getfacilityGroup() {
    if (this.searchValue != '' && this.pagenumber != 1) {
      this.pagenumber = 1;
      this.p = 1;
    }
    
    let  data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    this.getApiService.post("FacilityGroup/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityGroupArry = resp.data;
        this.totalRows = resp.data[0].totalcount;
        console.log(this.facilityGroupArry,"facilityGroupArry");
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  delGroupMaster(delRow) {

    let data = { "id": delRow.id, "deleted": !delRow.deleted };

    this.getApiService.post("FacilityGroup/DeleteGroup", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getfacilityGroup();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }
  // editFacilityMaster(data) {
  //   this.formData = data;
  //   if (this.shouldShowFormModal) {
  //     this.shouldShowFormModal = false;
  //   }
  //   else {
  //     this.shouldShowFormModal = true;
  //   }
  // }

}
