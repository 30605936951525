import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MastersRoutingModule } from './masters-routing.module';
import { FacilityMasterComponent } from './facility-master/facility-master.component';
import { FacilityMasterFormComponent } from './facility-master/facility-master-form/facility-master-form.component';
import { TextMaskModule } from 'angular2-text-mask';
import { CategoryComponent } from './category/category.component';
import { CategoryFormComponent } from './category/category-form/category-form.component';
//import { FacilityDetailsComponent } from './facility-master/facility-details/facility-details.component';
import { FacilityGroupComponent } from '../masters/facility-group/facility-group.component';
import { FacilityGroupFormComponent } from '../masters/facility-group-form/facility-group-form.component';
import { CustomFieldComponent } from './custom-field/custom-field.component';
import { CustomFieldFormComponent } from './custom-field/custom-field-form/custom-field-form.component';
import { ClaimMasterComponent } from './claim-master/claim-master.component';
import { ClaimMasterFormComponent } from './claim-master/claim-master-form/claim-master-form.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FacilityPricingComponent } from './facility-master/facility-pricing/facility-pricing.component';
import { PaperClaimAttachmentComponent } from './paper-claim-attachment/paper-claim-attachment.component';
import { PaperClaimAttachmentFormComponent } from './paper-claim-attachment-form/paper-claim-attachment-form.component';
import { ImportpayersComponent } from './importpayers/importpayers.component';
import { ImportPayerFormComponent } from './import-payer-form/import-payer-form.component';
import { PayerDataComponent } from './payer-data/payer-data.component';
import { CustomQueueComponent } from './custom-queue/component/custom-queue.component';
import { CustomQueueFormComponent } from './custom-queue/component/custom-queue-form/custom-queue-form.component'
import { CustomQueueEditFormComponent } from './custom-queue/component/custom-queue-edit-form/custom-queue-edit-form.component';
import { BillReasonComponent } from './undo-setbill-reason/component/bill-reason.component';
import { BillReasonFormComponent } from './undo-setbill-reason/component/bill-reason-add-form/bill-reason-add-form.component';
import { ImportPhysicianComponent } from './importphysicians/importphysicians.component';
import { ImportPhysicianFormComponent } from './import-physician-form/import-physician-form.component';
import { CodingStatusComponent } from './coding-status/coding-status.component';
import { CodingStatusAddComponent } from './coding-status/coding-status-add/coding-status-add.component';
import { CodingStatusEditComponent } from './coding-status/coding-status-edit/coding-status-edit.component';

@NgModule({
  declarations: [FacilityMasterComponent, FacilityMasterFormComponent, CategoryComponent, CategoryFormComponent,
    FacilityGroupComponent, FacilityGroupFormComponent, CustomFieldComponent, CustomFieldFormComponent, ClaimMasterComponent, ClaimMasterFormComponent, FacilityPricingComponent, PaperClaimAttachmentComponent, PaperClaimAttachmentFormComponent, ImportpayersComponent, ImportPayerFormComponent, PayerDataComponent,
    CustomQueueComponent, CustomQueueFormComponent, CustomQueueEditFormComponent,
    BillReasonFormComponent, BillReasonComponent,
    ImportPhysicianComponent, ImportPhysicianFormComponent, CodingStatusComponent, CodingStatusAddComponent, CodingStatusEditComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    MastersRoutingModule,
    TextMaskModule,
    SelectDropDownModule,
    //PatientActionComponent
  ],
  exports: [FacilityMasterFormComponent]
})
export class MastersModule { }
