import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { CurrentCaseRequest } from '../state/current-case-request/current-case-request.model';
import { CurrentCaseRequestQuery } from '../state/current-case-request/current-case-request.query';

@Component({
  selector: 'app-case-request-notes',
  templateUrl: './case-request-notes.component.html',
  styleUrls: ['./case-request-notes.component.css']
})
export class CaseRequestNotesComponent implements OnInit {

  note: string;
  noteType: string;
  noteSelect: string = "General Note";
  activePatient: Patient;
  showExpand: boolean;

  currentCaseRequestList$: Observable<CurrentCaseRequest[]>;

  constructor(
    private getApiService: ApiService,
    private toastr: ToastrService,
    private patientQuery: PatientQuery,
    private currentCaseRequestQuery: CurrentCaseRequestQuery
  ) { }

  ngOnInit(): void {
    this.noteType = "Private";
    this.activePatient = this.patientQuery.getActive().find(p => true);
    this.currentCaseRequestList$ = this.currentCaseRequestQuery.selectAll();
  }

  addNotes() {
    let queueInfo = this.currentCaseRequestQuery.buildRequestWithFlags();
    console.log('addNotes', queueInfo);

    queueInfo["BdosID"] = this.activePatient.bdosid;
    queueInfo["Notestypes"] = this.noteType;
    queueInfo["NotesCat"] = this.noteSelect;
    queueInfo["quickNotes"] = this.note;

    this.getApiService.post("BillingNew/AddNote", queueInfo).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Note added Successfully!!", "Success");
        //-this.getPatientHeaderData();
        this.noteType = "Private";
        this.note = "";
        this.noteSelect = "General Note";
      }
      else {
        this.toastr.error(res.message, 'Error!');
        this.noteType = "Private";
        this.note = "";
        this.noteSelect = "General Note";
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
      this.noteType = "Private";
      this.note = "";
      this.noteSelect = "General Note";
    });
  }

  toggleExapnd(){
    this.showExpand = this.showExpand ? false : true;
  }
}
