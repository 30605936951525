export interface Group {
  id: number | string;
  groupName: string;
  isActive: number;
  totalcount: number;
}

export function createGroup(params: Partial<Group>) {
  return {
    id: params.id,
    groupName: params.groupName,
    isActive: params.isActive,
    totalcount: params.totalcount
  } as Group;
}
