import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AttachmentStore, AttachmentState } from './attachment.store';

@Injectable({ providedIn: 'root' })
export class AttachmentQuery extends QueryEntity<AttachmentState> {

  constructor(protected store: AttachmentStore) {
    super(store);
  }

}
