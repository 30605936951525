<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">


    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">CPT Codes</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>

    
<div class="card-body">

    <div class="table-responsive data-list pb-3 form-fields" style="margin-top:5px;">
        <div class="col-md-9">
            <h4 >Scheduled CPT Codes</h4>
        </div>
    <table class="table"  width="100%" cellspacing="0">
        <thead >
          <tr>
            <th >CPT Code </th>
            <th >Descripton </th>
            <th >Rate </th>
          </tr>
        </thead>
        <tbody>

           
          <tr  *ngFor="let ScheduleCptCode of ScheduledCptCodes" >
            <td>{{ ScheduleCptCode.cptCode}}</td>
            <td data-toggle="tooltip" data-placement="top"
            title="{{(ScheduleCptCode?.description)}}">
            {{(ScheduleCptCode?.description?.length>30)?
                (ScheduleCptCode?.description | slice:0:40)+'...':(ScheduleCptCode?.description)}}</td>
            <td>{{ ScheduleCptCode.rate  | currency:'USD':'symbol' }}</td>
          </tr>
          

        </tbody>
      </table>
        </div> 
    



    <form class="pb-3 form-fields" [formGroup]="PatientsForm" (ngSubmit)="onSubmit()">
    <div class="row extra-col-space">
        <div class="col-md-9">
            <h4 class="">Coded CPT Codes</h4>
        </div>
        <div class="form-group remove-botttom-space extra-button col-md-3">
            <div class="input-group">
                <button type="button" (click)="addCptCodeList()"
                    class="btn btn-primary extra-button btn-patient-box"> <i
                    class="fas fa-plus fa-sm font-icn-color"></i>Add</button>
            </div>
        </div>
    </div>
    
    
    <div formArrayName="CPTCodeList">
        <div *ngFor="let af of CPTCodeListForm.controls; let b=index">
            <div class="row" [formGroupName]="b">
                <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                    
                    <div class="input-group">
                        <input id="typeahead-http" type="text" class="form-control" aria-readonly="false"
                            [class.is-invalid]="searchFailed" formControlName="cpt" [ngbTypeahead]="search"
                            placeholder="CPT Code *" [resultFormatter]="formatter" [inputFormatter]="formatter"
                            (selectItem)="onSelectItem($event, b)" (change)="chng(b)" /><br>

                    </div>
                    <small *ngIf="searching" class="form-text text-muted">searching...</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be
                        loaded.</div>

                    <div *ngIf="submitted && af.controls.CPTCode.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                    <div *ngIf="af.controls.CPTCode.errors?.notFound" class="invalid-feedback">
                        <p>Invalid
                            CPT code.</p>
                    </div>
                </div>
                <div class="form-group form-group remove-botttom-space align-input-user col-lg-5 col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="CPTDesc"
                            aria-describedby="CPTDesc" placeholder="CPT Desc *"
                            [ngClass]="{'is-invalid': submitted && af.controls.CPTDesc.errors }">
                    </div>
                    <div *ngIf="submitted && af.controls.CPTDesc.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
                <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                    <div class="input-group">
                        <input type="text"  class="form-control"  formControlName="Rate"  placeholder="$0.00"
                        (change)="transformAmount($event, b)" (mouseout)="transformAmount($event, b)"
                        [ngClass]="{'is-invalid': submitted && af.controls.Rate.errors }"
                            oninput="this.value = 
                            !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" >
                            
                    </div>
                    
                    <div *ngIf="submitted && af.controls.Rate.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
                 
                        
                    
                    
                <div class="form-group remove-botttom-space align-input-user col-lg-3 col-md-4">
                    <div class="input-group">
                        <button type="button" (click)="removeCptCodeList(b)"
                            class="btn btn-primary btn-color-minus btn-patient-box"> <i
                                class="fas fa-minus fa-sm font-icn-color"></i>Remove</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- </form>
    <form class="pb-3 form-fields" [formGroup]="PatientsForm" (ngSubmit)="onSubmit()"> -->
        <div class="row extra-col-space">
            <div class="col-md-9">
                <h4 class="">Billed CPT Codes</h4>
            </div>
            <div class="form-group remove-botttom-space extra-button col-md-3">
                <div class="input-group">
                    <button type="button" (click)="addBCptCodeList()"
                        class="btn btn-primary extra-button btn-patient-box"> <i
                            class="fas fa-plus fa-sm font-icn-color"></i>Add</button>
                </div>
            </div>
        </div>
        
        
        <div formArrayName="BCPTCodeList">
            <div *ngFor="let af of BCPTCodeListForm.controls; let c=index">
                <div class="row" [formGroupName]="c">
                    <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                        
                        <div class="input-group">
                            <input id="typeahead-http" type="text" class="form-control" aria-readonly="false"
                                [class.is-invalid]="searchFailed" formControlName="cpt" [ngbTypeahead]="search"
                                placeholder="CPT Code *" [resultFormatter]="formatter" [inputFormatter]="formatter"
                                (selectItem)="onSelectItem2($event, c)" (change)="chng2(c)" /><br>
    
                        </div>
                        <small *ngIf="searching" class="form-text text-muted">searching...</small>
                        <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be
                            loaded.</div>
    
                        <div *ngIf="submitted && af.controls.CPTCode.errors" class="invalid-feedback">
                            <p>*mandatory</p>
                        </div>
                        <div *ngIf="af.controls.CPTCode.errors?.notFound" class="invalid-feedback">
                            <p>Invalid
                                CPT code.</p>
                        </div>
                    </div>
                    <div class="form-group form-group remove-botttom-space align-input-user col-lg-5 col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="CPTDesc"
                                aria-describedby="CPTDesc" placeholder="CPT Desc *"
                                [ngClass]="{'is-invalid': submitted && af.controls.CPTDesc.errors }">
                        </div>
                        <div *ngIf="submitted && af.controls.CPTDesc.errors" class="invalid-feedback">
                            <p>*mandatory</p>
                        </div>
                    </div>
                    <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                        <div class="input-group">
                            <input type="text"  class="form-control" formControlName="Rate"  placeholder="$0.00"
                            (change)="transformAmountb($event, c)" (mouseout)="transformAmountb($event, c)"
                            [ngClass]="{'is-invalid': submitted && af.controls.Rate.errors }"
                                oninput="this.value = 
                                !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null" >  
                        </div>
                        <div *ngIf="submitted && af.controls.Rate.errors" class="invalid-feedback">
                            <p>*mandatory</p>
                        </div>
                    </div>      
                    <div  class="form-group remove-botttom-space align-input-user col-lg-3 col-md-4">
                        <div class="input-group">
                            <button type="button" (click)="removeBCptCodeList(c)"
                                class="btn btn-primary btn-color-minus btn-patient-box"> <i
                                    class="fas fa-minus fa-sm font-icn-color"></i>Remove</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right">
            <button type="button" [disabled]="cancelButtonStatus" (click)="onClickClose()"
                class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="submit"  class="btn btn-primary btn-patient-box" [disabled]="submitButtonStatus">
                    <span *ngIf="submitButtonStatus" class="fas fa-spinner fa-spin "></span>
                    {{submitButton}}
                </button>
            
        </div>
    
        </form>


    


    


</div>
   
</div>

<!-- Sidenav-->