import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-dr-client-form',
  templateUrl: './dr-client-form.component.html',
  styleUrls: ['./dr-client-form.component.css']
})
export class DrClientFormComponent implements OnInit {
  submit:any="submitee"
  @Output() closeEmitter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    
  }

  onCancel() {
    this.closeEmitter.emit();
   
  }

  onClickClose() {
    this.submit="Submiting"
    this.closeEmitter.emit();
  }

}
