export interface Physician {
  id: number | string;
  sourceSystemName:string; // naming convention is not correct using the exising response format we get from api
  facility: string;
  fullName: string;
  firstName: string;
  lastName: string;
  systemKey: string;
  active: number;
  importDate: Date;
  updateDate: Date;
  totalcount: number;
}

export function createPhysician(params: Partial<Physician>) {
  return {
    ...params
  } as Physician;
}
