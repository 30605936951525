import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CodingStatus } from 'src/app/modules/masters/coding-status/state/coding-status.model';
import {
  createNSNDropdownConfig,
  NSNDropdownConfig,
} from 'src/app/shared/components/nsn-dropdown/nsn-dropdown.component.types';
import {
  createCodingAction,
  httpGetRequestMethod,
  httpPOSTRequestMethod,
  NameConstant,
} from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { createAttachment } from 'src/app/shared/components/attachment/state/attachment.model';
import * as fileSaver from 'file-saver';
import { AttachmentService } from 'src/app/shared/components/attachment/state/attachment.service';

@Component({
  selector: 'app-coding-action',
  templateUrl: './coding-action.component.html',
  styleUrls: ['./coding-action.component.css'],
})
export class CodingActionComponent implements OnInit, OnDestroy {
  componentName: NameConstant = createCodingAction;
  @Output() closeEmitter = new EventEmitter();
  coderDropdownConfig: NSNDropdownConfig;
  statusDropdownConfig: NSNDropdownConfig;
  loading: boolean;
  submitted: boolean;
  _formData: any;
  isAttachedFileVisible: boolean = false;
  comment;
  file;
  fileName: string;
  bdosid: string | Blob | any;
  uploadFileText: string;
  uploadFile: any;

  saveSub: Subscription;
  @Output() onUpload = new EventEmitter();

  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
    }
    console.log('this.formdata', this._formData);
  }

  activePatient: Patient;
  selectedCoder;
  selectedStatus: CodingStatus;

  constructor(
    private patientQuery: PatientQuery,
    private patientService: PatientService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private apiService: ApiService,
    private attachmentService: AttachmentService
  ) // public fb: FormBuilder,
  // public form: FormGroup,
  {}

  ngOnInit(): void {
    // this.form: FormGroup;
    //  this.form = this.fb.group({
    //   file: [null]
    // })

    this.activePatient = this.patientQuery.getActive().find((p) => true);
    this.bdosid = this.activePatient?.bdosid;

    this.initCoderDropdownConfig();
    this.initStatusDropdownConfig();
  }
  fileChanged(event) {
    this.file = (event.target as HTMLInputElement).files[0];
    this.fileName = this.file.name;
    console.log('file changed to ', this.file);
    // this.form.patchValue({
    //   file : this.file
    // });
    // this.form.get('file').updateValueAndValidity()
  }
  uploadAttachment() {
    const attachment = createAttachment({
      id: this.file.name,
      file: this.file,
      comments: this.comment,
    });
    console.log(attachment);
    this.attachmentService.add(attachment);
    this.onUpload.emit();
  }
  downloadFile() {
    let blob: any = new Blob([this.file], { type: 'text/json; charset=utf-8' });
    fileSaver.saveAs(blob, this.fileName);
  }

  ngOnDestroy() {
    if (this.saveSub) {
      this.saveSub.unsubscribe();
    }
  }

  initCoderDropdownConfig() {
    const facId = this.activePatient.facilityId;
    this.coderDropdownConfig = createNSNDropdownConfig({
      sourceApi: 'ApplicationUser/BindCoderUser',
      sourceApiBody: { pageNum: '1', pageSize: '15000', FacilityId: facId },
      key: 'id',
      value: 'email',
      httpRequestType: httpPOSTRequestMethod,
    });
  }

  initStatusDropdownConfig() {
    this.statusDropdownConfig = createNSNDropdownConfig({
      sourceApi: 'InquiryStatus/BindInquiryStatusDropdown',
      sourceApiBody: {},
      key: 'id',
      value: 'inquirystatus',
      httpRequestType: httpGetRequestMethod,
    });
  }

  onCoderChange(event$) {
    console.log('onCoderChange', event$);
    this.selectedCoder = event$;
  }

  onStatusChange(event$) {
    console.log('onStatusChange', event$);
    this.selectedStatus = event$;
  }
  onClickClose() {
    this.patientService.setActive([]);
    this.closeEmitter.emit();
  }
  onHeaderClickClose() {
    this.closeEmitter.emit();
  }

  // uploadAttachment() {

  //   if (this.fileName != null && this.fileName != undefined) {
  //     let frmData = new FormData();
  //     frmData.append("txtNotes", (this.uploadFileText || ""));
  //     frmData.append("file", this.file , this.fileName);

  //     this.apiService.uploadFiles("BillingNew/Upload", frmData).subscribe(res => {
  //       if (res.status == "Success") {
  //         this.toastr.success("Uploaded Successfully!!", "Success");
  //         this.file = "";
  //         this.uploadFile = null;
  //         this.uploadFileText = "";
  //       }
  //       else {
  //         this.toastr.error(res.message, 'Error!');
  //       }
  //     }, (error) => {
  //       this.toastr.error(error, 'Error!');
  //     });

  //   }
  // }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    const bdosid = this.activePatient?.bdosid;
    console.log(this.file);
    const formData = new FormData();
    formData.append('AssignedComments', this.comment || '');
    var sbdosid = bdosid.toString();
    formData.append('BDOSID', sbdosid);
    if (this.file != null && this.fileName != null) {
      formData.append('file', this.file, this.fileName);
      formData.append('fileType', 'Attached File');
      formData.append('fileName', this.fileName);
    }
    formData.append('InquiryStatusId', this.selectedStatus.id.toString());
    formData.append('FacilityID', this.activePatient.facilityId.toString());

    // console.log(formData,"Form Data")

    if (!this.selectedStatus) {
      this.toastr.warning('please select status');
      this.loading = false;
      return;
    }
    if (!bdosid) {
      this.toastr.warning('unable to fetch bdosid');
      this.loading = false;
      return;
    }
    const requestBody = {
      InquiryStatusId: this.selectedStatus.id,
      AssignCodingInquiryCases: [
        {
          Bdosid: bdosid,
          FacilityID: this.activePatient.facilityId,
          AssignedComments: this.comment,
        },
      ],
    };
    this.saveSub = this.apiService
      .uploadFiles('CodingInquiry/SaveCodingInquiry', formData)
      .subscribe(
        (res) => {
          if (res.status === 'Success') {
            try {
              this.apiService
                .get('BillingNew/GetCodintReviewCount')
                .subscribe((res) => {
                  console.log(res, 'count');
                  localStorage.setItem('count', res.codingReviewCount);
                });
            } catch (err) {
              console.log(err);
            }
            this.loading = false;
            this.submitted = false;
            this.toastr.success('Saved Coding Inquiry');
            this.onClickClose();
          } else {
            this.loading = false;
            this.submitted = false;
            this.toastr.error('Server  Error!!');
          }
        },
        (error) => {
          this.loading = false;
          this.submitted = false;
          this.toastr.error('Unexpected Error!!');
        }
      );
  }
}
