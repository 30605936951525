<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">{{facilityName}}
                        {{currentMonth}}</h1>
                    <select class="col-md-2 form-control closeFileSidenav mr-4" (change)="onChangeSelect($event)"
                        [(ngModel)]="commandtype">
                        <option *ngFor="let data of CategoryArry" value="{{data.value}}">{{data.name}}</option>
                    </select>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th></th>
                                    <th>Name</th>
                                    <!-- <th>DOB</th> -->
                                    <th>MRN</th>
                                    <!-- <th>Case</th> -->
                                    <th>Provider</th>
                                    <th>DoS</th>
                                    <th>Insurance</th>
                                    <th>Group#</th>
                                    <!-- <th>Insurance Id</th> -->
                                    <th>Information Requests</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th></th>
                                    <th>Name</th>
                                    <!-- <th>DOB</th> -->
                                    <th>MRN</th>
                                    <!-- <th>Case</th> -->
                                    <th>Provider</th>
                                    <th>DoS</th>
                                    <th>Insurance</th>
                                    <th>Group#</th>
                                    <!-- <th>Insurance Id</th> -->
                                    <th>Information Requests</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of CurrentMonthArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>{{i+1}}</td>
                                    <td><input type="checkbox"></td>
                                    <td>{{data.name}}</td>
                                    <!-- <td>{{(data.dob || '')  | date: 'MM/dd/yyyy'}}</td> -->
                                    <td>{{data.mrn}}</td>
                                    <!-- <td>{{data.dosId}}</td> -->
                                    <td>{{data.physician}}</td>
                                    <td>{{data.dateOfSurgery | date: 'MM/dd/yyyy'}}</td>
                                    <td>{{data.insuranceProvider}}</td>
                                    <td>{{data.groupNo}}</td>
                                    <!-- <td>{{data.insuranceId}}</td> -->
                                    <td>
                                        <i title="In Coding" class="fa fa-code icon-color-blue mr-2"
                                            *ngIf="data.inCoding > 0 || data.returnedFor == 'In Coding'"></i>
                                        <i title="Returned Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                            *ngIf="data.codingReturned > 0"></i>
                                        <i title="Returned" class="fa fa-caret-square-left icon-color-blue mr-2"
                                            *ngIf="data.returned > 0"></i>
                                        <i title="Op Note" class="fa fa-envelope-open-text icon-color-blue mr-2"
                                            *ngIf="data.opReport > 0 || data.returnedFor == 'Op Note'"></i>
                                        <i title="Pathology" class="fa fa-clipboard-check icon-color-blue mr-2"
                                            *ngIf="data.pathology > 0 || data.returnedFor == 'Pathology'"></i>
                                        <i title="Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                            *ngIf="data.coding > 0 || data.returnedFor == 'Coding Query'"></i>
                                        <i title="Pricing" class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                            *ngIf="data.pricing > 0 || data.returnedFor == 'Pricing'"></i>
                                        <i title="High$$$" class="fa fa-dollar-sign icon-color-blue mr-2"
                                            *ngIf="data.high$$$ > 0 || data.returnedFor == 'NSN High$$$' || data.returnedFor == 'Client High$$$'"></i>
                                        <i title="Block" class="fa fa-stop-circle icon-color-blue mr-2"
                                            *ngIf="data.block > 0 || data.returnedFor == 'Block Report'"></i>
                                        <i title="Variance" class="fa fa-exclamation-triangle icon-color-blue mr-2"
                                            *ngIf="data.variance > 0 || data.returnedFor == 'Variance'"></i>
                                        <i title="Other Misc" class="fa fa-info-circle icon-color-blue mr-2"
                                            *ngIf="data.otherMisc > 0 || data.returnedFor == 'Other/Misc'"></i>
                                        <span *ngIf="data.lastNote != ''">{{data.lastNote}}</span>
                                    </td>
                                    <td><i title="Patient View" class="fa fa-eye"></i></td>
                                    <!-- {{data.dateOfSurgery | date:'MM/dd/yyyy'}} -->
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->