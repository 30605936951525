<!-- Begin Page Content -->
<div class="d-flex flex-column  arcontent ">
    <div class="container-fluid ">
        <div class="container1">
            <!-- Daily Progress-->
            <div class="row daily-progress">
                <div class="col-md-4">
                    <div class="border-progress">
                        <p class="progress-text">Daily Progress</p>
                        <!-- <img src="assets/img/dashboard-Progress-logo.png" alt="" class="dashboard-logo-side"> -->

                        <p class="progress-bar-text-second">Case Goal </p>
                        <div class="progress">
                            <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="80"
                                aria-valuemin="0" aria-valuemax="100" style="width:80%">
                                <span class="sr-only">80% Complete</span>50
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-8 newtop6sec">
                    <p class="progress-text-right">
                        Monthly Performance - April 2022

                        <span class="progress-text-date">April 1st - April 22th</span>
                    </p>
                    <p class="monthly-icon-text"><i class="fa fa-star fa-color-blue"></i>180
                        <span class="monthly-progress-box1">Total Assigned</span>
                    </p>
                    <p class="monthly-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>210
                        <span class="monthly-progress-box1">Worked</span>
                    </p>
                    <p class="monthly-icon-text"><i class="fa fa-file-invoice fa-color-blue"></i>210
                        <span class="monthly-progress-box1">Avg Cases/Day</span>
                    </p>
                    <p class="monthly-icon-text"><i class="fa fa-calendar fa-color-blue"></i>65
                        <span class="monthly-progress-box1">Avg Days to Close</span>
                    </p>
                    <p class="monthly-icon-text monthly-icon-text-billed"><i
                            class="fa fa-file-invoice-dollar fa-color-blue"></i>$210,460
                        <span class="monthly-progress-box1">Appeals Collected</span>
                    </p>
                    <p class="monthly-icon-text"><i class="fa fa-user fa-color-blue"></i>3.5
                        <span class="monthly-progress-box1">No. of Touches</span>
                    </p>
                </div>
            </div>
            <!-- daily Progreess End-->
        </div>
        <!-- container end-->
        <!-- /.container-fluid -->
    </div>
    <!-- Sugery Center Section-->
    <div class="container-fluid cont-mrg ">
        <div class="container1">
            <div class="row advance-surgery-center">
                <div class="col-md-1 surgery-row">
                    <p class="advance-section-text">Denial</p>
                </div>
                <div class="col-md-11" style="padding: 0px;">
                    <p class="advance-icon-text">10 <i class="fas fa-network-wired fa-color-blue"></i>

                    </p>
                    <p class="advance-icon-text">
                    </p>

                    <div class="adv-progress-bar">
                        <p class="progress-bar-text-second wdth-advance"> </p>


                    </div>
                    <p class="advance-icon-text price-box1 ">
                        <span class="surgety-number">$290,130 </span> 
                        <span class="spn-goaltext">Total Balance</span>

                    </p>
                    <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,51)"><i
                            *ngIf="selectedIndex != 51" class="fa fa-plus"></i>
                        <i *ngIf="selectedIndex == 51" class="fa fa-minus"></i>
                    </p>
                </div>
            </div>
            <!-- Sugery Center Section End-->
            <!-- container fluid advcance secttion end-->
            <!-- Facility Action Section-->
            <!-- <div class="container-fluid" > -->
            <!-- <div class="container1"> -->
            <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 51">


                <table class="toptable" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Patient</th>
                            <th>Facility</th>
                            <th>Status </th>
                            <th>Pri CPT</th>
                            <th>Billed</th>
                            <th>Balance</th>
                            <th>Payer</th>
                            <th>DOS </th>
                            <th>Age </th>

                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center </td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>




                    </tbody>
                </table>


            </div>
            <!-- container end-->
        </div>
        <!-- container end-->
    </div>
    <!-- container fluid advcance secttion end-->
    <!-- Sugery Center Section-->
    <div class="container-fluid cont-mrg ">
        <div class="container1">
            <div class="row advance-surgery-center">
                <div class="col-md-1 surgery-row">
                    <p class="advance-section-text">Top 10</p>
                </div>
                <div class="col-md-11" style="padding: 0px;">
                    <p class="advance-icon-text">10 <i class="fas fa-network-wired fa-color-blue"></i>

                    </p>


                    <!-- <div class="adv-progress-bar">
                        <p class="progress-bar-text-second wdth-advance"> </p>


                    </div> -->
                    <p class="advance-icon-text price-box1 ">
                        <span class="surgety-number">$290,130 </span>
                        <span class="spn-goaltext">Total Balance</span>

                    </p>
                    <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,52)"><i
                            *ngIf="selectedIndex != 52" class="fa fa-plus"></i>
                        <i *ngIf="selectedIndex == 52" class="fa fa-minus"></i>
                    </p>
                </div>
            </div>

            <!-- container fluid advcance secttion end-->
            <!-- Facility Action Section-->
            <!-- <div class="container-fluid" > -->
            <!-- <div class="container1"> -->
            <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 52">


                <table class="toptable" id="dataTable" width="100%" cellspacing="0">
                    <thead>
                        <tr>
                            <th>Patient</th>
                            <th>Facility</th>
                            <th>Status </th>
                            <th>Pri CPT</th>
                            <th>Billed</th>
                            <th>Balance</th>
                            <th>Payer</th>
                            <th>DOS </th>
                            <th>Age </th>

                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>
                        <tr>
                            <td>Anderson Jeniffer</td>
                            <td>HITech surgery center</td>
                            <td>Manager Claim Submitted</td>
                            <td>85600</td>
                            <td>87654</td>
                            <td>$65545</td>
                            <td>ABCD</td>
                            <td>02/01/2022</td>
                            <td>102</td>

                        </tr>




                    </tbody>
                </table>


            </div>
            <!-- container end-->
        </div>
    </div>
    <!-- Sugery Center Section End-->
    <!-- </div> -->
    <!-- container end-->
    <!-- </div> -->
    <!-- container fluid Facility Action Secttion end-->
    <!-- Sugery Center Section-->

    <div class="row ">
        <div class="col-md-8">
            <div class="container-fluid cont-mrg contmarg2">
                <div class="container1">
                    <div class="row advance-surgery-center">
                        <div class="col-md-2 surgery-row">
                            <p class="advance-section-text">Other</p>
                        </div>
                        <div class="col-md-10" style="padding: 0px;">

                            <!--<p class="advance-icon-text plus-sign-bx expand-data">-->
                            <h6 class="rightalign advance-icon-text texr-color-blue">More <i
                                    class="fa fa-angle-right  "></i></h6>
                        </div>
                    </div>
                    <!-- Sugery Center Section End-->
                    <div class="row widget-dashboard" id="show-data">
                        <table class="toptable" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Patient</th>
                                    <th>Age</th>
                                    <th>Status </th>
                                    <th>Last Worked</th>
                                    <th>Pri. CPT</th>
                                    <th>Balance</th>


                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>Anderson Jeniffer</td>
                                    <td>100</td>
                                    <td>Manager Claim Submitted</td>
                                    <td>01/02/2022</td>
                                    <td>87654</td>
                                    <td>$65545</td>


                                </tr>
                                <tr>
                                    <td>Anderson Jeniffer</td>
                                    <td>100</td>
                                    <td>Manager Claim Submitted</td>
                                    <td>01/02/2022</td>
                                    <td>87654</td>
                                    <td>$65545</td>


                                </tr>
                                <tr>
                                    <td>Anderson Jeniffer</td>
                                    <td>100</td>
                                    <td>Manager Claim Submitted</td>
                                    <td>01/02/2022</td>
                                    <td>87654</td>
                                    <td>$65545</td>


                                </tr>
                                <tr>
                                    <td>Anderson Jeniffer</td>
                                    <td>100</td>
                                    <td>Manager Claim Submitted</td>
                                    <td>01/02/2022</td>
                                    <td>87654</td>
                                    <td>$65545</td>


                                </tr>
                                <tr>
                                    <td>Anderson Jeniffer</td>
                                    <td>100</td>
                                    <td>Manager Claim Submitted</td>
                                    <td>01/02/2022</td>
                                    <td>87654</td>
                                    <td>$65545</td>


                                </tr>
                                <tr>
                                    <td>Anderson Jeniffer</td>
                                    <td>100</td>
                                    <td>Manager Claim Submitted</td>
                                    <td>01/02/2022</td>
                                    <td>87654</td>
                                    <td>$65545</td>


                                </tr>

                            </tbody>

                        </table>

                    </div>

                </div>
                <!-- container end-->
            </div>
        </div>

        <div class="col-md-4">

            <div class="container-fluid cont-mrg contmarg2">
                <div class="container1">

                    <div class="row">

                        <div class="forboxsection">
                            <div class="logofirstbox"> <img src="assets/img/blue-logo.png" alt="" />
                            </div>
                            <div class="righttextboxsec">

                                <ul class="myybox">
                                    <li>
                                        <h4> Internal </h4>
                                    </li>
                                    <li>
                                        <div class="cirlce-blue-num">10</div>
                                    </li>
                                    <li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-chevron-right texr-color-blue" aria-hidden="true"></i></li>
                                </ul>
                                <div class="clear"></div>
                            </div>

                        </div>

                    </div>




                    <div class="row">

                        <div class="forboxsection">
                            <div class="logofirstbox"> <i class="fa fa-building text-color-darkblue"
                                    aria-hidden="true"></i>
                            </div>
                            <div class="righttextboxsec">

                                <ul class="myybox">
                                    <li>
                                        <h4>Center </h4>
                                    </li>
                                    <li>
                                        <div class="cirlce-blue-num">10</div>
                                    </li>
                                    <li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-chevron-right texr-color-blue" aria-hidden="true"></i></li>
                                </ul>
                                <div class="clear"></div>
                            </div>

                        </div>

                    </div>



                    <div class="row">

                        <div class="forboxsection">
                            <div class="logofirstbox"> <i class="fa fa-plus-square text-color-darkblue"
                                    aria-hidden="true"></i>
                            </div>
                            <div class="righttextboxsec">

                                <ul class="myybox">
                                    <li>
                                        <h4>Insurer </h4>
                                    </li>
                                    <li>
                                        <div class="cirlce-blue-num">10</div>
                                    </li>
                                    <li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-chevron-right texr-color-blue" aria-hidden="true"></i></li>
                                </ul>
                                <div class="clear"></div>
                            </div>

                        </div>

                    </div>



                    <div class="row">

                        <div class="forboxsection">
                            <div class="logofirstbox"> <i class="fa fa-stop text-color-darkblue" aria-hidden="true"></i>
                            </div>
                            <div class="righttextboxsec">

                                <ul class="myybox">
                                    <li>
                                        <h4>On Hold </h4>
                                    </li>
                                    <li>
                                        <div class="cirlce-blue-num">10</div>
                                    </li>
                                    <li><i class="fa fa-briefcase" aria-hidden="true"></i></li>
                                    <li><i class="fa fa-chevron-right texr-color-blue" aria-hidden="true"></i></li>
                                </ul>
                                <div class="clear"></div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>

    </div>
</div>