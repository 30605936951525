import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { CustomQueue } from './custom-queue.model';

export interface CustomQueueState extends EntityState<CustomQueue, number>, MultiActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'custom-queue' })
export class CustomQueueStore extends EntityStore<CustomQueueState> {

  constructor() {
    super();
  }

}
