import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { NameConstant, userProductivityReport } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { createUserProductivity, UserProductivity } from '../state/user-productivity/user-productivity.model';
import { UserProductivityQuery } from '../state/user-productivity/user-productivity.query';
import { UserProductivityService } from '../state/user-productivity/user-productivity.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-user-productivity-report',
  templateUrl: './user-productivity-report.component.html',
  styleUrls: ['./user-productivity-report.component.css']
})
export class UserProductivityReportComponent implements OnInit, OnDestroy {
  componentName: NameConstant = userProductivityReport;
  coloumns: ColDef[] = [];
  datasource$: Observable<UserProductivity[]>;
  datasourceSub: Subscription;
  loading: boolean;
  submitted: boolean;
  fromDateError: string;
  toDateError: string;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  selFromDate;
  selToDate;
  activeUserProductivityReportLineId$ : Observable<number | string>;
  exportSub: Subscription;

  constructor(
    private userProductivityService: UserProductivityService,
    private userProductivityQuery: UserProductivityQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.initColoumns();
    this.initDataSource();
    this.datasource$ = this.userProductivityQuery.selectAll();
    this.commonUtil.setTitle(this.componentName.value);
    this.activeUserProductivityReportLineId$ = this.userProductivityQuery.selectActiveId();
  }

  ngOnDestroy(){
    if(this.datasourceSub){
      this.datasourceSub.unsubscribe();
    }
    this.exportSub?.unsubscribe();
    this.userProductivityService.set([]);
  }

  initDataSource(){
    this.loading = true;
    this.submitted = true;
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "pageNum": this.currentPage,
      "pageSize": this.itemsPerPage
  }
    this.datasourceSub = this.apiService.post("Reports/UserProductivity", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.loading = false;
        this.submitted = false;
        const entities: UserProductivity[] = res.data.map(r => createUserProductivity({
          userid: r.userid,
          email: r.email,
          username: r.username,
          fromdate: r.fromdate,
          todate: r.todate,
          totalcases: r.totalcases,
          totalchargedbill: r.totalchargedbill,
          caseswph: r.caseswph,
          totalCount: r.totalCount
        }));

        this.totalRows = entities ? (entities[0] ?  entities[0].totalCount : 0) : 0;
        this.userProductivityService.set(entities);
      }else{
        this.loading = false;
        this.submitted = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.submitted = false;
      this.toaster.error("Unexpected Error!!", err);
    })
  }

  initColoumns(){
    this.coloumns = [...this.coloumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "username", displayName: "UserName", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "fromdate", displayName: "From Date", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "todate", displayName: "To Date", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalcases", displayName: "Total # of Cases Worked", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalchargedbill", displayName: "Total Charges Billed", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "caseswph", displayName: "Cases Worked Per Hour", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "", displayName: "Action", isDataCol: false, isDataTypeDate: false, isAction: true })];
  }
  
  pageChange(event$){
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));

    if(!this.selFromDate){
      this.fromDateError = "From Date cannot be blank";
      return;
    }else{
      this.fromDateError = '';
    }
    if(!this.selToDate){
      this.toDateError = "To Date cannot be blank";
      return;
    }else{
      this.toDateError = '';
    }

    
    this.initDataSource();
  }

  showReportLineDetail(data){
    console.log(data);
    this.userProductivityService.setActive(data.userid);
  }

  onExport(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));

    if(!this.selFromDate){
      this.fromDateError = "From Date cannot be blank";
      return;
    }else{
      this.fromDateError = '';
    }
    if(!this.selToDate){
      this.toDateError = "To Date cannot be blank";
      return;
    }else{
      this.toDateError = '';
    }

    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
  }

    this.datasourceSub = this.apiService.post4File("Reports/DownloadUserProductivity", requestBody).subscribe(res => {
     // saveAs(res, this.componentName.value+".xlsx");
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
			saveAs(blob, this.componentName.value+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }
}
