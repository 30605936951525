import { Component, OnInit, Output } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { createBillReason, BillReason } from '../state/bill-reason.model';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { BillReasonQuery } from '../state/bill-reason.query';
import { BillReasonService } from '../state/bill-reason.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-bill-reason',
  templateUrl: './bill-reason.component.html',
  styleUrls: ['./bill-reason.component.scss']
})
export class BillReasonComponent implements OnInit {
  //rows$: Observable<BillReason[]>;
  p: number = 1;
  headerName: string = 'Undo Set Billed Reason';
  addBtnLbl: string = 'Add Undo Set Billed Reason';
  columns: ColDef[];
  shouldShowFormModal$: Observable<boolean>;
  formData: any = null;
  billReasonList: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  orderBy: string = '';
  sortDir: string = 'asc';
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private billReasonQuery: BillReasonQuery,
    private billReasonService: BillReasonService,
    private selectionQuery: SelectionQuery,
    private selectionService: SelectionService
  ) { }

  ngOnInit() {
    this.commonUtil.setTitle(this.headerName);
    this.initializeColumns();
    this.setBillingReasonList();
    this.shouldShowFormModal$ = this.selectionQuery.select().pipe(map((res => {
      this.setBillingReasonList();
      return res.value === "add" || res.value === "edit";
    })));
  }

  initializeColumns() {
    this.columns = [
      createColDef({ name: 'Reason', sortable: true }),
      createColDef({ name: 'Date', sortable: false }),
      createColDef({ name: 'Status', sortable: false }),
      createColDef({ name: 'Action', sortable: false })]
  }
  
  setBillingReasonList(){
    this.billReasonService.get(this.pagenumber, this.pageValue, this.searchValue)
    .subscribe(res=> {
      this.billReasonList = res.data;
      this.totalRows = res.data[0]?.totalcount;
    });
  }

  showFormModal() {
   this.selectionService.changeSelection("add");
   this.billReasonService.inactive();
  }


  onEdit(event$){
    this.billReasonService.active(event$);
    this.selectionService.changeSelection("edit");
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.setBillingReasonList();
  }

  delBillReason(delRow) {

    const  activeInactive = delRow.isActive == 1 ? 0 : 1;
    let data = { "id": delRow.id, "IsActive": activeInactive };
    console.log('req', data);
    this.getApiService.post("BillReason/DeactivateBillReason", data).subscribe(res => {
      console.log('delBillReason', res);
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
       this.setBillingReasonList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    this.p = 1;
    this.setBillingReasonList();
  }

  search() {
    this.pagenumber = 1;
    this.p = 1;
    this.setBillingReasonList();
  }

  sortListBillReason(value, order) {
    this.pagenumber = 1;
    this.p = 1;
    this.orderBy = value;
    this.sortDir = order;
    this.billReasonService.getByOrder(this.pagenumber, this.pageValue, this.searchValue, this.orderBy, this.sortDir)
    .subscribe(res=> {
      this.billReasonList = res.data;
      this.totalRows = res.data[0]?.totalcount;
    });
  }
}