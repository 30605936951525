<ngx-spinner bdColor="rgba(0, 0, 0, 0.05)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<!-- Begin Page Content -->
<div class="container-fluid"  >
    <!-- Content Row -->
    <div class="row counters" >
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4"  >
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="top-action-container">
                        <div class="item-facility">
                           
                            <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="facilityID"
                                (change)="getDataByFacility()">
                                <option selected value="0">All Facilities</option>
                                <option [value]="f.id" *ngFor="let f of (FacilityList$ | async)">{{f.name}}</option>
                            </select>
                        </div>
                        <div class="item-category">
                            <select (change)="getDataByCat($event)" class="custom-select dashboard-calnedar-patient">
                                <option *ngIf="defCategoryArry == {}" value="">Select</option>
                                <option *ngIf="defCategoryArry !== {}"
                                    [selected]="defCategoryArry?.data?.value === commandtype"
                                    [value]="defCategoryArry?.data?.value">
                                    {{defCategoryArry?.data?.name}}</option>
                                <option [value]="f.value"
                                    [selected]="isCategoryItemOptionIsSelected(f.value,commandtype)"
                                    *ngFor="let f of CategoryArry">{{ (f.name != 'NSN High $$$ Cases') ? f.name : 'High $$$ Cases' }}</option>
                            </select>
                        </div>
                        <div class="item-bulk-action">
                            <button class="btn btn-primary btn-sm" type="submit" *ngIf="isBulkActionEnabled()"
                                (click)="startBulkAction()" [disabled]="(hasActivePatients$ | async) === false">Bulk
                                Action</button>
                        </div>
                        <div class="item-review-cases">
                            <button class="btn btn-primary btn-sm" type="submit" *ngIf="isReviewCasesEnabled()"
                                (click)="startReviewCases()" [disabled]="(hasActivePatients$ | async) === false">Review
                                Cases</button>
                        </div>
                        <div class="item-month" *ngIf="catId == 7">
                            <select class="custom-select  dashboard-calnedar-patient4" [(ngModel)]="monthNumber"
                                (change)="getCategoryArry()">
                                <option selected>Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                            </select>
                        </div>
                        <div class="item-year" *ngIf="catId == 7">
                            <select class="custom-select  dashboard-calnedar-patient5" [(ngModel)]="yearNumber"
                            (change)="getCategoryArry()">
                            <option selected>Year</option>
                            <option value="2021">2021</option>
                            <option value="2020">2020</option>
                            <option value="2019">2019</option>
                            <option value="2018">2018</option>
                            <option value="2017">2017</option>
                            <option value="2016">2016</option>
                            <option value="2015">2015</option>
                            <option value="2014">2014</option>
                            <option value="2013">2013</option>
                            <option value="2012">2012</option>
                            <option value="2011">2011</option>
                            <option value="2010">2010</option>
                            <option value="2009">2009</option>
                        </select>
                        </div>
                        <div class="item-blank"></div>
                        <div class="item-search">
                            <input id="searchPatient" [(ngModel)]="searchValue" type="text" name="searchPatient"
                            placeholder="Search Patient" (keyup.enter)="searchPatient()">
                        </div>
                        <div class="item-export">
                            <a href="javascript:void(0)" (click)="exportData()"> <i class="fas fa-file-excel"></i></a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        
                    </div>

                    <ng-template [ngIf]="IndVsbl && tblVsbl && !showAccordianComponent(selectedCommand)"
                        class="table-responsive data-list">
                        <div>
                            <table class="table" id="dataTable" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th> 
                                        <th><input type="checkbox"  [checked]=SelectAllCheckboxForGrid (click)="checkAll()"></th>
                                        <th>Facility<i (click)="sortList('client', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('client', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>MRN <i (click)="sortList('mrn', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('mrn', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Patient <i (click)="sortList('name', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('name', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Provider</th> -->
                                        <th>DoS <i (click)="sortList('dateOfSurgery', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('dateOfSurgery', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Primary CPT</th> -->
                                        <th>Insurance <i (click)="sortList('insuranceProvider', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('insuranceProvider', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Active Queue</th>
                                        <th>Age <i (click)="sortList('timeCol', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('timeCol', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Comment</th>
                                        <th>Quick Access</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Facility <i (click)="sortList('client', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('client', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th class="flex">MRN <i (click)="sortList('mrn', 'asc', commandtype)"
                                                class="fas fa-arrow-up "></i>
                                            <i (click)="sortList('mrn', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Patient <i (click)="sortList('name', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('name', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Provider</th> -->
                                        <th>DoS <i (click)="sortList('dateOfSurgery', 'asc', commandtype)"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('dateOfSurgery', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Primary CPT</th> -->
                                        <th class="flex">Insurance <i (click)="sortList('insuranceProvider', 'asc', commandtype)"
                                                class="fas fa-arrow-up "></i>
                                            <i (click)="sortList('insuranceProvider', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Active Queue</th>
                                        <th class="flex">Age <i (click)="sortList('timeCol', 'asc', commandtype)"
                                                class="fas fa-arrow-up "></i>
                                            <i (click)="sortList('timeCol', 'desc', commandtype)"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Comment</th>
                                        <th>Quick Access</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr
                                        *ngFor='let data of allpatientQdata[commandtype] | paginate: { itemsPerPage: pageValue, currentPage: p[commandtype], totalItems: allpatientttlrws[commandtype] }; let i = index'>
                                        <td>
                                            <div>

                                                {{(i+1) + ((pagenumber-1)*pageValue)}}
                                                <!-- {{i+1}} -->
                                            </div>
                                        </td>
                                        <td><input class="gridAllCheckboxes" [checked]="gridCheckboxStatus" type="checkbox" (click)="selectRow(data)"
                                                [checked]="isRowActive(data)"></td>
                                        <td data-toggle="tooltip" style="word-wrap: break-word;width: 200px;" data-placement="top" title="{{(data?.client)}}">
                                            <a href="javascript:void(0)" (click)='showDetailModal(data)'>
                                                <!-- {{(data?.client?.length>20)? (data?.client |
                                                slice:0:23):(data?.client)}}<br>
                                                {{(data?.client?.length>20)? (data?.client | slice:23:40):""}}
                                                {{(data?.client?.length>40)? (data?.client | slice:40:40)+'...':""}} -->
                                                {{data?.client}}
                                            </a>
                                        </td>
                                        <td>{{data?.mrn}}</td>
                                        <td>{{data?.name}}<br>
                                            <!-- <span class="span-grey" *ngIf="data?.mrn != ''">
                                                MRN#</span> -->
                                        </td>
                                        <!-- <td></td> -->
                                        <td>{{data?.dateOfSurgery | date: 'MM/dd/yyyy'}}<br>
                                            <span class="span-grey">{{data?.physician}}</span>
                                        </td>
                                        <!-- <td>{{data?.primartCPT}}</td> -->
                                        <td data-toggle="tooltip" data-placement="top"
                                            title="{{(data?.insuranceProvider)}}">
                                            {{(data?.insuranceProvider?.length>15)? (data?.insuranceProvider |
                                            slice:0:15)+'..':(data?.insuranceProvider)}}<br>
                                            <span class="span-grey" *ngIf="data?.primartCPT != ''">
                                                CPT#{{data?.primartCPT}}</span>
                                        </td>
                                        <td><i title="In Coding" class="fa fa-code icon-color-blue mr-2"
                                                *ngIf="data?.inCoding > 0 || data?.returnedFor == 'In Coding'"></i>
                                            <!-- <i title="Returned Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                                *ngIf="data?.codingReturned > 0"></i> -->
                                            <i title="Returned" class="fa fa-caret-square-left icon-color-blue mr-2"
                                                *ngIf="data?.returned > 0"></i>
                                            <i title="Op Note" class="fa fa-envelope-open-text icon-color-blue mr-2"
                                                *ngIf="data?.opReport > 0 || data?.returnedFor == 'Op Note'"></i>
                                            <i title="Pathology" class="fa fa-clipboard-check icon-color-blue mr-2"
                                                *ngIf="data?.pathology > 0 || data?.returnedFor == 'Pathology'"></i>
                                            <i title="Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                                *ngIf="data?.coding > 0 || data?.returnedFor == 'Coding Query'"></i>
                                            <i title="Pricing" class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                                *ngIf="data?.pricing > 0 || data?.returnedFor == 'Pricing'"></i>
                                            <i title="High$$$" class="fa fa-dollar-sign icon-color-blue mr-2"
                                                *ngIf="data?.high$$$ > 0 || data?.returnedFor == 'NSN High$$$' || data?.returnedFor == 'Client High$$$'"></i>
                                            <i title="Block" class="fa fa-stop-circle icon-color-blue mr-2"
                                                *ngIf="data?.block > 0 || data?.returnedFor == 'Block Report'"></i>
                                            <i title="Variance" class="fa fa-exclamation-triangle icon-color-blue mr-2"
                                                *ngIf="data?.variance > 0 || data?.returnedFor == 'Variance'"></i>
                                            <i title="Other Misc" class="fa fa-info-circle icon-color-blue mr-2"
                                                *ngIf="data?.otherMisc > 0 || data?.returnedFor == 'Other/Misc'"></i>
                                            <i title="{{implantLog.value}}"
                                                class="fas fa-clipboard-list icon-color-blue mr-2"
                                                *ngIf="data?.implantLog > 0 || data?.returnedFor == implantLog.value"></i>
                                            <i title="{{invoice.value}}"
                                                class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                                *ngIf="data?.invoice > 0 || data?.returnedFor == invoice.value"></i>
                                        </td>
                                        <td data-toggle="tooltip" data-placement="top" title="Import Date - {{(data?.importDate)}}">{{data?.timeCol}} <div *ngIf="data?.timeCol != null"> <span
                                                    class="span-grey">Days</span></div>
                                        </td>
                                        <td data-toggle="tooltip" style="word-wrap: break-word;width: 200px;" data-placement="top" title="{{(data?.lastNote)}}">
                                            <!-- {{(data?.lastNote?.length>17)? (data?.lastNote |
                                            slice:0:17):(data?.lastNote)}}<br> -->
                                            <!-- {{(data?.lastNote?.length>17)? (data?.lastNote | slice:17:34):""}} -->
                                            {{(data?.lastNote?.length>0)? (data?.lastNote | slice:0:50)+'...':""}}
                                            
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)" (click)="showQAATFormModal(data)"
                                                class="icon-color-blue openMySidenav"><i title="Attachments"
                                                    class="fa fa-paperclip quickicn "></i></a>
                                            <a href="javascript:void(0)" (click)="showToolsFormModal(data)"
                                            class="icon-color-yellow openMySidenav"> <i title="Tools"
                                            class="fas fa-tools quickicn icon-color-pink"></i></a>
                                            <a href="javascript:void(0)"
                                                class="icon-color-darkgreen openMySidenav"><i title="Op Note"
                                                    class="fa fa-sticky-note quickicn"
                                                    (click)="getFile(data.patientOpNoteFileId,data.patientOpNoteFileName)"
                                                    *ngIf="data.patientOpNoteFileName != null"
                                                    ></i></a>
                                            <a href="javascript:void(0)"
                                                class="icon-color-yellow openMySidenav"
                                                (click)="getFile(data.patientCodeFileId,data.patientCodeFileName)"
                                                *ngIf="data.patientCodeFileName != null"
                                                ><i title="Coding"
                                                    class="fa fa-file-code quickicn"></i></a>
                                            
                                            <!-- <i class="fa fa-file-invoice quickicn icon-color-darkgreen"></i>
                                            <i class="fa fa-briefcase quickicn icon-color-yellow"></i>
                                            <i class="fa fa-laptop-code quickicn icon-color-pink"></i> -->
                                        </td>
                                        <td>
                                            <a href="javascript:void(0)"
                                                *ngIf="(commadnSelection$ | async) === caseBilledCommandType"
                                                id="appCaseRequestIdLink" class="icon-color-orange openMySidenav"><i
                                                    class="fas fa-undo" title="Return Mail" style="padding-right: 6px;"
                                                    (click)="showReturnMail(data)"></i>
                                            </a>
                                            <a href="javascript:void(0)"
                                            *ngIf="data.codingInquiryStatus == null || data.codingInquiryStatus == 'Reviewed' "
                                                            class="openMySidenav">
                                                            <i title="Coding Review" class="fas fa-check-double" style="color: red; padding-right: 6px;"
                                                            (click)="showCodingModal(data)"
                                                            ></i>
                                            </a>
                                            <a [routerLink]="['/d/patientDtls', (data?.facilityId || 0), (data?.mrn || 0)]"
                                                class="icon-color-darkgreen openMySidenav mr-2"><i
                                                    title="Patient View" class="fa fa-eye"></i></a>
                                            <a href="javascript:void(0)" class="icon-color-orange openMySidenav"><i
                                                    title="Action" class="fas fa-comment-alt" (click)="showFormModal(data)"></i></a>
                                            <!--  -->
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <pagination-controls class="float-right"
                                (pageChange)="pageChange($event, commandtype); p[commandtype] = $event">
                            </pagination-controls>
                        </div>
                    </ng-template>
                    <ng-template [ngIf]="IndVsbl && !tblVsbl && !showAccordianComponent(selectedCommand)">There were no
                        records matching your selection. Please try again.
                    </ng-template>
                    <!-- below is default accordian-->
                    <ng-template [ngIf]="!IndVsbl && !showAccordianComponent(selectedCommand)">
                        <div class="patient-detail-accordion container-fluid cont-mrg patient-detail-accordion"
                            *ngFor="let d of CategoryArry; let k = index">
                            <div class="container1">
                                <div class="row advance-surgery-center">
                                    <div class="col-md-3 surgery-row">
                                        <p class="advance-section-text">{{d.name}} 
                                        </p>
                                    </div>
                                    <div class="col-md-2">
                                        <p class="advance-section-text text-right">{{allpatientttlrws[d.value]}}
                                        </p>
                                    </div>
                                    <div class="col-md-7" style="padding: 0px;">
                                        <p class="advance-icon-text plus-sign-bx expand-data"
                                            (click)="toggle($event,k)">
                                            <i *ngIf="selectedIndex != k && d.name === billerOnHold.value"
                                                class="fa fa-plus" (click)="loadChildAccordian(d)"></i>
                                            <i *ngIf="selectedIndex == k" class="fa fa-minus"
                                                (click)="removeChildAccordian()"></i>
                                            <i *ngIf="selectedIndex != k && d.name !== billerOnHold.value"
                                                class="fa fa-plus" (click)="removeChildAccordian()"></i>
                                        </p>
                                    </div>
                                </div>
                                <!-- Sugery Center Section End-->
                                <div class="row widget-dashboard" id="show-data"
                                    *ngIf="selectedIndex == k && d.name !== billerOnHold.value">
                                    <div *ngIf="tblVsbl; else elseBlock" style="width: 100%;">
                                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Facility <i (click)="sortList('client', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('client', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>MRN <i (click)="sortList('mrn', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('mrn', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>Patient <i (click)="sortList('name', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('name', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>

                                                    <!-- <th>Provider</th> -->
                                                    <th>DoS <i (click)="sortList('dateOfSurgery', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('dateOfSurgery', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <!-- <th>Primary CPT</th> -->
                                                    <th>Insurance <i
                                                            (click)="sortList('insuranceProvider', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('insuranceProvider', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>Active Queue</th>
                                                    <th>Age <i (click)="sortList('timeCol', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('timeCol', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>Comment</th>
                                                    <th>Quick Access</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>#</th>
                                                    <th></th>
                                                    <th>Facility<i (click)="sortList('client', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('client', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>MRN <i (click)="sortList('mrn', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('mrn', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>Patient<i (click)="sortList('name', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('name', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>

                                                    <!-- <th>Provider</th> -->
                                                    <th>DoS<i (click)="sortList('dateOfSurgery', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('dateOfSurgery', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <!-- <th>Primary CPT</th> -->
                                                    <th>Insurance<i
                                                            (click)="sortList('insuranceProvider', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('insuranceProvider', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>Active Queue</th>
                                                    <th>Age<i (click)="sortList('timeCol', 'asc', d.value)"
                                                            class="fas fa-arrow-up mr-1"></i>
                                                        <i (click)="sortList('timeCol', 'desc', d.value)"
                                                            class="fas fa-arrow-down"></i>
                                                    </th>
                                                    <th>Comment</th>
                                                    <th>Quick Access</th>
                                                    <th>Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr
                                                    *ngFor='let data of allpatientQdata[d.value] | paginate: { itemsPerPage: pageValue, currentPage: p[d.value], totalItems: allpatientttlrws[d.value] }; let i = index'>

                                                    <td>

                                                        {{(i+1) + ((pagenumber-1)*pageValue)}}
                                                    </td>
                                                    <td><input type="checkbox"
                                                            (click)="selectRowOfAccordian(data, d.value)"
                                                            [checked]="isRowActive(data)"></td>
                                                    <td data-toggle="tooltip" data-placement="top"
                                                        title="{{(data?.client)}}">
                                                        <a href="javascript:void(0)" (click)='showDetailModal(data)'>
                                                            {{(data?.client?.length>20)? (data?.client |
                                                            slice:0:23):(data?.client)}}<br>
                                                            {{(data?.client?.length>20)? (data?.client |
                                                            slice:23:40):""}}
                                                            {{(data?.client?.length>40)? (data?.client |
                                                            slice:40:40)+'...':""}}
                                                        </a>
                                                    </td>
                                                    <td>{{data?.mrn}}</td>
                                                    <td>{{data?.name}}<br>
                                                        <!-- <span class="span-grey" *ngIf="data?.mrn != ''">
                                                            MRN#</span> -->
                                                    </td>

                                                    <!-- <td></td> -->
                                                    <td>{{data?.dateOfSurgery | date: 'MM/dd/yyyy'}}<br>
                                                        <span class="span-grey">{{data?.physician}}</span>
                                                    </td>
                                                    <!-- <td>{{data?.primartCPT}}</td> -->
                                                    <td data-toggle="tooltip" data-placement="top"
                                                        title="{{(data?.insuranceProvider)}}">
                                                        {{(data?.insuranceProvider?.length>15)? (data?.insuranceProvider
                                                        | slice:0:15)+'..':(data?.insuranceProvider)}}<br>
                                                        <span class="span-grey" *ngIf="data?.primartCPT != ''">
                                                            CPT#{{data?.primartCPT}}</span>
                                                    </td>
                                                    <td><i title="In Coding" class="fa fa-code icon-color-blue mr-2"
                                                            *ngIf="data?.inCoding > 0 || data?.returnedFor == 'In Coding'"></i>
                                                        <i title="Returned Coding"
                                                            class="fa fa-undo-alt icon-color-blue mr-2"
                                                            *ngIf="data?.codingReturned > 0"></i>
                                                        <i title="Returned"
                                                            class="fa fa-caret-square-left icon-color-blue mr-2"
                                                            *ngIf="data?.returned > 0"></i>
                                                        <i title="Op Note"
                                                            class="fa fa-envelope-open-text icon-color-blue mr-2"
                                                            *ngIf="data?.opReport > 0 || data?.returnedFor == 'Op Note'"></i>
                                                        <i title="Pathology"
                                                            class="fa fa-clipboard-check icon-color-blue mr-2"
                                                            *ngIf="data?.pathology > 0 || data?.returnedFor == 'Pathology'"></i>
                                                        <i title="Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                                            *ngIf="data?.coding > 0 || data?.returnedFor == 'Coding Query'"></i>
                                                        <i title="Pricing"
                                                            class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                                            *ngIf="data?.pricing > 0 || data?.returnedFor == 'Pricing'"></i>
                                                        <i title="High$$$"
                                                            class="fa fa-dollar-sign icon-color-blue mr-2"
                                                            *ngIf="data?.high$$$ > 0 || data?.returnedFor == 'NSN High$$$' || data?.returnedFor == 'Client High$$$'"></i>
                                                        <i title="Block" class="fa fa-stop-circle icon-color-blue mr-2"
                                                            *ngIf="data?.block > 0 || data?.returnedFor == 'Block Report'"></i>
                                                        <i title="Variance"
                                                            class="fa fa-exclamation-triangle icon-color-blue mr-2"
                                                            *ngIf="data?.variance > 0 || data?.returnedFor == 'Variance'"></i>
                                                        <i title="Other Misc"
                                                            class="fa fa-info-circle icon-color-blue mr-2"
                                                            *ngIf="data?.otherMisc > 0 || data?.returnedFor == 'Other/Misc'"></i>
                                                        <i title="{{implantLog.value}}"
                                                            class="fas fa-clipboard-list icon-color-blue mr-2"
                                                            *ngIf="data?.implantLog > 0 || data?.returnedFor == implantLog.value"></i>
                                                        <i title="{{invoice.value}}"
                                                            class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                                            *ngIf="data?.invoice > 0 || data?.returnedFor == invoice.value"></i>
                                                    </td>
                                                    <td
                                                    data-toggle="tooltip" 
                                                    data-placement="top"
                                                    title="Import Date : {{(data?.importDate)}}"
                                                    >{{data?.timeCol}} <div *ngIf="data?.timeCol != null"> <span
                                                                class="span-grey">Days</span></div>
                                                    </td>
                                                    <td data-toggle="tooltip" data-placement="top"
                                                        title="{{(data?.lastNote)}}">
                                                        {{(data?.lastNote?.length>17)? (data?.lastNote |
                                                        slice:0:17):(data?.lastNote)}}<br>
                                                        {{(data?.lastNote?.length>17)? (data?.lastNote |
                                                        slice:17:34):""}}
                                                        {{(data?.lastNote?.length>34)? (data?.lastNote |
                                                        slice:34:34)+'...':""}}
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)" (click)="showQAATFormModal(data)"
                                                            class="icon-color-blue openMySidenav"><i title="Attachments"
                                                                class="fa fa-paperclip quickicn "></i></a>
                                                        <a href="javascript:void(0)" (click)="showQAOPFormModal(data)"
                                                            class="icon-color-darkgreen openMySidenav"><i
                                                                title="Op Note"
                                                                class="fa fa-sticky-note quickicn"></i></a>
                                                        <a href="javascript:void(0)" (click)="showQAICFormModal(data)"
                                                            class="icon-color-yellow openMySidenav"><i title="Coding"
                                                                class="fa fa-file-code quickicn"></i></a>
                                                        <a href="javascript:void(0)" (click)="showToolsFormModal(data)"
                                                            class="icon-color-yellow openMySidenav"> <i title="Tools"
                                                                class="fas fa-tools quickicn icon-color-pink"></i></a>
                                                        <!-- <i class="fa fa-file-invoice quickicn icon-color-darkgreen"></i>
                                                        <i class="fa fa-briefcase quickicn icon-color-yellow"></i>
                                                        <i class="fa fa-laptop-code quickicn icon-color-pink"></i> -->
                                                    </td>
                                                    <td>
                                                        <a href="javascript:void(0)"
                                                            *ngIf="(commadnSelection$ | async) === caseBilledCommandType"
                                                            class="icon-color-orange openMySidenav"><i
                                                                class="fas fa-undo" title="Return Mail" style="padding-right: 6px;"
                                                                (click)="showReturnMail(data)"></i>
                                                        </a>
                                                        <a href="javascript:void(0)"
                                                            class="icon-color-orange openMySidenav">
                                                            <i title="Coding Review" class="fas fa-check-double" style="color: red;"
                                                            (click)="showCodingModal(data)"
                                                            ></i>
                                                        </a>
                                                        <a [routerLink]="['/d/patientDtls', (data?.facilityId || 0), (data?.mrn || 0)]"
                                                            class="icon-color-darkgreen openMySidenav mr-2"><i
                                                                title="Patient View" class="fa fa-eye"></i></a>
                                                        <a href="javascript:void(0)"
                                                            class="icon-color-orange openMySidenav"><i
                                                                title="Action" class="fas fa-comment-alt"
                                                                (click)="showFormModal(data)"></i></a>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <pagination-controls class="float-right"
                                            (pageChange)="pageChange($event, d.value); p[commandtype] = $event">
                                        </pagination-controls>
                                    </div>
                                    <ng-template #elseBlock>There were no records matching your selection. Please try
                                        again.
                                    </ng-template>
                                </div>
                            </div>
                            <!-- container end-->
                        </div>
                    </ng-template>
                    <ng-template #nsnAccordianHost>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>
<app-facility-details [facility_id]="facility_id" *ngIf=shouldShowDetailModal (closeEmitter)="onCloseFormModal()">
</app-facility-details>
<!-- /.container-fluid -->


<app-unset-billed-action [formData]="formData" *ngIf="shouldShowReturnMailModal" (closeEmitter)="onCloseFormModal()">
</app-unset-billed-action>
<!-- Sidenav-->


<!-- Sidenav-->
<!-- <app-patient-action [formData]="formData" [commandType]="commandtype" 
*ngIf= "shouldShowFormModal && (commandtype !== 'casebilled' && commandtype !== 'Unassigned' && commandtype !== 'Pricing')" 
(closeEmitter)="onCloseFormModal()">
</app-patient-action> -->
<!-- Sidenav-->
<!-- Sidenav-->
<!-- <app-case-request [commandType]="commandtype" 
*ngIf="shouldShowFormModal && (commandtype === 'casebilled' || commandtype === 'Unassigned' || commandtype === 'Pricing')" 
(closeEmitter)="onCloseFormModal()">
</app-case-request> -->
<app-case-request [commandType]="commandtype" [formData]="formData" *ngIf="shouldShowFormModal"
    (closeEmitter)="onCloseFormModal()"></app-case-request>
<!-- Sidenav-->


<!-- Sidenav-->
<app-bulk-action *ngIf=shouldShowBulkActionModal (closeEmitter)="onCloseFormModal()">
</app-bulk-action>

<app-create-batch-review-cases *ngIf=shouldShowReviewCasesModal (closeEmitter)="onCloseFormModal()"></app-create-batch-review-cases>
<app-coding-action *ngIf="shouldShowCodingModal" (closeEmitter)="onCloseFormModal()"></app-coding-action>

<!-- Sidenav-->
<!-- Sidenav-->
<app-opnote-qa [formData]="formData" *ngIf=shouldShowQAOPFormModal (closeEmitter)="onCloseFormModal()">
</app-opnote-qa>
<!-- Sidenav-->

<!-- Sidenav-->
<app-incoding-qa [formData]="formData" *ngIf=shouldShowQAICFormModal (closeEmitter)="onCloseFormModal()">
</app-incoding-qa>
<!-- Sidenav-->

<!-- Sidenav-->
<div>
    
</div>
<app-attachments-qa [formData]="formData" *ngIf=shouldShowQAATFormModal (closeEmitter)="onCloseFormModal()">
</app-attachments-qa>
<!-- Sidenav-->

<!-- Sidenav-->
<!-- <div *ngIf=shouldShowToolsModal  (click) = "outsideClick()" >
    </div> -->
    <app-tools-qa [formData]="formData" *ngIf=shouldShowToolsModal (closeEmitter)="onCloseFormModal()">
    </app-tools-qa>



<!-- Sidenav-->