import { Injectable } from '@angular/core';
import { ID, transaction } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { createPayer, Payer } from './payer.model';
import { PayersQuery } from './payers.query';
import { PayersStore } from './payers.store';

@Injectable({ providedIn: 'root' })
export class PayersService {

  constructor(private store: PayersStore,
    private apiService: ApiService,
    private toastr: ToastrService,
    private query: PayersQuery) {
  }

  get() {
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };
    return this.apiService.post("FacilityPayer/BindGrid", data).pipe(tap(e => {
      const entities: Payer[] = e.data;
      this.store.set(entities);
    }));
  }

  add(payer: Payer) {
    this.store.add(payer);
  }

  update(id, payer: Partial<Payer>) {
    this.store.update(id, payer);
  }
  active(ids: ID[]) {
    this.store.addActive(ids);
  }
  inActiveAll() {
    const ids = this.query.getActiveId();
    this.inActive(ids)
  }

  inActive(ids: ID[]) {
    const activeIds = this.query.getActiveId();
    const idsTobeMadeInactive = ids.filter(id => activeIds.includes(id));
    this.store.toggleActive(idsTobeMadeInactive);
  }

  getPayerData(id: string | number): Observable<Payer[]> {
    return this.apiService.post("FacilityPayer/GetFinancialClass", { facilityId: id }).pipe(map(res => {
      return res.data;
    }));
  }

  set(res) {
    this.store.set(res);
  }

  reset(){
    this.store.set([]);
  }
  @transaction()
  setPayerFacility(ids: ID[]) {
    let data = { "Facility": ids };
    this.apiService.post("FacilityPayer/GetPayerFacility", data).subscribe(res => {
      if (res.status === 'Success') {
        //  console.log('setPayerFacility', res.data);
        this.store.add(res.data);
      } else {
        this.toastr.error('Error', res.message);
      }
    })
  }

  setPayerFacilitySequentialCall(ids: ID[]) {
    let batch: ID[] = [];
    ids.forEach(id => {
      batch = [...batch, id];
      if(batch.length === 10){
        this.setPayerFacility(batch);
        batch = [];
      }
    });

    if(batch.length !==0){
      this.setPayerFacility(batch);
      batch = [];
    }
  }

  setPayerFinancialClassAndFacility(facilityId: ID, financialClass: string) {

    const rquestData = {
      pageNum: 1,
      pageSize: 1000,
      facilityID: facilityId,
      financialClass: financialClass
    };
    console.log('rquestData',rquestData);
    this.apiService.post("FacilityPayer/GetPayerBYFinancialClass", rquestData)
      .subscribe(res => {
        if (res.status === "Success") {
          console.log('payer by financial class', res);
          this.set(res.data);
        } else if (res.status === "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
  }


  setActive(ids: ID[]) {
    this.store.setActive(ids);
  }

}

