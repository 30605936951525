import { Injectable } from '@angular/core';
import { ActiveState } from '@datorama/akita';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ImplantLogPayer } from './implant-log-payer.model';

export interface ImplantLogPayerState extends EntityState<ImplantLogPayer, number | string>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'implant-log-payer' })
export class ImplantLogPayerStore extends EntityStore<ImplantLogPayerState> {

  constructor() {
    super();
  }

}
