import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { FacilityReport } from './facility-report.model';

export interface FacilityReportState extends EntityState<FacilityReport>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'facility-report' })
export class FacilityReportStore extends EntityStore<FacilityReportState> {

  constructor() {
    super();
  }

}
