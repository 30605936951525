import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl, FormBuilder } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { formatDate } from '@angular/common';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-fee-schedule-edit',
  templateUrl: './fee-schedule-edit.component.html',
  styleUrls: ['./fee-schedule-edit.component.css']
})
export class FeeScheduleEditComponent implements OnInit {
  feeScheduleForm: FormGroup;
  _formData: any;
  facilityName: string;
  // @ViewChild(NgbdDatepicker) d: NgbdDatepicker;
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  myDateValue: any;
  facilityList: [];
  submitted = false;

  @Input() set formData(value: any) {
    this._formData = value;
    this.initForm();
    this.facilityName = this._formData.facilityName;
    if (this._formData.validFrom != null || this._formData.validFrom != undefined) {
      let vlP = this._formData.validFrom;
      let vp = vlP.split('-');
      const dt = { year: parseInt(vp[0], 10), month: parseInt(vp[1], 10), day: parseInt(vp[2].substring(0, 2), 10) };
      this.myDateValue = dt;
    }
  }

  constructor(private fb: FormBuilder,
    private apiService: ApiService) { }

  ngOnInit(): void {
    this.getFacilityMasterList();
  }

  get f() { return this.feeScheduleForm.controls; }

  getFacilityMasterList() {
    let data = {};

    this.apiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.facilityList = res.data;
      }
      else if (res.status == "Error") {
        console.log(res.message);
      }
    }, (error) => {
      console.log(error);
    });
  }

  onDateChange(newDate) {
    let dt = newDate.month + "/" + newDate.day + "/" + newDate.year;
    this.feeScheduleForm.controls["ValidFrom"].setValue(dt);
  }

  initForm() {
    // let dt = formatDate(this._formData.validFrom, "MM/DD/YYYY").toString()
    this.feeScheduleForm = this.fb.group({
      FeeScheduleID: [{ value: this._formData.feeScheduleID, disabled: true }],
      FacilityId: [(this._formData.facilityId || ""), [Validators.required]],
      CPT: [this._formData.cpt, [Validators.required]],
      CPTDesc: [this._formData.cptDesc],
      Charge: [this._formData.rate, Validators.required],
      ValidFrom: [this.myDateValue, Validators.required],
      //status: [this._formData.status, Validators.required]
    })
  }

  onSubmit() {
    this.updateRecord();
  }

  updateRecord() {
    
    this.submitted = true;
    let vDat = this.feeScheduleForm.controls["ValidFrom"].value;

    if (vDat == null || vDat == '') {
      this.onDateChange(this.myDateValue);
    }
    
    if (this.feeScheduleForm.invalid) {
      return;
    }

    const data = this.feeScheduleForm.getRawValue();
   

    this.apiService.post("FeeSchedule/UpdateFeeSchedule", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.feeScheduleForm.reset();
      }
      this.editEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }

  onClickClose() {
    this.closeEmitter.emit();
  }



}
