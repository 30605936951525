import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ImplantLogPayerStore, ImplantLogPayerState } from './implant-log-payer.store';

@Injectable({ providedIn: 'root' })
export class ImplantLogPayerQuery extends QueryEntity<ImplantLogPayerState> {

  constructor(protected store: ImplantLogPayerStore) {
    super(store);
  }

}
