<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-560 active">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h6 class="m-0 font-weight-bold text-primary"> Payer Management</h6>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <!-- <form class="pb-3 form-fields" [formGroup]="FacilityGroupMasterForm" (ngSubmit)="onSubmit()"> -->
                <form class="pb-3 form-fields">
                <div class="row hr">
                    <div class="form-group remove-botttom-space col-12" >
                        <!-- <label style="font-size: 15px;" >Facility Name</label>&nbsp;&nbsp; -->
                        <p  style="font-size: 20px;">{{facilityName}}</p>
                        <!-- <input type="text" class="form-control" formControlName="Name" placeholder="Name" required> -->
    
                    </div>
                    <div class="form-group remove-botttom-space row col-12 d-flex" >
                        <!-- <label style="font-size: 15px;" >Facility Name</label>&nbsp;&nbsp; -->
                        <label class="col-5" style="font-size: 15px;">Choose financial class</label>
                        <ng-container *ngIf="payerDataHavingFinancialClass$ | async as payerDataHavingFinancialClass">
                            <select [(ngModel)]="selectedPayerWithFinancialClass" name="financialClass" class="col-5 form-control">
                                <option hidden value="" disabled selected>Select one category </option>
                                <option *ngFor="let payerWithFincancialClass of payerDataHavingFinancialClass.data" [ngValue]="payerWithFincancialClass">
                                    {{payerWithFincancialClass.payer_FinancialClass}}
                                </option>    
                            </select>
                        </ng-container>
                        <button class="ml-3 btn btn-primary" (click)="showTable()" [disabled]="!selectedPayerWithFinancialClass">Go</button> 
    
                    </div>
                </div>
               
                <div class="row" *ngIf="showTableData">
                    <div class="col-12">
                        <div id="Facilities" class="tab-pane fade in active show">
                           
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <div class="myTables_length"><label>Show <select name="myTable_length"
                                                aria-controls="dataTable" (change)="onEntryChange($event)"
                                                class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <div class="myTables_filter">
                                        <label>Search:<input type="search" [(ngModel)]="searchValue" name="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="search()"
                                        placeholder="Enter Keyword">
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="row"> -->
                                <table class="table" id="dataTable">
                                    <thead class="d-flex">
                                        <th style="color:black" class="col-6">Payer Name</th>
                                        <th style="color:black" class="col-6">Claim Type</th>
                                        <!-- <th></th> -->
                                    </thead>
                                    <tbody>
                                        <tr class="d-flex" *ngFor='let payer of payers | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows };'>
                                            <td style="color:black" class="col-6"> {{payer.payer_Name}}</td>
                                            <td class="col-6">
                                                <input  type="checkbox" [checked]="payer.is_electronic === 1" (change)="updateElectronicType($event,payer)"><label class="mb-0">Electronic</label>
                                                <br>
                                                <input  type="checkbox" [checked]="payer.is_paper === 1" (change)="updatePaperType($event,payer)"><label class="mb-0">Paper</label>
                                            </td>
                                            <!-- <td><a class="icon-color-red" (click) = "delCustomFieldMaster(ff.id)">
                                                <i class="fas fa-trash" title="Delete"></i></a></td> -->
                                        </tr>
                                    </tbody>
                                </table>
                                <div *ngIf="hasPayersData()">
                                    <pagination-controls class="float-right" (pageChange)="pageChange($event); p=$event">
                                    </pagination-controls>
                                </div>
                            <!-- </div> -->
                        </div>
                    </div>
                </div>
             
               
            </form>
        </div>
        </div>
        <!-- </div> -->
        <!-- Sidenav-->