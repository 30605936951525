import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface SelectionState {
    value: string;
}

export function createInitialState(): SelectionState {
    return {
        value: ""
    }
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'selection' })
export class SelectionStore extends Store<SelectionState> {
    constructor(){
        super(createInitialState());
    }
}