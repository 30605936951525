export interface BillReason {
  id: number | string;
  reason: string;
  isActive: number;
  updatedDate: Date;
  totalcount: number;
}

export function createBillReason(params: Partial<BillReason>) {
  return {
    id: params.id,
    reason: params.reason,
    isActive: params.isActive,
    updatedDate: params.updatedDate,
    totalcount: params.totalcount
  } as BillReason;
}
