export interface CaseRequestInfo {
  id: number | string;
  dataPatient: PatientInfo,
  dataDosDetails: SurgeryInfo,
  dataCPTDetails: CptDetails[],
  dataQueueInfo: QueueInfo,
  dataFileHistoryInfo: FileHistoryInfo[],
  dataPricingInfo: PricingInfo[],
  userRole: string;
}

export function createCaseRequestInfo(params: Partial<CaseRequestInfo>) {
  return {
    ...params
  } as CaseRequestInfo;
}

export interface PatientInfo {
  facilityId: number | string;
  facilityName: string;
  patientName: string;
  mrn: string;
  gender: string;
  address1: string;
  address2: string;
  state: string;
  city: string;
  zip: string;
  dob: string;
  phonenumber: string;
  ssnNo: string;
  region: string;
}
export function createPatientInfo(params: Partial<PatientInfo>) {
  return {
    ...params
  } as PatientInfo;
}

export interface SurgeryInfo {
  insuranceId: number | string;
  insuranceProvider: string;
  groupNumber: string;
  physician: string;
  billed: boolean;
  dateOfSurgery: Date;
}
export function createSurgeryInfo(params: Partial<SurgeryInfo>) {
  return {
    ...params
  } as SurgeryInfo;
}

export interface CptDetails {
  cptCode: string;
  surgeryDescription: string;
}

export function createCptDetails(params: Partial<CptDetails>) {
  return {
    ...params
  } as CptDetails;
}

export interface QueueInfo {
  billed: boolean;
  operatingReport: boolean;
  pathology: boolean;
  pricing: boolean;
  highApproval: boolean;
  blockReport: boolean;
  codingQuery: boolean;
  otherMisc: boolean;
  inCoding: boolean;
  variance: boolean;
  authReview: boolean;
  returnedCodingRFI: boolean;
  overdue: boolean;
  codingExpert: boolean;
  billerNote: string;
  clientNote: string;
  status: number;
  highApprovalInternal: boolean;
  implantLog: boolean;
  invoice: boolean;
}

export function createQueueInfo(params: Partial<QueueInfo>) {
  return {
    ...params
  } as QueueInfo;
}

export interface FileHistoryInfo {
  billerTime: string;
  billerName: string;
  billerNote: string;
  auditEventType: string;
  auditEventDescription: string;
  clientName: string;
  clientTime: string;
  clientNote: string;
  coderName: string;
  coderTime: string;
  coderNote: string;
  contentFileName: string;
  id: number;
}

export function createFileHistoryInfo(params: Partial<FileHistoryInfo>) {
  return {
    ...params
  } as FileHistoryInfo;
}

export interface PricingInfo {
  implantlog: number;
  invoice: number;
}

export function createPricingInfo(params: Partial<PricingInfo>) {
  return {
    ...params
  } as PricingInfo;
}