
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationStart } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { filter } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { AccessService } from 'src/app/shared/services/access.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder,FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  reactiveForm: FormGroup;

  isSubmitted = false;
  username: string;
  heading: string;
  p: number = 1;
  pagenumber: any = 1;
  searchValue: string = "";
  PatientsArry: any[];
  pageValue: any = 10;

  
  
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  shouldShowDetailModal:boolean =false;
  shouldShowPVFormModal: boolean = false;
  formData: any = null;
  
  
  totalRows: any;
  privilege: any;
  navbar: any;
  
  snoList: any = 1;
  
  facility_id:any;
  shouldShowPatientFormModal:boolean =false;
  privileges:any;



  constructor(private titleService: Title,private router: Router,
              private toastr: ToastrService,
              public fb: FormBuilder,
              private builder: FormBuilder,
              private patientService: PatientService,
              private commonUtil: CommonService,
              private spinner:NgxSpinnerService,
              public accessService:AccessService,
              private activatedRoute:ActivatedRoute,
              private getApiService: ApiService,
              ) {

    router.events.pipe(
      filter(event => event instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        this.setHeader();
      });
  }

  ngOnInit(): void {
    this.privilege = JSON.parse(localStorage.getItem('privilegearray'));
    console.log(this.privilege, 'privilege in navbar');
    this.accessService.access_array = this.privilege;

    this.navbar = this.accessService.navbar_access;
    
    //this.username = localStorage.getItem("loggedUser");
    this.username = localStorage.getItem("loggedUserName");
    this.setHeader();
    this.reactiveForm = this.builder.group({
      data: [null, Validators.required]
    });
}






  navigateToPatients(searchValue:any){
    if(searchValue){
      this.router.navigate(['patient/manage-patient'],{ queryParams: { search: searchValue.trim() } })
      this.spinner.show();
    }

   
    
 }
 
 onEntryChange(event) {
  let value = event.target.value;
  this.pageValue = value;
  this.pagenumber = 1;
  this.PatientsArry = [];
  this.getPatientsList();
}

 pageChange(event) {
  this.pagenumber = event;
  this.getPatientsList();
}
 
getPatientsList() {
    
  if (this.searchValue != '' && this.pagenumber == 0) {
    this.pagenumber = 1;
    this.p = 1;
  }
  
  let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "PatientName": this.searchValue.trim() };
  this.spinner.show();
  this.getApiService.post("CreatePatient/BindGridData", data).subscribe(res => {
    
    
    if (res.status == "Success") {
      this.spinner.hide();
      this.PatientsArry = res.data;
      console.log(this.PatientsArry,"patients array")
      this.totalRows = res.data[0]?.totalcount;
    }
    else if (res.status == "Error") {
      this.spinner.hide();
      this.toastr.error(res.message, 'Error!');
    }
  }, (error) => {
    this.spinner.hide();
    this.toastr.error(error, 'Error!');
  });
}

  

  setHeader() {
    setTimeout(() => {
      let titleVal = this.titleService.getTitle();
      this.heading = titleVal.split('-')[0];

    }, 100);
  }

  search() {
    this.pagenumber = 0;
    this.p = 0;
    this.getPatientsList();
  }
  

}