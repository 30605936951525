import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { saveAs } from 'file-saver';
import {
  createFacility,
  Facility,
} from 'src/app/shared/state/facilities/facility.model';
import { DashDetailHelper } from '../../billing-dashboard/dash-detail/helpers/dash-detail.helper';
import { Observable, Subscription } from 'rxjs';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { map } from 'rxjs/operators';
import { createPatient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { BulkActionConfig } from '../../billing-dashboard/bulk-action/bulk-action-config/bulk-action-config';
import {
  blockReport,
  codingQuery,
  highApproval,
  highApprovalInternal,
  implantLog,
  invoice,
  NameConstant,
  operatingReport,
  otherMisc,
  pathology,
  pendingclient,
  pricing,
  variance,
} from 'src/app/shared/models/name-constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { SessionQuery } from 'src/app/modules/login/session/session.query';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css'],
})
export class PatientListComponent implements OnInit, OnDestroy {
  @Output() closeEmitter = new EventEmitter();
  selectedIndex = -1;
  shouldShowFormModal: boolean = false;
  shouldShowQAOPFormModal: boolean = false;
  shouldShowQAICFormModal: boolean = false;
  shouldShowQAATFormModal: boolean = false;
  shouldShowBulkActionModal: boolean = false;
  formData: any = null;
  p: any = {};
  pageValue: any = 10;
  totalRows: any;
  pagenumber: number = 1;
  patientList: any[];
  allpatientQdata: {};
  allpatientttlrws: any = [];
  CategoryArry: any[];
  defCategoryArry: any = {};
  facilityID: number;
  commandtype: string;
  catId = 0;
  searchValue: any;
  tblVsbl = true;
  IndVsbl = false;
  monthNumber: any;
  yearNumber: any;
  monthName: any;
  catsrchVal: any;
  FacilityList: any;

  catArry: boolean = false;

  FacilityList$: Observable<Facility[]>;
  dashDetailHelper: DashDetailHelper = new DashDetailHelper();
  commadnSelection$: Observable<string>;
  hasActivePatients$: Observable<boolean>;
  requestToSatisfy: NameConstant;
  bulkActionAllowList = [
    operatingReport,
    pathology,
    codingQuery,
    pricing,
    blockReport,
    otherMisc,
    pendingclient,
    highApproval,
    variance,
    implantLog,
    invoice,
  ];
  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  sub: Subscription;

  constructor(
    private getApiService: ApiService,
    private toastr: ToastrService,
    private sessionQuery: SessionQuery,
    private route: ActivatedRoute,
    private commonUtil: CommonService,
    private facilityService: FacilityService,
    private facilityQuery: FacilityQuery,
    private patientService: PatientService,
    private selectionService: SelectionService,
    private selectionQuery: SelectionQuery,
    private patientQuery: PatientQuery,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.monthNumber = this.route.snapshot.params.month;
    this.yearNumber = this.route.snapshot.params.year;
    this.commandtype = this.route.snapshot.params.type;
    this.facilityID = this.route.snapshot.params.id;
    this.catId = this.route.snapshot.params.cat;
    this.getDataList();
    this.getFacilityInfo();
    this.sub = this.facilityService
      .fetchAndStoreFacilityForPatient()
      .subscribe();
    this.FacilityList$ = this.facilityQuery.selectAll();
    this.commadnSelection$ = this.selectionQuery
      .select()
      .pipe(map((res) => res.value));
    this.commonUtil.setTitle('Request for Info');
    this.hasActivePatients$ = this.patientQuery
      .selectActiveId()
      .pipe(map((res) => res && res.length > 0));
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  getCategoryArry() {
    if (this.catId > 0) {
      let data = {
        facilityId: this.facilityID,
        MonthNumber: this.monthNumber,
        YearNumber: this.yearNumber,
        CategoryId: this.catId,
      };

      this.getApiService.post('StatusChange/BindCategoryItems', data).subscribe(
        (res) => {
          if (res.status == 'Success') {
            this.CategoryArry = res.data;

            if (this.isClientLiteRole()) {
              let temp = [];
              let tempCheckingArray = [
                'Op Note',
                'Path',
                'Coding',
                'Other/Misc',
              ];
              tempCheckingArray.forEach((element) => {
                this.CategoryArry.forEach((e) => {
                  if (e.name == element) {
                    temp.push(e);
                  }
                });
              });
              this.CategoryArry = temp;
            }
            console.log(this.CategoryArry, 'hello');
            this.getDataList();
          } else if (res.status == 'Error') {
            this.toastr.error(res.message, 'Error!');
          }
        },
        (error) => {
          this.toastr.error(error, 'Error!');
        }
      );
    }
  }

  getDataByFacility() {
    this.catArry = false;
    this.pagenumber = 1;
    this.p[this.commandtype] = 1;
    this.getDataList();
  }

  getDataByCat(event) {
    this.commandtype = event.target.value;
    this.pagenumber = 1;
    this.p[this.commandtype] = 1;
    this.getDataList();
  }

  async getDataList() {
    this.patientService.reset();
    let relUrl = '';
    let data = {};

    let cmdType = this.commandtype;
    this.pagenumber = 1;

    if (
      this.commandtype.toLowerCase() == 'codingexpert' ||
      this.commandtype.toLowerCase() == 'baq' ||
      this.commandtype.toLowerCase() == 'authreview' ||
      this.commandtype.toLowerCase() == 'returnedcodingrfi'
    ) {
      cmdType = 'BillerOnHold';
    }

    if (this.catArry == false) {
      this.getCategoryArry();
      this.catArry = true;
      return;
    }
    this.spinner.show();
    this.selectionService.changeSelection(cmdType);
    switch (cmdType) {
      case 'caseperformed': {
        relUrl = 'CasesPerformedPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        var dcp = { value: cmdType, name: 'Cases Performed' };
        this.defCategoryArry['data'] = dcp;
        break;
      }
      case 'casebilled': {
        relUrl = 'CasesBilledPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        this.defCategoryArry['data'] = { value: cmdType, name: 'Cases Billed' };
        break;
      }
      case 'avgDystoBill': {
        relUrl = 'AvgDaysToBillPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        this.defCategoryArry['data'] = {
          value: cmdType,
          name: 'Avg Days To Bill',
        };
        break;
      }
      case 'avgDystoBillIntrnl': {
        relUrl = 'AvgDaysToBillInternalPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        this.defCategoryArry['data'] = {
          value: cmdType,
          name: 'Avg Days To Bill Internal',
        };
        break;
      }
      case 'priority': {
        relUrl = 'PriorityItemsPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        var dp = { value: cmdType, name: 'Priority Items' };
        this.defCategoryArry['data'] = {
          value: 'priority',
          name: 'Priority Items',
        };
        break;
      }
      case 'pendingclient': {
        relUrl = 'PendingClientPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        this.defCategoryArry['data'] = {
          value: 'pendingclient',
          name: 'Request for Info',
        };
        break;
      }
      case 'dailyflow': {
        relUrl = 'DailyFlowPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        this.defCategoryArry['data'] = {
          value: 'dailyflow',
          name: 'Daily Flow',
        };
        break;
      }
      case 'criticalitem': {
        relUrl = 'CriticalitemsPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
        };
        this.defCategoryArry['data'] = {
          value: cmdType,
          name: 'Criticalitems',
        };
        break;
      }
      default: {
        relUrl = 'GetPatientsByStatusNew';

        if (this.catId != 7) {
          data = {
            FacilityQueueId: this.facilityID,
            CommandName: cmdType,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
          };
          if (this.catId == 2) {
            this.defCategoryArry['data'] = {
              value: 'priority',
              name: 'Priority Items',
            };
          } else if (this.catId == 3) {
            this.defCategoryArry['data'] = {
              value: 'pendingclient',
              name: 'Request for Info',
            };
          } else if (this.catId == 5) {
            this.defCategoryArry['data'] = {
              value: 'dailyflow',
              name: 'Daily Flow',
            };
          }
        } else {
          data = {
            ClientFacilityId: this.facilityID,
            CommandName: cmdType,
            MonthNumber: this.monthNumber,
            YearNumber: this.yearNumber,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
          };
        }
        break;
      }
    }

    if (relUrl != '' && cmdType != 'null' && cmdType != '') {
      this.allpatientQdata = [];

      if (
        this.commandtype == 'priority' ||
        this.commandtype == 'pendingclient' ||
        this.commandtype == 'dailyflow'
      ) {
        this.IndVsbl = false;

        if (this.CategoryArry != undefined) {
          for (let d of this.CategoryArry) {
            this.p[d.value] = 1;
            let cmd = d.value;

            if (
              d.value.toLowerCase() == 'codingexpert' ||
              d.value.toLowerCase() == 'baq' ||
              d.value.toLowerCase() == 'authreview' ||
              d.value.toLowerCase() == 'returnedcodingrfi'
            ) {
              cmd = 'BillerOnHold';
            }

            relUrl = 'GetPatientsByStatusNew';

            if (this.catId != 7) {
              data = {
                FacilityQueueId: this.facilityID,
                CommandName: cmd,
                pageNum: this.pagenumber,
                pageSize: this.pageValue,
                Isassigned: 1,
              };
            } else {
              data = {
                ClientFacilityId: this.facilityID,
                CommandName: cmd,
                MonthNumber: this.monthNumber,
                YearNumber: this.yearNumber,
                pageNum: this.pagenumber,
                pageSize: this.pageValue,
                Isassigned: 1,
              };
            }

            if (d.value != 'EOD' && d.value != 'Exception') {
              this.getApiService.post('BillingNew/' + relUrl, data).subscribe(
                (res) => {
                  let pList = [];
                  if (res.status == 'Success') {
                    this.catsrchVal = this.commandtype;
                    console.log(res.data, 'clientlite');
                    pList = res.data;
                  } else if (res.status == 'Error') {
                    pList = [];
                    this.catsrchVal = '';
                    this.tblVsbl = false;
                    this.toastr.error(res.message, 'Error!');
                  }

                  if (res.data.length > 0) {
                    this.tblVsbl = true;
                    this.allpatientttlrws[d.value] = res.data[0].totalcount;
                  } else {
                    this.tblVsbl = false;
                  }
                  this.allpatientQdata[d.value] = pList;
                  this.spinner.hide();
                },
                (error) => {
                  this.allpatientQdata[d.value] = [];
                  this.catsrchVal = '';
                  this.tblVsbl = false;
                  this.spinner.hide();
                  this.toastr.error(error, 'Error!');
                }
              );
            }
          }
        }
      } else {
        this.getApiService.post('BillingNew/' + relUrl, data).subscribe(
          (res) => {
            this.IndVsbl = true;
            if (res.status == 'Success') {
              this.catsrchVal = this.commandtype;
              this.allpatientQdata[this.commandtype] = res.data;
              // this.patientList = res.data;
            } else if (res.status == 'Error') {
              this.allpatientQdata[this.commandtype] = [];
              this.catsrchVal = '';
              this.tblVsbl = false;
              this.toastr.error(res.message, 'Error!');
              this.spinner.hide();
            }

            if (res.data.length > 0) {
              this.tblVsbl = true;
              this.allpatientttlrws[this.commandtype] = res.data[0].totalcount;
              // this.totalRows = res.data[0].totalcount;
            } else {
              this.tblVsbl = false;
            }
            this.spinner.hide();
          },
          (error) => {
            // this.patientList = [];
            this.allpatientQdata[this.commandtype] = [];
            this.catsrchVal = '';
            this.tblVsbl = false;
            this.spinner.hide();
            this.toastr.error(error, 'Error!');
          }
        );
      }
    } else {
      this.patientList = [];
      this.tblVsbl = false;
      this.catsrchVal = '';
      this.spinner.hide();
    }
  }

  getFacilityInfo() {
    this.getApiService.get('CreatePatient/BindDashboardFacilityData').subscribe(
      (res) => {
        if (res.status == 'Success') {
          this.FacilityList = res.data;
        } else if (res.status == 'Error') {
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.toastr.error(error, 'Error!');
      }
    );
  }

  searchPatient() {
    let relUrl = '';
    let data = {};

    let cmdType = this.commandtype;
    this.pagenumber = 1;

    if (
      this.commandtype.toLowerCase() == 'codingexpert' ||
      this.commandtype.toLowerCase() == 'baq' ||
      this.commandtype.toLowerCase() == 'authreview' ||
      this.commandtype.toLowerCase() == 'returnedcodingrfi'
    ) {
      cmdType = 'BillerOnHold';
    }

    switch (cmdType) {
      case 'caseperformed': {
        relUrl = 'CasesPerformedPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        var dcp = { value: cmdType, name: 'Cases Performed' };
        this.defCategoryArry['data'] = dcp;
        break;
      }
      case 'casebilled': {
        relUrl = 'CasesBilledPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        this.defCategoryArry['data'] = { value: cmdType, name: 'Cases Billed' };
        break;
      }
      case 'avgDystoBill': {
        relUrl = 'AvgDaysToBillPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        this.defCategoryArry['data'] = {
          value: cmdType,
          name: 'Avg Days To Bill',
        };
        break;
      }
      case 'avgDystoBillIntrnl': {
        relUrl = 'AvgDaysToBillInternalPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        this.defCategoryArry['data'] = {
          value: cmdType,
          name: 'Avg Days To Bill Internal',
        };
        break;
      }
      case 'priority': {
        relUrl = 'PriorityItemsPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        var dp = { value: cmdType, name: 'Priority Items' };
        this.defCategoryArry['data'] = {
          value: 'priority',
          name: 'Priority Items',
        };
        break;
      }
      case 'pendingclient': {
        relUrl = 'PendingClientPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        this.defCategoryArry['data'] = {
          value: 'pendingclient',
          name: 'Request for Info',
        };
        break;
      }
      case 'dailyflow': {
        relUrl = 'DailyFlowPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        this.defCategoryArry['data'] = {
          value: 'dailyflow',
          name: 'Daily Flow',
        };
        break;
      }
      case 'criticalitem': {
        relUrl = 'CriticalitemsPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        this.defCategoryArry['data'] = {
          value: cmdType,
          name: 'Criticalitems',
        };
        break;
      }
      default: {
        relUrl = 'GetPatientsByStatusNew';

        if (this.catId != 7) {
          data = {
            FacilityQueueId: this.facilityID,
            CommandName: cmdType,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
            PatientName: this.searchValue,
            Isassigned: 1,
          };
          if (this.catId == 2) {
            this.defCategoryArry['data'] = {
              value: 'priority',
              name: 'Priority Items',
            };
          } else if (this.catId == 3) {
            this.defCategoryArry['data'] = {
              value: 'pendingclient',
              name: 'Request for Info',
            };
          } else if (this.catId == 5) {
            this.defCategoryArry['data'] = {
              value: 'dailyflow',
              name: 'Daily Flow',
            };
          }
        } else {
          data = {
            ClientFacilityId: this.facilityID,
            CommandName: cmdType,
            MonthNumber: this.monthNumber,
            YearNumber: this.yearNumber,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
            PatientName: this.searchValue,
            Isassigned: 1,
          };
        }
        break;
      }
    }

    if (relUrl != '' && cmdType != 'null' && cmdType != '') {
      this.allpatientQdata = [];

      if (
        this.commandtype == 'priority' ||
        this.commandtype == 'pendingclient' ||
        this.commandtype == 'dailyflow'
      ) {
        this.IndVsbl = false;

        if (this.CategoryArry != undefined) {
          for (let d of this.CategoryArry) {
            this.allpatientQdata[d.value] = [];
            this.allpatientttlrws[d.value] = 0;

            this.p[d.value] = 1;
            let cmd = d.value;

            if (
              d.value.toLowerCase() == 'codingexpert' ||
              d.value.toLowerCase() == 'baq' ||
              d.value.toLowerCase() == 'authreview' ||
              d.value.toLowerCase() == 'returnedcodingrfi'
            ) {
              cmd = 'BillerOnHold';
            }

            relUrl = 'GetPatientsByStatusNew';

            if (this.catId != 7) {
              data = {
                FacilityQueueId: this.facilityID,
                CommandName: cmd,
                pageNum: this.pagenumber,
                pageSize: this.pageValue,
                PatientName: this.searchValue,
                Isassigned: 1,
              };
            } else {
              data = {
                ClientFacilityId: this.facilityID,
                CommandName: cmd,
                MonthNumber: this.monthNumber,
                YearNumber: this.yearNumber,
                pageNum: this.pagenumber,
                pageSize: this.pageValue,
                PatientName: this.searchValue,
                Isassigned: 1,
              };
            }

            if (d.value != 'EOD' && d.value != 'Exception') {
              this.getApiService.post('BillingNew/' + relUrl, data).subscribe(
                (res) => {
                  let pList = [];
                  if (res.status == 'Success') {
                    this.catsrchVal = this.commandtype;
                    pList = res.data;
                  } else if (res.status == 'Error') {
                    pList = [];
                    this.catsrchVal = '';
                    this.tblVsbl = false;
                    this.toastr.error(res.message, 'Error!');
                  }

                  if (res.data.length > 0) {
                    this.allpatientttlrws[d.value] = res.data[0].totalcount;
                  }
                  // else {
                  //   this.tblVsbl = false;
                  // }
                  this.allpatientQdata[d.value] = pList;
                },
                (error) => {
                  this.allpatientQdata[d.value] = [];
                  this.catsrchVal = '';
                  this.tblVsbl = false;
                  this.toastr.error(error, 'Error!');
                }
              );
            }
          }
        }
      } else {
        this.getApiService.post('BillingNew/' + relUrl, data).subscribe(
          (res) => {
            this.IndVsbl = true;
            if (res.status == 'Success') {
              this.catsrchVal = this.commandtype;
              this.allpatientQdata[this.commandtype] = res.data;
              // this.patientList = res.data;
            } else if (res.status == 'Error') {
              this.allpatientQdata[this.commandtype] = [];
              this.catsrchVal = '';
              this.tblVsbl = false;
              this.toastr.error(res.message, 'Error!');
            }

            if (res.data.length > 0) {
              this.tblVsbl = true;
              this.allpatientttlrws[this.commandtype] = res.data[0].totalcount;
              // this.totalRows = res.data[0].totalcount;
            } else {
              this.tblVsbl = false;
            }
          },
          (error) => {
            // this.patientList = [];
            this.allpatientQdata[this.commandtype] = [];
            this.catsrchVal = '';
            this.tblVsbl = false;
            this.toastr.error(error, 'Error!');
          }
        );
      }
    } else {
      this.patientList = [];
      this.tblVsbl = false;
      this.catsrchVal = '';
    }
    this.p[this.commandtype] = 1;
  }

  // searchPatient() {
  //   let relUrl = "";
  //   let data = {};

  //   switch (this.commandtype) {
  //     case ("caseperformed"): {
  //       relUrl = "CasesPerformedPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "MonthNumber": this.monthNumber,
  //         "YearNumber": this.yearNumber,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("avgDystoBill"): {
  //       relUrl = "AvgDaysToBillPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "MonthNumber": this.monthNumber,
  //         "YearNumber": this.yearNumber,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("avgDystoBillIntrnl"): {
  //       relUrl = "AvgDaysToBillInternalPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "MonthNumber": this.monthNumber,
  //         "YearNumber": this.yearNumber,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("casebilled"): {
  //       relUrl = "CasesBilledPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "MonthNumber": this.monthNumber,
  //         "YearNumber": this.yearNumber,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("priority"): {
  //       relUrl = "PriorityItemsPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("pendingclient"): {
  //       relUrl = "PendingClientPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("dailyflow"): {
  //       relUrl = "DailyFlowPatientList";
  //       data = {
  //         "ClientFacilityId": this.facilityID,
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     case ("criticalitem"): {
  //       relUrl = "CriticalitemsPatientList";
  //       data = {
  //         "pageNum": this.pagenumber,
  //         "pageSize": this.pageValue,
  //         "PatientName": this.searchValue,
  //       }
  //       break;
  //     }
  //     default: {
  //       relUrl = "GetPatientsByStatus";
  //       if (this.catId != 7) {
  //         data = {
  //           "ClientFacilityId": this.facilityID,
  //           "CommandName": this.commandtype,
  //           "pageNum": this.pagenumber,
  //           "pageSize": this.pageValue,
  //           "PatientName": this.searchValue,
  //         }
  //       }
  //       else {
  //         data = {
  //           "ClientFacilityId": this.facilityID,
  //           "CommandName": this.commandtype,
  //           "MonthNumber": this.monthNumber,
  //           "YearNumber": this.yearNumber,
  //           "pageNum": this.pagenumber,
  //           "pageSize": this.pageValue,
  //           "PatientName": this.searchValue,
  //         }
  //       }
  //       break;
  //     }
  //   }
  //   this.allpatientQdata = [];
  //   this.allpatientttlrws = [];

  //   if (this.commandtype == "priority" || this.commandtype == "pendingclient" || this.commandtype == "dailyflow") {
  //     this.IndVsbl = false;

  //     if (this.CategoryArry != undefined) {
  //       for (let d of this.CategoryArry) {
  //         this.p[d.value] = 1;
  //         let cmd = d.value;

  //         if (d.value.toLowerCase() == "codingexpert" || d.value.toLowerCase() == "baq" || d.value.toLowerCase() == "authreview" || d.value.toLowerCase() == "returnedcodingrfi") {
  //           cmd = "BillerOnHold";
  //         }

  //         relUrl = "GetPatientsByStatus";

  //         if (this.catId != 7) {
  //           data = {
  //             "ClientFacilityId": this.facilityID,
  //             "CommandName": cmd,
  //             "pageNum": this.pagenumber,
  //             "pageSize": this.pageValue,
  //             "PatientName": this.searchValue,
  //           }
  //         }
  //         else {
  //           data = {
  //             "ClientFacilityId": this.facilityID,
  //             "CommandName": cmd,
  //             "MonthNumber": this.monthNumber,
  //             "YearNumber": this.yearNumber,
  //             "pageNum": this.pagenumber,
  //             "pageSize": this.pageValue,
  //             "PatientName": this.searchValue,
  //           }
  //         }

  //         if (d.value != "EOD" && d.value != "Exception") {

  //           this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
  //             let pList = [];
  //             if (res.status == "Success") {
  //               this.catsrchVal = this.commandtype;
  //               pList = res.data;
  //             }
  //             else if (res.status == "Error") {
  //               pList = [];
  //               this.catsrchVal = "";
  //               this.tblVsbl = false;
  //               this.toastr.error(res.message, 'Error!');
  //             }

  //             if (res.data.length > 0) {
  //               this.tblVsbl = true;
  //               this.allpatientttlrws[d.value] = res.data[0].totalcount;
  //             }
  //             else {
  //               this.tblVsbl = false;
  //             }
  //             this.allpatientQdata[d.value] = pList;

  //           }, (error) => {
  //             this.allpatientQdata[d.value] = [];
  //             this.catsrchVal = "";
  //             this.tblVsbl = false;
  //             this.toastr.error(error, 'Error!');
  //           });

  //         }
  //       }

  //     }
  //   }
  //   else {

  //     this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
  //       if (res.status == "Success") {
  //         this.allpatientQdata[this.commandtype] = res.data;
  //         this.patientList = res.data;
  //         if (res.data.length > 0) {
  //           this.tblVsbl = true;
  //           this.allpatientttlrws[this.commandtype] = res.data[0].totalcount;
  //         }
  //         else {
  //           this.tblVsbl = false;
  //         }
  //       }
  //       else if (res.status == "Error") {
  //         this.allpatientQdata[this.commandtype] = [];
  //         this.toastr.error(res.message, 'Error!');
  //       }
  //     }, (error) => {
  //       this.allpatientQdata[this.commandtype] = [];
  //       this.toastr.error(error, 'Error!');
  //     });
  //   }
  // }

  exportData() {
    let relUrl = '';
    let data = {};

    switch (this.commandtype) {
      case 'caseperformed': {
        data = {
          ClientFacilityId: this.facilityID,
          CommandName: 'CasesPerformed',
        };
        break;
      }
      case 'casebilled': {
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          CommandName: 'CasesBilled',
        };
        break;
      }
      case 'avgDystoBill': {
        relUrl = 'AvgDaysToBillPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          CommandName: 'AVGDays',
        };
        break;
      }
      case 'avgDystoBillIntrnl': {
        relUrl = 'AvgDaysToBillInternalPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          CommandName: 'AVGDaysInternal',
        };
        break;
      }
      case 'priority': {
        data = {
          ClientFacilityId: this.facilityID,
          CommandName: 'PriorityItems',
        };
        break;
      }
      case 'pendingclient': {
        data = {
          ClientFacilityId: this.facilityID,
          CommandName: 'PendingClient',
        };
        break;
      }
      case 'dailyflow': {
        data = {
          ClientFacilityId: this.facilityID,
          CommandName: 'DailyFlow',
        };
        break;
      }
      case 'criticalitem': {
        data = {
          CommandName: 'CriticalItems',
        };
        break;
      }
      default: {
        if (this.catId != 7) {
          data = {
            ClientFacilityId: this.facilityID,
            CommandName: this.commandtype,
          };
        } else {
          data = {
            ClientFacilityId: this.facilityID,
            CommandName: this.commandtype,
            MonthNumber: this.monthNumber,
            YearNumber: this.yearNumber,
          };
        }
        break;
      }
    }

    this.getApiService
      .post4File('BillingNew/ExportPatientData', data)
      .subscribe(
        (res) => {
          saveAs(
            res,
            this.commandtype.toUpperCase() +
              '_' +
              new Date().toISOString() +
              '.xlsx'
          );
        },
        (error) => {
          this.toastr.error(error, 'Error!');
        }
      );
  }

  sortList(orderby, sortby, subtype) {
    let relUrl = '';
    let data = {};
    let type = subtype;

    if (
      subtype.toLowerCase() == 'codingexpert' ||
      subtype.toLowerCase() == 'baq' ||
      subtype.toLowerCase() == 'authreview' ||
      subtype.toLowerCase() == 'returnedcodingrfi'
    ) {
      type = 'BillerOnHold';
    }

    switch (type) {
      case 'caseperformed': {
        relUrl = 'CasesPerformedPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'casebilled': {
        relUrl = 'CasesBilledPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'avgDystoBill': {
        relUrl = 'AvgDaysToBillPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'avgDystoBillIntrnl': {
        relUrl = 'AvgDaysToBillInternalPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'priority': {
        relUrl = 'PriorityItemsPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'pendingclient': {
        relUrl = 'PendingClientPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'dailyflow': {
        relUrl = 'DailyFlowPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      case 'criticalitem': {
        relUrl = 'CriticalitemsPatientList';
        data = {
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          OrderBy: orderby,
          SortDir: sortby,
        };
        break;
      }
      default: {
        relUrl = 'GetPatientsByStatusNew';
        if (this.catId != 7) {
          data = {
            FacilityQueueId: this.facilityID,
            CommandName: type,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
            PatientName: this.searchValue,
            OrderBy: orderby,
            SortDir: sortby,
            Isassigned: 1,
          };
        } else {
          data = {
            ClientFacilityId: this.facilityID,
            CommandName: type,
            MonthNumber: this.monthNumber,
            YearNumber: this.yearNumber,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
            PatientName: this.searchValue,
            OrderBy: orderby,
            SortDir: sortby,
            Isassigned: 1,
          };
        }
        break;
      }
    }

    this.getApiService.post('BillingNew/' + relUrl, data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          this.allpatientQdata[subtype] = res.data;
          if (res.data.length > 0) {
            this.allpatientttlrws[subtype] = res.data[0].totalcount;
          }
        } else if (res.status == 'Error') {
          this.allpatientQdata[subtype] = [];
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.allpatientQdata[subtype] = [];
        this.toastr.error(error, 'Error!');
      }
    );
  }

  pageChange(event, subtype) {
    this.pagenumber = event;
    let relUrl = '';
    let data = {};
    let type = subtype;

    if (
      subtype.toLowerCase() == 'codingexpert' ||
      subtype.toLowerCase() == 'baq' ||
      subtype.toLowerCase() == 'authreview' ||
      subtype.toLowerCase() == 'returnedcodingrfi'
    ) {
      type = 'BillerOnHold';
    }

    switch (type) {
      case 'caseperformed': {
        relUrl = 'CasesPerformedPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          Isassigned: 1,
        };
        break;
      }
      case 'casebilled': {
        relUrl = 'CasesBilledPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
          Isassigned: 1,
        };
        break;
      }
      case 'avgDystoBill': {
        relUrl = 'AvgDaysToBillPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        break;
      }
      case 'avgDystoBillIntrnl': {
        relUrl = 'AvgDaysToBillInternalPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          MonthNumber: this.monthNumber,
          YearNumber: this.yearNumber,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        break;
      }
      case 'priority': {
        relUrl = 'PriorityItemsPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        break;
      }
      case 'pendingclient': {
        relUrl = 'PendingClientPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        break;
      }
      case 'dailyflow': {
        relUrl = 'DailyFlowPatientList';
        data = {
          ClientFacilityId: this.facilityID,
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        break;
      }
      case 'criticalitem': {
        relUrl = 'CriticalitemsPatientList';
        data = {
          pageNum: this.pagenumber,
          pageSize: this.pageValue,
          PatientName: this.searchValue,
        };
        break;
      }
      default: {
        relUrl = 'GetPatientsByStatusNew';
        if (this.catId != 7) {
          data = {
            FacilityQueueId: this.facilityID,
            CommandName: type,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
            PatientName: this.searchValue,
            Isassigned: 1,
          };
        } else {
          data = {
            ClientFacilityId: this.facilityID,
            CommandName: type,
            MonthNumber: this.monthNumber,
            YearNumber: this.yearNumber,
            pageNum: this.pagenumber,
            pageSize: this.pageValue,
            PatientName: this.searchValue,
            Isassigned: 1,
          };
        }
        break;
      }
    }

    this.getApiService.post('BillingNew/' + relUrl, data).subscribe(
      (res) => {
        if (res.status == 'Success') {
          this.allpatientQdata[subtype] = res.data;
          if (res.data.length > 0) {
            this.allpatientttlrws[subtype] = res.data[0].totalcount;
          }
        } else if (res.status == 'Error') {
          this.allpatientQdata[subtype] = [];
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.allpatientQdata[subtype] = [];
        this.toastr.error(error, 'Error!');
      }
    );
  }

  showFormModal(data) {
    console.log(data);
    const patient = createPatient({
      mrn: data?.mrn,
      totalcount: data?.totalcount,
      facilityId: data?.facilityId,
      name: data?.patientName,
      dob: data?.dob,
      dateOfSurgery: data?.dateOfSurgery,
      insuranceProvider: data?.insuranceProvider,
      physician: data?.physician,
      bdosid: data.bdosid,
    });
    this.patientService.setAndActivate([patient]);
    this.facilityService.setActive([data.facilityId]);
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    } else {
      this.shouldShowFormModal = true;
    }
  }

  showQAOPFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAOPFormModal) {
      this.shouldShowQAOPFormModal = false;
    } else {
      this.shouldShowQAOPFormModal = true;
    }
  }

  showQAICFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAICFormModal) {
      this.shouldShowQAICFormModal = false;
    } else {
      this.shouldShowQAICFormModal = true;
    }
  }

  showQAATFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAATFormModal) {
      this.shouldShowQAATFormModal = false;
    } else {
      this.shouldShowQAATFormModal = true;
    }
  }

  onCloseFormModal() {
    this.patientService.reset();
    this.facilityService.setActive([]);
    this.shouldShowFormModal = false;
    this.shouldShowQAOPFormModal = false;
    this.shouldShowQAICFormModal = false;
    this.shouldShowQAATFormModal = false;
    this.shouldShowBulkActionModal = false;
    this.patientService.resetAndInactivateAll();
    this.getDataList();
  }

  toggle(evt, index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = -1;
    }
    this.patientService.reset();
  }

  isBulkActionAllowed() {
    const selectedRequest = this.selectionQuery.getValue().value;
    const requestNameConstant =
      BulkActionConfig.requestToNameConstanMap.get(selectedRequest);
    return (
      this.bulkActionAllowList.includes(requestNameConstant) &&
      this.bulkActionAllowList.includes(this.requestToSatisfy)
    );
  }

  startBulkAction() {
    if (this.isBulkActionAllowed()) {
      this.shouldShowBulkActionModal = this.shouldShowBulkActionModal
        ? false
        : true;
    }
  }
  selectRowOfAccordian(data, catValue) {
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    console.log('catValue', catValue);
    this.requestToSatisfy =
      BulkActionConfig.requestToNameConstanMap.get(catValue);
    if (this.patientQuery.getCount() === 0) {
      this.patientService.set(this.allpatientQdata[catValue]);
    }

    if (this.patientQuery.hasActive(bdosid)) {
      this.patientService.inActiveById(bdosid);
    } else {
      this.patientService.addActive(bdosid);
    }
  }

  selectRow(data) {
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    if (this.patientQuery.getCount() === 0) {
      console.log(
        'this.allpatientQdata[this.commandtype]',
        this.allpatientQdata[this.commandtype]
      );
      console.log('commandtype', this.commandtype);
      this.requestToSatisfy = BulkActionConfig.requestToNameConstanMap.get(
        this.commandtype
      );
      this.patientService.set(this.allpatientQdata[this.commandtype]);
    }

    if (this.patientQuery.hasActive(bdosid)) {
      this.patientService.inActiveById(bdosid);
    } else {
      this.patientService.addActive(bdosid);
    }
  }
  isRowActive(data) {
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    this.patientQuery.hasActive(bdosid);
  }

  resetPageNumber() {
    this.pagenumber = 1;
  }
  isClientRole(): boolean {
    return this.sessionQuery.isClientRole();
  }
  isClientLiteRole(): boolean {
    return this.sessionQuery.isClientLiteRole();
  }
}
