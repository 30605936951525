import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CptStore, CptState } from './cpt.store';
import { Cpt } from './cpt.model';

@Injectable({ providedIn: 'root' })
export class CptQuery extends QueryEntity<CptState> {

  constructor(protected store: CptStore) {
    super(store);
  }
   selectAllInactive(): Observable<Cpt[]>{
    const all$ = this.selectAll();
    const active$ = this.selectActive();
    const inactive$ = combineLatest([all$, active$]).pipe(
      map(([all, active]) => this.filterInactive(all, active))
    );
    return inactive$;
  }

   getAllInactive(): Cpt[]{
    return this.getAll({
      filterBy: e => !this.getActiveId().includes(e.cpt)
    })
  }

  private filterInactive(all: Cpt[], active: Cpt[]): Cpt[]{
   return  all.filter((el) => !this.isActive(el, active))
  }
  
  private isActive(e: Cpt, active: Cpt[]): boolean{
    return active?.some(a => a.cpt === e.cpt);
  }
}
