import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface BulkActionSelectionState {
    value: string;
}

export function createInitialState(): BulkActionSelectionState {
    return {
        value: ""
    }
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bulk-action-selection' })
export class BulkActionSelectionStore extends Store<BulkActionSelectionState> {
    constructor(){
        super(createInitialState());
    }
}