import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { BillingDashboardRoutingModule } from './billing-dashboard-routing.module';
import { CurrentMonthCasesComponent } from './current-month-cases/current-month-cases.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EodComponent } from './eod/eod.component';
import { EodFormComponent } from './eod/eod-form/eod-form.component';
import { EodHoldComponent } from './eod/eod-hold/eod-hold.component';
import { EodArchiveComponent } from './eod/eod-archive/eod-archive.component';
import { DashDetailComponent } from './dash-detail/dash-detail.component';
import { PatientActionComponent } from './patient-action/patient-action.component';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PatientDetailComponent } from './patient-detail/patient-detail.component';
import { OpnoteQaComponent } from './opnote-qa/opnote-qa.component';
import { AttachmentsQaComponent } from './attachments-qa/attachments-qa.component';
import { IncodingQaComponent } from './incoding-qa/incoding-qa.component';
import { ChartsModule } from 'ng2-charts';
import { EodHoldFormComponent } from './eod/eod-hold-form/eod-hold-form.component';
import { CloseOfDayComponent } from './eod/close-of-day/close-of-day.component';
import { NgxPrintModule } from 'ngx-print';
import { UndoCloseOfDayComponent } from './eod/undo-close-of-day/undo-close-of-day.component';
import { ExceptionComponent } from './exceptions/exception/exception.component';
import { AuditLogComponent } from './exceptions/audit-log/audit-log.component';
import { ExceptionActionComponent } from './exceptions/exception-action/exception-action.component';
import { ToolsQaComponent } from './tools-qa/tools-qa.component';
import { FacilityDetailsComponent } from '../masters/facility-master/facility-details/facility-details.component';
import { ClaimTypeActionComponent } from './patient-action/claim-type-action/claim-type-action.component';
import { UnsetBilledActionComponent } from './patient-action/unset-billed-action/unset-billed-action.component';
import { MatExpansionModule } from '@angular/material/expansion';
//import { UnsetBilledActionComponent } from './patient-action/unset-billed-action/unset-billed-action.component';
import {CurrencyPipe} from '@angular/common'
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { CriticalItemComponent } from './dash-detail/critical-item/critical-item.component';
import { NsnAccordianComponent } from './dash-detail/shared/nsn-accordian/nsn-accordian.component';
import { ReturnMailComponent } from './dash-detail/return-mail/return-mail.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { PatientInfoHeaderComponent } from './patient-info-header/patient-info-header.component';
import { ReturnMailActionComponent } from './dash-detail/critical-item/return-mail-action/return-mail-action.component';
import { CaseRequestComponent } from './patient-action/case-request/case-request.component';
import { CaseRequestSelectionComponent } from './patient-action/case-request/case-request-selection/case-request-selection.component';
import { CurrentCaseRequestComponent } from './patient-action/case-request/current-case-request/current-case-request.component';
import { CrOpNoteComponent } from './patient-action/case-request/cr-op-note/cr-op-note.component';
import { CrPathologyComponent } from './patient-action/case-request/cr-pathology/cr-pathology.component';
import { CrPricingComponent } from './patient-action/case-request/cr-pricing/cr-pricing.component';
import { CrBlockReportComponent } from './patient-action/case-request/cr-block-report/cr-block-report.component';
import { CrCodingQueryComponent } from './patient-action/case-request/cr-coding-query/cr-coding-query.component';
import { CrOtherMiscComponent } from './patient-action/case-request/cr-other-misc/cr-other-misc.component';
import { CrAuthReviewComponent } from './patient-action/case-request/cr-auth-review/cr-auth-review.component';
import { CrClientHighComponent } from './patient-action/case-request/cr-client-high/cr-client-high.component';
import { CaseRequestCptComponent } from './patient-action/case-request/case-request-cpt/case-request-cpt.component';
import { CrNsnHighComponent } from './patient-action/case-request/cr-nsn-high/cr-nsn-high.component';
import { CrCodingExpertComponent } from './patient-action/case-request/cr-coding-expert/cr-coding-expert.component';
import { CrInCodingComponent } from './patient-action/case-request/cr-in-coding/cr-in-coding.component';
import { CrVarianceComponent } from './patient-action/case-request/cr-variance/cr-variance.component';
import { CrInvoiceComponent } from './patient-action/case-request/cr-invoice/cr-invoice.component';
import { CrImplantLogComponent } from './patient-action/case-request/cr-implant-log/cr-implant-log.component';
import { CaseRequestNotesComponent } from './patient-action/case-request/case-request-notes/case-request-notes.component';
import { CaseRequestHistoryComponent } from './patient-action/case-request/case-request-history/case-request-history.component';
import { CrCptViewComponent } from './patient-action/case-request/case-request-cpt/cr-cpt-view/cr-cpt-view.component';
import { CriticalItemActionComponent } from './dash-detail/critical-item/critical-item-action/critical-item-action.component';
import { BulkActionComponent } from './bulk-action/bulk-action.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginLayoutModule } from 'src/app/layouts/login-layout/login-layout.module';
import { CreateBatchReviewCasesComponent } from './create-batch-review-cases/create-batch-review-cases.component';
import { CrReviewDoneComponent } from './patient-action/case-request/cr-review-done/cr-review-done.component';
import { ToolsQaImplantLogComponent } from './tools-qa/tools-qa-implant-log/tools-qa-implant-log.component';
import { CodingActionComponent } from './dash-detail/coding-action/coding-action.component';
@NgModule({
  declarations: [CurrentMonthCasesComponent,
    EodComponent,
    EodFormComponent,
    EodHoldComponent,
    EodArchiveComponent,
    DashDetailComponent,
    PatientActionComponent,
    PatientDetailComponent,
    OpnoteQaComponent,
    AttachmentsQaComponent,
    IncodingQaComponent,
    EodHoldFormComponent,
    CloseOfDayComponent,
    UndoCloseOfDayComponent,
    ExceptionComponent,
    AuditLogComponent,
    ExceptionActionComponent,
    FacilityDetailsComponent,
    ToolsQaComponent,
    ClaimTypeActionComponent,
    UnsetBilledActionComponent,
    CriticalItemComponent,
    NsnAccordianComponent,
    CriticalItemActionComponent,
    ReturnMailComponent,
    PatientInfoHeaderComponent,
    ReturnMailActionComponent,
    CaseRequestComponent,
    CaseRequestSelectionComponent,
    CurrentCaseRequestComponent,
    CrOpNoteComponent,
    CrPathologyComponent,
    CrPricingComponent,
    CrBlockReportComponent,
    CrCodingQueryComponent,
    CrOtherMiscComponent,
    CrAuthReviewComponent,
    CrClientHighComponent,
    CaseRequestCptComponent,
    CrNsnHighComponent,
    CrCodingExpertComponent,
    CrInCodingComponent,
    CrVarianceComponent,
    CrInvoiceComponent,
    CrImplantLogComponent,
    CaseRequestNotesComponent,
    CaseRequestHistoryComponent,
    CrCptViewComponent,
    BulkActionComponent,
    CreateBatchReviewCasesComponent,
    CrReviewDoneComponent,
    ToolsQaImplantLogComponent,
    //UnsetBilledActionComponent
    CodingActionComponent
  ],
  providers: [CurrencyPipe, DatePipe],
  imports: [
    CommonModule,
    BillingDashboardRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    ChartsModule,
    NgxPrintModule,
    BsDatepickerModule,
    DatepickerModule,
    SelectDropDownModule,
    MatExpansionModule,
    SharedModule,
    NgxSpinnerModule,
    LoginLayoutModule
  ],
  exports: [
    PatientInfoHeaderComponent,
    CaseRequestComponent,
    CaseRequestSelectionComponent,
    CurrentCaseRequestComponent,
    CrOpNoteComponent,
    CrPathologyComponent,
    CrPricingComponent,
    CrBlockReportComponent,
    CrCodingQueryComponent,
    CrOtherMiscComponent,
    CrAuthReviewComponent,
    CrClientHighComponent,
    CaseRequestCptComponent,
    CrNsnHighComponent,
    CrCodingExpertComponent,
    CrInCodingComponent,
    CrVarianceComponent,
    CrInvoiceComponent,
    CrImplantLogComponent,
    CaseRequestNotesComponent,
    CaseRequestHistoryComponent,
    BulkActionComponent,
    ToolsQaComponent,
    AttachmentsQaComponent,
    IncodingQaComponent,
    OpnoteQaComponent,
    UnsetBilledActionComponent
  ]
})
export class BillingDashboardModule { }
