import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { NSNAccordian } from './nsn-accordian.model';
import { NSNAccordianStore } from './nsn-accordian.store';

@Injectable({ providedIn: 'root' })
export class NSNAccordianService {

  constructor(private store: NSNAccordianStore, private apiService: ApiService, private toastr: ToastrService) {
  }

  add(nsnAccordian: NSNAccordian){
    this.store.add(nsnAccordian);
  }

  reset(){
    this.store.set([]);
  }

  setActive(id : ID){
    this.store.setActive(id);
  }

  setInactive(id: ID){
    this.store.toggleActive(id);
  }

  setLoading(state: boolean){
    this.setLoading(state);
  }
}
