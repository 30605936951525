import { Component, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { Observable, Subscription } from 'rxjs';
import { UsersService } from './state/users/users.service';
import { FacilityService } from '../../../shared/state/facilities/facility.service';
import { SelectionQuery } from '../../../shared/state/selection/selection.query';
import { map } from 'rxjs/operators';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { PhysicianService } from 'src/app/shared/state/physician/physician.service';
import { CustomQueueService } from '../../masters/custom-queue/state/custom-queue.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit, AfterViewInit, OnDestroy {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  UserMasterArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  columns: string[];
  privileges:any;
  subscrption: Subscription= new Subscription();
  formSelection$: Observable<string>;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private userService: UsersService,
    private groupService: GroupsService,
    private selectionService: SelectionService,
    private selectionQuery: SelectionQuery,
    private customQueueService: CustomQueueService,
    private accessServices:AccessService
    ) { }

  ngOnInit(): void {
    this.privileges=this.accessServices.navbar_access
    console.log(this.privileges,"in manage users")
    this.initColumns();
    this.commonUtil.setTitle("Manage Users");
    this.getUserMasterList();
    this.formSelection$ = this.selectionQuery.select().pipe(map((res)=> res.value ));
  }

  ngAfterViewInit() {
    const userSubs = this.userService.get().subscribe();
    const groupSub = this.groupService.get().subscribe();

    this.subscrption.add(userSubs);
    this.subscrption.add(groupSub);
  }

  ngOnDestroy(){
    this.subscrption.unsubscribe();  
  }

  initColumns(){
    this.columns = ['#', 'Full Name', 'Login/Email Id', 'Assignee', 'Reporting Manager', 'Role', 'Status', 'Action'];
  }
  showFormModal() {
    this.formData = null;
    this.selectionService.changeSelection("edit");
    console.log(this.shouldShowFormModal);
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.selectionService.changeSelection("");
    this.shouldShowFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getUserMasterList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }


  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getUserMasterList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }
  search(){
    this.pagenumber = 1;
    this.p = 1;
    this.getUserMasterList();
  }

  getUserMasterList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };
    this.getApiService.post("Admin/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.UserMasterArry = res.data;
        this.totalRows = res.data[0]?.totalcount;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  editUserMaster(data) {
    
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.userService.active(data);
      this.shouldShowFormModal = true;
    }
  }

  migrateUser(data){
    this.shouldShowFormModal = false;
    this.userService.active(data);
    this.customQueueService.get().subscribe(res => {
      this.selectionService.changeSelection("copy");
    });
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.UserMasterArry = [];
    this.getUserMasterList();
  }

  delUserMaster(delRow) {
    
    let isEnabled = "Inactive";

    switch (delRow.isEnabled) {
      case "Active":
        isEnabled = "Inactive";
        break;
      case "Inactive":
        isEnabled = "Active";
        break;
    }


    let data = { "UserId": delRow.id, "IsEnabled": isEnabled };


    this.getApiService.post("Admin/DeleteUser", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getUserMasterList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getUserMasterList();
  }

}
