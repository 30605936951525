<div *ngIf="privileges.dUpload" class="row" style="margin-top: 15px;">
    <div *ngIf=" callerId =='returnMail' " class="col-12" style="margin-top:10px">
        <label><b>Comment</b></label><br/>
        <textarea class="form-control"  [(ngModel)]="onCommentValue" ></textarea>
        
    </div>
    <div class="col-md-6">
        <p class="comment-heading-case">{{label}}</p>
        <h4 *ngIf= "callerId === 'returnMail'" class="required"><b>Upload File</b></h4>
        <div *ngIf="callerId === 'caseRequest'" style="color: black;" class="required"><b>Upload File</b></div>
        <input type="file" (change)="fileAttachChanged($event)"
            class="form-control-file py-1 file-overlap-name">
    </div>
    

    <div class="col-md-6"  [ngClass] = "{
                                        'return-mail-add-attachment': callerId === 'returnMail',
                                        'case-request-add-attachment': callerId === 'caseRequest'
                                        }">
        <button type="button" (click)="onClickClose()" *ngIf="showCancel"
            class="btn btn-secondary btn-patient-box closeMySidenav mr-1">Cancel</button>

        <button type="button"  (click)="uploadAttachment()"
            class="btn btn-primary btn-patient-box"  >Upload</button>
    </div>
</div>