import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ManagementReport } from './management-report.model';

export interface ManagementReportState extends EntityState<ManagementReport>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'management-report' })
export class ManagementReportStore extends EntityStore<ManagementReportState> {

  constructor() {
    super();
  }

}
