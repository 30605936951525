import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-ar-dashboard',
  templateUrl: './ar-dashboard.component.html',
  styleUrls: ['./ar-dashboard.component.css']
})
export class ArDashboardComponent implements OnInit {
  selectedIndex = 0;
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("AR Dashboard");
  }

  toggle(evt, index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = 0;
    }

  }

}
