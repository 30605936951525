<div class="user-login fullheight fullheight-pad">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div>
                    <img src="assets/img/logo-login.png" alt="" class="img-fluid py-3">
                </div>
                <div class="">
                    <h1 class="mb-4">Confirm Email</h1>
                    <!-- <p class="error-msg">all fields are mandatory*</p> -->
                </div>
                <div *ngIf="status == true">
                    <h4>Your email is confirmed and you're good to go !!</h4>
                    <button type="submit" routerLink="/login"
                        class="btn btn-primary btn-user btn-block login-btn-user">Continue to Login</button>
                </div>
                <div class="">
                    <p><a href="#" class="text-underline">Terms & Conditions</a> and <a href="#"
                            class="text-underline">Privacy
                            Policy</a></p>
                </div>
            </div>
        </div>
    </div>
</div>