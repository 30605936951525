import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ObservableInput } from 'ngx-observable-input';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CategoryItemQuery } from 'src/app/shared/state/category-item/category-item.query';
import { FacilityQuery } from '../../state/facilities/facility.query';
import { SelectionService } from '../../state/selection/selection.service';
import { createNsnSimpleDrodown, createNsnSimpleDropDownData, NsnSimpleDropdown, NsnSimpleDropDownData } from './dropdowns/nsn-simple-dropdown.types';
import { Position, TableMenuConfig } from './table-menu-config.types';

@Component({
  selector: 'app-table-menu-config',
  templateUrl: './table-menu-config.component.html',
  styleUrls: ['./table-menu-config.component.css']
})
export class TableMenuConfigComponent implements OnInit {
 @Input("tableMenuconfig") tableMenuconfig: TableMenuConfig;
 @ObservableInput()  @Input('actionEnabled') actionEnabled$: Observable<boolean>;
 @Output('simpleDropdownChange') simpleDropdownChange = new EventEmitter();
 @Output('searchGrid') searchGrid = new EventEmitter();
 searchValue: string;
 config;
 RF: Position  = Position.RF;
  //TODO: make this an array of observable and read from corresponding store.
  simpleDropdownListOne$: Observable<NsnSimpleDropdown>;
  selectedDropdownOne: NsnSimpleDropDownData = createNsnSimpleDropDownData({id: "Unassigned", displayValue: "Unassigned"});
  simpleDropdownListTwo$: Observable<NsnSimpleDropdown>;
  constructor(private selectionService: SelectionService, private categoryItemQuery: CategoryItemQuery, private facilityQuery: FacilityQuery) { 
    this.config = this.initialiseDropDownConfig();
  }

  ngOnInit(): void {
    this.simpleDropdownListOne$ = this.categoryItemQuery.selectAll().pipe(map( res => {
      const data = res.map(d => createNsnSimpleDropDownData({ id: d.name, displayValue: d.value}));
      return createNsnSimpleDrodown('', data);
    }));

    this.simpleDropdownListTwo$ = this.facilityQuery.selectAll().pipe(map( res => {
      const data = res.map(d => createNsnSimpleDropDownData({ id: d.name, displayValue: d.name}));
      return createNsnSimpleDrodown('', data);
    }));
  }

  simpleDropdownChanged(){
    // console.log('simpleDropdownChanged()', this.simpleDropdownSelectedOption);
    // this.simpleDropdownChange.emit(this.simpleDropdownSelectedOption[1].id);
  }

  notify(){
   console.log(this.tableMenuconfig.actionButton);
   this.selectionService.changeSelection(this.tableMenuconfig.actionButton.action);
  }

  search(){
    this.searchGrid.emit(this.searchValue);
    this.selectionService.changeSelection(this.searchValue);
  }
  initialiseDropDownConfig(){
    return {
      displayKey: 'name', //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '20rem', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: 'name', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }

  compare(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.id === val2.id;
  }
}
