<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="!(activeReportLineId$ | async)">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
        <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="top-action-container">
                        <div class="item-review-cases">
                            <label for="fromDate" class="pad-right-lbl">From Date:</label>
                            <input type="date" id="fromDate" name="fromDate" [(ngModel)]="selFromDate">
                            <span *ngIf="submitted && !selFromDate" class="invalid-feedback">
                                From Date is mandatory!!
                            </span>

                        </div>
                        <div class="item-month">
                            <label for="toDate" class="pad-right-lbl">To Date:</label>
                            <input type="date" id="toDate" name="toDate" [(ngModel)]="selToDate">
                            <span *ngIf="submitted && !selToDate" class="invalid-feedback">
                                To Date is mandatory!!
                            </span>
                        </div>
                        <div class="item-category">
                            <div>
                                <label class="radio-inline">
                                    <input type="radio" name="optradio" value='user' [(ngModel)]="reportBy" (ngModelChange)="onUserReportSelection($event)">Team
                                </label>
                                <label class="radio-inline">
                                    <input type="radio" name="optradio" value='facility' [(ngModel)]="reportBy" (ngModelChange)="onFacilityReportSelection($event)">Facility
                                </label>
                            </div>
                              <span *ngIf="submitted && (!reportBy) " class="invalid-feedback">
                                please select report category!!
                            </span>
                        </div>
                        <div class="item-facility">
                            <ngx-select-dropdown 
                            [config]="userdropdownConfig" 
                            [options]="userSourceList"
                            (change)="userSelectionChanged($event)"
                            autoClearSearch="true"
                            [multiple]="false"
                            [(ngModel)]="selectedUser"
                            >
                            </ngx-select-dropdown>
                            <span *ngIf="submitted && !selectedUser " class="invalid-feedback">
                                please select user!!
                            </span>
                        </div>
                        <div class="item-year">
                            
                            <button type="button" (click)="onSearch()" style="width: 120px;"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav"><i class="fa fa-search fa-fw"></i>Search</button>
                   
                        </div>
                        <div class="item-blank"></div>
                        <div class="item-search">
                        
                        </div>
                        <div class="item-export">
                            <button type="button" (click)="onExport()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav">Export</button>
                        </div>
                    </div>
                </div>
              
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr style="color: #333;" *ngIf="reportBy === 'user'">
                                    <th *ngFor="let col of userColoumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'totalopencasesbfr' || col?.name === 'totalopencasestilldate' || col?.name === 'totalcasesadded' || col?.name === 'totalsetbilledcases' }"
                                    >
                                        {{col.displayName}}
                                    </th>
                                </tr>
                                <tr style="color: #333;" *ngIf="reportBy === 'facility'">
                                    <th *ngFor="let col of facilityColoumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'totalopencasesbfr' || col?.name === 'totalopencasestilldate' || col?.name === 'totalcasesadded' || col?.name === 'totalsetbilledcases' }"
                                    >
                                        {{col.displayName}}
                                    </th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr style="color: #333;" *ngIf="reportBy === 'user'">
                                    <th *ngFor="let col of userColoumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'totalopencasesbfr' || col?.name === 'totalopencasestilldate' || col?.name === 'totalcasesadded' || col?.name === 'totalsetbilledcases' }"
                                    >
                                        {{col.displayName}}
                                    </th>
                                </tr>
                                <tr style="color: #333;" *ngIf="reportBy === 'facility'">
                                    <th *ngFor="let col of facilityColoumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'totalopencasesbfr' || col?.name === 'totalopencasestilldate' || col?.name === 'totalcasesadded' || col?.name === 'totalsetbilledcases' }"
                                    >
                                        {{col.displayName}}
                                    </th>
                                </tr>
                            </tfoot>
                            <ng-container *ngIf="datasource$ | async as datasource">
                                <tr *ngFor="let data of datasource | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;"
                                    style="color: #333;
                            font-weight: 400;">
                                    <td>{{(i+1) + ((currentPage-1)*itemsPerPage)}}</td>
                                    <td *ngIf="reportBy === 'user'">{{data.user}}</td>
                                    <td *ngIf="reportBy === 'facility'">{{data.facilityName}}</td>
                                    <td class="txt-align-right">{{data.totalopencasesbfr | number}}</td>
                                    <td class="txt-align-right">{{data.totalopencasestilldate | number}}</td>
                                    <td class="txt-align-right">{{data.totalcasesadded | number}}</td>
                                    <td class="txt-align-right">{{data.totalsetbilledcases | number}}</td>
                                    <td>
                                        <a href="javascript:void(0)"  (click)="showReportLineDetail(data)"
                                                class="icon-color-darkgreen openMySidenav mr-2"><i
                                                    title="Patient View" class="fa fa-eye"></i></a>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <pagination-controls class="float-right"
                            (pageChange)="pageChange($event); currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-management-report-line *ngIf="activeReportLineId$ | async" [selFromDate]="selFromDate" [selToDate]="selToDate" [reportBy]="reportBy"></app-management-report-line>