export interface AssignReviewCase {
    BDOSID: string | number,
    AssignedTo: string,
    AssignedComments: string
}


export function createAssignReviewCase(params: Partial<AssignReviewCase>) {
    return {
      ...params
    } as AssignReviewCase;
  }