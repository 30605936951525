<!-- Sidenav-->
<div class="bg-blur">
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Patient Detail</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="pb-3 form-fields">
            <h4 class="my-3"></h4>
            <div class="table-responsive data-list">
                <table class="table" id="dataTable" width="100%" cellspacing="0">
                    <tr>
                        <th>Name</th>
                        <td>Will Smith</td>
                    </tr>
                    <tr>
                        <th>Date Of Surgery</th>
                        <td>08/25/2020</td>
                    </tr>
                    <tr>
                        <th>MRN</th>
                        <td>11700</td>
                    </tr>
                    <tr>
                        <th>Billed</th>
                        <td>Yes</td>
                    </tr>
                    <tr>
                        <th>ID</th>
                        <td>737836</td>
                    </tr>
                    <tr>
                        <th>Attach</th>
                        <td><input type="file"></td>
                    </tr>
                    <tr>
                        <th>Select Required File Suffix Below</th>
                        <td><input type="checkbox" class="mr1">_OP
                            <input type="checkbox" class="mr1">_PATH
                            <input type="checkbox" class="mr1">_QUE
                            <input type="checkbox" class="mr1">_AOP</td>
                    </tr>
                </table>
            </div>

            <div class="text-right">
                <!-- <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav ">Cancel</button> -->
                <button type="button" (click)="onClickClose()" class="btn btn-primary btn-patient-box">Select</button>
            </div>
        </div>
    </div>
</div>
</div>
<!-- Sidenav-->