import { billerOnHold, codingExpert, authReview, na, returnedCodingRFI, priority, returned, unassigned, highApprovalInternal, overdue, pendingclient, operatingReport, pathology, codingQuery, pricing, highApproval, blockReport, variance, otherMisc, implantLog, invoice } from "src/app/shared/models/name-constant";
import { CategoryItem } from "src/app/shared/state/category-item/category-item.model";
import { CommandTypeConfig, createCommandTypeConfig } from "../dash-detail.types";

export class CommandTypeHelper {
  public static map: Map<string, CommandTypeConfig> = new Map([
    [billerOnHold.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: billerOnHold.serverValue,
      commandDisplayName: billerOnHold.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: "9",
      parentCommandId: na.id,
      hasChildren: true,
      childAccordianOrder: new Map([
        [returnedCodingRFI.id.toLowerCase(), 2],
        [codingExpert.id.toLowerCase(), 3],
        [authReview.id.toLowerCase(), 4]])
    })],
    [codingExpert.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: codingExpert.commandName,
      commandDisplayName: codingExpert.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: billerOnHold.id,
      hasChildren: false
    })],

    [authReview.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: authReview.commandName,
      commandDisplayName: authReview.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: billerOnHold.id,
      hasChildren: false
    })],

    [returnedCodingRFI.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: returnedCodingRFI.commandName,
      commandDisplayName: returnedCodingRFI.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: billerOnHold.id,
      hasChildren: false
    })],
    [priority.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: priority.serverValue,
      commandDisplayName: priority.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: "2",
      parentCommandId: na.id,
      hasChildren: true,
      childAccordianOrder: new Map([
        [returned.id.toLowerCase(), 1],
        [unassigned.id.toLowerCase(), 5],
        [highApprovalInternal.value.toLowerCase(), 7],
        [overdue.id.toLowerCase(), 14],
      ])
    })],
    [returned.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: returned.commandName,
      commandDisplayName: returned.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: priority.id,
      hasChildren: false
    })],
    [unassigned.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: unassigned.commandName,
      commandDisplayName: unassigned.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: priority.id,
      hasChildren: false
    })],
    [highApprovalInternal.value.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: highApprovalInternal.commandName,
      commandDisplayName: highApprovalInternal.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: priority.id,
      hasChildren: false
    })],
    [overdue.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: overdue.commandName,
      commandDisplayName: overdue.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: priority.id,
      hasChildren: false
    })],
    [pendingclient.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: pendingclient.serverValue,
      commandDisplayName: pendingclient.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: "3",
      parentCommandId: na.id,
      hasChildren: true,
      childAccordianOrder: new Map([
        [operatingReport.commandName.toLowerCase(), 1],
        [pathology.id.toLowerCase(), 5],
        [codingQuery.commandName.toLowerCase(), 9],
        [pricing.id.toLowerCase(), 14],
        [highApproval.commandName.toLowerCase(), 19],
        [blockReport.commandName.toLowerCase(), 24],
        [variance.id.toLowerCase(), 34],
        [otherMisc.id.toLowerCase(), 39],
        [implantLog.commandName.toLowerCase(), 45],
        [invoice.commandName, 49]
      ])
    })],
    [operatingReport.commandName.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: operatingReport.commandName,
      commandDisplayName: operatingReport.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [pathology.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: pathology.commandName,
      commandDisplayName: pathology.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [codingQuery.commandName.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: codingQuery.commandName,
      commandDisplayName: codingQuery.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [pricing.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: pricing.commandName,
      commandDisplayName: pricing.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [highApproval.commandName.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: highApproval.commandName,
      commandDisplayName: highApproval.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.value,
      hasChildren: false
    })],
    [blockReport.commandName.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: blockReport.commandName,
      commandDisplayName: blockReport.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [variance.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: variance.commandName,
      commandDisplayName: variance.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [otherMisc.id.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: otherMisc.commandName,
      commandDisplayName: otherMisc.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [implantLog.commandName.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: implantLog.commandName,
      commandDisplayName: implantLog.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
    [invoice.commandName.toLocaleLowerCase(), createCommandTypeConfig({
      commandName: invoice.commandName,
      commandDisplayName: invoice.value,
      apiName: "BillingNew/GetPatientsByStatusNew",
      apiBodyId: "FacilityQueueId",
      apiBodySearchId: "PatientName",
      bodyHasAssigned: true,
      childCatId: na.id,
      parentCommandId: pendingclient.id,
      hasChildren: false
    })],
  ]);

  public static getCommandNameFromConfigMap(cat: CategoryItem) {
    let commandId;
    console.log('getCommandNameFromConfigMap...', cat);
    if (!cat) {
      console.log("category  is undefined");
      return undefined;
    }
    if (!cat.value) {
      console.log("category value is undefined, cannot load child accordian please supply cat value which has child");
      return undefined;
    }
    const commandTypeConfig = CommandTypeHelper.map.get(cat.value.toLocaleLowerCase());
    if (!commandTypeConfig) {
      console.log("command type not defined, please add one for ", cat.value);
      return undefined;
    }

    if (commandTypeConfig.hasChildren) {
      commandId = commandTypeConfig.commandName;
    } else if (commandTypeConfig.parentCommandId !== na.id) {
      commandId = commandTypeConfig.parentCommandId;
    } else {
      console.log("command type niether has child nor has parent, so cannot form accordian", commandTypeConfig);
      return undefined;
    }
    return CommandTypeHelper.map.get(commandId.toLocaleLowerCase());
  }
}