<!-- Sidenav-->
<div class="bg-blur">
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Patient Detail</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="pb-3 form-fields">
            <div class="table-responsive data-list">
                <table class="table" id="dataTable" width="100%" cellspacing="0">
                    <tr>
                        <th style="width: 25%;">Received</th>
                        <td>{{this._formData?.fileScanned | date: "MM/dd/yyyy HH:mm a"}}</td>
                    </tr>
                    <tr>
                        <th>File Name</th>
                        <td>{{this._formData?.fileName}}</td>
                    </tr>
                    <tr>
                        <th>File Path</th>
                        <td>{{this._formData?.filePath}}</td>
                    </tr>
                    <tr>
                        <th>Patient Name</th>
                        <td>{{this._formData?.patientName}}</td>
                    </tr>
                    <tr>
                        <th>DoS</th>
                        <td>{{this._formData?.dateOfService | date: "MM/dd/yyyy"}}</td>
                    </tr>
                    <tr>
                        <th>MRN</th>
                        <td>{{this._formData?.mrn}}</td>
                    </tr>
                </table>
            </div>

            <h4 *ngIf="patientInfo.length == 0" class="my-3">No Matched Data</h4>

            <div *ngIf="patientInfo.length > 0">
                <div class="row">
                    <div class="col-md-6">
                        <h4 class="my-3">Matched Data</h4>
                    </div>
                    <div class="col-md-6 text-right">
                        <input id="searchPatient" type="text" [(ngModel)]="srchPatient" name="searchPatient"
                            placeholder="Search Patient">
                        <a href="javascript:void(0)" (click)="searchPatient()"><i class="fas fa-search"></i></a>
                    </div>
                </div>

                <div class="table-responsive data-list">
                    <!-- <table class="table" id="dataTable" width="100%" cellspacing="0">
                        <tr>
                            <th style="width: 25%;">Name</th>
                            <td>{{data?.name}}</td>
                        </tr>
                        <tr>
                            <th>Date Of Surgery</th>
                            <td>{{data?.dateOfSurgery | date:"MM/dd/yyyy"}}</td>
                        </tr>
                        <tr>
                            <th>MRN</th>
                            <td>{{data?.mrn}}</td>
                        </tr>
                        <tr>
                            <th>Billed</th>
                            <td>{{data?.billed}}</td>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <td>{{data?.bdosid}}</td>
                        </tr>
                        <tr *ngIf="reqSelect == 'OpNote Exception Updates'">
                            <th>Select Required File Suffix Below</th>
                            <td><input type="checkbox" value="_OP" (change)="chkFileSuffix($event)"
                                    [checked]="opFile == true" class="mr1">_OP
                                <input type="checkbox" value="_PATH" (change)="chkFileSuffix($event)"
                                    [checked]="pathFile == true" class="mr1">_PATH
                                <input type="checkbox" value="_QUE" (change)="chkFileSuffix($event)"
                                    [checked]="queFile == true" class="mr1">_QUE
                                <input type="checkbox" value="_AOP" (change)="chkFileSuffix($event)"
                                    [checked]="aopFile == true" class="mr1">_AOP</td>
                        </tr>
                        <tr>
                            <td class="text-danger" colspan="2"
                                *ngIf="reqSelect == 'OpNote Exception Updates' && fileError == true">
                                Please select a file suffix before attaching file!
                            </td>
                        </tr>
                    </table> -->

                    <div class="text-danger mb-4" *ngIf="reqSelect == 'OpNote Exception Updates' && fileError == true">
                        Please select a file suffix before attaching file!
                        <br>
                    </div>

                    <table class="table" id="dataTable" width="100%" cellspacing="0">
                        <thead>
                            <tr>
                                <th style="width: 15%;">Name</th>
                                <th>DoS</th>
                                <th>MRN</th>
                                <th>Billed</th>
                                <th>ID</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of patientInfo; let i = index;">
                                <td>{{data?.name}}</td>
                                <td>{{data?.dateOfSurgery | date:"MM/dd/yyyy"}}</td>
                                <td>{{data?.mrn}}</td>
                                <td>{{data?.billed}}</td>
                                <td>{{data?.bdosid}}</td>
                                <td><a href="javascript:void(0)" (click)="OpenModal(data)"
                                        class="icon-color-blue openMySidenav"><i title="Attach"
                                            class="fa fa-paperclip quickicn "></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="reqSelect == 'OpNote Exception Updates'">
                        <h4>Select Required File Suffix Below :</h4>
                        <input type="checkbox" value="_OP" (change)="chkFileSuffix($event)" [checked]="opFile == true"
                            class="mr1">_OP
                        <input type="checkbox" value="_PATH" (change)="chkFileSuffix($event)"
                            [checked]="pathFile == true" class="mr1">_PATH
                        <input type="checkbox" value="_QUE" (change)="chkFileSuffix($event)" [checked]="queFile == true"
                            class="mr1">_QUE
                        <input type="checkbox" value="_AOP" (change)="chkFileSuffix($event)" [checked]="aopFile == true"
                            class="mr1 mb-4">_AOP
                        <br>
                    </div>


                    <!-- <div class="col-md-12 text-right">
                        <button type="button" (click)="OpenModal(data)"
                        class="btn btn-primary btn-patient-box mb-3">Attach</button>
                       
                    </div> -->

                </div>
            </div>

            <div>
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav ">Cancel</button>
                <!-- <button type="button" (click)="onClickClose()" class="btn btn-primary btn-patient-box">Attach</button> -->
            </div>
        </div>
    </div>
</div>

<div id="attachModal" tabindex="-1" *ngIf="showModal == true" role="dialog" aria-labelledby="exampleModalLabel"
    class="modal fade show" style="display: block; padding-right: 16px;" aria-modal="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Attach File?</h5>
                <!-- <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button> -->
            </div>
            <div class="modal-body">
                <span *ngIf="reqSelect == 'OpNote Exceptions' || reqSelect == 'OpNote Exception Updates'">Attach file
                    and move to In Coding?</span>
                <span *ngIf="reqSelect == 'Coding Exceptions' || reqSelect == 'Coding Exception Updates'">Attach file
                    and move to Returned Coding?</span>
            </div>
            <div class="modal-footer">
                <button *ngIf="reqSelect == 'OpNote Exceptions' || reqSelect == 'OpNote Exception Updates'"
                    class="btn btn-primary" type="button" data-dismiss="modal" (click)="setSubmit('coding')">Send To
                    Coding</button>
                <button *ngIf="reqSelect == 'Coding Exceptions' || reqSelect == 'Coding Exception Updates'"
                    class="btn btn-primary" type="button" data-dismiss="modal" (click)="setSubmit('coding')">Return
                    Coding</button>
                <button *ngIf="reqSelect == 'OpNote Exceptions'" class="btn btn-primary" type="button"
                    (click)="setSubmit('patient')" data-dismiss="modal">H & P</button>
                <button *ngIf="reqSelect == 'OpNote Exception Updates'" class="btn btn-primary" type="button"
                    (click)="setSubmit('patient')" data-dismiss="modal">Attach Patient</button>
                <button class="btn btn-secondary" type="button" (click)="CloseModal()"
                    data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
</div>
</div>
<!-- Sidenav-->