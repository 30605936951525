<div
    class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
    <div class="row case-row m-0 font-weight-bold text-primary">
        <h4>{{ title }}</h4>
    </div>


</div>
<div class="card-body">
    <div class="centerText">

        <h5>{{ message }}</h5><br>
        <div class="text-right">

            <a href="javascript:void(0);" (click)="decline()" class="mr-2 btn btn-warning  closeMySidenav"
                id="closeMySidenav">
                {{ btnCancelText }}
            </a>
            <a href="javascript:void(0);" (click)="accept()" class="mr-2 btn btn-success closeMySidenav"
                id="closeMySidenav">
                {{ btnOkText }}
            </a>
        </div>

    </div>
</div>