import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportPatientsComponent } from '../import-patients/import-patients.component';
import { ImportPatientsFormComponent } from '../import-patients/import-patients-form/import-patients-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ImportPatientsComponent,
    ImportPatientsFormComponent
  ],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule
  ],
  exports: [
    ImportPatientsFormComponent
  ]
})
export class ImportPatientsModule { }
