import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SettingsRoutingModule } from './settings-routing.module';
import { EmailNotificationComponent } from './email-notification/email-notification.component';
import { EmailNotificationFormComponent } from './email-notification/email-notification-form/email-notification-form.component';


@NgModule({
  declarations: [EmailNotificationComponent, EmailNotificationFormComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    SettingsRoutingModule
  ]
})
export class SettingsModule { }
