import { Injectable } from "@angular/core";
import { BulkActionSelectionStore } from "./bulk-action-selection.store";

@Injectable({ providedIn: 'root'})
export class BulkActionSelectionService {
 constructor(private store: BulkActionSelectionStore){}

 changeSelection(state: string){
    this.store.update({ value: state });
 }
}