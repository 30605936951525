import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CaseQueueFacilityReportStore, CaseQueueFacilityReportState } from './case-queue-facility-report.store';

@Injectable({ providedIn: 'root' })
export class CaseQueueFacilityReportQuery extends QueryEntity<CaseQueueFacilityReportState> {

  constructor(protected store: CaseQueueFacilityReportStore) {
    super(store);
  }

}
