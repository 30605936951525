export interface Patient {
  mrn: string;
  totalcount: number;
  facilityId: number;
  client: string;
  name: string;
  dob: Date,
  dateOfSurgery: string;
  insuranceProvider: string;
  physician: string;
  primartCPT: string;
  codingReturned: number;
  opReport: number;
  pathology: number;
  pricing: number;
  high: number;
  nsnHigh: number;
  block: number;
  coding: number;
  inCoding: number;
  variance: number;
  authReview: number;
  returnedCodingRFI: number,
  overdue: number,
  overdue1: number,
  codingExpert: number,
  otherMisc: number,
  returned: number,
  bdosid: number;
  timeCol: string,
  oldCase: number;
  queueName: string;
  approved: number;
  lastNote: string;
  returnedFor: string;
  status?: string;
}

export function createPatient(params: Partial<Patient>) {
  return {
    ...params
  } as Patient;
}
