import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { values } from 'cypress/types/lodash';
import { ToastrService } from 'ngx-toastr';
import { AttachmentQuery } from 'src/app/shared/components/attachment/state/attachment.query';
import { AttachmentService } from 'src/app/shared/components/attachment/state/attachment.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { Patient } from 'src/app/shared/state/patient/patient.model';

@Component({
  selector: 'app-return-mail',
  templateUrl: './return-mail.component.html',
  styleUrls: ['./return-mail.component.css']
})
export class ReturnMailComponent implements OnInit {
  @Output() onSubmitSuccess = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  _formData:any;
  comment:string;
 

  

  @Input() set formData(value: any) {
    if (value) {
     
      this._formData = value;
    }
    console.log("this.formdata",this._formData)
  }
  returnedMailForm:FormGroup;
  activePatient;
  callerId: string = "returnMail";
  
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private attachmentService: AttachmentService,
    private attachmentQuery: AttachmentQuery
    ) { }
    
    

  ngOnInit(): void {
    this.initForm();
    this.activePatient = this._formData
    
  }

  get f() { return this.returnedMailForm.controls; }
  

  initForm() {
    this.returnedMailForm = this.fb.group({
     
    })
  }

  commentData(value:any){
    console.log("comment",value)
    this.comment = value
    return value;
 }

  onClickClose(){
    this.onCancel.emit();
  }

  uploadAttachment() {

    this.attachmentQuery.selectEntity(e => e.callerId === this.callerId).subscribe(ares => {
      if(!ares)
         return;
      let frmData = new FormData();
      // frmData.append("comment", this.comment);
      // console.log('comments' +this.comment);

      if(this.activePatient.bdosid){
        frmData.append("BdosID", this.activePatient.bdosid.toString());
      }else if(this.activePatient.dosId){
        frmData.append("BdosID", this.activePatient.dosId.toString());
      }

      if(this.activePatient.facilityId){
        frmData.append("facilityId", this.activePatient.facilityId);
      }else if(this.activePatient.facilityID){
        frmData.append("facilityId", this.activePatient.facilityID);
      }

     // frmData.append("BdosID", this.activePatient.bdosid.toString());
     // frmData.append("facilityId", this.activePatient.facilityId);
     
      
     frmData.append("comments", this.comment);
      frmData.append("FileAttachment[0].file", ares.file);
      frmData.append("FileAttachment[0].fileName", ares.id.toString());
      console.log(this.comment);
      this.getApiService.uploadFiles("BillingNew/SaveReturnClaim", frmData).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success("Uploaded Successfully!!", "Success");
         this.attachmentService.remove(ares.id);
         this.onSubmitSuccess.emit();
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
  });
  }

  
  

}
