<!-- <app-side-nav></app-side-nav> -->
<!--  Content Wrapper -->
<!-- <div id="content-wrapper" class="d-flex flex-column">

    <div id="content">
        <app-nav-bar></app-nav-bar> -->
<!-- <router-outlet></router-outlet> -->
<!-- </div>
</div> -->

<!-- <footer></footer> -->
<app-page-content></app-page-content>