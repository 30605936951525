import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { NsnGrid } from './nsn-grid.model';
import { NsnGridStore } from './nsn-grid.store';

@Injectable({ providedIn: 'root' })
export class NsnGridService {

  constructor(private nsnGridStore: NsnGridStore, private http: HttpClient) {
  }


  set(entities) {
    this.nsnGridStore.set(entities);
  }

  add(nsnGrid: NsnGrid) {
    this.nsnGridStore.add(nsnGrid);
  }

  update(id, nsnGrid: Partial<NsnGrid>) {
    this.nsnGridStore.update(id, nsnGrid);
  }

  remove(id: ID) {
    this.nsnGridStore.remove(id);
  }

  addActive(id: ID){
    this.nsnGridStore.addActive(id);
  }

  removeActive(id: ID){
    this.nsnGridStore.removeActive(id);
  }
}
