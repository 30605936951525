import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { authReview, blockReport, clientRole, codingExpert, codingQuery, highApproval, highApprovalInternal, implantLog, inCoding, invoice, operatingReport, otherMisc, pathology, pending, pricing, variance } from 'src/app/shared/models/name-constant';
import { CaseRequestHelper } from '../helper/case-request.helper';
import { CaseRequest } from '../state/case-request/case-request.model';
import { CaseRequestQuery } from '../state/case-request/case-request.query';
import { CaseRequestService } from '../state/case-request/case-request.service';
import { CurrentCaseRequestQuery } from '../state/current-case-request/current-case-request.query';
import { createTab } from '../state/tabs/tab.model';
import { TabsService } from '../state/tabs/tabs.service';

@Component({
  selector: 'app-case-request-selection',
  templateUrl: './case-request-selection.component.html',
  styleUrls: ['./case-request-selection.component.css']
})
export class CaseRequestSelectionComponent implements OnInit, OnDestroy {
  crHighApproval = highApproval;
  crHighApprovalInternal = highApprovalInternal;
  inCoding = inCoding;
  codingExpert = codingExpert;
  authReview = authReview;
  crVariance = variance;
  caseRequestConfig;
  clientCaseRequest$: Observable<CaseRequest[]>;
  nsnCaseRequest$: Observable<CaseRequest[]>;
  private caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  highApprovalStatus$: Observable<String>;
  highApprovalInternalStatus$: Observable<String>;
  varianceStatus$: Observable<String>;
  hasActiveInCoding$: Observable<boolean>;
  hasActiveAuthReview$: Observable<boolean>;
  hasActiveCodingExpert$: Observable<boolean>;
  constructor(
    private caseRequestQuery: CaseRequestQuery,
    private caseRequestService: CaseRequestService,
    private tabsService: TabsService,
    private currentCaseRequestQuery: CurrentCaseRequestQuery
  ) {
    this.caseRequestConfig = this.caseRequestDropdownConfig();
    this.highApprovalStatus$ = this.caseRequestQuery.selectCptCaseRequestStatus(highApproval.id);
    this.highApprovalInternalStatus$ = this.caseRequestQuery.selectCptCaseRequestStatus(highApprovalInternal.id);
    this.varianceStatus$ = this.caseRequestQuery.selectCptCaseRequestStatus(variance.id);
  }

  ngOnInit(): void {
    this.caseRequestService.init();
    this.clientCaseRequest$ = this.caseRequestQuery.selectClientCaseRequest(); 
  this.clientCaseRequest$.subscribe(res=>{
      console.log(res,"client 51")
    })
    this.nsnCaseRequest$ = this.caseRequestQuery.selectNSNCaseRequest();
    this.hasActiveInCoding$ = this.caseRequestQuery.selectActive().pipe(map(res => res.find(r => r.id === inCoding.id && r.alreadySubmitted ) ? true : false ));
    this.hasActiveAuthReview$ = this.caseRequestQuery.selectActive().pipe(map(res => res.find(r => r.id === authReview.id && r.alreadySubmitted ) ? true : false ));
    this.hasActiveCodingExpert$ = this.caseRequestQuery.selectActive().pipe(map(res => res.find(r => r.id === codingExpert.id && r.alreadySubmitted ) ? true : false ));
    // this.hasActiveAuthReview$.subscribe(res => {
    //   console.log(res);
    // });
    this.nsnCaseRequest$.subscribe(res => {
      console.log(res,"nsncaserequest");
    });
    
  }

  ngOnDestroy() {
    this.caseRequestService.reset();
  }
  // case request methods
  isCaseRequestSelected(cr: CaseRequest): boolean {
    const isActive = this.caseRequestQuery.hasActive(cr.id);
    return isActive;
  }

  isCaseRequestDisabled(cr: CaseRequest): boolean{
    return  this.currentCaseRequestQuery.hasEntity(cr.id) || cr.alreadySubmitted;
  }
  caseRequestSelected(cr: CaseRequest) {
    if(this.isCaseRequestDisabled(cr)){
      return;
    }

    const isActive = this.caseRequestQuery.hasActive(cr.id);
    this.caseRequestService.toggleActive(cr.id);
    if (isActive) {
      this.tabsService.remove(cr.id);
    } else {
      this.tabsService.add(createTab({ 
        id: cr.id, 
        value: cr.value, 
        displayName: cr.displayName, href: '#' + cr.id, 
        type: this.caseRequestHelper.getRequstType(cr.id),
        note: ""
      }));
      this.tabsService.setActive(cr.id);
    }
  }

  pendingCaseRequestSelected(cr: CaseRequest){
    this.tabsService.add(createTab({ 
      id: cr.id, 
      value: cr.value, 
      displayName: cr.displayName, href: '#' + cr.id, 
      type: this.caseRequestHelper.getRequstType(cr.id),
      note: ""
    }));
    this.tabsService.setActive(cr.id);
  }
  

  private caseRequestDropdownConfig() {
    return {
      displayKey: "value", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '16rem', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: "id", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }

}
