import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Attachment, createAttachment } from 'src/app/shared/components/attachment/state/attachment.model';
import { AttachmentService } from 'src/app/shared/components/attachment/state/attachment.service';
import { NameConstant, highApprovalInternal, highApproval, bulkAction } from 'src/app/shared/models/name-constant';
import { BulkActionSelectionService } from '../../../bulk-action/state/bulk-action-selection/bulk-action-selection.service';
import { CaseRequestHelper } from '../helper/case-request.helper';
import { CaseRequestCptService } from '../state/case-request-cpt/case-request-cpt.service';
import { CaseRequestInfoQuery } from '../state/case-request-info/case-request-info.query';
import { CaseRequestService } from '../state/case-request/case-request.service';
import { createCurrentCaseRequest } from '../state/current-case-request/current-case-request.model';
import { CurrentCaseRequestQuery } from '../state/current-case-request/current-case-request.query';
import { CurrentCaseRequestService } from '../state/current-case-request/current-case-request.service';
import { TabsQuery } from '../state/tabs/tabs.query';
import { TabsService } from '../state/tabs/tabs.service';
import * as fileSaver from 'file-saver';
import { AttachmentQuery } from 'src/app/shared/components/attachment/state/attachment.query';
import { SessionQuery } from 'src/app/modules/login/session/session.query';
import { CommentsQuery } from 'src/app/shared/state/comments/comments.query';
import { CaseRequestCptQuery } from '../state/case-request-cpt/case-request-cpt.query';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-cr-nsn-high',
  templateUrl: './cr-nsn-high.component.html',
  styleUrls: ['./cr-nsn-high.component.css']
})
export class CrNsnHighComponent implements OnInit, OnDestroy {
  @Input() callerId: string;
  @Input() bdosid: string | number;
  isAttachedFileVisible: boolean = false;
  componentName: NameConstant   = highApprovalInternal;
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  comment: string =  '';
  file;
  fileName: string;
  isDisabled$: Observable<boolean>;
  isRequestAlreadySubmitted$: Observable<boolean>;

  sub: Subscription;
  privileges:any;
  constructor(
    private tabsService: TabsService,

    private tabsQuery: TabsQuery,
    private caseRequestService: CaseRequestService,
    private currentCaseRequestService: CurrentCaseRequestService,
    private currentCaseRequestQuery: CurrentCaseRequestQuery,
    private caseRequestCptService: CaseRequestCptService,
    private caseRequestCpttQuery: CaseRequestCptQuery,
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private bulkActionSelectionService: BulkActionSelectionService,
    private attachmentService: AttachmentService,
    private attachmentQuery: AttachmentQuery,
    private toastr: ToastrService,
    private sessionQuery: SessionQuery,
    private commentQuery: CommentsQuery,
    private accessServices :AccessService,
  ) { }

  ngOnInit(): void {
    this.privileges=this.accessServices?.navbar_access
    // console.log(this.privileges,"in nsn high")
    this.loadComment();
    this.isDisabled$ = this.currentCaseRequestQuery.selectAll({
      filterBy: entity => entity.id === highApproval.id
    }).pipe(map(res => res && res.length > 0 ));

    this.isRequestAlreadySubmitted$ = this.currentCaseRequestQuery.selectEntity(this.componentName.id)
    .pipe(map(res => {
      if(res === undefined){
        return false;
      }else{
        return res.alreadySubmitted;
      }
    } ));
  }

  private loadComment(){
    if(this.isBulkAction()){
      if(!this.bdosid){
        this.toastr.error("Unexpected error!! unable to determine the bdosid");
      }
      const attchment: Attachment = this.attachmentQuery.getEntity(this.bdosid);
      const globalComment = this.commentQuery.getGlobalComment();
      if(attchment && attchment.fileName){
        this.fileName = attchment.fileName;
        this.file = attchment.file;
        this.isAttachedFileVisible = true;
      }
      if(attchment && attchment.comments){
        this.comment = attchment.comments;
       
      }else if(globalComment){
        this.comment = globalComment;
      } else{
        this.defaultComment();
      }
    }else{
      this.defaultComment();
    }
  }

  private defaultComment(){
    this.sub =  this.tabsQuery.selectActive().subscribe(activeTab => {
      this.comment = activeTab?.note;
    });
  }

  ngOnDestroy(){
    if(this.sub){
      console.log('Unubscribing the subscriptions');
      this.sub.unsubscribe();
    }
  }

  removeTab(){
    if(this.isBulkAction()){
      this.handleBulkActionCancel();
      return;
    }
    this.tabsService.remove(this.componentName.id);
    this.caseRequestService.toggleActive(this.componentName.id);
    this.caseRequestCptService.removeAllByRequestId(this.componentName.id);
  }

  addRequestToCurrentState(){
    if(this.isBulkAction()){
      this.handleBulkActionQueue();
      return;
    }

    const cptList = this.caseRequestCpttQuery.findCptByComponentId(this.componentName.id);
    if(!cptList || cptList.length === 0){
      this.toastr.warning("please add cpt details before queueing it");
      return;
    }
    const currentRequest = this.currentCaseRequestQuery.getEntity(this.componentName.id);
    const alSub = this.currentCaseRequestQuery.hasEntity(this.componentName.id);
    const isModified = currentRequest ? true : false;
    if(alSub){
      this.currentCaseRequestService.remove(this.componentName.id);
    }

    const currentCaseRequest = createCurrentCaseRequest({ 
      id: this.componentName.id, 
      value: this.componentName.value, 
      type: this.caseRequestHelper.getRequstType(this.componentName.id),
      comment: this.comment,
      file: this.file,
      fileName: this.fileName,
      alreadySubmitted: alSub,
      isModified: isModified
    });

    this.currentCaseRequestService.add(currentCaseRequest);
    this.tabsService.remove(this.componentName.id);
  }

  handleBulkActionQueue(){
    const bdosid = this.caseRequestInfoQuery.getBdosid();
    if(bdosid === undefined){
      this.toastr.error("could not evaluate bdosid");
      return;
    }
    const attachment = this.attachmentQuery.getEntity(bdosid);
    if (attachment) {
      // attachment already exists
      if (this.fileName) {
        // attachment exists and new file is attached
        this.attachmentService.remove(bdosid);
        this.createAndAddAttacmentToStore();
      } else {
        // attachment exists and new file is not attached so just sync the comments in component and in store
        this.attachmentService.update(bdosid, { comments: this.comment });
      }
    } else {
      // first time adding comment or file
      this.createAndAddAttacmentToStore();
    }
    this.bulkActionSelectionService.changeSelection("");
  }

  private createAndAddAttacmentToStore() {
    const attchmentDeatil = createAttachment({
      id: this.bdosid,
      bdosid: Number(this.bdosid),
      comments: this.comment,
      fileName: this.fileName,
      file: this.file
    })
    this.attachmentService.add(attchmentDeatil);
  }
  
  handleBulkActionCancel(){
    const bdosid = this.caseRequestInfoQuery.getBdosid();
    this.attachmentService.remove(bdosid);
    this.caseRequestCptService.removeAllByRequestIdAndPatientId(this.componentName.id, bdosid);
    this.bulkActionSelectionService.changeSelection("");
  }
  
  fileChanged(event) {
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    console.log("file changed to ", this.file);
  }

  rejectRequest(){
    const currentRequest = this.currentCaseRequestQuery.getEntity(this.componentName.id);
    const prevSub = currentRequest ? currentRequest.alreadySubmitted : false;
    const isModified = currentRequest ? true : false;
    
    const currentCaseRequest = createCurrentCaseRequest({ 
      id: this.componentName.id, 
      value: this.componentName.value, 
      type: this.caseRequestHelper.getRequstType(this.componentName.id),
      comment: this.comment,
      file: this.file,
      fileName: this.fileName,
      alreadySubmitted: prevSub,
      isModified: isModified,
      isApproved: false
    });
    this.currentCaseRequestService.remove(this.componentName.id);
    this.currentCaseRequestService.add(currentCaseRequest);
    this.tabsService.remove(this.componentName.id);
  }

  approveRequest(){
    const currentRequest = this.currentCaseRequestQuery.getEntity(this.componentName.id);
    const prevSub = currentRequest ? currentRequest.alreadySubmitted : false;
    const isModified = currentRequest ? true : false;
    
    const currentCaseRequest = createCurrentCaseRequest({ 
      id: this.componentName.id, 
      value: this.componentName.value, 
      type: this.caseRequestHelper.getRequstType(this.componentName.id),
      comment: this.comment,
      file: this.file,
      fileName: this.fileName,
      alreadySubmitted: prevSub,
      isModified: isModified,
      isApproved: true
    });
    this.currentCaseRequestService.remove(this.componentName.id);
    this.currentCaseRequestService.add(currentCaseRequest);
    this.tabsService.remove(this.componentName.id);
  }

  downloadFile(){
    let blob:any = new Blob([this.file], { type: 'text/json; charset=utf-8' });
			fileSaver.saveAs(blob, this.fileName);
  }
  private isBulkAction(): boolean{
    return this.callerId === bulkAction.id;
  }
}
