import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { createBillReason } from '../../state/bill-reason.model';
import { BillReasonService } from '../../state/bill-reason.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { BillReasonQuery } from '../../state/bill-reason.query';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
@Component({
  selector: 'app-bill-reason-add-form',
  templateUrl: './bill-reason-add-form.component.html',
  styleUrls: ['./bill-reason-add-form.component.css'],
  providers: [ NgxSpinnerService ]
})
export class BillReasonFormComponent implements OnInit {
  formSelection;
  config = this.initialiseDropDownConfig('userName');
  BillReasonForm: FormGroup;
  activeRow;
  submitted: boolean = false;
  submit:any="Submit"
  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private billReasonService: BillReasonService,
    private selectionService: SelectionService,
    private selectionQuery: SelectionQuery,
    private billReasonQuery: BillReasonQuery
    ) { }

  ngOnInit(): void {
    if(this.selectionQuery.getValue().value === 'edit'){
      this.formSelection = "Edit";
      this.activeRow = this.billReasonQuery.getActive();
      this.editForm();
    }else{
      this.formSelection = "Add";
      this.activeRow = undefined;
      this.initForm();
    }
  }

 
  initForm() {
    this.BillReasonForm = this.fb.group({
      reason: ["", Validators.required]
    });
  }

  editForm() {
    this.BillReasonForm = this.fb.group({
      reason: [this.activeRow.reason, Validators.required]
    });
    //this.BillReasonForm.controls["reason"].setValue(this.activeRow.reason);
  }

  onSubmit(){
    this.submitted = true;
   
    if(!this.BillReasonForm.valid){
      this.toastr.error("Please correct errors");
      this.submit="Submit"
      return;
    }
    else{
      this.submit="Submiting"
    }
    const billingReason = createBillReason({ reason: this.BillReasonForm.controls["reason"].value, id: this.activeRow?.id });
    this.billReasonService.add(billingReason);
  }

  onClickClose(){
    this.selectionService.changeSelection("");
    this.activeRow = undefined;
    this.billReasonService.inactive();
  }
  get f() { return this.BillReasonForm.controls; }

  initialiseDropDownConfig(drpDownFor: string){
    return {
      displayKey: drpDownFor, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: drpDownFor, // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }

 
}
