<!-- Begin Page Content -->
<div class="container-fluid width-index">
   <div class="container1">
      <!-- Daily Progress-->
      <div class="row daily-progress">
         <div class="col-md-4">
            <div class="border-progress">
               <p class="progress-text">Daily Progress</p>
               <!-- <img src="assets/img/dashboard-Progress-logo.png" alt="" class="dashboard-logo-side"> -->
               <p class="progress-bar-text">Cases Billed</p>
               <div class="progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"
                     style="width:90%">
                     <span class="sr-only">85% Complete</span>
                  </div>
               </div>
               <p class="progress-bar-text-second">Billed Amount</p>
               <div class="progress">
                  <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="70" aria-valuemin="0"
                     aria-valuemax="100" style="width:70%">
                     <span class="sr-only">70% Complete</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-8">
            <p class="progress-text-right">
               Monthly Performance
               <select class="dashboard-calnedar1 mr-1" [(ngModel)]="monthNumber" (change)="getDashboardList()">
                  <option selected>Month</option>
                  <option value="1">January</option>
                  <option value="2">February</option>
                  <option value="3">March</option>
                  <option value="4">April</option>
                  <option value="5">May</option>
                  <option value="6">June</option>
                  <option value="7">July</option>
                  <option value="8">August</option>
                  <option value="9">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
               </select>
               <select class="dashboard-calnedar2" [(ngModel)]="yearNumber" (change)="getDashboardList()">
                  <option selected>Year</option>
                  <option value="2020">2020</option>
                  <option value="2019">2019</option>
                  <option value="2018">2018</option>
                  <option value="2017">2017</option>
                  <option value="2016">2016</option>
                  <option value="2015">2015</option>
                  <option value="2014">2014</option>
                  <option value="2013">2013</option>
                  <option value="2012">2012</option>
                  <option value="2011">2011</option>
                  <option value="2010">2010</option>
                  <option value="2009">2009</option>
               </select>
               <span class="progress-text-date">{{monthName}} 1st - {{monthName}} {{endDate}}</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-star fa-color-blue"></i>{{totalPriorityItems}}
               <span class="monthly-progress-box1">Priority Items</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>{{totalActionItems}}
               <span class="monthly-progress-box1">Action Items</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-file-invoice fa-color-blue"></i>0
               <span class="monthly-progress-box1">Cases Billed</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-calendar-alt fa-color-blue"></i>0
               <span class="monthly-progress-box1">Avg Days to Bill</span>
            </p>
            <p class="monthly-icon-text monthly-icon-text-billed"><i
                  class="fa fa-file-invoice-dollar fa-color-blue"></i>$0
               <span class="monthly-progress-box1">Total Billed</span>
            </p>
         </div>
      </div>
      <!-- daily Progreess End-->
   </div>
   <!-- container end-->
   <!-- /.container-fluid -->
</div>

<div *ngFor="let d of DashboardArry; let i = index">
   <!-- Sugery Center Section-->
   <div class="container-fluid width-index cont-mrg">
      <div class="container1">
         <div class="row advance-surgery-center">
            <div class="col-md-4 surgery-row">
               <p class="advance-section-text">{{d.clients}}</p>
            </div>
            <div class="col-md-8" style="padding: 0px;">
               <p class="advance-icon-text"><i
                     class="fa fa-star fa-color-blue"></i>{{DashboardHeaderArry[i].priorityItems}}
                  <span class="advance-progress-box1">Priority Items</span>
               </p>
               <p class="advance-icon-text"><i
                     class="fa fa-list-alt fa-color-blue"></i>{{DashboardHeaderArry[i].actionitems}}
                  <span class="advance-progress-box1">Action Items</span>
               </p>
               <p class="advance-icon-text">$0
                  <span class="advance-billed-box1">Total Billed</span>
               </p>
               <div class="adv-progress-bar">
                  <p class="progress-bar-text-second wdth-advance">Billed Today</p>
                  <div class="progress">
                     <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="80" aria-valuemin="0"
                        aria-valuemax="100" style="width:80%">
                        <span class="sr-only">70% Complete</span>
                     </div>
                  </div>
                  <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">{{eom}}</span> Days Untill
                     EOM</p>
               </div>
               <p class="advance-icon-text price-box1 ">
                  <span class="surgety-number mr-1">$0</span>
                  <span class="spn-goaltext">Goal</span> <br>
                  <span class="surgety-number mr-1">$0</span>
                  <span class="spn-goaltext">Projected</span>
               </p>
               <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,i)"><i
                     *ngIf="selectedIndex != i" class="fa fa-plus"></i>
                  <i *ngIf="selectedIndex == i" class="fa fa-minus"></i>
               </p>
            </div>
            <!-- </div> -->
            <!-- Sugery Center Section End-->
         </div>

         <!-- container fluid advcance secttion end-->
         <!-- Facility Action Section-->
         <!-- <div class="container-fluid" > -->
         <!-- <div class="container1"> -->
         <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == i">
            <div class="col-md-2 pdng-widget">

               <p class="widget-text-sub-heading">{{d.clients}}</p>
               <!-- <p class="widget-text-sub-heading">Advanced Surgery Center <br/>of Metairie</p> -->
               <p class="widget-text-list"><i class="fa fa-user-plus icon-color-blue mr-1"></i> <span
                     class="widget-icon-txt-list"><a routerLink="/patient">Create Patient</a></span>
               </p>
               <p class="widget-text-list">
                  <!-- <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/ExceptionQueue"> -->
                  <i class="fa fa-exclamation-circle icon-color-blue mr-1"></i> <span
                     class="widget-icon-txt-list">Exception Queue</span> <span
                     class="widget-text-spn">{{d.exceptions}}</span>
                  <!-- </a> -->
               </p>
               <p class="widget-text-list">
                  <a routerLink="/d/eodMaintenance">
                     <!-- <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/EODQueue"> -->
                     <i class="fa fa-stopwatch icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list">EOD</span>
                     <span class="widget-text-spn" style="padding-left: 12px;">{{d.eoD}}</span>
                  </a>
               </p>
               <p class="widget-text-list">
                  <!-- <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/FollowupQueue"> -->
                  <i class="fa fa-headset icon-color-blue mr-1"></i> <span class="widget-icon-txt-list">Claim Follow
                     up</span> <span class="widget-text-spn">{{d.followUp}}</span>
                  <!-- </a> -->
               </p>
            </div>
            <div class="col-md-3 pdng-widget1">
               <p class="widget-text-heading">Pending</p>
               <p class="widget-text-sub-heading"> Case Total: {{d.nsnActionTotal + d.unassigned + d.new}} </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Unassigned"><i
                        class="fa fa-concierge-bell icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Unassigned</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.unassigned}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/InCoding"><i
                        class="fa fa-code icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list widget-icon-txt-list1">In Coding</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.inCoding}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Overdue"><i
                        class="fa fa-calendar icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list widget-icon-txt-list1">Overdue Coding</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.overdue}}</span></a></p>
               <p class="widget-text-list widget-text-list1"><a
                     routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/CodingReturned"><i
                        class="fa fa-undo-alt icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list widget-icon-txt-list1">Returned Coding</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.codingReturned}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/ReturnedCodingRFI">
                     <i class="fa fa-history icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list widget-icon-txt-list1">Coding RFI</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.returnedCodingRFI}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/NSN High$$$">
                     <i class="fa fa-dollar-sign icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1"> High$$$</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.highApprovalInternal}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/AuthReview">
                     <i class="fa fa-user-check icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list widget-icon-txt-list1">Auth Review</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.authReview}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/CodingExpert">
                     <i class="fa fa-chess-queen icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Coding Expert</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.codingExpert}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/BAQ">
                     <i class="fa fa-hand-paper icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list widget-icon-txt-list1">B.A.Q.</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.baq}}</span></a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Returned">
                     <i class="fa fa-caret-square-left icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Returned</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.returned}}</span></a></p>
            </div>
            <div class="col-md-3 pdng-widget1">
               <p class="widget-text-heading">Pending Clients</p>
               <p class="widget-text-sub-heading">RFI Case Total: {{d.clientActionTotal}}</p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/OpReport"><i
                        class="fa fa-envelope-open-text icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Op Note</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.opReport}}</span>
                  </a>
               </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Pathology">
                     <i class="fa fa-clipboard-check icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Path</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.pathology}}</span>
                  </a>
               </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Coding">
                     <i class="fa fa-laptop-code icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Coding</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.coding}}</span>
                  </a>
               </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Pricing">
                     <i class="fa fa-file-invoice-dollar icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Pricing</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.pricing}}</span>
                  </a>
               </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Client High$$$">
                     <i class="fa fa-dollar-sign icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">High$$$</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.high$$$}}</span>
                  </a>
               </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Block"><i
                        class="fa fa-stop-circle icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Block</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.block}}</span>
                  </a></p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Variance"><i
                        class="fa fa-exclamation-triangle icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Variance</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.variance}}</span>
                  </a>
               </p>
               <p class="widget-text-list widget-text-list1">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/OtherMisc">
                     <i class="fa fa-info-circle icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list widget-icon-txt-list1">Other/Misc</span> <span
                        class="widget-text-spn widget-text-spn1">{{d.otherMisc}}</span>
                  </a></p>
            </div>
            <div class="col-md-2 pdng-widget">
               <p class="widget-text-heading">Pending Cases</p>
               <p class="widget-text-sub-heading">Pending: {{d.allPending}}</p>
               <p class="widget-text-list">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/New">
                     <i class="fa fa-hourglass-start icon-color-blue mr-1"></i> <span class="widget-icon-txt-list">
                        < 48 Hr</span> <span class="widget-text-spn" style="padding-left:16px;">{{d.new}}</span>
                  </a></p>
               <p class="widget-text-list">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/ClientActionTotal">
                     <i class="fa fa-user-clock icon-color-blue mr-1"></i> <span class="widget-icon-txt-list">Pending
                        Client</span> <span class="widget-text-spn">{{d.clientActionTotal}}</span>
                  </a></p>
               <p class="widget-text-list">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/NSNActionTotal">
                     <i class="fa fa-business-time icon-color-blue mr-1"></i> <span class="widget-icon-txt-list">Pending
                        </span> <span class="widget-text-spn">{{d.nsnActionTotal}}</span>
                  </a></p>

            </div>
            <div class="col-md-2 pdng-widget2">
               <p class="widget-text-heading">Summary</p>
               <p class="widget-text-sub-heading">MTD Summary: {{d.total}}</p>
               <p class="widget-text-list">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/ThisMonth"> <i
                        class="fa fa-calendar-check icon-color-blue mr-1"></i>
                     <span class="widget-icon-txt-list">{{monthName}} Cases</span>
                     <span class="widget-text-spn">{{d.thisMonth - d.cancelled}}</span></a>
               </p>
               <p class="widget-text-list">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Cancelled">
                     <i class="fa fa-window-close icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list">Cancelled</span> <span
                        class="widget-text-spn">{{d.cancelled}}</span>
                  </a>
               </p>
               <p class="widget-text-list">
                  <a routerLink="/d/currentmonthcases/{{DashboardClientArry[i].id}}/Completed">
                     <i class="fa fa-thumbs-up icon-color-blue mr-1"></i> <span
                        class="widget-icon-txt-list">Completed</span> <span
                        class="widget-text-spn">{{d.completed}}</span>
                  </a>
               </p>
            </div>
         </div>
         <!-- container end-->
      </div>
   </div>
</div>



<!-- Sugery Center Section End-->
<div style="height: 50px;"></div>