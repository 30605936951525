import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { Attachment } from '../state/attachment.model';
import { AttachmentQuery } from '../state/attachment.query';
import { saveAs } from 'file-saver';
import { NameConstant, returnMail, caseRequest } from 'src/app/shared/models/name-constant';
import { FileHistoryInfo } from 'src/app/modules/billing-dashboard/patient-action/case-request/state/case-request-info/case-request-info.model';
import { CaseRequestInfoQuery } from 'src/app/modules/billing-dashboard/patient-action/case-request/state/case-request-info/case-request-info.query';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-show-attachment',
  templateUrl: './show-attachment.component.html',
  styleUrls: ['./show-attachment.component.css']
})
export class ShowAttachmentComponent implements OnInit {
  @Input() label: string;
  @Input() callerId: NameConstant;
  returnMail: NameConstant = returnMail;
  caseRequest: NameConstant = caseRequest;
  attachments$: Observable<Attachment[]>;
  fileHistoryInfo$ : Observable<FileHistoryInfo[]>;
  firsFileHistoryInfo$ : Observable<FileHistoryInfo>;
  expandCaseRequest: boolean = false;

  constructor( 
    private attachmentQuery: AttachmentQuery,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private caseRequestInfoQuery: CaseRequestInfoQuery
    
  ) { }

  ngOnInit(): void {
    if(this.callerId === returnMail){
      this.attachments$ = this.attachmentQuery.selectAll();
    }else if(this.callerId === caseRequest){
      this.fileHistoryInfo$ = this.caseRequestInfoQuery.getCaseRequestHistoryWhenContentFileNameIsPresent();
      this.firsFileHistoryInfo$ = this.fileHistoryInfo$.pipe(map(res => res && res[0]));
    }
  }

  getFile(fileId, fileName) {
    let data = { "FileId": fileId };
    const api = this.callerId === returnMail ? "BillingNew/DownloadBillingFile": "BillingNew/DownloadFile";
    this.getApiService.post4File(api, data).subscribe(res => {
      saveAs(res, fileName);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showComments(){
    switch(this.callerId){
      case returnMail: return false; 
    }
    return false;
  }

  showFileType(){
    switch(this.callerId){
      case returnMail: return false; 
    }
    return false;
  }

  showAttachmentType(){
    switch(this.callerId){
      case returnMail: return true; 
    }
    return false;
  }

  toggleExpandCaseRequest(){
    this.expandCaseRequest = this.expandCaseRequest ? false : true;
  }
}
