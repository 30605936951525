import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { UserProductivityStore, UserProductivityState } from './user-productivity.store';

@Injectable({ providedIn: 'root' })
export class UserProductivityQuery extends QueryEntity<UserProductivityState> {

  constructor(protected store: UserProductivityStore) {
    super(store);
  }

}
