<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
       <app-patient-info-header (closeEmitter)="onClickClose()"></app-patient-info-header>
        <div class="card-body pb-3 form-fields">
            <div class="row">
                <div class="col-md-6">
                    <h6 class="comment-heading-case">Follow Up Date</h6>
                    
                </div>
                <div class="col-md-6">
                    <h6 class="comment-heading-case">Comment</h6>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <input type="text" class="form-control" #dp="bsDatepicker" 
                    bsDatepicker
                    placeholder="Follow up date"
                    [bsConfig]="{ showWeekNumbers:false }" 
                    [minDate]="minDate"
                    [(bsValue)]="followupDate" 
                    >
                </div>
                <div class="col-md-6">
                    <textarea [(ngModel)]="comment" class="form-control" style="margin-right: 10px;height: 65px;">
                    </textarea>
                    <div *ngIf="submitted && !comment" class="invalid-feedback">
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="text-right action-row-btns-right">
                    <button type="button" (click)="onClickClose()"
                      class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                    <button type="button" (click)="addRecord()" [disabled] = "!comment || !followupDate" class="btn btn-primary btn-patient-box">Submit</button>
                  </div>
            </div>
        </div>
    
        <div class="container">
              <div class="table-responsive data-list" style="margin-top: 5px;">
                <h6 class="comment-heading-case">Follow Up History</h6>
            <table class="table" id="dataTable" width="100%" cellspacing="0">
                <thead >
                  <tr>
                    <th >User Name </th>
                    <th >Date </th>
                    <th >Followup Date </th>
                    <th >Comments </th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let followUpHistory of followUpHistories">
                    <td>{{followUpHistory.userName}}</td>
                    <td>{{followUpHistory.actionAt | date:"MM/dd/yyyy"}}</td>
                    <td>{{followUpHistory.followUpDate | date:"MM/dd/yyyy"}}</td>
                    <td data-toggle="tooltip" data-placement="top"
                    title="{{(followUpHistory?.comment)}}">
                    {{(followUpHistory?.comment?.length>30)?
                       (followUpHistory?.comment | slice:0:40)+'...':(followUpHistory?.comment)}}</td>
                       
                  </tr>
                </tbody>
              </table>
                </div>  
               
        </div>
</div>
</div>
