import { NameConstant } from "../../models/name-constant";

export interface  NsnGridConfig{
    api: string;
    apiBody: any;
    method: NameConstant,
    isTotalItemKeyPresentInDataSource: boolean;
    totalItemKey: string;
    responseRowKey: string;
}

export function createNsnGridConfig(params: Partial<NsnGridConfig>) {
    return {
      ...params
    } as NsnGridConfig;
  }
  