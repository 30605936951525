<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{formSelection}} Undo Set Billed Reason</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <form class="pb-3 form-fields" [formGroup]="BillReasonForm">
            <div class="row hr">
                <div class="form-group remove-botttom-space col-12">
                    <label for="reason" class="required">Name</label>
                    <input type="text" class="form-control" formControlName="reason" placeholder="Billing Reason" 
                    required [ngClass]="{'is-invalid': submitted && f.reason.errors }">
                            <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                </div>
            </div>
            
           
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <!-- <button type="submit" class="btn btn-primary btn-patient-box" [disabled]="!customQueueMasterForm.valid">Submit</button> -->
                <!-- <button type="submit" (click)="onSubmit()" class="btn btn-primary btn-patient-box" >Submit</button> -->
                <!-- (click)="onSubmit()" -->
                <button [attr.disabled]=" submit == 'Submiting' ? true : null" id="bt" 
                class="btn btn-primary btn-patient-box"type="submit" (click)="onSubmit()">
                    {{submit}}  
                     <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span></button>
            </div>
        </form>
    </div>
    </div>
    <!-- </div> -->
    <!-- Sidenav-->