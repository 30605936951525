import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { facilityReport, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { createFacilityReport, FacilityReport } from '../state/facility-report/facility-report.model';
import { FacilityReportQuery } from '../state/facility-report/facility-report.query';
import { FacilityReportService } from '../state/facility-report/facility-report.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-facility-report',
  templateUrl: './facility-report.component.html',
  styleUrls: ['./facility-report.component.css']
})
export class FacilityReportComponent implements OnInit {
  componentName: NameConstant = facilityReport;
  datasource$: Observable<FacilityReport>;
  datasourceSub: Subscription;
  facilitySub: Subscription;
  loading: boolean;
  submitted: boolean;
  fromDateError: string;
  toDateError: string;
  selFromDate;
  selToDate;
  selFacility;
  facilityDropdownConfig;
  facilitySourceList;
  activeReportLineId$ : Observable<number | string>;

  constructor(
    private facilityReportService: FacilityReportService,
    private facilityReportQuery: FacilityReportQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.initUserDropdownConfig();
    this.loadUserDropdown();
    this.datasource$ = this.facilityReportQuery.selectFirst();
    this.commonUtil.setTitle(this.componentName.value);
    this.activeReportLineId$ = this.facilityReportQuery.selectActiveId();
  }

  ngOnDestroy(){
    if(this.datasourceSub){
      this.datasourceSub.unsubscribe();
    }
    this.facilityReportService.set([]);
  }


  loadUserDropdown() {
    this.facilitySub = this.apiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status === 'Success') {
        this.facilitySourceList = res.data;
      } else {
        this.toaster.error("Server Errro", res?.message);
      }
    }, error => {
      this.toaster.error("Unexpected Errro", error?.message);
    });
  }

  initDataSource(){
    this.loading = true;
    this.submitted = true;
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "facilityId": this.selFacility.id
  }
    this.datasourceSub = this.apiService.post("Reports/FacilityProductivity", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.loading = false;
        this.submitted = false;
        const data = res.data[0];
        const firstQueue = data.queue[0] ? data.queue[0] : undefined;
        const secondQueue = data.queue[1] ? data.queue[1] : undefined;
        const thirdQueue = data.queue[2] ? data.queue[2] : undefined;

        const firstCpt = data.cpt[0] ? data.cpt[0] : undefined;
        const secondCpt= data.cpt[1] ? data.cpt[1] : undefined;
        const thirdCpt = data.cpt[2] ? data.cpt[2] : undefined;

        const entity: FacilityReport = createFacilityReport({
          id: this.selFacility.id,
          facilityName: data.facilityName,
          fromdate: data.fromdate,
          todate: data.todate,
          pendingClient: data.pendingClient,
          inCoding: data.inCoding,
          otherStats: data.otherStats,
          totalCases: data.totalCases,
          totalChargeBill: data.totalChargeBill,
          firstQueueReq: firstQueue?.req,
          firstQueueVal: firstQueue?.val,
          secondQueueReq: secondQueue?.req,
          secondQueueVal: secondQueue?.val,
          thirdQueueReq: thirdQueue?.req,
          thirdQueueVal: thirdQueue?.val,
          firstCptId: firstCpt?.cpt,
          firstTotalCpt: firstCpt?.totalCpt,
          firstCptCost: firstCpt?.cost,
          secondCptId: secondCpt?.cpt,
          secondTotalCpt: secondCpt?.totalCpt,
          secondCptCost: secondCpt?.cost,
          thirdCptId: thirdCpt?.cpt,
          thirdTotalCpt: thirdCpt?.totalCpt,
          thirdCptCost: thirdCpt?.cost
        });

        this.facilityReportService.set([entity]);
        this.facilityReportService.setActive(entity.id);
      }else{
        this.loading = false;
        this.submitted = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.submitted = false;
      this.toaster.error("Unexpected Error!!", err);
    })
  }

  onSearch(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));

    if(!this.selFromDate){
      this.fromDateError = "From Date cannot be blank";
      return;
    }else{
      this.fromDateError = '';
    }
    if(!this.selToDate){
      this.toDateError = "To Date cannot be blank";
      return;
    }else{
      this.toDateError = '';
    }

    
    this.initDataSource();
  }

  showReportLineDetail(data){
    console.log(data);
    this.facilityReportService.setActive(data.id);
  }

  initUserDropdownConfig() {
    this.facilityDropdownConfig = {
      displayKey: "name", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select Facility', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }


  userFacilityChanged(event$) {
    console.log(event$.value);
    this.selFacility = event$.value;
    this.onSearch();
  }

  onExport(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));

    if(!this.selFromDate){
      this.fromDateError = "From Date cannot be blank";
      return;
    }else{
      this.fromDateError = '';
    }
    if(!this.selToDate){
      this.toDateError = "To Date cannot be blank";
      return;
    }else{
      this.toDateError = '';
    }

    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "facilityId": this.selFacility.id
  }

    this.datasourceSub = this.apiService.post4File("Reports/DownloadFacilityProductivity", requestBody).subscribe(res => {
     // saveAs(res, this.componentName.value+".xlsx");
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
			saveAs(blob, this.componentName.value+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }

}
