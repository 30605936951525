import { Injectable } from '@angular/core';
import { Order, QueryConfig, QueryEntity } from '@datorama/akita';
import { NSNAccordianState, NSNAccordianStore } from './nsn-accordian.store';

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortBy: 'order',
  sortByOrder: Order.ASC
})
export class NSNAccordianQuery extends QueryEntity<NSNAccordianState> {

  constructor(protected store: NSNAccordianStore) {
    super(store);
  }

}
