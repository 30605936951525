import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { NsnGrid } from './nsn-grid.model';

export interface NsnGridState extends EntityState<NsnGrid>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'nsn-grid' })
export class NsnGridStore extends EntityStore<NsnGridState> {

  constructor() {
    super(initialState);
  }

}
