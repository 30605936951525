import {Pipe, PipeTransform} from '@angular/core';
 
@Pipe({
    name: 'camelToSentance'
})
export class CamelToSentancePipe implements PipeTransform {

    /**
     * @param value
     */
    transform(value): string {
        const result = value?.replace(/([A-Z])/g, " $1");
        return result?.charAt(0)?.toUpperCase() + result?.slice(1);
    }
}