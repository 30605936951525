
export interface CaseQueueFacilityReport {
  facilityId: number | string;
  facilityName: string;
  totalopencasesbfr: number;
  totalopencasestilldate: number;
  totalcasesadded: number;
  totalsetbilledcases: number;
  totalcount: number;
}

export function createCaseQueueFacilityReport(params: Partial<CaseQueueFacilityReport>) {
  return {
    ...params
  } as CaseQueueFacilityReport;
}
