import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { codingReview, NameConstant, reviewDone } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommentsQuery } from 'src/app/shared/state/comments/comments.query';
import { CommentsService } from 'src/app/shared/state/comments/comments.service';

@Component({
  selector: 'app-cr-review-done',
  templateUrl: './cr-review-done.component.html',
  styleUrls: ['./cr-review-done.component.css']
})
export class CrReviewDoneComponent implements OnInit, OnDestroy {
  componentName: NameConstant = reviewDone;
  comment: string;
  private reviewDoneSubscription: Subscription;
  httpSub: Subscription;
  @Input() callerId: NameConstant;
  @Input() reviewDoneEvent: Observable<any>;
  @Output() reviewCompletedEmitter =new EventEmitter();
  constructor(
    private commentService: CommentsService,
    private commentQuery: CommentsQuery,
    private apiService: ApiService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    console.log("caller id in review done component",this.callerId);
    this.reviewDoneSubscription = this.reviewDoneEvent.subscribe((data) => this.executeReviewDone(data));
  }

  ngOnDestroy() {
    if(this.reviewDoneSubscription){
      this.reviewDoneSubscription.unsubscribe();
    }
    if(this.httpSub){
      this.httpSub.unsubscribe();
    }
  }
  updateComment(){
    this.commentService.update(this.callerId.id, { note: this.comment });
  }

  executeReviewDone(data){
    console.log('executeReviewDone', data);
    const type =data?.inquirystatus;
    const commentFromStore = this.commentQuery.getEntity(this.callerId.id);
    const comment = commentFromStore ? commentFromStore.note: "";
    if(this.callerId === codingReview){
      const requestBody =  {
        "InquiryStatusId": data.inquiryStatusId,
        "AssignCodingInquiryCases": [
            {
                "BDOSID": data.bdosid,          
                "ReviewedComments": comment
            }
        ]
    };
    const formData = new FormData();
    formData.append("ReviewedComments", (comment || ""));
    console.log(data.bdosid,typeof(data.bdosid));
    var sbdosid = data.bdosid.toString()
    formData.append("BDOSID", sbdosid);
    formData.append("InquiryStatusId", data?.inquiryStatusId);
    formData.append("RequestType",type);

    
    

    this.httpSub = this.apiService.uploadFiles("CodingInquiry/SaveCodingInquiry", formData).subscribe(res => {
      if(res.status === 'Success'){
        try {
          this.apiService
            .get('BillingNew/GetCodintReviewCount')
            .subscribe((res) => {
              console.log(res, 'count');
              localStorage.setItem('count', res.codingReviewCount);
            });
        } catch (err) {
          console.log(err);
        }
        this.toast.success("Success!!", res.message);
        this.reviewCompletedEmitter.emit(res.message);
      }else{
        this.toast.error('Server Error !!', res?.message);  
        this.reviewCompletedEmitter.emit(res?.message);
      }
    }, err => {
      this.toast.error('Unexpected Error !!', err?.message);
      this.reviewCompletedEmitter.emit(err?.message);
      });

    }else {
    const reviewName = data.reviewName;
    const bdosid = data.bdosid;
    const commentFromStore = this.commentQuery.getEntity(this.callerId.id);
    const comment = commentFromStore ? commentFromStore.note: "";
    const requestBody = {
      ReviewName: reviewName,
      AssignReviewCases: [ 
        {
          BDOSID: bdosid,
          ReviewedComments: comment
        }
      ]
    };

    this.httpSub = this.apiService.post("ReviewCase/SaveReviewCase", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.toast.success("Success!!", res.message);
        this.reviewCompletedEmitter.emit(res.message);
      }else{
        this.toast.error('Server Error !!', res?.message);  
        this.reviewCompletedEmitter.emit(res?.message);
      }
    }, err => {
      this.toast.error('Unexpected Error !!', err?.message);
      this.reviewCompletedEmitter.emit(err?.message);
      });
    }
  }
}
