import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { PhysicianService } from '../../../shared/state/physician/physician.service';
import { PayersQuery } from 'src/app/shared/state/payers/payers.query';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-importphysicians',
  templateUrl: './importphysicians.component.html',
  styleUrls: ['./importphysicians.component.css']
})
export class ImportPhysicianComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  importPhysicianList: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  privileges:any;
  physicianFilter: string = "All physician";
  
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private physicianService: PhysicianService,
    private accessServices: AccessService,
    private physicianQuery: PayersQuery) { }

  ngOnInit(): void {
    this.privileges = this.accessServices.navbar_access
    this.commonUtil.setTitle("Import Physician");
    this.getImportPhysicianList();
    this.physicianService.get().subscribe(res=> console.log('Initialising physician store'));
  }

  onEditRecord(data) {
    this.shouldShowFormModal = true;
    this.formData = data;
  }

  showFormModal() {
    this.formData = null;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getImportPhysicianList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }

  }

  getImportPhysicianList() {
    let data;
    if(this.physicianFilter && this.physicianFilter === "Active Physician"){
      data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue, "Status": "Active" };
    }else if(this.physicianFilter && this.physicianFilter === "Inactive Physician"){
      data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue, "Status": "Inactive" };
    }else{
      data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };
    }
    this.getApiService.post("Physician/BindGrid", data).subscribe(res => {
      if (res.status == "Success") {
        this.importPhysicianList = res.data;
          this.totalRows = res.data[0] ? res.data[0].totalcount : 0;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  downloadfile(filename) {
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.importPhysicianList = [];
    this.getImportPhysicianList();
  }

  delPhysician(delRow) {

    let data = { "id": delRow.id, "deleted": delRow.active };

    this.getApiService.post("Physician/DeactivatePhysician", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
       this.getImportPhysicianList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    this.p = 1;
    this.getImportPhysicianList();
  }

  search() {
    this.pagenumber = 1;
    this.p = 1;
    this.getImportPhysicianList();
    const filterVal = this.physicianFilter && this.physicianFilter === 'Active Physician' ? 1 : this.physicianFilter && this.physicianFilter === 'Inactive Physician' ? 0 : 2;
    this.totalRows = this.physicianQuery.getAll().filter(p => p.active === filterVal).length;
  }

  onPhysicianFilterChange(){
    this.pagenumber = 1;
    this.p = 1;
    this.getImportPhysicianList();
  }
}
