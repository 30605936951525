import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  allpatientQdata: any = [];
  FacilityList: any = [];
  facilityID: number = 0;
  
  fromDate;
  toDate;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private commonUtil: CommonService
    ) { }

  ngOnInit(): void {
  }

  sortList(a, b) {

  }

  showFormModal(data) {
    this.formData = null;

    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getPaitentArrayList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getPaitentArrayList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  getPaitentArrayList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    // this.getApiService.post("PL/BindGridData", data).subscribe(res => {
    //   if (res.status == "Success") {
    //     this.allpatientQdata = res.data;
    //     if (res.data.length > 0) {
    //       this.totalRows = res.data[0].totalcount;
    //     }
    //   }
    //   else if (res.status == "Error") {
    //     this.toastr.error(res.message, 'Error!');
    //   }
    // }, (error) => {
    //   this.toastr.error(error, 'Error!');
    // });
  }

  editPL(data) {
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.allpatientQdata = [];
    this.getPaitentArrayList();
  }


  pageChange(event) {
    this.pagenumber = event;
    this.getPaitentArrayList();
  }

}
