<div class="align" *ngIf="(fileHistoryInfo$ | async) && (fileHistoryInfo$ | async).length > 0">
 
    <!-- <div *ngIf="(fileHistoryInfo$ | async) && (fileHistoryInfo$ | async).length > 0;else other_content"> -->
    
    
        <button *ngIf="expandAll" type="button" class="btn btn-secondary btn-sm btn-round" (click)="toggleExpand()" >
            <i class="fas fa-chevron-down"></i>
        </button>
        <button *ngIf="!expandAll" type="button" class="btn btn-secondary btn-sm btn-round" (click)="toggleExpand()" >
            <i class="fas fa-chevron-up"></i>
        </button>
        <div *ngIf="!expandAll">
        <div class="row cr-row-expand">
            
           
        <div *ngFor="let d of (previewFileHistoryInfo$ | async)" style="width: 100%;">
          
            <div class="row casehistory" style="margin-top: 25px;">

                <div class="col-md-8">
                    <p class="history-head">{{(d.billerName || d.clientName || d.coderName) | titlecase}}</p>
                    <p class="history-dttttime">{{d.billerTime || d.clientTime  || d.coderTime}}</p>
                </div>
                <div class="col-md-4">
                    <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i>
                        {{d.auditEventType}}<br>
                        <strong> {{d.auditEventDescription}}</strong><br>
                        <a href="javascript:void(0)"
                            (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a>
                    </p>
                </div>
             
                <div class="col-md-12">
                    <p class="history-detailed">
                        <strong>"{{d.billerNote || d.clientNote || d.coderNote}}"</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
 
    <div *ngIf="expandAll">
        <div class="row cr-row-expand">
            <button type="button" class="btn btn-secondary btn-sm btn-round" (click)="toggleExpand()">
                <i class="fas fa-chevron-up"></i>
            </button>
        </div>
        <div  *ngFor="let d of (fileHistoryInfo$ | async)">
            
            <div class="row casehistory">
                <div class="col-md-8">
                    <p class="history-head">{{(d.coderName || d.billerName || d.clientName )| titlecase}}</p>
                    <p class="history-dttttime">{{d.billerTime || d.clientTime || d.coderTime}}</p>
                </div>
                <div class="col-md-4">
                    <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i>
                        {{d.auditEventType}}<br>
                        <strong> {{d.auditEventDescription}}</strong><br>
                        <a href="javascript:void(0)"
                            (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a>
                    </p>
                </div>
                <div class="col-md-12">
                    <p class="history-detailed">
                        <strong>"{{d.billerNote || d.clientNote || d.coderNote}}"</strong>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #other_content>
    <div class="row casehistory">
        <div class="col-md-8">
            <p class="history-head"></p>
            <p class="history-dttttime"></p>
        </div>
    </div>
</ng-template>
