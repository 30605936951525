import { RequestType } from "../enums/request-type";
import * as cr from '../enums/case-request-name';
import { PricingInfo, QueueInfo } from "../state/case-request-info/case-request-info.model";
import { formatCurrency } from "@angular/common";
import { highApproval, highApprovalInternal, variance } from "src/app/shared/models/name-constant";

export class CaseRequestHelper {

    private map: Map<string| number, RequestType> = new Map();
    private idToRequestMap: Map<string | number, cr.CaseRequestName> = new Map();
    constructor() {
      this.initializeMap();
      this.initIdToRequestMap();
    }

    private initIdToRequestMap(){
        this.idToRequestMap.set(cr.operatingReport.id, cr.operatingReport);
        this.idToRequestMap.set(cr.pathology.id, cr.pathology);
        this.idToRequestMap.set(cr.pricing.id, cr.pricing);
        this.idToRequestMap.set(cr.blockReport.id, cr.blockReport);
        this.idToRequestMap.set(cr.codingQuery.id, cr.codingQuery);
        this.idToRequestMap.set(cr.otherMisc.id, cr.otherMisc);
        this.idToRequestMap.set(cr.highApproval.id, cr.highApproval);
        this.idToRequestMap.set(cr.variance.id, cr.variance);
        this.idToRequestMap.set(cr.implantLog.id, cr.implantLog);
        this.idToRequestMap.set(cr.invoice.id, cr.invoice);

        this.idToRequestMap.set(cr.inCoding.id, cr.inCoding);
        this.idToRequestMap.set(cr.highApprovalInternal.id, cr.highApprovalInternal);
        this.idToRequestMap.set(cr.authReview.id, cr.authReview);
        this.idToRequestMap.set(cr.codingExpert.id, cr.codingExpert);
    }
  
    private initializeMap() {
        this.map.set(cr.operatingReport.id, RequestType.CLIENT);
        this.map.set(cr.pathology.id, RequestType.CLIENT);
        this.map.set(cr.pricing.id, RequestType.CLIENT);
        this.map.set(cr.blockReport.id, RequestType.CLIENT);
        this.map.set(cr.codingQuery.id, RequestType.CLIENT);
        this.map.set(cr.otherMisc.id, RequestType.CLIENT);
        this.map.set(cr.highApproval.id, RequestType.CLIENT);
        this.map.set(cr.variance.id, RequestType.CLIENT);
        this.map.set(cr.implantLog.id, RequestType.CLIENT);
        this.map.set(cr.invoice.id, RequestType.CLIENT);

        this.map.set(cr.inCoding.id, RequestType.NSN);
        this.map.set(cr.highApprovalInternal.id, RequestType.NSN);
        this.map.set(cr.authReview.id, RequestType.NSN);
        this.map.set(cr.codingExpert.id, RequestType.NSN);


    }

    getCaseRequestByid(id: string | number): cr.CaseRequestName{
        const request = this.idToRequestMap.get(id);
        return request;
    }

    getRequstType(id: string | number): RequestType{
        return this.map.get(id);
    }

    isClientCaseRequest(id: string | number): boolean{
        return this.map.get(id) === RequestType.CLIENT;
    }

    isNSNCaseRequest(id: string | number): boolean{
        return this.map.get(id) === RequestType.NSN;
    }

    hasImplantLog(pricingInfo: PricingInfo[]): boolean{
        return pricingInfo && pricingInfo.length > 0 && pricingInfo[0] && pricingInfo[0].implantlog === 1;
      }
    
    hasInvoice(pricingInfo: PricingInfo[]): boolean{
        return pricingInfo && pricingInfo.length > 0 && pricingInfo[0] && pricingInfo[0].invoice === 1;
      }

    getAmountFromFormattedCurrency(amount){
        const str =  (amount+"").toString().replace("$", "").replace(",", "");
        return Number(str);
    }

    formatAmount(amount){
      if(isNaN(amount)){
        const strAmount = this.getAmountFromFormattedCurrency(amount);
        return formatCurrency(strAmount, 'en-US', '$');  
      }
      return formatCurrency(amount, 'en-US', '$');
    }
}