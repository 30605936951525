import { NameConstant } from "../../models/name-constant";

export interface NSNDropdownConfig {
    sourceApi: string;
    sourceApiBody: any;
    key: string;
    value: string;
    httpRequestType: NameConstant;
    height: any;
}

export function createNSNDropdownConfig(params: Partial<NSNDropdownConfig>) {
    return {
      ...params
    } as NSNDropdownConfig;
  }

export interface NsnData{
    id: string | number;
    value: string;
    obj: any;
}

export function createNsnData(params: Partial<NsnData>) {
    return {
      ...params
    } as NsnData;
  }
  