import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { BulkActionRequestStatus } from './bulk-action-request-status.model';

export interface BulkActionRequestStatusState extends EntityState<BulkActionRequestStatus> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'bulk-action-request-status' })
export class BulkActionRequestStatusStore extends EntityStore<BulkActionRequestStatusState> {

  constructor() {
    super();
  }

}
