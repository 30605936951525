import { Component, OnInit } from '@angular/core';
import { SessionQuery } from 'src/app/modules/login/session/session.query';
import { AccessService } from '../../services/access.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css'],
})
export class SideNavComponent implements OnInit {
  role = '';
  navbar: any;
  privilege: any;
  countStatus:boolean;
  constructor(
    private sessionQuery: SessionQuery,
    private accessservice: AccessService,
    private apiServices :ApiService
  ) {this.grttingCount()}

  ngOnInit(): void {
    this.gettingCount();
    this.grttingCount();
   
    this.privilege = JSON.parse(localStorage.getItem('privilegearray'));
    console.log(this.privilege, 'privilege in navbar');
    this.accessservice.access_array = this.privilege;

    this.navbar = this.accessservice.navbar_access;
    console.log(this.navbar, 'navbar');

    var finalnavbar = (navbar) => {
      var keys = Object.keys(navbar);

      if (this.privilege[0] == 'accessAll') {
        // console.log('Accessing all');
        for (var i = 0; i < keys.length; i++) {
          temp = keys[i];
          navbar[temp] = true;
        }
      } else {
        for (var i = 0; i < this.privilege.length; i++) {
          var temp = this.privilege[i];
          // console.log(temp, 'temp');
          if (keys.includes(temp)) {
            navbar[temp] = true;
          }
        }
      }
      return navbar;
    };
   

    finalnavbar(this.navbar);
    this.accessservice.navbar_access = this.navbar;

    // console.log(this.navbar, 'after navbar');
    // var val = localStorage.getItem('currentUser');
    // if (val != "" && val != undefined) {
    //   let v = JSON.parse(val);
    //   this.role = v.role;
    // }
    this.role = this.sessionQuery.getRole();
    
  }
  gettingCount(){
    try {
      this.apiServices
        .get('BillingNew/GetCodintReviewCount')
        .subscribe((res) => {
          console.log(res, 'count');
          localStorage.setItem('count', res.codingReviewCount);
          if(res.codingReviewCount > 0){
            this.countStatus =true
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
  grttingCount (){
    let count  = localStorage.getItem("count");
    var y: number = +count;
    if(y > 0){
      this.countStatus = true
    } else{
      this.countStatus = false;
    }  
  }

  isClientRole(): boolean {
    return this.sessionQuery.isClientRole();
  }
  isClientLiteRole():boolean {
    return this.sessionQuery.isClientLiteRole();
  }
  isCoderRole(): boolean {
    return this.sessionQuery.isCoderRole();
  }
  isCodingManagerRole():boolean {
    return this.sessionQuery.isCodingManagerRole();
  }
}
