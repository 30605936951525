import { Component, OnInit,EventEmitter,Output,Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-paper-claim-attachment-form',
  templateUrl: './paper-claim-attachment-form.component.html',
  styleUrls: ['./paper-claim-attachment-form.component.css']
})
export class PaperClaimAttachmentFormComponent implements OnInit {

  @Output() closeEmitter = new EventEmitter();
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  formFlag: string = "Add";
  _formData: any;
  submitted = false;
  ClaimMasterForm: FormGroup;
  @Input() set formData(value: any) {
    
    if (value != null && value != undefined) {
      this._formData = value;
      console.log(this._formData);
      this.formFlag = "Edit";
    // this.getCategories();
     
     this.editForm();
    }
    else {
      this.formFlag = "Add";
      this.initForm();
    }
  }
  
  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    
  }
  initForm() {
    this.ClaimMasterForm = this.fb.group({
      claimId: ["",],
      Name: ["", [Validators.required]],
      
    })
  }
  editForm() {
  
    this.ClaimMasterForm = this.fb.group({
      claimId: [this._formData.id, [Validators.required]],
      Name: [this._formData.paperClaim, [Validators.required]],
     
    });
  }
  
  get f() { return this.ClaimMasterForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.addRecord();
  }
 
  addRecord() {
    const formdata = this.ClaimMasterForm.getRawValue();
    let frmData = new FormData();
    var data:any;
    if (this.formFlag == "Edit") {
       data = {"id" : this.ClaimMasterForm.controls["claimId"].value,"PaperClaim" : this.ClaimMasterForm.controls["Name"].value}
    }else{
      data = {"PaperClaim" : this.ClaimMasterForm.controls["Name"].value}
    }
    
    this.getApiService.post("PaperClaim/SavePaperClaim", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
       
        this.ClaimMasterForm.reset();
        this.closeEmitter.emit();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }
  onClickClose() {
    this.closeEmitter.emit();
  }

}
