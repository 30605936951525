import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CaseRequestInfo } from './case-request-info.model';

export interface CaseRequestInfoState extends EntityState<CaseRequestInfo> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'case-request-info' })
export class CaseRequestInfoStore extends EntityStore<CaseRequestInfoState> {

  constructor() {
    super();
  }

}
