export interface ToolsQaImplantLog {
  id: number | string;
  up: number;
  toolsImplantLogId: number | string;
  claimLine: number | string;
  hcpcs: string;
  description: string;
  unitPrice: number | string;
  taxAndFreight: number | string;
  totalCost: number | string;
  quantity: number;
  chargeAmount: number | string;
  dueFromPayer: number | string;
}

export function createToolsQaImplantLog(params: Partial<ToolsQaImplantLog>) {
  return {
    ...params
  } as ToolsQaImplantLog;
}
