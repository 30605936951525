<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
            <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary" *ngIf="formFlag == 'Manage'">{{ formFlag }} Users</h6>
        <h4 class="m-0 font-weight-bold text-primary" *ngIf="formFlag == 'Edit'">{{ Username }}</h4>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <!--  Content Wrapper -->
    <div class="card-body">
        <form class="pb-3 form-fields" [formGroup]="UserMasterForm" *ngIf="UserMasterForm">
            <div class="row" *ngIf="formFlag == 'Manage'">
                <div class="form-group col-12">
                    <label  for="userName" class="required">Login Id</label>
                    <div class="input-group">
                        <ngx-select-dropdown [config]="config"
                            (change)="selectionChanged($event)" [options]="usernameList$ | async" style="width: 100%;"
                            formControlName="userName" autoClearSearch=" true" [multiple]="false">
                        </ngx-select-dropdown>
                        <label for="userName">{{Username}}</label>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <label for="fullName">Full Name</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="fullName" formControlName="fullName"
                            aria-describedby="MobileNumberHelp" placeholder="Enter Full Name"
                            required [ngClass]="{'is-invalid': submitted && f.fullName.errors }">
                            <div *ngIf="submitted && f.fullName.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                    </div>
                </div>

                <div class="form-group col-6">
                    <label for="inputFirstName">Role</label>
                    <select class="custom-select form-control" formControlName="Role">
                        <option *ngFor="let role of dataRoleList" value="{{role.name}}">
                            {{role.name}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <label for="assignee">Assignee</label>
                    <ngx-select-dropdown [config]="config"
                            (change)="changeAssignee($event)"
                            [options]="usernameList$ | async" style="width: 100%;"
                            formControlName="assignee" autoClearSearch=" true" [multiple]="false">
                    </ngx-select-dropdown>
                </div>
                <div class="form-group col-6">
                    <label for="reportingManager">Reporting Manager</label>
                    <ngx-select-dropdown  [config]="config"
                            (change)="changeReportingManager($event)"
                            [options]="usernameList$ | async" style="width: 100%;"
                            formControlName="reportingManager" autoClearSearch=" true" [multiple]="false">
                    </ngx-select-dropdown>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <input type="checkbox" formControlName="viewVariance" class="user-chkbox"
                        [checked]="dataViewVariance" (click)="updateDataViewVariance()">
                    <label class="user-lblbox">View Variance</label>
                    <input type="checkbox" formControlName="viewAuthReview"
                        class="user-chkbox" [checked]="dataViewAuthReview"
                        (click)="updateDataViewAuthReview()">
                    <label class="user-lblbox">View Auth Review</label>
                    <input type="checkbox" formControlName="viewCodingExpert"
                        class="user-chkbox" [checked]="dataViewCodingExpert"
                        (click)="updateDataViewCodingExpert()">
                    <label class="user-lblbox">View Coding Expert</label>
                </div>
            </div>
            <div class="container tab-remove">
                <ul class="nav nav-tabs nav-border">
                    <li class="user-menu-style"><a class="active" data-toggle="tab" href="#Groups">Group</a>
                    </li>
                    <li class="user-menu-style"><a  data-toggle="tab" href="#Facilities">Facilities</a>
                    </li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Physicians">Physicians</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Payers">Payers</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Queues">Queues</a></li>
                </ul>

                <div class="tab-content">
                    <div id="Groups" class="tab-pane fade  in active show">
                        <div class="row">
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Available Group</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedGroupToAdd"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let group of (availableGroups$ | async)" [ngValue]="group">
                                            {{group.groupName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 remove-button-spce">
                                <div style="height:44px;"></div>
                                <button type="button" (click)="assign($event)" id="AddGroup"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="assignAll($event)" id="AddAllGroup"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="unassign($event)" id="RemoveGroup"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                <button type="button" (click)="unassignAll($event)" id="RemoveAllGroup"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                            </div>
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Assigned Group</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedGroupToRemove"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let group of (activeGroups$ | async)" [ngValue]="group">
                                            {{group.groupName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div id="Facilities" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Available Facilities</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFacilityToAdd"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let facility of (availableFacilities$ | async)" [ngValue]="facility">
                                            {{facility.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 remove-button-spce">
                                <div class="tab-height"></div>
                                <button type="button" (click)="assign($event)" id="AddFacility"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="assignAll($event)" id="AddAllFacility"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="unassign($event)" id="RemoveFacility"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                <button type="button" (click)="unassignAll($event)" id="RemoveAllFacility"
                                    class="btn btn-secondary mr-1 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                            </div>
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Assigned Facilities</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedFacilityToRemove"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let facility of (activeFacilities$ | async)" [ngValue]="facility">
                                            {{facility.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Physicians" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Available Physicians</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPhysicianToAdd"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let physician of (availablePhysicians$ | async)" [ngValue]="physician">
                                            {{physician.fullName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 remove-button-spce">
                                <div class="tab-height"></div>
                                <button type="button" (click)="assign($event)" id="AddPhysician"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="assignAll($event)" id="AddAllPhysician"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="unassign($event)" id="RemovePhysician"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                <button type="button" (click)="unassignAll($event)" id="RemoveAllPhysician"
                                    class="btn btn-secondary mr-1 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                            </div>
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Assigned Physicians</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPhysicianToRemove"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let physician of (activePhysicians$ | async)" [ngValue]="physician">
                                            {{physician.fullName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Payers" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Available Payers</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPayerToAdd"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let payer of (availablePayers$ | async)" [ngValue]="payer">
                                            {{payer.payer_Name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 remove-button-spce">
                                <div class="tab-height"></div>
                                <button type="button" (click)="assign($event)" id="AddPayer"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="assignAll($event)" id="AddAllPayer"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="unassign($event)" id="RemovePayer"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                <button type="button" (click)="unassignAll($event)" id="RemoveAllPayer"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>

                            </div>
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Assigned Payers</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedPayerToRemove"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let payer of (activePayers$ | async)" [ngValue]="payer">
                                            {{payer.payer_Name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="Queues" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Available Queue</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCustomQueueToAdd"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let queue of (availableCustomQueues$ | async)" [ngValue]="queue">
                                            {{queue.queueName}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 remove-button-spce">
                                <div style="height:44px;"></div>
                                <button type="button" (click)="assign($event)" id="AddCustomQueue"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="assignAll($event)" id="AddAllCustomQueue"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="unassign($event)" id="RemoveCustomQueue"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                <button type="button" (click)="unassignAll($event)" id="RemoveAllCustomQueue"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                            </div>
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Assigned Queue</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedCustomQueueToRemove"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let queue of (activeCustomQueues$ | async)" [ngValue]="queue">
                                            {{queue.queueName}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                   
                <button type="button" [disabled] = "submitButtonStatus" (click)="addRecord()" class="btn btn-primary btn-patient-box" >{{submitButton}}</button>
            </div>
        </form>
    </div>
</div>
<!-- Sidenav-->
</div>