import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CaseRequestCpt } from './case-request-cpt.model';

export interface CaseRequestCptState extends EntityState<CaseRequestCpt> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'case-request-cpt', idKey: 'id' })
export class CaseRequestCptStore extends EntityStore<CaseRequestCptState> {

  constructor() {
    super();
  }

}
