import { NameConstant } from "../../models/name-constant";

export interface CustomAccordianConfig {
    categoryConfig: CategoryConfig;
    httpRequestType: NameConstant,
    api: string;
    bodyAttributeMap: Map<string, AttributeConfig>;
    accordianNameAttrKeyMap: Map<string, string>;
    accordianCountAttrKey: string;
    accordianIdAttrKey: string;
    searchValue: string;
    accordianRankAttrKey: string;
}

export interface AttributeConfig {
    isInputProvided: boolean,
    value: string
}
export interface CategoryConfig {
    api: string;
    body: any;
    httpRequestType: NameConstant,
}

export interface CustomAccordian {
    id: string | number;
    name: string;
    count: number;
    isActive: boolean;
    rank: number;
    api: string;
    requestBody: any;
    method: NameConstant;
}

export function createCustomAccordian(id: string | number, name: string, count:number, isActive: boolean, rank: number, api: string, requestBody: any, method: NameConstant) {
    return {
        id: id,
        name: name,
        count: count, 
        isActive: isActive,
        rank: rank,
        api: api,
        requestBody: requestBody,
        method: method
    } as CustomAccordian;
}