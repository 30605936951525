import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FacilityStore, FacilityState } from './facility.store';
import { Facility } from './facility.model';
import { GroupsQuery } from '../groups/groups.query';
import { Group } from '../groups/group.model';

@Injectable({ providedIn: 'root' })
export class FacilityQuery extends QueryEntity<FacilityState> {

  constructor(protected store: FacilityStore, private  groupQuery: GroupsQuery) {
    super(store);
  }
//   getFacility(id: number): Observable<Facility[]>{
//     return this.getFacility().pipe(
//         map(txs => txs.find(txn => txn.id === id))
//     );
// }
   selectAllInactive(): Observable<Facility[]>{
    const all$ = this.selectAll();
    const active$ = this.selectActive();
    const inactive$ = combineLatest([all$, active$]).pipe(
      map(([all, active]) => this.filterInactive(all, active))
    );
    return inactive$;
  }

   getAllInactive(): Facility[]{
    return this.getAll({
      filterBy: e => !this.getActiveId().includes(e.id)
    })
  }

  private filterInactive(all: Facility[], active: Facility[]): Facility[]{
   const inactive =   all.filter((el) => !this.isActive(el, active))
   return inactive;
  }
  
  private isActive(e: Facility, active: Facility[]): boolean{
    return active?.some(a => a.id === e.id);
  }

  getFacilitiesOfActiveGroup(): Facility[]{
    const allInactive = this.getAllInactive();
    const activeGroupId = this.groupQuery.getActiveId();
    return this.filterFacilityOfGroup(allInactive, activeGroupId);
  }

  selectFacilitiesOfActiveGroup(): Observable<Facility[]> {
    const allInactive$ = this.selectAllInactive();
    const activeGroupId$ = this.groupQuery.selectActiveId();
    const facilityOfGroup$ = combineLatest([allInactive$, activeGroupId$]).pipe(
      map(([allInactive, activeGroupId]) => this.filterFacilityOfGroup(allInactive, activeGroupId))
    );

    return facilityOfGroup$;
  }

  private filterFacilityOfGroup(allInactive: Facility[], activeGroupId: ID[]): Facility[]{
   return allInactive.filter(f => activeGroupId.includes(f.groupID));
  }
}
