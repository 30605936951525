import { Component } from '@angular/core';
// import * as $ from 'jquery';
import { Title } from '@angular/platform-browser';
import { IdleService, IdleWarningStates } from 'node_modules/ngx-idle-timeout';
import { Router } from '@angular/router';
import { ApiService } from './shared/services/api.service';
// import { data } from 'cypress/types/jquery';
import { any } from 'cypress/types/bluebird';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'nsn-web';
  heading: string;
  idleTimer;
  logouttime = localStorage.getItem('logouttime') || 900;
  warningtime = localStorage.getItem('warningtime') || 10;
  message: string =
    'You are going to logout withine ' + this.warningtime + ' sec !';

  constructor(
    private _idleService: IdleService,
    private router: Router,
    private apiservice: ApiService
  ) {}

  ngOnInit(): void {
    this.idleTimer = localStorage.getItem('idleTime');
    this.timerSubscribe();
    this.gettingCount();
  }

  resubscribe(): void {
    this.idleTimer = true;
    this.timerSubscribe();
  }
  gettingCount() {
    if (localStorage.getItem('loginStatus')) {
      try {
        this.apiservice
          .get('BillingNew/GetCodintReviewCount')
          .subscribe((res) => {
            console.log(res, 'count');
            localStorage.setItem('count', res.codingReviewCount);
          });
      } catch (err) {
        console.log(err);
      }
    }
    
  }

  private timerSubscribe(): void {
    console.log(this.warningtime);
    console.log(this.logouttime);
    this._idleService.idleStateChanged().subscribe((val) => {
      if (val === IdleWarningStates.SecondaryTimerExpired) {
        this._idleService.stopTimer();
        this.idleTimer = false;
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }
}