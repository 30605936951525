<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{formFlag}} Billing Rule</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="pb-3 form-fields">
            <form action="" [formGroup]="billingRulesForm">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">Rule Name</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="RuleName" placeholder="Rule Name"
                                required [ngClass]="{'is-invalid': submitted && f.RuleName.errors }"
                                [readonly]= "formFlag === 'Edit'">
                        </div>
                        <div *ngIf="submitted && f.RuleName.errors" class="invalid-feedback">
                            <div *ngIf="f.RuleName.errors.required">*mandatory</div>
                        </div>

                    </div>
                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">Billing Requirement</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="RuleText"
                                placeholder="Rule Description" required
                                [ngClass]="{'is-invalid': submitted && f.RuleName.errors }">
                        </div>
                        <div *ngIf="submitted && f.RuleText.errors" class="invalid-feedback">
                            <div *ngIf="f.RuleText.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                       
                        <label for="inputFirstName" class="billing-lblbox">Group</label>
                        <select formControlName="Group" class="custom-select form-control billing-slectbox1" 
                        [compareWith]="compare" (change) ="onGroupChange($event)">
                            <!-- <option selected>Select</option> -->
                            <option *ngFor="let data of facilityGroupArry" [ngValue]="data">{{data.groupName}}</option>
                        </select>
                        <div *ngIf="submitted && f.Group.errors" class="invalid-feedback">
                            <div *ngIf="f.Group.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                        <!-- <input type="checkbox" id="chckfacility" name="" value="" class="billing-chkbox"> -->
                        <label for="Facility" class="billing-lblbox">Facility</label>
                        <select formControlName="Facility" class="custom-select form-control billing-slectbox1" 
                        [compareWith]="compare" (change) ="onFacilityChange($event)">
                            <!-- <option selected>Select</option> -->
                            <option *ngFor='let data of facilitiesOfgroup$ | async' [ngValue]="data">{{data.name}}</option>
                        </select>
                        <div *ngIf="submitted && f.Facility.errors" class="invalid-feedback">
                            <div *ngIf="f.Facility.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                        <label for="FinancialClass" class="billing-lblbox" style="width: 1px;">Financial Class</label>
                        <select formControlName="FinancialClass" class="custom-select form-control billing-slectbox1"
                        [compareWith]="compareFinancialClass" (change) ="onFinancialClassChange($event)">
                            <!-- <option selected>Select</option> -->
                            <option *ngFor='let data of financialClass$ | async' [ngValue]="data">{{data.payer_FinancialClass}}</option>
                        </select>
                        <div *ngIf="submitted && f.FinancialClass.errors" class="invalid-feedback">
                            <div *ngIf="f.FinancialClass.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                        <label for="Payer" class="billing-lblbox">Payer</label>
                        <select formControlName="Payer" class="custom-select form-control billing-slectbox1"
                        [compareWith]="compare" (change) ="onPayerChange($event)">
                            <!-- <option selected>Select</option> -->
                            <option *ngFor='let data of payerOfFacility$ | async' [ngValue]="data">{{data.payer_Name}}</option>
                        </select>
                        <div *ngIf="submitted && f.Payer.errors" class="invalid-feedback">
                            <div *ngIf="f.Payer.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                        <label for="Physician" class="billing-lblbox">Physician</label>
                        <select formControlName="Physician" class="custom-select form-control billing-slectbox1"
                        [compareWith]="compare" (change) ="onPhysicianChange($event)">
                            <!-- <option selected>Select</option> -->
                            <option *ngFor='let data of physicianOfFacility$ | async' [ngValue]="data">{{data.fullName}}</option>
                        </select>
                        <div *ngIf="submitted && f.Physician.errors" class="invalid-feedback">
                            <div *ngIf="f.Physician.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                        <label for="CPT" class="billing-lblbox">CPT Code</label>
                        <select formControlName="CPT" class="custom-select form-control billing-slectbox1"
                        [compareWith]="compareCPT" (change) ="onCptChange()">
                            <option *ngFor='let data of cptOfFacility$ | async' [ngValue]="data">{{data.cpt}}</option>
                        </select>
                        <div *ngIf="submitted && f.CPT.errors" class="invalid-feedback">
                            <div *ngIf="f.CPT.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <!-- <div class="form-group col-12 frm-margin-btm">
                        <div class="row" >
                            <div class="col-md-4">
                                <label for="inputFirstName" class="billing-lblbox required">Claim Type</label></div>
                                <div class="col-md-4" style="display: flex; flex-direction: row;justify-content: space-between;margin-top:6px;">
                                    <input type="checkbox" style="margin-top:6px;" class="form-control-file py-1"  *ngIf='!Electronic' (change)="Electronic = !Electronic" formControlName="TypeElectronic">
                                    <input type="checkbox" style="margin-top:6px;" class="form-control-file py-1" *ngIf='Electronic' checked (change)="Electronic = !Electronic" formControlName="TypeElectronic"> Electronic 
                            
                                </div>
                                <div class="col-md-4" style="display: flex; flex-direction: row;justify-content: space-between;margin-top:6px;">
                                    <input type="checkbox" style="margin-top:6px;" *ngIf='!Paper' class="form-control-file py-1" (change)="Paper = !Paper" formControlName="TypePaper">
                                    <input type="checkbox" style="margin-top:6px;" *ngIf='Paper' class="form-control-file py-1" checked (change)="Paper = !Paper" formControlName="TypePaper"> Paper 
                                </div>
                                <div *ngIf="submitted && (!Electronic || !Paper)" class="invalid-feedback">
                                    <div style="margin-left: 13px" *ngIf="!Electronic && !Paper">*mandatory</div>
                                </div>
                        </div>
                       
                    </div> -->
                </div>
            </form>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav ">Cancel</button>
                <button type="button" (click)="addRecord()" class="btn btn-primary btn-patient-box">Submit</button>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->
</div>