import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { AccessService } from 'src/app/shared/services/access.service';
import { any } from 'cypress/types/bluebird';

@Component({
  selector: 'app-patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.css']
})
export class PatientFormComponent implements OnInit {
  ARForm: FormGroup;
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  eventName: string;
  formFlag: string = "Add";
  _formData: any;
  submitted = false;
  plCatArry: any[];
  filetoUpload;
  plMode: string;
  uploadFileText;
  
  PatientSummaryDtls;
  uploadFile;
  noteSelect;
  noteType;
  note;
  privileges:any;
  PatientsForm: FormGroup;
  smryIsToggled: boolean = false;

  patientCPTs: any = [];
  primaryCPT: number;

  @Input() set formData(value: any) {

    if (value != null && value != undefined) {
      this._formData = value;
      this.formFlag = "Edit";
      this.eventName = this._formData.emailEvent;
      this.editForm();
      this.plMode = "Edit "
    }
    else {
      this.formFlag = "Add";
      this.plMode = "Add "
      this.initForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private accessServices : AccessService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.privileges = this.accessServices.navbar_access
    this.getplCatArryList();
  }

  get f() { return this.ARForm.controls; }


  onSubmit() {

    if (this.formFlag == "Add") {
      this.addRecord();
    }
    else if (this.formFlag == "Edit") {
      this.editRecord();
    }
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  addRecord() {
    this.submitted = true;
    const frmdata = this.ARForm.getRawValue();

    // let formData = new FormData();
    // formData.append("title", frmdata.title);
    // formData.append("description", frmdata.description);
    // formData.append("categoryId", frmdata.categoryId);
    // formData.append("file", this.filetoUpload, this.filetoUpload.name);

    // this.getApiService.uploadFiles("pl/Savepl", formData).subscribe((resp: any) => {
    //   if (resp.status == "Success") {
    //     this.ARForm.reset();
    //   }
    //   this.editEmitter.emit({
    //     'status': true,
    //     'data': resp
    //   })

    // }, (err) => {
    //   console.log(err);
    // })
  }


  editRecord() {
    this.submitted = true;
    const frmdata = this.ARForm.getRawValue();

    // let formData = new FormData();
    // formData.append("id", frmdata.id);
    // formData.append("title", frmdata.title);
    // formData.append("description", frmdata.description);
    // formData.append("categoryId", frmdata.categoryId);

    // if (this.filetoUpload != "" && this.filetoUpload != undefined) {
    //   formData.append("file", this.filetoUpload, this.filetoUpload.name);
    // }


    // this.getApiService.uploadFiles("pl/Savepl", formData).subscribe((resp: any) => {
    //   if (resp.status == "Success") {
    //     this.ARForm.reset();
    //   }
    //   this.editEmitter.emit({
    //     'status': true,
    //     'data': resp
    //   })

    // }, (err) => {
    //   console.log(err);
    // })
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  editForm() {
    this.ARForm = this.fb.group({
      id: [this._formData.id, [Validators.required]],
      title: [this._formData.title, [Validators.required]],
      description: [this._formData.k_description, Validators.required],
      categoryId: [this._formData.categoryId, [Validators.required]],
      file: []
    })
  }

  initForm() {
    this.ARForm = this.fb.group({
      title: ["", [Validators.required]],
      description: ["", Validators.required],
      categoryId: ["", [Validators.required]],
      file: []
    })
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getplCatArryList() {

    // this.getApiService.get("pl/BindCatDropdown").subscribe(res => {
    //   if (res.status == "Success") {
    //     this.plCatArry = res.data;
    //   }
    //   else if (res.status == "Error") {
    //     this.toastr.error(res.message, 'Error!');
    //   }
    // }, (error) => {
    //   this.toastr.error(error, 'Error!');
    // });
  }

  uploadAttachment() {

  }

  fileAttachChanged(event) {

  }

  addNotes() { }

  getFile(a, b) { }
}
