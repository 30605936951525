import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { CommonService } from 'src/app/shared/services/common.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-fee-schedule',
  templateUrl: './fee-schedule.component.html',
  styleUrls: ['./fee-schedule.component.css']
})
export class FeeScheduleComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  shouldShowEditFormModal: boolean = false;
  formData: any = null;
  importfeeSchedule: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  facility_id:any;
  privileges:any;
  shouldShowDetailModal:boolean = false;
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private accessServices : AccessService
  ) { }

  ngOnInit(): void {
    this.privileges = this.accessServices.navbar_access
    this.commonUtil.setTitle("Fee Schedule");
    this.getImportfeeSchedule();
  }
  showDetailModal(data){
    console.log(data);
   // this.formData = data;
    this.facility_id = data.facilityId;

    console.log("this.shouldShowDetailModal : ",this.shouldShowDetailModal,this.facility_id);
    if (this.shouldShowDetailModal) {
      this.shouldShowDetailModal = false;
    }
    else {
      this.shouldShowDetailModal = true;
    }
  }

  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowEditFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getImportfeeSchedule();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowEditFormModal = true;
    }
  }

  showFormModal() {
    // this.formData = null;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }


  onCloseFormModal() {
    this.shouldShowFormModal = false;
    this.shouldShowEditFormModal = false;
    this.shouldShowDetailModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getImportfeeSchedule();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  delfeeSchedule(id) {
    let data = { "FeeScheduleID": id };
    this.getApiService.post("FeeSchedule/DeleteFeeSchedule", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getImportfeeSchedule();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error.message, 'Error!');
    });
  }

  editfeeSchedule(data) {
    this.formData = data;
    if (this.shouldShowEditFormModal) {
      this.shouldShowEditFormModal = false;
    }
    else {
      this.shouldShowEditFormModal = true;
    }
  }

  getImportfeeSchedule() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    this.getApiService.post("FeeSchedule/BindFeeSchedule", data).subscribe(res => {
      if (res.status == "Success") {
        this.importfeeSchedule = res.data;
        this.totalRows = res.data.length === 0 ? 0 : res.data[0].totalcount;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error.message, 'Error!');
    });
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.p = 1;
    this.importfeeSchedule = [];
    this.getImportfeeSchedule();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getImportfeeSchedule();
  }

search(){
  this.pagenumber = 1;
  this.p = 1;
  this.getImportfeeSchedule();
}


}
