import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { User } from './user.model';
import { UsersStore } from './users.store';

@Injectable({ providedIn: 'root' })
export class UsersService {

  constructor(private usersStore: UsersStore, private apiService: ApiService) {}


  get() {
    let data = { "pageNum": 1, "pageSize": 1000};
    return this.apiService.post("Admin/BindGridData", data).pipe(tap(e => {
      const entities: User[] = e.data;
      this.usersStore.set(entities);
    }));
  }

  add(user: User) {
    this.usersStore.add(user);
  }

  update(id, user: Partial<User>) {
    this.usersStore.update(id, user);
  }
  active(data: any){
    this.usersStore.setActive(data.id);
  }

  bindFacilityUsers(facilityId: string | number){
    let data = { FacilityId: facilityId };
    return this.apiService.post("FacilityMaster/BindFacilityUsers", data).pipe(tap(e => {
      const entities: User[] = e.data;
      this.usersStore.set(entities);
    }));
  }
}
