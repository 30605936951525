import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { createBillReason, BillReason } from './bill-reason.model';
import { BillReasonQuery } from './bill-reason.query';
import { BillReasonStore } from './bill-reason.store';

@Injectable({ providedIn: 'root' })
export class BillReasonService {

  constructor(
      private store:  BillReasonStore,
      private query: BillReasonQuery,
      private apiService: ApiService,
      private toast: ToastrService,
      private selectionService: SelectionService
      ) {
  }


  get(pageNum: number, pageSize: number, SearchValue: string){
    let data = { "pageNum": pageNum, "pageSize": pageSize, "SearchValue": SearchValue};
    return this.apiService.post("BillReason/BindGridData", data).pipe(tap(e => {
      const billReasons = e.data?.map(r => createBillReason({ id: r.id, reason: r.reason, updatedDate: r.updatedDate, isActive: r.isActive, totalcount: r.totalcount }));
      this.store.set(billReasons);
    }));
  }

  getByOrder(pageNum: number, pageSize: number, SearchValue: string, orderBy: string, sortDir: string){
    let data = { "pageNum": pageNum, "pageSize": pageSize, "SearchValue": SearchValue, "OrderBy": orderBy, "SortDir": sortDir};
    return this.apiService.post("BillReason/BindGridData", data).pipe(tap(e => {
      const billReasons = e.data?.map(r => createBillReason({ id: r.id, reason: r.reason, updatedDate: r.updatedDate, isActive: r.isActive, totalcount: r.totalcount }));
      this.store.set(billReasons);
    }));
  }

  add(BillReason: BillReason) {
    const request = { Reason: BillReason.reason, id: BillReason.id };
    this.apiService.post('BillReason/SaveBillReason', request).subscribe((resp => {
      if(!resp){
        this.toast.error("response is undefined");
        return;
      }

      if(resp.status !== 'Success' || !resp.data){
        this.toast.error(resp.message);
        return;
      }

      const billReasons = resp.data?.map(r => createBillReason({ id: r.id, reason: r.reason, updatedDate: r.updatedDate, isActive: r.isActive, totalcount: r.totalcount }));
      this.store.set(billReasons);
      this.toast.success(resp.message);
      this.selectionService.changeSelection("");
    }), err => {
      this.toast.error(err);
    });
    
  }

  update(id, BillReason: Partial<BillReason>) {
    this.store.update(id, BillReason);
  }


  active(data: any){
    this.store.setActive(data.id);
  }

  setStoreForDropdown(){
    return this.apiService.get("BillReason/BindReasonDropdown").pipe(tap(e => {
      if(e.status !== 'Success'){
        this.store.setError('Staus is not success'+e.message);
        return;
      }
      const billReasons = e.data?.map(r => createBillReason({ id: r.id, reason: r.reason }));
      this.store.set(billReasons);
    }));
  }

  inactive(){
    const activeIds = this.query.getActiveId();
    this.store.toggleActive(activeIds);
  }

}
