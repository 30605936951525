<!-- Sidenav-->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">{{formFlag}} Facility</h1>

                    <a href="javascript:void(0);" routerLink="/masters/facility-masters" id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <i class="fas fa-angle-left fa-sm font-icn-color"></i>
                        back
                    </a>
                </div>
                <div class="card-body">
                    <form class="pb-3 form-fields" [formGroup]="FacilityMasterForm" (ngSubmit)="onSubmit()">
                        <div class="row hr">
                            <div class="form-group remove-botttom-space col-4">
                                <label for="userName" class="required">Name</label>
                                <input type="text" class="form-control" formControlName="Name" placeholder="Name"
                                    required [ngClass]="{'is-invalid': submitted && f.Name.errors }">
                                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                    <p>*mandatory</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-4">
                                <label class="required">Short Name</label>
                                <input type="text" class="form-control" formControlName="ShortName"
                                    placeholder="Short Name"
                                    [ngClass]="{'is-invalid': submitted && f.ShortName.errors }" required>
                                <div *ngIf="submitted && f.ShortName.errors" class="invalid-feedback">
                                    <p>*mandatory</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-2">
                                <label class="required">Abbr</label>
                                <input type="text" class="form-control" formControlName="Abbr" placeholder="abbr"
                                [ngClass]="{'is-invalid': submitted && f.Abbr.errors }" required maxlength="5">
                                    <div *ngIf="submitted && f.Abbr.errors" class="invalid-feedback">
                                        <p>*mandatory</p>
                                    </div>
                            </div>
                            <div class="form-group remove-botttom-space col-2">
                                <label class="required">Acronym</label>
                                <input type="text" class="form-control" formControlName="Acrnym" placeholder="Acronym"
                                [ngClass]="{'is-invalid': submitted && f.Acrnym.errors }" required>
                                    <div *ngIf="submitted && f.Acrnym.errors?.required" class="invalid-feedback">
                                        <p>*mandatory</p>
                                    </div>
                                    <div *ngIf="f.Acrnym.errors?.Acrnym" class="invalid-feedback">
                                        <p>*Accronym already exist, please enter different value</p>
                                    </div>
                            </div>
                        </div>
                        <div class="row hr">
                            <div class="form-group remove-botttom-space col-4">
                                <!-- [ngClass]="{'is-invalid': submitted && f.Name.errors }" -->
                                <label for="group" class="required">Group</label>
                                <select class="form-control" placeholder="Group" formControlName="Group">
                                    <option value="">--Select Group--</option>
                                    <option *ngFor="let fg of facilityGroupArry ; let index = i" [disabled]="fg.isActive == 0" [ngValue]="fg.id"  >{{fg.groupName}}</option>
                                   
                                </select>
                                <div *ngIf="submitted && f.Group.errors" class="invalid-feedback">
                                    <p>*mandatory</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-4">
                                <label class="required">Region</label>
                                <input type="text" class="form-control" formControlName="Region" placeholder="Region"
                                [ngClass]="{'is-invalid': submitted && f.Region.errors }" required>
                                    <div *ngIf="submitted && f.Region.errors" class="invalid-feedback">
                                        <p>*mandatory</p>
                                    </div> 

                            </div>
                            <div class="col-md-4">
                                <label>Logo</label>
                                <input type="file" (change)="fileChanged($event)" class="form-control-file py-1">
                                <!-- <input type="file" formControlName="File" (change)="fileChanged($event)" this is commented by sachin because formCOntrolName was not present in ts file-->
                                    
                                    <img  width="100px" *ngIf="imageUrl !=''" [src]='imageUrl'/>
                                    <!-- <img  width="100px" *ngIf="logo !='null' " [src]='baseUrl+"/FacilityLogo/" + edit_id + ".jpg"'/> -->

                                    <!-- <img  width="100px" *ngIf="imageUrl !=''" [src]='imageUrl'/> -->
                                    <!-- <img  width="100px" *ngIf="imageUrl =='' && edit_id !=null" [src]='baseUrl+"/FacilityLogo/" + edit_id + ".jpg"'/> -->
                                 
                                  
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;    margin-top: 12px;  
                                margin-bottom: 0px;">Facility Address</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-4">
                                <label>Phone Number</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" 
                                    [textMask]="{mask: phoneCodeMask}"
                                        formControlName="txtPhone" placeholder="Phone Number" 
                                        (blur) = "validatephonefn($event)"
                                        >
                                </div>
                                
                                <div *ngIf="(submitted && validatephone) && phone_new_len > 0  && phone_new_len !==10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                                <!-- <div *ngIf="( submitted ) && phone_new_len > 0  && phone_new_len !==10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div> -->
                            </div>
                            <div class="form-group remove-botttom-space col-4">
                                <label>Fax</label>
                                <div class="input-group">
                                    <input type="text" class="form-control"  formControlName="txtFax" placeholder="Fax" [textMask]="{mask: phoneCodeMask}" 
                                    (blur) = "validatefaxfn($event)"  >
                                </div>
                              
                               
                                <div *ngIf="(submitted && validatefax) && fax_new_len > 0 && fax_new_len !==10;" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                               
                                <!-- <div *ngIf="(submitted) && fax_new_len > 0 && fax_new_len !==10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div> -->
                            </div>
                            <div class="form-group remove-botttom-space col-4">
                                <label>Billing Toll-Free Number</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [textMask]="{mask: phoneCodeMask}"
                                   
                                        formControlName="txtTollFree" placeholder="Billing Toll-Free No"
                                        (blur) = "validatetollfreefn($event)"
                                       >
                                </div>
                                <div *ngIf="( submitted &&  validatetollfree ) &&  tollfree_new_len > 0 &&  tollfree_new_len !==10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                                <!-- <div *ngIf="submitted  &&  tollfree_new_len > 0 &&  tollfree_new_len !==10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div> -->
                            </div>
                        </div>


                        <div class="row">

                            <div class="form-group remove-botttom-space col-md-4">
                                <label>Address 1</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" #addresstext formControlName="txtAddress1"
                                        placeholder="Address 1">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>Address 2</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtAddress2"
                                        placeholder="Address 2">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>City</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtCity"
                                        placeholder="City">
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="form-group remove-botttom-space col-md-4">
                                <label>State</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtState"
                                        placeholder="State">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>Zip</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtZip"
                                        placeholder="Enter Zip">
                                </div>
                            </div>
                        </div>

                        <!-- <hr> -->
                        <!-- </div> -->
                        <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;    margin-top: 12px;
                                margin-bottom: 0px;">Facility Details</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>NPI</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [textMask]="{mask: dolMask}" formControlName="txtNPI" placeholder="NPI" (blur) = "validatenpifn($event)">
                                </div>
                                <div *ngIf="( submitted &&  validateNpi ) &&  npi_new_len > 0 &&  npi_new_len !== 10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>TIN</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtTIN" [textMask]="{mask: tinCodeMask}" placeholder="TIN" (blur) = "validatetinfn($event)">
                                </div>
                                <div *ngIf="( submitted &&  validateTin ) &&  tin_new_len > 0 && tin_new_len !== 9" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>PTAN</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtPTAN" [textMask]="{mask: tanCodeMask}" (blur) = "validateptanfn($event)"
                                        placeholder="PTAN">
                                </div>
                                <div *ngIf="( submitted &&  validatePtan ) &&  ptan_new_len > 0 &&  ptan_new_len !== 6" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>RR PTAN</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRRPTAN" [textMask]="{mask: tanCodeMask}" (blur) = "validaterrtanfn($event)"
                                        placeholder="RR PTAN">
                                </div>
                                <div *ngIf="( submitted &&  validateRRPtan ) &&  rrptan_new_len > 0 &&  rrptan_new_len !== 6" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>DOL</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtDOL" [textMask]="{mask: dolMask}" placeholder="DOL" (blur) = "validatedolfn($event)">
                                </div>
                                <div *ngIf="( submitted &&  validateDol ) &&  dol_new_len > 0 &&  dol_new_len !== 10" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <label>Medicaid</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtMedicaid" [textMask]="{mask: tanCodeMask}" (blur) = "validatemedicaidfn($event)"
                                        placeholder="Medicaid">
                                </div>
                                <div *ngIf="( submitted &&  validateMedicaid ) &&  medicaid_new_len > 0 &&  medicaid_new_len !== 6" class="invalid-feedback">
                                    <p>Invalid number</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;margin-top:12px;
                                margin-bottom: 0px;">Coding</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" formControlName="Coding" value="External" checked>
                                    <label class="form-check-label" for="external">External</label>
                                  </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="Coding"  value="Internal" >
                                <label class="form-check-label" for="internal">Internal</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" formControlName="Coding"  value="CodingOnly" >
                                <label class="form-check-label" for="codingOnly">Coding only</label>
                              </div>
                            </div>
                        </div> -->
                         <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;margin-top:12px;
                                margin-bottom: 0px;">Coding</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">
                                <div class="form-check form-check-inline">
                                    <input  type="radio" formControlName="Coding" name="Coding" value="External" >
                                    <label class="form-check-label" for="external">External</label>
                                  </div>
                            <div class="form-check form-check-inline">
                                <input  type="radio" formControlName="Coding" name="Coding"  value="Internal" >
                                <label class="form-check-label" for="internal">Internal</label>
                              </div>
                              <div class="form-check form-check-inline">
                                <input  type="radio" formControlName="Coding" name="Coding"  value="CodingOnly" >
                                <label class="form-check-label" for="codingOnly">Coding only</label>
                              </div>
                            </div>
                        </div>
                        
                        
                        <!-- <hr> -->
                        
                        <!-- <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;    margin-top: 12px;
                                margin-bottom: 0px;">Facility Contacts</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional President </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRPName"
                                        placeholder="Regional President Name">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional President Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRPEmail"
                                        placeholder="Regional President Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional Director </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRDName"
                                        placeholder="Regional Director Name">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional Director Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRDEmail"
                                        placeholder="Regional Director Email">
                                </div>
                            </div>
                        </div> -->
                        <!-- <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;    margin-top: 12px;
                                margin-bottom: 0px;">Facility Contacts</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional President </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRPName"
                                        placeholder="Regional President Name">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional President Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRPEmail"
                                        placeholder="Regional President Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional Director </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRDName"
                                        placeholder="Regional Director Name">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Regional Director Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtRDEmail"
                                        placeholder="Regional Director Email">
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="row">
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Center Admin</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtAdminName"
                                        placeholder="Center Admin Name">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Center Admin Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtAdminEmail"
                                        placeholder="Center Admin Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Center BOM </label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtBOMName"
                                        placeholder="Center BOM Name">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Center BOM Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtBOMEmail"
                                        placeholder="Center BOM Email">
                                </div>
                            </div>
                        </div>

                        <div class="row">

                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Billing Model</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtBillingModel"
                                        placeholder="Billing Model">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Contracts</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtContracts"
                                        placeholder="Contracts">
                                </div>
                            </div>
                        </div> -->
                        <!-- <hr> -->


                        <!-- <div class="row">
                            <div class="col-12">
                                <p class="text-primary" style="font-size: 18px;margin-top: 12px;
                                margin-bottom: 0px;">Notification Settings</p><br />
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Notification Email 1</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtNSNHighDollarEmail1"
                                        placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Notification Email 2</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtNSNHighDollarEmail2"
                                        placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Notification Email 3</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="txtNSNHighDollarEmail3"
                                        placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Return Notification Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="returnEmailAdd"
                                        [ngModelOptions]="{standalone: true}" placeholder="Email">
                                </div>
                            </div>
                        </div>
                        <div class="row">

                            <div class="form-group remove-botttom-space col-md-3">
                                <label>Return Notification Message</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="returnEmailMsg"
                                        [ngModelOptions]="{standalone: true}" placeholder="Message">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>AuthReview Notification Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="authreviewEmailAdd"
                                        [ngModelOptions]="{standalone: true}" placeholder="Email">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>AuthReview Notification Message</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="authreviewEmailMsg"
                                        [ngModelOptions]="{standalone: true}" placeholder="Message">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-3">
                                <label>CodingExpert Notification Email</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="codingEmailAdd"
                                        [ngModelOptions]="{standalone: true}" placeholder="Email">
                                </div>
                            </div>
                        </div>
                       

                        <div class="row">

                            <div class="form-group remove-botttom-space col-md-4">
                                <label>CodingExpert Notification Msg</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" [(ngModel)]="codingEmailMsg"
                                        [ngModelOptions]="{standalone: true}" placeholder="Message">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space col-md-4">

                                <div class="input-group" style="margin-top: 25px;">
                                    <input type="checkbox" formControlName="chkNSNHighApproval"
                                        style="margin-top: 3px;">
                                    <label>NSN High$$$</label>
                                </div>
                            </div>



                        </div> -->
                        <!-- <hr> -->
                        <div class="text-right">

                            <a href="javascript:void(0);" routerLink="/masters/facility-masters"
                                class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</a>
                                
                                <!-- <button (click)="onSubmit()" [attr.disabled]=" submit == 'Submiting' ? true : null" id="bt" 
                                class="btn btn-primary btn-patient-box"type="submit">
                                    {{submit}}  
                                     <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span></button> -->
                            <button type="submit" (click)="onSubmit()" class="btn btn-primary btn-patient-box" [disabled]="loading" >Submit
                                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                            </button>

                            <!-- (click)="onSubmit()" -->
                        </div>
                    </form>
                    <!-- <hr> -->
                    <div class="row"  *ngIf="formFlag == 'Edit'">
                        <div class="col-12">
                            <p class="text-primary" style="font-size: 18px;    margin-top: 12px;
                            margin-bottom: 0px;"><b>Facility Contacts</b></p><br />
                        </div>
                        <form  [formGroup]="ContactDetailForm" (ngSubmit)="contactDetailSubmit()" class="d-flex"> 
                            <div class="col-md-3">
                                
                                <label><b>Title</b></label>
                                <input type="text" class="form-control-file py-1"
                                    placeholder="Title" formControlName="Title" 
                                    [ngClass]="{ 'has-error': submitted && ContactDetailForm.controls.Title.errors }">
                                    <div *ngIf="submitted && ContactDetailForm.controls['Title'].errors?.required" class="invalid-feedback"> <p></p></div>
                               
                            </div>
                            <div class="col-md-3">
                                <label><b>Name</b></label>
                                <input type="text" class="form-control-file py-1"
                                    placeholder="Name" formControlName="Name"
                                    [ngClass]="{ 'is-invalid': submitted && ContactDetailForm.controls['Name'].errors }" >
                                    <div *ngIf="submitted && ContactDetailForm.controls['Name'].errors?.required" class="invalid-feedback"> <p></p></div>
                            </div>
                            <div class="col-md-3">
                                
                                <label><b>Email</b></label>
                                <input type="email" class="form-control-file py-1"
                                    placeholder="Email" formControlName="Email"
                                    [ngClass]="{ 'is-invalid': submitted && ContactDetailForm.controls['Email'].errors }" >
                                    <div *ngIf="submitted && ContactDetailForm.controls['Email'].errors?.required" class="invalid-feedback"> <p></p></div>
                                    
                                    <div *ngIf="submitted && ContactDetailForm.controls['Email'].errors?.pattern" class="invalid-feedback"> <p>Enter Valid Email</p></div>
                                  
                                <!-- <div *ngIf="f.Email.errors.email">*Email Address must be valid</div> -->
                            </div>
                            <div class="col-md-3">
                                <label><b>Phone</b></label>
                                <input type="text" class="form-control-file py-1"
                                    placeholder="Phone" formControlName="Phone" [textMask]="{mask: phoneCodeMask}"
                                    [ngClass]="{ 'is-invalid': submitted && ContactDetailForm.controls['Phone'].errors }" >
                                    <div *ngIf="submitted && ContactDetailForm.controls['Phone'].errors?.required" class="invalid-feedback"> <p></p></div>
                            </div>
                            <div class="col-md-2">
                                <button type="submit"  *ngIf='updateShow' class="btn btn-primary btn-patient-box mt-3" [disabled]="ContactDetailForm.invalid">Update</button>
                                
                                <button type="submit" *ngIf='!updateShow' class="btn btn-primary btn-patient-box mt-3" [disabled]="ContactDetailForm.invalid">Add</button>
                            
                            </div>
                        </form>
                    </div>
                    <br/>
                    <div class="row col-md-12"   *ngIf="formFlag == 'Edit'">
                        <table class="table" width="100%">
                            <thead>
                                <th><b style="color:black;">Title <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('title','asc')"></i>
                                    <i class="fas fa-arrow-down" (click)="sortListContactDetails('title','desc')"></i>
                                </b></th>
                                <th><b style="color:black;">Name <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('name','asc')"></i>
                                    <i class="fas fa-arrow-down" (click)="sortListContactDetails('name','desc')"></i>
                                </b></th>
                                <th><b style="color:black;">Email <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('email','asc')"></i>
                                    <i class="fas fa-arrow-down" (click)="sortListContactDetails('email','desc')"></i>
                                </b></th>
                                <th><b style="color:black;">Phone <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('phone','asc')"></i>
                                    <i class="fas fa-arrow-down" (click)="sortListContactDetails('phone','desc')"></i>
                                </b></th>
                            
                                <th><b style="color:black;">Action</b></th>
                            </thead>
                            <tbody>
                                <tr *ngFor='let contact of contactDetails | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>{{contact.title}}
                                       </td>
                                    <td>{{contact.name}}</td>
                                    <td>{{contact.email}}</td>
                                    <td>{{contact.phone}}</td>
                                    <td><a href="javascript:void(0)" (click) = "editContacts(contact)"><i class="fa fa-edit" title="Edit"></i></a>&nbsp;&nbsp;<a href="javascript:void(0)" (click) = 'deleteContacts(contact.id)'><i class="fa fa-trash" title="Delete"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="row"  *ngIf="formFlag == 'Edit'">
                        <div class="col-12">
                            <p class="text-primary" style="font-size: 18px;    margin-top: 12px;
                            margin-bottom: 0px;"><b>Notification Settings</b></p><br />
                        </div>
                        <form  [formGroup]="notificationForm" (ngSubmit)='notificationDetailSubmit()' class="d-flex form_new_upd"> 
                            
                            <div class="col-md-3">
                                <label><b>Email</b></label>
                                 <ngx-select-dropdown *ngIf='!updateNotificationShow' [config]="config" formControlName="Email"
                            (change)="selectionChanged($event)" [options]="usernameList" style="width: 100%;"
                           autoClearSearch=" true" [multiple]="false">
                        </ngx-select-dropdown>
                       <br> <label *ngIf='updateNotificationShow'><b>{{nuserName}}</b></label>
                                <!-- <input type="text" class="form-control-file py-1"
                                    placeholder="Name" formControlName="Name" 
                                    [ngClass]="{ 'is-invalid': submitted && notificationForm.controls['Name'].errors }">
                                    <div *ngIf="submitted && notificationForm.controls['Name'].errors?.required" class="invalid-feedback">*mandatory</div> -->
                            </div>
                            <!-- <div class="col-md-3">
                                
                                <label><b>Name</b></label>
                                <input type="text" class="form-control-file py-1"
                                    placeholder="Name" formControlName="Name" 
                                    [ngClass]="{ 'is-invalid': submitted && notificationForm.controls['Name'].errors }" readOnly>
                                    
                                    <div *ngIf="submitted && !updateNotificationShow && notificationForm.controls['Name'].errors?.required" class="invalid-feedback"></div>
                               
                            </div> -->
                            <div class="text-center">
                                <!-- <label>Notification</label> -->
                            <b style="color:black;"> Notification</b> <input type="checkbox" class="form-control-file py-1 mt-2"
                                    placeholder="Notification" formControlName="Notification" (change)="Notification = !Notification"
                                   >
                                    <!-- <div *ngIf="submitted && ContactDetailForm.controls['Phone'].errors?.required" class="invalid-feedback">*mandatory</div> -->
                            </div>
                            <div class="text-center">
                                <!-- <label>Return Notification</label> -->
                                <b style="color:black;"> Return Notification</b><input type="checkbox" class="form-control-file py-1 mt-2"
                                    placeholder="Return Notification" formControlName="ReturnNotification" (change)="ReturnNotification = !ReturnNotification">
                                    <!-- <div *ngIf="submitted && ContactDetailForm.controls['Phone'].errors?.required" class="invalid-feedback">*mandatory</div> -->
                            </div>
                            
                            <div class="text-center">
                                <!-- <label>Auth Review</label> -->
                                <b style="color:black;"> Auth Review </b><input type="checkbox" class="form-control-file py-1 mt-2"
                                    placeholder="Auth Review" formControlName="AuthReview" (change)="AuthReview = !AuthReview">
                                    <!-- <div *ngIf="submitted && ContactDetailForm.controls['Phone'].hasError('required')" class="error-message"></div> -->
                            </div>
                            <div class="text-center">
                                <!-- <label>Coding Expert</label> -->
                                <b style="color:black;"> Coding Expert</b><input type="checkbox" class="form-control-file py-1 mt-2"
                                    placeholder="Coding Expert" formControlName="CodingExpert" (change)="CodingExpert = !CodingExpert">
                                    <!-- <div *ngIf="submitted && ContactDetailForm.controls['Phone'].hasError('required')" class="error-message"></div> -->
                            </div>
                            <div class="text-center">
                                <!-- <label>High $$$</label> -->
                              <b style="color:black;"> High $$$</b><input type="checkbox" class="form-control-file py-1 mt-2"
                                    placeholder="High" formControlName="High" (change)="High = !High">
                                    <!-- <div *ngIf="submitted && ContactDetailForm.controls['Phone'].hasError('required')" class="error-message"></div> -->
                            </div><br/>
                            <div class="row">
                            <div class="col-md-12">
                                
                                <button type="submit"  *ngIf='updateNotificationShow' class="btn btn-primary btn-patient-box mt-3">Update</button>
                                
                                <button type="submit" *ngIf='!updateNotificationShow' class="btn btn-primary btn-patient-box mt-3" [disabled]="notificationForm.invalid" >Add</button>
                               
                            
                            </div>
                        </div>
                        </form>
                    </div>
                    <br/>
                    <div class="row col-md-12"  *ngIf="formFlag == 'Edit'">
                        <table class="table">
                            <thead style = "text-align: center;">
                                
                                <th><b style="color:black;">Name
                                     <i (click)="sortListNotificationDetails('fullName','asc')" class="fas fa-arrow-up mr-1" ></i>
                                    <i (click)="sortListNotificationDetails('fullName','desc')" class="fas fa-arrow-down"></i>
                                </b></th>
                                <th><b style="color:black;">Email <i class="fas fa-arrow-up mr-1" (click)="sortListNotificationDetails('email','asc')"></i>
                                    <i class="fas fa-arrow-down" (click)="sortListNotificationDetails('email','desc')"></i>
                            </b></th>
                                <th><b style="color:black;">Notification</b>
                                     <!-- <i class="fas fa-arrow-up mr-1"></i>
                                    <i class="fas fa-arrow-down"></i> -->
                                </th>
                                <th><b style="color:black;">Return Notification </b>
                                    <!-- <i class="fas fa-arrow-up mr-1"></i>
                                    <i class="fas fa-arrow-down"></i> -->
                                </th>
                                <th><b style="color:black;">Auth Review </b>
                                    <!-- <i class="fas fa-arrow-up mr-1"></i>
                                    <i class="fas fa-arrow-down"></i> -->
                                </th>
                                <th><b style="color:black;">Coding Expert </b>
                                    <!-- <i class="fas fa-arrow-up mr-1"></i>
                                    <i class="fas fa-arrow-down"></i> -->
                                </th>
                                <th><b style="color:black;">High $$$</b>
                                    <!-- <i class="fas fa-arrow-up mr-1"></i>
                                    <i class="fas fa-arrow-down"></i> -->
                                </th>
                                <th><b style="color:black;">Action</b></th>
                            </thead>
                            <tbody style = "text-align: center;">
                                <tr style = "text-align: center;" *ngFor='let notification of notificationDetails | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>{{notification.fullName}}
                                       </td>
                                    <td>{{notification.email}}</td>
                                    <td>
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.notification == 1' checked disabled >
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.notification == 0'  disabled >
                                    </td>
                                    <td>
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.returnNotification == 1' checked disabled >
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.returnNotification == 0'  disabled >
                                    </td>
                                    <td>
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.authReview == 1' checked disabled >
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.authReview == 0'  disabled >
                                    </td>
                                    <td>
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.codingExpert == 1' checked disabled >
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.codingExpert == 0'  disabled >
                                    </td>
                                    <td>
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.highDollar == 1' checked disabled >
                                        <input type="checkbox" class="form-control-file py-1" *ngIf='notification.highDollar == 0'  disabled >
                                    </td>
                                    <td><a href="javascript:void(0)" (click) = "editNotification(notification)"><i class="fa fa-edit" title="Edit"></i></a>&nbsp;&nbsp;<a href="javascript:void(0)" (click) = 'deleteNotification(notification.id)'><i class="fa fa-trash" title="Delete"></i></a></td>
                                </tr>
                            </tbody> 
                        </table>
                    </div>
                        <!-- <hr> -->
                        <div class="row" *ngIf="formFlag == 'Edit'">
                            <div class="col-md-6">
                                <p class="text-primary" style="font-size: 18px; margin-top: 12px;
                                margin-bottom: 0px;"><b>Additional Information</b></p><br />
                            </div>
                            <div class="col-md-6">
                                <p class="text-primary" style="font-size: 18px; margin-top: 12px;
                                margin-bottom: 0px;"><b>Attachments</b></p><br />
                            </div>
                            <div class="col-md-12">
                                <div class="row ">
                                    
                                    <!-- -->
                                    <!-- <ng-template> -->
                                        <div class="col-md-6">
                                        <form  [formGroup]="CustomFieldForm" (ngSubmit)="fieldSubmit()" class="d-flex"> 
                                        <div class="col-md-4">
                                            
                                            <label><b>Select Field</b></label>
                                            <select class="form-control" formControlName="fieldName">
                                                <option *ngFor='let field of fieldArr' [ngValue]="field.id" >{{field.fieldName}}</option>
                                                
                                            </select>
                                            <div *ngIf="submitted && CustomFieldForm.controls['fieldName'].hasError('required')" class="error-message"></div>
                                        </div>
                                        <div class="col-md-4">
                                            <label><b>Custom Value</b></label>
                                            <input type="text" class="form-control-file py-1"
                                                placeholder="Custom Field value" formControlName="customValue" >
                                                <div *ngIf="submitted && CustomFieldForm.controls['customValue'].hasError('required')" class="error-message"></div>
                                        </div>
                                        <div class="col-md-4">
                                            <button type="submit" [disabled]="!CustomFieldForm.valid" class="btn btn-primary btn-patient-box mt-3 d-flex ml-auto">Add</button>
                                        
                                        </div>
                                    <!-- </ng-template>
                                         -->
                                    
                                    </form>
                                </div>


                                    <div class="col-md-6">
                                        <form  [formGroup]="attachmentForm" (ngSubmit)="attachmentFormSubmit()">
                                            <div class="row">
                                                <div class="col-md-4">
                                                    <label><b>File</b></label>
                                                    <input type="file" (change)="fileChanged($event)" class="form-control-file py-1">
                                                </div>
                                                <div class="col-md-3">
                                                    <label><b>Select Category</b></label>
                                                    <select class="form-control" formControlName="Category">
                                                        <option *ngFor='let cat of categories' [ngValue]="cat.id" >{{cat.categoryName}}</option>
                                                     
                                                    </select>

                                                </div>
                                                <div class="col-md-3">
                                                    <label><b>Comment</b></label>
                                                    <input type="text" formControlName="Comment" class="form-control">
                                                </div>
                                                <div class="col-md-2">
                                                    <button style="margin-top: 19px;"  type="submit" [disabled]="!attachmentForm.valid"
                                                        class="btn btn-primary btn-patient-box d-flex ml-auto">Add</button>
                                                </div>
                                            </div>
                                        </form>
                                        <!-- <div class="row back-color-comnt1">
                                            <div class="form-group1 col-md-4">
                                                <p class="patient-tab-above">Custom Field Name</p>
                                            </div>
                                            <div class="form-group1 col-md-4">
                                                <p class="patient-tab-above">Custom Field Value</p>
                                            </div>
                                            <div class="form-group1 col-md-4">
                                                <p class="patient-tab-above">Action</p>
                                            </div>

                                        </div>
                                        <div class="patient-center-space">

                                            <div class="row">
                                                <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                                    <div class="row">
                                                        <p class="col-md-4" style="font-weight: 300;">Custom feild 1</p>
                                                        <p class="col-md-4" style="font-weight: 300;">value1 </p>

                                                        <p class="col-md-4" style="display:flex;"> <a
                                                                class="icon-color-red">
                                                                <i class="fas fa-trash" title="Delete"></i></a>


                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-md-6">
                                        <table class="table">
                                            <thead>
                                                <th><b style="color:black;">Field <i class="fas fa-arrow-up mr-1" (click)="sortListFacilityCustomData('fieldName','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortListFacilityCustomData('fieldName','desc')"></i>
                                                </b></th>
                                                <th><b style="color:black;">Custom Value <i class="fas fa-arrow-up mr-1" (click)="sortListFacilityCustomData('fieldValue','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortListFacilityCustomData('fieldValue','desc')"></i>
                                                </b></th>
                                                <th><b style="color:black;">Action</b></th>
                                            </thead>
                                            
                                            <tbody>
                                                <tr *ngFor='let ff of facilityfields | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                                    <td>{{ff.fieldName}}</td>
                                                    <td>{{ff.fieldValue}}</td>
                                                    <td><a class="icon-color-red" (click) = "delCustomFieldMaster(ff.id)">
                                                        <i class="fas fa-trash" title="Delete" ></i></a></td>
                                                </tr>
                                                
                                            </tbody>

                                        </table>
                                    </div>
                                    <div class="col-md-6">
                                        <table class="table">
                                            <thead>
                                                <th><b style="color:black;">Date <i class="fas fa-arrow-up mr-1" (click)="sortFacilityAttachmentData('createdDate','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortFacilityAttachmentData('createdDate','desc')"></i>
                                                </b></th>
                                                <th><b style="color:black;">Category <i class="fas fa-arrow-up mr-1" (click)="sortFacilityAttachmentData('categoryName','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortFacilityAttachmentData('categoryName','desc')"></i>
                                                </b></th>
                                                <th><b style="color:black;">User <i class="fas fa-arrow-up mr-1" (click)="sortFacilityAttachmentData('userName','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortFacilityAttachmentData('userName','desc')"></i>
                                                </b></th>
                                                <th><b style="color:black;">File Details</b></th>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor='let ca of categoryAttachment | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                                    <td>{{ca.createdDate | date:'MM/dd/yyyy'}}
                                                       </td>
                                                    <td>{{ca.categoryName}}</td>
                                                    <td>{{ca.userName}}</td>
                                                    <td><a href="javascript:void(0)" (click)="downloadFile(ca)"class="icon-color-green mr-1"><i
                                                                class="fas fa-download" title="Download"></i>&nbsp;{{ca.fileName}}</a></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>

                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->
<!-- Sidenav-->