<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{ formFlag }} Facility Group</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <form class="pb-3 form-fields" [formGroup]="FacilityGroupMasterForm" (ngSubmit)="onSubmit()">
            <div class="row hr">
                <div class="form-group remove-botttom-space col-12">
                    <label for="userName" class="required">Name</label>
                    <input type="text" class="form-control" formControlName="Name" placeholder="Name" required>

                </div>
            </div>
            <div class="col-md-12">
                <div class="row">
                    <div id="Facilities" class="tab-pane fade in active show">
                        <div class="row">
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Available Facilities</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [ngModel]="selectedToAdd"
                                        (ngModelChange)="chosenFacilities($event)"
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let facility of facilities" [ngValue]="facility">
                                            {{facility.name}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group col-md-2 remove-button-spce">
                                <div class="tab-height"></div>
                                <button type="button" (click)="assigne()"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="assigneAll()"
                                    class="btn btn-secondary mr-3 user-mid-button-right">Add All<i
                                        class="fa fa-angle-double-right" aria-hidden="true"></i></button>
                                <button type="button" (click)="unassigne()"
                                    class="btn btn-secondary mr-3 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove</button>
                                <button type="button" (click)="unassigneAll()"
                                    class="btn btn-secondary mr-1 user-mid-button-left"><i
                                        class="fa fa-angle-double-left" aria-hidden="true"></i>Remove All</button>
                            </div>
                            <div class="form-group facil-space col-md-5">
                                <p class="user-tab-weight">Assigned Facilities</p>
                                <div class="input-group input-selc-box">
                                    <select [ngModelOptions]="{standalone: true}" [ngModel]="selectedToRemove"
                                        (ngModelChange)="chosenFacilitiesToRemove($event)" 
                                        class="custom-select form-control multiple-select-design" multiple>
                                        <option *ngFor="let facility of selectedFacilities" [ngValue]="facility">
                                            {{facility.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="submit" class="btn btn-primary btn-patient-box" [disabled]="!FacilityGroupMasterForm.valid">Submit</button>
                <!-- (click)="onSubmit()" -->
            </div>
        </form>
    </div>
    </div>
    <!-- </div> -->
    <!-- Sidenav-->