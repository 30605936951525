<div class="cr-selection-card">
       <div *ngFor="let cr of (clientCaseRequest$ | async)">
           <div class="row">
               <div class="col-md-11">
                <button type="button" class="btn btn-sm" 
                [ngClass]="{
                    'btn-primary btn-cr-selection-primary': isCaseRequestSelected(cr),
                    'btn-secondary btn-cr-selection-secondary': !isCaseRequestSelected(cr)
                    }"
                (click)="caseRequestSelected(cr)" 
                [disabled]= "isCaseRequestDisabled(cr)">{{ cr.value }}
                    <i *ngIf="cr.hasIndicator" style="color: #EE2501;" class="fas fa-exclamation"></i>
                </button>
               </div>
               <div class="col-md-1 cr-selection-pending">
                <i class="fas fa-hourglass-start" *ngIf="(cr.id === crHighApproval.id) && (highApprovalStatus$ | async) === 'Pending'" (click)="pendingCaseRequestSelected(cr)"></i>
                <i class="fa fa-check"  *ngIf="(cr.id === crHighApproval.id) && (highApprovalStatus$ | async) === 'Approved'" (click)="pendingCaseRequestSelected(cr)" aria-hidden="true"></i>
                <i class="fa fa-ban"  *ngIf="(cr.id === crHighApproval.id) && (highApprovalStatus$ | async) === 'Rejected'" (click)="pendingCaseRequestSelected(cr)" aria-hidden="true"></i>
                
                <i class="fas fa-hourglass-start" *ngIf="(cr.id === crVariance.id) && (varianceStatus$ | async) === 'Pending'" (click)="pendingCaseRequestSelected(cr)"></i>
                <i class="fa fa-check"  *ngIf="(cr.id === crVariance.id) && (varianceStatus$ | async) === 'Approved'" (click)="pendingCaseRequestSelected(cr)" aria-hidden="true"></i>
                <i class="fa fa-ban"  *ngIf="(cr.id === crVariance.id) && (varianceStatus$ | async) === 'Rejected'" (click)="pendingCaseRequestSelected(cr)" aria-hidden="true"></i>
                  
            </div>
           </div>
       </div>
</div>
<div class="cr-selection-card" style="padding-top: 5px;">
    <div *ngFor="let cr of (nsnCaseRequest$ | async)">
        <div class="row">
            <div class="col-md-11">
                <button type="button" class="btn btn-sm"
                [ngClass]="{
                    'btn-primary btn-cr-selection-primary': isCaseRequestSelected(cr),
                    'btn-secondary btn-cr-selection-secondary': !isCaseRequestSelected(cr)
                    }"
                (click)="caseRequestSelected(cr)" 
                [disabled]= "isCaseRequestDisabled(cr)">{{ cr.value }}</button>
            </div>
            <div class="col-md-1 cr-selection-pending">
                <i class="fas fa-hourglass-start" *ngIf="(cr.id === crHighApprovalInternal.id) && (highApprovalInternalStatus$ | async) === 'Pending'" (click)="pendingCaseRequestSelected(cr)"></i>
                <i class="fa fa-check"  *ngIf="(cr.id === crHighApprovalInternal.id) && (highApprovalInternalStatus$ | async) === 'Approved'" (click)="pendingCaseRequestSelected(cr)" aria-hidden="true"></i>
                <i class="fa fa-ban"  *ngIf="(cr.id === crHighApprovalInternal.id) && (highApprovalInternalStatus$ | async) === 'Rejected'" (click)="pendingCaseRequestSelected(cr)" aria-hidden="true"></i>
                <i class="fa fa-hourglass-start" *ngIf="(cr.id === inCoding.id) && (hasActiveInCoding$ | async)" (click)="pendingCaseRequestSelected(cr)"></i>
                <i class="fa fa-hourglass-start" *ngIf="(cr.id === authReview.id) && (hasActiveAuthReview$ | async)" (click)="pendingCaseRequestSelected(cr)"></i>
                <i class="fa fa-hourglass-start" *ngIf="(cr.id === codingExpert.id) && (hasActiveCodingExpert$ | async)" (click)="pendingCaseRequestSelected(cr)"></i>
            </div>
        </div>
        
    </div>
</div>