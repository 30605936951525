import { Injectable } from "@angular/core";
import { AbstractControl, AsyncValidator, AsyncValidatorFn, ValidationErrors } from "@angular/forms";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ApiService } from "src/app/shared/services/api.service";

@Injectable({ providedIn: 'root'})
export class UniqueAccronymValidator{

    static createValidator(getApiService: ApiService): AsyncValidatorFn {
      
        console.log('UniqueAccronymValidator ...')
        return (control: AbstractControl): Observable<ValidationErrors> => {
          return getApiService.post("FacilityMaster/CheckAcrnym", { acrnym: control.value }).pipe(
            map(r => ( r.msg !== 'Already Exists' ?  null : { Acrnym: true })),
            catchError(() => of(null))
        );
        };
      }
}