import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService, MustMatch } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-userregistration',
  templateUrl: './userregistration.component.html',
  styleUrls: ['./userregistration.component.css']
})
export class UserregistrationComponent implements OnInit {
  registrationForm: FormGroup;
  submitted = false;
  mismatchPwd: boolean = false;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }


  ngOnInit(): void {
    this.commonUtil.setTitle("Register User");

    this.registrationForm = this.formBuilder.group({
      FullName:['',[Validators.required,Validators.pattern('^[a-z A-Z]+$')]],
      UserName: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(6)]],
      InvitationCode: ['', [Validators.required]]
    }, {
      validator: MustMatch('Password', 'ConfirmPassword')
    });
  }

  get f() { return this.registrationForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registrationForm.invalid) {
      return;
    }

    this.getApiService.post('ApplicationUser/Register', this.registrationForm.value).subscribe(
      res => {

        if (res.status == "Success") {
          this.toastr.success('User Register Successfully!', 'Success!');
          this.router.navigate(['/login']);
        }
        else if (res.status == "IdentityError") {
          this.toastr.error(res.message[0].description, res.message[0].code);
          localStorage.clear();
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
          localStorage.clear();
        }
      }, (error) => {

        this.toastr.error(error, 'Error!');
      });

  }
}
