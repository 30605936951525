import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { UserProductivityReportComponent } from './user-productivity-report/user-productivity-report.component';
import { ReportsRoutingModule } from './reports-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule } from '@angular/forms';
import { UserProductivityReportDetailComponent } from './user-productivity-report/user-productivity-report-detail/user-productivity-report-detail.component';
import { CaseQueueFacilityReportComponent } from './case-queue-facility-report/case-queue-facility-report.component';
import { CaseQueueFacilityReportLineComponent } from './case-queue-facility-report/case-queue-facility-report-line/case-queue-facility-report-line.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ManagementReportComponent } from './management-report/management-report.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ManagementReportLineComponent } from './management-report/management-report-line/management-report-line.component';
import { FacilityReportComponent } from './facility-report/facility-report.component';
import { FacilityReportLineComponent } from './facility-report/facility-report-line/facility-report-line.component';



@NgModule({
  declarations: [UserProductivityReportComponent, UserProductivityReportDetailComponent, 
    CaseQueueFacilityReportComponent, CaseQueueFacilityReportLineComponent, ManagementReportComponent, ManagementReportLineComponent, FacilityReportComponent, FacilityReportLineComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    SharedModule,
    NgxPaginationModule,
    FormsModule,
    SelectDropDownModule,
    NgxSpinnerModule
  ],
  providers: [
    CurrencyPipe
  ]
})
export class ReportsModule { }
