import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { NameConstant, userProductivityReportDetail } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UserProductivity } from '../../state/user-productivity/user-productivity.model';
import { UserProductivityQuery } from '../../state/user-productivity/user-productivity.query';
import { UserProductivityService } from '../../state/user-productivity/user-productivity.service';
import { createUserProductivityReportDetail, UserProductivityReportDetail } from './user-productivity-report-detail';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-user-productivity-report-detail',
  templateUrl: './user-productivity-report-detail.component.html',
  styleUrls: ['./user-productivity-report-detail.component.css']
})
export class UserProductivityReportDetailComponent implements OnInit, OnDestroy {
  componentName: NameConstant = userProductivityReportDetail;

  @Input() selFromDate;
  @Input() selToDate;
  coloumns: ColDef[] = [];
  activeUserProdutivityReportLine: UserProductivity;
  activeUserProductivityReportLineId$: Observable<number | string>;

  datasource$: Observable<UserProductivityReportDetail[]>;
  loading: boolean;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  destroy$ = new Subject();
  constructor(
    private userProductivityService: UserProductivityService,
    private userProductivityQuery: UserProductivityQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
  ) { }

  ngOnInit(): void {
    this.initColoumns();
    this.initDataSource();
    this.activeUserProductivityReportLineId$ = this.userProductivityQuery.selectActiveId();
    this.commonUtil.setTitle(this.componentName.value);
  }

  ngOnDestroy(){
    this.destroy$.next(true);
  }
  back(){
    const activeId = this.userProductivityQuery.getActiveId();
    this.userProductivityService.toggleActive(activeId);
  }

  initDataSource(){
    this.loading = true;
    this.activeUserProdutivityReportLine = this.userProductivityQuery.getActive();
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "pageNum": this.currentPage,
      "pageSize": this.itemsPerPage,
      "email": this.activeUserProdutivityReportLine.email
  }
    this.datasource$ = this.apiService.post("Reports/UserProductivityByUserId", requestBody)
    .pipe(map(res => {
      if(res.status === 'Success'){
        this.loading = false;
        const entities: UserProductivityReportDetail[] = res.data.map(r => createUserProductivityReportDetail({
         facilityName: r.facilityName,
         mrn: r.mrn,
         workType: r.workType,
         importDate: r.importDate,
         setBilledDate: r.setBilledDate,
         totalCharges: r.totalCharges,
         totalCount: r.totalcount
        }));
        return entities;
      }else{
        this.loading = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.toaster.error("Unexpected Error!!", err);
    }));

    this.datasource$.pipe(takeUntil(this.destroy$)).subscribe(userProductivityReportDetail => {
      this.totalRows = userProductivityReportDetail.find(e => true)?.totalCount;
      console.log("totalRows", this.totalRows);
    })
  }

  initColoumns(){
    this.coloumns = [...this.coloumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "mrn", displayName: "MRN", isDataCol: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "workType", displayName: "Work Type", isDataCol: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "importDate", displayName: "Loaded to Navigator", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "setBilledDate", displayName: "Set Billed Date", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalCharges", displayName: "Total Charges Billed", isDataCol: true, isDataTypeDate: false })];
   // this.coloumns = [...this.coloumns, createColDef({ name: "", displayName: "Action", isDataCol: false, isDataTypeDate: false, isAction: true })];
  }
  
  pageChange(event$){
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch(){
    this.initDataSource();
  }

  onExport(){
    this.loading = true;
    this.activeUserProdutivityReportLine = this.userProductivityQuery.getActive();
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "email": this.activeUserProdutivityReportLine.email
  }

    this.apiService.post4File("Reports/DownloadUserProductivityByUserId", requestBody).subscribe(res => {
     // saveAs(res, this.componentName.value+".xlsx");
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
			saveAs(blob, this.activeUserProdutivityReportLine.username+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }
}
