
export interface CaseQueueFacilityReportLine {
  facilityName: string;
  mrn: string;
  importDate: string;
  setBilledDate: string;
  totalcount: number;
}

export function createCaseQueueFacilityReportLine(params: Partial<CaseQueueFacilityReportLine>) {
  return {
    ...params
  } as CaseQueueFacilityReportLine;
}
