<!-- Begin Page Content -->
<div *ngIf="(activeEntityId$ | async)">
    <!-- Content Row -->
    <div class="card-body">
        <div class="table-responsive">
            <table class="table" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr style="color: #333;">
                        <th *ngFor="let col of coloumns"
                        [ngClass]="{'txt-align-right': col?.name === 'importDate' || col?.name === 'pendingClient' || col?.name === 'inCoding' || col?.name === 'otherStat' || col?.name === 'setBilledDate' || col?.name === 'totalCharges' || col?.name === 'mrn' }"
                        >{{col.displayName}}</th>
                    </tr>
                </thead>
                <tfoot>
                    <tr style="color: #333;">
                        <th *ngFor="let col of coloumns"
                        [ngClass]="{'txt-align-right': col?.name === 'importDate' || col?.name === 'pendingClient' || col?.name === 'inCoding' || col?.name === 'otherStat' || col?.name === 'setBilledDate' || col?.name === 'totalCharges' || col?.name === 'mrn' }"
                        >{{col.displayName}}</th>
                    </tr>
                </tfoot>
                <ng-container *ngIf="datasource$ | async as datasource">
                    <tr *ngFor="let data of datasource | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;"
                        style="color: #333;
                font-weight: 400;">
                        <td>{{(i+1) + ((currentPage-1)*itemsPerPage)}}</td>
                        <td>{{data.facilityName}}</td>
                        <td class="txt-align-right">{{data.mrn}}</td>
                        <td class="txt-align-right">{{data.importDate | date: 'MM/dd/yyyy'}}</td>
                        <td class="txt-align-right">{{data.pendingClient | dotToColon}}</td>
                        <td class="txt-align-right">{{data.inCoding | dotToColon}}</td>
                        <td class="txt-align-right">{{data.otherStat | dotToColon}}</td>
                        <td class="txt-align-right" *ngIf="data.setBilledDate !== '1900-01-01T00:00:00'">{{data.setBilledDate | date: 'MM/dd/yyyy'}}</td>
                        <td *ngIf="data.setBilledDate === '1900-01-01T00:00:00'"></td>
                        <td class="txt-align-right">{{data.totalCharges | currency}}</td>
                    </tr>
                </ng-container>
            </table>
            <pagination-controls class="float-right"
                (pageChange)="pageChange($event); currentPage = $event">
            </pagination-controls>
        </div>
    </div>
</div>