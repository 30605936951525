import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { SelectionService } from '../../../../shared/state/selection/selection.service';
import { User } from '../state/users/user.model';
import { UsersQuery } from '../state/users/users.query';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CustomQueue } from 'src/app/modules/masters/custom-queue/state/custom-queue.model';
import { CustomQueueQuery } from 'src/app/modules/masters/custom-queue/state/custom-queue.query';

@Component({
  selector: 'app-copy-user-form',
  templateUrl: './copy-user-form.component.html',
  styleUrls: ['./copy-user-form.component.css'],
  providers: [ NgxSpinnerService, BsModalService ]
})
export class CopyUserFormComponent implements OnInit {
  modalRef: BsModalRef;
  message: string;
  config;
  CopyUserForm: FormGroup;
  usernameList$: Observable<User[]>;
  activeUser$: Observable<User>;
  queue: CustomQueue[] = [];
  constructor(
    private modalService: BsModalService,
    private selectionService: SelectionService,
    private userQuery: UsersQuery,
    private apiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private customQueueQuery: CustomQueueQuery,
    ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.activeUser$ = this.userQuery.selectActive().pipe(tap((e)=> {
      this.spinner.hide();
    }));
    this.initForm();
    this.config = this.initialiseDropDownConfig('userName');
    this.usernameList$ = this.userQuery.selectAll();
    this.queue = this.customQueueQuery.getAll();
    
  }
  initForm() {
    let q = {};
    for(let i = 0; i < this.queue.length; i++){
      q[this.queue[i].id] = new FormControl('', Validators.required);
    }
    this.CopyUserForm = new FormGroup(q);
    this.CopyUserForm.addControl('copyProfileTo', new FormControl('', Validators.required));
    
  }

  selectionChanged(event$) {
    if(event$.value){
      this.queue.map(q => this.CopyUserForm.controls[q.id].setValue(event$.value));
    }
  }

  onClickClose(){
    this.selectionService.changeSelection("");
  }

  addRecord(){
    if(!this.CopyUserForm.valid){
      this.toastr.error('Select Copy profile to user');
      return; 
    }
    const request = { 
      OldUserId: this.userQuery.getActiveId(), 
      NewUserId: this.CopyUserForm.controls['copyProfileTo'].value.id
    }
    this.apiService.post("ApplicationUser/CopyUserProfile", request)
      .subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.CopyUserForm.reset();
        this.toastr.success('Success!');
      }else{
        this.toastr.error('Unable to serve at the moment');
      }
      
    }, (err) => {
      console.log(err);
    })
  }
  
  initialiseDropDownConfig(drpDownFor: string){
    return {
      displayKey: drpDownFor, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: drpDownFor, // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
  }
 
  confirm(): void {
    this.message = 'Confirmed!';
    this.addRecord();
    this.modalRef.hide();
  }
 
  decline(): void {
    this.message = 'Declined!';
    this.modalRef.hide();
  }
}
