import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-fee-schedule-form',
  templateUrl: './fee-schedule-form.component.html',
  styleUrls: ['./fee-schedule-form.component.css']
})
export class FeeScheduleFormComponent implements OnInit {
  @Output() addedEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  filetoUpload;
  facilityList: any[];
  facility = '';
  submitted = false;
  fileFormat = false;
  // downloadUrl = this.getApiService.webUrl + "uploads/FeeSchedule.csv";
  // fileName="FeeSchedule.csv"

  @ViewChild('feeScheduleFile') feescheduleFile: ElementRef;


  constructor(
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getFacilityMasterList();
  }

  addRecord() {

    this.submitted = true;
    console.log(this.filetoUpload);

    if (this.facility == '' || this.filetoUpload.name == '') {
      return;
    }

    let formData = new FormData();
    formData.append("FacilityId", this.facility);
    formData.append("file", this.filetoUpload, this.filetoUpload.name);

    this.getApiService.uploadFiles('FeeSchedule/UploadFile', formData).subscribe((resp: any) => {
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }

  onCancel() {
    // this.surgeryCenterForm.reset();
    this.closeEmitter.emit();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  uploadFile(fileInput) {
    this.filetoUpload = fileInput.target.files[0];

    var ext = this.filetoUpload.name.split('.').pop().toLowerCase();

    if (ext !== 'csv') {
      this.feescheduleFile.nativeElement.value = '';
      this.filetoUpload = "";
      this.fileFormat = true;
      return false;
    }
    else {
      this.fileFormat = false;
    }
  }


  downloadfile() {
    let downloadUrl = this.getApiService.webUrl + "uploads/FeeSchedule.csv";
    console.log(downloadUrl,"download");
    window.open(downloadUrl, "_blank");
  }

  // get f() { return this.feeScheduleForm.controls; }

  getFacilityMasterList() {
    let data = {};

    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.facilityList = res.data;
      }
      else if (res.status == "Error") {
        console.log(res.message);
      }
    }, (error) => {
      console.log(error);
    });
  }

}
