import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { NSNAccordian } from './nsn-accordian.model';

export interface NSNAccordianState extends EntityState<NSNAccordian>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'nsn-accordian', idKey: 'value' })
export class NSNAccordianStore extends EntityStore<NSNAccordianState> {

  constructor() {
    super();
  }

}
