import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-eod-hold-form',
  templateUrl: './eod-hold-form.component.html',
  styleUrls: ['./eod-hold-form.component.css']
})
export class EodHoldFormComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();

  PatientSummaryDtls: any;
  _formData: any;

  patientCPTs: any = [];
  primaryCPT: number;

  eodHoldReason: any[];
  smryIsToggled = false;
  fileAttachName: any;
  facilityId: number = 0;
  facilityName: string = "";
  dataQueueInfo: any = {};
  dataQueueDtl: any = {};
  uploadFile: any;
  chckAccept = false;
  chckHold = false;
  chckClaim = false;
  chckSelfPay = false;
  holdReason = "";
  claimID = "";

  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
    }
  }

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getPatientHeaderData();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  addNote() {
    let data = {
      "EODID": this._formData.id,
      "txtHoldReviewNote": this.claimID,
      "BDOSID": this._formData.bdosid
    };

    this.getApiService.post("EOD/AddHoldReviewNote", data).subscribe(res => {
      if (res.status == "Success") {
        this.closeEmitter.emit();
        this.toastr.success("Eod updated sucessfully", 'Success!');
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getPatientHeaderData() {
    this.spinner.show();
    let data = {
      "Bdosid": this._formData.bdosid
    }

    this.getApiService.post("BillingNew/GetPatientsInfo", data).subscribe(res => {
      this.spinner.hide();
      if (res.status == "Success") {

        this.PatientSummaryDtls = res;

        this.facilityId = res.dataPatient[0].facilityId;
        this.facilityName = res.dataPatient[0].facilityName;

        this.dataQueueInfo = res.dataQueueInfo[0];

        if (res.dataCPTDetails.length > 0) {
          this.primaryCPT = res.dataCPTDetails[0].cptCode;
        }

        for (let d of res.dataCPTDetails) {
          if (this.patientCPTs.indexOf(d.cptCode) == -1) {
            this.patientCPTs.push(d.cptCode);
          }
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }


  satisfy() {
    let data = {
      "EODID": this._formData.id,
      "BDOSID": this._formData.bdosid
    };

    this.getApiService.post("EOD/Satisfy", data).subscribe(res => {
      if (res.status == "Success") {
        this.closeEmitter.emit();
        this.toastr.success("Eod updated sucessfully", 'Success!');
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
}
