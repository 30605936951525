import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { sessionPersistStorage } from './app/modules/login/session/session.store';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const providers = [
  { provide: 'persistStorage', useValue: sessionPersistStorage, multi: true }
]
platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
