import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SessionQuery } from 'src/app/modules/login/session/session.query';
import { getNameConstantMap, highApproval, NameConstant, pathology } from 'src/app/shared/models/name-constant';
import { CommentsQuery } from 'src/app/shared/state/comments/comments.query';
import { CaseRequestName } from '../../enums/case-request-name';
import { CaseRequestHelper } from '../../helper/case-request.helper';
import { createTab, Tab } from './tab.model';
import { TabsQuery } from './tabs.query';
import { TabsStore } from './tabs.store';

@Injectable({ providedIn: 'root' })
export class TabsService {

  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();

  constructor(
    private tabsStore: TabsStore,
    private tabsQuery: TabsQuery,
    private commentsQuery: CommentsQuery,
    private sessionQuery: SessionQuery
  ) {
  }


  get(): Observable<Tab[]> {
    return this.tabsQuery.selectAll();
  }

  add(tab: Tab) {
    console.log('adding tab', tab);
    this.tabsStore.add(tab);
    const isClientRole = this.sessionQuery.isClientRole();
    const isClientLiteRole = this.sessionQuery.isClientLiteRole();
    // if (!isClientRole && !isClientLiteRole )
    if (!isClientRole) {
      const rqeuestId = this.caseRequestHelper.getCaseRequestByid(tab.id);
      this.bindNoteByReq(rqeuestId);
    }
  }

  update(id, tab: Partial<Tab>) {
    this.tabsStore.update(id, tab);
  }

  remove(id: ID) {
    this.tabsStore.remove(id);
  }

  setActive(id: ID) {
    this.tabsStore.setActive(id);
  }

  destroy() {
    this.tabsStore.destroy();
  }
  reset() {
    this.tabsStore.set([]);
  }

  private bindNoteByReq(request: CaseRequestName) {
    if (!request) {
      return;
    }
    let commentId;
    if (request.value === pathology.value) {
      commentId = getNameConstantMap().get(pathology.id.toString()).serverKey;
    } else if (request.value === highApproval.value) {
      commentId = getNameConstantMap().get(highApproval.id.toString()).serverKey;
    }else{
      commentId = getNameConstantMap().get(request.id.toString()).serverKey;
    }

    console.log("commentId", commentId);
    const note = this.commentsQuery.getEntity(commentId)?.note;
    const tab = createTab({ note: note });

    this.update(request.id, tab);
  }
}
