<!-- footer section sttart--->
<div class="footer">
    <div class="row">
        <div class="col-md-6">
            <p class="copyright-text">&#169; 2022 HITech Health Solutions. All Rights Reserved</p>
        </div>
        <div class="col-md-6">
            <p class="version-text">Version 0.0.1</p>
        </div>
    </div>
</div>
<!-- footer section end--->
<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
            <div class="modal-footer">
                <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                <button class="btn btn-primary" type="button" (click)="logout()">Logout</button>
            </div>
        </div>
    </div>
</div>