import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-deposit-log',
  templateUrl: './deposit-log.component.html',
  styleUrls: ['./deposit-log.component.css']
})
export class DepositLogComponent implements OnInit {
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Deposit Log");
  }

}
