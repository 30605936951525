import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { masterCodingStatus, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CodingStatus, createCodingStatus } from './state/coding-status.model';
import { CodingStatusQuery } from './state/coding-status.query';
import { CodingStatusService } from './state/coding-status.service';

@Component({
  selector: 'app-coding-status',
  templateUrl: './coding-status.component.html',
  styleUrls: ['./coding-status.component.css']
})
export class CodingStatusComponent implements OnInit, OnDestroy {
  componentName: NameConstant = masterCodingStatus;
  coloumns: ColDef[] = [];
  dataSource$: Observable<CodingStatus[]>;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  dataSub: Subscription;
  toggleSub: Subscription;

  shouldShowAddForm: boolean;
  shouldShowEditForm: boolean;
  totalRows: number;
  constructor(
    private commonUtil: CommonService,
    private codingStatusQuery: CodingStatusQuery,
    private apiService: ApiService,
    private codingStatusService: CodingStatusService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.commonUtil.setTitle(this.componentName.value);
    this.initColoumns();
    this.dataSource$ = this.codingStatusQuery.selectAll();
    this.loadData(this.currentPage, this.itemsPerPage);
  }

  loadData(pageno: number, pageSize: number){
    let requestBody = {
      pageNum: pageno,
      pageSize: pageSize
    };

    this.dataSub = this.apiService.post("InquiryStatus/BindGridData", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        const entities = res.data.map(r => createCodingStatus({
          id: r.id,
          inquirystatus: r.inquirystatus,
          status: r.status,
          priority: r.priority
        }))
        this.codingStatusService.set(entities);

        this.totalRows = res.data[0]?.totalcount;
      }
    });
  }
  ngOnDestroy(){
    if(this.dataSub){
      this.dataSub.unsubscribe();
    }
  }

  initColoumns(){
    this.coloumns = [...this.coloumns, createColDef({ name: 'id', displayName: '', isIdCol: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: 'inquirystatus', displayName: 'Inquiry Status' })];
    this.coloumns = [...this.coloumns, createColDef({ name: 'status', displayName: 'Status' })];
    this.coloumns = [...this.coloumns, createColDef({ name: 'priority', displayName: 'Priority' })];
    this.coloumns = [...this.coloumns, createColDef({ name: 'action', displayName: 'Action' })];
  }

  pageChange(event$){
    console.log(event$);
    this.currentPage = event$;
    this.loadData(this.currentPage, this.itemsPerPage);
  }

  toggleStatus(data){
    console.log(data);
    this.toggleSub = this.apiService.post("InquiryStatus/ToggleInquiryStatus", { id: data.id }).subscribe(res => {
      if(res.status === 'Success'){
        this.toastr.success("Status Updated");
        this.loadData(this.currentPage, this.itemsPerPage);
      }else{
        this.toastr.error("Status Updated Failed");
        this.loadData(this.currentPage, this.itemsPerPage);
      }
    })
  }

  openAddForm(){
    this.shouldShowAddForm = this.shouldShowAddForm ? false : true;
  }

  openEditForm(data){
    console.log("editing", data);
    this.codingStatusService.setActive(data.id);
    this.shouldShowEditForm = this.shouldShowEditForm ? false : true;
  }

  onCloseFormModal(){
    this.shouldShowAddForm = false;
    this.shouldShowEditForm = false;
    this.itemsPerPage = 10;
    this.currentPage = 1;
    this.loadData(this.currentPage, this.itemsPerPage);
  }
}
