import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';

@Injectable({ providedIn: 'root' })
export class AssignCaseService {

  constructor(private apiService: ApiService, private toastr: ToastrService, private selectionService: SelectionService) {
  }

  add(assignCaseRequest: Map<number, string>) {
    let data = []
    for (let [k, v] of assignCaseRequest) {
      data = [...data, { BDosId: k, UserId: v} ];
    }

    this.apiService.post("AssignCases/Save", { assignCases: data}).subscribe(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to save assign case mapping', e.message);
        return;
      }
      this.toastr.success('Success!!','Assign Case save');
      this.selectionService.changeSelection("*load*");
    });
  }

}
