import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EodMaintenanceComponent } from './eod-maintenance.component';
import { EodMaintenanceFormComponent } from './eod-maintenance-form/eod-maintenance-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [EodMaintenanceComponent, EodMaintenanceFormComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [EodMaintenanceFormComponent]
})
export class EodMaintenanceModule { }
