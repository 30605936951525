import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ImplantLogPayer } from './implant-log-payer.model';
import { ImplantLogPayerStore } from './implant-log-payer.store';

@Injectable({ providedIn: 'root' })
export class ImplantLogPayerService {

  constructor(private implantLogPayerStore: ImplantLogPayerStore, private http: HttpClient) {
  }

  set(implantLogPayers: ImplantLogPayer[]){
    this.implantLogPayerStore.set(implantLogPayers);
  }

  setError(err){
    this.implantLogPayerStore.setError(err?.message);
  }

  add(implantLogPayer: ImplantLogPayer) {
    this.implantLogPayerStore.add(implantLogPayer);
  }

  update(id, implantLogPayer: Partial<ImplantLogPayer>) {
    this.implantLogPayerStore.update(id, implantLogPayer);
  }

  remove(id: ID) {
    this.implantLogPayerStore.remove(id);
  }

}
