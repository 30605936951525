export interface FacilityReport {
  id: number | string;
  facilityName: string;
  fromdate: Date;
  todate: Date;
  pendingClient: string;
  inCoding: string;
  otherStats: string;
  totalCases: string;
  totalChargeBill: string;
  firstQueueReq: string;
  firstQueueVal: number;
  secondQueueReq: string;
  secondQueueVal: number;
  thirdQueueReq: string;
  thirdQueueVal: number;
  firstCptId: string;
  firstTotalCpt: number;
  firstCptCost: number;
  secondCptId: string;
  secondTotalCpt: number;
  secondCptCost: number;
  thirdCptId: string;
  thirdTotalCpt: number;
  thirdCptCost: number;
}

export function createFacilityReport(params: Partial<FacilityReport>) {
  return {
    ...params
  } as FacilityReport;
}
