import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { saveAs } from 'file-saver';
import { Input } from '@angular/core';

@Component({
  selector: 'app-attachments-qa',
  templateUrl: './attachments-qa.component.html',
  styleUrls: ['./attachments-qa.component.css']
})
export class AttachmentsQaComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  PatientSummaryDtls: any;
  _formData: any;
  smryIsToggled = false;

  dataQueueInfo: any = {};
  uploadFileText: string;

  patientCPTs: any = [];
  primaryCPT: number;

  fileAttachName: any;
  uploadFile: any;
  facilityId: number = 0;
  facilityName: string = "";

  dataQueue = [{ "key": "OP Note", "value": "operatingReport" }, { "key": "Pathology", "value": "pathology" }, { "key": "Pricing", "value": "pricing" },
  { "key": "Block Report", "value": "blockReport" }, { "key": "Coding Query", "value": "codingQuery" }, { "key": "Other/Misc", "value": "otherMisc" },
  { "key": "Client High$$$", "value": "highApproval" }, { "key": "In Coding", "value": "inCoding" }, { "key": "NSN High$$$", "value": "highApprovalInternal" },
  { "key": "Coding Variance Review", "value": "variance" }, { "key": "Auth Review", "value": "authReview" }, { "key": "Coding Expert", "value": "codingExpert" }];


  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
    }
  }

  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getPatientHeaderData();
  }

  getPatientHeaderData() {
    let data = {
      "Bdosid": this._formData.bdosid
    }

    this.getApiService.post("BillingNew/GetPatientsInfo", data).subscribe(res => {
      if (res.status == "Success") {

        this.PatientSummaryDtls = res;
        this.dataQueueInfo = res.dataQueueInfo[0];

        this.facilityId = res.dataPatient[0].facilityId;
        this.facilityName = res.dataPatient[0].facilityName;

        if (res.dataCPTDetails.length > 0) {
          this.primaryCPT = res.dataCPTDetails[0].cptCode;
        }

        for (let d of res.dataCPTDetails) {
          if (this.patientCPTs.indexOf(d.cptCode) == -1) {
            this.patientCPTs.push(d.cptCode);
          }
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getFile(fileId, fileName) {
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  uploadAttachment() {

    if (this.fileAttachName != null && this.fileAttachName.name != undefined) {
      let frmData = new FormData();
      frmData.append("BdosID", this._formData.bdosid);
      frmData.append("Op", this.dataQueueInfo['operatingReport']);
      frmData.append("Pathology", this.dataQueueInfo['pathology']);
      frmData.append("Pricing", this.dataQueueInfo['pricing']);
      frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
      frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
      frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
      frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
      frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
      frmData.append("InCoding", this.dataQueueInfo['inCoding']);
      frmData.append("Variance", this.dataQueueInfo['variance']);
      frmData.append("AuthReview", this.dataQueueInfo['authReview']);
      frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);

      frmData.append("txtNotes", "");
      frmData.append("file", this.fileAttachName, this.fileAttachName.name);
      frmData.append("Filetype", this.uploadFileText);

      this.getApiService.uploadFiles("BillingNew/Upload", frmData).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success("Uploaded Successfully!!", "Success");
          this.getPatientHeaderData();
          this.fileAttachName = "";
          this.uploadFile = null;
          this.uploadFileText = "";
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });

    }
  }

  fileAttachChanged(event) {
    this.fileAttachName = event.target.files[0];
  }

}
