export const operatingReport: NameConstant = { 
    id: "operatingReport", 
    value: "OP Note", 
    serverValue: "Op", 
    serverKey: "2", 
    bulkActionQueueName: "Op", 
    bulkActionClientQueueName: "OpReport",
    commandName: "OpReport"
};
export const pathology: NameConstant = { 
    id: "pathology",
    value: "Pathology", 
    serverValue: "Path Report", 
    serverKey: "3",
    bulkActionQueueName: "Pathology",
    bulkActionClientQueueName: "Pathology",
    commandName: "Pathology"
};
export const blockReport: NameConstant = { 
    id: "blockReport", 
    value: "Block Report", 
    serverValue: "BlockReport",
    serverKey: "4", 
    bulkActionQueueName: "BlockReport",
    bulkActionClientQueueName: "Block",
    commandName: "Block"
};
export const pricing: NameConstant = { 
    id: "pricing", 
    value: "Pricing", 
    serverKey: "5",
    bulkActionQueueName: "Pricing",
    bulkActionClientQueueName: "Pricing",
    commandName: "Pricing"
};
export const codingQuery: NameConstant = { 
    id: "codingQuery", 
    value: "Coding Query", 
    serverValue: "codingquery", 
    bulkActionQueueName: "Codingquery",
    bulkActionClientQueueName: "Coding",
    commandName: "Coding"
};
export const otherMisc: NameConstant = { 
    id: "otherMisc", 
    value: "Other/Misc", 
    bulkActionQueueName: "OtherMisc",
    bulkActionClientQueueName: "OtherMisc",
    commandName: "OtherMisc"
};
export const highApproval: NameConstant = { 
    id: "highApproval", 
    value: "Client High$$$", 
    serverValue: "High $$$",
    serverKey: "7", 
    bulkActionQueueName: "Client High$$$",
    bulkActionClientQueueName: "Client High$$$",
    commandName: "Client High$$$"
};
export const highApprovalInternal: NameConstant = { 
    id: "highApprovalInternal", 
    value: "NSN High$$$", 
    serverKey: "8",
    bulkActionQueueName: "NSN High$$$",
    commandName: "NSN High$$$"
 };
export const variance: NameConstant = { 
    id: "variance", 
    value: "Variance", 
    serverValue: "Coding Variance Review", 
    serverKey: "9",
    bulkActionQueueName: "Variance",
    bulkActionClientQueueName: "Variance",
    commandName: "Variance"
};
export const implantLog: NameConstant = { 
    id: "implantLog", 
    value: "Implant Log", 
    serverValue: "ImplantLog",
    serverKey: "6",
    commandName: "ImplantLog",
    bulkActionQueueName: "ImplantLog",
    bulkActionClientQueueName: "ImplantLog"
 };
export const invoice: NameConstant = { 
    id: "invoice", 
    value: "Invoice",
    serverKey: "11",
    serverValue: "Invoice",
    commandName: "Invoice",
    bulkActionQueueName: "Invoice",
    bulkActionClientQueueName: "Invoice"
};
export const inCoding: NameConstant = { id: "inCoding", value: "In Coding", serverValue: "InCoding", bulkActionQueueName: "InCoding" };
export const authReview: NameConstant = { id: "authReview", value: "Auth Review", serverValue: "AuthReview", commandName:  "AuthReview", bulkActionQueueName: "AuthReview" };
export const codingExpert: NameConstant = { id: "codingExpert", value: "Coding Expert", serverValue: "CodingExpert", commandName:  "CodingExpert", bulkActionQueueName: "CodingExpert" };

export const lcdReview: NameConstant = { id: "lcdReview", value: "LCD Review" };
export const scsReview: NameConstant = { id: "scsReview", value: "SCS Review" };
export const unlistedReview: NameConstant = { id: "unlistedReview", value: "Unlisted Review" };
export const otherMiscReview: NameConstant = { id: "otherMiscReview", value: "Other/Misc Review" };

export const na: NameConstant = { id: "na", value: "Not Applicable", serverValue: "NA" }
export const bdosid: NameConstant = { id: "bdosid", value: "", serverValue: "BdosID" }
export const caseRequest: NameConstant = { id: "caseRequest", value: "Case Request", serverValue: "BdosID" }

export const patientCaseRequest: NameConstant = { id: "patientCaseRequest", value: "Patient Case Request", serverValue: "" }

export const nsnCpt: NameConstant = { id: "nsn_cpt", value: "" };
export const physicianCpt: NameConstant = { id: "physician_cpt", value: "" };

export const cpt: NameConstant = { id: "cpt", value: "CPT", serverValue: "CPT" };


export const facilityId: NameConstant = { id: "facilityId", value: "Facility Id" };
export const clientRole: NameConstant = { 
    id: "client", 
    value: "Client"
};
export const clientLiteRole: NameConstant = { 
    id: "clientLite", 
    value: "ClientLite"
};

export const billerRole: NameConstant = {
    id: "biller",
    value: "Biller"
}

export const coderRole: NameConstant = {
    id: "coder",
    value: "Coder"
}
export const codingManagerRole: NameConstant = {
    id: "codingManager",
    value: "CodingManager"
}

export const adminRole: NameConstant = {
    id: "admin",
    value: "Admin"
}

export const pending: NameConstant = { id: "pending", value: "Pending" };
export const approved: NameConstant = { id: "approved", value: "Approved" };
export const rejected: NameConstant = { id: "rejected", value: "Rejected" };
export const criticalItem: NameConstant = { id: "criticalItem", value: "criticalItem" };

export const billerOnHold: NameConstant = { id: "billerOnHold", value: "Biller on Hold", serverValue: "BillerOnHold", commandName: "BillerOnHold" };
export const priority: NameConstant = { id: "priority", value: "Priority Items", serverValue: "priority", commandName: "priority" };
export const returnedCodingRFI: NameConstant = { 
    id: "returnedCodingRFI", 
    value: "Coding RFI", 
    serverValue: "ReturnedCodingRFI", 
    commandName: "ReturnedCodingRFI"
 };

export const codingReturned: NameConstant = { id: "codingReturned", value: "Coding Returned", serverValue: "CodingReturned", commandName: "CodingReturned" };
export const unassigned: NameConstant = { 
    id: "unassigned", 
    value: "Unassigned", 
    serverValue: "Unassigned", 
    commandName: "Unassigned",
    bulkActionClientQueueName: "Unassigned"
};
export const returned: NameConstant = { 
    id: "returned", 
    value: "Returned Request", 
    serverValue: "Returned", 
    commandName: "Returned",
    bulkActionClientQueueName: "Returned"
};
export const returnMail: NameConstant = { id: 'returnMail', value: 'Return Mail', serverValue: 'Returned Claim' };

export const bulkAction: NameConstant = { id: "bulkAction", value: "Bulk Action", serverValue: "BulkAction" };
export const globalComment: NameConstant = { id: "globalComment", value: "Global Comment" };
export const overdue: NameConstant = { 
    id: "overdue", 
    value: "Overdue", 
    bulkActionClientQueueName: "Overdue",
    commandName: "Overdue"
};
export const pendingclient: NameConstant = { 
    id: "pendingclient", 
    value: "Pending Client",
    serverValue: "pendingclient",
    commandName: "pendingclient", 
    bulkActionClientQueueName: "pendingclient"
 };

export const batchCaseReview : NameConstant = { id: "batchCaseReview", value: "Bulk Review", commandName: "In Review" };
export const reviewedCases : NameConstant = { id: "reviewedCases", value: "Reviewed Cases", commandName: "Reviewed" };
export const createBatchCaseReview : NameConstant = { id: "createBatchCaseReview", value: "Create Batch Review Cases" };

export const httpGetRequestMethod : NameConstant = { id: "httpGetRequestMethod", value: "GET" };
export const httpPOSTRequestMethod : NameConstant = { id: "httpPOSTRequestMethod", value: "POST" };
export const checkbox : NameConstant = { id: "checkbox", value: "CheckBox" };
export const quickAccess : NameConstant = { id: "quickAccess", value: "Quick Access" };
export const activeQueue : NameConstant = { id: "activeQueue", value: "Active Queue" };
export const action : NameConstant = { id: "action", value: "Action" };
export const reviewDone = { id: "reviewDone", value:"Review Done" };
export const toolsQaImplantLogComponent = { id: "toolsQaImplantLogComponent", value:"Tools Qa Implant Log Component" };
export const userProductivityReport = { id: "userProductivityReport", value:"User Productivity Report" };
export const userProductivityReportDetail = { id: "userProductivityReportDetail", value:"User Productivity Report Detail" };
export const caseQueueFacilityProductivityReport = { id: "caseQueueFacilityProductivityReport", value:"Case Queue Facility Report" };
export const caseQueueFacilityProductivityReportLine = { id: "caseQueueFacilityProductivityReportLine", value:"Case Queue Facility Report Line" };
export const managementReport = { id: "managementReport", value:"Management Report" };
export const managementReportLine = { id: "managementReportLine", value:"Management Report Line" };
export const facilityReport = { id: "facilityReport", value:"Facility Report" };
export const facilityReportLine = { id: "facilityReportLine", value:"Facility Report" };

export const codingReview = { id: "codingReview", value:"Coding Review", commandName: "In Review" };
export const codingReviewed = { id: "codingReviewed", value:"Coding Reviewed", commandName: "Reviewed" };
export const createCodingAction = { id: "createCodingAction", value:"Create Coding Action" };
export const masterCodingStatus = { id: "masterCodingStatus", value:"Coding Status" };

export interface NameConstant{
    id: string;
    value: string;
    serverValue?: string;
    serverKey?: string;
    commandName?: string;
    bulkActionQueueName?: string
    bulkActionClientQueueName?: string // adding this var because op note is named as Op Report here don't know why ??
}

export function getNameConstantMap(): Map<string, NameConstant>{
   return new Map([
       [operatingReport.id, operatingReport],
       [pathology.id, pathology],
       [blockReport.id, blockReport],
       [pricing.id, pricing],
       [codingQuery.id, codingQuery],
       [otherMisc.id, otherMisc],
       [highApproval.id, highApproval],
       [highApprovalInternal.id, highApprovalInternal],
       [variance.id, variance],
       [implantLog.id, implantLog],
       [invoice.id, invoice],
       [inCoding.id, inCoding],
       [authReview.id, authReview],
       [codingExpert.id, codingExpert],
       [lcdReview.id, lcdReview],
       [unlistedReview.id, unlistedReview],
       [otherMiscReview.id, otherMiscReview],
       [na.id, na],
       [bdosid.id, bdosid],
       [caseRequest.id, caseRequest],
       [patientCaseRequest.id, patientCaseRequest],
       [nsnCpt.id, nsnCpt],
       [cpt.id, cpt],
       [facilityId.id, facilityId],
       [clientRole.id, clientRole],
       [pending.id, pending],
       [approved.id, approved],
       [rejected.id, rejected],
       [criticalItem.id, criticalItem],
       [billerOnHold.id, billerOnHold],
       [returnedCodingRFI.id, returnedCodingRFI],
       [codingReturned.id, codingReturned],
       [unassigned.id, unassigned],
       [returned.id, returned],
       [returnMail.id, returnMail],
       [bulkAction.id, bulkAction],
       [globalComment.id, globalComment],
       [overdue.id, overdue],
       [pendingclient.id, pendingclient],
       [billerRole.id, billerRole],
       [priority.id, priority],
       [batchCaseReview.id, batchCaseReview],
       [httpGetRequestMethod.id, httpGetRequestMethod],
       [httpPOSTRequestMethod.id, httpPOSTRequestMethod],
   ])
}