<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{formFlag}} Patient</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <form class="pb-3 form-fields" [formGroup]="PatientsForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="form-group col-12">
                    <label for="inputFirstName" >Facility Name</label>

                    <select formControlName="FacilityId"  style="width: 100%;padding: 4px;" [(ngModel)]="FacilityId">
                        <option selected value="0" >Select</option>
                        <option *ngFor="let f of facilityList"  [value]="f.id" >{{f.name}}</option>
                    </select>
                    <!-- <ngx-select-dropdown *ngIf="formFlag == 'Add'" [config]="config" [options]="facilityList"
                        style="width: 100%;" formControlName="FacilityId" autoClearSearch=" true" [multiple]="false">
                    </ngx-select-dropdown>
                    <span *ngIf="formFlag == 'Edit'">:
                        <br>
                        <h3>{{facilityName}}</h3>
                    </span> -->
                    <div *ngIf="submitted && f.FacilityId.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label for="inputFirstName" class="required">Patient Details</label>
                </div>
            </div>
            <div class="row">
                <div class="form-group remove-botttom-space col-6">
                    <div class="input-group">
                        <input type="text" class="form-control required" formControlName="MRN" placeholder="MRN *"
                            (change)="chckMrn()" [ngClass]="{'is-invalid': submitted && f.MRN.errors }">
                    </div>
                    <div *ngIf="submitted && f.MRN.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                    <div *ngIf="f.MRN.errors?.unique" class="invalid-feedback">
                        <p>*MRN already exists!!</p>
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-6">
                    <div class="input-group">
                        <input type="number" class="form-control" formControlName="caseId" placeholder="Case Number"
                            (change)="elmBlankVal('caseId')" [ngClass]="{'is-invalid': submitted && f.caseId.errors }">
                    </div>
                    <div *ngIf="submitted && f.caseId.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group remove-botttom-space col-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="FirstName" placeholder="First Name *"
                            (change)="elmBlankVal('FirstName')"
                            [ngClass]="{'is-invalid': submitted && f.FirstName.errors }">
                    </div>
                    <div *ngIf="submitted && f.FirstName.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="LastName" placeholder="Last Name *"
                            (change)="elmBlankVal('LastName')"
                            [ngClass]="{'is-invalid': submitted && f.LastName.errors }">
                    </div>
                    <div *ngIf="submitted && f.LastName.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group remove-botttom-space col-6">
                    <!-- <div class="input-group">
                        <input type="date" class="form-control" [(ngModel)]="myDateValue "
                            [ngModelOptions]="{standalone: true}" (dateSelect)="onDateChange($event)" ngbDatepicker
                            #d="ngbDatepicker" (click)="d.toggle()" required min="12-01-2000" max="12-31-2040" format="MM-dd-yyyy" />
                    </div> -->

                    <div class="input-group">
                      
                         <input  type="text" class="form-control" placeholder="MM/DD/YYYY"  #dp="bsDatepicker"
                        bsDatepicker  (keydown)="disableDate()"
                          (dateSelect)="onDateChange($event)" 
                          formControlName="DOS"
                          >
                           

                    </div>
                    <!-- <input type="text" class="form-control" [(ngModel)]="myDateValue"
                                [ngModelOptions]="{standalone: true}" (dateSelect)="onDateChange($event)" ngbDatepicker
                                #d="ngbDatepicker" format="MM-dd-yyyy" (click)="d.toggle()" /> -->


                    <!-- <div *ngIf="submitted && myDateValue == ''" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div> -->
                </div>
                <div class="form-group remove-botttom-space col-6">
                    <select formControlName="Gender" class="custom-select form-control">
                        <option selected value="Gender" value="" disabled>Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                    </select>
                    <div *ngIf="submitted && f.Gender.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-6">
                    <div class="input-group">
                        <input type="text" class="form-control" [textMask]="{mask: phoneCodeMask}" [(ngModel)]="phone"
                            formControlName="phoneNo" placeholder="Phone Number" 
                            >
                    </div>
                    <div *ngIf="submitted && f.phoneNo.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
                <!-- <div class="form-group col-6">
                    <input type="text" class="form-control" [textMask]="{mask: ssnCodeMask}" [(ngModel)]="ssn"
                        formControlName="ssnNo" placeholder="SSN No" 
                        [ngClass]="{'is-invalid': submitted && f.ssnNo.errors }">
                    <div *ngIf="submitted && f.ssnNo.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div> -->
            </div>
            <h4 class="required">Physician Information</h4>
            <div class="row">
                <div class="form-group col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="physicianFirstName"
                            placeholder="First Name *" (change)="elmBlankVal('physicianFirstName')"
                            [ngClass]="{'is-invalid': submitted && f.physicianFirstName.errors }">
                    </div>
                    <div *ngIf="submitted && f.physicianFirstName.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="physicianLastName"
                            placeholder="Last Name *" (change)="elmBlankVal('physicianLastName')"
                            [ngClass]="{'is-invalid': submitted && f.physicianLastName.errors }">
                    </div>
                    <div *ngIf="submitted && f.physicianLastName.errors" class="invalid-feedback">
                        <p>*mandatory</p>
                    </div>
                </div>
            </div>
            <h4>Address Information</h4>
            <div class="row">
                <div class="form-group remove-botttom-space col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" #addresstext formControlName="Add1"
                            placeholder="Address1">
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="Add2" placeholder="Address2">
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="city" placeholder="City">
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="state" placeholder="State">
                    </div>
                </div>
                <div class="form-group  col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" formControlName="ZIP" placeholder="Zip">
                    </div>
                </div>
            </div>
            <div class="row extra-col-space">
                <div class="col-md-9">
                    <h4 class="required">Insurance</h4>
                </div>
                <div class="form-group remove-botttom-space extra-button col-md-3">
                    <div class="input-group">
                        <button type="button" (click)="addInsuranceList()"
                            class="btn btn-primary extra-button btn-patient-box"> <i
                                class="fas fa-plus fa-sm font-icn-color"></i>Add</button>
                    </div>
                </div>
            </div>
           

            <div formArrayName="InsuranceList">
                <div *ngFor="let rf of InsuranceListForm.controls; let a=index">
                    <div class="row" [formGroupName]="a">

                        <div class="form-group remove-botttom-space col-md-4">
                            <div class="input-group">
                                <select formControlName="InsuranceType" class="custom-select form-control"
                                    [ngClass]="{'is-invalid': submitted && rf.controls.InsuranceType.errors }">
                                    <option selected value="" disabled>Select Insurance</option>
                                    <option value="Primary">Primary</option>
                                    <option value="Secondary">Secondary</option>
                                    <option value="Tertiary">Tertiary</option>
                                </select>

                                <div *ngIf="submitted && rf.controls.InsuranceType.errors" class="invalid-feedback">

                                    <p>*mandatory</p>
                                </div>
                            </div>
                        </div>
                        <div class="form-group remove-botttom-space col-md-8">
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="Provider"
                                    [ngClass]="{'is-invalid': submitted && rf.controls.Provider.errors }"
                                    (change)="elmInsBlankVal('Provider', a)" placeholder="Provider *">
                            </div>
                            <div *ngIf="submitted && rf.controls.Provider.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="Group"
                                    [ngClass]="{'is-invalid': submitted && rf.controls.Group.errors }"
                                    (change)="elmInsBlankVal('Group', a)" placeholder="Group #">
                            </div>
                            <div *ngIf="submitted && rf.controls.Group.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                        </div>
                        <div class="form-group col-md-4">
                            <div class="input-group">
                                <input type="text" class="form-control" (change)="elmInsBlankVal('InsuranceId', a)"
                                    [ngClass]="{'is-invalid': submitted && rf.controls.InsuranceId.errors }"
                                    formControlName="InsuranceId" placeholder="Insurance ID">
                            </div>
                            <div *ngIf="submitted && rf.controls.InsuranceId.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                        </div>
                        <div *ngIf="a != 0" class="form-group remove-botttom-space align-input-user col-lg-3 col-md-4">
                            <div class="input-group">
                                <button type="button" (click)="removeInsuranceList(a)"
                                    class="btn btn-primary btn-color-minus btn-patient-box"> <i
                                        class="fas fa-minus fa-sm font-icn-color"></i>Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row extra-col-space">
                <div class="col-md-9">
                    <h4 class="required">CPT Codes</h4>
                </div>
                <div class="form-group remove-botttom-space extra-button col-md-3">
                    <div class="input-group">
                        <button type="button" (click)="addCptCodeList()"
                            class="btn btn-primary extra-button btn-patient-box"> <i
                                class="fas fa-plus fa-sm font-icn-color"></i>Add</button>
                    </div>
                </div>
            </div>
            <div formArrayName="CPTCodeList">
                <div *ngFor="let af of CPTCodeListForm.controls; let b=index">
                    <div class="row" [formGroupName]="b">
                        <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                            <div class="input-group">
                                <input id="typeahead-http" type="text" class="form-control"
                                    [class.is-invalid]="searchFailed" formControlName="cpt" [ngbTypeahead]="search"
                                    placeholder="CPT Code *" [resultFormatter]="formatter" [inputFormatter]="formatter"
                                    (selectItem)="onSelectItem($event, b)" (change)="chng(b)" /><br>


                                <!-- <input type="text" class="form-control" (change)="fetchDetails(b)"
                                    formControlName="CPTCode" placeholder="CPT Code"
                                    [ngClass]="{'is-invalid': submitted && af.controls.CPTCode.errors }"> -->
                            </div>
                            <small *ngIf="searching" class="form-text text-muted">searching...</small>
                            <div class="invalid-feedback" *ngIf="searchFailed">Sorry, suggestions could not be
                                loaded.</div>

                            <div *ngIf="submitted && af.controls.CPTCode.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                            <div *ngIf="af.controls.CPTCode.errors?.notFound" class="invalid-feedback">
                                <p>Invalid
                                    CPT code.</p>
                            </div>
                        </div>
                        <div class="form-group form-group remove-botttom-space align-input-user col-lg-5 col-md-6">
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="CPTDesc"
                                    aria-describedby="CPTDesc" placeholder="CPT Desc *"
                                    [ngClass]="{'is-invalid': submitted && af.controls.CPTDesc.errors }">
                            </div>
                            <div *ngIf="submitted && af.controls.CPTDesc.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                        </div>
                        <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                            <div class="input-group">
                                <input type="text" class="form-control" formControlName="Rate" placeholder="$0.00"
                                    (change)="transformAmount($event, b)" (mouseout)="transformAmount($event, b)"
                                    [ngClass]="{'is-invalid': submitted && af.controls.Rate.errors }" 
                                    oninput="this.value = 
                                    !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null">
                            </div>
                            <div *ngIf="submitted && af.controls.Rate.errors" class="invalid-feedback">
                                <p>*mandatory</p>
                            </div>
                        </div>
                            <!-- <div class="form-group remove-botttom-space align-input-user col-lg-3 col-md-6">
                                <div class="input-group">
                                <select formControlName="CPTCode"  class="custom-select form-control"
                                    >
                                    <option selected value="" disabled>CPT List </option>
                                    <option value="Scheduled">Scheduled</option>
                                    <option value="Coded">Coded</option>
                                    <option value="Billed">Billed</option>
                                </select>
                                </div>
                            </div>
                                 -->
                            
                            
                        <div *ngIf="b != 0" class="form-group remove-botttom-space align-input-user col-lg-3 col-md-4">
                            <div class="input-group">
                                <button type="button" (click)="removeCptCodeList(b)"
                                    class="btn btn-primary btn-color-minus btn-patient-box"> <i
                                        class="fas fa-minus fa-sm font-icn-color"></i>Remove</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="text-right">
                <button type="button" [disabled]="cancelButtonStatus" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="submit" class="btn btn-primary btn-patient-box" [disabled]="submitButtonStatus">
                    <span *ngIf="submitButtonStatus" class="fas fa-spinner fa-spin "></span>
                    {{submitButton}}
                </button>
                <!-- (click)="onSubmit()"
                <!-- <button [attr.disabled]=" submit == 'Submiting' ? true : null" id="bt" 
                class="btn btn-primary btn-patient-box"type="submit">
                    {{submit}}  
                     <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span></button> -->
            </div>
        </form>
    </div>
</div>
</div>
<!-- Sidenav-->