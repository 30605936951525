import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManageUsersComponent } from './manage-users.component';
import { ManageUsersFormComponent } from './manage-users-form/manage-users-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CopyUserFormComponent } from './copy-user-form/copy-user-form.component';

@NgModule({
  declarations: [ManageUsersComponent, ManageUsersFormComponent, CopyUserFormComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    NgxSpinnerModule
  ],
  exports: [ManageUsersFormComponent],
})
export class ManageUsersModule { }
