import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { applyTransaction } from '@datorama/akita';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AttachmentQuery } from 'src/app/shared/components/attachment/state/attachment.query';
import { AttachmentService } from 'src/app/shared/components/attachment/state/attachment.service';
import { approved, authReview, blockReport, bulkAction, codingExpert, codingQuery, globalComment, highApproval, highApprovalInternal, implantLog, inCoding, invoice, na, NameConstant, nsnCpt, operatingReport, otherMisc, pathology, pending, physicianCpt, pricing, rejected, variance } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { CaseRequestHelper } from '../patient-action/case-request/helper/case-request.helper';
import { CaseRequestCptService } from '../patient-action/case-request/state/case-request-cpt/case-request-cpt.service';
import { CaseRequestInfoQuery } from '../patient-action/case-request/state/case-request-info/case-request-info.query';
import { CaseRequestInfoService } from '../patient-action/case-request/state/case-request-info/case-request-info.service';
import { CaseRequest } from '../patient-action/case-request/state/case-request/case-request.model';
import { CaseRequestQuery } from '../patient-action/case-request/state/case-request/case-request.query';
import { CaseRequestService } from '../patient-action/case-request/state/case-request/case-request.service';
import { createTab } from '../patient-action/case-request/state/tabs/tab.model';
import { TabsService } from '../patient-action/case-request/state/tabs/tabs.service';
import { BulkActionColDef, BulkActionConfig } from './bulk-action-config/bulk-action-config';
import { BulkActionRequestStatusQuery } from './state/bulk-action-request-status/bulk-action-request-status.query';
import { BulkActionRequestStatusService } from './state/bulk-action-request-status/bulk-action-request-status.service';
import { BulkActionSelectionQuery } from './state/bulk-action-selection/bulk-action-selection.query';
import { BulkActionSelectionService } from './state/bulk-action-selection/bulk-action-selection.service';
import * as caseRequestList from '../patient-action/case-request/enums/case-request-name';
import { CaseRequestCptQuery } from '../patient-action/case-request/state/case-request-cpt/case-request-cpt.query';
import { SessionQuery } from '../../login/session/session.query';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { CommentsService } from 'src/app/shared/state/comments/comments.service';
import { createComment } from 'src/app/shared/state/comments/comment.model';
import { CommentsQuery } from 'src/app/shared/state/comments/comments.query';

@Component({
  selector: 'app-bulk-action',
  templateUrl: './bulk-action.component.html',
  styleUrls: ['./bulk-action.component.css'],
  providers: [NgxSpinnerService]
})
export class BulkActionComponent implements OnInit, OnDestroy {
  @Output() closeEmitter = new EventEmitter();
  @Input() requesToSatisfy: NameConstant; // only for client
  componentId: string = bulkAction.id;
  BulkActionForm: FormGroup;
  caseRequestList$: Observable<CaseRequest[]>;
  activePatient: Patient[];
  bulkActionColDefList: BulkActionColDef[];
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  bulkActionSelection$: Observable<string>;

  operatingReport: NameConstant = operatingReport;
  pathology: NameConstant = pathology;
  pricing: NameConstant = pricing;
  blockReport: NameConstant = blockReport;
  codingQuery: NameConstant = codingQuery;
  otherMisc: NameConstant = otherMisc;
  authReview: NameConstant = authReview;
  clientHighApproval: NameConstant = highApproval;
  nsnHighApproval: NameConstant = highApprovalInternal;
  codingExpert: NameConstant = codingExpert;
  inCoding: NameConstant = inCoding;
  variance: NameConstant = variance;
  invoice: NameConstant = invoice;
  implantLog: NameConstant = implantLog;

  defaultStatus: string = na.id;
  pendingStatus: string = pending.value
  approvedStatus: string = approved.value;
  rejectStatus: string = rejected.value;

  globalCommentFormControl;
  selectedPatient: Patient;
  isClientRole: boolean;
  sub: Subscription;
  loading: boolean;

  constructor(
    private caseRequestQuery: CaseRequestQuery,
    private caseRequestService: CaseRequestService,
    private patientQuery: PatientQuery,
    private patientService: PatientService,
    private tabsService: TabsService,
    private caseRequestInfoService: CaseRequestInfoService,
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private bulkActionSelectionService: BulkActionSelectionService,
    private bulkActionSelectionQuery: BulkActionSelectionQuery,
    private bulkActionRequestStatusService: BulkActionRequestStatusService,
    private bulkActionRequestStatusQuery: BulkActionRequestStatusQuery,
    private caseRequestCptService: CaseRequestCptService,
    private caseRequestCptQuery: CaseRequestCptQuery,
    private attachmentQuery: AttachmentQuery,
    private attachmentService: AttachmentService,
    private apiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private sessionQuery: SessionQuery,
    private selectionQury: SelectionQuery,
    private commentService: CommentsService,
    private commentQuery: CommentsQuery
  ) { }

  ngOnInit(): void {
    this.isClientRole = this.sessionQuery.isClientRole();
    this.caseRequestService.init();
    this.caseRequestList$ = this.caseRequestQuery.selectAll();
    this.activePatient = this.patientQuery.getActive();
    this.initForm();
    this.bulkActionSelection$ = this.bulkActionSelectionQuery.select().pipe(map(s => s.value));
    this.sub = this.bulkActionRequestStatusService.get();
    if(this.isClientRole){
      let requestNameConstant = BulkActionConfig.requestToNameConstanMap.get(this.requesToSatisfy.bulkActionClientQueueName);
      if(!requestNameConstant){
        console.log("No mapping found for reuest. Please add one.", this.requesToSatisfy);
        this.toastr.error("No mapping found for reuest. Please add one.", this.requesToSatisfy.id );
        return;
      }
     this.onQueueChange({ value: requestNameConstant}); 
    }else{
      this.bulkActionColDefList = BulkActionConfig.map.get(operatingReport.id);
    }
    this.initiatliseCommentStore();
  }

  private initiatliseCommentStore(){
    const commentSub = this.commentService.get(this.componentId).subscribe(res => this.addBlankGlobalComment());
    this.sub?.add(commentSub);
  }
  ngOnDestroy() {
    this.tabsService.reset();
    this.caseRequestInfoService.reset();
    this.commentService.reset();
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  initForm() {
    let ac = {};
    for (let i = 0; i < this.activePatient.length; i++) {
      ac[this.activePatient[i].bdosid] = new FormControl('', Validators.required);
    }
    this.BulkActionForm = new FormGroup(ac);
    this.BulkActionForm.addControl("globalComment", new FormControl(""));
    this.globalCommentFormControl = this.BulkActionForm.get('globalComment');
  }

  removeActive(data) {
    console.log("removeActive ", data);
    this.patientService.toggleActive(data.bdosid);
    this.activePatient = this.activePatient.filter(p => p.bdosid !== data.bdosid);
    this.BulkActionForm.removeControl(data.bdosid);
  }

  onQueueChange(event$) {
    if (event$.value) {
      this.bulkActionColDefList = BulkActionConfig.map.get(event$.value.id);
      this.activePatient.map(q => {
        this.BulkActionForm.controls[q.bdosid].setValue(event$.value);
        this.patientService.updateQueueName(q.bdosid, event$.value.id);
        this.clearAttachment(q.bdosid);
        this.BulkActionForm.controls[q.bdosid].setErrors(null);
      });
    }
  }

  onRowQueueChange(event$, patient: Patient) {
    if (event$.value) {
      this.patientService.updateQueueName(patient.bdosid, event$.value.id);
      this.clearAttachment(patient.bdosid);
      this.BulkActionForm.controls[patient.bdosid].setErrors(null);
      console.log(this.BulkActionForm.controls[patient.bdosid]);
      console.log(this.BulkActionForm);
      
    }
  }

  private addBlankGlobalComment(){
    this.commentService.add(createComment({ id: globalComment.id, reqType: globalComment.value, note: "", userid: na.id, createddate: new Date() }));
  }
  updateComment(newComment: string){
    this.commentService.update(globalComment.id, { note: newComment });
  }
  private clearAttachment(bdosid: string | number) {
    this.attachmentService.remove(bdosid);
  }
  getRequestStatus(bdosid): string {
    if (!this.BulkActionForm.controls[bdosid].value) {
      return "";
    }

    const value = this.BulkActionForm.controls[bdosid].value.id;
    const id = bdosid + "_" + value
    const caseRequestStatus = this.bulkActionRequestStatusQuery.getEntity(id);
    if (!caseRequestStatus) {
      return this.defaultStatus;
    }
    return caseRequestStatus.status;
  }

  getAction(formControlName) {
    return this.BulkActionForm.controls[formControlName].value;
  }

  onClickAction(patient: Patient) {
    this.selectedPatient = patient;
    const formControlName = patient.bdosid;
    console.log("onClickAction", this.BulkActionForm.controls[formControlName].value);
    this.caseRequestInfoService.reset();
    const cr = this.BulkActionForm.controls[formControlName].value;
    this.caseRequestInfoService.fetchAndload(formControlName, true).subscribe(res => {
      console.log("After loading ..", this.caseRequestInfoQuery.getAll());
      this.tabsService.add(createTab({
        id: cr.id,
        value: cr.value,
        displayName: cr.displayName, href: '#' + cr.id,
        type: this.caseRequestHelper.getRequstType(cr.id),
        note: ""
      }));
      this.tabsService.setActive(cr.id);
      const value = this.BulkActionForm.controls[formControlName].value.id;
      this.bulkActionSelectionService.changeSelection(value);
    });
  }

  isActionAllowed(formControlName): boolean {
    const cr = this.BulkActionForm.controls[formControlName].value;
    return this.isRequestAllowed(cr.id);
  }

  isRequestAllowed(requestId: string) {
    return requestId === highApprovalInternal.id || requestId === highApproval.id || requestId === variance.id;
  }
  onClickClose() {
    this.bulkActionSelectionService.changeSelection("");
    this.caseRequestCptService.reset();
    this.attachmentService.destroy();
    this.commentService.reset();
    this.closeEmitter.emit();
  }

  backToBulkScreen() {
    this.bulkActionSelectionService.changeSelection("");
    this.caseRequestCptService.reset();
    this.caseRequestInfoService.reset();
  }


  private isClientHighOrNSNHighDollorRequest(queueName: string): boolean {
    return this.isClientHighDollorRequest(queueName) || this.isNSNHighDollorRequest(queueName);
  }

  private isClientHighDollorRequest(queueName: string): boolean {
    return queueName === caseRequestList.highApproval.bulkActionQueueName;
  }

  private isNSNHighDollorRequest(queueName: string): boolean {
    return queueName === caseRequestList.highApprovalInternal.bulkActionQueueName;
  }

  private isVarianceRequest(queueName: string): boolean {
    return queueName === caseRequestList.variance.bulkActionQueueName;
  }

  private addCptDetailsForClientHighOrNSNHighDollorRequest(frmData: FormData, index: number, caseRequestId: string | number, bdosid: string | number) {
    frmData.append("RFI[" + index + "].RequestDollarChanges", "true");
    const cptList = this.caseRequestCptQuery.getCptByRequestAndPatientId(caseRequestId, bdosid);
    if (cptList && cptList.length > 0) {
      for (let e = 0; e < cptList.length; e++) {
        frmData.append("RFI[" + index + "].DollarRequests[" + e + "].CPT", cptList[e].cptCode);
        frmData.append("RFI[" + index + "].DollarRequests[" + e + "].CPTDesc", cptList[e].cptDescription);
        frmData.append("RFI[" + index + "].DollarRequests[" + e + "].Cost", cptList[e].rate.toString());
      }
    } else {
      console.log("there is no cpt for rqueest" + caseRequestId + " and bdosid " + bdosid);
    }
  }

  private addCptDetailsForVarianceRequest(frmData: FormData, index: number, bdosid: string | number) {
    frmData.append("RFI[" + index + "].RequestVarianceChanges", "true");
    const cptVarArry = this.caseRequestCptQuery.getAllVarianceForPatient(bdosid);

    for (let f = 0; f < (cptVarArry.length) / 2; f++) {
      const cptVarAtIndex = cptVarArry.filter(cptVar => cptVar.uiIndex === f);
      const nsnVarCpt = cptVarAtIndex.find(cptVarIndex => cptVarIndex.codingType === nsnCpt.id)
      const physicianVarCpt = cptVarAtIndex.find(cptVarIndex => cptVarIndex.codingType === physicianCpt.id)
      frmData.append("RFI[" + index + "].RequestedVariance[" + f + "].CPT", nsnVarCpt.cptCode);
      frmData.append("RFI[" + index + "].RequestedVariance[" + f + "].CPTDesc", nsnVarCpt.cptDescription);
      frmData.append("RFI[" + index + "].RequestedVariance[" + f + "].CPTAlt", physicianVarCpt.cptCode);
      frmData.append("RFI[" + index + "].RequestedVariance[" + f + "].CPTAltDesc", physicianVarCpt.cptDescription);
      frmData.append("RFI[" + index + "].RequestedVariance[" + f + "].Cost", nsnVarCpt.rate.toString().valueOf() + physicianVarCpt.rate.toString().valueOf());
    }
  }

  private addCommentToRequest(frmData: FormData, index: number, bdosid: string | number, caseRequestId: string, queueName: string){
    const attachment = this.attachmentQuery.getEntity(bdosid);
    if (attachment && attachment.comments) {
      if(this.isClientSatisfyingDollorRequest(queueName)){
        frmData.append("RFI[" + index + "].RequestDollarComments", attachment.comments);
      }else if(this.isClientSatisfyingVarianceRequest(queueName)){
        frmData.append("RFI[" + index + "].RequestVarianceComments", attachment.comments); 
      }else{
        frmData.append("RFI[" + index + "]._requestNote", attachment.comments);
      }
    }else{
      if(this.sessionQuery.isClientRole()){
        const globalCommentFromStore = this.commentQuery.getGlobalComment();
        if(globalCommentFromStore){
          if(this.isClientSatisfyingDollorRequest(queueName)){
            frmData.append("RFI[" + index + "].RequestDollarComments", globalCommentFromStore);
          } else if(this.isClientSatisfyingVarianceRequest(queueName)){
            frmData.append("RFI[" + index + "].RequestVarianceComments", globalCommentFromStore); 
          }
          else{
            frmData.append("RFI[" + index + "]._requestNote", globalCommentFromStore);
          }
        }
      }else{ // variance request will not be satisfied by biller
        const defaultComment = this.commentQuery.getCommentForRequest(caseRequestId);
        if(defaultComment){
          if(this.isClientSatisfyingDollorRequest(queueName)){
            frmData.append("RFI[" + index + "].RequestDollarComments", defaultComment);
          }else{
            frmData.append("RFI[" + index + "]._requestNote", defaultComment);
          }
        }
      }
    }
  }

  private addFileDetailsToRequest(frmData: FormData, index: number, bdosid: string | number){
    const attachment = this.attachmentQuery.getEntity(bdosid);
    if (attachment && attachment.fileName) {
      frmData.append("RFI[" + index + "]._requestFileName", attachment.fileName);
      frmData.append("RFI[" + index + "]._rfiFile", attachment.file);
    }
  }

  private addPrimaryCptToRequest(frmData: FormData, index: number, queueName: string, bdosid: string | number){
    if(this.isImplantLog(queueName) || this.isInvoice(queueName)){
      const attachment = this.attachmentQuery.getEntity(bdosid);
      if (attachment && attachment.primaryCpt) {
        frmData.append("RFI[" + index + "].RequestCPT", attachment.primaryCpt);
      }
    }
  }


  private addApprovalOrRejectStatus(frmData: FormData, index: number, bdosid: string | number){
    const acttachment = this.attachmentQuery.getEntity(bdosid);
    if(acttachment && acttachment.satisfyAction){
      frmData.append("RFI[" + index + "].RequestDollarStatus", acttachment.satisfyAction);
    }else{
      console.log("Satisfy action not specified for bdosid", bdosid);
    }
  }

  private addApprovalOrRejectStatusForVariance(frmData: FormData, index: number, bdosid: string | number){
    const acttachment = this.attachmentQuery.getEntity(bdosid);
    if(acttachment && acttachment.satisfyAction){
      frmData.append("RFI[" + index + "].RequestVarianceStatus", acttachment.satisfyAction);
    }else{
      console.log("Satisfy action not specified for bdosid", bdosid);
    }
  }

  private isClientSatisfyingDollorRequest(queueName: string){
    return this.sessionQuery.isClientRole() && this.isClientHighOrNSNHighDollorRequest(queueName)
  }

  private isClientSatisfyingVarianceRequest(queueName: string){
    return this.sessionQuery.isClientRole() && this.isVarianceRequest(queueName)
  }
  private isBillerSubmittingVarianceRequest(queueName: string): boolean{
    return this.sessionQuery.isBillerRole() && this.isVarianceRequest(queueName)
  }

  private isImplantLog(queueName: string): boolean{
    return queueName === caseRequestList.implantLog.bulkActionQueueName;
  }

  private isInvoice(queueName: string): boolean{
    return queueName === caseRequestList.invoice.bulkActionQueueName;
  }


  private isBillerSubmittingDollorRequest(queueName: string): boolean{
    return this.sessionQuery.isBillerRole() && this.isClientHighOrNSNHighDollorRequest(queueName)
  }
  

  private isActionMandatory(queueName: string, bdosid: string | number): [boolean, string]{
    // check for attachment, attachment object will be created if and only if client has clicked Approve or Reject or Queue on Biller side
    const attachment = this.attachmentQuery.getEntity(bdosid);
    const prefix = "Please click on writing pad icon and "
      if(this.isClientHighOrNSNHighDollorRequest(queueName) || this.isVarianceRequest(queueName)){
        // if attachment object does not exist which means user has not click approve or reject or Queue, so we skip this.
        const message = this.sessionQuery.isClientRole() ? prefix+"approve or reject" : "queue request";
        return (attachment === undefined) ? [true, message] : [false, ''];      
      }else if(this.isImplantLog(queueName) || this.isInvoice(queueName)){
        const message = this.sessionQuery.isClientRole()? prefix+"add attachment" : prefix+" queue request";
        return (attachment === undefined) ? [true, message] : [false, '']; 
      }
    
    return [false, '']; // not a client role, do not skip request for any request
  }
  hasFormControlError(fBdosid): boolean{
    return this.BulkActionForm.controls[fBdosid].invalid;
  }
  getFormControlError(fBdosid){
    return this.BulkActionForm.controls[fBdosid].getError('invalid');
  }

  onBulkSubmit() {
   this.loading = true;
    this.activePatient.map(p => {
      const pBdosid = p.bdosid;
      const formControlValue = this.BulkActionForm.controls[pBdosid].value
      if(!formControlValue){
        this.BulkActionForm.controls[pBdosid].setErrors({invalid: 'please select queue'});
      }else{
        const queueName = formControlValue.bulkActionQueueName;
        const hasError = this.isActionMandatory(queueName, pBdosid);
        if(hasError[0]){
          this.BulkActionForm.controls[pBdosid].setErrors({invalid: hasError[1]});
        }
      }
    });

    if(this.BulkActionForm.invalid){
      console.error("please correct error before submitting bulk request");
      this.loading = false;
      return;
    }
   
    this.spinner.show();
    const patientToSubmit = this.activePatient;
    console.log("patient request to bulk submit", patientToSubmit);
    let frmData = new FormData();
    for (let e = 0; e < patientToSubmit.length; e++) {
      const bdosid = patientToSubmit[e].bdosid;
      const queueName = this.BulkActionForm.controls[bdosid].value?.bulkActionQueueName;
      const caseRequestId = this.BulkActionForm.controls[bdosid].value.id;
      if (!queueName) {
        this.spinner.hide();
        this.loading = false;
        this.toastr.error("Unexpected error, could not evaluate the queue name");
        return;
      }

      //BAD HACK: start:  this if condition is added because of last moment chnage by balajee for demo
      if(this.isImplantLog(queueName)){
        frmData.append("RFI[" + e + "]._requestType", implantLog.value);
      }else{
        frmData.append("RFI[" + e + "]._requestType", queueName);
      }
      if(this.isImplantLog(queueName) || this.isInvoice(queueName)){
        frmData.append("RFI[" + e + "].facilityId", patientToSubmit[e].facilityId?.toString());
      }
      //BAD HACK: end
      frmData.append("RFI[" + e + "]._requestBdosid", bdosid.toString());

      this.addCommentToRequest(frmData, e, bdosid, caseRequestId, queueName);

      if (this.isBillerSubmittingDollorRequest(queueName)) {
        this.addCptDetailsForClientHighOrNSNHighDollorRequest(frmData, e, caseRequestId, bdosid);
      }

      if(this.isClientSatisfyingDollorRequest(queueName)){
        this.addApprovalOrRejectStatus(frmData, e, bdosid);
      }
      

      if (this.isBillerSubmittingVarianceRequest(queueName)) {
        this.addCptDetailsForVarianceRequest(frmData, e, bdosid);
      }

      if(this.isClientSatisfyingVarianceRequest(queueName)){
        this.addApprovalOrRejectStatusForVariance(frmData, e, bdosid);
      }
      
      this.addFileDetailsToRequest(frmData, e, bdosid);
      this.addPrimaryCptToRequest(frmData, e, queueName, bdosid);

    }
    this.apiService.uploadFiles("BillingNew/PatientBulkAction", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.spinner.hide();
        this.loading = false;
        this.toastr.success("Saved Successfully!!", "Success");
        //   this.onClickClose();
        this.onClickClose();
      }
      else {
        this.spinner.hide();
        this.loading = false;
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.loading = false;
      this.toastr.error(error, 'Error!');
    });

  }

  isCommentOrAttachmentAdded(bdosid): boolean {
    return this.attachmentQuery.hasEntity(bdosid);
  }
  configForQueue = {
    displayKey: "value", //if objects array passed which key to be displayed defaults to description
    search: false, //true/false for the search functionlity defaults to false,
    height: '16rem', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Queue', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No facility found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Facility', // label thats displayed in search input,
    searchOnKey: "value", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }
}
