<!-- Begin Page Content -->
<div class="container-fluid width-index">
    <div class="container1">
        <!-- Daily Progress-->
        <div class="row daily-progress">
            <div class="col-md-4">
                <div class="border-progress">
                    <p class="progress-text">Daily Progress</p>
                    <!-- <img src="assets/img/dashboard-Progress-logo.png" alt="" class="dashboard-logo-side"> -->
                    <p class="progress-bar-text">A/R (0 - 60)</p>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0"
                            aria-valuemax="100" style="width:90%">
                            <span class="sr-only">85% Complete</span>
                        </div>
                    </div>
                    <p class="progress-bar-text-second">Total A/R</p>
                    <div class="progress">
                        <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="70"
                            aria-valuemin="0" aria-valuemax="100" style="width:70%">
                            <span class="sr-only">70% Complete</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8">
                <p class="progress-text-right">
                    Client Goal Tracker
                </p>
                <p class="monthly-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>18
                    <span class="monthly-progress-box1">Avg %</span></p>

                <p class="monthly-icon-text"><i class="fa fa-file-invoice fa-color-blue"></i>$2104
                    <span class="monthly-progress-box1">Cash Goal</span>
                </p>


                <p class="monthly-icon-text"><i class="fa fa-calendar fa-color-blue"></i>65
                    <span class="monthly-progress-box1">Actual</span>
                </p>

                <p class="monthly-icon-text"><i class="fa fa-file-invoice-dollar fa-color-blue"></i>105%
                    <span class="monthly-progress-box1">% of Goal</span>

                </p>


                <p class="monthly-icon-text monthly-icon-text-billed"><i
                        class="fa fa-file-invoice-dollar fa-color-blue"></i>$210,460
                    <span class="monthly-progress-box1">Variance of Goal</span>
                </p>
            </div>
        </div>
        <!-- daily Progreess End-->
    </div>
    <!-- container end-->
    <!-- /.container-fluid -->
</div>
<!-- Sugery Center Section-->
<div class="container-fluid cont-mrg width-index">
    <div class="container1">
        <div class="row advance-surgery-center">
            <div class="col-md-4 surgery-row">
                <p class="advance-section-text">HITech Surgery Center <br /></p>
            </div>
            <div class="col-md-8" style="padding: 0px;">
                <p class="advance-icon-text">
                    <!--<i class="fa fa-project-diagram fa-color-blue"></i>10
                <span class="advance-progress-box1">Priority Items</span>--> &nbsp;
                </p>
                <p class="advance-icon-text">
                    <!--<i class="fa fa-list-alt fa-color-blue"></i>10
                <span class="advance-progress-box1">Action Items</span>-->&nbsp;
                </p>
                <p class="advance-icon-text">$210,460
                    <span class="advance-billed-box1">Total Billed</span>
                </p>
                <div class="adv-progress-bar">
                    <p class="progress-bar-text-second wdth-advance">Billed Today</p>
                    <div class="progress">
                        <div class="progress-bar bar-third-color" role="progressbar" aria-valuenow="50"
                            aria-valuemin="0" aria-valuemax="100" style="width:50%">
                            <span class="sr-only">70% Complete</span>
                        </div>
                    </div>
                    <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">2</span> Day Untill EOM
                    </p>
                </div>
                <p class="advance-icon-text price-box1 ">
                    <span class="surgety-number mr-1">$290,130</span>
                    <span class="spn-goaltext">Goal</span><br>
                    <span class="surgety-number mr-1">$290,130</span>
                    <span class="spn-goaltext">Projected</span>
                </p>
                <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,41)"><i
                        *ngIf="selectedIndex != 41" class="fa fa-plus"></i>
                    <i *ngIf="selectedIndex == 41" class="fa fa-minus"></i>
                </p>
            </div>
        </div>
        <!-- Sugery Center Section End-->
        <!-- container fluid advcance secttion end-->
        <!-- Facility Action Section-->
        <!-- <div class="container-fluid" > -->
        <!-- <div class="container1"> -->
        <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 41">
            <table class="" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Total A/R</th>
                        <th>A/R (0-60) </th>
                        <th>A/R (61+)</th>
                        <th>Avg %</th>
                        <th>Cash Goal</th>
                        <th>Actual</th>
                        <th>Region </th>
                        <th>Team </th>
                        <th>% of Goal</th>
                        <th>Region</th>
                        <th>Variance Of Goal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Jan</td>
                        <td>$26500.56</td>
                        <td>$25416.56</td>
                        <td>$125600</td>
                        <td>18%</td>
                        <td>$65545</td>
                        <td>$5487584</td>
                        <td>Northest</td>
                        <td>Amy</td>
                        <td>105%</td>
                        <td>$895540</td>
                        <td>$(55450)</td>

                    </tr>




                </tbody>
            </table>

        </div>
        <!-- container end-->
    </div>
</div>
<!-- container fluid advcance secttion end-->
<!-- Sugery Center Section-->
<div class="container-fluid cont-mrg width-index">
    <div class="container1">
        <div class="row advance-surgery-center">
            <div class="col-md-4 surgery-row">
                <p class="advance-section-text">HITech Surgery Center II <br /></p>
            </div>
            <div class="col-md-8" style="padding: 0px;">
                <p class="advance-icon-text">
                    <!--<i class="fa fa-project-diagram fa-color-blue"></i>10
                <span class="advance-progress-box1">Priority Items</span>--> &nbsp;
                </p>
                <p class="advance-icon-text">
                    <!--<i class="fa fa-list-alt fa-color-blue"></i>10
                <span class="advance-progress-box1">Action Items</span>-->&nbsp;
                </p>
                <p class="advance-icon-text">$210,460
                    <span class="advance-billed-box1">Total Billed</span>
                </p>
                <div class="adv-progress-bar">
                    <p class="progress-bar-text-second wdth-advance">Billed Today</p>
                    <div class="progress">
                        <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="80"
                            aria-valuemin="0" aria-valuemax="100" style="width:80%">
                            <span class="sr-only">70% Complete</span>
                        </div>
                    </div>
                    <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">2</span> Day Untill
                        EOM
                    </p>
                </div>
                <p class="advance-icon-text price-box1 ">
                    <span class="surgety-number mr-1">$290,130</span>
                    <span class="spn-goaltext">Goal</span><br>
                    <span class="surgety-number mr-1">$290,130</span>
                    <span class="spn-goaltext">Projected</span>
                </p>
                <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,42)"><i
                        *ngIf="selectedIndex != 42" class="fa fa-plus"></i>
                    <i *ngIf="selectedIndex == 42" class="fa fa-minus"></i>
                </p>
            </div>
            <!-- </div> -->
            <!-- Sugery Center Section End-->
        </div>

        <!-- container fluid advcance secttion end-->
        <!-- Facility Action Section-->
        <!-- <div class="container-fluid" > -->
        <!-- <div class="container1"> -->
        <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 42">
            <table class="" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Total A/R</th>
                        <th>A/R (0-60) </th>
                        <th>A/R (61+)</th>
                        <th>Avg %</th>
                        <th>Cash Goal</th>
                        <th>Actual</th>
                        <th>Region </th>
                        <th>Team </th>
                        <th>% of Goal</th>
                        <th>Region</th>
                        <th>Variance Of Goal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Jan</td>
                        <td>$26500.56</td>
                        <td>$25416.56</td>
                        <td>$125600</td>
                        <td>18%</td>
                        <td>$65545</td>
                        <td>$5487584</td>
                        <td>Northest</td>
                        <td>Amy</td>
                        <td>105%</td>
                        <td>$895540</td>
                        <td>$(55450)</td>

                    </tr>




                </tbody>
            </table>
        </div>
        <!-- container end-->
    </div>
</div>
<!-- Sugery Center Section End-->
<!-- </div> -->
<!-- container end-->
<!-- </div> -->
<!-- container fluid Facility Action Secttion end-->
<!-- Sugery Center Section-->
<div class="container-fluid cont-mrg width-index">
    <div class="container1">
        <div class="row advance-surgery-center">
            <div class="col-md-4 surgery-row">
                <p class="advance-section-text">HITech Surgery Center III <br /> </p>
            </div>
            <div class="col-md-8" style="padding: 0px;">
                <p class="advance-icon-text">
                    <!--<i class="fa fa-project-diagram fa-color-blue"></i>10
                <span class="advance-progress-box1">Priority Items</span>--> &nbsp;
                </p>
                <p class="advance-icon-text">
                    <!--<i class="fa fa-list-alt fa-color-blue"></i>10
                <span class="advance-progress-box1">Action Items</span>-->&nbsp;
                </p>
                <p class="advance-icon-text">$210,460
                    <span class="advance-billed-box1">Total Billed</span>
                </p>
                <div class="adv-progress-bar">
                    <p class="progress-bar-text-second wdth-advance">Billed Today</p>
                    <div class="progress">
                        <div class="progress-bar bar-fourth-color" role="progressbar" aria-valuenow="30"
                            aria-valuemin="0" aria-valuemax="100" style="width:30%">
                            <span class="sr-only">70% Complete</span>
                        </div>
                    </div>
                    <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">2</span> Day Untill
                        EOM
                    </p>
                </div>
                <p class="advance-icon-text price-box1 ">
                    <span class="surgety-number mr-1">$290,130</span>
                    <span class="spn-goaltext">Goal</span><br>
                    <span class="surgety-number mr-1">$290,130</span>
                    <span class="spn-goaltext">Projected</span>
                </p>
                <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,43)"><i
                        *ngIf="selectedIndex != 43" class="fa fa-plus"></i>
                    <i *ngIf="selectedIndex == 43" class="fa fa-minus"></i>
                </p>
            </div>
        </div>
        <!-- Sugery Center Section End-->
        <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 43">
            <table class="" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr>
                        <th>Month</th>
                        <th>Total A/R</th>
                        <th>A/R (0-60) </th>
                        <th>A/R (61+)</th>
                        <th>Avg %</th>
                        <th>Cash Goal</th>
                        <th>Actual</th>
                        <th>Region </th>
                        <th>Team </th>
                        <th>% of Goal</th>
                        <th>Region</th>
                        <th>Variance Of Goal</th>
                    </tr>
                </thead>

                <tbody>
                    <tr>
                        <td>Jan</td>
                        <td>$26500.56</td>
                        <td>$25416.56</td>
                        <td>$125600</td>
                        <td>18%</td>
                        <td>$65545</td>
                        <td>$5487584</td>
                        <td>Northest</td>
                        <td>Amy</td>
                        <td>105%</td>
                        <td>$895540</td>
                        <td>$(55450)</td>

                    </tr>




                </tbody>
            </table>
        </div>
    </div>
    <!-- container end-->
</div>
<!-- container fluid advcance secttion end-->
<!-- End of Content Wrapper -->
<!-- </div> -->
<!-- End of Page Wrapper -->