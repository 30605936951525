import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { CodingStatus } from './coding-status.model';
import { CodingStatusStore } from './coding-status.store';

@Injectable({ providedIn: 'root' })
export class CodingStatusService {

  constructor(private codingStatusStore: CodingStatusStore, private apiService: ApiService) {
  }


set(entities: CodingStatus[]){
  this.codingStatusStore.set(entities);
}

setActive(id: ID){
  this.codingStatusStore.setActive(id);
}
  add(codingStatus: CodingStatus) {
    this.codingStatusStore.add(codingStatus);
  }

  update(id, codingStatus: Partial<CodingStatus>) {
    this.codingStatusStore.update(id, codingStatus);
  }

  remove(id: ID) {
    this.codingStatusStore.remove(id);
  }

}
