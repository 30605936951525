<div class="row" style="padding-top: 20px;" *ngIf="callerId === returnMail">
    <p class="comment-heading-case">{{label}}</p>
    <div class="col-md-12 form-fields">
        <div class="row back-color-comnt1">
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">Date</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">File Type</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">User</p>
            </div>
            <div class="form-group1 col-md-4">
                <p class="patient-tab-above">File Details</p>
            </div>
            <div class="form-group1 col-md-2" *ngIf="showComments()">
                <p class="patient-tab-above">Comments</p>
            </div>
        </div>
        <div class="patient-center-space">

            <div class="row" *ngFor="let d of (attachments$ | async)">
                <div class="form-group col-md-12 frm-margin-btm data-bottom">
                    <p class="ipt2-1">{{d.date | customDatePipe: 'dd-MM-yyyy hh:mm'}}</p>
                    <p *ngIf="showFileType()" class="ipt2-1">{{d.fileType}} </p>
                    <p *ngIf="showAttachmentType()" class="ipt2-1">{{d.attachmentType.value}} </p>
                    <p class="ipt2-1">{{d.username}}</p>
                    <p class="ipt2-2"><a href="javascript:void(0)"
                        (click)="getFile(d.id, d.fileName)" >{{d.fileName}}</a></p>
                    <p *ngIf="showComments()" class="ipt2-4">{{d.comments}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row cr-row-expand align" *ngIf="expandCaseRequest">
    <div (click)="toggleExpandCaseRequest()">
    <button type="button" class="btn btn-secondary btn-sm btn-round"
     >
        <i class="fas fa-chevron-up"></i>
    </button>
    </div>
</div>
<div *ngIf="callerId === caseRequest && expandCaseRequest">
<div class="row">
    <div class="col-md-12" >
        <div class="row back-color-comnt1">
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">Date</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">File Type</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">User</p>
            </div>
            <div class="form-group1 col-md-4">
                <p class="patient-tab-above">File Details</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">Status</p>
            </div>
        </div>
        <div class="patient-center-space">

            <div class="row" *ngFor="let d of fileHistoryInfo$ | async">
                <div *ngIf="d.contentFileName != null" class="form-group col-md-12 frm-margin-btm data-bottom">
                    <p class="ipt2-1">{{d.billerTime|| d.clientTime}}</p>
                    <p class="ipt2-1">{{d.auditEventDescription}} </p>
                    <p class="ipt2-1">{{d.billerName || d.clientName}}</p>
                    <p class="ipt2-2"><a href="javascript:void(0)"
                            (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a></p>
                    <p class="ipt2-4">{{d.auditEventType}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
<div class="row cr-row-expand align" *ngIf="!expandCaseRequest">
    <div (click)="toggleExpandCaseRequest()">
    <button type="button" class="btn btn-secondary btn-sm btn-round" (click)="toggleExpandCaseRequest()">
        <i class="fas fa-chevron-down"></i>
    </button>
    </div>
</div>
<div *ngIf="callerId === caseRequest && !expandCaseRequest">
<div class="row">
    <div class="col-md-12" >
        <div class="row back-color-comnt1">
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">Date</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">File Type</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">User</p>
            </div>
            <div class="form-group1 col-md-4">
                <p class="patient-tab-above">File Details</p>
            </div>
            <div class="form-group1 col-md-2">
                <p class="patient-tab-above">Status</p>
            </div>
        </div>
        <div class="patient-center-space">

            <ng-container class="row" *ngIf="(firsFileHistoryInfo$ | async) as d">
                <div *ngIf="d.contentFileName != null" class="form-group col-md-12 frm-margin-btm data-bottom">
                    <p class="ipt2-1">{{d.billerTime|| d.clientTime}}</p>
                    <p class="ipt2-1">{{d.auditEventDescription}} </p>
                    <p class="ipt2-1">{{d.billerName || d.clientName}}</p>
                    <p class="ipt2-2"><a href="javascript:void(0)"
                            (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a></p>
                    <p class="ipt2-4">{{d.auditEventType}}
                    </p>
                </div>
            </ng-container>
        </div>
    </div>
</div>
</div>
