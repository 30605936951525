<div class="table-responsive">
    <table class="table" id="dataTable" width="100%" cellspacing="0">
        <thead>
            <tr style="color: #333;">
                <th *ngFor="let col of coloumns">{{col.displayName}}</th>
            </tr>
        </thead>
        <tfoot>
            <tr style="color: #333;">
                <th *ngFor="let col of coloumns">{{col.displayName}}</th>
            </tr>
        </tfoot>
        <tr *ngFor="let data of dataSource | paginate: { id: paginationControlId, itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;" style="color: #333;
        font-weight: 400;">
            <td *ngFor="let col of coloumns;">
                <div *ngIf="col?.isIdCol">
                    {{(i+1) + ((currentPage-1)*itemsPerPage)}}
                </div>
                <div *ngIf="col?.isCheckbox">
                    <input type="checkbox" (click)="onClickCheckbox(data)" [checked]="isRowChecked(data)">
                </div>
                <div *ngIf="col?.isDataCol && !col?.isDataTypeDate">
                    {{data[col?.name]}}
                </div>
                <div *ngIf="col?.isDataCol && col?.isDataTypeDate">
                    {{data[col?.name] | date: 'MM/dd/yyyy'}}
                </div>

                <div *ngIf="col?.isActiveQueue">
                    <app-nsn-grid-active-queue [data]="data"></app-nsn-grid-active-queue>
                </div>
                <div *ngIf="col?.isQuickAccess">
                    <a href="javascript:void(0)" (click)="showQAATFormModal(data)"
                        class="icon-color-blue openMySidenav"><i title="Attachments"
                            class="fa fa-paperclip quickicn "></i></a>
                    <a href="javascript:void(0)" (click)="showQAOPFormModal(data)"
                        class="icon-color-darkgreen openMySidenav"><i title="Op Note"
                            class="fa fa-sticky-note quickicn"></i></a>
                    <a href="javascript:void(0)" (click)="showQAICFormModal(data)"
                        class="icon-color-yellow openMySidenav"><i title="Coding"
                            class="fa fa-file-code quickicn"></i></a>
                    <a href="javascript:void(0)" (click)="showToolsFormModal(data)"
                        class="icon-color-yellow openMySidenav"> <i title="Tools"
                            class="fas fa-tools quickicn icon-color-pink"></i></a>

                </div>
                <div *ngIf="col?.isAction">
                    <a [routerLink]="['/d/patientDtls', (data?.facilityId || 0), (data?.mrn || 0)]"
                        class="icon-color-darkgreen openMySidenav mr-2"><i title="Patient View" class="fa fa-eye"></i></a>
                    <a href="javascript:void(0)" class="icon-color-orange openMySidenav"><i title="Action" class="fas fa-comment-alt"
                            (click)="showCaseRequestFormModal(data)"></i></a>
                </div>
            </td>
        </tr>
    </table>
    <pagination-controls 
    id= "{{paginationControlId}}"
    class="float-right"
    (pageChange)="pageChange($event); currentPage = $event">
    </pagination-controls>
</div>