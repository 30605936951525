import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ImportPatientsComponent } from './import-patients/import-patients.component';
import { FeeScheduleComponent } from './fee-schedule/fee-schedule.component';
import { BillingRulesComponent } from './billing-rules/billing-rules.component';
import { EodMaintenanceComponent } from './eod-maintenance/eod-maintenance.component';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { ImportpayersComponent } from '../masters/importpayers/importpayers.component'
const routes: Routes = [{
  path: '',
  children: [
    {
      path: '',
      redirectTo: '/dashboard',
      pathMatch: 'full'
    },
    {
      path: 'importpatient',
      component: ImportPatientsComponent
    },
    {
      path: 'feeschedule',
      component: FeeScheduleComponent
    },
    {
      path: 'billingrules',
      component: BillingRulesComponent
    },
    {
      path: 'eodmaintenance',
      component: EodMaintenanceComponent
    },
    {
      path: 'manageusers',
      component: ManageUsersComponent
    }
  ]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
