import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-undo-close-of-day',
  templateUrl: './undo-close-of-day.component.html',
  styleUrls: ['./undo-close-of-day.component.css']
})
export class UndoCloseOfDayComponent implements OnInit {
  eodUCODArry: any[];
  pageValue: any = 10;
  totalRows: any = 0;
  pagenumber: any = 1;
  facilityID: number = 0;
  p: number = 1;
  formData: any = null;
  FacilityList: any[];
  bdoisArry: any[];

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.facilityID = this.route.snapshot.params.id;
    this.getEodArry(0);
    this.getFacilityInfo();
  }

  getEodArry(tCll) {

    let date = new Date();
    let cdt = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();

    let url = "EOD/GetUndoEODPatients";
    let data = {
      "facilityId": this.facilityID,
      // "EODdate": cdt,
      // "EODType": "OnHold",
      "pageNum": this.pagenumber,
      "pageSize": this.pageValue
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        if (tCll == 0) {
          this.totalRows = res.holdQueueCount;
        }
        this.eodUCODArry = res.dataClaimBindGrid;

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

  pageChange(event) {
    this.pagenumber = event;
    this.getEodArry(1);
  }

  getFacilityInfo() {
    let url = "EOD/GetUndoEODPatients";
    let data = {
      "facilityId": this.facilityID,
    }

    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  delEod() {
    let data = {
      "facilityId": this.facilityID,
      "BDOSID": this.bdoisArry
    }

    this.getApiService.post("EOD/DeleteEOD", data).subscribe(res => {
      if (res.status == "Success") {
        this.getEodArry(0);
        this.toastr.success("Deleted Successfully", 'Success!');
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }


  getCheckboxValues(ev, data) {

    if (ev.target.checked) {
      // Pushing the object into array
      this.bdoisArry.push(data);

    } else {
      let removeIndex = this.bdoisArry.findIndex(item => item === data);

      if (removeIndex !== -1)
        this.bdoisArry.splice(removeIndex, 1);
    }

    //Duplicates the obj if we uncheck it
    //How to remove the value from array if we uncheck it
    console.log(this.bdoisArry);
  }

}
