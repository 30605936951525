<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">EOD Pending
                        <span>({{eodCount}})</span>
                    </h1>
                    <form class="form-inline active-cyan-4">
                        <input class="form-control form-control-sm mr-3 w-100" name="search"
                        [(ngModel)]="searchValue" (focus)="focus()"  autocomplete="off"
                         type="text" placeholder="Search Patient/MRN"
                        (keyup.enter)="search()" aria-label="Search">
                        
                    </form>
                    
                    <div class="btn_right_eod">
                        <select class="custom-select dashboard-calnedar-patient1 mr-3" [(ngModel)]="facilityID"
                            (change)="onFacilityChange()">
                            <option  value="0">All Facilities</option>
                            <option [value]="f.id" *ngFor="let f of FacilityList">{{f.name}}</option>
                        </select>
                        <!-- <a routerLink="/d/eodHold/{{facilityID}}" id="openMySidenav"
                            class="btn btn-primary btn-patient-box openMySidenav mr-1" role="button">
                            <i class="fas fa-hand-rock font-icn-color" style="padding-right: 10px;"></i>Hold
                            <span>({{holdCount}})</span></a> -->
                        <a routerLink="/d/eodArchive/{{facilityID}}" id="openMySidenav"
                            class="btn btn-primary btn-patient-box openMySidenav mr-1" role="button">
                            <i class="fas fa-box-open font-icn-color" style="padding-right: 10px;"></i>Archive</a>
                        <button type="button" [disabled]="totalCurrentRows == 0" (click)="closeDay()" 
                            id="openMySidenav" class="closebtn btn btn-primary btn-patient-box openMySidenav mr-1 " role="button">
                            <i class="fas fa-calendar-check font-icn-color" style="padding-right: 10px;"></i>Close
                            Day</button>
                        <!-- <a *ngIf="buttonUndoEODVisibleStatus == true"  routerLink="/d/undocloseday/{{facilityID}}"
                            id="openMySidenav" class="btn btn-primary btn-patient-box openMySidenav " role="button">
                            <i class="fas fa-calendar-check font-icn-color" style="padding-right: 10px;"></i>Undo Close
                            Day</a> -->
                        <span class="mr-3"></span>
                    </div>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th>Claim Type</th>
                                    <th>Name</th>
                                    <th>MRN</th>
                                    <th>Payer</th>
                                    <th>DoS</th>
                                    <th>Physician</th>
                                    <th>Charge Amount</th>
                                    <th>Billed</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th>Claim Type</th>
                                    <th>Name</th>
                                    <th>MRN</th>
                                    <th>Payer</th>
                                    <th>DoS</th>
                                    <th>Physician</th>
                                    <th>Charge Amount</th>
                                    <th>Billed</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <ng-container
                                    *ngFor='let data of eodArry | paginate: { id: "Current", itemsPerPage: pageValue, currentPage: p["Current"], totalItems: totalCurrentRows }; let i = index'>
                                    <tr>
                                        <!-- *ngIf="data.onHold == false && data.verifySelfPay == false && data.accepted == false && data.attachFileName == null" -->
                                        <!-- <td>{{getSnoVal()}}</td> -->
                                        <td *ngIf="data.type == 'Electronic' || data.type == 'electronic'" >
                                           
                                               Electronic
                                            </td>  
                                            <td *ngIf="data.type == 'Paper' || data.type == 'paper'">
                                           
                                                Paper
                                            </td>
                                            <td *ngIf="data.type == 'Self' || data.type == 'self'">
                                           
                                                Self
                                            </td>
                                            
                                        
                                        <td>{{data.name}}
                                            <span
                                                style="display: block;font-size: 10px;">#{{data.eodformatted}}
                                            </span>
                                        </td>
                                        <td>{{data.mrn}}</td>
                                        <td>{{data.insuranceProvider}}</td>
                                        <td>{{data.dateOfSurgery | date: "MM/dd/yyyy"}}</td>
                                        <td>{{data.physician}}</td>
                                        <td> {{data.claimValue |currency:'CAD':'symbol-narrow'}}</td>
                                        <td>{{data.actionBy}}</td>
                                        <td>
                                            <a href="javascript:void(0)" (click)="showFormModal(data)"
                                                class="btn icon-color-orange icon-btn openMySidenav"><i
                                                class="fas fa-comment-alt" title="Action" aria-hidden="true"></i></a>
                                                
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <pagination-controls id="Current" class="float-right"
                            (pageChange)="pageChange($event, 'Current'); p['Current'] = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        <!-- second table start-->
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <!-- <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">EOD Completed</h1>

                   
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                 
                                    <th>Claim Type</th>
                                    <th>Name</th>
                                    <th>MRN</th>
                                    <th>Payer</th>
                                    <th>DoS</th>
                                    <th>Physician</th>
                                    <th>Billing Detail</th>
                                    <th>Billed</th>
                                 
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                 
                                    <th>Claim Type</th>
                                    <th>Name</th>
                                    <th>MRN</th>
                                    <th>Payer</th>
                                    <th>DoS</th>
                                    <th>Physician</th>
                                    <th>Billing Detail</th>
                                    <th>Billed</th>
                                  
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of eodComplete | paginate: {id: "Complete", itemsPerPage: pageValue, currentPage: p["Complete"], totalItems: totalCompletedRows }; let j = index'>
                                   
                                    <td>{{data.type}}
                                     
                                    </td>
                                    <td>{{data.name}}
                                       
                                    </td>
                                    <td>{{data.mrn}}</td>
                                    <td>{{data.insurance}}</td>
                                    <td>{{data.dos | date: "MM/dd/yyyy"}}</td>
                                    <td>{{data.physician}}</td>
                                    <td>${{data.claimValue}}</td>
                                    <td>{{data.biller}}</td>

                                  
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls id="Complete" class="float-right"
                            (pageChange)="pageChange($event, 'Complete'); p['Complete'] = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div> -->
        </div>
        <!-- second table end -->
    </div>
</div>
<div [ngClass]="{'bg-blur': showConfirmation}">
<div *ngIf=showConfirmation>
    <div id="centerModal"  class="sidenav shadow width-700  active" >
        <div
            class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
            <div class="row case-row m-0 font-weight-bold text-primary">
                <h3>Attention !</h3>
            </div>
            
            
        </div>
        <div class="card-body">
            <div class="centerText">
                <h4>Are you sure you want to close the day?</h4><br/>
                <div class="text-right">
                <a href="javascript:void(0);" (click)="confirmYesClick()" class="mr-2 btn btn-warning closeMySidenav" id="closeMySidenav">
                    Yes
                </a>
                <a href="javascript:void(0);" (click)="confirmNoClick()" class="mr-2 btn btn-success  closeMySidenav" id="closeMySidenav">
                    No
                </a>
            </div>

            </div>
        </div>
    </div>
</div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<!-- <app-eod-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()">
</app-eod-form> -->
<!-- Sidenav-->
<app-case-request [commandType]="commandtype" [formData]="formData" *ngIf="shouldShowFormModal"
    (closeEmitter)="onCloseFormModal()"></app-case-request>