import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({ name: 'TimeInDayHour' })
export class TimeInDayHourPipe implements PipeTransform {

    transform(numberOfHours){
        var Days=Math.floor(numberOfHours/24);
        var Remainder=numberOfHours % 24;
        var Hours=Math.floor(Remainder);
        var Minutes=Math.floor(60*(Remainder-Hours));
        return({"days":Days,"hours":Hours,"minutes":Minutes})
      }
}