import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PostingRoutingModule } from './posting-routing.module';
import { DrClientFormComponent } from './dr-client/dr-client-form/dr-client-form.component';
import { DrClientComponent } from './dr-client/dr-client.component';
import { DrppClientFormComponent } from './dr-client/drpp-client-form/drpp-client-form.component';
import { EobAranFormComponent } from './dr-client/eob-aran-form/eob-aran-form.component';
import { EobCasFormComponent } from './dr-client/eob-cas-form/eob-cas-form.component';
import { DrNsnComponent } from './dr-nsn/dr-nsn.component';
import { DepositLogComponent } from './deposit-log/deposit-log.component';
import { GoalTrackerComponent } from './goal-tracker/goal-tracker.component';
import { DashboardComponent } from './dashboard/dashboard.component';


@NgModule({
  declarations: [DrClientFormComponent, DrClientComponent, DrppClientFormComponent, EobAranFormComponent, EobCasFormComponent, DrNsnComponent, DepositLogComponent, GoalTrackerComponent, DashboardComponent],
  imports: [
    CommonModule,
    PostingRoutingModule
  ]
})
export class PostingModule { }
