import { Component, EventEmitter, OnInit, Output, Input, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { implantLog, NameConstant } from 'src/app/shared/models/name-constant';
import { ToolsQaImplantLogService } from './state/tools-qa-implant-log/tools-qa-implant-log.service';

@Component({
  selector: 'app-tools-qa',
  templateUrl: './tools-qa.component.html',
  styleUrls: ['./tools-qa.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolsQaComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  tabVal: any = [];
  tabHead: string = "";
  resphtml: any;
  planSelct: number = 1;
  paymentMethod: string = '';
  plantype: string = "";

  planTitle = "";

  PatientSummaryDtls: any;
  _formData: any;
  smryIsToggled = false;
  modalIsToggled = false;
  exceptionModalIsToggled = false;
  reviewModalIsToggled = false;

  patientCPTs: any = [];
  primaryCPT: number;

  facilityId: number = 0;
  facilityName: string = "";

  ppa: boolean = false;
  pppa: boolean = false;
  pa: boolean = false;

  patientbal: number;
  todayPayment: number;
  newBalance: number;
  noofMonths: number;
  monthlyPayment: number;
  paymentDate;

  issue: string = "";
  opreport: boolean = false;
  originalClaim: boolean = false;
  eob: boolean = false;

  cef_txt1: string = "";
  cef_txt2: string = "";
  dataException: any = {};
  dataReview: any = {};
  imgSrc = "";
  haserror: boolean = false;
  shouldShowToolsModal: any;


  

  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
    }
  }

  implantLog: NameConstant = implantLog;
  constructor(private sanitizer: DomSanitizer,
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private toolsQaImplantLogService: ToolsQaImplantLogService
    ) { }

  ngOnInit(): void {
    this.getPatientHeaderData();
  }

  getPatientHeaderData() {
    let data = {
      "Bdosid": this._formData.bdosid
    }

    this.getApiService.post("BillingNew/GetPatientsInfo", data).subscribe(res => {
      if (res.status == "Success") {

        this.PatientSummaryDtls = res;

        this.facilityId = res.dataPatient[0].facilityId;
        this.facilityName = res.dataPatient[0].facilityName;

        if (res.dataCPTDetails.length > 0) {
          this.primaryCPT = res.dataCPTDetails[0].cptCode;
        }

        for (let d of res.dataCPTDetails) {
          if (this.patientCPTs.indexOf(d.cptCode) == -1) {
            this.patientCPTs.push(d.cptCode);
          }
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  onClickClose() {
    this.closeEmitter.emit();
    
  }

  reloadCurrentPage() {
    window.location.reload();
   }

  outsideClick(){
      if (this.shouldShowToolsModal) {
        this.shouldShowToolsModal = false;
      }
    }

  slctchkBx(event) {
    this.plantype = event.target.value;

    if (event.target.checked == true) {

      if (this.plantype == 'Payment Plan Agreement') {
        this.ppa = true;
        this.pppa = false;
        this.pa = false;
      }
      else if (this.plantype == 'Proposed Payment Plan') {
        this.ppa = false;
        this.pppa = true;
        this.pa = false;
      }
      else if (this.plantype == 'Promissory Agreement') {
        this.ppa = false;
        this.pppa = false;
        this.pa = true;
      }
    }
    else {
      this.plantype = "";
      this.ppa = false;
      this.pppa = false;
      this.pa = false;
    }

  }

  savePaymentPlan() {
    var user = localStorage.getItem("loggedUser");
    let dop = "";
    this.haserror = false;

    if (this.paymentDate != "" && this.paymentDate != undefined) {
      let date = new Date(this.paymentDate);
      dop = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
    }

    if (this.plantype == 'Payment Plan Agreement') {
      if (this.patientbal == 0 || this.todayPayment == 0 || this.newBalance == 0 || this.noofMonths == 0 || this.monthlyPayment == 0 || this.patientbal == undefined || this.todayPayment == undefined || this.newBalance == undefined || this.noofMonths == undefined || this.monthlyPayment == undefined || this.paymentMethod == "" || this.paymentMethod == undefined || this.paymentDate == "" || this.paymentDate == undefined) {
        this.haserror = true;
        return;
      }
    }
    if (this.plantype == 'Promissory Agreement') {
      if (this.patientbal == 0 || this.noofMonths == 0 || this.monthlyPayment == 0 || this.paymentMethod == "" || this.patientbal == undefined || this.noofMonths == undefined || this.monthlyPayment == undefined || this.paymentMethod == undefined || this.paymentDate == "" || this.paymentDate == undefined) {
        this.haserror = true;
        return;
      }
    }
    if (this.plantype == 'Proposed Payment Plan') {
      if (this.patientbal == 0 || this.noofMonths == 0 || this.monthlyPayment == 0 || this.patientbal == undefined || this.noofMonths == undefined || this.monthlyPayment == undefined) {
        this.haserror = true;
        return;
      }
    }


    let data = {};
    data = {
      "TeamMember": user,
      "Position": "",
      "BDOSID": this._formData.bdosid,
      "PlanType": this.plantype,
      "PatientBal": this.patientbal,
      "TodaysPay": this.todayPayment,
      "NewBal": this.newBalance,
      "noOFmonth": this.noofMonths,
      "monthlyPay": this.monthlyPayment,
      "DOFPayment": dop,
      "PayMethod": this.paymentMethod
    }

    this.getApiService.post("PaymentPlan/Save", data).subscribe(res => {
      if (res.status == "Success") {
        this.patientbal = 0;
        this.todayPayment = 0;
        this.newBalance = 0;
        this.noofMonths = 0;
        this.monthlyPayment = 0;
        this.paymentDate = "";

        this.imgSrc = this.getApiService.baseUrl + "FacilityLogo/" + this._formData.facilityId + ".jpg";

        this.fetchHtml(res.id, res.planType);
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  fetchHtml(id, plan) {

    let data = {
      "id": id,
      "PlanType": plan
    };

    this.getApiService.post("PaymentPlan/DownloadTemplate", data).subscribe(res => {
      if (res.status == "Success") {

        if (res.data.length > 0) {
          this.previewTemplate(plan, res.data[0].templateData);
        }

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  saveCodingreview() {

    let date = new Date();
    let dt = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();

    let data = {
      "Bdosid": this._formData.bdosid,
      "IssueReviewed": this.issue,
      "DateOfRequest": dt,
      "OpReport": this.opreport,
      "OriginalClaim": this.originalClaim,
      "Eob": this.eob
    }

    this.getApiService.post("PaymentPlan/CodingReviewRequestSave", data).subscribe(res => {
      if (res.status == "Success") {
        this.issue = "";
        this.opreport = false;
        this.originalClaim = false;
        this.eob = false;

        if (res.datat.length > 0) {
          let d = res.datat[0];
          this.previewTemplate('Coding Review Request', d);
        }

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  previewTemplate(type, data) {
    this.planTitle = type;

    if (type == 'Coding Exception') {

      this.dataException = data;
      this.exceptionModalIsToggled = true;

    }
    else if (type == "Coding Review Request") {

      this.dataReview = data;
      this.reviewModalIsToggled = true;
    }
    else {

      this.resphtml = this.sanitizer.bypassSecurityTrustHtml(data);
      this.modalIsToggled = true;
    }
  }


  saveCodingException() {

    let date = new Date();
    let dt = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();

    let data = {
      "FacilityId": this._formData.facilityId,
      "Input1": this.cef_txt1,
      "Input2": this.cef_txt2
    }

    this.getApiService.post("PaymentPlan/CodingExceptionSave", data).subscribe(res => {
      if (res.status == "Success") {
        if (res.datat.length > 0) {
          let d = res.datat[0];
          this.cef_txt1 = "";
          this.cef_txt2 = "";
          this.previewTemplate('Coding Exception', d);
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getPdf() {
    var htmlData = document.getElementById('paymentContent');
    if (this.planTitle == "Coding Exception") {
      htmlData = document.getElementById('exceptionContent');
    }
    else if (this.planTitle == "Coding Review Request") {
      htmlData = document.getElementById('reviewContent');
    }

    html2canvas(htmlData).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;
      let contentDataURL = canvas.toDataURL('image/png');

      let pdf = new jsPDF("p", "mm", "a4");

      // let pdf = new jsPDF({
      //   orientation: 'l',
      //   unit: 'pt',
      //   format: [imgWidth, imgHeight], // set surface larger according to desired margins
      // }); // A4 size page of PDF  

      const imgProps = pdf.getImageProperties(contentDataURL);
      var width = pdf.internal.pageSize.getWidth();
      var height = pdf.internal.pageSize.getHeight();
      let widthRatio = width / canvas.width
      let heightRatio = height / canvas.height

      let ratio = widthRatio > heightRatio ? heightRatio : widthRatio

      // var height = (imgProps.height * width) / imgProps.width;

      pdf.addImage(contentDataURL, 'PNG', 5, 40, canvas.width * ratio,
        canvas.height * ratio);
      pdf.save(this.planTitle + '.pdf'); // Generated PDF 
      this.modalIsToggled = false;

      this.onClickClose();

    });
  }

  checkValue(type) {

    if (type == 'ce') {
      if (this.cef_txt2.trim() != "" || this.cef_txt1.trim() != "") {
        return true;
      }
      else {
        return false;
      }
    }
    else if (type == 'crr') {
      if (this.issue.trim() != "") {
        return true;
      }
      else {
        return false;
      }
    }
  }

  onPlanChange(){
    if(this.planSelct !== 4){
      this.toolsQaImplantLogService.reset();
    }
  }
}
