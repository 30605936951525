import { Component, OnInit, EventEmitter, Output,Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-facility-group-form',
  templateUrl: './facility-group-form.component.html',
  styleUrls: ['./facility-group-form.component.css']
})
export class FacilityGroupFormComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  formFlag: string = "Add";
  FacilityGroupMasterForm:FormGroup;
  facilities: any[] = [];
  submitted=false;
  selectedFacilities: any[] = [];
  removedselectedFacilities: any[] = [];
  selectedToAdd: any[] = [];
  selectedToRemove: any[] = [];
  _formData:any;
  group_id:any;
  @Input() set formData(value: any) {

    if (value != null && value != undefined) {
      this._formData = value;
      this.formFlag = "Edit";
      this.group_id = this._formData.id;
      this.editForm();
      this.getselectedFacilities();
      
    }
    else {
      this.formFlag = "Add";
      this.initForm();
    }
  }
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }


  ngOnInit(): void {
    this.getFacilities();
  }
  initForm() {
    this.FacilityGroupMasterForm = this.fb.group({
      groupId: ["",],
      Name: ["", [Validators.required]],
      assigned: [""],
      
    })
  }
  editForm() {
  console.log(this._formData,"this.formdata");
     this.FacilityGroupMasterForm = this.fb.group({
      groupId: [this._formData.id, [Validators.required]],
      Name: [this._formData.groupName, [Validators.required]],
      
     });
    
    
   }
  onClickClose() {
    this.closeEmitter.emit();
  }
  get f() { return this.FacilityGroupMasterForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.addRecord();

  }
  getselectedFacilities(){
    console.log(this.group_id,'groupid');
    let data = { "pageNum": 1, "pageSize": 100};

    this.getApiService.get("FacilityGroup/GetGroupFacilities?GroupId="+this.group_id).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.selectedFacilities = resp.data;
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  addRecord() {
    console.log(this.selectedToAdd,"addedFacilities");
    const formdata = this.FacilityGroupMasterForm.getRawValue();
    let frmData = new FormData();
    let facilitiesTobeadded=[];
    let data:any;
    const groupName = this.FacilityGroupMasterForm.controls["Name"].value;
    this.selectedFacilities.forEach(element => {
      var facility_id = {'FacilityId':element.id}
      facilitiesTobeadded.push(facility_id);
      
    });
    if (this.formFlag == "Edit") {
      data ={"id":this.FacilityGroupMasterForm.controls["groupId"].value,'GroupName':groupName,'addedFacilities':facilitiesTobeadded};
    }else{
      data ={'GroupName':groupName,'addedFacilities':facilitiesTobeadded};      
    }
    
    this.getApiService.post("FacilityGroup/SaveGroup", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
       
        this.FacilityGroupMasterForm.reset();
        this.closeEmitter.emit();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }
  getFacilities() {
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };

    this.getApiService.post("FacilityMaster/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilities = resp.data;
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }

  chosenFacilities(facility) {
    this.selectedToAdd = facility;
  }

  chosenFacilitiesToRemove(facility) {
    this.selectedToRemove = facility;
  }

  assigne() {
    this.selectedFacilities = this.selectedFacilities.concat(this.selectedToAdd);
    this.facilities = this.facilities.filter(facility => {
      return this.selectedFacilities.indexOf(facility) < 0;
    });

    this.selectedToAdd = [];
  }

  assigneAll() {
    this.selectedFacilities = this.selectedFacilities.concat(this.facilities);
    this.facilities = [];

    this.selectedToAdd = [];
  }

  unassigneAll() {
    this.facilities = this.facilities.concat(this.selectedFacilities);
    this.selectedFacilities = [];
    this.selectedToRemove = [];
  }

  unassigne() {
    this.facilities = this.facilities.concat(this.selectedToRemove);
    this.removedselectedFacilities = this.removedselectedFacilities.concat(this.selectedToRemove);

    this.selectedFacilities = this.selectedFacilities.filter(selectedFacility => {
      return this.facilities.indexOf(selectedFacility) < 0;
    });
    this.selectedToRemove = [];
  }
}

