import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Facility } from '../facilities/facility.model';
import { FacilityQuery } from '../facilities/facility.query';
import { Physician } from './physician.model';
import { PhysicianStore, PhysicianState } from './physician.store';

@Injectable({ providedIn: 'root' })
export class PhysicianQuery extends QueryEntity<PhysicianState> {

  constructor(
    protected store: PhysicianStore,
    private facilityQuery: FacilityQuery) {
    super(store);
  }

   selectAllInactive(): Observable<Physician[]>{
    const all$ = this.selectAll();
    const active$ = this.selectActive();
    const inactive$ = combineLatest([all$, active$]).pipe(
      map(([all, active]) => this.filterInactive(all, active))
    );
    return inactive$;
  }

   getAllInactive(): Physician[]{
    return this.getAll({
      filterBy: e => !this.getActiveId().includes(e.id)
    })
  }

  private filterInactive(all: Physician[], active: Physician[]): Physician[]{
   return  all.filter((el) => !this.isActive(el, active))
  }
  
  private isActive(physician: Physician, active: Physician[]): boolean{
    return active?.some(a => a.id === physician.id);
  }

  getPhysicianOfActiveFacility(): Physician[]{
    const allInactive = this.getAllInactive();
    const activeFacility = this.facilityQuery.getActive();
    return this.filterPhysicianOfFacility(allInactive, activeFacility);
  }

  selectPhysicianOfActiveFacility(): Observable<Physician[]> {
    const allInactive$ = this.selectAllInactive();
    const activeFacility$ = this.facilityQuery.selectActive();
    const physicianOfFacility$ = combineLatest([allInactive$, activeFacility$]).pipe(
      map(([allInactive, activeFacility]) => this.filterPhysicianOfFacility(allInactive, activeFacility))
    );

    return physicianOfFacility$;
  }

  private filterPhysicianOfFacility(allInactive: Physician[], activeFacilities: Facility[]): Physician[]{
   const facilityAccronym =  activeFacilities.map(facility => facility.acrnym);
   var uniqueFacilityAccronym = facilityAccronym.filter(function(elem, index, self) {
    return index === self.indexOf(elem);
    }).map( acrnym => (acrnym !== null && acrnym !== undefined ? acrnym.toLocaleLowerCase() : acrnym));
   return allInactive.filter(f => f.facility && uniqueFacilityAccronym.includes(f.facility.split('-')[0].toLocaleLowerCase()));
  }
}
