export interface ColDef{
    name: string;
    displayName: string;
    sortable: boolean;
    sortFieldName: string;
    isIdCol: boolean;
    isCheckbox: boolean;
    isDataCol: boolean;
    isDataTypeDate: boolean;
    isActiveQueue: boolean;
    isQuickAccess: boolean;
    isAction: boolean;
    isNumber?: boolean;
}

export function createColDef(params: Partial<ColDef>) {
    return {
      ...params
    } as ColDef;
  }