import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { managementReport, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { createManagementReport, ManagementReport } from '../state/management-report/management-report.model';
import { ManagementReportQuery } from '../state/management-report/management-report.query';
import { ManagementReportService } from '../state/management-report/management-report.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-management-report',
  templateUrl: './management-report.component.html',
  styleUrls: ['./management-report.component.css']
})
export class ManagementReportComponent implements OnInit, OnDestroy {

  componentName: NameConstant = managementReport;
  userColoumns: ColDef[] = [];
  facilityColoumns: ColDef[] = [];
  datasource$: Observable<ManagementReport[]>;
  datasourceSub: Subscription;
  loading: boolean;
  submitted: boolean;
  fromDateError: string;
  toDateError: string;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  selFromDate;
  selToDate;
  activeReportLineId$: Observable<string>;
  userdropdownConfig;
  userSourceList;
  selectedUser;
  userSub: Subscription;
  reportBy: string;

  constructor(
    private managementReportService: ManagementReportService,
    private managementReportQuery: ManagementReportQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
    private currencyPipe: CurrencyPipe,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.initUserDropdownConfig();
    this.initUserColoumns();
    this.initFacilityColoumns();
    this.loadUserDropdown();
    this.datasource$ = this.managementReportQuery.selectAll();
    this.commonUtil.setTitle(this.componentName.value);
    this.activeReportLineId$ = this.managementReportQuery.selectActiveId().pipe(map(res => res?.toString()));
  }

  ngOnDestroy() {
    if (this.datasourceSub) {
      this.datasourceSub.unsubscribe();
    }
    this.managementReportService.set([]);
  }

  loadUserDropdown() {
    this.userSub = this.apiService.get("ApplicationUser/BindReportingManager").subscribe(res => {
      if (res.status === 'Success') {
        this.userSourceList = res.data;
      } else {
        this.toaster.error("Server Errro", res?.message);
      }
    }, error => {
      this.toaster.error("Unexpected Errro", error?.message);
    });
  }

  userSelectionChanged(event$) {
    console.log(event$.value);
    this.selectedUser = event$.value;
    this.onSearch();
  }



  initDataSource() {
    this.loading = true;
    this.spinner.show();
    this.submitted = true;
    const userId = this.selectedUser ? (this.selectedUser.id ? this.selectedUser.id : '') : '';

    let apiName;
    let requestBody;
    console.log("report", this.reportBy);

    if (this.reportBy === 'user') {
      requestBody = {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "pageNum": this.currentPage,
        "pageSize": this.itemsPerPage,
        "userId": userId
    };
      apiName = "Reports/ManagementByTeam";
    } else if (this.reportBy === 'facility') {
      requestBody = {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "pageNum": this.currentPage,
        "pageSize": this.itemsPerPage,
        "userId": userId
    };
      apiName = "Reports/ManagementByTeamFacility";
    } else {
      this.spinner.hide();
      console.log('no selection matches!!');
      return;
    }

    this.datasourceSub = this.apiService.post(apiName, requestBody).subscribe(res => {
      if (res.status === 'Success') {
        this.loading = false;
        this.spinner.hide();
        this.submitted = false;
        const entities: ManagementReport[] = res.data.map(r => createManagementReport({
          id: this.reportBy === 'user' ? r.userId : r.facilityID,
          user: r.user,
          facilityName: r.facilityName,
          totalcasesadded: r.totalcasesadded,
          totalopencasesbfr: r.totalopencasesbfr,
          totalopencasestilldate: r.totalopencasestilldate,
          totalsetbilledcases: r.totalsetbilledcases,
          totalcount: r.totalcount
        }));

        this.totalRows = entities ? (entities[0] ? entities[0].totalcount : 0) : 0;
        this.managementReportService.set(entities);
      } else {
        this.loading = false;
        this.spinner.hide();
        this.submitted = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.spinner.hide();
      this.submitted = false;
      this.toaster.error("Unexpected Error!!", err);
    })
  }

  initUserColoumns() {
    this.userColoumns = [...this.userColoumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "user", displayName: "User", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "totalopencasesbfr", displayName: "Count of Total Cases in Start", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "totalopencasestilldate", displayName: "Count of Total Cases Till Date", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "totalcasesadded", displayName: "Count of cases added", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "totalsetbilledcases", displayName: "Count of \'Set Billed\' Cases", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "", displayName: "Action", isDataCol: false, isDataTypeDate: false, isAction: true })];
  }

  initFacilityColoumns() {
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "totalopencasesbfr", displayName: "Count of Total Cases in Start", isDataCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "totalopencasestilldate", displayName: "Count of Total Cases Till Date", isDataCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "totalcasesadded", displayName: "Count of cases added", isDataCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "totalsetbilledcases", displayName: "Count of \'Billed\' Cases", isDataCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "", displayName: "Action", isDataCol: false, isDataTypeDate: false, isAction: true })];
  }

  pageChange(event$) {
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch() {
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));
    console.log("report", this.reportBy);
    if (!this.selFromDate) {
      this.fromDateError = "From Date cannot be blank";
      return;
    } else {
      this.fromDateError = '';
    }
    if (!this.selToDate) {
      this.toDateError = "To Date cannot be blank";
      return;
    } else {
      this.toDateError = '';
    }

    if (!this.selectedUser) {
      console.log("user not selected");
      return;
    }

    if (!this.reportBy) {
      console.log("report", this.reportBy);

      return;
    }
    this.initDataSource();
  }

  showReportLineDetail(data) {
    console.log(data);
    this.managementReportService.setActive(String(data.id));
  }

  initUserDropdownConfig() {
    this.userdropdownConfig = {
      displayKey: "userName", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: "userName", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }

  onUserReportSelection(event$) {
    console.log('onUserReportSelection', event$);
    this.onSearch();
  }

  onFacilityReportSelection(event$) {
    console.log('onFacilityReportSelection', event$);
    this.onSearch();
  }


  onExport(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));
    console.log("report", this.reportBy);
    if (!this.selFromDate) {
      this.fromDateError = "From Date cannot be blank";
      return;
    } else {
      this.fromDateError = '';
    }
    if (!this.selToDate) {
      this.toDateError = "To Date cannot be blank";
      return;
    } else {
      this.toDateError = '';
    }

    if (!this.selectedUser) {
      console.log("user not selected");
      return;
    }

    if (!this.reportBy) {
      console.log("report", this.reportBy);

      return;
    }
    const userId = this.selectedUser ? (this.selectedUser.id ? this.selectedUser.id : '') : '';

    let apiName;
    let requestBody;
    console.log("report", this.reportBy);

    if (this.reportBy === 'user') {
      requestBody = {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "userId": userId
    };
      apiName = "Reports/DownloadManagementByTeam";
    } else if (this.reportBy === 'facility') {
      requestBody = {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "userId": userId
    };
      apiName = "Reports/DownloadManagementByTeamFacility";
    } else {
      this.spinner.hide();
      console.log('no selection matches!!');
      return;
    }
    this.datasourceSub = this.apiService.post4File(apiName, requestBody).subscribe(res => {
     // saveAs(res, this.componentName.value+".xlsx");
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
      saveAs(blob, this.reportBy+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }
}
