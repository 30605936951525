import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-eod-hold',
  templateUrl: './eod-hold.component.html',
  styleUrls: ['./eod-hold.component.css']
})
export class EodHoldComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;

  eodHoldArry: any[];
  pageValue: any = 10;
  totalRows: any = 0;
  pagenumber: any = 1;
  facilityID: number = 0;
  p: number = 1;
  formData: any = null;
  FacilityList: any[];

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.facilityID = this.route.snapshot.params.id;
    this.getEodArry(0);
    this.getFacilityInfo();
  }

  showFormModal(data) {
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  getEodArry(tCll) {

    let date = new Date();
    let cdt = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();

    let url = "EOD/BindEODGridData";
    let data = {
      "facilityId": this.facilityID,
      "EODdate": cdt,
      "EODType": "OnHold",
      "pageNum": this.pagenumber,
      "pageSize": this.pageValue
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        if (tCll == 0) {
          this.totalRows = res.holdQueueCount;
        }
        this.eodHoldArry = res.dataClaimBindGrid;

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

  pageChange(event) {
    this.pagenumber = event;
    this.getEodArry(1);
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
    this.getEodArry(0);
  }

  getFacilityInfo() {
    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
}
