import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CategoryItem } from './category-item.model';

export interface CategoryItemState extends EntityState<CategoryItem>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'category-item', idKey: 'name' })
export class CategoryItemStore extends EntityStore<CategoryItemState> {

  constructor() {
    super();
  }

}
