export interface ImplantLogPayer {
  id: number | string;
  bdosid: number | string;
  payerId: number | string;
  payerName: string;
}

export function createImplantLogPayer(params: Partial<ImplantLogPayer>) {
  return {
    ...params
  } as ImplantLogPayer;
}
