import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-dr-nsn',
  templateUrl: './dr-nsn.component.html',
  styleUrls: ['./dr-nsn.component.css']
})
export class DrNsnComponent implements OnInit {

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("DR NSN");
  }

}
