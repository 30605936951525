
<div class="row" *ngIf="(activeTab$ | async).id !== variance.id">
        <div class="col-md-8">
            <p class="high-section-textt cr-label"> {{(activeTab$ | async).value}} Total</p>
        </div>
        <div class="col-md-4">
            <p class="high-sect-amount">{{totalCost$ | async}}</p>
        </div>
    </div>
    <form [formGroup]="PatientsForm" style="width: 25.5rem;">
        <div formArrayName="CPTCodeList" *ngIf="(activeTab$ | async).id !== variance.id">
            <div *ngFor="let af of CPTCodeListForm.controls; let b=index">
                <div class="row" [formGroupName]="b">
                    <div class="form-group remove-botttom-space align-input-user col-lg-3 col-md-6">
                        <div class="input-group">
                            <label class="case-aug-num">#{{b+1}}</label>
                            <input type="text" class="form-control cr-cpt-input-cpt" [class.is-invalid]="searchFailed"
                                [ngbTypeahead]="search" placeholder="CPT Code" formControlName="cpt"
                                [resultFormatter]="formatter" [inputFormatter]="formatter"
                                (selectItem)="onSelectItem($event, b)" (change)="chng(b)" /><br>
                        </div>
                        <small *ngIf="searching" class="form-text text-muted">searching...</small>
                        <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                            suggestions
                            could not
                            be
                            loaded.</div>
                        <div *ngIf="af.controls.CPTCode.errors?.notFound" class="invalid-feedback" style="margin-left: 27px;">
                            <p>New CPT Code.</p>
                        </div>
                    </div>
                    <div class="form-group remove-botttom-space align-input-user col-lg-5 col-md-6">
                        <div class="input-group">
                            <input type="text" (change)="updateCPTdescription($event, b)" class="form-control cr-cpt-input-desc" formControlName="CPTDesc"
                                placeholder="CPT Desc">
                        </div>
                    </div>
                    <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control cr-cpt-input-rate" formControlName="Rate"
                                placeholder="$0.00" (change)="transformAmount($event, b)" min="0"
                                (mouseout)="transformAmount($event, b)">
                        </div>
                    </div>
                    <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                        <div class="input-group" style="flex-wrap: nowrap;">
                            <button type="button" (click)="addCptCodeList()"
                                class="btn btn-primary cr-cpt-btn-plus btn-add-user btn-patient-box mr-1">
                                <i class="fas fa-plus"></i></button>
                            <button *ngIf="b > 0" type="button" (click)="removeCptCodeList(b)"
                                class="btn btn-primary btn-color cr-cpt-btn-minus btn-patient-box">
                                <i class="fas fa-minus"></i></button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div *ngIf="(activeTab$ | async).id === variance.id" style="width: 32rem;">
            <div class="row highrow cr-label">
                <div class="col-lg-6 col-md-6">
                    <p class="ext-case-pad"><strong> Coding</strong></p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <p><strong>Physician Coding</strong></p>
                </div>
            </div>
            <div class="row highrow cr-label">
                <div class="col-md-6 row">
                    <div class="col-lg-4 col-md-6">
                        <p>CPT</p>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <p>Description</p>
                    </div>
                </div>
                <div class="col-md-6 row">
                    <div class="col-lg-4 col-md-6">
                        <p>CPT</p>
                    </div>
                    <div class="col-lg-8 col-md-6">
                        <p>Description</p>
                    </div>
                </div>
            </div>
            <div formArrayName="VarianceList">
                <div *ngFor="let vl of VarianceListForm.controls; let c=index">
                    <div class="row" style="margin-left: -3.5px;" [formGroupName]="c">
                        <div class="col-lg-5 row">
                            <div class="form-group remove-botttom-space align-input-user col-lg-4 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" [class.is-invalid]="searchFailed"
                                        [ngbTypeahead]="search" placeholder="CPT Code" formControlName="nsn_cpt"
                                        [resultFormatter]="formatter" [inputFormatter]="formatter"
                                        (selectItem)="onVarSelectItem($event, c, 'nsn_cpt')"
                                        (change)="chngVariance(c, 'nsn_cpt')" /><br>

                                </div>
                                <small *ngIf="searching" class="form-text text-muted">searching...</small>
                                <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                                    suggestions
                                    could not
                                    be
                                    loaded.</div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-8 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="nsn_Desc"
                                        placeholder="CPT Desc" (change)="chngVarianceDesc(c, 'nsn_cpt')">
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-5 row" style="margin-left: 22px;">
                            <div class="form-group remove-botttom-space align-input-user col-lg-4 col-md-6">
                                <div class="input-group">
                                    <!-- <input id="physician_cptt" type="text" class="form-control"
                            placeholder="CPT Code" formControlName="physician_cpt"> -->
                                    <div class="input-group">
                                        <input type="text" class="form-control" [class.is-invalid]="searchFailed"
                                            [ngbTypeahead]="search" placeholder="CPT Code"
                                            formControlName="physician_cpt" [resultFormatter]="formatter"
                                            [inputFormatter]="formatter"
                                            (selectItem)="onVarSelectItem($event, c, 'physician_cpt')"
                                            (change)="chngVariance(c, 'physician_cpt')" /><br>
                                        <!--   -->
                                    </div>
                                    <small *ngIf="searching" class="form-text text-muted">searching...</small>
                                    <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                                        suggestions
                                        could not
                                        be
                                        loaded.</div>

                                    <!-- <input id="typeahead-http" type="text" class="form-control"
                            [class.is-invalid]="searchFailed" [ngbTypeahead]="search"
                            placeholder="CPT Code" formControlName=""
                            [resultFormatter]="formatter" [inputFormatter]="formatter"
                            (selectItem)="onSelectItem($event, b)"
                            (change)="chng(b)" /><br> -->
                                </div>
                                <!-- <small *ngIf="searching"
                        class="form-text text-muted">searching...</small>
                    <div class="invalid-feedback" *ngIf="searchFailed">Sorry,
                        suggestions
                        could not
                        be
                        loaded.</div> -->
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-8 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" formControlName="physician_Desc"
                                        placeholder="CPT Desc">
                                </div>
                            </div>

                        </div>

                        <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                            <div class="input-group" style="flex-wrap: nowrap;">
                                <button type="button" (click)="addVarianceList()"
                                    class="btn btn-primary plus-btn btn-add-user btn-patient-box mr-1">
                                    <i class="fas fa-plus"></i></button>
                                <button *ngIf="c > 0" type="button" (click)="removeVarianceList(c)"
                                    class="btn btn-primary btn-color minus-btn btn-patient-box">
                                    <i class="fas fa-minus"></i></button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </form>