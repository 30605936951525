<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active" *ngFor='let data of facility_data;let i =index;'>
        <div
            class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
            <div class="row case-row m-0 font-weight-bold text-primary">
                <h3>{{data.name}}</h3>
            </div>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <div >
    
                <div class="row col-12 hr">
                    <div class="form-group remove-botttom-space col-3">
                        <label>Short Name</label>
                        <div style="color:black">
                            <b>{{data.shortName}}</b>
                        </div>
                    </div>
    
    
                    <div class="form-group remove-botttom-space col-3">
                        <label for="group">Group</label>
                        <div style="color:black">
                            <b>Group A</b>
                        </div>
                    </div>
    
                    <div class="form-group remove-botttom-space col-3">
                        <label>Region</label>
                        <div style="color:black">
                            <b>{{data.region}}</b>
                        </div>
    
    
                    </div>
                    <div class="form-group remove-botttom-space col-3">
                        <label>Abbr</label>
                        <div style="color:black">
                            <b>{{data.abbr}}</b>
                        </div>
    
    
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <!-- <label>Logo</label> -->
                        <!-- <span><b><img width="100px" *ngIf="imageUrl !=''" src='{{imageUrl }}'/></b></span> -->
                
                    </div>
                </div>
                
                <div class="container tab-remove">
                    <ul class="nav nav-tabs nav-border patient-tab-pad">
                      <li class="user-menu-style"><a class="active" data-toggle="tab" href="#Facilities">Facilities</a></li>
                      <li class="user-menu-style"><a data-toggle="tab" (click)="onsel()" href="#Pricing">Pricing</a></li>
                    </ul>
                
                    <div class="tab-content">
                        <div id="Facilities" class="tab-pane fade in active show">
                            <h6 class="text-primary"><b>Facility Address</b></h6>
                            <hr>
                            <div class="row col-12 hr">
                                <div class="form-group remove-botttom-space col-4">
                                    <label>Phone Number</label>
                                    <div style="color:black"><b>{{data.phone}}</b></div>
                                </div>
                                <div class="form-group remove-botttom-space col-4">
                                    <label>Fax</label>
                                    <div style="color:black"><b>{{data.fax}}</b></div>
                                </div>
                
                                <div class="form-group remove-botttom-space col-4">
                                    <label>Billing Toll-Free Number</label>
                                    <div style="color:black"><b>{{data.tollFree}}</b></div>
                
                                </div>
                                <!-- <div class="row col-12 hr"> -->
                                    <div class="form-group remove-botttom-space col-md-4">
                                        <label>Address 1</label>
                                        <div style="color:black"><b>{{data.address1}}</b></div>
                                    </div>
                                    <div class="form-group remove-botttom-space col-md-4">
                                        <label>Address 2</label>
                                        <div style="color:black"><b>{{data.address2}}</b></div>
                                    </div>
                                    <div class="form-group remove-botttom-space col-md-4">
                                        <label>City</label>
                                        <div style="color:black"><b>{{data.city}}</b></div>
                                    </div>
                                <!-- </div>
                                <div class="row col-12 hr"> -->
                                    <div class="form-group remove-botttom-space col-md-4">
                                        <label>State</label>
                                        <div style="color:black"><b>{{data.state}}</b></div>
                                    </div>
                
                
                                    <div class="form-group remove-botttom-space col-md-4">
                                        <label>Zip</label>
                                        <div style="color:black"><b>{{data.zip}}</b></div>
                                    </div>
                                <!-- </div> -->
                            </div>
                            <hr>
                            
                            <h6 class="text-primary"><b>Facility Details</b></h6>
                            <!-- <hr> -->
                            <div class="row col-12 hr">
                                <div class="form-group remove-botttom-space col-md-4">
                                    <label>NPI</label>
                                    <div style="color:black"><b>{{data.npi}}</b></div>
                                </div>
                                <div class="form-group remove-botttom-space col-md-4">
                                    <label>TIN</label>
                                    <div style="color:black"><b>{{data.tin}}</b></div>
                                </div>
                                <div class="form-group remove-botttom-space col-md-4">
                                    <label>PTAN</label>
                                    <div style="color:black"><b>{{data.ptan}}</b></div>
                                </div>
                            </div>
                            <div class="row col-12 hr">
                                <div class="form-group remove-botttom-space col-md-4">
                                    <label>RR PTAN</label>
                                    <div style="color:black"><b>{{data.rrptan}}</b></div>
                                </div>
                                <div class="form-group remove-botttom-space col-md-4">
                                    <label>DOL</label>
                                    <div style="color:black"><b>{{data.dol}}</b></div>
                                </div>
                                <div class="form-group remove-botttom-space col-md-4">
                                    <label>Medicaid</label>
                                    <div style="color:black"><b>{{data.medicaid}}</b></div>
                                </div>
                            </div>
                
                            <hr>
                            <h6 class="text-primary"><b>Facility Contacts</b> </h6>
                
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <th style="color:black">Title <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('title','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListContactDetails('title','desc')"></i>
                                        </th>
                                        <th style="color:black">Name <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('name','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListContactDetails('name','desc')"></i>
                                        </th>
                                        <th style="color:black">Email <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('email','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListContactDetails('email','desc')"></i>
                                        </th>
                                        <th style="color:black">Phone <i class="fas fa-arrow-up mr-1" (click)="sortListContactDetails('phone','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListContactDetails('phone','desc')"></i>
                                        </th>
                                    
                                        <!-- <th>Action</th> -->
                                    </thead>
                                    <tbody>
                                        <tr *ngFor='let contact of contactDetails '>
                                            <td>{{contact.title}}
                                               </td>
                                            <td>{{contact.name}}</td>
                                            <td>{{contact.email}}</td>
                                            <td>{{contact.phone}}</td>
                                            <!-- <td><a href="javascript:void(0)" (click) = "editContacts(contact)"><i class="fa fa-edit"></i></a>&nbsp;&nbsp;<a href="javascript:void(0)" (click) = 'deleteContacts(contact.id)'><i class="fa fa-trash" title="Delete"></i></a></td> -->
                                        </tr>
                                    </tbody>
                                </table>
                                
                            </div>
                            <hr>
                            <h6 class="text-primary"> <b>Notification Settings</b> </h6>
                
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        
                                        <th style="color:black">Name <i class="fas fa-arrow-up mr-1" (click)="sortListNotificationDetails('fullName','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListNotificationDetails('fullName','desc')"></i>
                                        </th>
                                        <th style="color:black">Email <i class="fas fa-arrow-up mr-1" (click)="sortListNotificationDetails('email','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListNotificationDetails('email','desc')"></i>
                                        </th>
                                        <th style="color:black">Notification 
                                            <!-- <i class="fas fa-arrow-up mr-1"></i>
                                            <i class="fas fa-arrow-down"></i> -->
                                        </th>
                                        <th style="color:black">Return Notification
                                             <!-- <i class="fas fa-arrow-up mr-1"></i>
                                            <i class="fas fa-arrow-down"></i> -->
                                        </th>
                                        <th style="color:black">Auth Review
                                             <!-- <i class="fas fa-arrow-up mr-1"></i>
                                            <i class="fas fa-arrow-down"></i> -->
                                        </th>
                                        <th style="color:black">Coding Expert
                                             <!-- <i class="fas fa-arrow-up mr-1"></i>
                                            <i class="fas fa-arrow-down"></i> -->
                                        </th>
                                        <th style="color:black">High $$$
                                            <!-- <i class="fas fa-arrow-up mr-1"></i>
                                            <i class="fas fa-arrow-down"></i> -->
                                        </th>
                                        <!-- <th>Action</th> -->
                                    </thead>
                                    <tbody>
                                        <tr *ngFor='let notification of notificationDetails '>
                                            <td>{{notification.fullName}}
                                               </td>
                                            <td>{{notification.email}}</td>
                                            <td>
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.notification == 1' checked disabled >
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.notification == 0'  disabled >
                                            </td>
                                            <td>
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.returnNotification == 1' checked disabled >
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.returnNotification == 0'  disabled >
                                            </td>
                                            <td>
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.authReview == 1' checked disabled >
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.authReview == 0'  disabled >
                                            </td>
                                            <td>
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.codingExpert == 1' checked disabled >
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.codingExpert == 0'  disabled >
                                            </td>
                                            <td>
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.highDollar == 1' checked disabled >
                                                <input type="checkbox" class="form-control-file py-1" *ngIf='notification.highDollar == 0'  disabled >
                                            </td>
                                            
                                        </tr>
                                    </tbody> 
                                </table>
                                
                            </div>
                           
                
                            <hr>
                            <h6 class="text-primary"><b> Additional Information</b></h6>
                
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <th style="color:black">Field <i class="fas fa-arrow-up mr-1" (click)="sortListFacilityCustomData('fieldName','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListFacilityCustomData('fieldName','desc')"></i>
                                        </th>
                                        <th style="color:black">Custom Value <i class="fas fa-arrow-up mr-1" (click)="sortListFacilityCustomData('fieldValue','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortListFacilityCustomData('fieldValue','desc')"></i>
                                        </th>
                                        <!-- <th></th> -->
                                    </thead>
                                    <tbody>
                                        <tr *ngFor='let ff of facilityfields '>
                                            <td>{{ff.fieldName}}</td>
                                            <td>{{ff.fieldValue}}</td>
                                            <!-- <td><a class="icon-color-red" (click) = "delCustomFieldMaster(ff.id)">
                                                <i class="fas fa-trash" title="Delete"></i></a></td> -->
                                        </tr>
                                        
                                    </tbody>
                                </table>
                                
                            </div>
                            <hr>
                            <!-- <div class="row col-12 hr" style="margin-top:13px;"> -->
                            <h6 class="text-primary"><b>Attachments</b></h6>
                            <div class="col-md-12">
                                <table class="table">
                                    <thead>
                                        <th style="color:black">Date <i class="fas fa-arrow-up mr-1" (click)="sortFacilityAttachmentData('createdDate','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortFacilityAttachmentData('createdDate','desc')"></i>
                                        </th>
                                        <th style="color:black">Category <i class="fas fa-arrow-up mr-1" (click)="sortFacilityAttachmentData('categoryName','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortFacilityAttachmentData('categoryName','desc')"></i>
                                        </th>
                                        <th style="color:black">User <i class="fas fa-arrow-up mr-1" (click)="sortFacilityAttachmentData('userName','asc')"></i>
                                            <i class="fas fa-arrow-down" (click)="sortFacilityAttachmentData('userName','desc')"></i>
                                        </th>
                                        <th style="color:black">File Details</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor='let ca of categoryAttachment '>
                                            <td>{{ca.createdDate | date:'MM/dd/yyyy'}};
                                               </td>
                                            <td>{{ca.categoryName}}</td>
                                            <td>{{ca.userName}}</td>
                                            <td><a (click)='downloadFile(ca)' href="javascript:void(0)" class="icon-color-green mr-1"><i
                                                        class="fas fa-download" title="Download"></i>{{ca.fileName}}</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div  id="Pricing" class="tab-pane fade">
                            <div *ngIf="facilityPricingArry.length > 0">
                                <h6 class="text-primary"><b>Pricing</b></h6><br/>
                            <div class="row">
                            
                                <div class="col-sm-12 col-md-6">
                                    <div class="myTables_length"><label>Show 
                                        <select name="myTable_length" aria-controls="dataTable" (change)="onEntryChange($event)" class="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries</label></div>
                                    </div>
                                    <div class="col-sm-12 col-md-6">
                                        <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue1"
                                                    class="form-control form-control-sm" (keyup.enter)="getfacilityPricing()" 
                                                    placeholder="CPT search"></label></div>
                                        <!-- (keyup)="sortTable($event)" -->
                                    </div>
                                </div>
                                <div class="table-responsive data-list">
                                    <table class="table" id="dataTable" width="100%" cellspacing="0">
                                        <thead>
                                            <tr>
                                                <th style="color:black"># </th>
                                                <th style="color:black">Date <i class="fas fa-arrow-up mr-1" (click)="sortListPricing('createddate','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortListPricing('createddate','desc')"></i>
                                                </th>
                                                <th style="color:black">CPT <i class="fas fa-arrow-up mr-1" (click)="sortListPricing('cpt','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortListPricing('cpt','desc')"></i>
                                                </th>
                                                <th style="color:black">Implant Log</th>
                                                <th style="color:black">Invoice</th>
                                                <th style="color:black">Comment</th>
                                                <!-- <th>Action</th> -->
                                            </tr>
                                        </thead>
                                        
                                        <tfoot>
                                            <tr>
                                                <th style="color:black"># </th>
                                                <th style="color:black">Date <i class="fas fa-arrow-up mr-1" (click)="sortListPricing('createddate','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortListPricing('createddate','desc')"></i>
                                                </th>
                                                <th style="color:black">CPT <i class="fas fa-arrow-up mr-1" (click)="sortListPricing('cpt','asc')"></i>
                                                    <i class="fas fa-arrow-down" (click)="sortListPricing('cpt','desc')"></i>
                                                </th>
                                                <th style="color:black">Implant Log</th>
                                                <th style="color:black">Invoice</th>
                                                <th style="color:black">Comment</th>
                                                <!-- <th>Action</th> -->
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                           
                                            <tr
                                                *ngFor='let data of facilityPricingArry | paginate: { itemsPerPage: pageValue1, currentPage: p1, totalItems: facilityPricingArry?.length }; let i = index'>
                                                <td>{{i+1}}</td>
                                                <td>{{data.createddate | date:'MM/dd/yyyy'}}</td>
                                                <td>{{data.cpt}}</td>
                                                <td><a *ngIf="data.implantlogname" href="javascript:void(0)" class="icon-color-green mr-1" (click)="downloadImplantLogFile(data)">
                                                    <i
                                                            class="fas fa-download" title="Download"></i>&nbsp;
                                                            {{data.implantlogname}}
                                                            <!-- {{data.implantlog}} -->
                                                        </a>
                                                        <span *ngIf="!(data.implantlogname)">Not Attached</span>
                                                    </td>
                                                        
                                                <td><a *ngIf="data.invoicename" href="javascript:void(0)" class="icon-color-green mr-1" (click)="downloadInvoiceFile(data)">
                                                    <i
                                                            class="fas fa-download" title="Download"></i>&nbsp;
                                                            {{data.invoicename}}
                                                            <!-- {{data.invoice}} -->
                                                        </a>
                                                        <span *ngIf="!(data.invoicename)">Not Attached</span></td>
                                                <td>{{data.comment}}</td>
                                                <!-- <td>
                                                    <a href="javascript:void(0)" class="icon-color-red">
                                                        <i class="fas fa-trash" title="Delete"></i></a>
                                                </td> -->
                                            </tr>
                
                                        </tbody>
                                      
                                    </table>
                                    <pagination-controls class="float-right"  (pageChange)="pageChange($event); p1 = $event" >
                                    </pagination-controls>
                                </div>

                            </div>
                            <div *ngIf="facilityPricingArry.length == 0">
                                No data Found
                            </div>
                            
                            </div>
                            
                          </div>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    <!-- Sidenav-->