import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CurrentCaseRequest } from './current-case-request.model';

export interface CurrentCaseRequestState extends EntityState<CurrentCaseRequest> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'current-case-request' })
export class CurrentCaseRequestStore extends EntityStore<CurrentCaseRequestState> {

  constructor() {
    super();
  }

}
