import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-notification-form',
  templateUrl: './email-notification-form.component.html',
  styleUrls: ['./email-notification-form.component.css']
})
export class EmailNotificationFormComponent implements OnInit {
  ENForm: FormGroup;
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  eventName: string;
  formFlag: string = "Add";
  _formData: any;
  submitted = false;
  
  @Input() set formData(value: any) {

    if (!isNullOrUndefined(value)) {
      this._formData = value;
      this.formFlag = "Edit";
      this.eventName = this._formData.emailEvent;
      this.editForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void { }

  get f() { return this.ENForm.controls; }

  addRecord() {
    this.submitted = true;
    const formdata = this.ENForm.getRawValue();

    if (formdata.EmailTo != "") {

      this.getApiService.post("Admin/SaveEmailConfig", formdata).subscribe((resp: any) => {
        if (resp.status == "Success") {
          this.ENForm.reset();
        }
        this.editEmitter.emit({
          'status': true,
          'data': resp
        })

      }, (err) => {
        console.log(err);
      })
    }
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  editForm() {
    this.ENForm = this.fb.group({
      Id: [this._formData.id, [Validators.required]],
      EmailTo: [this._formData.emailTo, [Validators.required]],
      EmailCc: [this._formData.emailCC, Validators.required],
      EmailBcc: [this._formData.emailBCC, [Validators.required]]
    })
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

}
