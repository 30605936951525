<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="row">
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-12">
                                    <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="facilityName"
                                        (change)="onFacilityChange()">

                                        <option *ngFor="let c of FacilityList" [value]="c.name">{{c.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                        [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="fromDate">
                                </div>
                                <div class="col-md-3">
                                    <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                        [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="toDate">
                                </div>
                                <div class="col-md-3">
                                    <button (click)="getData()" class="btn btn-primary">Refresh</button>
                                </div>
                                <div class="col-md-2">
                                    <a [routerLink]="['/d/exceptions', facilityID]" class="btn btn-primary"
                                        type="button">Exceptions</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="row rowwrp">
                        <div class="col-md-6">
                            <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="facilityName"
                                (change)="onFacilityChange()">
                              
                                <option *ngFor="let c of FacilityList" [value]="c.name">{{c.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row rowwrp">
                        <div class="col-md-3">
                            <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="fromDate">
                        </div>
                        <div class="col-md-3">
                            <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="toDate">
                        </div>
                        <div class="col-md-2">
                            <button (click)="getData()" class="btn btn-primary">Refresh</button>
                        </div>
                        <div class="col-md-2">
                            <a [routerLink]="['/d/exceptions', facilityID]" class="btn btn-primary"
                                type="button">Exceptions</a>
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Audit DateTime</th>
                                    <th>UserName</th>
                                    <th>MonitorType</th>
                                    <th>AuditActivity</th>
                                    <th>FilePath</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Audit DateTime</th>
                                    <th>UserName</th>
                                    <th>MonitorType</th>
                                    <th>AuditActivity</th>
                                    <th>FilePath</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor="let d of auditList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{d?.auditDateTime | date: "MM/dd/yyyy"}}<br>
                                        {{d?.auditDateTime | date: "hh:mm a"}}</td>
                                    <td>{{d?.userName}}</td>
                                    <td>{{d?.monitorType}}</td>
                                    <td>{{d?.auditActivity}}</td>
                                    <td>{{d?.filePath}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>