import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { authReview, blockReport, clientLiteRole, clientRole, codingExpert, codingQuery, highApproval, highApprovalInternal, implantLog, inCoding, invoice, na, NameConstant, operatingReport, otherMisc, pathology, pricing, variance } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CaseRequestHelper } from '../../helper/case-request.helper';
import { CaseRequestCptService } from '../case-request-cpt/case-request-cpt.service';
import { createCaseRequest } from '../case-request/case-request.model';
import { CaseRequestService } from '../case-request/case-request.service';
import { createCurrentCaseRequest, CurrentCaseRequest } from '../current-case-request/current-case-request.model';
import { CurrentCaseRequestService } from '../current-case-request/current-case-request.service';
import { CaseRequestInfo, createCaseRequestInfo, createCptDetails, createFileHistoryInfo, createPatientInfo, createPricingInfo, createQueueInfo, createSurgeryInfo, PatientInfo, PricingInfo, QueueInfo, SurgeryInfo } from './case-request-info.model';
import { CaseRequestInfoQuery } from './case-request-info.query';
import { CaseRequestInfoStore } from './case-request-info.store';
import { SessionQuery } from 'src/app/modules/login/session/session.query';

@Injectable({ providedIn: 'root' })
export class CaseRequestInfoService {

  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  clientRole:boolean =  this.sessionQuery.isClientRole();
  clientLiteRole:boolean = this.sessionQuery.isClientLiteRole();
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  constructor(
    private caseRequestInfoStore: CaseRequestInfoStore,
    private apiService: ApiService,
    private toastr: ToastrService,
    private caseRequestService: CaseRequestService,
    private currentCaseRequestService: CurrentCaseRequestService,
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private caseRequestCptService: CaseRequestCptService,
    private sessionQuery:SessionQuery,
  ) {
  }

  reset() {
    this.caseRequestInfoStore.set([]);
  }
  
  
  fetchAndload(id: string | number, isBulkAction: boolean) {
    console.log("Loading patiet info for ", id);
    let data = {
      "Bdosid": id
    }

    return this.apiService.post("BillingNew/GetPatientsInfo", data).pipe(tap(res => {
      
      if (res.status == "Success") {

        if(!res.dataPatient[0]){
          this.toastr.error('No data found for give bdosid', id.toString());
          return;
        }
        const patientInfo: PatientInfo = createPatientInfo({
          facilityId: res.dataPatient[0].facilityId,
          facilityName: res.dataPatient[0].facilityName,
          patientName: res.dataPatient[0].patientName,
          mrn: res.dataPatient[0].mrn,
          gender: res.dataPatient[0].gender,
          address1: res.dataPatient[0].address1,
          address2: res.dataPatient[0].address2,
          state: res.dataPatient[0].state,
          city: res.dataPatient[0].city,
          zip: res.dataPatient[0].zip,
          dob: res.dataPatient[0].dob,
          phonenumber: res.dataPatient[0].phonenumber,
          ssnNo: res.dataPatient[0].ssnNo,
          region: res.dataPatient[0].region
        });

        const surgeryInfo: SurgeryInfo = createSurgeryInfo({
          insuranceId: res.dataDosDetails[0].insuranceId,
          insuranceProvider: res.dataDosDetails[0].insuranceProvider,
          groupNumber: res.dataDosDetails[0].groupNumber,
          physician: res.dataDosDetails[0].physician,
          billed: res.dataDosDetails[0].billed,
          dateOfSurgery: res.dataDosDetails[0].dateOfSurgery,
        });

        const cptDetails = res.dataCPTDetails?.map(dataCpt => createCptDetails({
          cptCode: dataCpt.cptCode,
          surgeryDescription: dataCpt.surgeryDescription
        }));

        const pricingInfo: PricingInfo[] = res.dataPricingInfo?.map(pi => createPricingInfo({
          implantlog: pi.implantlog,
          invoice: pi.invoice
        }));

        const queueInfo: QueueInfo = createQueueInfo({
          billed: res.dataQueueInfo[0].billed,
          operatingReport: res.dataQueueInfo[0].operatingReport,
          pathology: res.dataQueueInfo[0].pathology,
          pricing: res.dataQueueInfo[0].pricing,
          highApproval: res.dataQueueInfo[0].highApproval,
          blockReport: res.dataQueueInfo[0].blockReport,
          codingQuery: res.dataQueueInfo[0].codingQuery,
          otherMisc: res.dataQueueInfo[0].otherMisc,
          inCoding: res.dataQueueInfo[0].inCoding,
          variance: res.dataQueueInfo[0].variance,
          authReview: res.dataQueueInfo[0].authReview,
          returnedCodingRFI: res.dataQueueInfo[0].returnedCodingRFI,
          overdue: res.dataQueueInfo[0].overdue,
          codingExpert: res.dataQueueInfo[0].codingExpert,
          billerNote: res.dataQueueInfo[0].billerNote,
          clientNote: res.dataQueueInfo[0].clientNote,
          status: res.dataQueueInfo[0].status,
          highApprovalInternal: res.dataQueueInfo[0].highApprovalInternal,
          implantLog: res.dataQueueInfo[0].implantLog,
          invoice: res.dataQueueInfo[0].invoice
        });

        const fileHistoryInfo = res.dataFileHistoryInfo?.map(fh => createFileHistoryInfo({
          billerTime: fh.billerTime,
          billerName: fh.billerName,
          billerNote: fh.billerNote,
          auditEventType: fh.auditEventType,
          auditEventDescription: fh.auditEventDescription,
          clientName: fh.clientName,
          clientTime: fh.clientTime,
          clientNote: fh.clientNote,
          coderName: fh.coderName,
          coderTime: fh.coderTime,
          coderNote: fh.coderNote,
          contentFileName: fh.contentFileName,
          id: fh.id
        }));

        const caseRequestInfo = createCaseRequestInfo({
          id: id,
          dataPatient: patientInfo,
          dataDosDetails: surgeryInfo,
          dataCPTDetails: cptDetails,
          dataQueueInfo: queueInfo,
          dataFileHistoryInfo: fileHistoryInfo,
          dataPricingInfo: pricingInfo,
          userRole: res.userRole
        });
        
        this.caseRequestCptService.fetchAndLoadHigh$$$CptDetails(caseRequestInfo.userRole, id, isBulkAction);
        this.caseRequestCptService.fetchAndLoadVarianceCptDetails(caseRequestInfo.userRole,id, isBulkAction);


        // if(caseRequestInfo.userRole === clientRole.value){
          if(this.clientRole || this.clientLiteRole ){
            console.log("client or clientlite")
          this.caseRequestService.reset();
        }
        if (queueInfo.operatingReport) {
          if (caseRequestInfo.userRole === clientRole.value || caseRequestInfo.userRole ===clientLiteRole.value){

// if (caseRequestInfo.userRole === clientRole.value){
          if(this.caseRequestHelper.isClientCaseRequest(operatingReport.id)) {
              this.addRequestToSelectionListForClient(operatingReport);
            }
          } else {
            this.caseRequestService.update(operatingReport.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(operatingReport.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(operatingReport));
          }
        }
        if (queueInfo.pathology) {
          // if (caseRequestInfo.userRole === clientRole.value || clientLiteRole.value){ 
            // if (caseRequestInfo.userRole === clientRole.value){ 
              if (caseRequestInfo.userRole === clientRole.value || caseRequestInfo.userRole ===clientLiteRole.value){
            if(this.caseRequestHelper.isClientCaseRequest(pathology.id)) {
            this.addRequestToSelectionListForClient(pathology);
            }
          } else {
            this.caseRequestService.update(pathology.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(pathology.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(pathology));
          }
        }
        if (queueInfo.pricing) {
          if (caseRequestInfo.userRole === clientRole.value ) {
            if(this.caseRequestHelper.isClientCaseRequest(pricing.id)){
              this.addRequestToSelectionListForClient(pricing);
            }
          } else {
            this.caseRequestService.update(pricing.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(pricing.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(pricing));
          }
        }
        if (queueInfo.highApproval) {
          if (caseRequestInfo.userRole === clientRole.value) {
            if(this.caseRequestHelper.isClientCaseRequest(highApproval.id)){
              this.addRequestToSelectionListForClient(highApproval);
            }
          } else {
            this.caseRequestService.update(highApproval.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(highApproval.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(highApproval));
          }
        }
        if (queueInfo.blockReport) {
          if (caseRequestInfo.userRole === clientRole.value) {
            if(this.caseRequestHelper.isClientCaseRequest(blockReport.id)){
              this.addRequestToSelectionListForClient(blockReport);
            }
            
          } else {
            this.caseRequestService.update(blockReport.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(blockReport.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(blockReport));
          }
        }
        if (queueInfo.codingQuery) {
          // if (caseRequestInfo.userRole === clientRole.value || clientLiteRole.value) {
            // if (caseRequestInfo.userRole === clientRole.value) {
              if (caseRequestInfo.userRole === clientRole.value || caseRequestInfo.userRole ===clientLiteRole.value){
            if(this.caseRequestHelper.isClientCaseRequest(codingQuery.id)){
              this.addRequestToSelectionListForClient(codingQuery);
            }
          } else {
            this.caseRequestService.update(codingQuery.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(codingQuery.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(codingQuery));
          }
        }
        if (queueInfo.otherMisc) {
          // if (caseRequestInfo.userRole === clientRole.value || clientLiteRole.value) {
            // if (caseRequestInfo.userRole === clientRole.value) {
              if (caseRequestInfo.userRole === clientRole.value || caseRequestInfo.userRole ===clientLiteRole.value){
            if(this.caseRequestHelper.isClientCaseRequest(otherMisc.id)){
              this.addRequestToSelectionListForClient(otherMisc);
            }
          } else {
            this.caseRequestService.update(otherMisc.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(otherMisc.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(otherMisc));
          }
        }
        if (queueInfo.inCoding) {
          // if ((caseRequestInfo.userRole === clientRole.value || clientLiteRole.value)){
            // if ((caseRequestInfo.userRole === clientRole.value)){
              if (caseRequestInfo.userRole === clientRole.value || caseRequestInfo.userRole ===clientLiteRole.value){
            if(this.caseRequestHelper.isClientCaseRequest(inCoding.id)) {
            this.addRequestToSelectionListForClient(inCoding);
            }
          } else {
            this.caseRequestService.update(inCoding.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(inCoding.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(inCoding));
          }
        }
        if (queueInfo.variance) {
          if (caseRequestInfo.userRole === clientRole.value) {
            if(this.caseRequestHelper.isClientCaseRequest(variance.id)){
            this.addRequestToSelectionListForClient(variance);
            }
          } else {
            this.caseRequestService.update(variance.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(variance.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(variance));

          }
        }
        if (queueInfo.authReview) {
          if (caseRequestInfo.userRole === clientRole.value){
            if(this.caseRequestHelper.isClientCaseRequest(authReview.id)) {
            this.addRequestToSelectionListForClient(authReview);
            }
          } else {
            this.caseRequestService.update(authReview.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(authReview.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(authReview));
          }
        }
        if (queueInfo.codingExpert) {
          // if (caseRequestInfo.userRole === clientRole.value || clientLiteRole.value){ 
            // if (caseRequestInfo.userRole === clientRole.value){ 
              if (caseRequestInfo.userRole === clientRole.value || caseRequestInfo.userRole ===clientLiteRole.value){
            if(this.caseRequestHelper.isClientCaseRequest(codingExpert.id)) {
              this.addRequestToSelectionListForClient(codingExpert);
            }
          } else {
            this.caseRequestService.update(codingExpert.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(codingExpert.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(codingExpert));
          }
        }
        if (queueInfo.highApprovalInternal) {
          if (caseRequestInfo.userRole === clientRole.value ){ 
            if(this.caseRequestHelper.isClientCaseRequest(highApprovalInternal.id)) {
              // to nothing as this won' be shown on client side TODO: remove this if
            }
          } else {
            this.caseRequestService.update(highApprovalInternal.id, { alreadySubmitted: true });
            this.caseRequestService.addActive(highApprovalInternal.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(highApprovalInternal));
          }
        }

        if (queueInfo.implantLog) {
          if (caseRequestInfo.userRole === clientRole.value ){  
            if(this.caseRequestHelper.isClientCaseRequest(implantLog.id)) {
              this.addRequestToSelectionListForClient(implantLog);
            }
          } else {
            const exclamationIndicator = this.caseRequestHelper.hasImplantLog(pricingInfo);
            this.caseRequestService.update(implantLog.id, { hasIndicator: exclamationIndicator, alreadySubmitted: true });
            this.caseRequestService.addActive(implantLog.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(implantLog));

          }
        }else if(this.caseRequestHelper.hasImplantLog(pricingInfo)){
          this.caseRequestService.update(implantLog.id, { hasIndicator: true});
        }

        if (queueInfo.invoice) {
          if (caseRequestInfo.userRole === clientRole.value ){
            if(this.caseRequestHelper.isClientCaseRequest(invoice.id)) {
              this.addRequestToSelectionListForClient(invoice);
            }
          } else {
            const exclamationIndicator = this.caseRequestHelper.hasInvoice(pricingInfo);
            this.caseRequestService.update(invoice.id, { hasIndicator: exclamationIndicator, alreadySubmitted: true });
            this.caseRequestService.addActive(invoice.id);
            this.currentCaseRequestService.add(this.getCurrentRequest(invoice));
          }
        }else if(this.caseRequestHelper.hasInvoice(pricingInfo)){
          this.caseRequestService.update(invoice.id, { hasIndicator: true });
        }

        this.caseRequestInfoStore.set([caseRequestInfo]);
      } else {
        this.toastr.error("Error!", res.message);
      }
    }));
  }

  add(caseRequestInfo: CaseRequestInfo) {
    this.caseRequestInfoStore.add(caseRequestInfo);
  }

  update(id, caseRequestInfo: Partial<CaseRequestInfo>) {
    this.caseRequestInfoStore.update(id, caseRequestInfo);
  }

  remove(id: ID) {
    this.caseRequestInfoStore.remove(id);
  }

  private getCurrentRequest(name: NameConstant): CurrentCaseRequest {
    return createCurrentCaseRequest({
      id: name.id,
      value: name.value,
      type: this.caseRequestHelper.getRequstType(name.id),
      alreadySubmitted: true,
      isModified: false
    });
  }

  private addRequestToSelectionListForClient(name: NameConstant){
           const req = this.caseRequestHelper.getCaseRequestByid(name.id);
            const cr = createCaseRequest({
              id: req.id,
              value: req.value,
              displayName: req.displayName,
              type: this.caseRequestHelper.getRequstType(req.id),
              hasIndicator: false,
              alreadySubmitted: false,
              isHigh$$$orVarRequestStatus: na.id,
              bulkActionQueueName: req.bulkActionQueueName
              });
            this.caseRequestService.add(cr);
  }


  destroy(){
    this.caseRequestInfoStore.destroy();
  }
}
