import {Pipe, PipeTransform} from '@angular/core';
 
@Pipe({
    name: 'dotToColon'
})
export class DotToColonPipe implements PipeTransform {

    /**
     * @param value
     */
    transform(value): string {
        console.log('replacing . with : in ', value);
        if (value === undefined) {
            return '';
        }
        if(isNaN(Number(value))){
            return 'na';
        }
        const valStr = String(value);
        let retVal =  valStr.replace('.', ':');
        if(retVal.includes(':')){
            console.log('Return Value', retVal);
            return retVal;
        }else{
            console.log('Return Value', retVal + ":00");
            return retVal + ":00";
        }
    }
}