export interface TableMenuConfig {
 searchDropdown?: SearchDropdown;
 simpleDropdown?: SimpleDropdown[];
 searchBar?: SearchBar;
 actionButton?: ActionButton;
}

export interface SearchBar {
  placeholder?: string;
  hidden?: boolean;
  position: Position;
  isInputAllowed?: (val: string) => boolean;
}

export interface SearchDropdown {
  key: string;
  hidden?: boolean;
  position: Position;
}

export interface SimpleDropdown {
  key: string;
  serviceName: string;
  label?: string;
  hidden?: boolean;
  position: Position;
}

export interface ActionButton {
  action: string;
  label: string;
  hidden?: boolean;
  position?: Position;
  enabled?: () => boolean;
}
export enum Position {
  LF, // Left First
  LS, // Left Second
  RF, // Right First
}