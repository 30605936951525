<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="true">
    <p class="bulk-action-loader"> Loading... </p>
</ngx-spinner>
<div class="" *ngIf='showModalForm'>
    
        <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
        <app-patient-info-header [bdosid]="_formData.bdosid" (closeEmitter)="onHeaderClickClose()"></app-patient-info-header>
        <div class="container container-pad-lf container-pad-tp-one">
            <div class="row">
                <div class="col-md-3" *ngIf="isCaseRequestSelectionAllowed()">
                    <div class="row">
                        <div class="col-md-12 container-pad-lf-zero">
                    
                            <div class="row" *ngIf="!(clientRole || clientLiteRole)">
                                <div class="col-md-6">
                                    <button type="button" [disabled]="todaydate >= newdate" data-toggle="modal"
                                        data-target="#deleteModal" class="btn btn-secondary btn-cr-action"
                                        style="margin-top: 10px;"><i
                                        class="fa fa-trash" title="Delete" aria-hidden="true"></i>
                                    </button>
                                </div>
                                <div class="col-md-6">
                                    <button type="button" [disabled]="todaydate >= newdate" data-toggle="modal"
                                        data-target="#cancelModal" class="btn btn-secondary btn-cr-action-cancel"
                                        style="margin-top: 10px;"><i class="fa fa-times" aria-hidden="true"></i>
                                    </button>
                                </div>
                            </div>
                            <app-case-request-selection ></app-case-request-selection>
                        </div>
                    </div>
                </div>
                <div [ngClass]="isCaseRequestSelectionAllowed() ? 'col-md-9' : 'col-md-12'">
                    <div class="row" style="height: 32px;">
                        <div class="col-md-1">
                            <a *ngIf="(tabs$ | async)?.length > 3" href="javascript:void(0)"
                                class="btn btn-primary case-request-tab-side-btn" role="button"
                                (click)="scrollLeft()"><i class="fas fa-chevron-left"></i></a>
                        </div>
                        <div class="col-md-10 container-pad-lf-zero">
                          
                            <div style="display: flex;overflow-x: scroll;" #tabList>
                                <div *ngFor="let tab of tabs$ | async">
                                    <button type="button" style="min-width: max-content"
                                        class="btn btn-primary  btn-sm btn-cr-tab"
                                        (click)="tabSelected(tab)">{{ tab.displayName }}
                                        <i class="fas fa-times fa-sm cr-close" (click)="removeTab(tab)"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <a *ngIf="(tabs$ | async)?.length > 3" href="javascript:void(0)" style="margin-left: -37px;"
                                class="btn btn-primary case-request-tab-side-btn" role="button"
                                (click)="scrollRight()"><i class="fas fa-chevron-right"></i></a>
                        </div>
                    </div>
                    <div id="crContainerComponentId" class="container case-rquest-tab-container" 
                    *ngIf="!(isCaseRequestCalledFromBatchCaseReview() || isCaseRequestCalledFromReviewedCases() || isCaseRequestCalledFromCodingReview() || isCaseRequestCalledFromCodingReviewed())">
                        <div class="tab-content case-request-tab-content">
                            <div *ngFor="let tab of tabs$ | async" id="{{tab.id}}" class="tab-pane fade"
                                [ngClass]="{ 'active show': isTabActive(tab) }">
                                <!-- Putting Switch case based on id load correponding component-->
                                <container-element [ngSwitch]="activeTabId$ | async">
                                    <!-- the same view can be shown in more than one case -->
                                    <app-cr-op-note *ngSwitchCase="operatingReport.id"></app-cr-op-note>
                                    <app-cr-pathology *ngSwitchCase="pathology.id"></app-cr-pathology>
                                    <app-cr-pricing *ngSwitchCase="pricing.id"></app-cr-pricing>
                                    <app-cr-block-report *ngSwitchCase="blockReport.id"></app-cr-block-report>
                                    <app-cr-coding-query *ngSwitchCase="codingQuery.id"></app-cr-coding-query>
                                    <app-cr-other-misc *ngSwitchCase="otherMisc.id"></app-cr-other-misc>
                                    <app-cr-auth-review *ngSwitchCase="authReview.id"></app-cr-auth-review>
                                    <app-cr-client-high *ngSwitchCase="clientHighApproval.id"></app-cr-client-high>
                                    <app-cr-nsn-high *ngSwitchCase="nsnHighApproval.id"></app-cr-nsn-high>
                                    <app-cr-coding-expert *ngSwitchCase="codingExpert.id"></app-cr-coding-expert>
                                    <app-cr-in-coding *ngSwitchCase="inCoding.id"></app-cr-in-coding>
                                    <app-cr-variance *ngSwitchCase="variance.id"></app-cr-variance>
                                    <app-cr-invoice *ngSwitchCase="invoice.id"></app-cr-invoice>
                                    <app-cr-implant-log *ngSwitchCase="implantLog.id"></app-cr-implant-log>

                                    <!--default case when there are no matches -->
                                    <div class="card-body" *ngSwitchDefault>
                                        No Component Found for id {{activeTabId$ | async}}
                                    </div>
                                </container-element>
                            </div>
                            <div *ngIf="(tabs$ | async).length === 0" class="card-body">
                                Select your request from the list on the left.
                            </div>
                        </div>
                        
                    </div>
                    <div id="crContainerReviewDone" class="container case-rquest-tab-container" *ngIf="isCaseRequestCalledFromBatchCaseReview() || isCaseRequestCalledFromCodingReview()">
                        <div class="tab-content case-request-tab-content">
                            <app-cr-review-done 
                            [callerId]="_commandType"
                            [reviewDoneEvent]="reviewDoneSubject.asObservable()"
                            (reviewCompletedEmitter)="reviewCompleted($event)"
                            ></app-cr-review-done>
                        </div>
                    </div>
                    <div id="crContainerReviewDoneBtn" class="container container-pad-lf-zero" *ngIf="((isClientView$ | async) === false) && !isCaseRequestSelectionAllowed()">
                        <div class="card">
                            <div class="card-body" *ngIf="isCaseRequestCalledFromBatchCaseReview() || isCaseRequestCalledFromCodingReview()">
                                <div class="row">
                                    <div style="padding-left: 125px;">
                                        <button type="button" (click)="reviewDone()" [disabled]="loading"
                                        class="btn btn-primary btn-cr-action-set-billed"><i class="fa fa-check"
                                        aria-hidden="true"></i>Review Done
                                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="crContainerCurrentRequest" class="container container-pad-lf-zero" *ngIf="hasCurrentRequest$ | async">
                        <div class="row">
                            <div class="col-md-12">
                                <app-current-case-request (closeEmitter)="onClickClose()"></app-current-case-request>
                            </div>
                        </div>
                    </div>
                    <div id="crContainerSetBilledBtn" class="container container-pad-lf-zero" *ngIf="(isClientView$ | async) === false">
                        <div class="card" *ngIf="isCaseRequestSelectionAllowed()">
                            <div class="card-body">
                                <div class="row">
                                    <div style="padding-left: 125px;">
                                        <button type="button"
                                            *ngIf="privileges.dSetBilled &&!billedInfo" data-toggle="modal" (click)='showSetBilled()'
                                            class="btn btn-primary btn-cr-action-set-billed"><i class="fa fa-check"
                                                aria-hidden="true"></i>
                                            SET BILLED</button>

                                        <button type="button" 
                                        [disabled]="todaydate >= newdate" 
                                        *ngIf="privileges.dUndoSetBilled && billedInfo"
                                            (click)="showUnSetBilled()" class="btn btn-secondary btn-cr-action"><i
                                                class="fa fa-times" aria-hidden="true"></i>
                                            UNDO SET BILLED</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="crContainerRequestNotes" class="container container-pad-lf-zero" *ngIf="isCaseRequestSelectionAllowed()">
                        <app-case-request-notes></app-case-request-notes>
                    </div>
                    <div id="crContainerRequestHistory" [ngClass]="isCaseRequestSelectionAllowed() ? 'cr-history-section' : 'cr-history-section-without-req-selection'"
                    class="container container-pad-lf-zero">
                        <app-case-request-history></app-case-request-history>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <app-add-attachment [label]="'Attachments'" [callerId]="'caseRequest'" [showCancel]="true"
                                (onUpload)="onUpload()" (onCancel)="onClickClose()"></app-add-attachment>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <app-show-attachment [label]="'History'" [callerId]="componentId"></app-show-attachment>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="cancelModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Confirm Cancelled Case?</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">Are you sure you would like to cancel this case?</div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Wait</button>
                    <button class="btn btn-primary" type="button" data-dismiss="modal"
                        (click)="caseCancelled()">Confirm</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="setBilledModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">SPECIFY TYPE</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h6>Please specify the case type</h6>
                    <div class="row">
                        <div class="col-md-4 text-right mt-2">
                            <label><strong>Case Charge $</strong></label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" [(ngModel)]="billAmount" placeholder="0.00">
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" data-dismiss="modal"
                        (click)="setBilled('Electronic')">Electronic</button>
                    <button class="btn btn-primary" type="button" data-dismiss="modal"
                        (click)="setBilled('Paper')">Paper</button>
                    <button class="btn btn-primary" type="button" data-dismiss="modal"
                        (click)="setBilled('SelfPay')">Self-Pay</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Delete This Case?</h5>
                    <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h6>Are you sure you would like to delete this case?</h6>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" type="button" data-dismiss="modal"
                        (click)="deleteCase()">Confirm</button>
                    <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf=showConfirmation class="bg-blur">
        <div id="centerModal" class="sidenav cr-confirmation shadow width-700  active">
            <div
                class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
                <div class="row case-row m-0 font-weight-bold text-primary">
                    <h4>There are unsaved changes !</h4>
                </div>


            </div>
            <div class="card-body">
                <div class="centerText">

                    <h5>Are you sure you want to cancel changes?</h5><br>
                    <div class="text-right">

                        <a href="javascript:void(0);" (click)="confirmNoClick()"
                            class="mr-2 btn btn-warning  closeMySidenav" id="closeMySidenav">
                            No
                        </a>
                        <a href="javascript:void(0);" (click)="confirmYesClick()"
                            class="mr-2 btn btn-success closeMySidenav" id="closeMySidenav">
                            Yes
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
    
</div>

<app-unset-billed-action [formData]="_formData" *ngIf="showUnSetBilledForm"  [primaryCPt]="primaryCPt" (closeEmitter)="onCloseFormModal()"
(addedEmitter)="onAddRecord($event)"></app-unset-billed-action>

<app-claim-type-action [formData]="_formData" [claimTypeAction]="'Set Billed'" *ngIf="showSetBilledForm" (closeEmitter)="onCloseFormModal()"
    (addedEmitter)="onAddRecord($event)">
</app-claim-type-action>