<div class="patient-detail-accordion container-fluid cont-mrg patient-detail-accordion"
    *ngFor="let nsnAccordian of customAccordianList">
    <div class="container1" *ngIf="nsnAccordian.count !== 0">
        <div class="row advance-surgery-center">
            <div class="col-md-5 accordian-row">
                <p class="advance-section-text">{{nsnAccordian.name}}
                </p>
            </div>
            <div class="col-md-2">
                <p class="advance-section-text text-right">{{nsnAccordian.count}}
                </p>
            </div>
            <div class="col-md-5" style="padding: 0px;">
                <p class="advance-icon-text plus-sign-bx expand-data">
                    <i *ngIf="!nsnAccordian.isActive" (click)="onClickPlus(nsnAccordian)" class="fa fa-plus"></i>
                    <i *ngIf="nsnAccordian.isActive" (click)="onClickMinus(nsnAccordian)" class="fa fa-minus"></i>
                </p>
            </div>
        </div>
    <div style="margin-top: 5px;">
        <app-nsn-grid 
        *ngIf="nsnAccordian.isActive" 
        [callerId]="callerId" 
        [nsnGridConfigEvent]="nsnGridConfigSubject.asObservable()" 
        [coloumns]="coloumns" 
        [isDataSourceProvided]="false"
        [paginationControlId]="callerId.id" 
        (showQAOPFormEmitter)="showQAOPFormModal($event)"
        (showQAICFormEmitter)="showQAICFormModal($event)" 
        (showQAATFormEmitter)="showQAATFormModal($event)"
        (showToolsFormEmitter)="showToolsFormModal($event)" 
        (showCaseRequestFormEmitter)="showCaseRequestFormModal($event)"
        (onPageChange)="pageChange($event)">
        </app-nsn-grid>
    </div>       

</div>

<div *ngIf="customAccordianList.length === 0" class="widget-dashboard">
    There were no records matching your selection. Please try again.
</div>