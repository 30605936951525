import { Component, Input, OnInit } from '@angular/core';
import { implantLog, invoice, NameConstant } from 'src/app/shared/models/name-constant';

@Component({
  selector: 'app-nsn-grid-active-queue',
  templateUrl: './nsn-grid-active-queue.component.html',
  styleUrls: ['./nsn-grid-active-queue.component.css']
})
export class NsnGridActiveQueueComponent implements OnInit {
  @Input() data;
  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  constructor() { }

  ngOnInit(): void {
  }

}
