import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/services/api.service';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-patient-request',
  templateUrl: './patient-request.component.html',
  styleUrls: ['./patient-request.component.css']
})
export class PatientRequestComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  PatientSummaryDtls: any;
  _formData: any;
  smryIsToggled = false;
  tabHead = "";
  fileSuffix = "";
  stat: any;
  highApprovalinternal: any;
  varStat: any;
  dataQueueInfo: any = {};
  dataDefaultQueueInfo: any = {};
  dataQueueDtl: any = {};

  clntNsnInfo = false;
  highNsnInfo = false;
  varianceInfo = false;

  varianceList: any = [];
  dollarList: any = [];

  unlockReq = false;

  note: string;
  noteType: string;
  satisfyType: string;
  noteSelect: string = "General Note";

  patientCPTs: any = [];
  primaryCPT: number;
  totalCost: number = 0;
  billAmount: number = 0;

  searching = false;
  searchFailed = false;
  notFound = false;

  requestArry: any = [];
  cptArry: any = [];
  cptVarArry: any = [];
  CPTCodeListForm: FormArray;
  VarianceListForm: FormArray;
  fileName: any;
  fname: any;
  fileAttachName: any;
  fileByte: any;
  facilityId: number = 0;
  facilityName: string = "";
  uploadFileText: string;
  uploadFile: any;
  PatientsForm: FormGroup;

  tabVal: any = [];
  selectedtabVal: any = [];
  txtApproval = "";
  billedInfo: boolean = true;
  privileges: any ;

  dataQueue = [{ "key": "OP Note", "value": "operatingReport" }, { "key": "Pathology", "value": "pathology" }, { "key": "Pricing", "value": "pricing" },
  { "key": "Block Report", "value": "blockReport" }, { "key": "Coding Query", "value": "codingQuery" }, { "key": "Other/Misc", "value": "otherMisc" },
  { "key": "Client High$$$", "value": "highApproval" }, { "key": "In Coding", "value": "inCoding" }, { "key": "NSN High$$$", "value": "highApprovalInternal" },
  { "key": "Coding Variance Review", "value": "variance" }, { "key": "Auth Review", "value": "authReview" }, { "key": "Coding Expert", "value": "codingExpert" }];


  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private accessServices : AccessService,
    private spinner: NgxSpinnerService) {
    this.initForm();
    this.tabVal["CONT0"] = true;
  }

  initForm() {
    this.PatientsForm = this.fb.group({
      txtNote: ["", [Validators.required]],
      reqFile: ["", Validators.required],
      CPTCodeList: this.fb.array([
        this.getCptCodeList()
      ]),
      VarianceList: this.fb.array([
        this.getVarianceList()
      ])
    })
  }

  ngOnInit(): void {
    this.privileges = this.accessServices.navbar_access
    this.getPatientHeaderData();
    this.CPTCodeListForm = <FormArray>this.PatientsForm.controls.CPTCodeList;
    this.VarianceListForm = <FormArray>this.PatientsForm.controls.VarianceList;

    this.noteType = "Public";
  }

  getCptCodeList() {
    return this.fb.group({
      cpt: ['', Validators.required],
      CPTCode: ['', Validators.required],
      CPTDesc: ['', Validators.required],
      Rate: ['', Validators.required]
    });
  }

  getVarianceList() {
    return this.fb.group({
      nsn_cpt: ['', Validators.required],
      nsn_Desc: ['', Validators.required],
      physician_cpt: ['', Validators.required],
      physician_Desc: ['', Validators.required]
    });
  }

  searchVal(term: string) {
    if (term == '') {
      return of([]);
    }

    let data = { "CPTCode": term, "FacilityId": this.facilityId };

    return this.getApiService
      .post("/CreatePatient/GetCPT", data).pipe(
        map(response => response.dataCPTInfo)
      );
  }

  formatter = (x: { cpt: string }) => x.cpt;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.searchVal(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  onClickClose() {
    this.closeEmitter.emit();
  }

  getPatientHeaderData() {
    this.spinner.show();
    let data = {
      "Bdosid": this._formData.bdosid
    }

    this.getApiService.post("BillingNew/GetPatientsInfo", data).subscribe(res => {
      this.spinner.hide();
      if (res.status == "Success") {

        this.PatientSummaryDtls = res;

        this.facilityId = res.dataPatient[0].facilityId;
        this.facilityName = res.dataPatient[0].facilityName;

        this.dataDefaultQueueInfo = res.dataQueueInfo[0];

        if (res.dataCPTDetails.length > 0) {
          this.primaryCPT = res.dataCPTDetails[0].cptCode;
        }

        for (let d of res.dataCPTDetails) {
          if (this.patientCPTs.indexOf(d.cptCode) == -1) {
            this.patientCPTs.push(d.cptCode);
          }
        }

        if (res.dataDosDetails[0].billed == "Yes") {
          this.billedInfo = false;
        }
        else if (res.dataDosDetails[0].billed == "No") {
          this.billedInfo = true;
        }
        this.fetch$data();

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  getFile(fileId, fileName) {
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  setTab(name, val, tabname) {

    if (this.selectedtabVal.indexOf(name) == -1) {

      this.dataQueueInfo[val] = !this.dataQueueInfo[val];
      this.tabVal["CONT0"] = false;
      this.tabVal["CONT1"] = false;
      this.tabVal["CONT2"] = false;
      this.tabVal["CONT3"] = false;

      this.fileName = "";
      this.fileByte = "";
      this.PatientsForm.reset();
      this.totalCost = 0;



      if (this.dataQueueInfo[val] == true) {
        this.tabHead = name;
        this.tabVal[tabname] = true;
        if (tabname == "CONT2" || tabname == "CONT3") {
          this.fetchDollarData(name, val, tabname);
        }
      }
      else {
        this.tabHead = "";
        this.tabVal["CONT0"] = true;
      }
    }
  }


  setRequest() {
    var txtNote = this.PatientsForm.controls["txtNote"].value;
    let v = "";

    let flextn = "";
    if (this.fileName != null && this.fileName.name != undefined) {
      v = this.fileName.name;
      flextn = v.split('.')[1];
    }

    var req = {};
    if (this.tabHead == "In Coding" && (this.fname != undefined || this.fname != null)) {
      req = { "_requestType": this.tabHead, "_requestNote": txtNote, "_requestFileName": this.fname + '.' + flextn, "_requestFile": this.fileName };
    }
    else {
      req = { "_requestType": this.tabHead, "_requestNote": txtNote, "_requestFileName": v, "_requestFile": this.fileName };
    }
    this.requestArry.push(req);

    let dat = this.dataQueue.find(x => x.key == this.tabHead);
    this.dataQueueDtl[dat.value] = true;

    if (this.tabHead == "Client High$$$" || this.tabHead == "NSN High$$$") {

      for (let d = 0; d < this.dollarList.length; d++) {
        if (this.dollarList[d].cpt != "" && this.dollarList[d].cpt != null) {
          var cptDtls = { "CPT": this.dollarList[d].cpt, "CPTDesc": this.dollarList[d].cptDesc, "Cost": this.dollarList[d].cost };
          this.cptArry.push(cptDtls);
        }
      }
    }

    if (this.tabHead == "Coding Variance Review") {
      for (let d = 0; d < this.varianceList.length; d++) {
        if (this.varianceList[d].cpt != "" && this.varianceList[d].cpt != null) {
          var cptVarDtls = { "CPT": this.varianceList[d].cpt, "CPTDesc": this.varianceList[d].cptDesc, "CPTAlt": this.varianceList[d].cptAlt, "CPTAltDesc": this.varianceList[d].cptAltDesc };
          this.cptVarArry.push(cptVarDtls);
        }
      }
    }

    this.selectedtabVal.push(this.tabHead);
    this.fileName = null;
    this.fname = null;
    this.fileByte = "";
    this.PatientsForm.controls["txtNote"].setValue("");
    this.PatientsForm.controls["reqFile"].setValue("");
    this.tabVal["CONT0"] = true;
    this.tabVal["CONT1"] = false;
    this.tabVal["CONT2"] = false;
    this.tabVal["CONT3"] = false;

  }

  fileChanged(event) {
    if (this.tabHead == 'In Coding' && this.fileSuffix != "") {
      let data = { "BDOSID": this._formData.bdosid, "applyFileSuffixForInCoding": this.fileSuffix };

      this.getApiService.post("BillingNew/GetStandardFileName", data).subscribe(res => {
        if (res.status == "Success") {
          this.fname = res.standardFileName;
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
    }

    this.fileName = event.target.files[0];

  }

  fileAttachChanged(event) {
    this.fileAttachName = event.target.files[0];
  }



  onSelectItem(event, id) {
    console.log(event);
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    frmCntrl.controls["CPTCode"].setValue(event.item.cpt);
    frmCntrl.controls["CPTDesc"].setValue(event.item.cptDesc);
    frmCntrl.controls["Rate"].setValue(event.item.rate);

    this.totalCost += Number(event.item.rate);
  }

  onVarianceSelectItem(event, id, type) {
    const frmCntrl = <FormArray>this.VarianceListForm.controls[id];
    if (type == 'nsn') {
      frmCntrl.controls["nsn_Desc"].setValue(event.item.cptDesc);
    }
    else if (type == 'physician') {
      frmCntrl.controls["physician_Desc"].setValue(event.item.cptDesc);
    }


  }

  chng(id) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    let vl = frmCntrl.controls["cpt"].value;

    try {
      if (vl.cpt == undefined) {

        this.searching = false;
        frmCntrl.controls["cpt"].setValue("");
        frmCntrl.controls["CPTCode"].setValue("");
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: true
        });
      }
      else {
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: false
        });
        frmCntrl.controls["CPTCode"].setValue(vl.cpt);
      }
    }
    catch {
      console.log("catch");
      frmCntrl.controls["cpt"].setValue("");
      frmCntrl.controls["CPTCode"].setValue("");
      frmCntrl.controls["CPTCode"].setErrors({
        notFound: true
      });
    }
  }

  addCptCodeList() {
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    control.push(this.getCptCodeList());
  }

  removeCptCodeList(id: number) {
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    const frmCntrl = <FormArray>control.controls[id];
    var rt = frmCntrl.controls["Rate"].value;
    this.totalCost = this.totalCost - Number(rt);
    control.removeAt(id);
  }

  addVarianceList() {
    const control = <FormArray>this.PatientsForm.controls['VarianceList'];
    control.push(this.getVarianceList());
  }

  removeVarianceList(id: number) {
    const control = <FormArray>this.PatientsForm.controls['VarianceList'];
    control.removeAt(id);
  }

  onSubmit() {
    if (this.requestArry.length > 0) {
      this.spinner.show();
      let frmData = new FormData();
      frmData.append("BdosID", this._formData.bdosid);
      frmData.append("facilityId", this.facilityId.toString());

      if (this.dataQueueDtl.hasOwnProperty("operatingReport")) {
        frmData.append("Op", this.dataQueueDtl['operatingReport']);
      }
      else {
        if (this.dataDefaultQueueInfo['operatingReport'] == false) {
          frmData.append("Op", "true");
        }
        else {
          frmData.append("Op", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("pathology")) {
        frmData.append("Pathology", this.dataQueueDtl['pathology']);
      }
      else {
        if (this.dataDefaultQueueInfo['pathology'] == false) {
          frmData.append("Pathology", "true");
        }
        else {
          frmData.append("Pathology", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("pricing")) {
        frmData.append("Pricing", this.dataQueueDtl['pricing']);
      }
      else {
        if (this.dataDefaultQueueInfo['pricing'] == false) {
          frmData.append("Pricing", "true");
        }
        else {
          frmData.append("Pricing", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("blockReport")) {
        frmData.append("BlockReport", this.dataQueueDtl['blockReport']);
      }
      else {
        if (this.dataDefaultQueueInfo['blockReport'] == false) {
          frmData.append("BlockReport", "true");
        }
        else {
          frmData.append("BlockReport", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("codingQuery")) {
        frmData.append("codingquery", this.dataQueueDtl['codingQuery']);
      }
      else {
        if (this.dataDefaultQueueInfo['codingQuery'] == false) {
          frmData.append("codingquery", "true");
        }
        else {
          frmData.append("codingquery", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("otherMisc")) {
        frmData.append("otherMisc", this.dataQueueDtl['otherMisc']);
      }
      else {
        if (this.dataDefaultQueueInfo['otherMisc'] == false) {
          frmData.append("otherMisc", "true");
        }
        else {
          frmData.append("otherMisc", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("inCoding")) {
        frmData.append("InCoding", this.dataQueueDtl['inCoding']);
      }
      else {
        if (this.dataDefaultQueueInfo['inCoding'] == false) {
          frmData.append("InCoding", "true");
        }
        else {
          frmData.append("InCoding", "false");
        }

      }
      if (this.dataQueueDtl.hasOwnProperty("authReview")) {
        frmData.append("AuthReview", this.dataQueueDtl['authReview']);
      }
      else {
        if (this.dataDefaultQueueInfo['authReview'] == false) {
          frmData.append("AuthReview", "true");
        }
        else {
          frmData.append("authReview", "false");
        }


      }
      if (this.dataQueueDtl.hasOwnProperty("codingExpert")) {
        frmData.append("CodingExpert", this.dataQueueDtl['codingExpert']);
      }
      else {
        if (this.dataDefaultQueueInfo['codingExpert'] == false) {
          frmData.append("CodingExpert", "true");
        }
        else {
          frmData.append("CodingExpert", "false");
        }

      }

      frmData.append("txtNotes", "");

      if (this.dataQueueDtl.hasOwnProperty("highApproval")) {
        frmData.append("clientHigh", this.dataQueueDtl['highApproval']);
      }
      else {
        if (this.dataDefaultQueueInfo['highApproval'] == false) {
          frmData.append("clientHigh", "true");
        }
        else {
          frmData.append("clientHigh", "false");
        }

      }

      if (this.dataQueueDtl.hasOwnProperty("highApprovalInternal")) {
        frmData.append("NSNHigh", this.dataQueueDtl['highApprovalInternal']);
      }
      else {
        if (this.dataDefaultQueueInfo['highApprovalInternal'] == false) {
          frmData.append("NSNHigh", "true");
        }
        else {
          frmData.append("NSNHigh", "false");
        }
      }

      if (this.dataQueueDtl.hasOwnProperty("variance")) {
        frmData.append("Variance", this.dataQueueDtl['variance']);
      }
      else {
        if (this.dataDefaultQueueInfo['variance'] == false) {
          frmData.append("Variance", "true");
        }
        else {
          frmData.append("Variance", "false");
        }
      }

      if (this.dataQueueDtl.hasOwnProperty("codingReturned")) {
        frmData.append("codingReturned", this.dataQueueDtl['codingReturned']);
      }
      else {
        if (this.dataDefaultQueueInfo['returnedCodingRFI'] == false) {
          frmData.append("codingReturned", "true");
        }
        else {
          frmData.append("codingReturned", "false");
        }
      }

      for (let d = 0; d < this.cptArry.length; d++) {
        frmData.append("DollarRequests[" + d + "].CPT", this.cptArry[d].CPT);
        frmData.append("DollarRequests[" + d + "].CPTDesc", (this.cptArry[d].CPTDesc || ""));
        frmData.append("DollarRequests[" + d + "].Cost", this.cptArry[d].Cost);
      }

      for (let f = 0; f < this.cptVarArry.length; f++) {
        frmData.append("RequestedVariance[" + f + "].CPT", this.cptVarArry[f].CPT);
        frmData.append("RequestedVariance[" + f + "].CPTDesc", (this.cptVarArry[f].CPTDesc || ""));
        frmData.append("RequestedVariance[" + f + "].CPTAlt", this.cptVarArry[f].CPTAlt);
        frmData.append("RequestedVariance[" + f + "].CPTAltDesc", (this.cptVarArry[f].CPTAltDesc || ""));
      }

      for (let e = 0; e < this.requestArry.length; e++) {
        frmData.append("RFI[" + e + "]._requestType", this.requestArry[e]._requestType);
        frmData.append("RFI[" + e + "]._requestNote", (this.requestArry[e]._requestNote || ""));
        frmData.append("RFI[" + e + "]._requestFileName", this.requestArry[e]._requestFileName);
        frmData.append("RFI[" + e + "]._rfiFile", this.requestArry[e]._requestFile);
      }

      if (this.dataQueueInfo['highApproval'] == true || this.dataQueueInfo['highApprovalInternal'] == true) {
        frmData.append("RequestDollarChanges", "true");
      }
      else {
        frmData.append("RequestDollarChanges", "false");
      }

      if (this.dataQueueInfo['variance'] == true) {
        frmData.append("RequestVarianceChanges", "true");
      }
      else {
        frmData.append("RequestVarianceChanges", "false");
      }

      frmData.append("IsHighApprovalInternal", this.dataDefaultQueueInfo['highApprovalInternal']);
      frmData.append("facilityName", this.facilityName);
      frmData.append("MRN", this.PatientSummaryDtls.dataPatient[0].mrn);
      frmData.append("RequestDollarStatus", "");
      frmData.append("RequestDollarComments", "");
      frmData.append("RequestVarianceStatus", "");
      frmData.append("RequestVarianceComments", "");

      this.getApiService.uploadFiles("BillingNew/Save", frmData).subscribe(res => {
        this.spinner.hide();
        if (res.status == "Success") {
          this.toastr.success("Saved Successfully!!", "Success");
          this.PatientsForm.reset();
          this.onClickClose();
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      });

    }
    else {
      return;
    }
  }

  // deleteAll() {
  //   if (this.CPTCodeListForm.length > 1) {
  //     var ttlrw = this.CPTCodeListForm.length;
  //     for (let d = 0; d < ttlrw; d++) {
  //       this.CPTCodeListForm.removeAt(0);
  //     }
  //     // this.addCptCodeList();
  //   }
  // }

  delChanges(tabHead) {
    let dat = this.dataQueue.find(x => x.key == this.tabHead);
    this.dataQueueDtl[dat.value] = false;
    this.dataQueueInfo[dat.value] = false;

    let e = this.selectedtabVal.findIndex(x => x == tabHead);
    this.selectedtabVal.splice(e, 1);

    let d = this.requestArry.findIndex(x => x._requestType == tabHead);
    this.requestArry.splice(d, 1);
  }

  uploadAttachment() {

    if (this.fileAttachName != null && this.fileAttachName.name != undefined) {
      let frmData = new FormData();
      frmData.append("BdosID", this._formData.bdosid);
      frmData.append("Op", this.dataQueueInfo['operatingReport']);
      frmData.append("Pathology", this.dataQueueInfo['pathology']);
      frmData.append("Pricing", this.dataQueueInfo['pricing']);
      frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
      frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
      frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
      frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
      frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
      frmData.append("InCoding", this.dataQueueInfo['inCoding']);
      frmData.append("Variance", this.dataQueueInfo['variance']);
      frmData.append("AuthReview", this.dataQueueInfo['authReview']);
      frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);

      frmData.append("txtNotes", (this.uploadFileText || ""))
      frmData.append("file", this.fileAttachName, this.fileAttachName.name);
      frmData.append("Filetype", "Attached File");

      console.log(frmData,"form data")

      this.getApiService.uploadFiles("BillingNew/Upload", frmData).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success("Uploaded Successfully!!", "Success");
          this.getPatientHeaderData();
          this.fileAttachName = "";
          this.uploadFile = null;
          this.uploadFileText = "";
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });

    }
  }

  addNotes() {
    let queueInfo = this.dataQueueInfo;
    queueInfo["BdosID"] = this._formData.bdosid;
    queueInfo["Notestypes"] = this.noteType;
    queueInfo["NotesCat"] = this.noteSelect;
    queueInfo["quickNotes"] = this.note;

    this.getApiService.post("BillingNew/AddNote", queueInfo).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Saved Successfully!!", "Success");
        this.getPatientHeaderData();
        this.noteType = "Private";
        this.note = "";
        this.noteSelect = "General Note";
      }
      else {
        this.toastr.error(res.message, 'Error!');
        this.noteType = "Private";
        this.note = "";
        this.noteSelect = "General Note";
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
      this.noteType = "Private";
      this.note = "";
      this.noteSelect = "General Note";
    });
  }

  reqCancel() {
    this.tabVal["CONT0"] = true;
    this.tabVal["CONT1"] = false;
    this.tabVal["CONT2"] = false;
    this.tabVal["CONT3"] = false;

    let dat = this.dataQueue.find(x => x.key == this.tabHead);
    this.PatientsForm.reset();
    if (this.dataQueueDtl[dat.value] != true) {
      this.dataQueueInfo[dat.value] = false;
    }
  }

  setStatus(stat) {
    let frmData = new FormData();
    frmData.append("BdosID", this._formData.bdosid);
    frmData.append("facilityId", this.facilityId.toString());
    frmData.append("Op", this.dataQueueInfo['operatingReport']);
    frmData.append("Pathology", this.dataQueueInfo['pathology']);
    frmData.append("Pricing", this.dataQueueInfo['pricing']);


    frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
    frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
    frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
    frmData.append("InCoding", this.dataQueueInfo['inCoding']);

    frmData.append("AuthReview", this.dataQueueInfo['authReview']);
    frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);
    frmData.append("txtNotes", "");

    for (let d = 0; d < this.cptArry.length; d++) {
      frmData.append("DollarRequests[" + d + "].CPT", this.cptArry[d].CPT);
      frmData.append("DollarRequests[" + d + "].CPTDesc", this.cptArry[d].CPTDesc);
      frmData.append("DollarRequests[" + d + "].Cost", this.cptArry[d].Cost);
    }

    if (this.requestArry.length > 0) {
      for (let e = 0; e < this.requestArry.length; e++) {
        frmData.append("RFI[" + e + "]._requestType", this.requestArry[e]._requestType);
        frmData.append("RFI[" + e + "]._requestNote", this.requestArry[e]._requestNote);
        frmData.append("RFI[" + e + "]._requestFileName", this.requestArry[e]._requestFileName);
        frmData.append("RFI[" + e + "]._rfiFile", this.requestArry[e]._requestFile);
      }
    }
    else {
      frmData.append("RFI[0]._requestType", this.tabHead);
      frmData.append("RFI[0]._requestNote", "");
      frmData.append("RFI[0]._requestFileName", "");
      frmData.append("RFI[0]._rfiFile", "");
    }


    if (this.tabHead == "Client High$$$" || this.tabHead == 'NSN High$$$') {
      frmData.append("RequestDollarChanges", "false");
      if (this.tabHead == "Client High$$$") {
        frmData.append("clientHigh", "false");
      }
      else {
        frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
      }
      if (this.tabHead == 'NSN High$$$') {
        frmData.append("NSNHigh", "false");
      }
      else {
        frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
      }
    }

    if (this.tabHead == "Coding Variance Review") {
      frmData.append("RequestVarianceChanges", "false");
      frmData.append("Variance", "false");
    }
    else {
      frmData.append("Variance", this.dataQueueInfo['variance']);
    }

    if (this.dataQueueInfo['highApproval'] == true || this.dataQueueInfo['highApprovalInternal'] == true) {
      frmData.append("RequestDollarStatus", stat);
      frmData.append("RequestDollarComments", this.txtApproval);
      frmData.append("RequestVarianceStatus", "");
      frmData.append("RequestVarianceComments", "");
    }
    else if (this.dataQueueInfo['variance'] == true) {
      frmData.append("RequestDollarStatus", "");
      frmData.append("RequestDollarComments", "");
      frmData.append("RequestVarianceStatus", stat);
      frmData.append("RequestVarianceComments", this.txtApproval);
    }
    else {
      frmData.append("RequestDollarStatus", "");
      frmData.append("RequestDollarComments", "");
      frmData.append("RequestVarianceStatus", "");
      frmData.append("RequestVarianceComments", "");
    }


    frmData.append("IsHighApprovalInternal", this.dataQueueInfo['highApprovalInternal']);
    frmData.append("facilityName", this.facilityName);
    frmData.append("MRN", this.PatientSummaryDtls.dataPatient[0].mrn);


    this.getApiService.uploadFiles("BillingNew/Save", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Saved Successfully!!", "Success");
        this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  fetchDollarData(name, val, tabname) {

    this.tabVal["CONT0"] = false;
    this.tabVal["CONT1"] = false;
    this.tabVal["CONT2"] = false;
    this.tabVal["CONT3"] = false;

    this.fileName = "";
    this.fileByte = "";
    this.PatientsForm.reset();
    this.totalCost = 0;
    this.fetch$data();
    this.tabHead = name;
    this.tabVal[tabname] = true;

  }

  fetch$data() {
    let data = { "BdosID": this._formData.bdosid };

    this.getApiService.uploadFiles("BillingNew/GetHighDollarTabData", data).subscribe(res => {
      if (res.status == "Success") {
        this.dollarList = res.dataHighTab;

        for (let t of this.dollarList) {
          this.totalCost += t.cost;
        }
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

    this.getApiService.uploadFiles("BillingNew/GetVarianceTabData", data).subscribe(res => {
      if (res.status == "Success") {
        this.varianceList = res.dataVarianceTab;
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

}
