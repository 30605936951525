import { Component, OnInit,EventEmitter,Output,Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-category-form',
  templateUrl: './category-form.component.html',
  styleUrls: ['./category-form.component.css']
})
export class CategoryFormComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  formFlag: string = "Add";
  _formData: any;
  category: string;
  categories: any[] = [];
  submitted = false;
  CategoryMasterForm: FormGroup;
  @Input() set formData(value: any) {
    
    if (value != null && value != undefined) {
      this._formData = value;
      this.formFlag = "Edit";
    // this.getCategories();
     
     this.editForm();
    }
    else {
      this.formFlag = "Add";
      this.initForm();
    }
  }
  
  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getCategories();
   //this.initForm();
    
  }
  initForm() {
    this.CategoryMasterForm = this.fb.group({
      categoryId: ["",],
      Name: ["", [Validators.required]],
      
    })
  }
  editForm() {
   // this.CategoryMasterForm.controls['Name'].setValue(this._formData.categoryName);
    this.CategoryMasterForm = this.fb.group({
      categoryId: [this._formData.id, [Validators.required]],
      Name: [this._formData.categoryName, [Validators.required]],
     
    });
   
    // this.CategoryMasterForm.patchValue({
    //   Name: this._formData.categoryName
    // })
   // console.log(this.CategoryMasterForm,"catmaster");
  }
  getCategories() {
    let data = { "pageNum": 1, "pageSize": 10};

    this.getApiService.post("FileCategory/BindGridData", data).subscribe((resp: any) => {
     // console.log(resp,'response');
      if (resp.status == "Success") {
        this.categories = resp.data;
        console.log(this.categories);
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  get f() { return this.CategoryMasterForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.addRecord();
    // if (this.formFlag == "Add") {
    //   this.addRecord();
    // }
    // else if (this.formFlag == "Edit") {
    //   this.editRecord();
    // }

  }
  editRecord() {
    this.submitted = true;
    //const frmdata = this.ARForm.getRawValue();

    // let formData = new FormData();
    // formData.append("id", frmdata.id);
    // formData.append("title", frmdata.title);
    // formData.append("description", frmdata.description);
    // formData.append("categoryId", frmdata.categoryId);

    // if (this.filetoUpload != "" && this.filetoUpload != undefined) {
    //   formData.append("file", this.filetoUpload, this.filetoUpload.name);
    // }


    // this.getApiService.uploadFiles("pl/Savepl", formData).subscribe((resp: any) => {
    //   if (resp.status == "Success") {
    //     this.ARForm.reset();
    //   }
    //   this.editEmitter.emit({
    //     'status': true,
    //     'data': resp
    //   })

    // }, (err) => {
    //   console.log(err);
    // })
  }
  addRecord() {
    const formdata = this.CategoryMasterForm.getRawValue();
    let frmData = new FormData();
    var data:any;
    if (this.formFlag == "Edit") {
     // frmData.append("Id", this.CategoryMasterForm.controls["Id"].value);
       data = {"id" : this.CategoryMasterForm.controls["categoryId"].value,"CategoryName" : this.CategoryMasterForm.controls["Name"].value}
    }else{
      data = {"CategoryName" : this.CategoryMasterForm.controls["Name"].value}
    }
    
    this.getApiService.post("FileCategory/SaveCategory", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
       
        this.CategoryMasterForm.reset();
        this.closeEmitter.emit();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }
  onClickClose() {
    this.closeEmitter.emit();
  }

}
