import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ArRoutingModule } from './ar-routing.module';
import { ArDashboardComponent } from './dashboard/ar-dashboard.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientFormComponent } from './patient-form/patient-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { fromEventPattern } from 'rxjs';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
  declarations: [ArDashboardComponent, PatientListComponent, PatientFormComponent],
  imports: [
    CommonModule,
    ArRoutingModule,
    NgxPaginationModule,
    FormsModule,
    BsDatepickerModule,
    DatepickerModule,
    ReactiveFormsModule 
  ]
})
export class ArModule { }
