export enum CommandType{
    CaseBilled = "casebilled",
    ReturnedMail = "returnmail"
}

export interface CommandTypeConfig {
    commandName: string;
    commandDisplayName: string;
    apiName: string;
    apiBodyId: string;
    apiBodySearchId: string;
    bodyHasAssigned: boolean;
    view: string;
    childCatId: string | number; // cat id that is used to get subchild of command name
    parentCommandId: string; // set to parent command Name and NA if not
    hasChildren: boolean; // to define if a coommand has sub child eg: billerOnHold it's true for codingExpert it's false
    childAccordianOrder?: Map<string, number>;
}

export function createCommandTypeConfig(params: Partial<CommandTypeConfig>) {
    return {
      ...params
    } as CommandTypeConfig;
  }