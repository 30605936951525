import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FacilityMasterComponent } from './facility-master/facility-master.component';
import { FacilityMasterFormComponent } from './facility-master/facility-master-form/facility-master-form.component'
import { FacilityGroupComponent } from '../masters/facility-group/facility-group.component'
import { CategoryComponent } from '../masters/category/category.component'
import { CustomFieldComponent } from '../masters/custom-field/custom-field.component'
import { FacilityPricingComponent } from '../masters/facility-master/facility-pricing/facility-pricing.component'
import { PaperClaimAttachmentComponent } from '../masters/paper-claim-attachment/paper-claim-attachment.component'
import { ImportpayersComponent } from '../masters/importpayers/importpayers.component'
import { CustomQueueComponent } from './custom-queue/component/custom-queue.component';
import { BillReasonComponent } from './undo-setbill-reason/component/bill-reason.component';
import { ImportPhysicianComponent } from './importphysicians/importphysicians.component';
import { CodingStatusComponent } from './coding-status/coding-status.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/facility-masters',
        pathMatch: 'full'
      },
      {
        path: 'facility-masters',
        component: FacilityMasterComponent,
      },
      {
        path: 'import-payers',
        component: ImportpayersComponent,
      },
      {
        path: 'import-physicians',
        component: ImportPhysicianComponent,
      },
      {
        path: 'facility-master/facility-pricing/:id',
        component: FacilityPricingComponent,
      },
      {
        path: 'group',
        component: FacilityGroupComponent,
      },
      {
        path: 'queue',
        component: CustomQueueComponent,
      },
      {
        path: 'undo-setbill-reason',
        component: BillReasonComponent
      },
      {
        path: 'category',
        component: CategoryComponent,
      },
      {
        path: 'paper-claim',
        component: PaperClaimAttachmentComponent,
      },
      {
        path: 'coding-status',
        component: CodingStatusComponent,
      },
      {
        path: 'custom-field',
        component: CustomFieldComponent,
      },
      {
        path: 'add-facility',
        component: FacilityMasterFormComponent,
      },
      
      {
        path: 'add-facility/:id',
        component: FacilityMasterFormComponent,
      },
    ]
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MastersRoutingModule { }
