<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="facilityName"
                                        (change)="onFacilityChange()">
                                        <!-- <option selected value="0">All Facilities</option> -->
                                        <option *ngFor="let c of FacilityList" [value]="c.name">{{c.name}}</option>
                                    </select>
                                </div>
                                <div class="col-md-6">
                                    <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="reqSelect"
                                        (change)="getData()">
                                        <option selected value="OpNote Exceptions">OpNote Exceptions</option>
                                        <option value="OpNote Exception Updates">OpNote Exception Updates</option>
                                        <option value="Coding Exceptions">Coding Exceptions</option>
                                        <option value="Coding Exception Updates">Coding Exception Updates</option>

                                    </select>
                                    <!-- <p class="lblinputspan">
                                <input type="radio" id="OpNote" name="note" [(ngModel)]="reqSelect" (change)="getData()"
                                    value="OpNote Exceptions">
                                <label for="OpNote">OpNote Exceptions</label>
                                <input type="radio" id="reject" name="note" [(ngModel)]="reqSelect" (change)="getData()"
                                    value="OpNote Exception Updates">
                                <label for="OpNoteExp">OpNote Exception Updates</label>
                            </p>
                            <p class="lblinputspan">
                                <input type="radio" id="codingExp" name="note" [(ngModel)]="reqSelect"
                                    (change)="getData()" value="Coding Exceptions">
                                <label for="codingExp">Coding Exceptions</label>
                                <input type="radio" id="codingExpUpdt" name="note" [(ngModel)]="reqSelect"
                                    (change)="getData()" value="Coding Exception Updates">
                                <label for="codingExpUpdt">Coding Exception Updates</label>
                            </p> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-3">
                                    <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                        [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="fromDate">
                                </div>
                                <div class="col-md-3">
                                    <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                        [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="toDate">
                                </div>
                                <div class="col-md-3">
                                    <button (click)="getData()" class="btn btn-primary">Refresh</button>
                                </div>
                                <div class="col-md-3">
                                    <a *ngIf="role == 'Admin'" [routerLink]="['/d/auditlog', facilityID]"
                                        class="btn btn-primary exception-button" type="button">Audit
                                        Log</a>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- <div class="row rowwrp" style="width: 70%;">
                        <div class="col-md-6">
                            <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="facilityName"
                                (change)="onFacilityChange()">                              
                                <option *ngFor="let c of FacilityList" [value]="c.name">{{c.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-6">
                            <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="reqSelect"
                                (change)="getData()">
                                <option selected value="OpNote Exceptions">OpNote Exceptions</option>
                                <option value="OpNote Exception Updates">OpNote Exception Updates</option>
                                <option value="Coding Exceptions">Coding Exceptions</option>
                                <option value="Coding Exception Updates">Coding Exception Updates</option>
                                
                            </select> -->
                    <!-- <p class="lblinputspan">
                                <input type="radio" id="OpNote" name="note" [(ngModel)]="reqSelect" (change)="getData()"
                                    value="OpNote Exceptions">
                                <label for="OpNote">OpNote Exceptions</label>
                                <input type="radio" id="reject" name="note" [(ngModel)]="reqSelect" (change)="getData()"
                                    value="OpNote Exception Updates">
                                <label for="OpNoteExp">OpNote Exception Updates</label>
                            </p>
                            <p class="lblinputspan">
                                <input type="radio" id="codingExp" name="note" [(ngModel)]="reqSelect"
                                    (change)="getData()" value="Coding Exceptions">
                                <label for="codingExp">Coding Exceptions</label>
                                <input type="radio" id="codingExpUpdt" name="note" [(ngModel)]="reqSelect"
                                    (change)="getData()" value="Coding Exception Updates">
                                <label for="codingExpUpdt">Coding Exception Updates</label>
                            </p> -->
                    <!-- </div>
                    </div>
                    <div class="row rowwrp">

                        <div class="col-md-4">
                            <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="fromDate">
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="toDate">
                        </div>
                        <div class="col-md-4">
                            <button (click)="getData()" class="btn btn-primary">Refresh</button>
                        </div>
                    </div>
                    <div class="row rowwrp">

                        <div class="col-md-12">
                            <a *ngIf="role == 'Admin'" [routerLink]="['/d/auditlog', facilityID]"
                                class="btn btn-primary exception-button" type="button">Audit
                                Log</a>
                        </div>
                    </div> -->
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Received</th>
                                    <th>File Name</th>
                                    <th>File Path</th>
                                    <th>Patient Name</th>
                                    <th>DoS</th>
                                    <th>MRN</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Received</th>
                                    <th>File Name</th>
                                    <th>File Path</th>
                                    <th>Patient Name</th>
                                    <th>DoS</th>
                                    <th>MRN</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor="let d of exceptionList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{d.fileScanned | date: "MM/dd/yyyy"}}<br>
                                        {{d.fileScanned | date: "HH:mm a"}}</td>
                                    <td style="width: 18%;"><a href="javascript:void(0)"
                                            (click)="getFile(d.fileName)">{{d.fileName}}</a></td>
                                    <td style="width: 18%;"><a href="javascript:void(0)"
                                            (click)="getFile(d.fileName)">{{d.filePath}}</a></td>
                                    <td>{{d.patientName}}</td>
                                    <td>{{d.dateOfService | date: "MM/dd/yyyy"}}</td>
                                    <td>{{d.mrn}}</td>
                                    <td>
                                        <a class="icon-color-green mr-2" href="javascript:void(0)"
                                            style="font-size: 15px;" (click)='showFormModal(d)'>
                                            <i class="fas fa-check"></i>
                                        </a>
                                        <a class="icon-color-red mr-1" href="javascript:void(0)" (click)="delData = d"
                                            style="font-size: 15px;" data-toggle="modal" data-target="#removeModal">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>
                                        <!-- <button class="btn btn-secondary mr-1"
                                            (click)='showFormModal(d)'>Select</button>
                                        <a class="btn btn-danger mr-1" href="javascript:void(0)" (click)="delData = d"
                                            data-toggle="modal" data-target="#removeModal">
                                            Remove
                                        </a> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-exception-action [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()">
</app-exception-action>

<div class="modal fade" id="removeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Remove File?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                Remove file from exception queue?
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" (click)="removeFiles()" data-dismiss="modal">Yes</button>
                <button class="btn btn-secondary" type="button" (click)="delData = []" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<!-- /.container-fluid -->