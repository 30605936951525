<div class="row">
    <div class="col-md-8">
        <p class="high-section-textt cr-label"> {{(activeTab$ | async).value}} Total</p>
    </div>
    <div class="col-md-4" *ngIf="(activeTab$ | async).id !=='variance'">
        <p class="high-sect-amount">{{totalCost$ | async}}</p>
    </div>
</div>
<div class="row" *ngIf="(activeTab$ | async).id ==='variance'">
    <div class="col-md-6">
        <p class="high-section-textt cr-label"> HiTech</p>
    </div>
    <div class="col-md-6">
        <p class="high-section-textt cr-label"> Physician</p>
    </div>
</div>
<table class="table table-hover" *ngIf="(activeTab$ | async).id !=='variance'">
    <thead>
        <th>CPT</th>
        <th>CPT Description</th>
        <th>$$$</th>
    </thead>
    <tr *ngFor="let row of (caseRequestCPt$ | async)">
        <td>{{row.cptCode}}</td>
        <td>{{row.cptDescription}}</td>
        <td>{{row.rate | CustomCurrency}}</td>
    </tr>
</table>

<table class="table table-hover" *ngIf="(activeTab$ | async).id ==='variance'">
    <thead>
        <th>CPT</th>
        <th>Description</th>        
        <th>CPT</th>
        <th>Description</th>
    </thead>
    <tr *ngFor="let row of (varianceList$ | async)">
        <td>{{row.cptCode}}</td>
        <td>{{row.cptDescription}}</td> 
        <td>{{row.cptAltCode}}</td>
        <td>{{row.cptAltDescription}}</td>
    </tr>
</table>