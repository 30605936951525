export interface BulkActionRequestStatus {
  id: number | string;
  caseRequestId: string;
  status: string;
  bdosid: string;
}

export function createBulkActionRequestStatus(params: Partial<BulkActionRequestStatus>) {
  return {
    ...params
  } as BulkActionRequestStatus;
}
