import { AfterViewInit, Component, OnDestroy, OnInit, Output } from '@angular/core';
import { CustomQueueService } from '../state/custom-queue.service';
import { CustomQueueQuery } from '../state/custom-queue.query';
import { CustomQueue } from '../state/custom-queue.model';
import { ID } from '@datorama/akita';
import { Observable, Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { EventEmitter } from 'events';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { map } from 'rxjs/operators';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { PhysicianService } from 'src/app/shared/state/physician/physician.service';

@Component({
  selector: 'app-custom-queue',
  templateUrl: './custom-queue.component.html',
  styleUrls: ['./custom-queue.component.scss']
})
export class CustomQueueComponent implements OnInit, AfterViewInit, OnDestroy {
  customQueueList$: Observable<CustomQueue[]>;
  isLoading$: Observable<boolean>;
  headerName: string = 'Custom Queue';
  addBtnLbl: string = 'Add Custom Queue';
  columns: ColDef[];
  shouldShowFormModal$: Observable<string>;

  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  formData: any = null;
  pageValue: any = 10;
  totalRows$: Observable<number>;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  subscrption: Subscription = new Subscription();

  constructor(
    private commonUtil: CommonService,
    private customQueueQuery: CustomQueueQuery,
    private customQueueService: CustomQueueService,
    private groupService: GroupsService,
    private selectionService: SelectionService,
    private selectionQuery: SelectionQuery,
  ) { }

  ngOnInit() {
    this.commonUtil.setTitle(this.headerName);
    this.initializeColumns();
    this.shouldShowFormModal$ =this.selectionQuery.select().pipe(map((state => state.value)));
    this.isLoading$ = this.customQueueQuery.selectLoading();
    this.totalRows$ = this.customQueueQuery.selectAll().pipe(map(resp => resp.length));
    this.customQueueService.getByParams(1, 1000, "");
    this.customQueueList$ = this.customQueueQuery.selectAll();

  }

  ngAfterViewInit() {
    const groupSub = this.groupService.get().subscribe();
    this.subscrption.add(groupSub);
  }
  ngOnDestroy(){
    this.subscrption.unsubscribe();  
  }

  initializeColumns() {
    this.columns = [
      createColDef({ name: 'Queue Name', sortable: true, sortFieldName: 'queueName' }), 
      createColDef({ name: 'Description', sortable: true, sortFieldName: 'description' }),
      createColDef({ name: 'Status', sortable: false }), 
      createColDef({ name: 'Action', sortable: false }),
    ]
  }


  onEntryChange(event$) {

    let value = event$.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.p = 1;
    this.customQueueService.getByParams(this.pagenumber, this.pageValue, this.searchValue);

  }

  sortListQueue(value, order) {
    this.pagenumber = 1;
    this.pageValue = 10;
    this.customQueueService.getByParamsWithSorting(this.pagenumber, this.pageValue, this.searchValue, value, order);
  }
  pageChange(event$) {
    this.pagenumber = event$;
    this.p = 1;
    this.customQueueService.getByParams(this.pagenumber, this.pageValue, this.searchValue);
  }

  search(){
    this.pagenumber = 1;
    this.pageValue = 10;
    this.customQueueService.getByParams(this.pagenumber, this.pageValue, this.searchValue);
  }

  onClickAdd() {
    this.selectionService.changeSelection("Add");
  }

  onEditRecord(event$) {
    console.log('onEditRecord ',event$);
    this.customQueueService.setActive([event$.id]);
    this.selectionService.changeSelection("Edit");
    this.customQueueService.setQueueDetails(event$.id);
  }
  deleteQueue(delRow){
    this.customQueueService.delete(delRow);
  }
}