export interface UserProductivityReportDetail {
    facilityName: string;
    mrn: string;
    workType: string;
    importDate: string;
    setBilledDate: string;
    totalCharges: number;
    totalCount: number;
  }
  
  export function createUserProductivityReportDetail(params: Partial<UserProductivityReportDetail>) {
    return {
      ...params
    } as UserProductivityReportDetail;
  }
  