<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Content Row -->
    <div class="row counters">
        <div class="col-12 mb-4">

            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size  ml-4">{{headerName}} </h1>
                    <a href="javascript:void(0)" (click)='showFormModal()' id="closeFileSidenav"
                        class="btn btn-primary btn-patient-box openMySidenav  mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        {{addBtnLbl}}
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list appointments-list">
                        <div class="row">
                            <div class="col-sm-6 col-md-6">
                                <div class="myTables_length"><label>Show <select name="myTable_length"
                                            aria-controls="dataTable" (change)="onEntryChange($event)"
                                            class="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries</label></div>
                            </div>
                            
                            <div class="col-sm-6 col-md-6">
                                <div class="myTables_filter">
                                    <label>Search:<input type="search" [(ngModel)]="searchValue" name="searchValue"
                                    class="form-control form-control-sm" (keyup.enter)="search()"
                                    placeholder="Enter Keyword">
                                    </label>
                                </div>
                                <!-- (keyup)="sortTable($event)" -->
                            </div>
                        </div>
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">{{col.name}}
                                        <i *ngIf="col.sortable" class="fas fa-arrow-up mr-1"
                                            (click)="sortListBillReason('reason','asc')"></i>
                                        <i *ngIf="col.sortable" class="fas fa-arrow-down"
                                            (click)="sortListBillReason('reason','desc')"></i>
                                    </th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th *ngFor="let col of columns">{{col.name}}
                                        <i *ngIf="col.sortable" class="fas fa-arrow-up mr-1"
                                            (click)="sortListBillReason('reason','asc')"></i>
                                        <i *ngIf="col.sortable" class="fas fa-arrow-down"
                                            (click)="sortListBillReason('reason','desc')"></i>
                                    </th>
                                </tr>
                            </tfoot>
                            <tbody>
                                
                                <tr
                                    *ngFor='let data of billReasonList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>{{data.reason}}</td>
                                    <td>{{data.updatedDate | date:'MM/dd/yyyy HH:mm'}}</td>
                                    <td>
                                        <label class="custom-switch">
                                            <input type="checkbox" (click)="delBillReason(data)"
                                                [checked]="data.isActive === 1">
                                            <span class="slider"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" (click)="onEdit(data)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit" title="Edit"></i></a>

                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls *ngIf="billReasonList && billReasonList.length !== 0" class="float-right" (pageChange)="pageChange($event); p=$event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-bill-reason-add-form *ngIf="(shouldShowFormModal$ | async)">
</app-bill-reason-add-form>
<!-- Sidenav-->


<!--  -->