<div class="user-login fullheight fullheight-pad">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div>
                    <img src="assets/img/hitechhealth-logo.png" alt="" class="img-fluid py-3" />
                </div>
                <div class="">
                    <h1 class="mb-4">Forgot Your Password?</h1>
                </div>
                <form class="" [formGroup]="forgetForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="input-group">

                            <input type="text" placeholder="Email Address" formControlName="email"
                                class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                            <!-- Email validation -->
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">*Email is required</div>
                                <div *ngIf="f.email.errors.email">*Email must be a valid email address</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-user btn-block login-btn-user">Submit</button>
                </form>
                <div class="">
                    <p><a routerLink="/login"><i class="fa fa-arrow-left back-arrow login-btn-user"></i>Back to
                            Login</a></p>
                </div>

                <div class="">
                    <p><a href="#" class="text-underline">Terms & Conditions</a> and <a href="#"
                            class="text-underline">Privacy Policy</a></p>
                </div>
            </div>
        </div>
    </div>
</div>