import { Injectable } from '@angular/core';
import { persistState, Store, StoreConfig } from '@datorama/akita';

export interface SessionState {
   token: string;
   username: string;
   email: string;
   role: string;
   fullName: string;
}

export function createInitialState(): SessionState {
  return {
    token: '',
    username: '',
    email: '',
    role: 'Annonymous',
    fullName: ''
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ 
  name: 'session', 
  cache: {
    ttl: 24 * 60 * 60000
  }
 })
export class SessionStore extends Store<SessionState> {
  constructor() {
    super(createInitialState());
  }
}

export const sessionPersistStorage = persistState({
  include: ['session'],
  key: 'session'
})