import { Injectable } from "@angular/core";
import { SelectionStore } from "./selection.store";

@Injectable({ providedIn: 'root'})
export class SelectionService {
 constructor(private store: SelectionStore){}

 changeSelection(state: string){
    this.store.update({ value: state });
 }
}