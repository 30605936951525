export interface CodingStatus {
  id: number | string;
  inquirystatus: string;
  status: string;
  priority: string;
  totalcount: number;
}

export function createCodingStatus(params: Partial<CodingStatus>) {
  return {
    ...params
  } as CodingStatus;
}
