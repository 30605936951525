export interface CaseRequestCpt {
  id: number | string; // id = requestId_cptCode but for bulk action it will be requestId_cptCode_bdosid and for variance it's requestId_cptCode_type and in bulk action requestId_cptCode_type_bdosid
  requestId: number | string;
  cptCode: string;
  cptDescription: string;
  rate: number;
  codingType: string;
  uiIndex?: number;
  notes?: string;
  approved: boolean;
  alreadySubmitted: boolean;
  bdosid: number | string;
}

// only used to disaplay in cr-cpt-view coponent only
export interface CaseRequestVarCpt{
cptCode: string;
cptDescription: string;
cptAltCode: string;
cptAltDescription: string;
}

export function createCaseRequestCpt(params: Partial<CaseRequestCpt>) {
  return {
    ...params
  } as CaseRequestCpt;
}
export function createCaseRequestVarCpt(params: Partial<CaseRequestVarCpt>) {
  return {
    ...params
  } as CaseRequestVarCpt;
}
