<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Edit Fee Schedule</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="pb-3 form-fields">
            
            <form action="" [formGroup]="feeScheduleForm">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="selectFacility" class="required">Facility</label>
                        <div class="input-group">
                            <select formControlName="FacilityId" class="custom-select form-control">
                                <option selected value="" disabled>Select Facility</option>
                                <option *ngFor="let data of facilityList" value="{{data.id}}">{{data.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.FacilityId.errors" class="invalid-feedback">
                                <p>{{f.FacilityId.errors}}</p>
                                <p>*mandatory</p>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">CPT</label>
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="CPT CODE" formControlName="CPT"
                                required>
                        </div>
                        <div *ngIf="submitted && f.CPT.errors" class="invalid-feedback">
                            <p>{{f.CPT.errors}}</p>
                            <p>*mandatory</p>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">Description</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="CPTDesc" placeholder="Description"
                                required>
                        </div>
                        <div *ngIf="submitted && f.CPTDesc.errors" class="invalid-feedback">
                            <p>{{f.CPTDesc.errors}}</p>
                            <p>*mandatory</p>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">Charges($)</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="Charge" placeholder="Charge"
                                required>
                        </div>
                        <div *ngIf="submitted && f.Charge.errors" class="invalid-feedback">
                            <p>{{f.Charge.errors}}</p>
                            <p>*mandatory</p>
                        </div>
                    </div>
                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">Valid From</label>
                        <div class="input-group">
                            <input type="text" class="form-control" [(ngModel)]="myDateValue"
                                [ngModelOptions]="{standalone: true}" (dateSelect)="onDateChange($event)" ngbDatepicker
                                #d="ngbDatepicker" format="MM-dd-yyyy" (click)="d.toggle()" />
                        </div>
                    </div>
                </div>
            </form>
            <p>Form value: {{ feeScheduleForm.get('FacilityId').errors }}</p>
            <p>Form value: {{ feeScheduleForm.errors }}</p>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="button" (click)="updateRecord()" class="btn btn-primary btn-patient-box">Submit</button>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->
</div>