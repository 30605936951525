<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Knowledge Base</h1>
                    <a *ngIf="privileges.kbAdd" href="javascript:void(0);" (click)='showFormModal()' id="closeFileSidenav"
                        class="btn btn-primary btn-patient-box closeFileSidenav mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add KB
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="getKBArryList()"
                                        placeholder="KB Title/Category"></label></div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list appointments-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>KB Title</th>
                                    <th>KB Description</th>
                                    <th>Category</th>
                                    <th>Link</th>
                                    <th *ngIf="privileges.kbAction">Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>KB Title</th>
                                    <th>KB Description</th>
                                    <th>Category</th>
                                    <th>Link</th>
                                    <th *ngIf="privileges.kbAction">Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of KBArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>
                                        <!-- {{i+1}} -->

                                        {{(i+1) + ((p-1)*pageValue)}} 
                                    </td>
                                    <td>{{data.title}}</td>
                                    <td>{{data.k_description}}</td>
                                    <td>{{data.category}}</td>
                                    <td><a href="javascript:void(0)" (click)="downloadfile(data.file_name)"
                                            class="curs-pointer"><i class="fa fa-upload pdf_space_icon" title="Download"
                                                aria-hidden="true"></i>{{data.file_name}}</a></td>
                                    <td *ngIf="privileges.kbAction">
                                        <a href="javascript:void(0)" (click)='editKB(data)'
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>
                                        <a href="javascript:void(0)" (click)='deleteKB(data)'
                                            class="btn btn-danger icon-btn"><i class="fa fa-trash-alt" title="Delete"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-kb-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)">
</app-kb-form>
<!-- Sidenav-->