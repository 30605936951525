<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Content Row -->
    <div class="row counters">
        <div class="col-12 mb-4">

            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size  ml-4">Import Patients </h1>
                    <a  href="javascript:void(0)" (click)='showFormModal()' id="closeFileSidenav"
                        class="btn btn-primary btn-patient-box openMySidenav  mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Import Patients
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list appointments-list">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="myTables_length"><label>Show <select name="myTable_length"
                                            aria-controls="dataTable" (change)="onEntryChange($event)"
                                            class="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries</label></div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                            [(ngModel)]="searchValue" class="form-control form-control-sm"
                                            (keyup.enter)="getImportPatientList()" placeholder="File Name"></label>
                                </div>
                                <!-- (keyup)="sortTable($event)" -->
                            </div>
                        </div>
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>File Name</th>
                                    <th>Centers</th>
                                    <th>Patients</th>
                                    <th>Service Dates</th>
                                    <th>Procedure</th>
                                    <th>Error Details</th>
                                    <th>Import Date</th>
                                    <th>Status</th>
                                    <th>Downloads</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>File Name</th>
                                    <th>Centers</th>
                                    <th>Patients</th>
                                    <th>Service Dates</th>
                                    <th>Procedure</th>
                                    <th>Error Details</th>
                                    <th>Import Date</th>
                                    <th>Status</th>
                                    <th>Downloads</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of importPatientList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>
                                        <!-- {{i+1}} -->
                                        {{(i+1) + ((p-1)*pageValue)}} 
                                    </td>
                                    <td>{{data.fname}}</td>
                                    <td>{{data.facilities}}</td>
                                    <td>{{data.patients}}</td>
                                    <td>{{data.serviceDates}}</td>
                                    <td>{{data.procedures}}</td>
                                    <td>{{data.errorMessage}}</td>
                                    <td>{{data.importDate | date:'MM/dd/yyyy HH:mm'}}</td>
                                    <td>{{data.status}}</td>
                                    <td><a href="javascript:void(0)" (click)="downloadfile(data.fileKey)"
                                            class="curs-pointer"><i class="fa fa-download download-btn-icon" title="Download"
                                                aria-hidden="true"></i></a></td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p=$event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-import-patients-form *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (addedEmitter)="onAddRecord($event)">
</app-import-patients-form>
<!-- Sidenav-->


<!--  -->