<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700 active">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h4 class="m-0 font-weight-bold text-primary">Assign Cases - {{ activeFacility.name }}</h4>
            <a href="javascript:void(0);"  class="closeMySidenav" id="closeMySidenav" (click)="onClickClose()">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
        <form class="pb-3 form-fields" [formGroup]="AssignCaseForm" *ngIf="AssignCaseForm">
            <div class="row">
                <div class="form-group col-6">
                    <label for="assignAll" >Assign all case to</label>
                </div>
                <div class="form-group col-6">
                    <div class="input-group">
                        <ngx-select-dropdown [config]="config" (change)="onUserChange($event)"
                            [options]="usernameList$ | async" style="width: 100%;"
                            autoClearSearch=" true" [multiple]="false">
                        </ngx-select-dropdown>
                    </div>
                </div>
            </div>
            <div class="table-responsive" style="min-height: 22rem;">
                    <table class="table" id="dataTable" width="auto" cellspacing="0">
                        <thead>
                            <tr style="color: #333;">
                                <th>MRN</th>
                                <th>Patient</th>
                                <th>DoS</th>
                                <th>Insurance</th>
                                <th>User</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tfoot>
                            <tr style="color: #333;">
                                <th>MRN</th>
                                <th>Patient</th>
                                <th>DoS</th>
                                <th>Insurance</th>
                                <th>User</th>
                                <th>Action</th>
                            </tr>
                        </tfoot>
                        <tbody> 
                        <tr *ngFor="let elem of activePatient" style="color: #333;
                        font-weight: 400;">
                            <td>{{elem.mrn}}</td>
                            <td>{{elem.name}}</td>
                            <td>{{elem.dateOfSurgery}}</td>
                            <td>{{elem.insuranceProvider}}</td>
                            <td style="width: 16rem;">
                                <ngx-select-dropdown [config]="config" [options]="usernameList$ | async"
                                style="width: 100%;" formControlName="{{elem.bdosid}}" autoClearSearch=" true"
                                [multiple]="false">
                                </ngx-select-dropdown>
                            </td>
                            <td><i (click)="removeActive(elem)"
                                class="fas fa-times"></i>
                            </td>
                        </tr>
                        </tbody>
                    </table>
            </div>
            <div class="text-right" style="margin: 75px 0px;">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="button" (click)="onSubmit()" [disabled] = "!hasAssignCaseMapping()" class="btn btn-primary btn-patient-box">Submit</button>
            </div>
        </form>    

        </div>
    </div>
</div>
<br><br>