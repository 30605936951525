import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  selectedIndex = 0;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Dashboard");
  }

  toggle(evt, index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = 0;
    }

  }

}
