
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
        <!-- <div class="container-fluid"> -->
            <div class="row">
                <div class="col-md-12 p-0  ">
                    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
                    <h6 class="m-0 font-weight-bold text-primary bill_6_new">{{claimTypeAction}}</h6>
                    <a  class="" id="" (click)="onClickClose()">
                    <i class="fas fa-times fa-sm"></i>
                    </a>
                </div>
                </div>
                <div class="container-fluid">
                    <form class="form-fields" [formGroup]="setBilledForm" (ngSubmit)="onSubmit()">
                    <div class="col-md-12  ml-auto mr-auto mt-4" *ngIf="billingRuleDetails">
                        <div class="bag_gre" style="padding-left : 15px; padding-bottom: 3px;">
                            <p class="col-md-5 mt-3" style="margin-left: -27px;">Required To Bill </p>
                        <p class="red-col" *ngFor="let rule of ruleText">
                            <input type="checkbox" formControlName="checkbill" (change)='checkbill($event)'>
                            <!-- Regence Blue Shield must go electronically. However. if you have an attechment it can be fixed to (888)208-8039. The clain unmber must be on the fax cover sheet.
                            </p> -->
                            {{rule.ruleText}}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 main_in">
                        
                            <div class="row">
                                <div class="col-md-5 mt-3">
                                        <h4 class="required">Claim Type</h4>
                                </div>
                                    <div class="col-md-7 d-flex mt-3">
                                        <select class="main_in" formControlName="ClaimType" (change)='selectClaimType($event)'>
                                            <!-- <option  value=''> --Select Claim Type-- </option> -->
                                            <option  value='self'> Self </option>
                                            <option  value='paper'> Paper </option>
                                            <option  value='electronic'> Electronic </option>
                                            
                                        </select>
                                        <!-- <button class="new_red" *ngIf='billingRuleDetails' [disabled]='!checked' (click)='overRideClaim()'>Override</button> -->
                                </div>
                                <div class="col-md-5 mt-3" *ngIf='showOverrideReason'>
                                        <h4 class="required"> Override Reason</h4>
                                </div>
                                    <div class="col-md-7 d-flex mt-3" *ngIf='showOverrideReason'>
                                        <input class="main_in" formControlName="OverrideReason" type="text"  placeholder='Override Reason'>
                                </div>
                                <div class="col-md-5 mt-3" *ngIf="showClaimConfirmation">
                                        <h4 class="required">Claim Confirmation</h4>
                                </div>
                                    <div class="col-md-7 d-flex mt-3" *ngIf="showClaimConfirmation ">
                                        <!-- <span>Claim#</span> -->
                                        <input class="main_in" type="text" formControlName="ClaimConfirmation" placeholder="Claim#" >
                                </div>
                                <div class="col-md-5 mt-3" *ngIf="showUpload ">
                                    <h4 class="required">Upload Claim </h4>
                                </div>
                                <div class="col-md-7 d-flex mt-3" *ngIf="showUpload ">
                                    <input  type="file" multiple formControlName="fileName" (change)="selectionChanged($event)">
                                    
                                </div>
                                <div class="col-md-5 mt-3" *ngIf="validateFile">
                                 
                                </div>
                                <div class="col-md-7 d-flex mt-3" *ngIf="validateFile">
                                 
                                    <div *ngIf="validateFile">
                                        <p style="color:red">{{validateFileMsg}}</p>
                                    </div>
                                </div>   
                                <div class="col-md-5 mt-3" *ngIf="showUpload">
                                        <h4 class="required">Paper Claim Attachment</h4>
                                </div>
                                    <div class="col-md-7 mt-3"  *ngIf=" showUpload ">
                                        <div class="d-flex align-items-center"  *ngFor="let claim of claims ; let i = index">
                                            <input type="checkbox" multiple formControlName="PaperClaim" id="myCheckbox" 
                                            [value]="claim.id" 
                                            (change)="changeSelection($event)" 
                                            [(ngModel)]="claim.isChecked"
                                            >
                                            <label class="mb-0">{{claim.paperClaim}}</label>
                                        </div>
                                     </div>
                                <div class="col-md-5 mt-3" *ngIf="noValue || showUpload || showClaimConfirmation">
                                    <h4 class="required">Case Charge Amount</h4>
                                </div>
                                <div class="col-md-7 mt-3" *ngIf="noValue || showUpload || showClaimConfirmation">
                                    <!-- <input class="main_in" type="number" formControlName="Charges" min="0" placeholder='Case Charges'> -->
                                    <input class="main_in" type="text" (change)="transformAmount($event)" formControlName="Charges" min="0"   [value]="formattedAmount" placeholder='Case Charges'>
                                    <!-- <div *ngIf="f.Charges.touched && f.Charges.invalid" class="alert alert-danger">
                                        <div *ngIf="f.Charges.errors.required">Name is required.</div>
                                       (mouseout)="transformAmount($event)"
                                    </div> -->
                                </div>
                            </div>
                            <div class="row mt-3 mb-5">
                                <div class="col-md-6 ml-auto text-right">
                                    <button type="button" [disabled]="cancelButtonStatus"  class="btn btn-secondary mr-3 btn-patient-box" (click)="onClickClose()">Cancel</button>
                                    <button type="submit" [attr.disabled]=" submit == 'Submiting' ? true : null || submitButtonStatus" *ngIf="billingRuleDetails"   class="btn btn-primary btn-patient-box ml-auto"
                                        [disabled]='validateFile || !setBilledForm.valid || !checked ' >
                                        <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span>{{submit}}</button>
                                     <button type="submit" [attr.disabled]=" submit == 'Submiting' ? true : null || submitButtonStatus" *ngIf="!billingRuleDetails"   class="btn btn-primary btn-patient-box ml-auto"
                                       [disabled]='(!setBilledForm.valid) || (showUpload && checkedCheckbox.length==0)'>
                                        <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span>{{submit}}</button>

                                                                           
                                </div>
                                
                            </div>
                            
                            <!-- <div class="row" *ngIf="showUpload">
                                <table class="table">
                                    <thead>
                                        <th>Date 
                                        </th>
                                       
                                        <th>User 
                                        </th>
                                        <th>File Details</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let billed of setBilled">
                                            <td> 05/03/2021 </td>
                                           
                                            <td>{{userName}}</td>
                                            <td><a href="javascript:void(0)" class="icon-color-green mr-1" (click)="downloadFile(billed)"><i
                                                        class="fas fa-download" title="Download"></i>&nbsp;{{billed.filename}}</a></td>
                                            <td><a href="javascript:void(0)" (click)="deleteFile(billed)" class="icon-color-red mr-1"><i
                                                            class="fas fa-trash" title="Delete"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div> -->
                       
                    </div>
                </form>
                </div>
            </div>
        <!-- </div> -->
    </div>
    <div *ngIf=showConfirmation>
        <div id="centerModal"  class="sidenav shadow width-700  active" >
            <div
                class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
                <div class="row case-row m-0 font-weight-bold text-primary">
                    <h4>There are unsaved changes !</h4>
                </div>
                
                
            </div>
            <div class="card-body">
                <div class="centerText">
                
                    <h5>Are you sure you want to cancel changes?</h5><br>
                    <div class="text-right">
                    
                    <a href="javascript:void(0);" (click)="confirmNoClick()" class="mr-2 btn btn-warning  closeMySidenav" id="closeMySidenav">
                        No
                    </a>
                    <a href="javascript:void(0);" (click)="confirmYesClick()" class="mr-2 btn btn-success closeMySidenav" id="closeMySidenav">
                        Yes
                    </a>
                </div>
    
                </div>
            </div>
        </div>
    </div>
</div>


<!-- Sidenav-->