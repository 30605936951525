import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-close-of-day',
  templateUrl: './close-of-day.component.html',
  styleUrls: ['./close-of-day.component.css']
})
export class CloseOfDayComponent implements OnInit {
  FacilityList: any;
  facilityID: any = 0;
  facilityName: string = "";
  dataEODSummary: any = [];
  dataEODSummaryDetails: any = [];
  dataEODSummaryOnHold: any = [];
  variancettl = 0;
  varReason = "";
  confComments = "";
  PendingPatientList: any = [];
  smryIsToggled = false;
  submitted = false;
  varMandatory = false;
  commentMandatory = false;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private commonUtil: CommonService,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.facilityID = this.route.snapshot.params.id;
    this.getFacilityInfo();
    this.getPendingPatientList();
    this.getSummary();
  }

  getFacilityInfo() {
    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
        let val = this.FacilityList.find(x => x.id == this.facilityID);
        this.facilityName = val.name;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getSummary() {

    let url = "EOD/btnCloseDay";
    let data = {
      "facilityId": this.facilityID
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.dataEODSummary = res.dataEODSummary;
        this.dataEODSummaryDetails = res.dataEODSummaryDetails;
        this.dataEODSummaryOnHold = res.dataEODSummaryOnHold;

        if (this.dataEODSummary.length > 0) {
          this.variancettl = (this.dataEODSummary[0].totalMC || 0) - (this.dataEODSummary[0].totalPending || 0);
        }


      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

  calcVariance(event) {
    let cVal = (event.target.value || 0);
    let tVal = 0;
    if (this.dataEODSummary[0] != undefined) {
      tVal = this.dataEODSummary[0].totalPending;
    }

    this.variancettl = cVal - tVal;
  }

  confirmCOD() {
    this.submitted = true;
    if (this.variancettl != 0 && this.varReason == "") {
      this.varMandatory = true;
      return;
    }
    if (this.confComments == "") {
      this.commentMandatory = true;
      return;
    }

    if (this.dataEODSummary.length > 0) {
      let url = "EOD/SaveEODSummary";
      let data = {
        "facilityId": this.facilityID,
        "lblElectronicCharges": this.dataEODSummary[0].eTotal,
        "lblPaperCharges": this.dataEODSummary[0].pTotal,
        "lblSelfPayCharges": this.dataEODSummary[0].sTotal,
        "txtComments": this.confComments,
        "txtVarianceReason": this.varReason,
        "lblHighDollarClaimsOnHoldCount": this.dataEODSummary[0].highDollarCount,
        "lblHighDollarClaimsOnHoldCharges": this.dataEODSummary[0].highDollarTotal,
        "lblOnHoldCount": this.dataEODSummary[0].onHoldCount,
        "lblOnHoldClaims": this.dataEODSummary[0].onHoldTotal,
        "lblElectronicClaims": this.dataEODSummary[0].eClaims,
        "lblPaperClaims": this.dataEODSummary[0].pClaims,
        "lblSelfPayClaims": this.dataEODSummary[0].sClaims,
        "txtMCClaims": this.dataEODSummary[0].totalMC,
        "hidPendingClaims": this.dataEODSummary[0].totalPending
      }

      this.getApiService.post(url, data).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success("Saved Successfully!", 'Success!');
          this.router.navigate(['d/eod/0']);
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });

    }
  }

  checkText() {
    if (this.confComments.trim() != "") {
      this.commentMandatory = false;
    }
    if (this.varReason.trim() != "") {
      this.varMandatory = false;
    }
  }

  getPendingPatientList() {
    let url = "EOD/Get48HPendingPatientList";
    let data = {
      "facilityId": this.facilityID
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.PendingPatientList = res.data48HPendingPatientList;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  getFile(fileId, fileName) {
    this.spinner.show();
    let data = { "BDOSID": fileId };

    this.getApiService.post4File("EOD/DownloadFile", data).subscribe(res => {
      if (res.type != "application/json") {
        saveAs(res, fileName);
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

}
