export interface UserProductivity {
  userid: number | string;
  email: string;
  username: string;
  fromdate: Date;
  todate: Date;
  totalcases: number;
  totalchargedbill: number;
  caseswph: number;
  totalCount: number;
}

export function createUserProductivity(params: Partial<UserProductivity>) {
  return {
    ...params
  } as UserProductivity;
}
