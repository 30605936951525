import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { CptService } from 'src/app/shared/state/cpt/cpt.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { GroupsQuery } from 'src/app/shared/state/groups/groups.query';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { PayersQuery } from 'src/app/shared/state/payers/payers.query';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { PhysicianQuery } from 'src/app/shared/state/physician/physician.query';
import { PhysicianService } from 'src/app/shared/state/physician/physician.service';
import { createCustomQueue, createFacilityId, createGroupId, createPhysicianId, CustomQueue } from './custom-queue.model';
import { CustomQueueQuery } from './custom-queue.query';
import { CustomQueueStore } from './custom-queue.store';

@Injectable({ providedIn: 'root' })
export class CustomQueueService {

  constructor(
    private customQueueStore: CustomQueueStore, 
    private apiService: ApiService,
    private toastr: ToastrService,
    private groupService: GroupsService,
    private facilityService: FacilityService,
    private physicianService: PhysicianService,
    private groupQuery: GroupsQuery,
    private facilityQuery: FacilityQuery,
    private physicianQuery: PhysicianQuery,
    private payerQuery: PayersQuery,
    private payersService: PayersService,
    private query: CustomQueueQuery,
    private cpsService: CptService
    ) {
  }


  set(customQueueList: CustomQueue[]){
    this.customQueueStore.set(customQueueList);
  }
  add(customQueue: CustomQueue, pagenumber: number, pageSize: number, searchValue: string) {
   this.apiService.post("CustomQueue/SaveCustomQueue", customQueue).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getByParams(pagenumber, pageSize, searchValue);
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, 
    error => {
      this.toastr.error(error, 'Error!');
    });
    this.customQueueStore.add(customQueue);
  }

  update(id, customQueue: Partial<CustomQueue>) {
    this.customQueueStore.update(id, customQueue);
  }

  setActive(id: ID[]){
    this.customQueueStore.setActive(id);
  }

  active(ids: ID[]) {
    this.customQueueStore.addActive(ids);
  }

  delete(delRow) {
    let data = { "Id": delRow.id, "IsActive": delRow.active };

    this.apiService.post("CustomQueue/DeactivateCustomQueue", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
       this.getByParams(1, 1000, "");
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getByParams(pageNum: number, pageSize: number, searchValue: string): void{
    this.customQueueStore.setLoading(true);
    let data = { "pageNum": pageNum, "pageSize": pageSize, "SearchValue": searchValue };
    console.log('getByParams', data);
    this.apiService.post('CustomQueue/BindGridData', data).subscribe(resp => {
      if(resp === null || resp === undefined || resp.status === 'Error' || resp === 'error'){
        this.toastr.error('Failed to fetch details', (resp ? resp.message : resp));
        this.customQueueStore.setLoading(false);
        return;
      }
      this.customQueueStore.set(resp.data?.map( r => createCustomQueue({ 
        totalcount: r.totalcount, id: r.id, queueName: r.queueName, description: r.description, isActive: r.isActive 
      })));
      this.customQueueStore.setLoading(false);
    }, error => {
      this.toastr.error(error);
      this.customQueueStore.setLoading(false);
    });
  }

  getByParamsWithSorting(pageNum: number, pageSize: number, searchValue: string, sortBy: string, sortDir): void{
    this.customQueueStore.setLoading(true);
    let data = { "pageNum": pageNum, "pageSize": pageSize, "SearchValue": searchValue, "OrderBy": sortBy, "SortDir": sortDir };
    console.log('getByParams', data);
    this.apiService.post('CustomQueue/BindGridData', data).subscribe(resp => {
      if(resp === null || resp === undefined || resp.status === 'Error' || resp === 'error'){
        this.toastr.error('Failed to fetch details', (resp ? resp.message : resp));
        this.customQueueStore.setLoading(false);
        return;
      }
      this.customQueueStore.set(resp.data?.map( r => createCustomQueue({ 
        totalcount: r.totalcount, id: r.id, queueName: r.queueName, description: r.description, isActive: r.isActive 
      })));
      this.customQueueStore.setLoading(false);
    }, error => {
      this.toastr.error(error);
      this.customQueueStore.setLoading(false);
    });
  }

  get() {
    this.customQueueStore.setLoading(true);
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };
    return this.apiService.post("CustomQueue/BindGridData", data).pipe(tap(e => {
      const entities: CustomQueue[] = e.data;
      this.customQueueStore.set(entities);
      this.customQueueStore.setLoading(false);
    }));
  }

  setQueueDetails(id: ID): void{
     this.apiService.post("CustomQueue/GetQueueDetails", { Id: id }).subscribe(res => {
       console.log('Recieved response for GetQueueDetails',res);
      if(res.status !== 'Success'){
        this.toastr.error('Error', res?.message);
        return;
      }
      const assignedGroupId = res.dataAssignedGroup.map(g => g.id);
      this.groupService.setActive(assignedGroupId);
      //initialize facility store
      this.facilityService.setFacilityGroup(assignedGroupId);
      const assignedFacilityId = res.dataAssignedFacility.map(fac => fac.id);
      this.facilityService.setActive(assignedFacilityId);
      //initialize physician, payer, cpt
      this.physicianService.setPhysicianFacility(assignedFacilityId);
      this.payersService.setPayerFacility(assignedFacilityId);
      this.cpsService.setCptFacility(assignedFacilityId);

      //make assigned physician, payer cpt as active
      this.physicianService.setActive(res.dataAssignedPhysician.map(phy => phy.id));
      this.payersService.setActive(res.dataAssignedPayer.map(payer => payer.id));
      this.cpsService.setActive(res.dataAssignedCPT.map(cpt => cpt.cpt));
    });
  }

  setAvaialableQueues(): void{
    this.customQueueStore.set([]);
    const activeId =this.facilityQuery.getActiveId();
     const request$ = activeId.map(id => this.getQueueByFacility(id));
     request$.forEach(req => {
       req.subscribe(res => {
         this.customQueueStore.add(res);
       })
     });
   }

   private getQueueByFacility(id: ID): Observable<CustomQueue[]>{
     const data = { FacilityId: id };
     return this.apiService.post("CustomQueue/GetQueueByFacility", data).pipe(map(res => res.data));
   }

   inActive(ids: ID[]) {
    const activeIds = this.query.getActiveId();
    const idsTobeMadeInactive = ids.filter(id => activeIds.includes(id));
    this.customQueueStore.toggleActive(idsTobeMadeInactive);
  }

  inActiveAll() {
    const ids = this.query.getActiveId();
    this.inActive(ids)
  }

  reset(){
    this.customQueueStore.set([]);
  }
  setCustomQueueByFilters(){

    let data = {
                "Group": this.groupQuery.getActiveId(),
                "Facility": this.facilityQuery.getActiveId(),
                "Physician": this.physicianQuery.getActiveId(),
                "Payer": this.payerQuery.getActiveId()
            }
    console.log('Request object for getting queue based on filters ', data);        
    this.apiService.post("CustomQueue/GetCustomQueueByFilters", data).subscribe(res => {
      if(res.status === 'Success'){
       this.set(res.data);
      }else{
        this.toastr.error('Error', res.message);
      }
    })
  }
}
