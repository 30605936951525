import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { groupBy } from 'rxjs/internal/operators/groupBy';
import { map } from 'rxjs/operators';
import { bulkAction } from 'src/app/shared/models/name-constant';
import { CaseRequestCpt, CaseRequestVarCpt } from '../../state/case-request-cpt/case-request-cpt.model';
import { CaseRequestCptQuery } from '../../state/case-request-cpt/case-request-cpt.query';
import { CaseRequestCptService } from '../../state/case-request-cpt/case-request-cpt.service';
import { Tab } from '../../state/tabs/tab.model';
import { TabsQuery } from '../../state/tabs/tabs.query';

@Component({
  selector: 'app-cr-cpt-view',
  templateUrl: './cr-cpt-view.component.html',
  styleUrls: ['./cr-cpt-view.component.css']
})
export class CrCptViewComponent implements OnInit {
  @Input() callerId: string;
  @Input() bdosid: string | number;
  caseRequestCPt$: Observable<CaseRequestCpt[]>;
  varianceList$: Observable<CaseRequestVarCpt[]>;
  activeTab$: Observable<Tab>;
  totalCost$: Observable<string>;

  constructor(
    private caseRequestCptService: CaseRequestCptService,
    private caseRequestCptQuery: CaseRequestCptQuery,
     private tabsQuery: TabsQuery) { }

  ngOnInit(): void {
    const activeTab = this.tabsQuery.getActive();
    this.activeTab$ = this.tabsQuery.selectActive();
    if(this.isBulkAction()){
      this.caseRequestCPt$ = this.caseRequestCptQuery.selectCptByRequestAndPatientId(activeTab.id, this.bdosid);
      this.totalCost$ = this.caseRequestCptQuery.selectTotalCostByRequestAndPatient(activeTab.id, this.bdosid);
      this.varianceList$ = this.caseRequestCptService.selectAllVarianceForPatient(this.bdosid);
    }else{
      this.caseRequestCPt$ = this.caseRequestCptQuery.selectCptByComponentId(activeTab.id.toString());
      this.totalCost$ = this.caseRequestCptQuery.selectTotalCostFor(activeTab.id);
      this.varianceList$ = this.caseRequestCptService.selectAllVariance();
    }
  }

  private isBulkAction(): boolean {
    return this.callerId === bulkAction.id;
  }
}
