import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CodingReviewComponent } from './coding-review/coding-review.component';
import { CodingReviewedComponent } from './coding-reviewed/coding-reviewed.component';


const routes: Routes = [
  {
    path: '',
    children:[
      {
        path: '',
        redirectTo: '/coding-review',
        pathMatch: 'full'
      },
      {
        path: 'coding-review',
        component: CodingReviewComponent,
      },
      {
        path: 'coding-reviewed',
        component: CodingReviewedComponent,
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CodingInquiryRoutingModule { }
