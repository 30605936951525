export interface FacilityReportLine {
    facilityName: string;
    mrn: string;
    workType: string;
    importDate: string;
    setBilledDate: string;
    totalCharges: number;
    pendingClient: number;
    inCoding: number;
    otherStat: number;
    totalcount: number;
  }
  
  export function createFacilityReportLine(params: Partial<FacilityReportLine>) {
    return {
      ...params
    } as FacilityReportLine;
  }
  