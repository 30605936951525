import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-eod-maintenance',
  templateUrl: './eod-maintenance.component.html',
  styleUrls: ['./eod-maintenance.component.css']
})
export class EodMaintenanceComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  EODMaintenanceArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  privileges:any;
  searchValue: string = "";

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private accessServices : AccessService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.privileges= this.accessServices.navbar_access
    this.commonUtil.setTitle("EOD Maintenance");
    this.getEODMaintenanceList();
  }


  showFormModal() {
    this.formData = null;
    console.log(this.shouldShowFormModal);
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getEODMaintenanceList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }


  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getEODMaintenanceList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  getEODMaintenanceList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    this.getApiService.post("EODMaintenance/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.EODMaintenanceArry = res.datatEod;
        this.totalRows = res.datatEod[0].totalcount;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  editEODMaintenance(data) {
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.EODMaintenanceArry = [];
    this.getEODMaintenanceList();
  }

  delEODMaintenance(delRow) {

    let data = {};
    let url = "";

    switch (delRow.tableType) {
      case "Followup": {
        url = "EODMaintenance/DeleteFolloUpReason";
        data = { "id": delRow.id };
        break;
      }
      case "Reject": {
        url = "EODMaintenance/DeleteRejectReason";
        data = { "id": delRow.id };
        break;
      }
      case "Hold": {
        url = "EODMaintenance/DeleteHoldReason";
        data = { "id": delRow.id };
        break;
      }
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getEODMaintenanceList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getEODMaintenanceList();
  }

}
