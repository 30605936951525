import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { saveAs } from 'file-saver';
import { implantLog, invoice, NameConstant } from 'src/app/shared/models/name-constant';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-facility-pricing',
  templateUrl: './facility-pricing.component.html',
  styleUrls: ['./facility-pricing.component.css']
})
export class FacilityPricingComponent implements OnInit {
  p: number = 1;
 // @Output() closeEmitter = new EventEmitter();
  //shouldShowFormModal: boolean = false;
  formData: any = null;
  facilityPricingArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  edit_id:any;
  constructor( private getApiService: ApiService,
    private toastr: ToastrService,
    private route : ActivatedRoute,
    private spinner: NgxSpinnerService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.edit_id =this.route.snapshot.paramMap.get('id');
    console.log(this.edit_id,"edit id");
    this.getfacilityPricing();
  }
  sortListPricing(orderBy,sortDir){
    this.spinner.show();
    let  data = { "FacilityId":this.edit_id,"pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue,"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("Pricing/BindGrid", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityPricingArry = resp.data;
        this.totalRows = resp.data[0].totalcount;
        console.log(this.facilityPricingArry,"facilityGroupArry");
        this.spinner.hide();
      }
  }, (err) => {
    console.log(err);
    this.spinner.hide();
  })
}
  getfacilityPricing() {
    this.spinner.show();
    if (this.searchValue != '' && this.pagenumber != 1) {
      this.pagenumber = 1;
      this.p = 1;
    }
    
    let  data = { "FacilityId":this.edit_id,"pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    this.getApiService.post("Pricing/BindGrid", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.spinner.hide();
        this.facilityPricingArry = resp.data;
        this.totalRows = resp.data[0]?.totalcount;
        console.log(this.facilityPricingArry,"facilityGroupArry");
        
      }
    }, (err) => {
      console.log(err);
      this.spinner.hide();
    })
  }
  onEntryChange(event) {
    this.p = event;
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.facilityPricingArry = [];
    this.getfacilityPricing();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getfacilityPricing();
  }

  downloadImplantLogFile(data: any){
    this.getFile(implantLog, data);
  }

  downloadInvoiceFile(data: any){
    this.getFile(invoice, data);
  }

  private getFile(name: NameConstant, pdata: any){
    let data = {
      "facilityId": pdata.facilityid,
      "CPT": pdata.cpt,
      "fileType": name.value
    }
    this.getApiService.post4File("BillingNew/DownloadPricingFile", data).subscribe(res => {
      saveAs(res, name.value);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
}
