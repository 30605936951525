import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService, MustMatch } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  chngPwdForm: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Change Password");

    this.chngPwdForm = this.formBuilder.group({
      CurrentPassword: ['', Validators.required],
      NewPassword: ['', Validators.required],
      confirmPassword: ['']
    }, { validator: MustMatch('NewPassword', 'confirmPassword') });
  }

  get f() { return this.chngPwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.chngPwdForm.invalid) {
      return;
    }

    let data = this.chngPwdForm.value;

    if (data.CurrentPassword != data.NewPassword) {
      this.getApiService.post('ApplicationUser/ChangePassword', this.chngPwdForm.value).subscribe(
        res => {
          console.log(res);
          if (res.status == "Success") {
            this.toastr.success('Password Change Successfully!', 'Success!');
            this.router.navigate(['/dashboard']);
          }
          else if (res.status == "IdentityError") {
            this.toastr.error(res.message[0].description, res.message[0].code);

          }
          else if (res.status == "Error") {
            this.toastr.error(res.message, 'Error!');

          }
        }, (error) => {
          this.toastr.error(error, 'Error!');
        });
    }
    else {
      this.toastr.error("New Password cannot be same as Current Password !!", 'Error!');
    }
  }
}
