<div class=" ">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
    <div
        class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
        <div class="row case-row">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-4">
                        <p class="head-name-case">MRN - </p>
                    </div>
                    <div class="col-md-8">
                        <p class="head-name-case">{{PatientSummaryDtls?.dataPatient[0]?.mrn}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 head-det-case">
                        <p class="head-det-case"><i class="fa fa-user mr-1"></i>
                            <span>{{PatientSummaryDtls?.dataPatient[0]?.patientName}}<span
                                    *ngIf="PatientSummaryDtls?.dataPatient[0]?.gender != null">({{PatientSummaryDtls?.dataPatient[0]?.gender}})</span>
                            </span>
                        </p>
                    </div>
                </div>
                <!-- <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'"> -->
                <div class="row">
                    <div class="col-md-12 head-det-case">
                        <p class="head-det-case">{{PatientSummaryDtls?.dataPatient[0]?.facilityName}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <p class="head-det-case">Primary CPT:</p>
                    </div>
                    <div class="col-md-6">
                        <p class="head-det-case">{{primaryCPT }}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 head-det-case">
                        <p class="head-det-case">Related CPTs:</p>
                    </div>
                    <div class="col-md-6 head-det-case">
                        <p class="head-det-case">
                            <span *ngFor="let d of patientCPTs">
                                <span *ngIf="d != primaryCPT">
                                    {{d}},
                                </span>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 head-det-case">
                        <p class="head-det-case"> <i class="fas fa-tools mr-1"></i> Tools and Templates</p>
                    </div>
                </div>
                <!-- </div> -->
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-name-case">Personal Information</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Address</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.address1}}
                                {{PatientSummaryDtls?.dataPatient[0]?.address2}}
                            </span>
                        </p>
                    </div>

                </div>

                <!-- <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'"> -->
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">City</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.city}}</span></p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">State</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.state}}
                                {{PatientSummaryDtls?.dataPatient[0]?.zip}}</span></p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Phone No</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.phonenumber}}</span></p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">DOB</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.dob | date: "MM/dd/yyyy"}}</span>
                        </p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">SSN No</span><span
                                *ngIf="PatientSummaryDtls?.dataPatient[0]?.ssnNo != null"
                                class="head-det-cse1">#{{PatientSummaryDtls?.dataPatient[0]?.ssnNo}}</span></p>
                    </div>

                </div>

                <!-- </div> -->
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-name-case">Insurance</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2" style="margin-bottom: 20px;"><span
                                class="head-det-cse2">Primary</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.insuranceProvider}}</span>
                        </p>
                    </div>

                </div>

                <!-- <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'"> -->
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Group #</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.groupNumber}}</span>
                        </p>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Insurance Id</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.insuranceId}}</span>
                        </p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Physician</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.physician}}</span>
                        </p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Date Of Surgery</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.dateOfSurgery}}</span>
                        </p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Billed</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.billed}}</span></p>
                    </div>

                </div>
                <!-- </div> -->
            </div>
            <!-- <p class="advance-icon-text plus-sign-bx1 expand-data"><i (click)="showPatientDetail()"
                    [ngClass]="smryIsToggled ? 'fa fa-minus showhideicn' : 'fa fa-plus showhideicn'"></i></p> -->
        </div>
    </div>
    <div class="card-body pb-3 form-fields">
        <div class="row" style="padding-top: 20px;">
            <div class="col-md-4">
                <p class="comment-heading-case">Attachments</p>
                <h4 class="">Upload File</h4>
                <input type="file" [(ngModel)]="uploadFile" (change)="fileAttachChanged($event)"
                    class="form-control-file py-1 file-overlap-name">
            </div>
            <div class="col-md-4" style="padding-top: 45px;">
                <select class="custom-select form-control" [(ngModel)]="uploadFileText">
                    <option selected Value="Attached File">File Type</option>
                    <ng-container *ngFor="let d of dataQueue">
                        <option *ngIf="dataQueueInfo[d.value] == true" [value]="d.key">{{d.key}}</option>
                    </ng-container>
                </select>
                <!-- <textarea [(ngModel)]="uploadFileText" class="form-control" style="margin-right: 10px;">
                    </textarea> -->
            </div>

            <div class="form-group frm-margin-btm caseatselect col-md-4">
                <!-- <div class="input-group"> -->
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary btn-patient-box closeMySidenav mr-1">Cancel</button>

                <button type="button" (click)="uploadAttachment()"
                    class="btn btn-primary btn-patient-box">Submit</button>
                <!-- </div> -->
            </div>
        </div>
        <div class="row" style="padding-top: 20px;">
            <p class="comment-heading-case">History</p>
            <div class="col-md-12">
                <div class="row back-color-comnt1">
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">Date</p>
                    </div>
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">File Type</p>
                    </div>
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">User</p>
                    </div>
                    <div class="form-group1 col-md-4">
                        <p class="patient-tab-above">File Details</p>
                    </div>
                    <div class="form-group1 col-md-2">
                        <p class="patient-tab-above">Comments</p>
                    </div>
                </div>
                <div class="patient-center-space">

                    <div class="row" *ngFor="let d of PatientSummaryDtls?.dataFileHistoryInfo">
                        <div *ngIf="d.contentFileName != null" class="form-group col-md-12 frm-margin-btm data-bottom">
                            <p class="ipt2-1">{{d.billerTime|| d.clientTime}}</p>
                            <p class="ipt2-1">{{d.auditEventDescription}} </p>
                            <p class="ipt2-1">{{d.billerName || d.clientName}}</p>
                            <p class="ipt2-2"><a href="javascript:void(0)"
                                    (click)="getFile(d.id, d.contentFileName)">{{d.contentFileName}}</a></p>
                            <p class="ipt2-4">{{d.billerNote}}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
<!-- Sidenav-->
</div>