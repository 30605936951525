import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginLayoutModule } from './layouts/login-layout/login-layout.module';
import { DefaultLayoutModule } from './layouts/default-layout/default-layout.module';
import { AuthInterceptor } from './shared/helpers/auth.interceptor';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';

import { ChangePasswordComponent } from './modules/user/change-password/change-password.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { TestComponent } from './modules/test/test.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { ChartsModule } from 'ng2-charts';
import { NewDashboardComponent } from './modules/new-dashboard/new-dashboard.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { TestSideNavComponent } from './modules/test/test-side-nav/test-side-nav.component';
import { NgxPrintModule } from 'ngx-print';
import { FacilityGroupComponent } from './facility-group/facility-group.component';
import { MockedInterceptor } from './shared/helpers/mock.interceptor';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './shared/helpers/timeout.interceptor';
import { AuditlogsComponent } from './modules/auditlogs/auditlogs.component';
import { NgxIdleTimeoutModule } from 'ngx-idle-timeout';
import { CurrencyPipe} from '@angular/common';
//import { PatientActionComponent } from './modules/billing-dashboard/patient-action/patient-action.component'
@NgModule({
  declarations: [
    AppComponent,
    ChangePasswordComponent,
    TestComponent,
    NewDashboardComponent,
    TestSideNavComponent,
    FacilityGroupComponent,
    AuditlogsComponent,
  ],
  imports: [
    CommonModule,
    NgxIdleTimeoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    LoginLayoutModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DefaultLayoutModule,
    ToastrModule.forRoot(),
    NgxPaginationModule,
    SelectDropDownModule,
    AppRoutingModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    NgbModule,
    TextMaskModule,
    ChartsModule,
    NgxSpinnerModule,
    NgxPrintModule,
    NgxIdleTimeoutModule,
  
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockedInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
    { provide: DEFAULT_TIMEOUT, useValue: 360000 },
    Title,
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }},
    CurrencyPipe,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
