import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { UserConfirmationComponent } from './user-confirmation/user-confirmation.component';

import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { UserregistrationComponent } from './userregistration/userregistration.component';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'forgotPassword',
        component: ForgotpasswordComponent
      },
      {
        path: 'userregistration',
        component: UserregistrationComponent
      },
      {
        path: 'resetpassword/:token/:email',
        component: ResetPasswordComponent
      },
      {
        path: 'confirmation/:code/:userId',
        component: UserConfirmationComponent
      }],
  }]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserRoutingModule { }
