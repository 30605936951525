import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-exception-action',
  templateUrl: './exception-action.component.html',
  styleUrls: ['./exception-action.component.css']
})
export class ExceptionActionComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  _formData: any;
  patientInfo: any = [];
  reqSelect: string = "";
  showModal = false;
  fileSuffix: string = "";
  srchPatient: string = "";
  fileError: boolean = false;

  // opFile: boolean = false;
  // pathFile: boolean = false;
  // queFile: boolean = false;
  // aopFile: boolean = false;

  opFile: any = [];
  pathFile: any = [];
  queFile: any = [];
  aopFile: any = [];

  selectedData: any = [];

  @Input() set formData(value: any) {
    if (value) {
      this._formData = value;
      this.reqSelect = value.reqSelect;
    }
  }
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.getData();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getData() {
    let url = "ExceptionQue/GetPossibleExceptionPatients";
    let data = {};
    if (this._formData != undefined) {
      data = {
        "FacilityName": this._formData.facilityName,
        "MRN": this._formData.mrn,
        "DoS": this._formData.dateOfService,
        "PatientName": this._formData.patientName
      }
    }
    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.patientInfo = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  OpenModal(data) {
    if (this.reqSelect == 'OpNote Exception Updates' && this.fileSuffix == '') {
      this.fileError = true;
      this.showModal = false;
    }
    else {
      this.selectedData = data;
      this.fileError = false;
      this.showModal = true;
    }
  }

  CloseModal() {
    this.selectedData = [];
    this.fileError = false;
    this.showModal = false;
  }

  chkFileSuffix(event) {
    let type = event.target.value;

    if (event.target.checked == true) {
      if (type == '_OP') {
        this.opFile = true;
        this.pathFile = false;
        this.queFile = false;
        this.aopFile = false;
        this.fileSuffix = type;
        this.fileError = false;
      }
      else if (type == '_PATH') {
        this.opFile = false;
        this.pathFile = true;
        this.queFile = false;
        this.aopFile = false;
        this.fileSuffix = type;
        this.fileError = false;
      }
      else if (type == '_QUE') {
        this.opFile = false;
        this.pathFile = false;
        this.queFile = true;
        this.aopFile = false;
        this.fileSuffix = type;
        this.fileError = false;
      }
      else if (type == '_AOP') {
        this.opFile = false;
        this.pathFile = false;
        this.queFile = false;
        this.aopFile = true;
        this.fileSuffix = type;
        this.fileError = false;
      }
    }
    else {
      this.opFile = false;
      this.pathFile = false;
      this.queFile = false;
      this.aopFile = false;
      this.fileSuffix = "";
      this.fileError = true;
    }
  }

  setSubmit(type) {
    let url = "";
    let data = {};

    this.fileError = false;
    this.showModal = false;

    if (type == 'coding') {
      url = "ExceptionQue/btnAttachFileYes";
    }
    else if (type == "patient") {
      url = "ExceptionQue/btnAttachFileOnly";
    }

    if (JSON.stringify(this.selectedData) != "{}") {
      data = {
        "PatientName": this.selectedData.name,
        "MRN": this.selectedData.mrn,
        "DoS": this.selectedData.dateOfSurgery,
        "rdoExceptionType": this.reqSelect,
        "SelectedFileSuffix": this.fileSuffix,
        "FacilityName": this.selectedData.client,
        "FilePath": this._formData.filePath,
        "BDOSID": this.selectedData.bdosid,
        "txtBeginDate": this._formData.fromDate,
        "txtEndDate": this._formData.toDate
      }
    }
    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Attached File Successfully", 'Success!');
        this.selectedData = [];
        this.onClickClose();

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  searchPatient() {
    if (this.srchPatient.trim() != "" && this.srchPatient != undefined) {
      let url = "ExceptionQue/BindPatientSearchGridData";
      let data = {
        "FacilityName": this._formData.facilityName,
        "txtSearch": this.srchPatient
      }

      this.getApiService.post(url, data).subscribe(res => {
        if (res.status == "Success") {
          this.patientInfo = res.data;
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
    }
    else {
      this.getData();
    }
  }
}
