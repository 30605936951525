import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { CaseQueueFacilityReport } from './case-queue-facility-report.model';
import { CaseQueueFacilityReportStore } from './case-queue-facility-report.store';

@Injectable({ providedIn: 'root' })
export class CaseQueueFacilityReportService {

  constructor(private caseQueueFacilityReportStore: CaseQueueFacilityReportStore, private http: HttpClient) {
  }

  set(entities: CaseQueueFacilityReport[]) {
    this.caseQueueFacilityReportStore.set(entities);
   }
 
   setActive(id: ID){
     this.caseQueueFacilityReportStore.setActive(id);
   }
 
   toggleActive(id: ID){
     this.caseQueueFacilityReportStore.toggleActive(id);
   }

  add(caseQueueFacilityReport: CaseQueueFacilityReport) {
    this.caseQueueFacilityReportStore.add(caseQueueFacilityReport);
  }

  update(id, caseQueueFacilityReport: Partial<CaseQueueFacilityReport>) {
    this.caseQueueFacilityReportStore.update(id, caseQueueFacilityReport);
  }

  remove(id: ID) {
    this.caseQueueFacilityReportStore.remove(id);
  }

}
