<div class="bg-blur">
    <div id="mySidenav" class="sidenav shadow width-560  active">
        <!-- <div class="container-fluid"> -->
            <div class="row">
                <div class="col-md-12 p-0  ">
                    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
                    <h6 class="m-0 font-weight-bold text-primary bill_6_new">Undo Set Billed</h6>
                    <a  class="" id="" (click)="onClickClose()">
                    <i class="fas fa-times fa-sm"></i>
                    </a>
                </div>
                </div>
                <div class="container-fluid">
                    <form class="form-fields" [formGroup]="setBilledForm" (ngSubmit)="onSubmit()">
                    <div class="col-md-12  ml-auto mr-auto mt-4" >
                        <div class="bag_gre" *ngIf='billingRuleDetails' style="padding-left : 15px; padding-bottom: 3px;">
                            <p class="red-col pt-2">Required To Bill </p>
                        <p class="red-col"><input type="checkbox" formControlName="checkbill" (change)="checkbill()">
                            <!-- Regence Blue Shield must go electronically. However. if you have an attechment it can be fixed to (888)208-8039. The clain unmber must be on the fax cover sheet.
                            </p> -->
                            {{billingRulesList[0].ruleText}}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-12 main_in">
                        
                            <div class="row">
                                <div class="col-md-5 mt-3">
                                        <h4 class="required">Claim Type</h4>
                                </div>
                                    <div class="col-md-7 d-flex mt-3">
                                        <p>{{claimType}}</p>
                                        <input class="main_in" type="hidden" formControlName="ClaimType" placeholder="Claim Type" readonly="true">
                                        <!-- <select class="main_in"  formControlName="ClaimType" *ngIf='showpaperclaim' (change)='selectClaimType($event)'>
                                            <option *ngIf='claimType == "paper"' value='paper' selected="true"> Paper </option>
                                            <option *ngIf='claimType != "paper"' value='paper' > Paper </option>
                                            <option *ngIf='claimType == "electronic"' value='electronic' selected="true"> Electronic </option>
                                            <option *ngIf='claimType != "electronic"' value='electronic'> Electronic </option>
                                            <option  *ngIf='claimType == "self"' value='self' selected="true"> Self </option>
                                            <option  *ngIf='claimType != "self"' value='self'> Self </option>
                                        </select> -->
                                        <!-- <select class="main_in" formControlName="ClaimType" *ngIf='!showpaperclaim' (change)='selectClaimType($event)'>
                                            
                                            <option  value='self'> Self </option>
                                            <option  value='paper'> Paper </option>
                                            <option  value='electronic'> Electronic </option>
                                            
                                        </select> -->
                                        <!-- <button class="new_red" *ngIf='billingRuleDetails' [disabled]='!checked' (click)='overRideClaim()'>Override</button> -->
                                </div>
                                <div class="col-md-5 mt-3" *ngIf='showOverrideReason'>
                                        <h4 class="required"> Override Reason</h4>
                                </div>
                                    <div class="col-md-7 d-flex mt-3" *ngIf='showOverrideReason'>
                                        <input class="main_in" formControlName="OverrideReason" type="text"  placeholder='Override Reason'>
                                </div>
                                <div class="col-md-5 mt-3" *ngIf="showClaimConfirmation">
                                        <h4 class="required">Claim Confirmation</h4>
                                </div>
                                    <div class="col-md-7 d-flex mt-3" *ngIf="showClaimConfirmation ">
                                        <!-- <span>Claim#</span> -->
                                        <input class="main_in" type="text" formControlName="ClaimConfirmation" placeholder="Claim#" >
                                </div>
                                    <div class="col-md-5 mt-3" *ngIf="showUpload ">
                                        <h4 class="required">Upload Claim </h4> 
                                </div>
                                    <div class="col-md-7 d-flex mt-3" *ngIf="showUpload ">
                                        <input  type="file" multiple formControlName="fileName" (change)="selectionChanged($event)">
                                </div>
                                <div class="col-md-5 mt-3" *ngIf="validateFile">
                                 
                                </div>
                                <div class="col-md-7 d-flex mt-3" *ngIf="validateFile">
                                 
                                    <div *ngIf="validateFile">
                                        <p style="color:red">{{validateFileMsg}}</p>
                                    </div>
                                </div>   
                                <div class="col-md-5 mt-3" *ngIf="showUpload">
                                        <h4 class="required">Paper Claim Attachment</h4>
                                </div>
                                    <div class="col-md-7 mt-3" *ngIf="showUpload">
                                        <!-- <div  *ngIf='showpaperclaim' >
                                        <div class="d-flex align-items-center" *ngFor="let pclaim of paperclaim ; let i = index">
                                            <input type="checkbox" formControlName="PaperClaim" [value]="pclaim.paperclaimid" checked (change)="changeSelection($event)">
                                            <label class="mb-0">{{pclaim.paperClaim}}</label>
                                        </div></div> -->
                                        <!-- <div *ngFor="let pclaim of paperclaim "> -->
                                            <div class="d-flex align-items-center"  *ngFor="let claim of claims;let i= index">
                                             
                                                <!-- <input type="checkbox" formControlName="PaperClaim" *ngIf="claim.id == pclaim.paperclaimid" checked="checked" [value]="claim.id" (change)="changeSelection($event)"> -->
                                                <!-- <div *ngIf="pclaim.paperclaimid == claim.id;"> -->
                                                    <input  type="checkbox" [checked]='claim.checked ' [id]='i'  (change)="changeSelection($event)" >
                                                    <label class="mb-0">{{claim.paperClaim}}</label>
                                                <!-- </div> -->
                                            </div>
                                        <!-- </div> -->
                                                <!-- <ng-template  #second>
                                                    <div *ngIf="paperclaim[1]==claim.id;else third">
                                                        <input  type="checkbox"[checked]="paperclaim[1]==claim.id" (change)="changeSelection($event)" >
                                                        <label class="mb-0">{{claim.paperClaim}}</label>
                                                    </div>
                                            </ng-template>
                                            <ng-template  #third>
                                                <div #third *ngIf="paperclaim[2]==claim.id;else fourth">
                                                    <input  type="checkbox" [checked]="paperclaim[2]==claim.id" (change)="changeSelection($event)">
                                                    <label class="mb-0">{{claim.paperClaim}}</label>
                                                </div>
                                                </ng-template>
                                                <ng-template #fourth>
                                                <div #fourth *ngIf="paperclaim[3]==claim.id;else fifth">
                                                    <input  type="checkbox" [checked]="paperclaim[3]==claim.id" (change)="changeSelection($event)" >
                                                    <label class="mb-0">{{claim.paperClaim}}</label>
                                                </div>
                                            </ng-template>
                                            <ng-template #fifth>
                                                <div *ngIf="paperclaim[4]==claim.id;else sixth">
                                                    <input type="checkbox" [checked]="paperclaim[4]==claim.id" (change)="changeSelection($event)">
                                                    <label class="mb-0">{{claim.paperClaim}}</label>
                                                </div>
                                                </ng-template>
                                                <ng-template #sixth>
                                                    <input type="checkbox" (change)="changeSelection($event)">
                                                    <label class="mb-0">{{claim.paperClaim}}</label>
                                                </ng-template>
                                        </div> -->
                                    
                                </div>
                                <div class="col-md-5 mt-3" *ngIf="noValue || showUpload || showClaimConfirmation">
                                    <h4 class="required">Case Charge Amount</h4>
                                </div>
                                <div class="col-md-7 mt-3" *ngIf="noValue || showUpload || showClaimConfirmation">
                                    <input class="main_in" type="text"   formControlName="Charges" (change)="transformAmount($event)" [value]="claimValue" placeholder='Case Charges' (mouseout)="transformAmount($event)">
                                    <!-- <input class="main_in" type="number"   min="0" formControlName="Charges" [value]="claimValue" placeholder='Case Charges'> -->
                                    <!-- <div *ngIf="f.Charges.touched && f.Charges.invalid" class="alert alert-danger">
                                        <div *ngIf="f.Charges.errors.required">Name is required.</div>
                                       
                                    </div> -->
                                </div>
                            </div>
                            <div class="row mt-3 mb-5">
                                <div class="col-md-8 ml-auto text-right d-flex" >
                                    <button class="btn btn-secondary mr-1 btn-patient-box" (click)="onClickClose()">Cancel</button>
                                    <button type="submit" *ngIf="billingRuleDetails" class="btn btn-primary mr-1 btn-patient-box ml-auto" [disabled]='!setBilledForm.valid || !checked'  >Update</button>
                                    <button type="submit" *ngIf="!billingRuleDetails" class="btn btn-primary mr-1 btn-patient-box ml-auto" [disabled]='validateFile || !setBilledForm.valid'>Update</button>
                                    <button type="button"  class="btn btn-danger mr-1 btn-patient-box ml-auto" style="white-space: nowrap;" (click)="undoSetBilled()">Undo Set Billed</button>
                                </div>
                            </div>
                            <div class="row" *ngIf="showUpload">
                                <table class="table">
                                    <thead>
                                        <th>Date 
                                        </th>
                                       
                                        <th>User 
                                        </th>
                                        <th>File Details</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let billed of setBilled">
                                            <td> {{billed.createddate | date:'MM/dd/yyyy' }} </td>
                                           
                                            <td>{{userName}}</td>
                                            <td><a href="javascript:void(0)" class="icon-color-green mr-1" (click)="downloadFile(billed)"><i
                                                        class="fas fa-download" title="Download"></i>&nbsp;{{billed.filename}}</a></td>
                                            <td><a href="javascript:void(0)" (click)="deleteFile(billed)" class="icon-color-red mr-1"><i
                                                            class="fas fa-trash" title="Delete"></i></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                       
                    </div>
                </form>
                </div>
            </div>
        <!-- </div> -->
    </div>
    <div *ngIf=showConfirmation>
        <div id="centerModal"  class="sidenav shadow width-700  active" >
            <div
                class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
                <div class="row case-row m-0 font-weight-bold text-primary">
                    <h4>Attention !</h4>
                </div>
                
                
            </div>
            <div class="card-body">
                <div class="centerText">
                
                    <h5>Are you sure you want to undo set billed?</h5><br>
                    <div class="text-right">
                    
                    <a href="javascript:void(0);" (click)="confirmNoClick()" class="mr-2 btn btn-warning  closeMySidenav" id="closeMySidenav">
                        No
                    </a>
                    <a href="javascript:void(0);" (click)="confirmYesClick()" class="mr-2 btn btn-success closeMySidenav" id="closeMySidenav">
                        Yes
                    </a>
                </div>
    
                </div>
            </div>
        </div>
    </div>
    <div *ngIf=showSecondConfirmation>
        <div id="centerModal1"  class="sidenav shadow width-700  active" >
            <div
                class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
                <div class="row case-row m-0 font-weight-bold text-primary">
                    <h4>Please give the reason for Undo Set Billed</h4>
                </div>
                
                
            </div>
            <div class="card-body">
                <div class="centerText">
                
                   
                    <form [formGroup]="reasonForm" (ngSubmit)="confirmSubmitClick()">
                        <div class="row">
                            <div class="col-12">
                                <label class="required"><b>Reason</b></label><br/>
                                <div class="input-group">
                                    <ngx-select-dropdown [config]="config"
                                        (change)="reasonChanged($event)" [options]="billReasonList$ | async" style="width: 100%;"
                                        formControlName="reason" autoClearSearch=" true" [multiple]="false">
                                    </ngx-select-dropdown>
                                </div>
                                <div *ngIf="billReasonSubmitted && f.reason.errors" class="invalid-feedback">
                                    <p>*mandatory</p>
                                </div>
                            </div>
                           <br/><br/>
                            <div class="col-12" style="margin-top:10px">
                                <label class="required"><b>Comment</b></label><br/>
                                <textarea class="form-control" formControlName="Comment" ></textarea>
                                <div *ngIf="billReasonSubmitted && f.Comment.errors" class="invalid-feedback">
                                    <p>*mandatory</p>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div class="text-right">
                        
                            <a href="javascript:void(0);" (click)="confirmCancelClick()" class="mr-2 btn btn-secondary  closeMySidenav" id="closeMySidenav">
                                Cancel
                            </a>
                            <input type="submit" [disabled]="!reasonForm.valid" value="Submit"  class="mr-2 btn btn-primary">
                        
                    
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->