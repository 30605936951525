import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { Facility } from './facility.model';

export interface FacilityState extends EntityState<Facility, number>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'facilities' })
export class FacilityStore extends EntityStore<FacilityState> {

  constructor() {
    super(initialState);
  }

}
