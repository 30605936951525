<i title="In Coding" class="fa fa-code icon-color-blue mr-2"
    *ngIf="data?.inCoding > 0 || data?.returnedFor == 'In Coding'"></i>
<!-- <i title="Returned Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                                *ngIf="data?.codingReturned > 0"></i> -->
<i title="Returned" class="fa fa-caret-square-left icon-color-blue mr-2" *ngIf="data?.returned > 0"></i>
<i title="Op Note" class="fa fa-envelope-open-text icon-color-blue mr-2"
    *ngIf="data?.opReport > 0 || data?.returnedFor == 'Op Note'"></i>
<i title="Pathology" class="fa fa-clipboard-check icon-color-blue mr-2"
    *ngIf="data?.pathology > 0 || data?.returnedFor == 'Pathology'"></i>
<i title="Coding" class="fa fa-undo-alt icon-color-blue mr-2"
    *ngIf="data?.coding > 0 || data?.returnedFor == 'Coding Query'"></i>
<i title="Pricing" class="fa fa-file-invoice-dollar icon-color-blue mr-2"
    *ngIf="data?.pricing > 0 || data?.returnedFor == 'Pricing'"></i>
<i title="High$$$" class="fa fa-dollar-sign icon-color-blue mr-2"
    *ngIf="data?.high$$$ > 0 || data?.returnedFor == 'NSN High$$$' || data?.returnedFor == 'Client High$$$'"></i>
<i title="Block" class="fa fa-stop-circle icon-color-blue mr-2"
    *ngIf="data?.block > 0 || data?.returnedFor == 'Block Report'"></i>
<i title="Variance" class="fa fa-exclamation-triangle icon-color-blue mr-2"
    *ngIf="data?.variance > 0 || data?.returnedFor == 'Variance'"></i>
<i title="Other Misc" class="fa fa-info-circle icon-color-blue mr-2"
    *ngIf="data?.otherMisc > 0 || data?.returnedFor == 'Other/Misc'"></i>
<i title="{{implantLog.value}}" class="fas fa-clipboard-list icon-color-blue mr-2"
    *ngIf="data?.implantLog > 0 || data?.returnedFor == implantLog.value"></i>
<i title="{{invoice.value}}" class="fa fa-file-invoice-dollar icon-color-blue mr-2"
    *ngIf="data?.invoice > 0 || data?.returnedFor == invoice.value"></i>