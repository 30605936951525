export interface CategoryItem {
  name: number | string;
  value: string;
}

export function createCategoryItem(params: Partial<CategoryItem>) {
  return {
    ...params
  } as CategoryItem;
}
