import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersQuery } from '../state/users/users.query';
import { FacilityQuery } from '../../../../shared/state/facilities/facility.query';
import { FacilityService } from '../../../../shared/state/facilities/facility.service'
import { createFacility, Facility } from '../../../../shared/state/facilities/facility.model';
import { Group } from 'src/app/shared/state/groups/group.model';
import { Payer } from 'src/app/shared/state/payers/payer.model';
import { GroupsQuery } from 'src/app/shared/state/groups/groups.query';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { PayersQuery } from 'src/app/shared/state/payers/payers.query';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { PhysicianQuery } from 'src/app/shared/state/physician/physician.query';
import { PhysicianService } from 'src/app/shared/state/physician/physician.service';
import { Physician } from 'src/app/shared/state/physician/physician.model';
import { CustomQueueService } from 'src/app/modules/masters/custom-queue/state/custom-queue.service';
import { CustomQueue } from 'src/app/modules/masters/custom-queue/state/custom-queue.model';
import { CustomQueueQuery } from 'src/app/modules/masters/custom-queue/state/custom-queue.query';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-manage-users-form',
  templateUrl: './manage-users-form.component.html',
  styleUrls: ['./manage-users-form.component.css'],
  providers: [NgxSpinnerService]
})
export class ManageUsersFormComponent implements OnInit {

  config;
  UserMasterForm: FormGroup;
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  filetoUpload;
  formFlag: string = "Add";
  reasonString: string = "Reason";
  typeString: string = "Type";
  istemplateVisible: boolean = false;
  _formData: any;
  submitted = false;
  submitButtonStatus: boolean = false;
  submitButton: string = "Submit";

  usernameList$: Observable<any[]>;

  availableGroups$: Observable<Group[]>;
  activeGroups$: Observable<Group[]>;
  selectedGroupToAdd;
  selectedGroupToRemove;

  availablePayers$: Observable<Payer[]>;
  activePayers$: Observable<Payer[]>;
  selectedPayerToAdd;
  selectedPayerToRemove;

  availableFacilities$: Observable<Facility[]>;
  activeFacilities$: Observable<Facility[]>;
  selectedFacilityToAdd;
  selectedFacilityToRemove;

  availablePhysicians$: Observable<Physician[]>;
  activePhysicians$: Observable<Physician[]>;
  selectedPhysicianToAdd;
  selectedPhysicianToRemove;

  availableCustomQueues$: Observable<CustomQueue[]>;
  activeCustomQueues$: Observable<CustomQueue[]>;
  selectedCustomQueueToAdd;
  selectedCustomQueueToRemove;



  selectedToAdd: any[] = [];
  selectedToRemove: any[] = [];
  dataRoleList: any[] = [];
  dataViewVariance: boolean = false;
  dataViewAuthReview: boolean = false;
  dataViewCodingExpert: boolean = false;
  Username: any;
  model: any;
  FullName: any;
  //selectedToAddGp:
  @Input() set formData(value: any) {
    this.usernameList$ = this.selectUserMaster();
    if (!isNullOrUndefined(value)) {

      this._formData = value;
      this.formFlag = "Edit";
      this.getUserFacilities(this._formData.id);
    }
    else {
      this.formFlag = "Manage";
      this.initForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private userQuery: UsersQuery,
    private groupQuery: GroupsQuery,
    private groupService: GroupsService,
    private payerQuery: PayersQuery,
    private payersService: PayersService,
    private facilityQuery: FacilityQuery,
    private facilityService: FacilityService,
    private physicianQuery: PhysicianQuery,
    private physicianService: PhysicianService,
    private customQueueService: CustomQueueService,
    private customQueueQuery: CustomQueueQuery
  ) { }

  ngOnInit(): void {
    this.config = this.initialiseDropDownConfig('userName');
    this.selectAllInactiveGroups();
    this.selectAllInactiveFacilities();
    this.selectAllInactivePayers();
    this.selectAllInactivePhysicians();
    this.selectAllInactiveCustomQueues();

    this.selectActiveGroup();
    this.selectActiveFacility();
    this.selectActivePayer();
    this.selectActivePhysician();
    this.selectActiveCustomQueue();
  }

  selectAllInactiveGroups() {
    this.availableGroups$ = this.groupQuery.selectAllInactive();
  }

  selectAllInactiveFacilities() {
    this.availableFacilities$ = this.facilityQuery.selectAllInactive();
  }

  selectAllInactivePayers() {
    this.availablePayers$ = this.payerQuery.selectAllInactive();
  }

  selectAllInactivePhysicians() {
    this.availablePhysicians$ = this.physicianQuery.selectAllInactive();
  }

  selectAllInactiveCustomQueues() {
    this.availableCustomQueues$ = this.customQueueQuery.selectAllInactive();
  }

  addRecord() {

    this.submitted = true;
    this.submitButtonStatus = true;
    this.submitButton = "Submitting....";

    this.UserMasterForm.removeControl["userName"];
    this.UserMasterForm.removeControl["email"];

    if (this.groupQuery.getActive() && this.groupQuery.getActive()[0] != undefined && this.groupQuery.getActive().map(g => g.id) != undefined) {
      this.UserMasterForm.controls["addedGroups"].setValue(this.groupQuery.getActive().map(g => { return { GroupId: g.id } }));
    }else{
      this.UserMasterForm.controls["addedGroups"].setValue([]);
    }

    if (this.facilityQuery.getActive() && this.facilityQuery.getActive()[0] != undefined && this.facilityQuery.getActive().map(f => f.id) != undefined) {
      this.UserMasterForm.controls["addedFacilities"].setValue(this.facilityQuery.getActive().map(f => { return { FacilityId: f.id } }));
    }else{
      this.UserMasterForm.controls["addedFacilities"].setValue([]);
    }

    if (this.physicianQuery.getActive() && this.physicianQuery.getActive()[0] != undefined && this.physicianQuery.getActive().map(p => p.id) != undefined) {
      this.UserMasterForm.controls["addedPhysicians"].setValue(this.physicianQuery.getActive().map(p => { return { PhysicianId: p.id } }));
    }else{
      this.UserMasterForm.controls["addedPhysicians"].setValue([]);
    }

    if (this.payerQuery.getActive() && this.payerQuery.getActive()[0] != undefined && this.payerQuery.getActive().map(p => p.id) != undefined) {
    this.UserMasterForm.controls["addedPayers"].setValue(this.payerQuery.getActive().map(p => { return { PayerId: p.id } }));
    }else{
      this.UserMasterForm.controls["addedPayers"].setValue([]);
    }
    if (this.customQueueQuery.getActive() && this.customQueueQuery.getActive()[0] != undefined && this.customQueueQuery.getActive().map(p => p.id) != undefined) {
    this.UserMasterForm.controls["addedCustomQueues"].setValue(this.customQueueQuery.getActive().map(cq => { return { QueueId: cq.id } }));
    }else{
      this.UserMasterForm.controls["addedCustomQueues"].setValue([]);
    }

    if (isNullOrUndefined(this._formData.isEnabled) || this._formData.isEnabled == "") {
      this.UserMasterForm.controls["IsEnabled"].setValue("Inactive");
    }

    this.UserMasterForm.controls["viewVariance"].setValue(this.dataViewVariance);
    this.UserMasterForm.controls["fullName"].value;
    this.UserMasterForm.controls["viewAuthReview"].setValue(this.dataViewAuthReview);
    this.UserMasterForm.controls["viewCodingExpert"].setValue(this.dataViewCodingExpert);
    console.log('addRecord ->', this.UserMasterForm);
    if (this.UserMasterForm.invalid) {
      this.toastr.error('please correct the errors');
      return;
    }

    const formdata = this.UserMasterForm.getRawValue();
    formdata.assignee = this.UserMasterForm.controls["assignee"].value?.id;
    formdata.reportingManager = this.UserMasterForm.controls["reportingManager"].value?.id;
    delete formdata.userName;
    delete formdata.email;
    console.log('request object', formdata);

    this.getApiService.post("Admin/SaveUserFacilities", formdata).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.UserMasterForm.reset();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })
      this.submitButtonStatus = false;
      this.submitButton = "Submit";
    }, (err) => {
      console.log(err);
      this.submitButtonStatus = false;
      this.submitButton = "Submit";
    })

  }

  onCancel() {
    this.onClickClose();
  }

  onClickClose() {
    this.groupService.inActiveAll();
    this.closeEmitter.emit();
    this.facilityService.reset();
    this.payersService.reset();
    this.physicianService.reset();
    this.customQueueService.reset();
  }

  initForm() {
    this.UserMasterForm = this.fb.group({
      UserId: ["", [Validators.required]],
      userName: ["", Validators.required],
      fullName: ["", Validators.required],
      email: ["", Validators.required],
      Role: ["", Validators.required],
      IsEnabled: ["", [Validators.required]],
      viewVariance: ["", Validators.required],
      viewAuthReview: ["", Validators.required],
      viewCodingExpert: ["", Validators.required],
      addedFacilities: ["",],
      addedPhysicians: [""],
      addedPayers: [""],
      addedCustomQueues: ["",],
      assignee: ["", []],
      reportingManager: ["", []],
      addedGroups: ["",]
    })
  }

  editForm(res: any[]) {

    this.UserMasterForm.controls["UserId"].setValue(this._formData.id);
    this.UserMasterForm.controls["userName"].setValue(this._formData.id);
    this.UserMasterForm.controls["fullName"].setValue(this._formData.fullName);
    this.UserMasterForm.controls["email"].setValue(this._formData.email);
    this.UserMasterForm.controls['Role'].setValue(this._formData.role);
    this.UserMasterForm.controls["IsEnabled"].setValue(this._formData.isEnabled);
    this.UserMasterForm.controls["viewVariance"].setValue(this.dataViewVariance);
    this.UserMasterForm.controls["viewAuthReview"].setValue(this.dataViewAuthReview);
    this.UserMasterForm.controls["viewCodingExpert"].setValue(this.dataViewCodingExpert);
    this.UserMasterForm.controls["addedFacilities"].setValue("");
    this.UserMasterForm.controls["addedPhysicians"].setValue("");
    this.UserMasterForm.controls["addedPayers"].setValue("");
    this.UserMasterForm.controls["addedCustomQueues"].setValue("");
    if (res) {
      const users = res.filter(user => user.userName === this._formData.assigneeEmail ||
        user.userName === this._formData.reportingMangerEmail);
      const map = new Map(users.map(u => [u.userName, u]));
      this.UserMasterForm.controls["assignee"].setValue(map.get(this._formData.assigneeEmail));
      this.UserMasterForm.controls["reportingManager"].setValue(map.get(this._formData.reportingMangerEmail));
    }
  }


  getUserFacilities(userID) {
    this.spinner.show();
    console.log('getUserFacilities ', userID);
    this.getApiService.get("Admin/GetUserFacilities?UserId=" + userID).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.FullName = resp.fullName;
        const assignedGroupId = resp.dataAssignedGroup.map(g => g.id);
        this.groupService.setActive(assignedGroupId);
        console.log('resp ', resp);
        this.facilityService.setFacilityGroup(assignedGroupId);
        const failityIds = resp.dataAssignedFacility.map(f => f.id);
        console.log('failityIds', failityIds);
        this.facilityService.setActive(failityIds);

        this.physicianService.setPhysicianFacilitySequentialCall(failityIds);
        this.physicianService.setActive(resp.dataAssignedPhysician?.map(p => p.id));

        this.payersService.setPayerFacilitySequentialCall(failityIds);
        this.payersService.setActive(resp.dataAssignedPayer.map(p => p.id));

        this.customQueueService.setCustomQueueByFilters();
        this.customQueueService.setActive(resp.dataAssignedCustomQueue?.map(q => q.id));

        this.dataRoleList = resp.dataRoleList;
        this.dataViewVariance = (resp.dataViewVariance || false);
        this.dataViewAuthReview = (resp.dataViewAuthReview || false);
        this.dataViewCodingExpert = (resp.dataViewCodingExpert || false);

        if (this.formFlag == "Edit") {
          this.Username = this._formData.userName;
          console.log(this._formData, 'formData');
          // when in edit form user facility is loaded than load the user master based on that initialize the form, better way would be to handle all in observable

          this.usernameList$.subscribe(res => {
            this.initForm();
            this.editForm(res);
            this.spinner.hide();
          }, (e) => {
            this.spinner.hide();
            console.log(e);
          })
        } else {
          this.spinner.hide();
        }
      }

    }, (err) => {
      this.spinner.hide();
      console.log(err);
    })
  }

  //to return observable of usernameList
  selectUserMaster(): Observable<any> {
    return this.userQuery.selectAll();
  }

  selectionChanged(event) {
    if (event.value != undefined) {
      this._formData = event.value;
      console.log('forms data', this._formData);
      console.log(event.value, 'value ');
      this.UserMasterForm.controls["UserId"].setValue(this._formData.id);
      this.UserMasterForm.controls["email"].setValue(this._formData.email);
      this.UserMasterForm.controls["Role"].setValue(this._formData.role);
      this.UserMasterForm.controls["fullName"].setValue(this._formData.fullName);
      this.UserMasterForm.controls["IsEnabled"].setValue(this._formData.isEnabled);
      this.getUserFacilities(this._formData.id);
    }
    else {
      this.initForm();
      //this.selectedFacilities = [];
      this.dataRoleList = [];

      //this.facilities = [];

      this.dataViewVariance = false;
      this.dataViewAuthReview = false;
      this.dataViewCodingExpert = false;
    }

  }

  assign(event$) {
    console.log(event$);
    const target = event$.target || event$.srcElement || event$.currentTarget;
    const idAttr = target.attributes.id;
    const tabName = idAttr?.nodeValue;
    switch (tabName) {
      case 'AddGroup': if (this.selectedGroupToAdd === undefined) {
        this.toastr.warning('please select group to add');
        return;
      }
        this.groupService.active(this.selectedGroupToAdd.map(e => e.id));
        const activeGroupId = this.groupQuery.getActiveId();
        console.log('AddGroup -> activeGroupId -> ', activeGroupId);
        this.facilityService.setFacilityGroup(activeGroupId);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddPayer': if (this.selectedPayerToAdd === undefined) {
        this.toastr.warning('please select group to add');
        return;
      }
        this.payersService.active(this.selectedPayerToAdd.map(e => e.id));
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddFacility': if (this.selectedFacilityToAdd === undefined) {
        this.toastr.warning('please select facility to add');
        return;
      }
        this.facilityService.active(this.selectedFacilityToAdd.map(e => e.id));
        const activeFacilityIds = this.facilityQuery.getActiveId();
        console.log('AddFacility -> activeFacilityIds ->', activeFacilityIds);
        this.physicianService.setPhysicianFacility(activeFacilityIds);
        this.payersService.setPayerFacility(activeFacilityIds);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddPhysician': if (this.selectedPhysicianToAdd === undefined) {
        this.toastr.warning('please select physician to add');
        return;
      }
        this.physicianService.active(this.selectedPhysicianToAdd.map(e => e.id));
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddCustomQueue': if (this.selectedCustomQueueToAdd === undefined) {
        this.toastr.warning('please select queue to add');
        return;
      }
        console.log("selectedCustomQueueToAdd", this.selectedCustomQueueToAdd);
        this.customQueueService.active(this.selectedCustomQueueToAdd.map(e => e.id));
        break;

    }

  }

  assignAll(event$) {
    const target = event$.target || event$.srcElement || event$.currentTarget;
    const idAttr = target.attributes.id;
    const tabName = idAttr.nodeValue;
    switch (tabName) {
      case 'AddAllGroup': const inactiveGroups = this.groupQuery.getAllInactive();
        if (inactiveGroups.length === 0) {
          this.toastr.warning('no group to add');
          return;
        }
        this.groupService.active(inactiveGroups.map(g => g.id));
        const activeGroupId = this.groupQuery.getActiveId();
        console.log('AddAllGroup -> activeGroupId -> ', activeGroupId);
        this.facilityService.setFacilityGroup(activeGroupId);
        this.customQueueService.setCustomQueueByFilters();

        break;
      case 'AddAllPayer': const inactivePayers = this.payerQuery.getAllInactive();
        if (inactivePayers.length === 0) {
          this.toastr.warning('no payer to add');
          return;
        }
        this.payersService.active(inactivePayers.map(g => g.id));
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddAllFacility': const inactiveFacilities = this.facilityQuery.getAllInactive();
        if (inactiveFacilities.length === 0) {
          this.toastr.warning('no facility to add');
          return;
        }
        this.facilityService.active(inactiveFacilities.map(g => g.id));
        const activeFacilityIds = this.facilityQuery.getActiveId();
        console.log('AddAllFacility -> activeFacilityIds ->', activeFacilityIds);
        this.physicianService.setPhysicianFacility(activeFacilityIds);
        this.payersService.setPayerFacility(activeFacilityIds);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddAllPhysician': const inactivePhysicians = this.physicianQuery.getAllInactive();
        if (inactivePhysicians.length === 0) {
          this.toastr.warning('no physician to add');
          return;
        }
        this.physicianService.active(inactivePhysicians.map(g => g.id));
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'AddAllCustomQueue': const inactiveCustomQueues = this.customQueueQuery.getAllInactive();
        if (inactiveCustomQueues.length === 0) {
          this.toastr.warning('no custom queue to add');
          return;
        }
        this.customQueueService.active(inactiveCustomQueues.map(g => g.id));
        break;
    }
  }

  unassign(event$) {
    const target = event$.target || event$.srcElement || event$.currentTarget;
    const idAttr = target.attributes.id;
    const tabName = idAttr.nodeValue;

    switch (tabName) {
      case 'RemoveGroup': if (this.selectedGroupToRemove === undefined) {
        this.toastr.warning('please select group to remove');
        return;
      }
        this.groupService.inActive(this.selectedGroupToRemove.map(e => e.id));
        const activeGroupId = this.groupQuery.getActiveId();
        console.log('RemoveGroup -> activeGroupId -> ', activeGroupId);
        this.facilityService.setFacilityGroup(activeGroupId);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemovePayer': if (this.selectedPayerToRemove === undefined) {
        this.toastr.warning('please select payer to remove');
        return;
      }
        this.payersService.inActive(this.selectedPayerToRemove.map(e => e.id));
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemoveFacility': if (this.selectedFacilityToRemove === undefined) {
        this.toastr.warning('please select facility to remove');
        return;
      }
        this.facilityService.inActive(this.selectedFacilityToRemove.map(e => e.id));
        const activeFacilityIds = this.facilityQuery.getActiveId();
        console.log('RemoveFacility -> activeFacilityIds ->', activeFacilityIds);
        this.physicianService.setPhysicianFacility(activeFacilityIds);
        this.payersService.setPayerFacility(activeFacilityIds);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemovePhysician': if (this.selectedPhysicianToRemove === undefined) {
        this.toastr.warning('please select physcian to remove');
        return;
      }
        this.physicianService.inActive(this.selectedPhysicianToRemove.map(e => e.id));
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemoveCustomQueue': if (this.selectedCustomQueueToRemove === undefined) {
        this.toastr.warning('please select custom queue to remove');
        return;
      }
        this.customQueueService.inActive(this.selectedCustomQueueToRemove.map(e => e.id));
        break;
    }
  }

  unassignAll(event$) {
    const target = event$.target || event$.srcElement || event$.currentTarget;
    const idAttr = target.attributes.id;
    const tabName = idAttr.nodeValue;

    switch (tabName) {
      case 'RemoveAllGroup': if (!this.groupQuery.hasActive()) {
        this.toastr.warning('no group to remove');
        return;
      }
        this.groupService.inActiveAll();
        const activeGroupId = this.groupQuery.getActiveId();
        console.log('RemoveAllGroup -> activeGroupId -> ', activeGroupId);
        this.facilityService.setFacilityGroup(activeGroupId);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemoveAllPayer': if (!this.payerQuery.hasActive()) {
        this.toastr.warning('no payer to remove');
        return;
      }
        this.payersService.inActiveAll();
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemoveAllFacility': if (!this.facilityQuery.hasActive()) {
        this.toastr.warning('no facility to remove');
        return;
      }
        this.facilityService.inActiveAll();
        const activeFacilityIds = this.facilityQuery.getActiveId();
        console.log('RemoveAllFacility -> activeFacilityIds ->', activeFacilityIds);
        this.physicianService.setPhysicianFacility(activeFacilityIds);
        this.payersService.setPayerFacility(activeFacilityIds);
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemoveAllPhysician': if (!this.physicianQuery.hasActive()) {
        this.toastr.warning('no physician to remove');
        return;
      }
        this.physicianService.inActiveAll();
        this.customQueueService.setCustomQueueByFilters();
        break;
      case 'RemoveAllCustomQueue': if (!this.customQueueQuery.hasActive()) {
        this.toastr.warning('no custom queue to remove');
        return;
      }
        this.customQueueService.inActiveAll();
        break;
    }

  }

  selectActiveGroup(): void {
    this.activeGroups$ = this.groupQuery.selectActive();
  }

  selectActiveFacility(): void {
    this.activeFacilities$ = this.facilityQuery.selectActive();
  }

  selectActivePayer(): void {
    this.activePayers$ = this.payerQuery.selectActive();
  }

  selectActivePhysician(): void {
    this.activePhysicians$ = this.physicianQuery.selectActive();
  }

  selectActiveCustomQueue(): void {
    this.activeCustomQueues$ = this.customQueueQuery.selectActive().pipe(map(res => {
      if (res && !Array.isArray(res))
        res = [res];

      return res;
    }));
  }

  changeAssignee(event$) {
    this.UserMasterForm.controls["assignee"].setValue(event$.value);
  }

  changeReportingManager(event$) {
    this.UserMasterForm.controls["reportingManager"].setValue(event$.value);
  }

  updateDataViewVariance() {
    this.dataViewVariance = !this.dataViewVariance;
  }

  updateDataViewAuthReview() {
    this.dataViewAuthReview = !this.dataViewAuthReview;
  }

  updateDataViewCodingExpert() {
    this.dataViewCodingExpert = !this.dataViewCodingExpert;
  }

  initialiseDropDownConfig(drpDownFor: string) {
    return {
      displayKey: drpDownFor, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: drpDownFor, // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }

  get f() { return this.UserMasterForm.controls; }
}
