import { RequestType } from "../../enums/request-type";

export interface CaseRequest {
  id: number | string;
  value: string;
  displayName: string;
  type: RequestType;
  hasIndicator: boolean;
  alreadySubmitted: boolean;
  isHigh$$$orVarRequestStatus: string;
  bulkActionQueueName: string;
}

export function createCaseRequest(params: Partial<CaseRequest>) {
  return {
    ...params
  } as CaseRequest;
}
