import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-current-month-cases',
  templateUrl: './current-month-cases.component.html',
  styleUrls: ['./current-month-cases.component.css']
})
export class CurrentMonthCasesComponent implements OnInit {
  p: number = 1;
  CurrentMonthArry: any[];
  CategoryArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  facilityID: number;
  commandtype: string;

  monthNumber: any;
  yearNumber: any;
  monthName: any;

  currentMonth: any;
  facilityName: any;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.facilityID = this.route.snapshot.params.id;
    this.commandtype = this.route.snapshot.params.type;
    this.monthNumber = new Date().getMonth() + 1;
    this.yearNumber = new Date().getFullYear();
    this.getMonthName();
    this.gettitle(this.commandtype);
    this.commonUtil.setTitle("Biller Station");
    this.getCurrentMonthArry();
    this.getCategoryArry();
  }

  getCurrentMonthArry() {

    let data = {
      "facilityId": this.facilityID,
      "CommandName": this.commandtype,
      "MonthNumber": this.monthNumber,
      "YearNumber": this.yearNumber,
      "pageNum": this.pagenumber,
      "pageSize": this.pageValue
    }

    this.getApiService.post("StatusChange/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.CurrentMonthArry = res.data;
        if (res.data.length > 0) {
          this.facilityName = res.data[0].client + " - ";
          this.totalRows = res.data[0].totalcount;
        }
      }
      else if (res.status == "Error") {
        this.CurrentMonthArry = [];
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.CurrentMonthArry = [];
      this.toastr.error(error, 'Error!');
    });
  }

  getCategoryArry() {

    let data = {
      "facilityId": this.facilityID,
      "MonthNumber": this.monthNumber,
      "YearNumber": this.yearNumber,
    }

    this.getApiService.post("StatusChange/BindCategoryItems", data).subscribe(res => {
      if (res.status == "Success") {
        this.CategoryArry = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.CurrentMonthArry = [];
    this.getCurrentMonthArry();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getCurrentMonthArry();
  }

  getMonthName() {
    var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    this.monthName = month[this.monthNumber - 1];
  }

  gettitle(type) {
    switch (type) {
      case "ThisMonth":
        this.currentMonth = this.monthName + " " + this.yearNumber + " Cases";
        break;
      case "Cancelled":
        this.currentMonth = "Cancelled Cases";
        break;
      case "Completed":
        this.currentMonth = "Completed Cases";
        break;
      case "New":
        this.currentMonth = "< 48 Cases";
        break;
      case "ClientActionTotal":
        this.currentMonth = "Pending Client Cases";
        break;
      case "NSNActionTotal":
        this.currentMonth = "Pending NSN Cases";
        break;
      case "OpReport":
        this.currentMonth = "Op Note Cases";
        break;
      case "Pathology":
        this.currentMonth = "Path Cases";
        break;
      case "Coding":
        this.currentMonth = "Coding Cases";
        break;
      case "Pricing":
        this.currentMonth = "Pricing Cases";
        break;
      case "Client High$$$":
        this.currentMonth = "High$$ Cases";
        break;
      case "Block":
        this.currentMonth = "Block Cases";
        break;
      case "Variance":
        this.currentMonth = "Variance Cases";
        break;
      case "OtherMisc":
        this.currentMonth = "Other/Misc Cases";
        break;
      case "Returned":
        this.currentMonth = "Returned Cases";
        break;
      case "BAQ":
        this.currentMonth = "B.A.Q Cases";
        break;
      case "CodingExpert":
        this.currentMonth = "Coding Expert Cases";
        break;
      case "AuthReview":
        this.currentMonth = "Auth Review Cases";
        break;
      case "NSN High$$$":
        this.currentMonth = "NSN High$$$ Cases";
        break;
      case "ReturnedCodingRFI":
        this.currentMonth = "Coding RFI Cases";
        break;
      case "CodingReturned":
        this.currentMonth = "Returned Coding Cases";
        break;
      case "Overdue":
        this.currentMonth = "Overdue Coding Cases";
        break;
      case "InCoding":
        this.currentMonth = "In Coding Cases";
        break;
      case "Unassigned":
        this.currentMonth = "Unassigned Cases";
        break;
      case "FollowupQueue":
        this.currentMonth = "Claim Follow Up Cases";
        break;
      case "EODQueue":
        this.currentMonth = "EOD Cases";
        break;
      case "ExceptionQueue":
        this.currentMonth = "Exceptions Queue Cases";
        break;

    }
  }

  onChangeSelect(event) {
    this.commandtype = event.target.value;

    if (this.commandtype != "Client High$$$" && this.commandtype != "NSN High$$$" && this.commandtype != "Variance") {
      this.getCurrentMonthArry();
    }
    else {
      this.getGetVarianceHighNsnPatients();
    }
    this.gettitle(event.target.value);
    this.commonUtil.setTitle(this.currentMonth);

  }

  getGetVarianceHighNsnPatients() {

    let data = {
      "facilityId": this.facilityID,
      "CommandName": this.commandtype,
      "MonthNumber": this.monthNumber,
      "YearNumber": this.yearNumber,
      "pageNum": this.pagenumber,
      "pageSize": this.pageValue
    }

    this.getApiService.post("Billing/GetVarianceHighNsnPatients", data).subscribe(res => {
      if (res.status == "Success") {
        this.CurrentMonthArry = res.data;

      }
      else if (res.status == "Error") {
        this.CurrentMonthArry = [];
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getDollarVarianceRequest(id) {
    let url = "";
    if (this.commandtype == "Client High$$$" || this.commandtype == "NSN High$$$") {
      url = "Billing/GetDollarRequestsForGrid";
    }
    else if (this.commandtype == "Variance") {
      url = "Billing/GetVarianceRequestsForGrid";
    }
  }

}
