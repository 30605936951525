import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, Subscription } from 'rxjs';
import { AttachmentQuery } from 'src/app/shared/components/attachment/state/attachment.query';
import { authReview, batchCaseReview, bdosid, blockReport, caseRequest, codingExpert, codingQuery, codingReview, codingReviewed, cpt, facilityId, highApproval, highApprovalInternal, implantLog, inCoding, invoice, NameConstant, operatingReport, otherMisc, pathology, pricing, reviewedCases, variance } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommentsService } from 'src/app/shared/state/comments/comments.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { CaseRequestHelper } from './helper/case-request.helper';
import { CaseRequestCptQuery } from './state/case-request-cpt/case-request-cpt.query';
import { CaseRequestCptService } from './state/case-request-cpt/case-request-cpt.service';
import { QueueInfo } from './state/case-request-info/case-request-info.model';
import { CaseRequestInfoQuery } from './state/case-request-info/case-request-info.query';
import { CaseRequestInfoService } from './state/case-request-info/case-request-info.service';
import { CaseRequestQuery } from './state/case-request/case-request.query';
import { CaseRequestService } from './state/case-request/case-request.service'
import { CurrentCaseRequestQuery } from './state/current-case-request/current-case-request.query';
import { CurrentCaseRequestService } from './state/current-case-request/current-case-request.service';
import { Tab } from './state/tabs/tab.model';
import { TabsQuery } from './state/tabs/tabs.query';
import { TabsService } from './state/tabs/tabs.service';
import { AccessService } from 'src/app/shared/services/access.service';
import { SessionQuery } from 'src/app/modules/login/session/session.query';
@Component({
  selector: 'app-case-request',
  templateUrl: './case-request.component.html',
  styleUrls: ['./case-request.component.css']
})
export class CaseRequestComponent implements OnInit, OnDestroy {
  componentId: NameConstant = caseRequest;
  @Output() closeEmitter = new EventEmitter();
  tabs$: Observable<Tab[]>;
  @ViewChild('tabList') tabListElementRef: ElementRef;
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  operatingReport: NameConstant = operatingReport;
  pathology: NameConstant = pathology;
  pricing: NameConstant = pricing;
  blockReport: NameConstant = blockReport;
  codingQuery: NameConstant = codingQuery;
  otherMisc: NameConstant = otherMisc;
  authReview: NameConstant = authReview;
  clientHighApproval: NameConstant = highApproval;
  nsnHighApproval: NameConstant = highApprovalInternal;
  codingExpert: NameConstant = codingExpert;
  inCoding: NameConstant = inCoding;
  variance: NameConstant = variance;
  invoice: NameConstant = invoice;
  implantLog: NameConstant = implantLog;
  _formData: any;
  activeTabId$: Observable<string | number>;
  activePatient: Patient;
  _commandType:any;
  showConfirmation: boolean = false;
  isClientView$: Observable<boolean>;
  reviewDoneSubject: Subject<any> = new Subject<any>();
  loading: boolean;
  privileges:any;
  @Input() set commandType(value: any) {
    if (value) {
      this._commandType = value;
    }
  }

  @Input() set formData(value: any) {
    if (value) {
     
      this._formData = value;
    }
    console.log("case request component",this._formData)
  }


  showSetBilledForm:boolean =false;
  showUnSetBilledForm:boolean = false;
  showModalForm:boolean=true;
  billAmount: number = 0;

  newdate:any;
  todaydate:any;
  billedInfo: boolean = false;
  hasCurrentRequest$: Observable<boolean>;
  clientRole:boolean;
  clientLiteRole:boolean;
  sub: Subscription;
  primaryCPt: string; //used to pass to undo setbill component;

  constructor(
    private caseRequestService: CaseRequestService,
    private caseRequestQuery: CaseRequestQuery,
    private tabsService: TabsService,
    private tabsQuery:TabsQuery,
    private currentCaseRequestService: CurrentCaseRequestService,
    private currentCaseRequestQuery: CurrentCaseRequestQuery,
    private caseRequestCptService: CaseRequestCptService,
    private caseRequestInfoService: CaseRequestInfoService,
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private patientQuery: PatientQuery,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private caseRequestCptQuery: CaseRequestCptQuery,
    private facilityQuery: FacilityQuery,
    private commentService: CommentsService,
    private attachmentQuery: AttachmentQuery,
    private spinner: NgxSpinnerService,
    private accessServices :AccessService,
    private sessionQuery : SessionQuery,
    ) { }

  ngOnInit(): void {
    this.privileges=this.accessServices.navbar_access
    this.tabs$ = this.tabsService.get();
    this.activeTabId$ = this.tabsQuery.selectActiveId();
    this.activePatient = this.patientQuery.getActive().find(p => true);
    this.hasCurrentRequest$ = this.currentCaseRequestQuery.hasAnyRequest$;
    this.isClientView$ = this.caseRequestInfoQuery.hasClientRole();
    this.clientRole = this.sessionQuery.isClientRole();
    this.clientLiteRole = this.sessionQuery.isClientLiteRole();
    this.getBilled();
    this.sub = this.commentService.get(this._commandType).subscribe();
  }
  ngOnDestroy(){
    this.caseRequestService.reset();
    this.tabsService.reset();
    this.currentCaseRequestService.reset();
    this.caseRequestCptService.reset();
    this.caseRequestInfoService.reset();
    this.commentService.reset();
    this.sub?.unsubscribe();
  }

  //tab methods
  scrollLeft(){
    this.tabListElementRef.nativeElement.scrollLeft -= 150;
  }

  scrollRight(){
    this.tabListElementRef.nativeElement.scrollLeft += 150;
  }
  
  isTabActive(tab: Tab): boolean{
    return this.tabsQuery.hasActive(tab.id);
  }

  tabSelected(tab: Tab){
    this.tabsService.setActive(tab.id);
  }

  removeTab(tab: Tab){
    this.tabsService.remove(tab.id);
    const cr = this.caseRequestQuery.getEntity(tab.id);
    if(!cr.alreadySubmitted)
      this.caseRequestService.toggleActive(tab.id);
  }

  onHeaderClickClose(){
    this.showConfirmation = this.currentCaseRequestQuery.hasAnyUnsavedCurrentRequest();
    if(this.showConfirmation){
      return;
    }
    this.showModalForm = false;
    this.closeEmitter.emit();
  }

  onClickClose(){
    this.showModalForm = false;
    this.closeEmitter.emit();
  }


  onUpload(){
    console.log("On Upload called");
    let frmData = new FormData();
    const attachment = this.attachmentQuery.getAll().find(a => true);
    if(!attachment){
      console.log("attachment is not present in store");
      return;
    }
    this.spinner.show();
    const queueInfo: QueueInfo = this.caseRequestInfoQuery.getQueueInfo();
    const activePatient = this.patientQuery.getActive().find(p => true);
    let activeFacility = this.facilityQuery.getEntity(activePatient.facilityId);
    if(!activeFacility){
      const fac_id = "facility_"+ activePatient.facilityId.toString();
      activeFacility = this.facilityQuery.getAll().find(f => f.id === fac_id);
    }
    const caseRequestInfo = this.caseRequestInfoQuery.getEntity(activePatient.bdosid);


    frmData.append("file", attachment.file, attachment.fileName);
    frmData.append("Filetype", "Attached File"); 
    //frmData.append("txtNotes", "true");     
    frmData.append(bdosid.serverValue, activePatient.bdosid.toString());
    frmData.append(facilityId.id, caseRequestInfo.dataPatient.facilityId?.toString());
    frmData.append(cpt.serverValue, caseRequestInfo?.dataCPTDetails[0]?.cptCode);
    frmData.append(operatingReport.serverValue, queueInfo.operatingReport ?  "true" : "false");
    frmData.append(pathology.value, queueInfo.pathology ? "true" : "false");
    frmData.append(pricing.value, queueInfo.pricing ? "true" : "false");
    frmData.append("clientHigh", queueInfo.highApproval ? "true" : "false");
    frmData.append(blockReport.serverValue, queueInfo.blockReport ? "true" : "false");
    frmData.append(codingQuery.serverValue, queueInfo.codingQuery ? "true" : "false");
    frmData.append(otherMisc.id, queueInfo.otherMisc ? "true" : "false");
    frmData.append(inCoding.serverValue, queueInfo.inCoding ? "true" : "false");
    frmData.append(variance.value, queueInfo.variance ? "true" : "false");
    frmData.append(authReview.serverValue, queueInfo.authReview ? "true" : "false");
    frmData.append(codingExpert.serverValue, queueInfo.codingExpert ? "true" : "false");
    frmData.append("NSNHigh", queueInfo.highApprovalInternal ? "true" : "false");
    
      
    
    this.getApiService.uploadFiles("BillingNew/Upload", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.spinner.hide();
        this.onHeaderClickClose();
        this.toastr.success("Uploaded Successfully!!", "Success");
      }
      else {
        this.spinner.hide();
        this.onHeaderClickClose();
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.onHeaderClickClose();
      this.toastr.error(error, 'Error!');
    });
  }

  getBilled() {
     console.log(this._formData,"this.formdata");
     console.log(this.activePatient,"this.formdata");
     let data = { "BdosID": this.activePatient.bdosid, "facilityId": this.activePatient.facilityId };
 
     this.getApiService.post("BillingNew/GetSetBilled", data).subscribe((resp: any) => {
       console.log(resp," response ");
       if (resp.status == "Success") {
         this.newdate=new Date(resp.data[0].eodDate);
         this.todaydate = new Date();
         this.newdate.setMonth(this.newdate.getMonth()+8);
         if(resp.data.length == 0){
           this.billedInfo = false;
         }else{
           this.billedInfo = true;
         }
         console.log(resp.data,'setbilled',this.billedInfo);
       }
 
     }, (err) => {
 
       this.billedInfo = false;
       console.log(err);
     })
   }

   showUnSetBilled(){
    // this.closeEmitter.emit();
    if (this.showUnSetBilledForm) {
      // this.showModalForm = false;
      this.showUnSetBilledForm = false;
    }
    else {
    //  this.showModalForm = false
      this.showUnSetBilledForm = true;
      this.showModalForm = false
      this.primaryCPt = this.caseRequestInfoQuery.getPrimaryCpt();
    }

  }
  
  showSetBilled(){
    // this.closeEmitter.emit();
    console.log(this.showSetBilledForm)
 //   this.showSetBilledForm = true;
    if (this.showSetBilledForm) {
      // this.showModalForm = false;
      this.showSetBilledForm = false;
    }
    else {
    //  this.showModalForm = false
      this.showSetBilledForm = true;
      this.showModalForm = false
     
    }

  }

  setBilled(type) {

    let frmData = new FormData();
    frmData.append("BdosID", this.activePatient.bdosid.toString());
    frmData.append("facilityId", this.activePatient.facilityId.toString());
    frmData.append("Op", this.currentCaseRequestQuery.hasCurrentRequestType(operatingReport.id));
    frmData.append("Pathology", this.currentCaseRequestQuery.hasCurrentRequestType(pathology.id));
    frmData.append("Pricing", this.currentCaseRequestQuery.hasCurrentRequestType(pricing.id));
    frmData.append("clientHigh", this.currentCaseRequestQuery.hasCurrentRequestType(highApproval.id));
    frmData.append("NSNHigh", this.currentCaseRequestQuery.hasCurrentRequestType(highApprovalInternal.id));
    frmData.append("BlockReport", this.currentCaseRequestQuery.hasCurrentRequestType(blockReport.id));
    frmData.append("codingquery", this.currentCaseRequestQuery.hasCurrentRequestType(codingQuery.id));
    frmData.append("otherMisc", this.currentCaseRequestQuery.hasCurrentRequestType(otherMisc.id));
    frmData.append("InCoding", this.currentCaseRequestQuery.hasCurrentRequestType(inCoding.id));
    frmData.append("Variance", this.currentCaseRequestQuery.hasCurrentRequestType(variance.id));
    frmData.append("AuthReview", this.currentCaseRequestQuery.hasCurrentRequestType(authReview.id));
    frmData.append("CodingExpert", this.currentCaseRequestQuery.hasCurrentRequestType(codingExpert.id));
    frmData.append("SetBilledType", type);
    frmData.append("claimAmount", this.billAmount.toString());
    frmData.append("status", "Billed");
    frmData.append("txtNotes", "");

    const cptArry = this.caseRequestCptQuery.getAllCpt();

    for (let d = 0; d < cptArry.length; d++) {
      frmData.append("DollarRequests[" + d + "].CPT", cptArry[d].cptCode);
      frmData.append("DollarRequests[" + d + "].CPTDesc", cptArry[d].cptDescription);
      frmData.append("DollarRequests[" + d + "].Cost", cptArry[d].rate.toString());
    }

    const currentRequestList = this.currentCaseRequestQuery.getAll();
    for (let e = 0; e < currentRequestList.length ; e++) {
      frmData.append("RFI[" + e + "]._requestType", currentRequestList[e].value);
      frmData.append("RFI[" + e + "]._requestNote", currentRequestList[e].comment);
      frmData.append("RFI[" + e + "]._requestFileName", currentRequestList[e].fileName);
      frmData.append("RFI[" + e + "]._rfiFile", currentRequestList[e].file);
    }

    if(this.currentCaseRequestQuery.hasEntity(highApproval.id) || this.currentCaseRequestQuery.hasEntity(highApprovalInternal.id)){  
      frmData.append("RequestDollarChanges", "true");
      }
      else {
        frmData.append("RequestDollarChanges", "false");
      }

      if (this.currentCaseRequestQuery.hasEntity(variance.id)) {
        frmData.append("RequestVarianceChanges", "true");
      }
      else {
        frmData.append("RequestVarianceChanges", "false");
      }
    const activeFacility = this.facilityQuery.getEntity(this.activePatient.facilityId);
      
    frmData.append("IsHighApprovalInternal", this.currentCaseRequestQuery.hasEntity(highApprovalInternal.id)+"");
    frmData.append("facilityName", activeFacility.name);
    frmData.append("MRN", this.activePatient.mrn);
    frmData.append("RequestDollarStatus", "");
    frmData.append("RequestDollarComments", "");
    frmData.append("RequestVarianceStatus", "");
    frmData.append("RequestVarianceComments", "");

    this.getApiService.uploadFiles("BillingNew/SetBilled", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Billed Successfully!!", "Success");
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  caseCancelled() {
    // let queueInfo = this.dataQueueInfo;
    // queueInfo["BdosID"] = this._formData.bdosid;

    this.getApiService.post("BillingNew/CaseCancelled", {BdosID: this.activePatient.bdosid }).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Case cancelled Successfully!!", "Success");
      //  this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }


  deleteCase() {
    let data = { "BdosID": this.activePatient.bdosid };

    this.getApiService.post("BillingNew/Delete", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Case Deleted Successfully!!", "Success");
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.showSetBilledForm = false;
      // this.toastr.success(event.data.message, 'Success!');
      // this.getImportfeeSchedule();
    }
    else if (event.data.status == "Error") {
      // this.toastr.error(event.data.message, 'Error!');
      // this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.showUnSetBilledForm = false;
    this.showSetBilledForm = false;
 
  }

  confirmNoClick(){
    this.showConfirmation = false;
  }

  confirmYesClick(){
    this.showModalForm = false;
    this.closeEmitter.emit();
  }

  isCaseRequestSelectionAllowed(): boolean{
    return !(this.isCaseRequestCalledFromBatchCaseReview() || this.isCaseRequestCalledFromReviewedCases() || this.isCaseRequestCalledFromCodingReview() || this.isCaseRequestCalledFromCodingReviewed());
  }

  isCaseRequestCalledFromBatchCaseReview(): boolean{
    return this._commandType === batchCaseReview
  }

  isCaseRequestCalledFromReviewedCases(): boolean{
    return this._commandType === reviewedCases
  }

  isCaseRequestCalledFromCodingReview(): boolean {
    return this._commandType === codingReview;
  }

  isCaseRequestCalledFromCodingReviewed(): boolean {
    return this._commandType === codingReviewed;
  }

  reviewDone(){
    this.loading = true;
    this.reviewDoneSubject.next(this._formData);
  }
  reviewCompleted(event$){
    this.loading = false;
    this.onClickClose();
  }
}
