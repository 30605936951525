import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Payer } from './payer.model';
import { PayersStore, PayersState } from './payers.store';

@Injectable({ providedIn: 'root' })
export class PayersQuery extends QueryEntity<PayersState> {

  constructor(protected store: PayersStore) {
    super(store);
  }

  selectAllInactive(): Observable<Payer[]>{
    const all$ = this.selectAll();
    const active$ = this.selectActive();
    const inactive$ = combineLatest([all$, active$]).pipe(
      map(([all, active]) => this.filterInactive(all, active))
    );
    return inactive$;
  }

  getAllInactive(): Payer[]{
    return this.getAll({
      filterBy: e => !this.getActiveId().includes(e.id)
    })
  }

  filterInactive(all: Payer[], active: Payer[]): Payer[]{
   return  all.filter((el) => !this.isActive(el, active))
  }
  
  isActive(group: Payer, active: Payer[]): boolean{
    return active?.some(a => a.id === group.id);
  }

  getPayerByFinancialClass(financialClass: string): Payer{
    return this.getAll()
              .filter(payer => payer.payer_FinancialClass === financialClass)
              .find(p => p.payer_FinancialClass === financialClass);
  }

}
