import { User } from "src/app/modules/admin/manage-users/state/users/user.model";
import { NameConstant } from "src/app/shared/models/name-constant";

export interface Attachment {
  id: number | string;
  file:any;
  callerId?:string
  date?: string;
  fileType?: string;
  user?: User;
  fileDetails?: string;
  comments?: string;
  bdosid?: number;
  fileName?: string;
  reqType?: string;
  userId?: string;
  username?: string;
  attachmentType?: NameConstant;
  satisfyAction?: string;
  primaryCpt?: string;
}

export function createAttachment(params: Partial<Attachment>) {
  return {
    ...params
  } as Attachment;
}
