import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { caseQueueFacilityProductivityReport, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { CaseQueueFacilityReport, createCaseQueueFacilityReport } from '../state/case-queue-facility-report/case-queue-facility-report.model';
import { CaseQueueFacilityReportQuery } from '../state/case-queue-facility-report/case-queue-facility-report.query';
import { CaseQueueFacilityReportService } from '../state/case-queue-facility-report/case-queue-facility-report.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-case-queue-facility-report',
  templateUrl: './case-queue-facility-report.component.html',
  styleUrls: ['./case-queue-facility-report.component.css']
})
export class CaseQueueFacilityReportComponent implements OnInit, OnDestroy {
  componentName: NameConstant = caseQueueFacilityProductivityReport;
  coloumns: ColDef[] = [];
  datasource$: Observable<CaseQueueFacilityReport[]>;
  datasourceSub: Subscription;
  loading: boolean;
  submitted: boolean;
  fromDateError: string;
  toDateError: string;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  selFromDate;
  selToDate;
  activeReportLineId$ : Observable<number | string>;
  userdropdownConfig;
  facilitydropdownConfig;
  userSourceList;
  facilitySourceList;
  selectedUser;
  selectedFacility;
  userSub: Subscription;
  facilitySub: Subscription;

  constructor(
    private caseQueueFacilityReportService: CaseQueueFacilityReportService,
    private caseQueueFacilityReportQuery: CaseQueueFacilityReportQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
    private currencyPipe: CurrencyPipe
  ) { }

  ngOnInit(): void {
    this.initFacilityDropdownConfig();
    this.initUserDropdownConfig();
    this.initColoumns();
    this.initDataSource();
    this.loadUserDropdown();
    this.datasource$ = this.caseQueueFacilityReportQuery.selectAll();
    this.commonUtil.setTitle(this.componentName.value);
    this.activeReportLineId$ = this.caseQueueFacilityReportQuery.selectActiveId();
  }

  ngOnDestroy(){
    if(this.datasourceSub){
      this.datasourceSub.unsubscribe();
    }

    this.caseQueueFacilityReportService.set([]);
  }

  loadUserDropdown(){
    this.userSub = this.apiService.get("ApplicationUser/BindNsnUser").subscribe(res => {
      if(res.status === 'Success'){
        this.userSourceList = res.data;
      }else{
        this.toaster.error("Server Errro", res?.message);
      }
    }, error => {
      this.toaster.error("Unexpected Errro", error?.message);
    });
  }

  userSelectionChanged(event$) {
    console.log(event$.value);
    this.selectedUser = event$.value;
    this.loadFacilityDropdown();
    this.onSearch();
  }

  facilitySelectionChanged(event$){
    console.log(event$.value);
    this.selectedFacility = event$.value;
    this.onSearch();
  }

  loadFacilityDropdown(){
    const userId = this.selectedUser ? (this.selectedUser.id ? this.selectedUser.id : "" ) : "";
    let requestBody = {
      "userId": userId
    };

    this.userSub = this.apiService.post("CreatePatient/BindFacilityDataByUserId", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.facilitySourceList = res.data;
      }else{
        this.toaster.error("Server Errro", res?.message);
      }
    }, error => {
      this.toaster.error("Unexpected Errro", error?.message);
    });
  }

  initDataSource(){
    this.loading = true;
    this.submitted = true;
    const facId = this.selectedFacility ? (this.selectedFacility.id ? this.selectedFacility.id : "0" ) : '0';
    const userId = this.selectedUser ? (this.selectedUser.id ?  this.selectedUser.id : '') : '';
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "pageNum": this.currentPage,
      "pageSize": this.itemsPerPage,
      "facilityId": facId,
      "userId": userId
  };

    this.datasourceSub = this.apiService.post("Reports/CaseQueueReportByFacility", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.loading = false;
        this.submitted = false;
        const entities: CaseQueueFacilityReport[] = res.data.map(r => createCaseQueueFacilityReport({
          facilityId: r.facilityId,
          facilityName: r.facilityName,
          totalcasesadded: r.totalcasesadded,
          totalopencasesbfr: r.totalopencasesbfr,
          totalopencasestilldate: r.totalopencasestilldate,
          totalsetbilledcases: r.totalsetbilledcases,
          totalcount: r.totalcount
        }));

        this.totalRows = entities ? (entities[0] ?  entities[0].totalcount : 0) : 0;
        this.caseQueueFacilityReportService.set(entities);
      }else{
        this.loading = false;
        this.submitted = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.submitted = false;
      this.toaster.error("Unexpected Error!!", err);
    })
  }

  initColoumns(){
    this.coloumns = [...this.coloumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalopencasesbfr", displayName: "Count of Total cases in Queue (From Date)", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalopencasestilldate", displayName: "Count of Total Cases Till Date", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalcasesadded", displayName: "Count of cases added", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalsetbilledcases", displayName: "Count of \'Set Billed\' Cases", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "", displayName: "Action", isDataCol: false, isDataTypeDate: false, isAction: true })];
  }
  
  pageChange(event$){
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));

    if(!this.selFromDate){
      this.fromDateError = "From Date cannot be blank";
      return;
    }else{
      this.fromDateError = '';
    }
    if(!this.selToDate){
      this.toDateError = "To Date cannot be blank";
      return;
    }else{
      this.toDateError = '';
    }

    
    this.initDataSource();
  }

  showReportLineDetail(data){
    console.log(data);
    this.caseQueueFacilityReportService.setActive(String(data.facilityId));
  }

  initFacilityDropdownConfig(){
    this.facilitydropdownConfig = {
      displayKey: "name", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'User Facilities', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }
  initUserDropdownConfig(){
    this.userdropdownConfig = {
      displayKey: "email", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select User', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: "email", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }


  onExport(){
    this.submitted = true;
    console.log("From Date: ", new Date(this.selFromDate));
    console.log("To Date: ", new Date(this.selToDate));

    if(!this.selFromDate){
      this.fromDateError = "From Date cannot be blank";
      return;
    }else{
      this.fromDateError = '';
    }
    if(!this.selToDate){
      this.toDateError = "To Date cannot be blank";
      return;
    }else{
      this.toDateError = '';
    }
    const facId = this.selectedFacility ? (this.selectedFacility.id ? this.selectedFacility.id : "0" ) : '0';
    const userId = this.selectedUser ? (this.selectedUser.id ?  this.selectedUser.id : '') : '';
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "facilityId": facId,
      "userId": userId
  };


    this.datasourceSub = this.apiService.post4File("Reports/DownloadCaseQueueReportByFacility", requestBody).subscribe(res => {
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
			saveAs(blob, this.componentName.value+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }
}
