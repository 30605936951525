<div class="user-login fullheight fullheight-pad">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div>
          <img src="assets/img/hitechhealth-logo.png" alt="" class="img-fluid py-3" />
        </div>
        <div class="">
          <h1 class="mb-4">Login to your account</h1>
        </div>
        <form class="" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <div class="input-group">
              <input
                type="email"
                placeholder="Email Address"
                formControlName="UserName"
                class="form-control form-control-user form-control-color"
                [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }"
              />
              <div
                *ngIf="submitted && f.UserName.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.UserName.errors.required">
                  *Email Address is required
                </div>
                <div *ngIf="f.UserName.errors.email">
                  *Email Address must be valid
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="input-group">
              <input
                type="password"
                placeholder="Password"
                formControlName="Password"
                class="form-control form-control-user form-control-color"
                [ngClass]="{ 'is-invalid': submitted && f.Password.errors }"
              />
              <div
                *ngIf="submitted && f.Password.errors"
                class="invalid-feedback"
              >
                <div *ngIf="f.Password.errors.required">
                  *Password is required
                </div>
                <div *ngIf="f.Password.errors.minlength">
                  *Password must be at least 6 characters
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-md-6">
              <!-- <div class="">
                                <input type="checkbox" value="lsRememberMe" id="rememberMe"> <label
                                    for="rememberMe">Remember me</label>
                            </div> -->
            </div>
            <div class="col-md-6">
              <div class="register-text">
                <a routerLink="/user/forgotPassword">Forgot Password?</a>
              </div>
            </div>
          </div>

          <button
            [attr.disabled]="loginButton == 'Logging' ? true : null"
            id="bt"
            class="btn btn-primary btn-user btn-block login-btn-user"
          >
            {{ loginButton }}
            <span
              *ngIf="loginButton == 'Logging'"
              class="fas fa-spinner fa-spin"
            ></span>
          </button>
        </form>
        <div class="">
          <p>
            Don't have an account?
            <a class="link" routerLink="/user/userregistration">Sign Up Now</a>
          </p>
          <p>
            By signing up, you agree to HITech Health Solutions
            <a href="#" class="text-underline">Terms & Conditions</a> and
            <a href="#" class="text-underline">Privacy Policy</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
