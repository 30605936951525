import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, MultiActiveState } from '@datorama/akita';
import { Group } from './group.model';

export interface GroupsState extends EntityState<Group, number>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'groups' })
export class GroupsStore extends EntityStore<GroupsState> {

  constructor() {
    super(initialState);
  }

}
