import { authReview, blockReport, codingExpert, codingQuery, highApproval, highApprovalInternal, implantLog, inCoding, invoice, NameConstant, operatingReport, otherMisc, overdue, pathology, pendingclient, pricing, returned, unassigned, variance } from "src/app/shared/models/name-constant";

export class BulkActionConfig {

    public static map: Map<string, BulkActionColDef[]> = new Map([ 
        [operatingReport.id, BulkActionConfig.createColDefForGenericRequest()],
        [pathology.id, BulkActionConfig.createColDefForGenericRequest()],
        [invoice.id, BulkActionConfig.createColDefForGenericRequest()],
        [implantLog.id, BulkActionConfig.createColDefForGenericRequest()],
        [blockReport.id, BulkActionConfig.createColDefForGenericRequest()],
        [codingQuery.id, BulkActionConfig.createColDefForGenericRequest()],
        [highApproval.id, BulkActionConfig.createColDefForGenericRequest()],
        [variance.id, BulkActionConfig.createColDefForGenericRequest()],
        [otherMisc.id, BulkActionConfig.createColDefForGenericRequest()],
        [inCoding.id, BulkActionConfig.createColDefForGenericRequest()],
        [highApprovalInternal.id, BulkActionConfig.createColDefForGenericRequest()],
        [codingExpert.id, BulkActionConfig.createColDefForGenericRequest()],
        [authReview.id, BulkActionConfig.createColDefForGenericRequest()],
        [pricing.id, BulkActionConfig.createColDefForGenericRequest()],
        [unassigned.id, BulkActionConfig.createColDefForGenericRequest()],
        [overdue.id, BulkActionConfig.createColDefForGenericRequest()],
        [returned.id, BulkActionConfig.createColDefForGenericRequest()],
    ]);

    public static requestToNameConstanMap: Map<string, NameConstant> = new Map([ 
       [operatingReport.bulkActionClientQueueName, operatingReport],
       [pathology.bulkActionClientQueueName, pathology],
       [codingQuery.bulkActionClientQueueName, codingQuery],
       [pricing.bulkActionClientQueueName, pricing],
       [highApproval.bulkActionClientQueueName, highApproval],
       [blockReport.bulkActionClientQueueName, blockReport],
       [variance.bulkActionClientQueueName, variance],
       [otherMisc.bulkActionClientQueueName, otherMisc],
       [pendingclient.bulkActionClientQueueName, pendingclient],
       [implantLog.bulkActionClientQueueName, implantLog],
       [invoice.bulkActionClientQueueName, invoice]
    ]);

    private static createColDefForGenericRequest(): BulkActionColDef[]{
        return [
            createBulkActionColDef({ colId: "mrn", colDisplayName: "MRN"}),
            createBulkActionColDef({ colId: "name", colDisplayName: "Patient"}),
            createBulkActionColDef({ colId: "dateOfSurgery", colDisplayName: "DoS"}),
            createBulkActionColDef({ colId: "insuranceProvider", colDisplayName: "Insurance"})
        ]
    }
}

export interface BulkActionColDef {
    colId: string;
    colDisplayName: string;
}

export function createBulkActionColDef(params: Partial<BulkActionColDef>) {
    return {
      ...params
    } as BulkActionColDef;
  }