import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ManagementReport } from './management-report.model';
import { ManagementReportStore } from './management-report.store';

@Injectable({ providedIn: 'root' })
export class ManagementReportService {

  constructor(private managementReportStore: ManagementReportStore, private http: HttpClient) {
  }


  set(entities: ManagementReport[]) {
    this.managementReportStore.set(entities);
   }
 
   setActive(id: ID){
     this.managementReportStore.setActive(id);
   }
 
   toggleActive(id: ID){
     this.managementReportStore.toggleActive(id);
   }

  add(managementReport: ManagementReport) {
    this.managementReportStore.add(managementReport);
  }

  update(id, managementReport: Partial<ManagementReport>) {
    this.managementReportStore.update(id, managementReport);
  }

  remove(id: ID) {
    this.managementReportStore.remove(id);
  }

}
