import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;

  constructor() { }

  ngOnInit(): void {

  }
  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  showFormModal() {
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

}


