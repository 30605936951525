import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { CaseRequestInfo } from '../patient-action/case-request/state/case-request-info/case-request-info.model';
import { CaseRequestInfoQuery } from '../patient-action/case-request/state/case-request-info/case-request-info.query';
import { CaseRequestInfoService } from '../patient-action/case-request/state/case-request-info/case-request-info.service';
import { saveAs } from 'file-saver';
import { bulkAction, implantLog, invoice, NameConstant } from 'src/app/shared/models/name-constant';

@Component({
  selector: 'app-patient-info-header',
  templateUrl: './patient-info-header.component.html',
  styleUrls: ['./patient-info-header.component.css']
})
export class PatientInfoHeaderComponent implements OnInit, OnDestroy {

  @Output() closeEmitter = new EventEmitter();
  @Input() callerId: string;
  @Input() bdosid: string | number;
  PatientSummaryDtls: any;
  activePatient: Patient;
  dataQueueInfo: any = {};
  facilityId: number = 0;
  facilityName: string = "";
  patientCPTs: any = [];
  primaryCPT: number;
  smryIsToggled = false;
  caseRequestInfo$ : Observable<CaseRequestInfo>;
  hasImplantLog$: Observable<boolean>;
  hasInvoice$: Observable<boolean>;

  sub: Subscription;
  constructor(
    private getApiService: ApiService,
    private toastr: ToastrService,
    private patientQuery: PatientQuery,
    private caseRequestInfoService: CaseRequestInfoService,
    private caseRequestInfoQuery: CaseRequestInfoQuery
  ) { }

  ngOnInit(): void {

    if(!this.isBulkAction()){
      this.activePatient = this.patientQuery.getActive().find(r => true);
      const patientBdosid = this.bdosid ? this.bdosid : this.activePatient.bdosid;
      this.sub = this.caseRequestInfoService.fetchAndload(patientBdosid, false).subscribe();
    }else{
      this.sub = this.caseRequestInfoService.fetchAndload(this.bdosid, true).subscribe();
    }
    this.caseRequestInfo$ = this.caseRequestInfoQuery.selectFirst();
    this.hasImplantLog$ = this.caseRequestInfoQuery.hasImplantLog();
    this.hasInvoice$ = this.caseRequestInfoQuery.hasInvoice();
  }

  ngOnDestroy(){
    if(this.sub){
      this.sub.unsubscribe();
    }
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getImplantLogFile(){
    this.getFile(implantLog);
  }

  getInvoiceFile(){
    this.getFile(invoice);
  }

  private getFile(name: NameConstant){
    let data = {
      "facilityId": this.activePatient.facilityId,
      "CPT": this.caseRequestInfoQuery.getPrimaryCpt(),
      "fileType": name.value
    }
    this.getApiService.post4File("BillingNew/DownloadPricingFile", data).subscribe(res => {
      saveAs(res, name.value);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  isBulkAction(){
    return this.callerId === bulkAction.id;
  }
}
