import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { ToolsQaImplantLog } from './tools-qa-implant-log.model';

export interface ToolsQaImplantLogState extends EntityState<ToolsQaImplantLog, number | string>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tools-qa-implant-log'})
export class ToolsQaImplantLogStore extends EntityStore<ToolsQaImplantLogState> {

  constructor() {
    super(initialState);
  }

}
