import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KbComponent } from './kb.component';


const routes: Routes = [ {
  path: '',
  children: [
    {
      path: '',
      redirectTo: '/knowledge-base',
      pathMatch: 'full'
    },
    {
      path: 'knowledge-base',
      component: KbComponent,
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class KbRoutingModule { }
