import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { CurrentCaseRequest } from './current-case-request.model';
import { CurrentCaseRequestQuery } from './current-case-request.query';
import { CurrentCaseRequestStore } from './current-case-request.store';

@Injectable({ providedIn: 'root' })
export class CurrentCaseRequestService {

  constructor(private currentCaseRequestStore: CurrentCaseRequestStore, private currentCaseRequestQuery: CurrentCaseRequestQuery) {
  }

  add(currentCaseRequest: CurrentCaseRequest) {
    this.currentCaseRequestStore.add(currentCaseRequest);
  }

  update(id, currentCaseRequest: Partial<CurrentCaseRequest>) {
    this.currentCaseRequestStore.update(id, currentCaseRequest);
  }

  reset(){
    this.currentCaseRequestStore.set([]);
  }

  remove(id: ID) {
    this.currentCaseRequestStore.remove(id);
  }

  removeAllGiven(ids: ID[]) {
    this.currentCaseRequestStore.remove(ids);
  }

  removeAll(){
    const allIds = this.currentCaseRequestQuery.getAll().map(cr => cr.id);
    this.currentCaseRequestStore.remove(allIds);
  }

}
