<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">

            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Daily Reconciliation</h1>
                    <form class="form-fields">
                        <div class="col-12">
                            <select class="custom-select form-control">
                                <option selected="">HITech Speciality Surgery Center</option>
                                <option value="1">option 1</option>
                                <option value="2">option 2</option>
                            </select>
                        </div>
                    </form>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table biggtb" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Patient <br />Checks</th>
                                    <th>Insurance <br>Checks</th>
                                    <th>Check <br>Recoupments </th>
                                    <th>Total <br>Checks</th>
                                    <th>Patient<br>Credit Card</th>
                                    <th>Insurance<br>Credit Card</th>
                                    <th>Care<br>Credit</th>
                                    <th>ACH<br>Payments </th>
                                    <th>Credit Card<br>Recoupements </th>
                                    <th>Total Credit Card<br>Deposit</th>
                                    <th>EFT Insurance <br>Deposit</th>
                                    <th>EFT<br>Recoupments</th>
                                    <th>Total<br>ETF Deposit </th>
                                    <th>Total<br>Cash Deposit </th>
                                    <th>Total Income<br>For DOS</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>$1000</td>
                                    <td>$200</td>
                                    <td>$0.00</td>
                                    <td>$1200</td>
                                    <td>$500</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                    <td>$500</td>
                                    <td>$100</td>
                                    <td>$0</td>
                                    <td>$0</td>
                                    <td>$0</td>

                                    <td>$1800</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>




            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Daily Reconciliation</h1>
                    <a *ngIf="privileges.postingDrClientAdd1" href="javascript:void(0);" (click)='showDRFormModal()' id="openMySidenav5"
                        class="btn btn-primary btn-patient-box openMySidenav5  mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add
                    </a>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Facility</th>
                                    <th>Date</th>
                                    <th>Patient Name</th>
                                    <th>MRN</th>
                                    <th>Case</th>
                                    <th>DOS</th>
                                    <th>Patient Resp</th>
                                    <th>Patient Paid</th>
                                    <th>Mode</th>
                                    <th>Payer Type</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--end of 1-->

            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Daily Reconciliation - Patient Payments
                        for Other DOS (Mail or CC)</h1>
                    <a *ngIf="privileges.postingDrClientAdd2" href="javascript:void(0);" (click)='showDRPPFormModal()' id="openMySidenav2"
                        class="btn btn-primary btn-patient-box openMySidenav2 mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add
                    </a>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Facility</th>
                                    <th>Date</th>
                                    <th>Patient Name</th>
                                    <th>MRN</th>
                                    <th>Case</th>
                                    <th>DOS</th>
                                    <th>Patient Resp</th>
                                    <th>Patient Paid</th>
                                    <th>Mode</th>
                                    <th>Payer Type</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--end of 2-->


            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">EOB Summary - A/R Action Needed</h1>
                    <a *ngIf="privileges.postingDrClientAdd3" href="javascript:void(0);" (click)='showEOBFormModal()' id="openMySidenav3"
                        class="btn btn-primary btn-patient-box openMySidenav3 mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add
                    </a>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Facility</th>
                                    <th>Date</th>
                                    <th>Patient Name</th>
                                    <th>MRN</th>
                                    <th>Case</th>
                                    <th>DOS</th>
                                    <th>Patient Resp</th>
                                    <th>Patient Paid</th>
                                    <th>Mode</th>
                                    <th>Payer Type</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--end of 3-->



            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">EOB Summary - Closed Account Summary
                    </h1>
                    <a *ngIf="privileges.postingDrClientAdd4" href="javascript:void(0);" (click)='showEOBCASFormModal()' id="openMySidenav4"
                        class="btn btn-primary btn-patient-box openMySidenav4 mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add
                    </a>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Facility</th>
                                    <th>Date</th>
                                    <th>Patient Name</th>
                                    <th>MRN</th>
                                    <th>Case</th>
                                    <th>DOS</th>
                                    <th>Patient Resp</th>
                                    <th>Patient Paid</th>
                                    <th>Mode</th>
                                    <th>Payer Type</th>
                                    <th>Comments</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$500</td>
                                    <td>$500</td>
                                    <td>Check</td>
                                    <td>OON Payer</td>
                                    <td>CIGNA</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ABCD</td>
                                    <td>01/22/19</td>
                                    <td>Smith John</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>01/22/19</td>
                                    <td>$700</td>
                                    <td>$700</td>
                                    <td>Check</td>
                                    <td>INN Payer</td>
                                    <td>UHC</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!--end of 3-->


        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-dr-client-form *ngIf=shouldShowDRFormModal (closeEmitter)="onCloseDRFormModal()">
</app-dr-client-form>
<!-- Sidenav-->

<!-- Sidenav-->
<app-drpp-client-form *ngIf=shouldShowDRPPFormModal (closeEmitter)="onCloseDRPPFormModal()">
</app-drpp-client-form>
<!-- Sidenav-->

<!-- Sidenav-->
<app-eob-aran-form *ngIf=shouldShowEOBFormModal (closeEmitter)="onCloseEOBFormModal()">
</app-eob-aran-form>
<!-- Sidenav-->

<!-- Sidenav-->
<app-eob-cas-form *ngIf=shouldShowEOBCASFormModal (closeEmitter)="onCloseEOBCASFormModal()">
</app-eob-cas-form>
<!-- Sidenav-->