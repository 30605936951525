import { Injectable } from '@angular/core';
import { ID, transaction } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from './patient.model';
import { PatientQuery } from './patient.query';
import { PatientStore } from './patient.store';
import { saveAs } from 'file-saver';
import { AccordianConfig } from 'src/app/modules/billing-dashboard/dash-detail/shared/nsn-accordian/nsn-accordian-config';
import { CommandTypeConfig } from 'src/app/modules/billing-dashboard/dash-detail/dash-detail.types';
import { CategoryItem } from '../category-item/category-item.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PatientService {

  constructor(
    private store: PatientStore, 
    private apiService: ApiService, 
    private query: PatientQuery,
    private toastr: ToastrService
    ) {
  }

  set(dataList: Patient[]){
    this.store.set(dataList);
  }
  reset(){
    this.store.set([])
  }

  @transaction()
  resetAndInactivateAll(){
    this.reset();
    this.setActive([]);
  }

  get() {
    let data = { "pageNum": 1, "pageSize": 1000, "SearchValue": "" };
    return this.apiService.post("FacilityGroup/BindGridData", data).pipe(tap(e => {
      const entities: Patient[] = e.data;
      this.store.set(entities);
    }));
  }

  loadPatientByStatus(facilityId: string | number, commandName: string | number,  pageNum: number, pageSize: number, searchValue: string){
    let data = { FacilityQueueId: facilityId,  CommandName: commandName, pageNum: pageNum, pageSize: pageSize, PatientName: searchValue, Isassigned: 1 };
    return this.apiService.post("BillingNew/GetPatientsByStatusNew", data).pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to load patient list', e.message);
        return;
      }
      const entities: Patient[] = e.data;
      this.store.set(entities);
    }));
  }

  loadPatientByConfig(pAccordianConfig: AccordianConfig, commandTypeConfig: CommandTypeConfig, categoryItem: CategoryItem){
    let data = {};
    data[commandTypeConfig.apiBodyId] = pAccordianConfig.facilityId;
    data["CommandName"] = categoryItem.value;
    data["pageNum"] = pAccordianConfig.pageNum;
    data["pageSize"] = pAccordianConfig.pageSize;
    data[commandTypeConfig.apiBodySearchId] = pAccordianConfig.searchValue;
    if(commandTypeConfig.bodyHasAssigned) {
      data["Isassigned"] = 1;
    }
   
    return this.apiService.post(commandTypeConfig.apiName, data).pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to load patient list', e.message);
        return;
      }
      const entities: Patient[] = e.data;
      this.store.set(entities);
    }));
  }

  loadPatientsForAssignCases(facilityId: string | number, commandName: string | number, searchValue: string, pageNum: number, pageSize: number){
    let data = { ClientFacilityId: facilityId,  CommandName: commandName, pageNum: pageNum, pageSize: pageSize, searchValue: searchValue };
    console.log('request object for loadPatientsForAssignCases', data);
    return this.apiService.post("AssignCases/GetPatients", data).pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to load patient list', e.message);
        return;
      }
      const entities: Patient[] = e.data;
      this.store.set(entities);
    }));

  }

  loadCriticalitemsPatientList(facilityId: string | number, status: string, pageNum: number, pageSize: number, searchValue: string){
    let data = { FacilityQueueId: localStorage.getItem("dashboardFacilityId"),  status: status, pageNum: pageNum, pageSize: pageSize, PatientName: searchValue };
    return this.apiService.post("BillingNew/CriticalitemsPatientList", data).pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to load patient list', e.message);
        return;
      }
      const entities: Patient[] = e.data.map(d => {
        let copyEntity = {...d};
        copyEntity.status = status;
        return copyEntity;
      })
      this.store.set(entities);
    }));

  }

  loadCriticalitemsPatientListWithSorting(
    facilityId: string | number, 
    status: string, 
    pageNum: number, 
    pageSize: number, 
    searchValue: string,
    sortType,
    sortOrder
    ){
    let data = { 
      FacilityQueueId: facilityId,  
      status: status, 
      pageNum: pageNum, 
      pageSize: pageSize, 
      PatientName: searchValue,
      OrderBy: sortOrder,
      SortDir: sortType
      };
    return this.apiService.post("BillingNew/CriticalitemsPatientList", data).pipe(tap(e => {
      if(e.status !== 'Success'){
        this.toastr.error('Failed to load patient list', e.message);
        return;
      }
      const entities: Patient[] = e.data.map(d => {
        let copyEntity = {...d};
        copyEntity.status = status;
        return copyEntity;
      })
      this.store.set(entities);
    }));

  }


  add(group: Patient) {
    this.store.add(group);
  }

  setAndActivate(patients: Patient[]){
    this.store.set(patients);
    this.store.setActive(patients.map(p => p.bdosid));
  }

  
  setActive(ids: ID[]){
    this.store.setActive(ids);
  }
 addActive(id: ID) {
    this.store.addActive(id);
  }

  toggleActive(id){
    this.store.toggleActive(id);
  }

  toggleAllActive(){
    this.store.toggleActive(this.query.getActiveId());
  }

  exportPatientData(facilityID, category){

    let data  = {
      "FacilityQueueId": facilityID,
      "CommandName": category
    }
    this.apiService.post4File("BillingNew/ExportPatientData", data).subscribe(res => {
      saveAs(res, category.toUpperCase() + "_" + new Date().toISOString() + '.xlsx');
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  inActiveById(id: ID){
    const activeId = this.query.getActiveId();
    const newActiveIds = activeId.filter(i => i.toString() !== id.toString());
    this.store.setActive(newActiveIds);
  }

  @transaction()
  updateQueueName(id: ID, queueName: string){
    this.store.update({ queueName: queueName});
  }
}
