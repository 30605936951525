import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { async } from '@angular/core/testing';
import { EventEmitter } from 'events';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { batchCaseReview, criticalItem, implantLog, invoice, NameConstant } from 'src/app/shared/models/name-constant';
import { CommonService } from 'src/app/shared/services/common.service';
import { CategoryItem, createCategoryItem } from 'src/app/shared/state/category-item/category-item.model';
import { CategoryItemQuery } from 'src/app/shared/state/category-item/category-item.query';
import { CategoryItemService } from 'src/app/shared/state/category-item/category-item.service';
import { createFacility, Facility } from 'src/app/shared/state/facilities/facility.model';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { DashDetailHelper } from '../helpers/dash-detail.helper';
import { NsnAccordianComponent } from '../shared/nsn-accordian/nsn-accordian.component';

@Component({
  selector: 'app-critical-item',
  templateUrl: './critical-item.component.html',
  styleUrls: ['./critical-item.component.css']
})
export class CriticalItemComponent implements OnInit {
  componentId: string = criticalItem.id;
  defaultCategoryItemValue = '8';
  activeFacility: Facility = createFacility({id: "0", name: 'All Facilities'});
  // activeFacility:any;
  activeCategoryItem: CategoryItem = new DashDetailHelper().getDefaultCategoryItem();
  
  tblVsbl: boolean = false;
  categoryItem$: Observable<CategoryItem[]>;
  facilities$: Observable<Facility[]>;
  searchValue: string;
  activeSelection$: Observable<string>;
  patients$: Observable<Patient[]>;
  hasActivePatients$: Observable<boolean>;
  @ViewChild(NsnAccordianComponent)
  private nsnAccordianComponent: NsnAccordianComponent;
  shouldShowBulkActionModal: boolean = false;

  facility_id; // used to open facility detail component only
  p;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: number = 1;
  shouldShowFormModal: boolean = false;
  shouldShowCaseRequestFormModal: boolean = false;
  shouldShowReturnActionFormModal: boolean = false;
  shouldShowDetailModal: boolean = false;
  shouldShowQAOPFormModal: boolean = false;
  shouldShowQAICFormModal: boolean = false;
  shouldShowQAATFormModal: boolean = false;
  shouldShowToolsModal: boolean = false;
  shouldShowReviewCasesModal: boolean = false;
  formData: any = null;

  sortType;
  sortOrder;

  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  reviewCases: NameConstant = batchCaseReview;
  constructor(
    private patientService: PatientService,
    private patientQuery: PatientQuery,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private facilityService: FacilityService,
    private facilityQuery: FacilityQuery,
    private categoryItemService: CategoryItemService,
    private categoryItemQuery: CategoryItemQuery,
    private selectionService: SelectionService,
    private selectionQuery: SelectionQuery
    ) { }

  ngOnInit(): void {
    this.facilities$ = this.facilityQuery.selectAll();
    this.findFacilityData()
    this.commonUtil.setTitle("Biller Station");
    
    this.facilityService.fetchAndStoreFacilityForPatientWithAllOption().subscribe();
    this.categoryItemService.loadCategoryItemByFacilityAndCategory(this.activeFacility.id, this.activeCategoryItem.value);
    // this.categoryItemService.loadCategoryItemByFacilityAndCategory(this.activeFacility, this.activeCategoryItem.value);
    this.selectionService.changeSelection(this.activeCategoryItem.value);

    this.categoryItemService.active(this.activeCategoryItem.name);
    // this.facilityService.setActive([this.activeFacility]);
    this.facilityService.setActive([this.activeFacility.id]);

    
    this.categoryItem$ = this.categoryItemQuery.selectAll();
    this.activeSelection$ = this.selectionQuery.select().pipe(map(res => {
      if(res.value !==this.defaultCategoryItemValue){
       this.loadPatient();
      }
      return res.value;
    }));
    this.patients$ = this.patientQuery.selectAll();
    this.hasActivePatients$ = this.patientQuery.selectActiveId().pipe(map(res => res && res.length > 0 ));
  }
  // checkAndsetDefaultFacility(){
  //   let localFacility = localStorage.getItem("dashboardFacilityId")
  //   console.log(localFacility,"local")
  //   if(localFacility != "0"){
  //     console.log("consoling here 105",localFacility)
  //     this.activeFacility = localFacility;
  //   } else{
  //     this.activeFacility = "0"
  //   }
  // }
  findFacilityData(){
    let facility=localStorage.getItem("dashboardFacilityId")
    this.facilities$.subscribe(val=>{
      val.forEach(element=>{
        if(facility == element.id){
          this.activeFacility =element
        }
      })
    })

  }

 private loadPatient(){
    this.patientService.reset();
    this.patientService.loadCriticalitemsPatientList(this.activeFacility.id, this.activeCategoryItem.value, this.pagenumber, this.pageValue, this.searchValue)
    // this.patientService.loadCriticalitemsPatientList(this.activeFacility, this.activeCategoryItem.value, this.pagenumber, this.pageValue, this.searchValue)
    .subscribe(res => this.totalRows = res.data[0]?.totalcount);
  }
  onFacilityChange(){
    this.changeFacility()
    this.findFacilityData();
    // this.facilityService.setActive([this.activeFacility]);
    this.facilityService.setActive([this.activeFacility.id]);
    this.loadPatient();
  }
  changeFacility(){
    console.log(this.facilities$,"128")
    console.log(this.activeFacility.id,"acti 120")
    let temp = this.activeFacility.id
    localStorage.setItem("dashboardFacilityId",temp)
  }
 

  onCategoryItemChange(){
    this.pagenumber =1;
    this.pageValue = 10;
    this.p = undefined;
    this.selectionService.changeSelection(this.activeCategoryItem.value);
    this.categoryItemService.active(this.activeCategoryItem.name);
    this.loadPatient();
  }

  searchPatient(){
    this.pagenumber =1;
    this.pageValue = 10;
    this.p = undefined;
    const selection = this.selectionQuery.getValue().value;
    if(selection === this.defaultCategoryItemValue){
      this.nsnAccordianComponent.searchGrid(this.searchValue);
    }else{
      this.loadPatient();
    }
  }

  exportData(){
    const id = this.facilityQuery.getActiveId();
    const categeoryItem = this.categoryItemQuery.getActive();
    console.log('categeoryItem', categeoryItem);
    this.patientService.exportPatientData(id[0], "CriticalItems");
  }

  sortList(orderby, sortby) {
    this.sortType = sortby;
    this.sortOrder = orderby;
    this.patientService.reset();
    this.patientService.loadCriticalitemsPatientListWithSorting(
      this.activeFacility.id, this.activeCategoryItem.value, 
      this.pagenumber, this.pageValue, this.searchValue,
      this.sortType,
      this.sortOrder
      )
      // this.activeFacility, this.activeCategoryItem.value, 
      // this.pagenumber, this.pageValue, this.searchValue,
      // this.sortType,
      // this.sortOrder
      // )
    .subscribe(res => this.totalRows = res.data[0]?.totalcount);
  }

  toggle(d) {
    if(d.openState){
      d.openState = false;
    }else{
      d.openState = true;
    }
  }

  compareFacility(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.id === val2.id;
  }

  compareCategoryItem(val1, val2){
    if(!val1 || !val2)
    return false;
    
  return val1.name === val2.name;
  }

  

  showCaseRequestFormModal(data) {
    console.log('showCaseRequestFormModal', data);
    this.formData = data;
    this.patientService.setActive([data.bdosid]);
    this.formData = data;
    this.shouldShowCaseRequestFormModal = !this.shouldShowCaseRequestFormModal;
  }


  showFormModal(data) {
    
    this.formData = data;
    this.patientService.setActive([data.bdosid]);
    
    const activeCategory = this.categoryItemQuery.getActive();

    if(activeCategory && activeCategory.value === 'returnedclaim'){
      this.shouldShowReturnActionFormModal = true;
      return;
    }
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }
  showQAOPFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAOPFormModal) {
      this.shouldShowQAOPFormModal = false;
    }
    else {
      this.shouldShowQAOPFormModal = true;
    }
  }

  showQAICFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAICFormModal) {
      this.shouldShowQAICFormModal = false;
    }
    else {
      this.shouldShowQAICFormModal = true;
    }
  }

  showQAATFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAATFormModal) {
      this.shouldShowQAATFormModal = false;
    }
    else {
      this.shouldShowQAATFormModal = true;
    }
  }

  showToolsFormModal(data) {
    this.formData = data;
    this.patientService.setActive([data.bdosid]);
    if (this.shouldShowToolsModal) {
      this.shouldShowToolsModal = false;
    }
    else {
      this.shouldShowToolsModal = true;
    }
  }

  showDetailModal(data) {
    console.log(data);
    this.formData = data;

    this.facility_id = data.facilityId;
    if (this.shouldShowDetailModal) {
      this.shouldShowDetailModal = false;
    }
    else {
      this.shouldShowDetailModal = true;
    }
  }

  onCloseFormModal() {
    console.log("onCloseFormModal");
    this.patientService.toggleAllActive();
    this.shouldShowFormModal = false;
    this.shouldShowCaseRequestFormModal = false;
    this.shouldShowQAOPFormModal = false;
    this.shouldShowQAICFormModal = false;
    this.shouldShowQAATFormModal = false;
    this.shouldShowToolsModal = false;
    this.shouldShowDetailModal = false;
    this.shouldShowReturnActionFormModal = false;
    this.shouldShowBulkActionModal = false;
    this.shouldShowReviewCasesModal = false;
    location.reload(); // this hacky way to load the page not doing async way is not because i don't know (sachin) but because balagee wants this to be done asap mode

   }

  pageChange(event) {
    this.pagenumber = event;
    this.loadPatient();
  }

  onSubmitSuccess(){
    this.shouldShowFormModal = false;
    this.shouldShowCaseRequestFormModal = false;
    this.loadPatient();
  }

  /* Bulk Action Methods */
  startBulkAction(){
    //this.shouldShowBulkActionModal = this.shouldShowBulkActionModal ? false : true;
  }

  startReviewCases(){
    this.shouldShowReviewCasesModal = this.shouldShowReviewCasesModal ? false : true;
  }

  selectRow(data){
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    this.patientService.toggleActive(bdosid);
  }
  isRowActive(data): boolean{
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    return this.patientQuery.hasActive(bdosid);
  }

}
