<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{ formFlag }} Custom Field</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <form [formGroup]="CustomFieldMasterForm" (ngSubmit)="onSubmit()" class="pb-3 form-fields">
            <div class="row hr">
                <div class="form-group remove-botttom-space col-12">
                    <label for="userName" class="required">Field Name</label>
                    <input type="text" class="form-control" formControlName="Name" placeholder="Name"
                    required [ngClass]="{'is-invalid': submitted && f.Name.errors }">
                <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                    <p>*mandatory</p>
                </div>
                   
                </div>
            </div>
            
            <hr>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="submit" class="btn btn-primary btn-patient-box" [disabled]="!CustomFieldMasterForm.valid">Submit</button>
                <!-- (click)="onSubmit()" -->
            </div>
        </form>
    </div>
    <!-- </div> -->
    <!-- Sidenav-->
    </div>