<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="!(activeReportLineId$ | async)">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="top-action-container">
                        <div class="pad-right-ten">
                            <label for="fromDate" class="pad-right-ten">From Date:</label>
                            <input type="date" id="fromDate" name="fromDate" [(ngModel)]="selFromDate">
                            <span *ngIf="submitted && !selFromDate" class="invalid-feedback">
                                From Date is mandatory!!
                            </span>
                            
                        </div>
                        <div class="pad-right-ten">
                            <label for="toDate" class="pad-right-ten">To Date:</label>
                            <input type="date" id="toDate" name="toDate" [(ngModel)]="selToDate">
                            <span *ngIf="submitted && !selToDate" class="invalid-feedback">
                                To Date is mandatory!!
                            </span>

                        </div>
                        <div class="pad-right-ten" style="width: 20%;">
                            <ngx-select-dropdown 
                            [config]="userdropdownConfig" 
                            [options]="userSourceList"
                            (change)="userSelectionChanged($event)"
                            autoClearSearch="true"
                            [multiple]="false"
                            [(ngModel)]="selectedUser"
                            >
                            </ngx-select-dropdown>

                        </div>
                        <div class="pad-right-ten" style="width: 25%;">
                            <ngx-select-dropdown 
                            [config]="facilitydropdownConfig" 
                            [options]="facilitySourceList"
                            (change)="facilitySelectionChanged($event)"
                            autoClearSearch="true"
                            [multiple]="false"
                            [(ngModel)]="selectedFacility"
                            >
                            </ngx-select-dropdown>
                        </div>
                        <div class="pad-right-ten">
                            
                            <button type="button" (click)="onSearch()" style="width: 120px;"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav"><i class="fa fa-search fa-fw"></i>Search</button>
                   
                        </div>
                    </div>
                    <div class="row" style="float: right;">
                        <div class="col">
                            <button type="button" (click)="onExport()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav">Export</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'totalopencasesbfr' || col?.name === 'totalopencasestilldate' || col?.name === 'totalcasesadded' || col?.name === 'totalsetbilledcases' }"
                                    >{{col.displayName}}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'totalopencasesbfr' || col?.name === 'totalopencasestilldate' || col?.name === 'totalcasesadded' || col?.name === 'totalsetbilledcases' }"
                                    >{{col.displayName}}</th>
                                </tr>
                            </tfoot>
                            <ng-container *ngIf="datasource$ | async as datasource">
                                <tr *ngFor="let data of datasource | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;"
                                    style="color: #333;font-weight: 400;"
                                >
                                    <td>{{(i+1) + ((currentPage-1)*itemsPerPage)}}</td>
                                    <td>{{data.facilityName}}</td>
                                    <td class="txt-align-right">{{data.totalopencasesbfr | number}}</td>
                                    <td class="txt-align-right">{{data.totalopencasestilldate |  number}}</td>
                                    <td class="txt-align-right">{{data.totalcasesadded |  number}}</td>
                                    <td class="txt-align-right">{{data.totalsetbilledcases |  number}}</td>
                                    <td>
                                        <a href="javascript:void(0)"  (click)="showReportLineDetail(data)"
                                                class="icon-color-darkgreen openMySidenav mr-2"><i
                                                    title="Patient View" class="fa fa-eye"></i></a>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <pagination-controls class="float-right"
                            (pageChange)="pageChange($event); currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-case-queue-facility-report-line *ngIf="activeReportLineId$ | async" [selFromDate]="selFromDate" [selToDate]="selToDate">
</app-case-queue-facility-report-line>