import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { httpGetRequestMethod, httpPOSTRequestMethod, NameConstant } from '../../models/name-constant';
import { ApiService } from '../../services/api.service';
import { createNsnData, NsnData, NSNDropdownConfig } from './nsn-dropdown.component.types';

@Component({
  selector: 'app-nsn-dropdown',
  templateUrl: './nsn-dropdown.component.html',
  styleUrls: ['./nsn-dropdown.component.css']
})
export class NsnDropdownComponent implements OnInit, OnDestroy {
  @Input() callerId: NameConstant;
  @Input() config: NSNDropdownConfig;
  @Input() name: string;
  @Input() isMultiple: boolean;
  @Input() selectedKey: string;
  selectedModel: NsnData;
  @Input() selectedKeyEvent: Observable<string>;
  private selectedKeyEventSub: Subscription;
  @Output() onChangeEmitter = new EventEmitter();
  @Output() onLoad = new EventEmitter();
  dropdownConfig;
  sourceList$: Observable<NsnData[]>;
  sourceList: NsnData[];

  selectedSub: Subscription;
  constructor(
    private apiService: ApiService, 
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.initDropdownConfig();
    this.sourceList$ = this.initSource();
    this.selectedSub = this.sourceList$.subscribe(res => {
      this.sourceList = res;
      this.onLoad.emit(res);
      this.selectedModel = res.find(r => r.value === this.selectedKey );
    });

    this.selectedKeyEventSub = this.selectedKeyEvent?.subscribe( 
      res => {
        this.selectedModel = this.sourceList?.find(r => r.value === res );
      });
     
  }

  ngOnDestroy(){
    if(this.selectedSub){
      this.selectedSub.unsubscribe();
    }

    if(this.selectedKeyEventSub){
      this.selectedKeyEventSub.unsubscribe();
    }
  }
  selectionChanged(event$) {
    this.onChangeEmitter.emit(event$.value);
  }

  initSource(): Observable<NsnData[]>{
    if(this.config.httpRequestType === httpGetRequestMethod){
      return this.apiService.get(this.config.sourceApi).pipe(map( inres => {
        let res;
        //if(this.config.sourceApi === "InquiryStatus/BindInquiryStatusDropdown"){
        if(false){  
        let coding = { ...inres.data[0]};
          coding.id = 'CodingExpert';
          coding.inquirystatus = 'Coding Expert';
          coding.priority = 2000;

          let incoding = { ...inres.data[0]};
          incoding.inquirystatus = 'InCoding';
          incoding.id = 'InCoding';
          incoding.priority = 1000;
          res = [ coding, incoding, ...inres.data ];
          res = res.sort((a, b) => (a.priority > b.priority) ? 1 : -1);
        }else{
          res = inres.data;
        }
        console.log('res', res);
        return res.map(r => createNsnData({ id: r[this.config.key], value: r[this.config.value], obj: r }))
      }));
    }else if (this.config.httpRequestType === httpPOSTRequestMethod){
      return this.apiService.post(this.config.sourceApi, this.config.sourceApiBody)
        .pipe(map( res => {
          console.log('res', res);
          return res.data.map(r => createNsnData({ id: r[this.config.key], value: r[this.config.value], obj: r }))
        }));
    }else{
      if(!this.config.httpRequestType){
        this.toastr.error('httpRequestType in config is not configured in component', this.callerId.id);
      }else{
        this.toastr.error('httpRequestType in config is not configured, it supports only get', this.config.httpRequestType.id);
      }
      return of();
    }
  }
  initDropdownConfig(){
    console.log("this.config", this.config);
    this.dropdownConfig = {
      displayKey: "value", //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: this.config.height ? this.config.height : 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: "value", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }
}
