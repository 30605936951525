import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.css']
})
export class AuditLogComponent implements OnInit {
  auditList: any = [];
  fromDate;
  toDate;

  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  p: number = 1;
  FacilityList: any = [];
  facilityName: string = "";
  facilityID: number = 0;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Audit Log");
    this.facilityID = this.route.snapshot.params.id;

    let curDate = new Date();

    console.log(curDate.getTimezoneOffset());
    let strdt = new Date(curDate.getTime() - (7 * 24 * 60 * 60 * 1000));
    this.fromDate = new Date((strdt.getMonth() + 1) + "/" + strdt.getDate() + "/" + strdt.getFullYear());
    this.toDate = new Date((curDate.getMonth() + 1) + "/" + curDate.getDate() + "/" + curDate.getFullYear());

    this.getFacilityInfo();
  }

  getFacilityInfo() {
    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
        if (res.data[0] != undefined) {
          if (this.facilityID == 0) {
            this.facilityName = res.data[0].name;
            this.facilityID = res.data[0].id;
          }
          else {
            let val = this.FacilityList.find(x => x.id == this.facilityID);
            this.facilityName = val.name;
          }
        }
        this.getData();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getData() {
    var fDate = new Date(this.fromDate);
    var tDate = new Date(this.toDate);
    var fromDt = fDate.getFullYear() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getDate();
    var toDt = tDate.getFullYear() + "/" + (tDate.getMonth() + 1) + "/" + tDate.getDate();

    let url = "ExceptionQue/BindAuditGridData";
    let data = {
      "FacilityName": this.facilityName,
      "txtBeginDate": fromDt,
      "txtEndDate": toDt,
      "pageNum": this.pagenumber,
      "pageSize": this.pageValue
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.auditList = res.data;
        if (res.data.length > 0) {
          this.totalRows = res.data[0].totalcount;
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onDateChange(newDate: Date) {
    this.getData();
  }

  onFacilityChange() {
    let val = this.FacilityList.find(x => x.name == this.facilityName);
    this.facilityID = val.id;
    this.pagenumber = 1;
    this.getData();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getData();
  }

}
