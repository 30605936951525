<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{formFlag}} EOD Maintenance</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="pb-3 form-fields">
            <form action="" [formGroup]="EODMaintenanceForm">
                <div class="row">
                    <div class="form-group col-12 frm-margin-btm">
                        <label for="inputFirstName" class="required">{{reasonString}}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="reason"
                                (change)="elmBlankVal('reason')" placeholder="{{reasonString}}"
                                [ngClass]="{'is-invalid': submitted && f.reason.errors }">
                            <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
                                <div *ngIf="f.reason.errors.required">*mandatory</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm" *ngIf="istemplateVisible == true">
                        <label for="inputFirstName" class="required">Message</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="message"
                                (change)="elmBlankVal('message')" placeholder="Message"
                                [ngClass]="{'is-invalid': submitted && f.message.errors }">

                        </div>
                        <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                            <div *ngIf="f.message.errors.required">*mandatory</div>
                        </div>
                    </div>
                    <div class="form-group col-12 frm-margin-btm">
                        <label for="inputFirstName" class="required">{{typeString}}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="type"
                                (change)="elmBlankVal('type')" placeholder="{{typeString}}"
                                [ngClass]="{'is-invalid': submitted && f.type.errors }">
                            <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                <div *ngIf="f.type.errors.required">*mandatory</div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="formFlag == 'Add'" class="form-group col-12 frm-margin-btm label-lght">
                        <p>Please select your Type</p>

                        <input type="radio" (change)="modeSelected($event)" formControlName="mode" value="Hold">
                        <label for="Hold">Hold</label>
                        <input type="radio" (change)="modeSelected($event)" formControlName="mode" value="Reject">
                        <label for="Reject">Reject</label>
                        <input type="radio" (change)="modeSelected($event)" formControlName="mode" value="Followup">
                        <label for="Followup">FollowUp</label><br>
                        <div *ngIf="submitted && f.mode.errors" class="invalid-feedback">
                            <div *ngIf="f.mode.errors.required">*mandatory</div>
                        </div>
                    </div>

                    <div *ngIf="formFlag == 'Edit'" class="form-group col-12 frm-margin-btm label-lght">
                        <p>Type :
                            <label *ngIf="_formData.tableType == 'Hold'">Hold</label>
                            <label *ngIf="_formData.tableType == 'Reject'">Reject</label>
                            <label *ngIf="_formData.tableType == 'Followup'">FollowUp</label>
                        </p>
                    </div>
                </div>
            </form>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav ">Cancel</button>
                <button type="button" (click)="onSubmit()" class="btn btn-primary btn-patient-box">Submit</button>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->
</div>