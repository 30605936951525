import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { facilityReportLine, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { FacilityReport } from '../../state/facility-report/facility-report.model';
import { FacilityReportQuery } from '../../state/facility-report/facility-report.query';
import { FacilityReportService } from '../../state/facility-report/facility-report.service';
import { createFacilityReportLine, FacilityReportLine } from './facility-report-line';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-facility-report-line',
  templateUrl: './facility-report-line.component.html',
  styleUrls: ['./facility-report-line.component.css']
})
export class FacilityReportLineComponent implements OnInit {
  @Input() selFromDate;
  @Input() selToDate;
  componentName: NameConstant = facilityReportLine;
  coloumns: ColDef[] = [];
  activeEntity: FacilityReport;
  activeEntityId$: Observable<string>;

  datasource$: Observable<FacilityReportLine[]>;
  loading: boolean;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  destroy$ = new Subject();

  constructor(
    private facilityReportService: FacilityReportService,
    private facilityReportQuery: FacilityReportQuery,
    private apiService: ApiService,
    private commonUtil: CommonService,
    private toaster: ToastrService,  
  ) { }

  ngOnInit(): void {
    this.initColoumns();
    this.activeEntityId$ = this.facilityReportQuery.selectActiveId().pipe(map(res => res?.toString()));
    this.commonUtil.setTitle(this.componentName.value);
    this.activeEntityId$.subscribe(res => {
      if(res){
        this.initDataSource();
      }
    });
  }

  initColoumns() {
    this.coloumns = [...this.coloumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.coloumns = [...this.coloumns, createColDef({ name: "mrn", displayName: "MRN", isDataCol: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "importDate", displayName: "Loaded to Navigator", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "pendingClient", displayName: "Time in Pending Client Status (HH:MM)", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "inCoding", displayName: "Time in Coding Status  (HH:MM)", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "otherStat", displayName: "Time in all other Status  (HH:MM)", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "setBilledDate", displayName: "Set Billed Date", isDataTypeDate: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalCharges", displayName: "Total Charges Billed", isDataTypeDate: true })];

  }

  pageChange(event$) {
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch() {
    this.initDataSource();
  }

  initDataSource(){
    this.loading = true;
    this.activeEntity = this.facilityReportQuery.getActive();
    const requestBody = {
      "fromDate": this.selFromDate,
      "toDate": this.selToDate,
      "pageNum": this.currentPage,
      "pageSize": this.itemsPerPage,
      "facilityId": this.activeEntity.id
  }
    this.datasource$ = this.apiService.post("Reports/FacilityProductivityByFacilityId", requestBody)
    .pipe(map(res => {
      if(res.status === 'Success'){
        this.loading = false;
        const entities: FacilityReportLine[] = res.data.map(r => createFacilityReportLine({
          facilityName: r.facilityName,
          mrn: r.mrn,
          importDate: r.importDate,
          setBilledDate: r.setBilledDate,
          totalcount: r.totalcount,
          inCoding: r.inCoding,
          otherStat: r.otherStat,
          pendingClient: r.pendingClient,
          totalCharges: r.totalCharges,
          workType: r.workType
        }));
        return entities;
      }else{
        this.loading = false;
        this.toaster.error("Unexpected Error!!", res?.message);
      }
    }, err => {
      this.loading = false;
      this.toaster.error("Unexpected Error!!", err);
    }));

    this.datasource$.pipe(takeUntil(this.destroy$)).subscribe( entities=> {
      this.totalRows = entities.find(e => true)?.totalcount;
      console.log("totalRows", this.totalRows);
    })
  }

}
