import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import {CommonModule, CurrencyPipe, DecimalPipe} from '@angular/common'
import { ActivatedRoute,Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as fileSaver from 'file-saver';
import { disableDebugTools } from '@angular/platform-browser';
import { CaseRequestInfoQuery } from '../case-request/state/case-request-info/case-request-info.query';
import { trim } from 'cypress/types/lodash';

@Component({
  selector: 'app-claim-type-action',
  templateUrl: './claim-type-action.component.html',
  styleUrls: ['./claim-type-action.component.css']
})
export class ClaimTypeActionComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  @Output() AddedEmitter = new EventEmitter();
  @Input() claimTypeAction: string;
  
  
  
  _formData:any;
  amount:number = 0.0;
    formattedAmount: string = '';
  claimValue: string;
  @Input() set formData(value: any) {
    if (value) {
     
      this._formData = value;
    }
    console.log("sss",this._formData)
  }
  
  
  submitted = false;
  p: number = 1;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  billingRulesList:any;
  overrideClaim:boolean=false;
  showClaimConfirmation:boolean =false;
  showUpload:boolean = false;
  claims=[];
  showOverrideReason:boolean = false;
  billingRuleDetails:boolean =false;
  noValue:boolean = false;
  checked:boolean = false;
 // checked = [];
  claim_type='';
  setBilled:any;
  fileAttachName=[];
  checkedCheckbox=[];
  selectedCheckBoxes=[];
  checkedCheckboxbool:boolean = false;
  userName='';
  setBilledForm:FormGroup;
  ClaimType= "";
  ruleText=[];
  checkedarr=[];
  validateFile:boolean = false;
  validateFileMsg:any;
  submit:any="Submit";
  submitButton:string = "Submit";
  submitButtonStatus = false;
  cancelButtonStatus = false;
  
  showConfirmation:boolean = false;
  constructor(private fb: FormBuilder,
    private formBuilder: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private currencyPipe:CurrencyPipe,
    private caseRequestInfoQuery: CaseRequestInfoQuery
   ) { }

  ngOnInit(): void {
    this.initForm();
   // this.getBillingRulesList();
    this.getClaims();
    this.getRuleText();
    this.getBilled();
    
  }
  get f() { return this.setBilledForm.controls; }
  initForm() {
    this.setBilledForm = this.fb.group({
      BilledId: ["",],
      checkbill:["",],
      ClaimType: ["",],
      OverrideReason: ["", ],
      ClaimConfirmation: ["",],
      fileName: ["",],
      PaperClaim: ["", ],
      Charges: ["", [Validators.required]],
    })
  }
  //(!(amount.match(/^\$/)))

   /* Validation for Numbers  and Negative Value  and Zero Tkt No - */

  transformAmount(event){
    let amount = this.setBilledForm.controls['Charges'].value;
    if(amount!= ''){
      console.log(amount); 
      let amountstr=amount.substr(0,1);
     console.log(amountstr); 
      if(amountstr=="$"){
        let amountlength=amount.length;
        amount = amount.substr(1,(amountlength-1));
        amount=amount.replace(",","");
        console.log(amountlength);
        console.log(amount);
      
     }
      
   
      amount=amount.replace(",","");
    //amount = DecimalPipe.Parse(amount, NumberStyles.Currency); 
    if (isNaN(amount))
    {
      this.setBilledForm.controls['Charges'].setValue("");
      this.setBilledForm.controls['Charges'].setErrors({'incorrect': true});
      this.toastr.error("Charge Amount should be a number. ",'Error!');
      return false;
    }else if ( amount <= 0) {
      
      this.setBilledForm.controls['Charges'].setValue("");
      this.setBilledForm.controls['Charges'].setErrors({'incorrect': true});
      this.toastr.error("Charge Amount should be greater than zero.",'Error!');
     
      return false;
    }else if(amount > 10000000){
      this.setBilledForm.controls['Charges'].setValue("");
      this.setBilledForm.controls['Charges'].setErrors({'incorrect': true});
      this.toastr.error("Please check the Charge Amount.",'Error!');
      return false;
    }else{
        this.formattedAmount = this.currencyPipe.transform(amount, 'USD');
    //console.log(this.formattedAmount);
    // Remove or comment this line if you dont want to show the formatted amount in the textbox.
    event.target.value = this.formattedAmount;
    console.log("this is valid");
       } }

}
  
  


  // checkNum(){
  //   let x = this.setBilledForm.controls['Charges'].value;
  //   if (isNaN(x)) 
  // {
  //   alert("Must input numbers");
  //   return false;
  // }
  // }
  checkbill(event){
  console.log(event.target.checked,event.target.value,)
    if(event.target.checked){
      this.checkedarr.push(event.target.checked);
    }else{
      this.checkedarr.pop();
    }
    console.log(this.checkedarr.length , this.ruleText.length,"length")
    if(this.checkedarr.length == this.ruleText.length){
      this.checked = true;
    }else{
      this.checked = false;
    }
    //this.checked = !this.checked;
    // if(this.checked === false){
    //   this.setBilledForm.disable();
    // }
  }
  selectionChanged(event) {
  //  if (event.target.length != undefined) {
      //this._formData = event.value;
      this.fileAttachName=[];
      console.log(event.target.files);
      console.log(event.target.files.length ,'value ',);
      for  (var i =  0; i <  event.target.files.length ; i++)  {  
        console.log(event.target.files[i].name,'type',event.target.files[0].name.split('.').pop(),"ext")
        let extension = event.target.files[i].name.split('.').pop();
        if(extension == "xlsx" || extension == "xlsm" || extension == "xlsb" || extension == "xltx" || extension == "csv"){
          this.validateFile = true;
          this.validateFileMsg = "File extension "+extension+ " is not allowed.";
          
          this.fileAttachName.pop();
          return;
        }else{
          this.validateFile = false;
          this.fileAttachName.push(event.target.files[i]);
          
        }
      }
      
      // }
      //this.notificationForm.controls["Name"].setValue(this._formData.fullName);
      //this.fullname = this._formData.fullName;
      //this.user_id = this._formData.id;
    //}
    // else {
    //   //this.notificationinitForm();
    // }

  }
  changeSelection(event){
   
    this.checkedCheckbox = [];
    this.selectedCheckBoxes = [];
    this.selectedCheckBoxes = this.claims.filter((value, index) => {
      return value.isChecked
    });

    for(let i = 0 ; i < this.selectedCheckBoxes.length ; i++){
      this.checkedCheckbox.push(this.selectedCheckBoxes[i].id);
    }
    
    if(event.target.checked){
      //this.checkedCheckbox.push(event.target.value);
          }
          console.log(this.checkedCheckbox);
    if(this.checkedCheckbox.length <= 0){
      this.toastr.error('Please select atleast one option', 'Error!');
    }

  }
  onSubmit(){
   this.cancelButtonStatus = true;
   this.submitButtonStatus = true;
   console.log("onSubmit : form data", this._formData);
    const formdata = this.setBilledForm.getRawValue();
    let frmData = new FormData();
    console.log(this.setBilledForm,"form",this.billingRuleDetails,"getData");
    
    if(!this.setBilledForm.valid){
      
      console.log(this.setBilledForm);
      return;
      
    }
    if(this.setBilledForm.controls['PaperClaim'].value == 'paper'){
     
     
    if (this.fileAttachName != undefined && this.fileAttachName != []) {
      
      if(!this.setBilledForm.controls['PaperClaim'].value){
        
        return;
      }
    }}
     
      for  (var i =  0; i <  this.fileAttachName.length; i++)  {  
        frmData.append("FileAttachment["+i+"].file",  this.fileAttachName[i]);
        frmData.append("FileAttachment["+i+"].fileName",  this.fileAttachName[i].name);
        this.submit="Submiting"
      }
      
      console.log(this.checkedCheckbox.length,'length');
      for (var j = 0 ; j < this.checkedCheckbox.length; j++){
        frmData.append("PaperClaimAttachment["+j+"].PaperClaimAttachmentId",this.checkedCheckbox[j] );
        
      }
      let newClaimType = "";
      console.log(this.ClaimType,"claimType");
      if(this.ClaimType === 'electronic'){
        this.submit="Submiting"
        newClaimType = this.ClaimType;
      }else if(this.ClaimType === 'paper'){
        this.submit="Submiting"
        newClaimType = this.ClaimType;
      }else{
        newClaimType = this.setBilledForm.controls["ClaimType"].value;
      }
      let chargesAmt = (this.setBilledForm.controls["Charges"].value).replace(',', '').replace('$','');
    //  console.log(this.setBilledForm.controls["Charges"].value)
      const bdosid = this._formData.bdosid ? this._formData.bdosid : this._formData.dosId;
      const facId = this._formData.facilityId ? this._formData.facilityId : this._formData.facilityID;
      frmData.append("BdosID", bdosid);
      frmData.append("facilityId", facId);
      frmData.append("SetBilledType",newClaimType );
      frmData.append("claimAmount", chargesAmt);

      frmData.append("claim", this.setBilledForm.controls["ClaimConfirmation"].value);
      frmData.append("overridereason", this.setBilledForm.controls["OverrideReason"].value);
      this.getApiService.uploadFiles("BillingNew/SetBilled", frmData).subscribe((resp: any) => {
        if (resp.status == "Success") {
         this.toastr.success('Data successfully uploaded', 'Success!');
         this.closeEmitter.emit();
        window.location.reload();
        }
      
        

      }, (err) => {
        console.log(err);
      })
  }
  overRideClaim(){
    this.overrideClaim = true;
    this.noValue = false;
    this.showOverrideReason = false
    this.showUpload = false
    this.showClaimConfirmation = false
  }
  getClaims() {
    if (this.searchValue != '' && this.pagenumber != 1) {
      this.pagenumber = 1;
      this.p = 1;
    }
    
    let  data = { "pageNum": this.pagenumber, "pageSize": this.pageValue };
    // let data = { "pageNum": 1, "pageSize": 100 };

    this.getApiService.post("PaperClaim/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        let claimResponse = resp.data;
        claimResponse.forEach(element => {
          if(element.isActive == 1){
            this.claims.push(element)
          }
        });
        
        
     //   this.totalRows = resp.data[0].totalcount;
      

      }

    }, (err) => {
      console.log(err);
    })
  }

  getBilled() {
    // if (this.searchValue != '' && this.pagenumber != 1) {
    //   this.pagenumber = 1;
    //   this.p = 1;
    // }
    console.log('formdata ',this._formData)
   // let  data = { "pageNum": this.pagenumber, "pageSize": this.pageValue };
    let data = { "BdosID": this._formData.bdosid, "facilityId": this._formData.facilityId };

    this.getApiService.post("BillingNew/GetSetBilled", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.setBilled = resp.attachment;
        this.userName = resp.data[0].actionBy;
        console.log(resp.attachment,'setbilled');
      }

    }, (err) => {
      console.log(err);
    })
  }
  downloadFile(file){
    let data = {"FileId": file.id,"BdosID": this._formData.bdosid, "facilityId": this._formData.facilityId }
    this.getApiService.post4File("BillingNew/DownloadBillingFile", data).subscribe(res => {
      fileSaver.saveAs(res, file.filename);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
    // this.getApiService.post4File("/BillingNew/DownloadBillingFile",data).subscribe(response => {
		// 	let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
		// 	const url = window.URL.createObjectURL(blob);
		// 	//window.open(url);
		// 	//window.location.href = response.url;
		// 	fileSaver.saveAs(blob, file.filename);
		// }), error => console.log('Error downloading the file'),
    //              () => console.info('File downloaded successfully');
   
    
  }
  deleteFile(file){
    let data = { "id": file.id,"BdosID": this._formData.bdosid, "facilityId": this._formData.facilityId};

    this.getApiService.post("BillingNew/DeleteBilledFiles", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.getBilled();
        this.toastr.success(resp.message, 'Success!');
       
       // console.log(resp.attachment,'setbilled');
      }

    }, (err) => {
      console.log(err);
    })
  }
  selectClaimType(event){
    //console.log(event.target.value);
    this.setBilledForm.controls["ClaimType"].setValidators( [Validators.required]);
    if(event.target.value == 'electronic' || event.target.value == 'Electronic'){
      this.showClaimConfirmation = true
      this.showUpload = false
      //this.setBilledForm.controls["fileName"].clearValidators();
      //this.setBilledForm.controls["PaperClaim"].clearValidators();

      this.setBilledForm.controls["ClaimConfirmation"].setValidators( [Validators.required]);
      let fileControl = this.setBilledForm.get("fileName");
      let paperClaim = this.setBilledForm.get("PaperClaim");
      fileControl.clearValidators();
      fileControl.updateValueAndValidity();
      paperClaim.clearValidators();
      paperClaim.updateValueAndValidity();
      
      // if(this.billingRulesList[0].electronic == 0){
       
      //   this.showOverrideReason = true
      // }else{
      //   this.claim_type = this.billingRulesList[0].electronic;
      //   this.showOverrideReason = false
      // }
     
    }else if(event.target.value == 'paper' || event.target.value == 'Paper'){
      this.showUpload = true
      this.showClaimConfirmation = false
      let fileControl = this.setBilledForm.get("fileName");
      let paperClaim = this.setBilledForm.get("PaperClaim");
      fileControl.setValidators( [Validators.required]);
      fileControl.updateValueAndValidity();
      paperClaim.setValidators( [Validators.required]);
      paperClaim.updateValueAndValidity();
      let claimConfirmation = this.setBilledForm.get("ClaimConfirmation");
        claimConfirmation.clearValidators();
        claimConfirmation.updateValueAndValidity();
      //this.setBilledForm.controls["fileName"].setValidators( [Validators.required]);
     // this.setBilledForm.controls["PaperClaim"].setValidators( [Validators.required]);
      
      // if(this.billingRulesList[0].paper == 0){
       
      //   this.showOverrideReason = true
      // }else{
      //   this.claim_type = this.billingRulesList[0].paper;
      //   this.showOverrideReason = false
      // }
      
    }else if(event.target.value == 'self' || event.target.value == 'Self'){
      this.noValue = true;
      this.showOverrideReason = false
      this.showUpload = false
      this.showClaimConfirmation = false
      let fileControl = this.setBilledForm.get("fileName");
      let paperClaim = this.setBilledForm.get("PaperClaim");
      fileControl.clearValidators();
      fileControl.updateValueAndValidity();
      paperClaim.clearValidators();
      paperClaim.updateValueAndValidity();
     // if(this.setBilledForm.controls["claimConfirmation"].value == ''){
        let claimConfirmation = this.setBilledForm.get("ClaimConfirmation");
        claimConfirmation.clearValidators();
        claimConfirmation.updateValueAndValidity();
    // }
      
     
     
     // this.setBilledForm.controls["fileName"].setValidators([]);
      //this.setBilledForm.controls["PaperClaim"].setValidators([]);
     // this.setBilledForm.controls["ClaimConfirmation"].setValidators([]);
    
    
    }else{
      
      this.noValue = true;
      this.showOverrideReason = false
      this.showUpload = false
      this.showClaimConfirmation = false
    }
   // console.log(this.showUpload,'show upload',this.showClaimConfirmation,'claim')
  }
  getRuleText(){
    //let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": facility };
    console.log(this._formData);
    const primaryCPt = this.caseRequestInfoQuery.getPrimaryCpt();
    const facilityName = this._formData.facilityName ? this._formData.facilityName : this._formData.client;
    const trimmedFacilityName = facilityName ? String(facilityName).trim() : "";
    //const groupNumber = this._formData.groupNumber;
   let data = { "facilityName": trimmedFacilityName, "Payor": this._formData.insuranceProvider, "Physician": this._formData.physician,"CPT": primaryCPt };
   console.log('_formData', this._formData);   
   console.log('getRuleText', data);
   this.getApiService.post("BillingNew/GetBillingRulesData", data).subscribe(res => {
      if (res.status == "Success") {
        console.log(res.data,"billingrule");
        if(res.data.length !== 0){
              this.billingRuleDetails = true;
              this.ruleText = res.data;
          }
        // res.data.forEach(element => {
        //   this.ruleText.push(element);
        // });
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  // getBillingRulesList() {
  //   let facility = this._formData.client;
  //   console.log(this._formData,"billingrule data")
  //   let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": facility };
  //  // let data = { "facilityName": this._formData.client, "Payor": this._formData.insuranceProvider, "Physician": this._formData.physician,"CPT":this._formData.primartCPT};
  //   this.getApiService.post("Rules/BindGridData", data).subscribe(res => {
  //     if (res.status == "Success") {

  //       this.billingRulesList = res.dataGridList;
  //       if(res.dataGridList.length !== 0){
  //         this.billingRuleDetails = true;
  //       }
       
  //       if(this.billingRulesList[0].paper == 1){
  //         this.ClaimType = "Paper"
  //         this.showClaimConfirmation = false
  //         this.showUpload = true;
        
  //         // this.billingRulesList[0].electronic == 0;
  //        // this.setBilledForm.controls["checkbill"].setValidators( [Validators.required]);
  //         //this.showOverrideReason = true
  //       }else if(this.billingRulesList[0].electronic == 1){
  //         // this.showOverrideReason = true
  //         this.ClaimType = "Electronic"
  //         this.showUpload = false;
  //         this.showClaimConfirmation = true
          
  //         // this.billingRulesList[0].paper = 0;
  //         //this.setBilledForm.controls["checkbill"].setValidators( [Validators.required]);

  //       }
  //      console.log(this.billingRulesList,'billing rule');

  //     //  if(this.billingRulesList.paper == 1){
  //     //    this.claimType.push('Paper')
  //     //  }
  //     }
  //     else if (res.status == "Error") {
  //       this.toastr.error(res.message, 'Error!');
  //     }
  //   }, (error) => {
  //     this.toastr.error(error, 'Error!');
  //   });
  // }
  onClickClose() {
  //  this.checkedarr
    let val = this.setBilledForm.controls['ClaimType'].value;
    console.log(val.length,"vlaue",this.showConfirmation,this.checkedarr.length);
    if(val.length !== 0 || this.checkedarr.length !==0){
      
      this.showConfirmation = true;
      console.log("blah blah",this.showConfirmation);
    }else{
      this.closeEmitter.emit();
    }
    //this.closeEmitter.emit();
  }
  confirmYesClick(){
    this.showConfirmation = false;
    this.closeEmitter.emit();
  }
  confirmNoClick(){
    this.showConfirmation = false;
  }
  
  
}
