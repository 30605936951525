import { RequestType } from "../../enums/request-type";

export interface Tab {
  id: number | string;
  value: string;
  displayName: string;
  type: RequestType;
  href: string;
  note: string;
}

export function createTab(params: Partial<Tab>) {
  return {
    ...params
  } as Tab;
}
