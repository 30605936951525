import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { PayersService } from 'src/app/shared/state/payers/payers.service';
import { PayersQuery } from 'src/app/shared/state/payers/payers.query';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-importpayers',
  templateUrl: './importpayers.component.html',
  styleUrls: ['./importpayers.component.css']
})
export class ImportpayersComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  importPayerList: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  privileges:any;
  payerFilter: string = "All payer";
  
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private payerService: PayersService,
    private accessServices: AccessService,
    private payerQuery: PayersQuery) { }

  ngOnInit(): void {
    this.privileges=this.accessServices.navbar_access;
    this.commonUtil.setTitle("Import Payers");
    this.getImportPayerList();
    this.payerService.get().subscribe(res=> console.log('Initialising payer store'));
  }

  onEditRecord(data) {
    this.shouldShowFormModal = true;
    this.formData = data;
  }

  showFormModal() {
    this.formData = null;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getImportPayerList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }

  }

  getImportPayerList() {
    let data;
    if(this.payerFilter && this.payerFilter === "Active Payer"){
      data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue, "Status": "Active" };
    }else if(this.payerFilter && this.payerFilter === "Inactive Payer"){
      data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue, "Status": "Inactive" };
    }else{
      data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };
    }
    this.getApiService.post("FacilityPayer/BindGrid", data).subscribe(res => {
      if (res.status == "Success") {
        this.importPayerList = res.data;
        this.totalRows = res.data[0] ? res.data[0].totalcount : 0;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  downloadfile(filename) {

    let filetodownload = filename.split('-')[0];

    let downloadUrl = this.getApiService.baseUrl + "FacilityPayer/DownloadFile?filename=" + filetodownload;

    window.open(downloadUrl, "_blank");

  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.importPayerList = [];
    this.getImportPayerList();
  }

  delPayer(delRow) {

    let data = { "id": delRow.id, "deleted": delRow.active };

    this.getApiService.post("FacilityPayer/DeactivatePayer", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
       this.getImportPayerList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    this.p = 1;
    this.getImportPayerList();
  }

  search() {
    this.pagenumber = 1;
    this.p = 1;
    this.getImportPayerList();
    const filterVal = this.payerFilter && this.payerFilter === 'Active Payer' ? 1 : this.payerFilter && this.payerFilter === 'Inactive Payer' ? 0 : 2;
    this.totalRows = this.payerQuery.getAll().filter(p => p.active === filterVal).length;
  }

  onPayerFilterChange(){
    this.pagenumber = 1;
    this.p = 1;
    this.getImportPayerList();
  }
}
