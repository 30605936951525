import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { Attachment, createAttachment } from './attachment.model';
import { AttachmentStore } from './attachment.store';

@Injectable({ providedIn: 'root' })
export class AttachmentService {

  constructor(private attachmentStore: AttachmentStore,
    private apiService: ApiService,
    private toastr: ToastrService) {
  }

  loadAttachment(attachmentType: NameConstant, bdosid: number){
    this.attachmentStore.setLoading(true);
    let data = { SetBilledType: attachmentType.serverValue, BdosID: bdosid };
    this.apiService.post("BillingNew/GetSetBilledAttachment", data).subscribe(res => {
      if(res.status && res.status === "Success"){
        const attachments = res.data.map(r => createAttachment({
          id: r.id,
          bdosid: r.bdosid,
          fileName: r.filename,
          file: r.fileAttach,
          fileType: r.filetype,
          date: r.createddate,
          reqType: r.reqType,
          userId: r.userid,
          username: r.userName,
          attachmentType: attachmentType
        }));
        this.attachmentStore.set(attachments);
      }else{
        this.attachmentStore.setLoading(false);
        this.attachmentStore.setError(res.message); 
        this.toastr.error(res.message);
      }
    }, err => {
      this.attachmentStore.setLoading(false);
      this.attachmentStore.setError(err);
      this.toastr.error(err);
    })
  }

  add(attachment: Attachment) {
    this.attachmentStore.add(attachment);
  }

  update(id, attachment: Partial<Attachment>) {
    this.attachmentStore.update(id, attachment);
  }

  remove(id: ID) {
    this.attachmentStore.remove(id);
  }

  destroy(){
    this.attachmentStore.destroy();
  }
}
