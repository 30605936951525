<!-- Comment Section Sidenav-->
<div id="mySidenav1" class="sidenav shadow width-560 active">
    <div
        class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
        <div class="row case-row">
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-4">
                        <p class="head-name-case">MRN</p>
                    </div>
                    <div class="col-md-8">
                        <p class="head-name-case">{{PatientSummaryDtls?.dataPatient[0]?.mrn}}</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 head-det-case">
                        <p class="head-det-case"><i class="fa fa-user mr-1"></i>
                            <span>{{PatientSummaryDtls?.dataPatient[0]?.patientName}}<span
                                    *ngIf="PatientSummaryDtls?.dataPatient[0]?.gender != null">({{PatientSummaryDtls?.dataPatient[0]?.gender}})</span>
                            </span>
                        </p>
                    </div>
                </div>
                <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'">
                    <div class="row">
                        <div class="col-md-12 head-det-case">
                            <p class="head-det-case">{{PatientSummaryDtls?.dataPatient[0]?.facilityName}}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="head-det-case">Primary CPT:</p>
                        </div>
                        <div class="col-md-6">
                            <p class="head-det-case">{{primaryCPT }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 head-det-case">
                            <p class="head-det-case">Related CPTs:</p>
                        </div>
                        <div class="col-md-6 head-det-case">
                            <p class="head-det-case">
                                <span *ngFor="let d of patientCPTs">
                                    <span *ngIf="d != primaryCPT">
                                        {{d}},
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 head-det-case">
                            <p class="head-det-case"> <i class="fas fa-tools mr-1"></i> Tools and Templates</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-name-case">Personal Information</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2"><span class="head-det-cse2">Address</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.address1}}
                                {{PatientSummaryDtls?.dataPatient[0]?.address2}}
                            </span>
                        </p>
                    </div>

                </div>

                <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">City</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.city}}</span></p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">State</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.state}}
                                    {{PatientSummaryDtls?.dataPatient[0]?.zip}}</span></p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Phone No</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.phonenumber}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">DOB</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataPatient[0]?.dob | date: "MM/dd/yyyy"}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">SSN No</span><span
                                    *ngIf="PatientSummaryDtls?.dataPatient[0]?.ssnNo != null"
                                    class="head-det-cse1">#{{PatientSummaryDtls?.dataPatient[0]?.ssnNo}}</span></p>
                        </div>

                    </div>

                </div>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-name-case">Insurance</p>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="head-det-case2" style="margin-bottom: 20px;"><span
                                class="head-det-cse2">Primary</span><span
                                class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.insuranceProvider}}</span>
                        </p>
                    </div>

                </div>

                <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Group #</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.groupNumber}}</span>
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Insurance Id</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.insuranceId}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Physician</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.physician}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Date Of Surgery</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.dateOfSurgery}}</span>
                            </p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case2"><span class="head-det-cse2">Billed</span><span
                                    class="head-det-cse1">{{PatientSummaryDtls?.dataDosDetails[0]?.billed}}</span>
                            </p>
                        </div>

                    </div>
                </div>
            </div>
            <p class="advance-icon-text plus-sign-bx1 expand-data"><i (click)="showPatientDetail()"
                    [ngClass]="smryIsToggled ? 'fa fa-minus showhideicn' : 'fa fa-plus showhideicn'"></i></p>
        </div>
    </div>

    <div class="card-body">
        <form class="pb-3 form-fields">
            <div class="row">
                <p style="margin-bottom: 5px;padding-left: 12px;">Claim Confirmation</p>
                <!-- <div *ngIf="_formData?.type == 'Electronic'" class="form-group col-md-12" style="margin-bottom:15px;">
                    <input type="checkbox" value="Accepted" [checked]="chckAccept == true"
                        (click)="slctchkBx('chckAccept')" class="user-chkbox">
                    <label class="user-lblbox">Accepted</label>
                    <span class="casetxt">Claim# <input type="text" class="form-control" id="inputClaim#"
                            [(ngModel)]="claimID" [ngModelOptions]="{standalone: true}"
                            style="width: 66%;display: -webkit-inline-box;" required></span>
                </div>
                <div *ngIf="_formData?.type == 'Paper'" class="form-group col-md-12" style="margin-bottom:15px;">
                    <input type="checkbox" value="UploadClaim" [checked]="chckClaim == true"
                        (click)="slctchkBx('chckClaim')" class="user-chkbox">
                    <label class="user-lblbox">Upload Claim</label>
                    <input type="file" [(ngModel)]="uploadFile" style="width:75%;margin-left: 27px;"
                        (change)="fileAttachChanged($event)" [ngModelOptions]="{standalone: true}"
                        class="form-control-file py-1 file-overlap-name">
                </div>-->
                <div class="form-group col-md-12" style="margin-bottom:15px;">
                    <div class="row">
                        <label class="user-lblbox">
                            <i class="fa fa-check mr-1"></i>Hold -
                            {{_formData?.holdReason}} </label><button type="button" (click)="satisfy()"
                            class="btn btn-warning btn-patient-box">Satisfy</button>
                    </div>
                    <!-- <input type="checkbox" value="UploadClaim" [checked]="chckSelfPay == true"
                        (click)="slctchkBx('chckSelfPay')" class="user-chkbox">
                    <label class="user-lblbox">Verify Self-Pay/No Insurance</label> -->
                </div>
                <div class="form-group col-md-12" style="margin-bottom:15px;">
                    <!-- <input type="checkbox" value="Hold" [checked]="chckHold == true" (click)="slctchkBx('chckHold')"
                        class="user-chkbox"> -->

                    <label class="user-lblbox">Hold Review</label>
                    <span class="casetxt"><input type="text" class="form-control mr-2" id="inputClaim#"
                            [(ngModel)]="claimID" [ngModelOptions]="{standalone: true}"
                            style="width: 45%;display: -webkit-inline-box;" required>
                        <button type="button" (click)="addNote()" class="btn btn-primary btn-patient-box">Add
                            Note</button>
                    </span>

                </div>
            </div>
            <div class="row mt-4">
                <div class="col-md-12 frm-margin-btm btn-remove-pdng">
                    <div class="text-right">
                        <button type="button" (click)="onClickClose()"
                            class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
                        <!-- <button type="button" (click)="onSubmit()"
                            class="btn btn-primary btn-width-cmn btn-patient-box">Submit</button>
                        <button type="button" (click)="onSubmit()"
                            class="btn btn-primary btn-width-cmn btn-patient-box">Submit</button> -->
                    </div>
                </div>
            </div>
            <!-- <div class="container tab-remove">
                <ul class="nav nav-tabs nav-border patient-tab-pad">
                    <li class="user-menu-style"><a class="active" data-toggle="tab" href="#history">History</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Notes">Notes</a></li>
                </ul>
                <div class="tab-content">
                    <div id="history" class="tab-pane fade in active show">

                        <div class="row history-btm-border">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right">Billed</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right">Billed</p>
                            </div>
                        </div>


                    </div>
             
                    <div id="Notes" class="tab-pane fade">

                        <div class="row">
                            <div class="form-group frm-margin-btm col-md-12">
                                <label >Add Note</label>
                                <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                    required></textarea>
                            </div>
                            <div class="form-group frm-margin-btm col-md-12">
                                <input type="file" class="form-control-file py-1" id="exampleFileUpload">
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="text-right">
                                    <button type="button"
                                        class="btn btn-secondary btn-patient-box closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn-patient-box">Submit</button>
                                </div>
                            </div>
                        </div>
                        <p class="comment-heading">Notes</p>
                        <div class="row history-btm-border">
                            <div class="col-md-12">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>




                        </div>
                     

                    </div>
                </div>
            </div> -->
        </form>
    </div>
</div>
<!-- Comment Section Sidenav -->