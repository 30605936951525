<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="!(activeUserProductivityReportLineId$ | async)">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="top-action-container">
                        <div>
                            <label for="fromDate" class="pad-right-lbl">From Date:</label>
                            <input type="date" id="fromDate" name="fromDate" [(ngModel)]="selFromDate" value="20/09/2021" >
                            <span *ngIf="submitted && !selFromDate" class="invalid-feedback">
                                From Date is mandatory!!
                            </span>
                        </div>
                        <div style="padding-left: 10px;">
                            <label for="toDate" class="pad-right-lbl">To Date:</label>
                            <input type="date" id="toDate" name="toDate" [(ngModel)]="selToDate">
                            <span *ngIf="submitted && !selToDate" class="invalid-feedback">
                                To Date is mandatory!!
                            </span>
                        </div>
                        <div class="item-review-cases" style="padding-left: 20px;">
                            <button type="button" (click)="onSearch()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav"><i class="fa fa-search fa-fw"></i>Search</button>
                        </div>
                        <div class="item-month">

                        </div>
                        <div class="item-year">

                        </div>
                        <div class="item-blank"></div>
                        <div class="item-search">

                        </div>
                        <div class="item-export">
                            <button type="button" (click)="onExport()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav">Export</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns"
                                    [ngClass]="{'txt-align-right': col.name === 'totalcases' || col.name === 'totalchargedbill' || col.name === 'caseswph' }"
                                    >{{col.displayName}}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns"
                                    [ngClass]="{'txt-align-right': col.name === 'totalcases' || col.name === 'totalchargedbill' || col.name === 'caseswph' }"
                                    >{{col.displayName}}</th>
                                </tr>
                            </tfoot>
                            <ng-container *ngIf="datasource$ | async as datasource">
                                <tr *ngFor="let data of datasource | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;"
                                    style="color: #333;
                            font-weight: 400;">
                                    <td>{{(i+1) + ((currentPage-1)*itemsPerPage)}}</td>
                                    <td>{{data.username}}</td>
                                    <td>{{data.fromdate | date: 'MM/dd/yyyy'}}</td>
                                    <td>{{data.todate | date: 'MM/dd/yyyy'}}</td>
                                    <td class="txt-align-right">{{data.totalcases}}</td>
                                    <td class="txt-align-right">{{data.totalchargedbill | currency}}</td>
                                    <td class="txt-align-right">{{data.caseswph}}</td>
                                    <td>
                                        <a href="javascript:void(0)"  (click)="showReportLineDetail(data)"
                                                class="icon-color-darkgreen openMySidenav mr-2"><i
                                                    title="Patient View" class="fa fa-eye"></i></a>
                                    </td>
                                </tr>
                            </ng-container>
                        </table>
                        <pagination-controls class="float-right"
                            (pageChange)="pageChange($event); currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-user-productivity-report-detail *ngIf="activeUserProductivityReportLineId$ | async" [selFromDate]="selFromDate" [selToDate]="selToDate"></app-user-productivity-report-detail>