import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { CategoryItemState, CategoryItemStore } from './category-item.store';

@Injectable({ providedIn: 'root' })
export class CategoryItemQuery extends QueryEntity<CategoryItemState> {

  constructor(protected store: CategoryItemStore) {
    super(store);
  }

}
