import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { combineAll, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { Cpt, createCpt } from './cpt.model';
import { CptStore } from './cpt.store';
import { ApiService } from 'src/app/shared/services/api.service';
import { CptQuery } from './cpt.query';
import { GroupsQuery } from '../groups/groups.query';
import { forkJoin, Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({ providedIn: 'root' })
export class CptService {

  constructor(
    private store: CptStore, 
    private apiService: ApiService, 
    private query: CptQuery,
    private toastr: ToastrService) {
  }

  setActive(ids: ID[]){
    this.store.setActive(ids);
  }

  active(ids: ID[]) {
    this.store.addActive(ids);
  }

  inActiveAll() {
    const ids = this.query.getActiveId();
    this.inActive(ids)
  }

  inActive(ids: ID[]) {
    const activeIds = this.query.getActiveId();
    const idsTobeMadeInactive = ids.filter(id => activeIds.includes(id));
    this.store.toggleActive(idsTobeMadeInactive);
  }

  set(res){
    this.store.set(res);
  }

  setCptFacility(ids: ID[]){
    let data = { "Facility": ids };
    console.log('setCptFacility -> ', data);
    this.apiService.post("FeeSchedule/GetCPTbyFacility", data).subscribe(res => {
      if(res.status === 'Success'){
       this.set(res.data.map(c => createCpt({ cpt: c.cpt, cptDesc: c.cptDesc })));
      }else{
        this.toastr.error('Error', res.message);
      }
    })
  }
}
