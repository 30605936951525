import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.css']
})
export class UserConfirmationComponent implements OnInit {
  status: boolean = false;
  code: string;
  userid: string;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) {

    this.code = this.activatedRoute.snapshot.params.code;
    this.userid = this.activatedRoute.snapshot.params.userId;

  }

  ngOnInit(): void {
    this.commonUtil.setTitle("Confirm Email");
    this.onload();
  }

  onload() {

    this.service.post('ApplicationUser/ConfirmEmail?code=' + this.code + '&userId=' + this.userid, "").subscribe(
      res => {
        if (res.status == "Success") {
          this.status = true;
          this.toastr.success('Confirm Email Successfully!', 'Success!');
        }
        else if (res.status == "IdentityError") {
          this.toastr.error(res.message[0].description, res.message[0].code);
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
  }

}
