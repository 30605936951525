<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
    <p class="bulk-action-loader"> Loading... </p>
</ngx-spinner>
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h6 class="m-0 font-weight-bold text-primary">{{componentName.value}}</h6>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <form class="pb-3 form-fields" [formGroup]="CreateBatchReviewCasesForm" *ngIf="CreateBatchReviewCasesForm">
                <div class="row">
                    <div class="form-group col-6">
                        <label for="assignAll">
                            <label>Reviewer:</label>
                        </label>
                    </div>
                    <div class="form-group col-6">
                        <div>
                            <app-nsn-dropdown 
                            [callerId]="componentName" 
                            [config]="userDropdownConfig" 
                            [name]="'Users'"
                            [isMultiple]="false" 
                            (onChangeEmitter)="onUserChange($event)">
                            </app-nsn-dropdown>
                            <span *ngIf="submitted && !selectedUser" class="invalid-feedback">
                                Reviewer is mandatory!!
                            </span>
                            <span *ngIf="submitted && selectedUser && selfAssigning" class="invalid-feedback">
                                You cannot assign yourself!!
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="assignAll">
                            <label>Batch Name</label>
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="batchName" placeholder="Batch Name"
                                required [ngClass]="{'is-invalid': submitted && f.batchName.errors }">
                                <span *ngIf="submitted && f.batchName.errors && f.batchName.errors.required" class="invalid-feedback">
                                    Batch Name is mandatory!!
                                </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="assignAll">
                            <label>Comment</label>
                        </label>
                    </div>
                    <div class="form-group col-md-6">
                        <div class="input-group">
                            <textarea type="text" class="form-control" formControlName="comment" placeholder="Comment"
                                required [ngClass]="{'is-invalid': submitted && f.comment.errors }">
                            </textarea>
                                <span *ngIf="submitted && f.comment.errors && f.comment.errors.required" class="invalid-feedback">
                                    Comment is mandatory!!
                                </span>
                        </div>
                    </div>
                </div>

                <app-nsn-grid
                [callerId]="componentName"
                [isDataSourceProvided]="true"
                [dataSource]="activePatientList"
                [coloumns]="coloumns"
                [paginationControlId]="componentName.id"
                ></app-nsn-grid>

                <div class="text-right" style="margin: 75px 0px;">
                    <button type="button" (click)="onClickClose()"
                        class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                    <button type="button" (click)="onSubmit()" [disabled]="loading"
                        class="btn btn-primary btn-patient-box">Submit
                        <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>