import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CodingStatus } from './coding-status.model';

export interface CodingStatusState extends EntityState<CodingStatus>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'coding-status' })
export class CodingStatusStore extends EntityStore<CodingStatusState> {

  constructor() {
    super();
  }

}
