<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Content Row -->

    <div class="row">

        <!-- Area Chart -->
        <div class="col-xl-12 col-lg-7">

            <div class="card shadow profile-card mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size">Change Password</h1>
                </div>
                <div class="card-body">
                    <form class="" [formGroup]="chngPwdForm">
                        <div class="row">
                            <div class="form-group col-md-6 col-12">
                                <label for="exampleInputPassword" class="d-flex">Current Password</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    </div>
                                    <input type="password" class="form-control form-control-user"
                                        [ngClass]="{ 'is-invalid': submitted && f.CurrentPassword.errors }"
                                        placeholder="Password" formControlName="CurrentPassword">
                                    <div *ngIf="submitted && f.CurrentPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.CurrentPassword.errors.required">*Current Password is required
                                        </div>
                                        <div *ngIf="f.CurrentPassword.errors.validators">*Current Password Doesn't Match
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-12">
                                <label for="exampleInputConfirmPassword" class="d-flex">New Password</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <!-- <div class="input-group-text"><i class="fa fa-lock"></i> -->
                                        <!-- </div> -->
                                    </div>
                                    <input type="password" class="form-control form-control-user"
                                        [ngClass]="{ 'is-invalid': submitted && f.NewPassword.errors }"
                                        placeholder="Password" formControlName="NewPassword">
                                    <div *ngIf="submitted && f.NewPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.NewPassword.errors.required">*New Password is required</div>
                                        <div *ngIf="f.NewPassword.errors.validators">*New Password Doesn't Match</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6 col-12">
                                <label for="exampleInputConfirmPassword" class="d-flex">Confirm Password</label>
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <!-- <div class="input-group-text"><i class="fa fa-lock"></i> -->
                                        <!-- </div> -->
                                    </div>
                                    <input type="password" class="form-control form-control-user"
                                        [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }"
                                        placeholder="Password" formControlName="confirmPassword">
                                    <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                                        <div *ngIf="f.confirmPassword.errors.required">*Confirm Password is required
                                        </div>
                                        <div *ngIf="f.confirmPassword.errors.validators">*Confirm Password Doesn't Match
                                        </div>
                                        <div *ngIf="f.confirmPassword.errors.mustMatch">*Password and Confirm Password
                                            must
                                            match</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 col-12 text-right">
                                <button type="button" routerLink="/dashboard"
                                    class="btn btn-secondary btn-patient-box mr-2">Cancel</button>
                                <button type="button" (click)="onSubmit()"
                                    class="btn btn-primary btn-patient-box">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


</div>
<!-- /.container-fluid -->