<div class="user-login fullheight fullheight-pad">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div>
                    <img src="assets/img/logo-login.png" alt="" class="img-fluid py-3">
                </div>
                <div class="">
                    <h1 class="mb-4">Reset Password</h1>
                    <!-- <p class="error-msg">all fields are mandatory*</p> -->
                </div>
                <form class="" [formGroup]="resetPwdForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="password" class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" placeholder="Password"
                                formControlName="Password">
                            <div *ngIf="submitted && f.Password.errors" class="invalid-feedback">
                                <div *ngIf="f.Password.errors?.required">*Password is required</div>
                                <div *ngIf="f.Password.errors?.minlength">*Password must be 8 characters long</div>
                                <!-- <div *ngIf="f.Password.errors.mustMatch">*Password and Confirm Password
                                    must
                                    match</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="password" class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.ConfirmPassword.errors }"
                                placeholder="Confirm Password" formControlName="ConfirmPassword">
                            <div *ngIf="submitted && f.ConfirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.ConfirmPassword.errors?.required">*Confirm Password is required</div>
                                <div *ngIf="f.ConfirmPassword.errors?.minlength">*Confirm Password must be 8 characters long</div>
                                <div *ngIf="f.ConfirmPassword.errors.mustMatch">*Password and Confirm Password
                                    must
                                    match</div>
                            </div>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-primary btn-user btn-block login-btn-user">Submit</button>
                </form>
                <div class="">
                    <p><a routerLink="/login"><i class="fa fa-arrow-left back-arrow"></i>Back to Login</a></p>
                </div>
                <div class="">
                    <p><a href="#" class="text-underline">Terms & Conditions</a> and <a href="#"
                            class="text-underline">Privacy
                            Policy</a></p>
                </div>
            </div>
        </div>
    </div>
</div>