import { AfterViewInit, Component, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { map } from 'rxjs/operators';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { CategoryItemService } from '../../../../shared/state/category-item/category-item.service';
import { CategoryItemQuery } from '../../../../shared/state/category-item/category-item.query';
import { UsersService } from 'src/app/modules/admin/manage-users/state/users/users.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { Facility } from 'src/app/shared/state/facilities/facility.model';
import { CategoryItem } from '../../../../shared/state/category-item/category-item.model';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { AccessService  } from 'src/app/shared/services/access.service';
@Component({
  selector: 'app-assign-case',
  templateUrl: './assign-case.component.html',
  styleUrls: ['./assign-case.component.scss']
})
export class AssignCaseComponent implements OnInit {
  componentName: string = "Assign Case";
  actionEnabled$: Observable<boolean>;
  patients$: Observable<Patient[]>;
  
  pageNum: number = 1;
  pageSize: number = 10;
  p = 1;
  totalRows: any;
  searchValue: any;
  privileges:any;

  subscrption: Subscription= new Subscription();
  isAssignFormEnabled$: Observable<boolean>;
  
  facility$: Observable<Facility[]>;
  categoryItem$: Observable<CategoryItem[]>;
  selectedFacility: Facility;
  selectedCategoryItem: CategoryItem;

  constructor(
    private selectionService: SelectionService,
    private patientService: PatientService,
    private patientQuery: PatientQuery,
    private facilityService: FacilityService,
    private facilityQuery: FacilityQuery,
    private commonUtil: CommonService,
    private selectionQuery: SelectionQuery,
    private categoryItemService: CategoryItemService,
    private categoryItemQuery: CategoryItemQuery,
    private accessServices : AccessService,
  ) {
    this.actionEnabled$ = this.patientQuery.selectActiveId().pipe((map(res => {
      return res.length > 0;
    })));

    this.commonUtil.setTitle(this.componentName);
  };

  ngOnInit() {
    this.privileges=this.accessServices.navbar_access;
    this.loadFacilityDrodpdown();
    this.patients$ = this.patientQuery.selectAll();
    this.facility$ = this.facilityQuery.selectAll();
    this.categoryItem$ = this.categoryItemQuery.selectAll();

    this.isAssignFormEnabled$ = this.selectionQuery.select().pipe(map(res =>  {
      if(res.value === '*load*'){
        
        this.loadPatients();
      }
     return res.value === "*Add*"
    }));

  }
  private loadFacilityDrodpdown() {
    this.facilityService.fetchAndStoreFacilityForPatient().subscribe(res => {
      if(!this.selectedFacility){
        this.selectedFacility = res.data[0];
        this.facilityService.setActive([this.selectedFacility.id]);
      }
      this.loadCategoryItems();
    });
  }

  private loadCategoryItems(){
    if(this.selectedFacility){
    const facilityId = this.selectedFacility.id;
    this.categoryItemService.loadCategoryItem(facilityId).subscribe( res=> {
      this.selectedCategoryItem = res.data[0];
      this.loadPatients();
      });
    }
  }

  public loadPatients() {
    if(this.selectedFacility && this.selectedCategoryItem){
    const facilityId = this.selectedFacility.id;
    const categeoryItem = this.selectedCategoryItem.value;
    this.patientService.loadPatientsForAssignCases(facilityId, categeoryItem, this.searchValue, this.pageNum, this.pageSize)
      .subscribe(res => {
        this.setTotalRows();
      });
    }
  }

  onCheckboxChange(data){
    this.patientService.toggleActive(data.bdosid);
  }

  searchPatient(){
    this.pageNum = 1;
    this.p = 1;
    this.loadPatients();
  }

  sortList(orderby, sortby, subtype) {

  }

  pageChange(event) {
    this.pageNum = event;
    this.loadPatients();
  }

  isActive(data): boolean{
    return this.patientQuery.getActiveId().includes(data.bdosid);
  }

  facilityChange(event$) {
    this.pageNum = 1;
    this.p = 1;
    this.selectedFacility = event$.value;
    this.facilityService.setActive([this.selectedFacility.id]);
    this.loadFacilityDrodpdown();
  }

  categoryItemChange(event$){
    this.pageNum = 1;
    this.p = 1;
    this.selectedCategoryItem = event$.value;
    this.loadPatients();
  }

  setTotalRows(){
  const all =   this.patientQuery.getAll();
  const patient = all[0];
  this.totalRows = patient?.totalcount;
  }

  compareFacility(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.id === val2.id;
  }

  compareCategory(val1, val2) {
    if(!val1 || !val2)
      return false;
      
    return val1.value === val2.value;
  }

  openForm(){
    this.selectionService.changeSelection("*Add*");
   }

   configForFacility = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: false, //true/false for the search functionlity defaults to false,
    height: '16rem', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Facility', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No facility found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Facility', // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }

  configForCategoryItem = {
    displayKey: "name", //if objects array passed which key to be displayed defaults to description
    search: false, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select Category', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No category found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search Category', // label thats displayed in search input,
    searchOnKey: "name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }
}
