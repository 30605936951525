import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingRulesComponent } from './billing-rules.component';
import { BillingRulesFormComponent } from './billing-rules-form/billing-rules-form.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacilityDetailsComponent } from './facility-details/facility-details.component';

@NgModule({
  declarations: [BillingRulesComponent, BillingRulesFormComponent, FacilityDetailsComponent],
  imports: [
    CommonModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [BillingRulesFormComponent]

})
export class BillingRulesModule { }
