<!-- Begin Page Content -->
<div class="container-fluid width-index">
   <div class="container1">
      <!-- Daily Progress-->
      <div class="row daily-progress">
         <div class="col-md-4">
            <div class="border-progress">
               <p class="progress-text">Daily Progress <i class="fa fa-exclamation-triangle text-color-red"
                     aria-hidden="true"></i><br>
                  <span class="text-color-red" style="font-size: 16px;">One Or More Centers Missing Daily Rec</span>
               </p>
               <!-- <img src="assets/img/dashboard-Progress-logo.png" alt="" class="dashboard-logo-side"> -->

               <p class="progress-bar-text">Charges Posted (<span class="redtext">$56,586 Remaining</span>) </p>
               <div class="progress">
                  <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="80" aria-valuemin="0"
                     aria-valuemax="100" style="width:80%">$580,260
                     <span class="sr-only">85% Complete</span>
                  </div>
               </div>
               <p class="progress-bar-text-second">Lines Posted</p>
               <div class="progress">
                  <div class="progress-bar " role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"
                     style="width:90%">512
                     <span class="sr-only">90% Complete</span>
                  </div>
               </div>
            </div>
         </div>
         <div class="col-md-8">
            <p class="progress-text-right">
               Monthly Performance - April 2022

               <span class="progress-text-date">April 1st - April 22th</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-star fa-color-blue"></i>180
               <span class="monthly-progress-box1">Priority Items</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>210
               <span class="monthly-progress-box1">Action Items</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-file-invoice fa-color-blue"></i>555
               <span class="monthly-progress-box1">Total Lines Posted</span>
            </p>
            <p class="monthly-icon-text"><i class="fa fa-calendar fa-color-blue"></i>465
               <span class="monthly-progress-box1">Avg Lines Posted</span>
            </p>
            <p class="monthly-icon-text monthly-icon-text-billed"><i
                  class="fa fa-file-invoice-dollar fa-color-blue"></i>$210,460
               <span class="monthly-progress-box1">Total Billed</span>
            </p>
         </div>
      </div>
      <!-- daily Progreess End-->
   </div>
   <!-- container end-->
   <!-- /.container-fluid -->
</div>
<!-- Sugery Center Section-->
<div class="container-fluid cont-mrg width-index">
   <div class="container1">
      <div class="row advance-surgery-center">
         <div class="col-md-4 surgery-row">
            <p class="advance-section-text">HITech Surgery Center <br /> </p>
         </div>
         <div class="col-md-8" style="padding: 0px;">
            <p class="advance-icon-text"><i class="fa fa-project-diagram fa-color-blue"></i>10
               <span class="advance-progress-box1">Priority Items</span>
            </p>
            <p class="advance-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>10
               <span class="advance-progress-box1">Action Items</span>
            </p>
            <p class="advance-icon-text">$210,460
               <span class="advance-billed-box1">Posted Today</span>
            </p>
            <div class="adv-progress-bar">
               <p class="progress-bar-text-second wdth-advance">Remaining From Daily Rec</p>
               <div class="progress">
                  <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                     aria-valuemax="100" style="width:50%">
                     <span class="sr-only">70% Complete</span>
                  </div>
               </div>
               <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">$22,598</span></p>
            </div>
            <p class="advance-icon-text price-box1 ">
               <span class="surgety-number mr-1">$290,130</span>
               <span class="spn-goaltext">Posted</span><br>
               <span class="surgety-number mr-1">$290,130</span>
               <span class="spn-goaltext">Daily Goal</span><br>
               <span class="surgety-number mr-1"><span class="text-color-red">$290,130</span></span>
               <span class="spn-goaltext">Variance</span><br>
               <span class="surgety-number mr-1"><span class="text-color-red">$290,130</span></span>
               <span class="spn-goaltext">Projected</span>
            </p>
            <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,1)"><i *ngIf="selectedIndex != 1"
                  class="fa fa-plus"></i>
               <i *ngIf="selectedIndex == 1" class="fa fa-minus"></i>
            </p>
         </div>
      </div>
      <!-- Sugery Center Section End-->
      <!-- container fluid advcance secttion end-->
      <!-- Facility Action Section-->
      <!-- <div class="container-fluid" > -->
      <!-- <div class="container1"> -->
      <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 1">

         <div class="col-md-6 pdng-widget10">
            <p class="widget-text-heading">Pending</p>
            <p class="widget-text-sub-heading">Case Total: 5 </p>
            <div class="row t3newbox">
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-times-circle icon-color-blue"></i>
                  <span class="widget-icon-txt-list widget-icon-txt-list1">Denials</span> <span
                     class="widget-text-spn widget-text-spn1">7</span></p>
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-usd icon-color-blue"></i> <span
                     class="widget-icon-txt-list widget-icon-txt-list1">Underpayments</span> <span
                     class="widget-text-spn widget-text-spn1">3</span></p>
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-info-circle  icon-color-blue"></i>
                  <span class="widget-icon-txt-list widget-icon-txt-list1">Correspondence</span> <span
                     class="widget-text-spn widget-text-spn1">3</span></p>
            </div>
         </div>


         <div class="col-md-6 pdng-widget20">
            <p class="widget-text-heading">Summary</p>
            <div class="addgap"></div>
            <p class="widget-text-list"><i class="fa fa-calendar-check icon-color-blue mr-2"></i> <span
                  class="widget-icon-txt-list posting-widget-icon-txt-list">April Cases</span> <span
                  class="widget-text-spn">95</span></p>

         </div>
      </div>
      <!-- container end-->
   </div>
   <!-- container end-->
</div>
<!-- container fluid advcance secttion end-->
<!-- Sugery Center Section-->
<div class="container-fluid cont-mrg  width-index">
   <div class="container1">
      <div class="row advance-surgery-center">
         <div class="col-md-4 surgery-row">
            <p class="advance-section-text">HITech Surgery Center II <br /></p>
         </div>
         <div class="col-md-8" style="padding: 0px;">
            <p class="advance-icon-text"><i class="fa fa-project-diagram fa-color-blue"></i>10
               <span class="advance-progress-box1">Priority Items</span>
            </p>
            <p class="advance-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>10
               <span class="advance-progress-box1">Action Items</span>
            </p>
            <p class="advance-icon-text">$210,460
               <span class="advance-billed-box1">Posted Today</span>
            </p>
            <div class="adv-progress-bar">
               <p class="progress-bar-text-second wdth-advance">Remaining From Daily Rec</p>
               <div class="progress">
                  <div class="progress-bar bar-second-color" role="progressbar" aria-valuenow="80" aria-valuemin="0"
                     aria-valuemax="100" style="width:80%">
                     <span class="sr-only">70% Complete</span>
                  </div>
               </div>
               <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">$24,598</span></p>
            </div>
            <p class="advance-icon-text price-box1 ">
               <span class="surgety-number mr-1">$290,130</span>
               <span class="spn-goaltext">Posted</span><br>
               <span class="surgety-number mr-1">$290,130</span>
               <span class="spn-goaltext">Daily Goal</span><br>
               <span class="surgety-number mr-1"><span class="text-color-red">$290,130</span></span>
               <span class="spn-goaltext">Variance</span><br>
               <span class="surgety-number mr-1"><span class="text-color-red">$290,130</span></span>
               <span class="spn-goaltext">Projected</span>
            </p>
            <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,2)"><i *ngIf="selectedIndex != 2"
                  class="fa fa-plus"></i>
               <i *ngIf="selectedIndex == 2" class="fa fa-minus"></i>
            </p>
         </div>
         <!-- </div> -->
         <!-- Sugery Center Section End-->
      </div>

      <!-- container fluid advcance secttion end-->
      <!-- Facility Action Section-->
      <!-- <div class="container-fluid" > -->
      <!-- <div class="container1"> -->
      <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 2">

         <div class="col-md-6 pdng-widget10">
            <p class="widget-text-heading">Pending</p>
            <p class="widget-text-sub-heading"> Case Total: 5 </p>
            <div class="row t3newbox">
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-times-circle icon-color-blue"></i>
                  <span class="widget-icon-txt-list widget-icon-txt-list1">Denials</span> <span
                     class="widget-text-spn widget-text-spn1">7</span></p>
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-usd icon-color-blue"></i> <span
                     class="widget-icon-txt-list widget-icon-txt-list1">Underpayments</span> <span
                     class="widget-text-spn widget-text-spn1">3</span></p>
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-info-circle  icon-color-blue"></i>
                  <span class="widget-icon-txt-list widget-icon-txt-list1">Correspondence</span> <span
                     class="widget-text-spn widget-text-spn1">3</span></p>
            </div>
         </div>


         <div class="col-md-6 pdng-widget20">
            <p class="widget-text-heading">Summary</p>
            <div class="addgap"></div>
            <p class="widget-text-list"><i class="fa fa-calendar-check icon-color-blue mr-2"></i> <span
                  class="widget-icon-txt-list posting-widget-icon-txt-list">April Cases</span> <span
                  class="widget-text-spn">95</span></p>

         </div>
      </div>
      <!-- container end-->
   </div>
</div>
<!-- Sugery Center Section End-->
<!-- </div> -->
<!-- container end-->
<!-- </div> -->
<!-- container fluid Facility Action Secttion end-->
<!-- Sugery Center Section-->
<div class="container-fluid cont-mrg  width-index">
   <div class="container1">
      <div class="row advance-surgery-center">
         <div class="col-md-4 surgery-row">
            <p class="advance-section-text">HITech Surgery Center III <br /></p>
         </div>
         <div class="col-md-8" style="padding: 0px;">
            <p class="advance-icon-text"><i class="fa fa-project-diagram fa-color-blue"></i>10
               <span class="advance-progress-box1">Priority Items</span>
            </p>
            <p class="advance-icon-text"><i class="fa fa-list-alt fa-color-blue"></i>10
               <span class="advance-progress-box1">Action Items</span>
            </p>
            <p class="advance-icon-text">$210,460
               <span class="advance-billed-box1">Posted Today</span>
            </p>
            <div class="adv-progress-bar">
               <p class="progress-bar-text-second wdth-advance">Remaining From Daily Rec</p>
               <div class="progress">
                  <div class="progress-bar bar-fourth-color" role="progressbar" aria-valuenow="30" aria-valuemin="0"
                     aria-valuemax="100" style="width:30%">
                     <span class="sr-only">70% Complete</span>
                  </div>
               </div>
               <p class="progress-bar-text-second wdth-advance1"><span class="colr-numb">! Missing Daily Rec</span></p>
            </div>
            <p class="advance-icon-text price-box1 ">
               <span class="surgety-number mr-1">$290,130</span>
               <span class="spn-goaltext">Posted</span><br>
               <span class="surgety-number mr-1">$290,130</span>
               <span class="spn-goaltext">Daily Goal</span><br>
               <span class="surgety-number mr-1"><span class="text-color-red">$290,130</span></span>
               <span class="spn-goaltext">Variance</span><br>
               <span class="surgety-number mr-1"><span class="text-color-red">$290,130</span></span>
               <span class="spn-goaltext">Projected</span>
            </p>
            <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,3)"><i *ngIf="selectedIndex != 3"
                  class="fa fa-plus"></i>
               <i *ngIf="selectedIndex == 3" class="fa fa-minus"></i>
            </p>
         </div>
      </div>
      <!-- Sugery Center Section End-->
      <!-- container fluid advcance secttion end-->
      <!-- Facility Action Section-->
      <!-- <div class="container-fluid" > -->
      <!-- <div class="container1"> -->
      <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 3">

         <div class="col-md-6 pdng-widget10">
            <p class="widget-text-heading">Pending</p>
            <p class="widget-text-sub-heading">Case Total: 5 </p>
            <div class="row t3newbox">
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-times-circle icon-color-blue"></i>
                  <span class="widget-icon-txt-list widget-icon-txt-list1">Denials</span> <span
                     class="widget-text-spn widget-text-spn1">7</span></p>
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-usd icon-color-blue"></i> <span
                     class="widget-icon-txt-list widget-icon-txt-list1">Underpayments</span> <span
                     class="widget-text-spn widget-text-spn1">3</span></p>
               <p class="widget-text-list widget-text-list1 col-4"><i class="fa fa-info-circle  icon-color-blue"></i>
                  <span class="widget-icon-txt-list widget-icon-txt-list1">Correspondence</span> <span
                     class="widget-text-spn widget-text-spn1">3</span></p>
            </div>
         </div>


         <div class="col-md-6 pdng-widget20">
            <p class="widget-text-heading">Summary</p>
            <div class="addgap"></div>
            <p class="widget-text-list"><i class="fa fa-calendar-check icon-color-blue mr-2"></i> <span
                  class="widget-icon-txt-list posting-widget-icon-txt-list">April Cases</span> <span
                  class="widget-text-spn">95</span></p>

         </div>
      </div>
      <!-- container end-->
   </div>
   <!-- container end-->
</div>
<!-- container fluid advcance secttion end-->
<!-- End of Content Wrapper -->