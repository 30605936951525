import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-kb-form',
  templateUrl: './kb-form.component.html',
  styleUrls: ['./kb-form.component.css']
})
export class KbFormComponent implements OnInit {
  KBForm: FormGroup;
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  eventName: string;
  formFlag: string = "Add";
  _formData: any;
  submitted = false;
  KBCatArry: any[];
  filetoUpload;
  kbMode: string;
  submit:any="Submit"

  @Input() set formData(value: any) {

    if (!isNullOrUndefined(value)) {
      this._formData = value;
      this.formFlag = "Edit";
      this.eventName = this._formData.emailEvent;
      this.editForm();
      this.kbMode = "Edit "
    }
    else {
      this.formFlag = "Add";
      this.kbMode = "Add "
      this.initForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getKBCatArryList();
  }

  get f() { return this.KBForm.controls; }


  onSubmit() {
    console.log("Form Is Submited.");
    if (this.formFlag == "Add") {
      this.addRecord();
      this.submit="Submiting"
    }
    else if (this.formFlag == "Edit") {
      this.editRecord();
    }

  }

  addRecord() {
    
    this.submitted = true;
    const frmdata = this.KBForm.getRawValue();

    let formData = new FormData();
    formData.append("title", frmdata.title);
    formData.append("description", frmdata.description);
    formData.append("Category", frmdata.category);
    formData.append("file", this.filetoUpload, this.filetoUpload?.name);

    this.getApiService.uploadFiles("KB/SaveKB", formData).subscribe((resp: any) => {
      if (resp.status == "Success") {
        
        this.KBForm.reset();
      }
      this.editEmitter.emit({
        'status': true,
        'data': resp
      })
      this.submit="Submiting"
    }, (err) => {
      console.log(err);
    })
  }


  editRecord() {
    this.submitted = true;
    const frmdata = this.KBForm.getRawValue();

    let formData = new FormData();
    formData.append("id", frmdata.id);
    formData.append("title", frmdata.title);
    formData.append("description", frmdata.description);
    formData.append("Category", frmdata.category);

    if (this.filetoUpload != "" && this.filetoUpload != undefined) {
      formData.append("file", this.filetoUpload, this.filetoUpload.name);
    }


    this.getApiService.uploadFiles("KB/SaveKB", formData).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.KBForm.reset();
      }
      this.editEmitter.emit({
        'status': true,
        'data': resp
      })
     
    }, (err) => {
      console.log(err);
    })
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  editForm() {
    this.KBForm = this.fb.group({
      id: [this._formData.id, [Validators.required]],
      title: [this._formData.title, [Validators.required]],
      description: [this._formData.k_description, Validators.required],
      category: [this._formData.category, [Validators.required]],
      file: []
    })
  }

  initForm() {
    this.KBForm = this.fb.group({
      title: ["", [Validators.required]],
      description: ["", Validators.required],
      category: ["", [Validators.required]],
      file: []
    })
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  getKBCatArryList() {

    this.getApiService.get("KB/BindCatDropdown").subscribe(res => {
      if (res.status == "Success") {
        this.KBCatArry = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  uploadFile(fileInput) {
    this.filetoUpload = fileInput.target.files[0];
  }
}