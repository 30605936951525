import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BatchReviewCasesComponent } from './batch-review-cases/batch-review-cases.component';
import { ReviewedCasesComponent } from './reviewed-cases/reviewed-cases.component';
const routes: Routes = [
  {
    path: '',
    children:[
      {
        path: '',
        redirectTo: '/batch-review-cases',
        pathMatch: 'full'
      },
      {
        path: 'batch-review-cases',
        component: BatchReviewCasesComponent,
      },
      {
        path: 'reviewed-cases',
        component: ReviewedCasesComponent,
      },
    ]
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReviewCasestRoutingModule { }
