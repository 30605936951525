import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../../shared/services/api.service';
import { CommonService } from '../../../../shared/services/common.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.css']
})
export class ExceptionComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  expTitle: string = "Exception";

  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  p: number = 1;
  exceptionList: any = [];
  fromDate;
  toDate;
  FacilityList: any = [];
  facilityName: string = "";
  srchPatient: string = "";
  facilityID: number = 0;
  reqSelect = "OpNote Exceptions";
  formData: any = null;
  delData: any = [];
  role = "";

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Exception Queue");

    var val = localStorage.getItem('currentUser');
    if (val != "" && val != undefined) {
      let v = JSON.parse(val);
      this.role = v.role;
    }

    this.facilityID = this.route.snapshot.params.id;
    let curDate = new Date();
    let strdt = new Date(curDate.getTime() - (7 * 24 * 60 * 60 * 1000));

    this.fromDate = new Date((strdt.getMonth() + 1) + "/" + strdt.getDate() + "/" + strdt.getFullYear());
    this.toDate = new Date((curDate.getMonth() + 1) + "/" + curDate.getDate() + "/" + curDate.getFullYear());


    console.log(this.fromDate);
    console.log(this.toDate);

    this.getFacilityInfo();
  }

  getFacilityInfo() {
    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
        if (res.data[0] != undefined) {
          if (this.facilityID == 0) {
            this.facilityName = res.data[0].name;
            this.facilityID = res.data[0].id;
          }
          else {
            let val = this.FacilityList.find(x => x.id == this.facilityID);
            this.facilityName = val.name;
          }

        }
        this.getData();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }


  onCloseFormModal() {
    this.shouldShowFormModal = false;
    this.getData();
  }

  showFormModal(data) {
    var fDate = new Date(this.fromDate);
    var tDate = new Date(this.toDate);
    var fromDt = fDate.getFullYear() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getDate();
    var toDt = tDate.getFullYear() + "/" + (tDate.getMonth() + 1) + "/" + tDate.getDate();

    data["facilityName"] = this.facilityName;
    data["fromDate"] = fromDt;
    data["toDate"] = toDt;
    data["reqSelect"] = this.reqSelect;

    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  getData() {
    var fDate = new Date(this.fromDate);
    var tDate = new Date(this.toDate);
    var fromDt = fDate.getFullYear() + "/" + (fDate.getMonth() + 1) + "/" + fDate.getDate();
    var toDt = tDate.getFullYear() + "/" + (tDate.getMonth() + 1) + "/" + tDate.getDate();

    let url = "ExceptionQue/BindGridData";
    let data = {
      "FacilityName": this.facilityName,
      "rdoExceptionType": this.reqSelect,
      "txtBeginDate": fromDt,
      "txtEndDate": toDt,
      "pageNum": this.pagenumber,
      "pageSize": this.pageValue
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.exceptionList = res.data;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getData();
  }

  onFacilityChange() {
    let val = this.FacilityList.find(x => x.name == this.facilityName);
    this.facilityID = val.id;
    this.pagenumber = 1;
    this.getData();
  }

  removeFiles() {
    let url = "ExceptionQue/btnRemoveFileYes";
    let data = {};

    data = {
      "FacilityName": this.facilityName,
      "rdoExceptionType": this.reqSelect,
      "FilePath": this.delData.filePath,
      "txtBeginDate": this.delData.fromDate,
      "txtEndDate": this.delData.toDate
    }


    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success('File Removed Successfully', 'Success!');
        this.delData = [];
        this.getData();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getFile(fileName) {
    this.spinner.show();
    let data = { "FileName": fileName };

    this.getApiService.post4File("ExceptionQue/DownloadFile", data).subscribe(res => {

      if (res.type != "application/json") {
        saveAs(res, fileName);
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }


}
