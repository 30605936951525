<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="fileSidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{kbMode}} Knowledge Base</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <div class="pb-3 form-fields">
            <h4 class="my-3">Knowledge Base </h4>
            <form action="" [formGroup]="KBForm">
                <div class="row">
                    <div class="form-group col-12 frm-margin-btm">
                        <div class="input-group">
                            <input type="text" class="form-control" formControlName="title" placeholder="KB Title"
                                required>
                                
                        </div>
                    </div>
                    <div class="form-group frm-margin-btm col-md-12">

                        <textarea class="form-control" formControlName="description" Placeholder="KB Description"
                            required></textarea>
                    </div>
                    <div class="form-group frm-margin-btm col-md-12">
                        <div class="input-group">
                             <input type="text" class="form-control" formControlName="category" placeholder="Category" required> 
                             <!-- <input type="text" class="form-control" formControlName="CategoryId" placeholder="facebook page id" > -->

                        </div>
                    </div>

                    <div class="form-group col-12">
                        <label for="inputFirstName" class="required">Upload File</label>
                        <input type="file" (change)="uploadFile($event)" class="form-control-file py-1" id="kbFile">
                    </div>
                </div>
            </form>

            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav ">Cancel</button>
                    <button (click)="onSubmit()" [attr.disabled]=" submit == 'Submiting' ? true : null" id="bt" 
                    class="btn btn-primary btn-patient-box"type="submit">
                        {{submit}}  
                         <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span></button>

                <!-- <button type="button" (click)="onSubmit()" class="btn btn-primary btn-patient-box">Submit</button> -->
            </div>
        </div>
    </div>
</div>
</div>
<!-- Sidenav-->