import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Attachment } from './attachment.model';

export interface AttachmentState extends EntityState<Attachment> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'attachment' })
export class AttachmentStore extends EntityStore<AttachmentState> {

  constructor() {
    super();
  }

}
