import { AfterViewInit, Component, ComponentFactoryResolver, EventEmitter, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { saveAs } from 'file-saver';
import { DashDetailHelper } from './helpers/dash-detail.helper';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { Observable, Subject } from 'rxjs';
import { Facility } from 'src/app/shared/state/facilities/facility.model';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { SelectionQuery } from 'src/app/shared/state/selection/selection.query';
import { map } from 'rxjs/operators';
import { CommandType } from './dash-detail.types';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { batchCaseReview, billerOnHold, bulkAction, codingReturned, implantLog, invoice, na, NameConstant, returned, unassigned } from 'src/app/shared/models/name-constant';
import { NsnAccordianComponent } from './shared/nsn-accordian/nsn-accordian.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccordianConfig, createAccordianConfig } from './shared/nsn-accordian/nsn-accordian-config';
import { CommandTypeHelper } from './helpers/command-config.helper';
import { CategoryItem, createCategoryItem } from 'src/app/shared/state/category-item/category-item.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { ConfirmationDialogService } from 'src/app/shared/components/confirmation-dialog/confimation-dialog.service';
import { AccessService } from 'src/app/shared/services/access.service';
import { data } from 'jquery';


@Component({
  selector: 'app-dash-detail',
  templateUrl: './dash-detail.component.html',
  styleUrls: ['./dash-detail.component.css']
})
export class DashDetailComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  @ViewChild('nsnAccordianHost', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
  
  accordianConfig: AccordianConfig;
  implantLog: NameConstant = implantLog;
  invoice: NameConstant = invoice;
  hasActivePatients$: Observable<boolean>;

  selectedIndex = -1;
  facility_id: any;
  shouldShowFormModal: boolean = false;
  shouldShowDetailModal: boolean = false;
  shouldShowQAOPFormModal: boolean = false;
  shouldShowQAICFormModal: boolean = false;
  shouldShowQAATFormModal: boolean = false;
  shouldShowToolsModal: boolean = false;
  shouldShowReturnMailModal: boolean = false;
  shouldShowBulkActionModal: boolean = false;
  shouldShowReviewCasesModal: boolean = false;
  shouldShowCodingModal: boolean = false;
  SelectAllCheckboxForGrid: boolean = false;
  formData: any = null;
  p: any = {};
  pageValue: any = 10;
  totalRows: any;
  pagenumber: number = 1;
  patientList: any[];
  allpatientQdata: {};
  allpatientttlrws: any = [];
  CategoryArry: any[];
  defCategoryArry: any = {};
  facilityID: any;
  commandtype: string;
  sortType: string = "desc";
  sortOrder: string = "dateOfSurgery";

  catId = 0;
  searchValue: any = "";
  tblVsbl = true;
  IndVsbl = false;
  gridCheckboxStatus = false;
  monthNumber: any;
  yearNumber: any;
  monthName: any;
  catsrchVal: any;
  FacilityList$: Observable<Facility[]>;
  caseBilledCommandType = CommandType.CaseBilled.toLowerCase();
  commadnSelection$: Observable<string>;
  billerOnHold: NameConstant = billerOnHold;
  showBillerOnHoldChildAccordian: boolean = false;
  catArry: boolean = false;

  dashDetailHelper: DashDetailHelper = new DashDetailHelper();
  bulkActionAllowList: NameConstant[] = [codingReturned, unassigned, returned, billerOnHold, implantLog];
  selectedCommand: string;

  reviewCases: NameConstant = batchCaseReview;
  bulkAction: NameConstant = bulkAction;
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private commonUtil: CommonService,
    private facilityService: FacilityService,
    private facilityQuery: FacilityQuery,
    private selectionService: SelectionService,
    private selectionQuery: SelectionQuery,
    private patientService: PatientService,
    private patientQuery: PatientQuery,
    private resolver: ComponentFactoryResolver,
    private confirmationDialogService: ConfirmationDialogService,
    private spinner: NgxSpinnerService,
    private accessService :AccessService,
  ) { }

  ngOnInit(): void {
    this.monthNumber = this.route.snapshot.params.month;
    this.yearNumber = this.route.snapshot.params.year;
    this.commandtype = this.route.snapshot.params.type;
    this.facilityID = this.route.snapshot.params.id;
    this.catId = this.route.snapshot.params.cat;
    console.log(this.monthNumber, this.yearNumber, this.commandtype, this.facilityID, this.catId, "ddd");
    
    if(this.showAccordianComponent(this.commandtype)){
      this.loadChildAccordian(createCategoryItem({ value: this.commandtype }));
      this.getCategoryArry();
      this.catArry = true;
    }else{
      this.getDataList();
    }

    this.facilityService.bindDashboardFacilityData().subscribe();
    this.FacilityList$ = this.facilityQuery.selectAll();
    this.commadnSelection$ = this.selectionQuery.select().pipe(map(res => res.value));
    this.commonUtil.setTitle("Biller Station");
    this.hasActivePatients$ = this.patientQuery.selectActiveId().pipe(map(res => res && res.length > 0 ));

    //check if facility is already selected.
    this.checkAndSetDefaultFacility()
  }
  checkAndSetDefaultFacility() {
    const defaultFacilityID = localStorage.getItem("dashboardFacilityId");
    console.log("133==>",defaultFacilityID);
    if(defaultFacilityID != "0") {
      this.facilityID = defaultFacilityID;
      this.getDataByFacility();
    }
  }
  showAccordianComponent(commandType: string): boolean{
    return this.dashDetailHelper.loadNSNAccordianComponent(commandType);
  }

  isBulkActionEnabled(): boolean{
    const command = this.route.snapshot.params.type;
    return this.bulkActionAllowList.map(name => name.commandName).includes(command);
  }

  isReviewCasesEnabled(): boolean{
    // const command = this.route.snapshot.params.type;
    // return [codingReturned, returned, billerOnHold, implantLog].map(name => name.commandName).includes(command);
    return true;
  }

  getCategoryArry() {
   // alert("category array");
   
    if (this.catId > 0) {
      let data = {
        "facilityId": this.facilityID,
        "MonthNumber": this.monthNumber,
        "YearNumber": this.yearNumber,
        "CategoryId": this.catId
      }
      this.spinner.show();
      this.getApiService.post("StatusChange/BindCategoryItems", data).subscribe(res => {
        this.spinner.hide();
        if (res.status == "Success") {
          this.CategoryArry = res.data;
          let indx = this.CategoryArry.findIndex(x => x.name == "EOD");
          if (indx > -1) {
            this.CategoryArry.splice(indx, 1);
          }

          this.getDataList();
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      });
    }
  }

  getDataByFacility(){
    console.log('----------------------Id----------------');
    console.log(this.facilityID);
    localStorage.setItem("dashboardFacilityId",this.facilityID)
    this.catArry = false;
    this.pagenumber = 1;
    this.p[this.commandtype] = 1;
    this.getDataList();
    this.facilityService.setActive([this.facilityID]);
    
    this.removeChildAccordian();
    if(this.dashDetailHelper.loadNSNAccordianComponent(this.commandtype)){
      this.loadChildAccordian(createCategoryItem({ value: this.commandtype }));
    }
  }

  getDataByCat(event) {
    this.commandtype = event.target.value;
    this.pagenumber = 1;
    this.p[this.commandtype] = 1;
    console.log(this.commandtype, "commandtype");
    this.removeChildAccordian();
    // this.getCategoryArry();
    if(this.dashDetailHelper.loadNSNAccordianComponent(this.commandtype)){
      this.loadChildAccordian(createCategoryItem({ value: this.commandtype }));
    }
    this.getDataList();
  }

  async getDataList() {
   
   // this.spinner.show();
    this.patientService.reset();
    let relUrl = "";
    let data = {};
    this.sortType = "desc";
    this.sortOrder = "dateOfSurgery";

    let cmdType = this.commandtype;
    this.pagenumber = 1;

    if (this.dashDetailHelper.isBillerOnHold(this.commandtype)) {
      cmdType = "BillerOnHold";
    }

    if (this.catArry == false) {
     // this.spinner.hide();
    
      this.getCategoryArry();
      this.catArry = true;
      return;
    }

    this.selectionService.changeSelection(cmdType);
    this.selectedCommand = cmdType;
    const request = this.dashDetailHelper.buildRequest(this.catId, cmdType, this.facilityID, this.monthNumber, this.yearNumber, this.pagenumber, this.pageValue, this.defCategoryArry);
    relUrl = request.relUrl;
    data = request.data;
    this.defCategoryArry = request.defCategoryArry;

    if (relUrl != "" && cmdType != "null" && cmdType != "") {

      this.allpatientQdata = [];

      if (this.commandtype == "priority" || this.commandtype == "pendingclient" || this.commandtype == "dailyflow" || this.commandtype === billerOnHold.serverValue) {
        this.IndVsbl = false;

        if (this.CategoryArry != undefined) {
          for (let d of this.CategoryArry) {

            this.p[d.value] = 1;
            let cmd = d.value;

            if (this.dashDetailHelper.isBillerOnHold(this.commandtype)) {
              cmd = "BillerOnHold";
            }

            relUrl = "GetPatientsByStatusNew";

            if (this.catId != 7) {
              data = {
                "FacilityQueueId": this.facilityID,
                "CommandName": cmd,
                "pageNum": this.pagenumber,
                "pageSize": this.pageValue,
                "Isassigned": 1
              }
            }
            else {
              data = {
                "FacilityQueueId": this.facilityID,
                "CommandName": cmd,
                "MonthNumber": this.monthNumber,
                "YearNumber": this.yearNumber,
                "pageNum": this.pagenumber,
                "pageSize": this.pageValue,
                "Isassigned": 1
              }
            }


            if (d.value != "EOD" && d.value != "Exception") {
              this.spinner.show();
              this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
                this.spinner.hide();
                let pList = [];
                if (res.status == "Success") {
                  this.catsrchVal = this.commandtype;
                  pList = res.data;
                }
                else if (res.status == "Error") {
                  pList = [];
                  this.catsrchVal = "";
                  this.tblVsbl = false;
                  this.toastr.error(res.message, 'Error!');
                }

                if (res.data.length > 0) {
                  this.tblVsbl = true;
                  
                  this.allpatientttlrws[d.value] = res.data[0].totalcount;
                }
                else {
                  this.tblVsbl = false;
                }
                this.allpatientQdata[d.value] = pList;

              }, (error) => {
                this.spinner.hide();
                this.allpatientQdata[d.value] = [];
                this.catsrchVal = "";
                this.tblVsbl = false;
                this.toastr.error(error, 'Error!');
              });

            }
          }
        }
      }
      else {
        this.spinner.show();
        this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
          this.spinner.hide();
          this.IndVsbl = true;
          if (res.status == "Success") {
            this.catsrchVal = this.commandtype;
            this.allpatientQdata[this.commandtype] = res.data;
            // this.patientList = res.data;
          }
          else if (res.status == "Error") {
            this.allpatientQdata[this.commandtype] = [];
            this.catsrchVal = "";
            this.tblVsbl = false;
            this.toastr.error(res.message, 'Error!');
          }

          if (res.data?.length > 0) {
            this.tblVsbl = true;
            this.allpatientttlrws[this.commandtype] = res.data[0].totalcount;
            // this.totalRows = res.data[0].totalcount;
          }
          else {
            this.tblVsbl = false;
          }
        }, (error) => {
          this.spinner.hide();
          // this.patientList = [];
          this.allpatientQdata[this.commandtype] = [];
          this.catsrchVal = "";
          this.tblVsbl = false;
          this.toastr.error(error, 'Error!');
        });

      }
    }
    else {
      this.patientList = [];
      this.tblVsbl = false;
      this.catsrchVal = "";
    }
  }


  searchPatient() {
     
    this.removeChildAccordian();
    if(this.dashDetailHelper.loadNSNAccordianComponent(this.commandtype)){
      this.loadChildAccordian(createCategoryItem({ value: this.commandtype }));
    }

    let relUrl = "";
    let data = {};

    let cmdType = this.commandtype;
    this.pagenumber = 1;

    if (this.dashDetailHelper.isBillerOnHold(this.commandtype)) {
      cmdType = "BillerOnHold";
    }

    switch (cmdType) {
      case ("caseperformed"): {
        relUrl = "CasesPerformedPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        };
        var dcp = { "value": cmdType, "name": 'Cases Performed' };
        this.defCategoryArry["data"] = dcp;
        break;
      }
      case ("casebilled"): {
        relUrl = "CasesBilledPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        };
        this.defCategoryArry["data"] = { "value": cmdType, "name": 'Cases Billed' };
        break;
      }
      case ("avgDystoBill"): {
        relUrl = "AvgDaysToBillPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        }
        this.defCategoryArry["data"] = { "value": cmdType, "name": 'Avg Days To Bill' };
        break;
      }
      case ("avgDystoBillIntrnl"): {
        relUrl = "AvgDaysToBillInternalPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        }
        this.defCategoryArry["data"] = { "value": cmdType, "name": 'Avg Days To Bill Internal' };
        break;
      }
      case ("priority"): {
        relUrl = "PriorityItemsPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        };
        this.defCategoryArry["data"] = { "value": "priority", "name": 'Priority Items' };
        break;
      }
      case ("pendingclient"): {
        relUrl = "PendingClientPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        };
        this.defCategoryArry["data"] = { "value": "pendingclient", "name": 'Pending Client' };
        break;
      }
      case ("dailyflow"): {
        relUrl = "DailyFlowPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        };
        this.defCategoryArry["data"] = { "value": "dailyflow", "name": 'Daily Flow' };
        break;
      }
      case ("criticalitem"): {
        relUrl = "CriticalitemsPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          // "pageNum": this.pagenumber,
          // "pageSize": this.pageValue,
          "PatientName": this.searchValue,
        };
        this.defCategoryArry["data"] = { "value": cmdType, "name": 'Criticalitems' };
        break;
      }
      default: {
        relUrl = "GetPatientsByStatusNew";

        if (this.catId != 7) {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": cmdType,
            // "pageNum": this.pagenumber,
            // "pageSize": this.pageValue,
            "PatientName": this.searchValue,
            "Isassigned": 1
          }
          if (this.catId == 2) {
            this.defCategoryArry["data"] = { "value": "priority", "name": 'Priority Items' };
          }
          else if (this.catId == 3) {
            this.defCategoryArry["data"] = { "value": "pendingclient", "name": 'Pending Client' };
          }
          else if (this.catId == 5) {
            this.defCategoryArry["data"] = { "value": "dailyflow", "name": 'Daily Flow' };
          }
        }
        else {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": cmdType,
            "MonthNumber": this.monthNumber,
            "YearNumber": this.yearNumber,
            // "pageNum": this.pagenumber,
            // "pageSize": this.pageValue,
            "PatientName": this.searchValue,
            "Isassigned": 1
          }
        }
        break;
      }
    }

    if (relUrl != "" && cmdType != "null" && cmdType != "") {

      this.allpatientQdata = [];

      if (this.commandtype == "priority" || this.commandtype == "pendingclient" || this.commandtype == "dailyflow") {
        this.IndVsbl = false;

        if (this.CategoryArry != undefined) {
          for (let d of this.CategoryArry) {

            this.allpatientQdata[d.value] = [];
            this.allpatientttlrws[d.value] = 0;

            this.p[d.value] = 1;
            let cmd = d.value;
           
            if (this.dashDetailHelper.isBillerOnHold(this.commandtype)) {
              cmd = "BillerOnHold";
            }

            relUrl = "GetPatientsByStatusNew";

            if (this.catId != 7) {
              data = {
                "FacilityQueueId": this.facilityID,
                "CommandName": cmd,
                // "pageNum": this.pagenumber,
                // "pageSize": this.pageValue,
                "PatientName": this.searchValue,
                "Isassigned": 1
              }
            }
            else {
              data = {
                "FacilityQueueId": this.facilityID,
                "CommandName": cmd,
                "MonthNumber": this.monthNumber,
                "YearNumber": this.yearNumber,
                // "pageNum": this.pagenumber,
                // "pageSize": this.pageValue,
                "PatientName": this.searchValue,
                "Isassigned": 1
              }
            }


            if (d.value != "EOD" && d.value != "Exception") {
              this.spinner.show();
              this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
                this.spinner.hide();
                let pList = [];
                if (res.status == "Success") {
                  this.catsrchVal = this.commandtype;
                  pList = res.data;
                }
                else if (res.status == "Error") {
                  pList = [];
                  this.catsrchVal = "";
                  this.tblVsbl = false;
                  this.toastr.error(res.message, 'Error!');
                }

                if (res.data.length > 0) {
                 
                  this.allpatientttlrws[d.value] = res.data[0].totalcount;
                }
                // else {
                //   this.tblVsbl = false;
                // }
                this.allpatientQdata[d.value] = pList;

              }, (error) => {
                this.spinner.hide();
                this.allpatientQdata[d.value] = [];
                this.catsrchVal = "";
                this.tblVsbl = false;
                this.toastr.error(error, 'Error!');
              });

            }
          }

        }
      }
      else {
        if(this.p){
          this.p[this.commandtype] = 1;
        }
        this.spinner.show();
        this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
          this.spinner.hide();
          this.IndVsbl = true;
          if (res.status == "Success") {
            this.catsrchVal = this.commandtype;
            this.allpatientQdata[this.commandtype] = res.data;

            // this.patientList = res.data;
          }
          else if (res.status == "Error") {
            this.allpatientQdata[this.commandtype] = [];
            this.catsrchVal = "";
            this.tblVsbl = false;
            this.toastr.error(res.message, 'Error!');
          }

          if (res.data.length > 0) {
            this.tblVsbl = true;
            
            this.allpatientttlrws[this.commandtype] = res.data[0].totalcount;
            // this.totalRows = res.data[0].totalcount;
          }
          else {
            this.tblVsbl = false;
          }
        }, (error) => {
          this.spinner.hide();
          // this.patientList = [];
          this.allpatientQdata[this.commandtype] = [];
          this.catsrchVal = "";
          this.tblVsbl = false;
          this.toastr.error(error, 'Error!');
        });

      }
    }
    else {
      this.patientList = [];
      this.tblVsbl = false;
      this.catsrchVal = "";
    }
  }

  exportData() {
    let relUrl = "";
    let data = {};

    switch (this.commandtype) {
      case ("caseperformed"): {
        data = {
          "FacilityQueueId": this.facilityID,
          "CommandName": 'CasesPerformed'
        }
        break;
      }
      case ("casebilled"): {
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "CommandName": 'CasesBilled'
        }
        break;
      }
      case ("avgDystoBill"): {
        relUrl = "AvgDaysToBillPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "CommandName": 'AVGDays'
        }
        break;
      }
      case ("avgDystoBillIntrnl"): {
        relUrl = "AvgDaysToBillInternalPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "CommandName": 'AVGDaysInternal'
        }
        break;
      }
      case ("priority"): {
        data = {
          "FacilityQueueId": this.facilityID,
          "CommandName": 'PriorityItems'
        }
        break;
      }
      case ("pendingclient"): {
        data = {
          "FacilityQueueId": this.facilityID,
          "CommandName": 'PendingClient'
        }
        break;
      }
      case ("dailyflow"): {
        data = {
          "FacilityQueueId": this.facilityID,
          "CommandName": 'DailyFlow'
        }
        break;
      }
      case ("criticalitem"): {
        data = {
          "CommandName": 'CriticalItems'
        }
        break;
      }
      default: {
        if (this.catId != 7) {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": this.commandtype
          }
        }
        else {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": this.commandtype,
            "MonthNumber": this.monthNumber,
            "YearNumber": this.yearNumber,

          }
        }
        break;
      }
    }

    this.getApiService.post4File("BillingNew/ExportPatientData", data).subscribe(res => {
      saveAs(res, this.commandtype.toUpperCase() + "_" + new Date().toISOString() + '.xlsx');
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  sortList(orderby, sortby, subtype) {

    let relUrl = "";
    let data = {};
    let type = subtype;
    this.sortType = sortby;
    this.sortOrder = orderby;

    if (this.dashDetailHelper.isBillerOnHold(this.commandtype)) {
      type = "BillerOnHold";
    }

    switch (type) {
      case ("caseperformed"): {
        relUrl = "CasesPerformedPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby

        }
        break;
      }
      case ("casebilled"): {
        relUrl = "CasesBilledPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby

        }
        break;
      }
      case ("avgDystoBill"): {
        relUrl = "AvgDaysToBillPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby
        }
        break;
      }
      case ("avgDystoBillIntrnl"): {
        relUrl = "AvgDaysToBillInternalPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby
        }
        break;
      }
      case ("priority"): {
        relUrl = "PriorityItemsPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby
        }
        break;
      }
      case ("pendingclient"): {
        relUrl = "PendingClientPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby
        }
        break;
      }
      case ("dailyflow"): {
        relUrl = "DailyFlowPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby
        }
        break;
      }
      case ("criticalitem"): {
        relUrl = "CriticalitemsPatientList";
        data = {
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": orderby,
          "SortDir": sortby
        }
        break;
      }
      default: {
        relUrl = "GetPatientsByStatusNew";
        if (this.catId != 7) {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": type,
            "pageNum": this.pagenumber,
            "pageSize": this.pageValue,
            "PatientName": this.searchValue,
            "OrderBy": orderby,
            "SortDir": sortby,
            "Isassigned": 1
          }
        }
        else {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": type,
            "MonthNumber": this.monthNumber,
            "YearNumber": this.yearNumber,
            "pageNum": this.pagenumber,
            "pageSize": this.pageValue,
            "PatientName": this.searchValue,
            "OrderBy": orderby,
            "SortDir": sortby,
            "Isassigned": 1
          }
        }
        break;
      }
    }
    this.spinner.show();
    this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
      this.spinner.hide();
      if (res.status == "Success") {
        this.allpatientQdata[subtype] = res.data;
        if (res.data.length > 0) {
          
          this.allpatientttlrws[subtype] = res.data[0].totalcount;
        }
      }
      else if (res.status == "Error") {
        this.allpatientQdata[subtype] = [];
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.allpatientQdata[subtype] = [];
      this.toastr.error(error, 'Error!');
    });

  }

  pageChange(event, subtype) {
    this.pagenumber = event;
    let relUrl = "";
    let data = {};
    let type = subtype;

    if (this.dashDetailHelper.isBillerOnHold(this.commandtype)) {
      type = "BillerOnHold";
    }

    switch (type) {
      case ("caseperformed"): {
        relUrl = "CasesPerformedPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType

        }
        break;
      }
      case ("casebilled"): {
        relUrl = "CasesBilledPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      case ("avgDystoBill"): {
        relUrl = "AvgDaysToBillPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      case ("avgDystoBillIntrnl"): {
        relUrl = "AvgDaysToBillInternalPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "MonthNumber": this.monthNumber,
          "YearNumber": this.yearNumber,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      case ("priority"): {
        relUrl = "PriorityItemsPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      case ("pendingclient"): {
        relUrl = "PendingClientPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      case ("dailyflow"): {
        relUrl = "DailyFlowPatientList";
        data = {
          "FacilityQueueId": this.facilityID,
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      case ("criticalitem"): {
        relUrl = "CriticalitemsPatientList";
        data = {
          "pageNum": this.pagenumber,
          "pageSize": this.pageValue,
          "PatientName": this.searchValue,
          "OrderBy": this.sortOrder,
          "SortDir": this.sortType
        }
        break;
      }
      default: {
        relUrl = "GetPatientsByStatusNew";
        if (this.catId != 7) {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": type,
            "pageNum": this.pagenumber,
            "pageSize": this.pageValue,
            "PatientName": this.searchValue,
            "OrderBy": this.sortOrder,
            "SortDir": this.sortType,
            "Isassigned": 1
          }
        }
        else {
          data = {
            "FacilityQueueId": this.facilityID,
            "CommandName": type,
            "MonthNumber": this.monthNumber,
            "YearNumber": this.yearNumber,
            "pageNum": this.pagenumber,
            "pageSize": this.pageValue,
            "PatientName": this.searchValue,
            "OrderBy": this.sortOrder,
            "SortDir": this.sortType,
            "Isassigned": 1
          }
        }
        break;
      }
    }
    this.spinner.show();
    this.getApiService.post("BillingNew/" + relUrl, data).subscribe(res => {
      this.spinner.hide();
      if (res.status == "Success") {

        this.allpatientQdata[subtype] = res.data;
        if (res.data.length > 0) {
          this.allpatientttlrws[subtype] = res.data[0].totalcount;
        }
      }
      else if (res.status == "Error") {
        this.allpatientQdata[subtype] = [];
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.allpatientQdata[subtype] = [];
      this.toastr.error(error, 'Error!');
    });

  }
  showDetailModal(data) {
    
    delete data.totalcount;
    console.log(data);
    // data.remove('totalcount')
    this.formData = data;
    // this.facility_id = data.facilityId;
    // this.facility_id = data.facilityId;
    console.log(this.facility_id)
    this.accessService.facility = data.facilityId;
    console.log (this.accessService.facility,"facility")
    this.facility_id = this.accessService.facility;
    console.log(this.formData)

    console.log("this.shouldShowDetailModal : ", this.shouldShowDetailModal);
    if (this.shouldShowDetailModal) {
      this.shouldShowDetailModal = false;
    }
    else {
      this.shouldShowDetailModal = true;
    }
  }


  showFormModal(data) {
    this.patientService.setAndActivate([data]);
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  showCodingModal(data){
    this.patientService.setAndActivate([data]);
    this.formData = data;
    if (this.shouldShowCodingModal) {
      this.shouldShowCodingModal = false;
    }
    else {
      this.shouldShowCodingModal = true;
    }
  }

  showQAOPFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAOPFormModal) {
      this.shouldShowQAOPFormModal = false;
    }
    else {
      this.shouldShowQAOPFormModal = true;
    }
  }

  showQAICFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAICFormModal) {
      this.shouldShowQAICFormModal = false;
    }
    else {
      this.shouldShowQAICFormModal = true;
    }
  }

  showQAATFormModal(data) {
    this.formData = data;
    if (this.shouldShowQAATFormModal) {
      this.shouldShowQAATFormModal = false;
    }
    else {
      this.shouldShowQAATFormModal = true;
    }
  }

  // outsideClick(){
  //   if (this.shouldShowToolsModal) {
  //     this.shouldShowToolsModal = false;
  //   }
  // }

  showToolsFormModal(data) {
    this.formData = data;
    this.patientService.setAndActivate([data]);
    if (this.shouldShowToolsModal) {
      this.shouldShowToolsModal = false;
    }
    else {
      this.shouldShowToolsModal = true;
    }
  }

  showReturnMail(data) {
    console.log('return mail data', data);
    this.selectionService.changeSelection(CommandType.ReturnedMail);
    this.selectedCommand = CommandType.ReturnedMail;
    this.formData = data;
    if (this.shouldShowReturnMailModal) {
      this.shouldShowReturnMailModal = false;
    }
    else {
      this.shouldShowReturnMailModal = true;
    }
  }

  onCloseFormModal() {
    console.log("Called in DashBoard");
    this.pagenumber = 1;
    this.p[this.commandtype] = 1;
    this.shouldShowFormModal = false;
    this.shouldShowCodingModal = false;
    this.shouldShowQAOPFormModal = false;
    this.shouldShowQAICFormModal = false;
    this.shouldShowQAATFormModal = false;
    this.shouldShowToolsModal = false;
    this.shouldShowDetailModal = false;
    this.shouldShowReturnMailModal = false;
    this.shouldShowBulkActionModal = false;
    this.shouldShowReviewCasesModal = false;
    this.SelectAllCheckboxForGrid = false;
    if(this.dashDetailHelper.loadNSNAccordianComponent(this.commandtype)){
      this.patientService.toggleAllActive();
    }else{
    this.selectionService.changeSelection("");
    this.selectedCommand = "";
    this.patientService.resetAndInactivateAll();
    this.getDataList();
    }
  }

  toggle(evt, index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = -1;
    }
  }

  loadChildAccordian(cat: CategoryItem) {
    this.entry.clear();
    const commandNameConfig =  CommandTypeHelper.getCommandNameFromConfigMap(cat);
    if(commandNameConfig === undefined){
      console.log("could not identify the command name for ", cat);
      return;
    }
    const factory = this.resolver.resolveComponentFactory(NsnAccordianComponent);
    const componentRef = this.entry.createComponent(factory);
    componentRef.instance.callerId = billerOnHold.id;
    componentRef.instance.isChildAccordian = true;
    //componentRef.instance.accordianConfigEvents = this.accordianConfigSubject.asObservable();
   
    componentRef.instance.accordianConfig = createAccordianConfig({ 
      facilityId: this.facilityID, 
      commandName: commandNameConfig.commandName, 
      searchValue: this.searchValue,
      catId: commandNameConfig?.childCatId,
      monthNumber: this.monthNumber,
      yearNumber: this.yearNumber,
      pageNum: this.pagenumber,
      pageSize: this.pageValue,
    });
    console.log("accordian config before nginit ", componentRef.instance.accordianConfig);
    componentRef.instance.ngOnInit();

  }
  removeChildAccordian() {
    console.log('removeChildAccordian...');
    this.patientService.reset();
    this.entry.clear();
  }

  isCategoryItemOptionIsSelected(cateValue: string, pCommandType: string): boolean {
    if (this.dashDetailHelper.isBillerOnHold(cateValue) && this.dashDetailHelper.isBillerOnHold(pCommandType)) {
      return true;
    }
    return cateValue === pCommandType;
  }
  
  startBulkAction(){
    this.shouldShowBulkActionModal = this.shouldShowBulkActionModal ? false : true;
  }

  startReviewCases(){
    this.shouldShowReviewCasesModal = this.shouldShowReviewCasesModal ? false : true;
  }

  selectRowOfAccordian(data, catValue){
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    if(this.patientQuery.getCount() === 0){
      this.patientService.set(this.allpatientQdata[catValue]);
    }
    
    if(this.patientQuery.hasActive(bdosid)){
      this.patientService.inActiveById(bdosid);
    }else{
      this.patientService.addActive(bdosid);
    }
  }

  selectRow(data){
    //this.updateSelectAllCheckboxforGrid()
    console.log(this.gridCheckboxStatus)
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    if(this.patientQuery.getCount() === 0){
      this.patientService.set(this.allpatientQdata[this.commandtype]);
    }
    
    if(this.patientQuery.hasActive(bdosid)){
      this.patientService.inActiveById(bdosid);
    }else{
      this.patientService.addActive(bdosid);
    }
    if(this.patientQuery.hasActive(bdosid)){
      this.updateSelectAllCheckboxforGridToTrue();
    } else {
      this.updateSelectAllCheckboxforGridToFalse();
    }
  }
  
  isRowActive(data){
    const bdosid = data.bdosid ? data.bdosid : data.dosId;
    this.patientQuery.hasActive(bdosid);
  }

  getFile(fileId, fileName){
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
      //console.log(res);
      //var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
      //console.log(JSON.stringify(res));
      //console.log(this.getApiService.webUrl + 'ClientImport/' +  res.demoFile);
      //window.open( this.getApiService.webUrl + 'ClientImport/' +  res.demoFile, "_blank", strWindowFeatures);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  updateSelectAllCheckboxforGridToFalse() {
    let allCheckboxes;
    let unchecked = false;
    var items:any = document.getElementsByClassName('gridAllCheckboxes') as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < items.length; i++) {
        let isChecked = items[i].checked;
        if(!isChecked) {  
          unchecked = true;
        } 
    }
    if(unchecked){
      this.SelectAllCheckboxForGrid = false;
    }
  }
  updateSelectAllCheckboxforGridToTrue() {
    let allCheckboxes;
    let checkedCount = 0;
    var items:any = document.getElementsByClassName('gridAllCheckboxes') as HTMLCollectionOf<HTMLElement>
    for (let i = 0; i < items.length; i++) {
        let isChecked = items[i].checked;
        if(isChecked) {  
          checkedCount++;
        } 
    }
    if(checkedCount == items.length){
      this.SelectAllCheckboxForGrid = true;
    }
  }

  checkAll(){
    this.SelectAllCheckboxForGrid=!(this.SelectAllCheckboxForGrid)
    let rowCount = document.getElementsByClassName("gridAllCheckboxes").length;
    let allCheckboxes;
    for (let i = 0; i < rowCount; i++) {
      let evt = new MouseEvent('click', { bubbles: true,cancelable: true,view: window}); 
      allCheckboxes = document.getElementsByClassName("gridAllCheckboxes")[i] as HTMLElement;
      if(this.SelectAllCheckboxForGrid && (!allCheckboxes.checked)){
        allCheckboxes.dispatchEvent(evt);
      }else if(!(this.SelectAllCheckboxForGrid) && allCheckboxes.checked){
        allCheckboxes.dispatchEvent(evt);
      }
    }
  }


  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    localStorage.setItem('pageValue',this.pageValue);
    this.pagenumber = 1;
    this.getDataByFacility();
  }
  
}
