import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ArDashboardComponent } from './dashboard/ar-dashboard.component';
import { PatientListComponent } from './patient-list/patient-list.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full'
      },
      {
        path: 'dashboard',
        component: ArDashboardComponent
      },
      {
        path: 'patient-list',
        component: PatientListComponent
      },
    ],
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArRoutingModule { }
