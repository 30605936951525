import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AccessService {
  access_array: string[] = [];
  facility:any;
  navbar_access: any = {
    //Dashboard

    dashboard: false,
    auditLog:false,

    d: false,

    dIncoding: false,
    dNSNHigh: false,
    dCodingExpert: false,
    dAuthReview: false,

    dSetBilled: false,
    dUndoSetBilled: false,
    dQueue: false,
    dUpload: false,

    //billing

    billing: false,

    //coding
    coding: false,

    //Posting

    posting: false,
    postingDrClientAdd1: false,
    postingDrClientAdd2: false,
    postingDrClientAdd3: false,
    postingDrClientAdd4: false,

    //Collections

    collections: false,

    //Ar

    ar: false,
    refunds: false,

    //patients

    patient: false,

    patients: false,
    addPatient: false,

    patientActionView: false,
    patientActionManage: false,
    patientActionEdit: false,

    assignCases: false,
    assignCasesAdd: false,

    //Qa

    qa: false,

    //Reports

    reports: false,
    caseQueue: false,
    management: false,
    productivityByUser: false,
    productivityByFacility: false,

    //KB

    kb: false,
    kbAdd: false,
    kbAction: false,

    //Masters

    masters: false,

    //admin

    admin: false,

    manageUsers: false,
    manageUsersAdd: false,
    manageUsersEdit: false,
    manageUsersStatus: false,

    importPayers: false,
    importPayersAdd: false,
    importPayersStatus: false,

    importPhysicians: false,
    importPhysiciansAdd: false,
    importPhysiciansStatus: false,

    importPatients: false,

    billingRules: false,
    billingRulesAdd: false,
    billingRulesEdit: false,
    billingRulesDelete: false,

    feeSchedule: false,
    feeScheduleAdd: false,
    feeScheduleEdit: false,
    feeScheduleDelete: false,

    EOD: false,
    EODAdd: false,
    EODEdit: false,
    EODDelete: false,

    //Center for client

    center: false,

    settings: false,

    //client
    ClientShow: false,
  };
  constructor() {}
}
