import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-dr-client',
  templateUrl: './dr-client.component.html',
  styleUrls: ['./dr-client.component.css']
})
export class DrClientComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowDRFormModal: boolean = false;
  shouldShowDRPPFormModal: boolean = false;
  shouldShowEOBFormModal: boolean = false;
  shouldShowEOBCASFormModal: boolean = false;

  formData: any = null;
  DrClientArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  privileges:any;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    public accessServices:AccessService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.privileges=this.accessServices.navbar_access
    // console.log(this.privileges,"indr-client")
    this.commonUtil.setTitle("DR Client");
    this.getDrClientList();
  }


  showDRFormModal() {
    this.formData = null;

    if (this.shouldShowDRFormModal) {
      this.shouldShowDRFormModal = false;
    }
    else {
      this.shouldShowDRFormModal = true;
    }
  }

  onCloseDRFormModal() {
    this.shouldShowDRFormModal = false;
  }

  showDRPPFormModal() {
    this.formData = null;

    if (this.shouldShowDRPPFormModal) {
      this.shouldShowDRPPFormModal = false;
    }
    else {
      this.shouldShowDRPPFormModal = true;
    }
  }

  onCloseDRPPFormModal() {
    this.shouldShowDRPPFormModal = false;
  }

  showEOBFormModal() {
    this.formData = null;

    if (this.shouldShowEOBFormModal) {
      this.shouldShowEOBFormModal = false;
    }
    else {
      this.shouldShowEOBFormModal = true;
    }
  }

  onCloseEOBFormModal() {
    this.shouldShowEOBFormModal = false;
  }

  showEOBCASFormModal() {
    this.formData = null;

    if (this.shouldShowEOBCASFormModal) {
      this.shouldShowEOBCASFormModal = false;
    }
    else {
      this.shouldShowEOBCASFormModal = true;
    }
  }

  onCloseEOBCASFormModal() {
    this.shouldShowEOBCASFormModal = false;
  }

  // onAddRecord(event) {
  //   if (event.data.status == "Success") {
  //     this.shouldShowFormModal = false;
  //     this.toastr.success(event.data.message, 'Success!');
  //     this.getDrClientList();
  //   }
  //   else if (event.data.status == "Error") {
  //     this.toastr.error(event.data.message, 'Error!');
  //     this.shouldShowFormModal = true;
  //   }
  // }


  // onEditRecord(event) {

  //   if (event.data.status == "Success") {
  //     this.shouldShowFormModal = false;
  //     this.toastr.success(event.data.message, 'Success!');
  //     this.getDrClientList();
  //   }
  //   else if (event.data.status == "Error") {
  //     this.toastr.error(event.data.message, 'Error!');
  //     this.shouldShowFormModal = true;
  //   }
  // }

  getDrClientList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue };

    // this.getApiService.post("DrClient/BindGridData", data).subscribe(res => {
    //   if (res.status == "Success") {
    //     this.DrClientArry = res.data;
    //     this.totalRows = res.data[0].totalcount;
    //   }
    //   else if (res.status == "Error") {
    //     this.toastr.error(res.message, 'Error!');
    //   }
    // }, (error) => {
    //   this.toastr.error(error, 'Error!');
    // });
  }

  // editDrClient(data) {
  //   this.formData = data;
  //   if (this.shouldShowFormModal) {
  //     this.shouldShowFormModal = false;
  //   }
  //   else {
  //     this.shouldShowFormModal = true;
  //   }
  // }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.DrClientArry = [];
    this.getDrClientList();
  }


  pageChange(event) {
    this.pagenumber = event;
    this.getDrClientList();
  }

}
