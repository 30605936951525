import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { CaseQueueFacilityReport } from './case-queue-facility-report.model';

export interface CaseQueueFacilityReportState extends EntityState<CaseQueueFacilityReport>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'case-queue-facility-report', idKey: 'facilityId' })
export class CaseQueueFacilityReportStore extends EntityStore<CaseQueueFacilityReportState> {

  constructor() {
    super();
  }

}
