import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-patient-billing',
  templateUrl: './patient-billing.component.html',
  styleUrls: ['./patient-billing.component.css']
})
export class PatientBillingComponent implements OnInit {
  selectedIndex = 0;

  constructor() { }

  ngOnInit(): void {
  }

  toggle(evt, index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = 0;
    }
  }
}
