import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-eob-cas-form',
  templateUrl: './eob-cas-form.component.html',
  styleUrls: ['./eob-cas-form.component.css']
})
export class EobCasFormComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  submit:any="Submit"
  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  onClickClose() {
    this.submit="Submiting"
    this.closeEmitter.emit();
  }

}
