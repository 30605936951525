export interface CustomQueue {
  id: number | string;
  queueName: string;
  description: string;
  isActive:number;
  totalcount: number;
  addedGroups?: GroupId[];
  addedFacilities?:  FacilityId[];
  addedPhysicians?: PhysicianId[];
  addedPayers?: PayerId[];
  addedCpt?: CptCode[];
}

export function createCustomQueue(params: Partial<CustomQueue>) {
  return {
    id: params.id,
    queueName: params.queueName,
    description: params.description,
    isActive: params.isActive,
    totalcount: params.totalcount
  } as CustomQueue;
}

export interface GroupId {
  GroupId: number | string;
  groupName?: string;
}

export function createGroupId(params: Partial<GroupId>) {
  return {
    ...params
  } as GroupId;
}

export interface FacilityId {
  FacilityId: number | string;
  name?: string;
}

export function createFacilityId(params: Partial<FacilityId>) {
  return {
    ...params
  } as FacilityId;
}

export interface PhysicianId {
  PhysicianId: number | string;
  fullName?: string;
  firstName?: string;
  lastName?: string;
}

export function createPhysicianId(params: Partial<PhysicianId>) {
  return {
    ...params
  } as PhysicianId;
}

export interface PayerId {
  PayerId: number | string;
}

export function createPayerId(params: Partial<PayerId>) {
  return {
    ...params
  } as PayerId;
}

export interface CptCode {
  CptCode: number | string;
}

export function createCptCode(params: Partial<CptCode>) {
  return {
    ...params
  } as CptCode;
}