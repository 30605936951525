<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">File Category</h1>
                    <a href="javascript:void(0);" (click)='showFormModal()' id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add Category
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="getCategories()"
                                        placeholder="Category Name"></label></div>
                            
                        </div>
                    </div>
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th>Category Name<i class="fas fa-arrow-up mr-1" (click)="sortListCategory('categoryName','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListCategory('categoryName','desc')"></i></th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            
                            <tfoot>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th>Category Name<i class="fas fa-arrow-up mr-1" (click)="sortListCategory('categoryName','asc')"></i>
                                        <i class="fas fa-arrow-down" (click)="sortListCategory('categoryName','desc')"></i></th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <!-- <tr>
                                <td>1</td>
                                <td>Category A</td>
                                <td><label class="custom-switch">
                                    <input type="checkbox" >
                                    <span class="slider"></span>
                                </label></td>
                                <td><a href="javascript:void(0)"
                                    class="btn btn-success icon-btn openMySidenav"><i
                                        class="fa fa-edit"></i></a></td>
                            </tr> -->
                            <tbody>
                               
                                <tr
                                    *ngFor='let data of categories | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <!-- <td>{{i+1}}</td> -->
                                    <td>{{data.categoryName}}</td>
                                    <td>
                                        <label class="custom-switch">
                                            <input type="checkbox" (click)="delCategoryMaster(data)"
                                                [checked]="data.isActive == 1">
                                            <span class="slider"></span>
                                        </label>
                                    </td>
                                   
                                    <td>
                                        <a href="javascript:void(0)" (click)="editCategoryMaster(data)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>
                                      
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- /.container-fluid -->

<!-- Sidenav-->
<!-- (editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)" -->
<app-category-form  [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
(editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)"
   >
</app-category-form>
<!-- Sidenav-->