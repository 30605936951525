<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size">EOD Hold</h1>
                    <span style="width: 30%;"><select class="custom-select form-control" [(ngModel)]="facilityID"
                            (change)="getEodArry(0)">
                            <option selected value="0">Select Facility</option>
                            <option [value]="f.id" *ngFor="let f of FacilityList">{{f.name}}</option>
                        </select></span>
                    <div class="btn_right_eod">
                        <a routerLink="/d/eod/{{facilityID}}" id="openMySidenav"
                            class="btn btn-primary btn-patient-box openMySidenav mr-1" role="button">
                            Back</a>
                        <a href="javascript:void(0)" (click)="delEod()"
                            class="btn btn-primary btn-patient-box openMySidenav mr-1" role="button">
                            Delete</a>
                    </div>

                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th></th>
                                    <th>Claim Type</th>
                                    <th>Name</th>
                                    <th>MRN</th>
                                    <th>Payer</th>
                                    <th>DoS</th>
                                    <th>Physician</th>
                                    <th>Charge Amount</th>
                                    <th>Billed</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <!-- <th>#</th> -->
                                    <th></th>
                                    <th>Claim Type</th>
                                    <th>Name</th>
                                    <th>MRN</th>
                                    <th>Payer</th>
                                    <th>DoS</th>
                                    <th>Physician</th>
                                    <th>Charge Amount</th>
                                    <th>Billed</th>
                                    <!-- <th>Action</th> -->
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of eodUCODArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <!-- <td>{{i+1}}</td> -->
                                    <td><input type="checkbox" (change)="getCheckboxValues($event, data.bdosid)"></td>
                                    <td>{{data.type}} </td>
                                    <td>{{data.name}}
                                        <span
                                            style="display: block;font-size: 10px;">#{{data.eodDate | date: "MM/dd/yyyy HH:MM a"}}
                                        </span>
                                    </td>
                                    <td>{{data.mrn}}</td>
                                    <td>{{data.insuranceProvider}}</td>
                                    <td>{{data.dateOfSurgery | date: "MM/dd/yyyy"}}</td>
                                    <td>{{data.physician}}</td>
                                    <td>$ {{data.claimValue}}</td>
                                    <td>{{data.actionBy}}</td>

                                    <!-- <td>

                                        <a href="javascript:void(0)" (click)="showFormModal(data)"
                                            class="btn btn-primary icon-btn openMySidenav1"><i class="fa fa-clipboard"
                                                aria-hidden="true"></i></a>
                                        <a href="javascript:void(0)" (click)="delEod(data.bdosid, data.facilityID)"
                                            class="btn btn-danger icon-btn"><i class="fa fa-trash-alt" title="Delete"></i></a>
                                    </td> -->
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->