import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService, MustMatch } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPwdForm: FormGroup;
  submitted = false;
  mismatchPwd: boolean = false;
  token: string;
  email: string;


  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private service: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) {

    this.token = this.activatedRoute.snapshot.params.token;
    this.email = this.activatedRoute.snapshot.params.email;

  }

  ngOnInit(): void {
    this.commonUtil.setTitle("Reset Password");

    let userDetails = JSON.parse(localStorage.getItem("currentUser"));

    this.resetPwdForm = this.formBuilder.group({
      UserId: [this.email, Validators.required],
      Token: [this.token, Validators.required],
      Password: ['', Validators.required, Validators.minLength(8)],
      ConfirmPassword: ['', [Validators.required, Validators.minLength(8)]]
    }, { validator: MustMatch('Password', 'ConfirmPassword') });
  }



  get f() { return this.resetPwdForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.resetPwdForm.invalid) {
      return;
    }

    this.service.post('ApplicationUser/ResetPassowrd', this.resetPwdForm.value).subscribe(
      res => {
        if (res.status == "Success") {

          this.toastr.success('Password Reset Successfully!', 'Success!');
          this.router.navigate(['/login']);
        }
        else if (res.status == "IdentityError") {
          this.toastr.error(res.message[0].description, res.message[0].code);
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
  }

}
