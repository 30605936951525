import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-kb',
  templateUrl: './kb.component.html',
  styleUrls: ['./kb.component.css']
})
export class KbComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  KBArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  privileges:any;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    public accessService:AccessService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.privileges=this.accessService.navbar_access
    this.commonUtil.setTitle("Knowledge Base");
    this.getKBArryList();
  }


  showFormModal() {
    this.formData = null;

    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      // this.getFacilityMasterList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }

  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getKBArryList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  getKBArryList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    this.getApiService.post("KB/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.KBArry = res.data;
        if (res.data.length > 0) {
          this.totalRows = res.data[0].totalcount;
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  editKB(data) {
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.KBArry = [];
    this.getKBArryList();
  }


  pageChange(event) {
    this.pagenumber = event;
    this.getKBArryList();
  }

  downloadfile(filename) {

    // let filetodownload = filename.split('-')[0];

    let downloadUrl = this.getApiService.baseUrl + "api/KB/DownloadFile?filename=" + filename;

    window.open(downloadUrl, "_blank");
  }

  deleteKB(data) {

    let jVal = { "id": data.id };
    this.getApiService.post("KB/DeleteKB", jVal).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getKBArryList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

}
