import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { SideNavComponent } from './components/side-nav/side-nav.component';
import { PageContentComponent } from './components/page-content/page-content.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { TableMenuConfigComponent } from './components/table-menu-config/table-menu-config.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAttachmentComponent } from './components/attachment/add-attachment/add-attachment.component';
import { ShowAttachmentComponent } from './components/attachment/show-attachment/show-attachment.component';
import { CustomDatePipe } from './directives/custom-date.pipe';
import { CustomCurrencyPipe } from './directives/custom-currency.pipe';
import { TimeInDayHourPipe } from './directives/time-in-day-hour.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NsnDropdownComponent } from './components/nsn-dropdown/nsn-dropdown.component';
import { NsnGridComponent } from './components/nsn-grid/nsn-grid.component';
import { NsnGridActiveQueueComponent } from './components/nsn-grid/nsn-grid-active-queue/nsn-grid-active-queue.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { CustomAccordianComponent } from './components/custom-accordian/custom-accordian.component';
import { TruncatePipe } from './directives/truncate.pipe';
import { DotToColonPipe } from './directives/dot-to-colon.pipe';
import { CamelToSentancePipe } from './directives/camel-to-sentance.pipe';
@NgModule({
  declarations: [
  FooterComponent,  
  NavBarComponent,
  SideNavComponent,
  PageContentComponent,
  TableMenuConfigComponent,
  AddAttachmentComponent,
  ShowAttachmentComponent,
  CustomDatePipe,
  CustomCurrencyPipe,
  TruncatePipe,
  DotToColonPipe,
  TimeInDayHourPipe,
  CamelToSentancePipe,
  ConfirmationDialogComponent,
  NsnDropdownComponent,
  NsnGridComponent,
  NsnGridActiveQueueComponent,
  CustomAccordianComponent
],
  imports: [
    CommonModule,
    RouterModule,
    NgxSpinnerModule,
    SelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule
  ],
  exports: [
    FooterComponent,   
    NavBarComponent,
    SideNavComponent,
    PageContentComponent,
    TableMenuConfigComponent,
    AddAttachmentComponent,
    ShowAttachmentComponent,
    CustomDatePipe,
    CustomCurrencyPipe,
    TruncatePipe,
    DotToColonPipe,
    CamelToSentancePipe,
    TimeInDayHourPipe,
    ConfirmationDialogComponent,
    NsnDropdownComponent,
    NsnGridComponent,
    CustomAccordianComponent
  ]
})
export class SharedModule { }
