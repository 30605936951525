import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-custom-field',
  templateUrl: './custom-field.component.html',
  styleUrls: ['./custom-field.component.css']
})
export class CustomFieldComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  fields: any[] = [];
  searchValue: string = "";
  CustomFieldForm: FormGroup;
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  this.getCustomFields();
    }
    sortListCustomFields(orderBy,sortDir){
      //let  data = { "FacilityId":this.edit_id,"pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue,"OrderBy":orderBy,  "SortDir":sortDir };
      let  data = {"OrderBy":orderBy,  "SortDir":sortDir };
      this.getApiService.post("CustomField/BindGridData", data).subscribe((resp: any) => {
  
        if (resp.status == "Success") {
          this.fields = resp.data;
          this.totalRows = resp.data[0].totalcount;
         // console.log(this.categories,"catgories");
          // this.selectedFacilities = resp.data;
  
        }
  
      }, (err) => {
        console.log(err);
      })
  }
    editCustomFieldMaster(data) {
  
      this.formData = data;
      if (this.shouldShowFormModal) {
        this.shouldShowFormModal = false;
        this.getCustomFields();
      }
      else {
        this.shouldShowFormModal = true;
      }
    }
  
    onEntryChange(event) {
      let value = event.target.value;
      this.pageValue = value;
      this.pagenumber = 1;
      this.fields = [];
      this.getCustomFields();
    }
  
    pageChange(event) {
      this.pagenumber = event;
      this.getCustomFields();
    }
    onAddRecord(event) {
      if (event.data.status == "Success") {
        this.shouldShowFormModal = false;
        this.toastr.success(event.data.message, 'Success!');
        this.getCustomFields();
      }
      else if (event.data.status == "Error") {
        this.toastr.error(event.data.message, 'Error!');
        this.shouldShowFormModal = true;
      }
    }
    onEditRecord(event) {

      if (event.data.status == "Success") {
        this.shouldShowFormModal = false;
        this.toastr.success(event.data.message, 'Success!');
        this.getCustomFields();
      }
      else if (event.data.status == "Error") {
        this.toastr.error(event.data.message, 'Error!');
        this.shouldShowFormModal = true;
      }
    }
    getCustomFields() {
      if (this.searchValue != '' && this.pagenumber != 1) {
        this.pagenumber = 1;
        this.p = 1;
      }
      
      let  data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };
     // let data = { "pageNum": 1, "pageSize": 10 };
  
      this.getApiService.post("CustomField/BindGridData", data).subscribe((resp: any) => {
  
        if (resp.status == "Success") {
          this.fields = resp.data;
          this.totalRows = resp.data[0].totalcount;
         // console.log(this.categories,"catgories");
          // this.selectedFacilities = resp.data;
  
        }
  
      }, (err) => {
        console.log(err);
      })
    }
    showFormModal() {
      this.formData = null;
  
      if (this.shouldShowFormModal) {
        this.shouldShowFormModal = false;
      }
      else {
        this.shouldShowFormModal = true;
      }
    }
    delCustomFieldMaster(delRow) {
  
      let data = { "id": delRow.id, "deleted": !delRow.deleted };
  
      this.getApiService.post("CustomField/DeleteCustomField", data).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success(res.message, 'Success!');
          this.getCustomFields();
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
    }
  
    onCloseFormModal() {
      this.shouldShowFormModal = false;
    }

}
