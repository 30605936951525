import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/services/api.service';
import { TabsQuery } from '../state/tabs/tabs.query';
import { CaseRequestCpt, createCaseRequestCpt } from '../state/case-request-cpt/case-request-cpt.model';
import { CaseRequestCptService } from '../state/case-request-cpt/case-request-cpt.service';
import { CaseRequestCptQuery } from '../state/case-request-cpt/case-request-cpt.query';
import { ToastrService } from 'ngx-toastr';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { Tab } from '../state/tabs/tab.model';
import { bdosid, bulkAction, na, NameConstant, nsnCpt, physicianCpt, variance } from 'src/app/shared/models/name-constant';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { CaseRequestHelper } from '../helper/case-request.helper';
import { NgxSpinnerService } from 'ngx-spinner';
import { CaseRequestInfoQuery } from '../state/case-request-info/case-request-info.query';
import { Variable } from '@angular/compiler/src/render3/r3_ast';

@Component({
  selector: 'app-case-request-cpt',
  templateUrl: './case-request-cpt.component.html',
  styleUrls: ['./case-request-cpt.component.css']
})
export class CaseRequestCptComponent implements OnInit, OnDestroy {
  @Input() callerId: string;
  @Input() bdosid: string | number;
  PatientsForm: FormGroup;
  CPTCodeListForm: FormArray;
  VarianceListForm: FormArray;

  searching = false;
  searchFailed = false;
  notFound = false;
  totalCost$: Observable<string>;

  activeFacilityId: string | number;
  activeTab$: Observable<Tab>;
  variance: NameConstant = variance;
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  isLoading$: Observable<boolean>;

  sub: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private tabsQuery: TabsQuery,
    private caseRequestCptService: CaseRequestCptService,
    private caseRequestCptQuery: CaseRequestCptQuery,
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private PatientQuery: PatientQuery,
    private toastr: ToastrService,
  ) {
    this.initForm();
  }

  initForm() {
    this.PatientsForm = this.fb.group({
      txtNote: ["", [Validators.required]],
      reqFile: ["", Validators.required],
      CPTCodeList: this.fb.array([
        this.getCptCodeList()
      ]),
      VarianceList: this.fb.array([
        this.getVarianceList()
      ])
    })
    // console.log(this.getCptCodeList(),"initial form")
  }


  getVarianceList() {
    return this.fb.group({
      nsn_cpt: ['', Validators.required],
      nsn_cpt_code: ['', Validators.required],
      nsn_Desc: ['', Validators.required],
      physician_cpt: ['', Validators.required],
      physician_cpt_code: ['', Validators.required],
      physician_Desc: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    this.CPTCodeListForm = <FormArray>this.PatientsForm.controls.CPTCodeList;
    this.VarianceListForm = <FormArray>this.PatientsForm.controls.VarianceList;
    this.activeFacilityId = this.PatientQuery.getActive().find(p => true).facilityId;
    this.activeTab$ = this.tabsQuery.selectActive();
    const activeTab = this.tabsQuery.getActive();
    if (this.isBulkAction()) {
      this.totalCost$ = this.caseRequestCptQuery.selectTotalCostByRequestAndPatient(activeTab.id, this.bdosid);
    } else {
      this.totalCost$ = this.caseRequestCptQuery.selectTotalCostFor(activeTab.id);
    }
    if (this.isBulkAction()) {
      if (this.caseRequestCptQuery.hasAnyEntityByRequestForPatient(activeTab.id, this.bdosid)) {
        this.syncFormToStore();
      }
    }
    this.syncFormWhenCptloadingHasFinished();

  }

  private syncFormWhenCptloadingHasFinished() {
    const chileSub = this.caseRequestCptQuery.selectLoading().subscribe(isLoading => {
      if (!isLoading) {
        console.log('Synching form to store');
        this.syncFormToStore();
      }
    });
    if (this.sub) {
      this.sub.add(chileSub);
    } else {
      this.sub = chileSub;
    }
  }


  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  getCptCodeList() {
    return this.fb.group({
      cpt: ['', Validators.required],
      CPTCode: ['', Validators.required],
      CPTDesc: ['', Validators.required],
      Rate: ['', Validators.required]
    });
  }

  syncFormToStore() {
    const activeTab = this.tabsQuery.getActive();
    if (activeTab === undefined) {
      return;
    }
    if (activeTab.id !== variance.id) {

      let cpts;
      if (this.isBulkAction()) {
        const bdosid = this.getBdosid();
        if (this.bdosidIsNotPresent(bdosid)) {
          this.toastr.error("could not evaluate bdosid");
          return;
        }
        cpts = this.caseRequestCptQuery.getCptByRequestAndPatientId(activeTab.id, bdosid);
        console.log(cpts,"in loop")
      } else {
        cpts = this.caseRequestCptQuery.findCptByComponentId(activeTab.id.toString());
        console.log(cpts,"in loop")
      }

      console.log("Sync form from store with cpt", cpts);
      const formArray = <FormArray>this.PatientsForm.controls['CPTCodeList'];
      for (let index = 0; index < cpts.length; index++) {
        const cptFromStore = cpts[index];
        if (index !== 0) {
          formArray.push(this.getCptCodeList());
        }

        const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
        let cptJson = { cpt: cptFromStore.cptCode, cptDesc: cptFromStore.cptDescription, rate: cptFromStore.rate, uploadDateTime: '', validFrom: '' };
        frmCntrl.controls['cpt'].setValue(cptJson);
        frmCntrl.controls['CPTCode'].setValue(cptFromStore.cptCode);
        frmCntrl.controls['CPTDesc'].setValue(cptFromStore.cptDescription);
        frmCntrl.controls['Rate'].setValue(cptFromStore.rate);


      }
    } else if (activeTab.id === variance.id) {
      const bdosid = this.getBdosid();
      if (this.bdosidIsNotPresent(bdosid)) {
        this.toastr.error("could not evaluate bdosid");
        return;
      }
      let cptMap: Map<number, CaseRequestCpt[]>;
      if (this.isBulkAction()) {
        cptMap = this.caseRequestCptQuery.groupAllVarianceByUIIndexForPatient(bdosid);
      } else {
        cptMap = this.caseRequestCptQuery.groupAllVarianceByUIIndex();    
      }
      this.buildFormControlWhenCptByUIIndexGiven(cptMap);
    } else {
      this.toastr.error("Unexpected error", "Active tab is not supported for cpt screen");
    }


  }

  private buildFormControlWhenCptByUIIndexGiven(cptMap: Map<number, CaseRequestCpt[]>){
    const formArray = <FormArray>this.PatientsForm.controls['VarianceList'];
    for (let index = 1; index < cptMap.size; index++) {
      formArray.push(this.getVarianceList());
    }
    cptMap.forEach((values: CaseRequestCpt[], index: number) => {

      const frmCntrl = <FormArray>this.VarianceListForm.controls[index];
      console.log("frmCntrl index", index);
      console.log("frmCntrl", frmCntrl);
      const cptNsn = values.find(v => v.codingType === nsnCpt.id);
      const cptPhysician = values.find(v => v.codingType === physicianCpt.id);
      if (cptNsn) {
        let nsnCptJson = { cpt: cptNsn.cptCode, cptDesc: cptNsn.cptDescription, rate: cptNsn.rate, uploadDateTime: '', validFrom: '' };
        frmCntrl.controls["nsn_cpt"].setValue(nsnCptJson);
        frmCntrl.controls["nsn_cpt_code"].setValue(cptNsn.cptCode);
        frmCntrl.controls["nsn_Desc"].setValue(cptNsn.cptDescription);
      }

      if (cptPhysician) {
        let phyCptJson = { cpt: cptPhysician.cptCode, cptDesc: cptPhysician.cptDescription, rate: cptPhysician.rate, uploadDateTime: '', validFrom: '' };
        frmCntrl.controls["physician_cpt"].setValue(phyCptJson);
        frmCntrl.controls["physician_cpt_code"].setValue(cptPhysician.cptCode);
        frmCntrl.controls["physician_Desc"].setValue(cptPhysician.cptDescription);
      }
    });
  }
  private bdosidIsNotPresent(pBdosid: string | number): boolean{
    return pBdosid === undefined
  }
  private getBdosid(): string | number | undefined{
    const bdosid = this.bdosid ? this.bdosid : this.caseRequestInfoQuery.getBdosid();
    return bdosid;
  }
  removeCptCodeList(id: number) {
    const activeTab = this.tabsQuery.getActive();
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    const frmCntrl = <FormArray>control.controls[id];
    var rt = frmCntrl.controls["Rate"].value;
    if (this.isBulkAction()) {
      const cptList = this.caseRequestCptQuery.getCptByRequestAtUIIndexForPatient(activeTab.id, id, this.bdosid);
      this.caseRequestCptService.removeAll(cptList.map(cpt => cpt.id));
    } else {
      //const caseRequestCptId = activeTab.id+"_"+frmCntrl.controls["CPTCode"].value;
      const requestId = activeTab.id;
      this.caseRequestCptService.removeCptAtUIIndex(id, requestId);
    }
    control.removeAt(id);
  }
  // we don't do anythinh here with respect to store as we save to store on select of cpt
  addCptCodeList() {
    const formArray = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    let formIndex = formArray.controls.length - 1
    const formGroup = formArray.controls[formIndex];
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[formIndex];
    const cpt = formGroup.value;
    console.log('addCptCodeList', cpt);
    if ((cpt.CPTCode + "").toString().trim() === "") {
      frmCntrl.controls["CPTCode"].setErrors({
        notFound: true
      });
      console.log((cpt.CPTCode).toString().trim() != ""+"CCC"+(cpt.CPTDesc).toString().trim())
      if((cpt.Rate).toString().trim() != "" && (cpt.cpt).toString().trim() != "" && (cpt.CPTDesc).toString().trim() != "") {
        this.addCPTCode(cpt)
      }
    }
    //console.log("we are "+ JSON.stringify(this.getCptCodeList()));
    formArray.push(this.getCptCodeList());
    //formArray.push(cpt);

  }
  private addCPTCode(cpt) {
    this.searchFailed = false;
    let rateInt = parseInt(cpt.Rate.substring(1));
    let data = { "FacilityId": this.activeFacilityId,'Cpt':cpt.cpt,'Cptdesc':cpt.CPTDesc,'Rate':rateInt};
    this.getApiService.post("FeeSchedule/AddFeeSchedule", data).subscribe(res => {
      this.toastr.success('CPT added') 
      this.searchFailed = false;
      if(res.status == "Success") {
        cpt.CPTCode = cpt.cpt;
        const activeTab = this.tabsQuery.getActive();
        const caseRequestCpt = createCaseRequestCpt({
          id: activeTab.id + "_" + cpt.cpt,
          requestId: activeTab.id,
          cptCode: cpt.cpt,
          cptDescription: cpt.CPTDesc,
          rate: rateInt,
          codingType: na.id,
          //uiIndex: na.id,
          approved: false,
          alreadySubmitted: false
        });
  
        // const hasCptOfRequest = this.caseRequestCptQuery.hasEntity(caseRequestCpt.id)
        // if (hasCptOfRequest) {
        //   this.toastr.warning("cpt already exists in current request");
        //   return;
        // }
        this.caseRequestCptService.add(caseRequestCpt);

      }

    }, (error) => {
      this.toastr.error(error, 'Error!');
    })
    //this.toastr.warning("New cpt code added."+this.activeFacilityId);
    //return;
  }

  addVarianceList() {
    const control = <FormArray>this.PatientsForm.controls['VarianceList'];
    control.push(this.getVarianceList());
  }

  removeVarianceList(id: number) {
    const control = <FormArray>this.PatientsForm.controls['VarianceList'];
    if (this.isBulkAction()) {
       const cptList = this.caseRequestCptQuery.getCptByRequestAtUIIndexForPatient(variance.id, id, this.bdosid);
       this.caseRequestCptService.removeAll(cptList.map(cpt => cpt.id));
    } else {
      const cptList = this.caseRequestCptQuery.getCptByRequestAtUIIndex(variance.id, id);
      this.caseRequestCptService.removeAll(cptList.map(cpt => cpt.id));
    }
    control.removeAt(id);
  }

  chng(id) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    let vl = frmCntrl.controls["cpt"].value;

    try {
      if (vl.cpt == undefined) {

        this.searching = false;
        // frmCntrl.controls["cpt"].setValue("");
        // frmCntrl.controls["CPTCode"].setValue("");
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: true
        });
      }
      else {
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: false
        });
        frmCntrl.controls["CPTCode"].setValue(vl.cpt);
      }
    }
    catch {
      console.log("catch");
      // frmCntrl.controls["cpt"].setValue("");
      // frmCntrl.controls["CPTCode"].setValue("");
      frmCntrl.controls["CPTCode"].setErrors({
        notFound: true
      });
    }
  }

  onSelectItem(event, index) {
    if (this.isBulkAction()) {
      console.log(this.isBulkAction(),"is bulk action")
      const activeTab = this.tabsQuery.getActive();
      if (!activeTab) {
        return;
      }

      if (!this.bdosid) {
        return;
      }
      const alreadySub = this.caseRequestCptQuery.hasEntity(activeTab.id + "_" + event.item.cpt + "_" + this.bdosid);
      const cptList = this.caseRequestCptQuery.getCptByRequestAtUIIndexForPatient(activeTab.id, index, this.bdosid);
      // if (cptList && cptList.length > 0) {
      //   this.caseRequestCptService.removeAll(cptList.map(cpt => cpt.id));
      // }

      let caseRequestCpt = createCaseRequestCpt({
        id: activeTab.id + "_" + event.item.cpt + "_" + this.bdosid+"_"+index,
        requestId: activeTab.id,
        cptCode: event.item.cpt,
        cptDescription: event.item.cptDesc,
        rate: event.item.rate,
        codingType: na.id,
        uiIndex: index,
        approved: false,
        alreadySubmitted: alreadySub,
        bdosid: this.bdosid
      });

      const hasCptOfRequest = this.caseRequestCptQuery.hasEntity(caseRequestCpt.id)
      if (hasCptOfRequest) {
      //   this.toastr.warning("cpt already exists in current request");
      //   return;
       caseRequestCpt = createCaseRequestCpt({
        id: activeTab.id + "_" + event.item.cpt + "_" + this.bdosid +"_"+index,
        requestId: activeTab.id,
        cptCode: event.item.cpt,
        cptDescription: event.item.cptDesc,
        rate: event.item.rate,
        codingType: na.id,
        uiIndex: index,
        approved: false,
        alreadySubmitted: alreadySub,
        bdosid: this.bdosid
      });
      }
      this.caseRequestCptService.add(caseRequestCpt);
    } else {
      console.log(this.isBulkAction(),"is bulk action")
      const activeTab = this.tabsQuery.getActive();
      const alreadySub = this.caseRequestCptQuery.hasEntity(activeTab.id + "_" + event.item.cpt);
      if (this.caseRequestCptQuery.hasCptAtUIIndex(index, activeTab.id)) {
        // this.caseRequestCptService.removeCptAtUIIndex(index, activeTab.id);
      }

      // let caseRequestCpt = createCaseRequestCpt({
      //   id: activeTab.id +"_"+ event.item.cpt,
      //   requestId: activeTab.id,
      //   cptCode: event.item.cpt,
      //   cptDescription: event.item.cptDesc,
      //   rate: event.item.rate,
      //   codingType: na.id,
      //   uiIndex: index,
      //   approved: false,
      //   alreadySubmitted: alreadySub
      // });
      let caseRequestCpt
      if (this.caseRequestCptQuery.hasCptAtUIIndex(index, activeTab.id)) {
        caseRequestCpt = createCaseRequestCpt({
          id: activeTab.id +"_"+ event.item.cpt+"_"+index,
          requestId: activeTab.id,
          cptCode: event.item.cpt,
          cptDescription: event.item.cptDesc,
          rate: event.item.rate,
          codingType: na.id,
          uiIndex: index,
          approved: false,
          alreadySubmitted: alreadySub,
          // bdosid:bdosid+"_"+index
        });
       
      }else{
        caseRequestCpt = createCaseRequestCpt({
          id: activeTab.id +"_"+ event.item.cpt+"_"+index,
          requestId: activeTab.id,
          cptCode: event.item.cpt,
          cptDescription: event.item.cptDesc,
          rate: event.item.rate,
          codingType: na.id,
          uiIndex: index,
          approved: false,
          alreadySubmitted: alreadySub,
          // bdosid:bdosid+"_"+index
        });

      }
      console.log(caseRequestCpt,"case request cpt")

      const hasCptOfRequest = this.caseRequestCptQuery.hasEntity(caseRequestCpt.id)
      // if (hasCptOfRequest) {
      //   this.toastr.warning("cpt already exists in current request");
      //   const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
      //   return;
      // }
      this.caseRequestCptService.add(caseRequestCpt);
    }
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
    frmCntrl.controls["CPTCode"].setValue(event.item.cpt);
    frmCntrl.controls["CPTDesc"].setValue(event.item.cptDesc);
    const formatRate = this.caseRequestHelper.formatAmount(event.item.rate);
    frmCntrl.controls["Rate"].setValue(formatRate);
  }



  chngVarianceDesc(index, type) {
    console.log('updating chngVarianceDesc');
    let cptCode = "";
    let desc = "";
    const frmCntrl = <FormArray>this.VarianceListForm.controls[index];
    if (type == 'nsn_cpt') {
      cptCode = frmCntrl.controls["nsn_cpt_code"].value;
      desc = frmCntrl.controls["nsn_Desc"].value;
    }
    else if (type == 'physician_cpt') {
      cptCode = frmCntrl.controls["physician_cpt_code"].value;
      desc = frmCntrl.controls["physician_Desc"].value;
    }

    if (this.isBulkAction()) {
      const activeTab = this.tabsQuery.getActive();
      const cptId = activeTab.id + "_" + cptCode + "_" + type + "_" + this.bdosid;
      this.caseRequestCptService.update(cptId, { cptDescription: desc });
    } else {
      const activeTab = this.tabsQuery.getActive();
      const cptId = activeTab.id + "_" + cptCode + "_" + type;
      this.caseRequestCptService.update(cptId, { cptDescription: desc });
    }
  }


  chngVariance(id, type) {
    const frmCntrl = <FormArray>this.VarianceListForm.controls[id];
    let vl = frmCntrl.controls[type].value;
    console.log(vl.cpt);
    try {
      if (vl == undefined) {

        if (type == 'nsn_cpt') {
          this.searching = false;
          frmCntrl.controls["nsn_cpt"].setValue("");
          frmCntrl.controls["nsn_cpt_code"].setValue("");
          frmCntrl.controls["nsn_Desc"].setValue("");
          frmCntrl.controls["nsn_cpt"].setErrors({
            notFound: true
          });
        }
        else if (type == 'physician_cpt') {
          this.searching = false;
          frmCntrl.controls["physician_cpt"].setValue("");
          frmCntrl.controls["physician_cpt_code"].setValue("");
          frmCntrl.controls["physician_Desc"].setValue("");
          frmCntrl.controls["physician_cpt"].setErrors({
            notFound: true
          });
        }
      }
      else {
        if (type == 'nsn_cpt') {
          frmCntrl.controls["nsn_cpt"].setErrors({
            notFound: false
          });
          frmCntrl.controls["nsn_cpt"].setValue(vl);
          // frmCntrl.controls["nsn_cpt_code"].setValue(vl);
        }
        else if (type == 'physician_cpt') {
          frmCntrl.controls["physician_cpt"].setErrors({
            notFound: false
          });
          frmCntrl.controls["physician_cpt"].setValue(vl);
          // frmCntrl.controls["physician_cpt_code"].setValue(vl);
        }
      }
    }
    catch {
      console.log("catch");
      if (type == 'nsn_cpt') {
        frmCntrl.controls["nsn_cpt"].setValue("");
        frmCntrl.controls["nsn_cpt_code"].setValue("");
        frmCntrl.controls["nsn_cpt"].setErrors({
          notFound: true
        });

      }
      else if (type == 'physician_cpt') {
        frmCntrl.controls["physician_cpt"].setValue("");
        frmCntrl.controls["physician_cpt_code"].setValue("");
        frmCntrl.controls["physician_cpt"].setErrors({
          notFound: true
        });

      }
    }
  }

  formatter = (x: { cpt: string }) => x.cpt;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.searchVal(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  searchVal(term: string) {
    if (term == '') {
      return of([]);
    }

    let data = { "CPTCode": term, "FacilityId": this.activeFacilityId };
    //let data = { "CPTCode": term, "FacilityId": 0 }; // for testing of cpt
    return this.getApiService
      .post("CreatePatient/GetCPT", data).pipe(
        map(response => response.dataCPTInfo)
      );
  }

  onVarSelectItem(event, index, type) {
    if (this.isBulkAction()) {
      const activeTab = this.tabsQuery.getActive();
      if (!activeTab) {
        this.toastr.error("Active tab is undefined, please try again");
        return;
      }

      if (!this.bdosid) {
        this.toastr.error("patient is still loading, please try again");
        return;
      }
      const cptList = this.caseRequestCptQuery.getCptByRequestAndTypeAtUIIndexForPatient(variance.id, index, type, this.bdosid);
      if (cptList && cptList.length > 0) {
        this.caseRequestCptService.removeAll(cptList.map(cpt => cpt.id));
      }

      const caseRequestCpt = createCaseRequestCpt({
        id: activeTab.id + "_" + event.item.cpt + "_" + type + "_" + this.bdosid,
        requestId: activeTab.id,
        cptCode: event.item.cpt,
        cptDescription: event.item.cptDesc,
        rate: event.item.rate,
        codingType: type,
        uiIndex: index,
        approved: false,
        alreadySubmitted: false,
        bdosid: this.bdosid
      });

      const hasCptOfRequest = this.caseRequestCptQuery.hasEntity(caseRequestCpt.id)
      // if (hasCptOfRequest) {
      //   this.toastr.warning("cpt already exists in current request");
      //   return;
      // }
      this.caseRequestCptService.add(caseRequestCpt);
    } else {
      const activeTab = this.tabsQuery.getActive();
      const alreadySub = this.caseRequestCptQuery.hasEntity(activeTab.id + "_" + event.item.cpt + "_" + type);
      const caseRequestCpt = createCaseRequestCpt({
        id: activeTab.id + "_" + event.item.cpt + "_" + type,
        requestId: activeTab.id,
        cptCode: event.item.cpt,
        cptDescription: event.item.cptDesc,
        rate: event.item.rate,
        codingType: type,
        uiIndex: index,
        approved: false,
        alreadySubmitted: alreadySub
      });
      const hasCptOfRequest = this.caseRequestCptQuery.hasEntity(caseRequestCpt.id)
      // if (hasCptOfRequest) {
      //   this.toastr.warning("cpt already exists in current request");
      //   const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
      //   return;
      // }
      this.caseRequestCptService.add(caseRequestCpt);
    }

    const frmCntrl = <FormArray>this.VarianceListForm.controls[index];
    console.log("form contrail ", frmCntrl);
    if (type == 'nsn_cpt') {
      frmCntrl.controls["nsn_cpt"].setValue(event.item.cpt);
      frmCntrl.controls["nsn_cpt_code"].setValue(event.item.cpt);
      frmCntrl.controls["nsn_Desc"].setValue(event.item.cptDesc);
    }
    else if (type == 'physician_cpt') {
      frmCntrl.controls["physician_cpt"].setValue(event.item.cpt);
      frmCntrl.controls["physician_cpt_code"].setValue(event.item.cpt);
      frmCntrl.controls["physician_Desc"].setValue(event.item.cptDesc);
    }

  }
  updateCPTdescription(event, index) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
    const activeTab = this.tabsQuery.getActive();
    const cptCode = frmCntrl.controls["CPTCode"].value;
    let updatedCPTDesc = frmCntrl.controls["CPTDesc"].value;
    const id = activeTab.id + "_" + cptCode+"_"+index;
    const caseRequestCpt = createCaseRequestCpt({
      cptDescription: updatedCPTDesc
    });
    this.caseRequestCptService.update(id, caseRequestCpt);


    
    // let updatedCPTDesc = frmCntrl.controls["CPTDesc"].value;
    // console.log('UPdated Desccription', updatedCPTDesc);
    // const caseRequestCpt = createCaseRequestCpt({
    //   cptDescription: updatedCPTDesc
    // });
    // this.caseRequestCptService.update(index, caseRequestCpt);
    console.log(this.caseRequestCptQuery.getAllCpt());
  }
  transformAmount(event, index) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
    let amount = frmCntrl.controls["Rate"].value;
    if (isNaN(amount) && !(amount.match(/^\$/))) {
      frmCntrl.controls['Rate'].setValue("");
      frmCntrl.controls['Rate'].setErrors({ 'incorrect': true });
      // return false;
    } else {
      let formattedAmount = this.caseRequestHelper.formatAmount(amount);
      // Remove or comment this line if you dont want to show the formatted amount in the textbox.
      event.target.value = formattedAmount;
      this.chngRate(formattedAmount, index);
    }
  }
  // used only in high $$$ request both client and nsn at biller side as in facility side we have different read only component ( existing one )
  private chngRate(formattedAmount, index) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[index];
    const rate = frmCntrl.controls["Rate"].value;
    console.log('Change Rate', rate);
    frmCntrl.controls['Rate'].setValue(formattedAmount);
    if (this.isBulkAction()) {
      const activeTab = this.tabsQuery.getActive();
      const cptCode = frmCntrl.controls["CPTCode"].value;
      // const id = activeTab.id + "_" + cptCode + "_" + this.bdosid+"_"+index;
      const id = activeTab.id + "_" + cptCode+"_"+index;
      // const id = activeTab.id + "_" + cptCode +"_"+index
      const caseRequestCpt = createCaseRequestCpt({
        rate: this.caseRequestHelper.getAmountFromFormattedCurrency(formattedAmount)
      });
      this.caseRequestCptService.update(id, caseRequestCpt);
    } else {
      const activeTab = this.tabsQuery.getActive();
      const cptCode = frmCntrl.controls["CPTCode"].value;
      // const id = activeTab.id + "_" + cptCode;
      const id = activeTab.id + "_" + cptCode+"_"+index;
      const caseRequestCpt = createCaseRequestCpt({
        rate: this.caseRequestHelper.getAmountFromFormattedCurrency(formattedAmount)
      });
      this.caseRequestCptService.update(id, caseRequestCpt);
    }
  }


  private isBulkAction(): boolean {
    return this.callerId === bulkAction.id;
  }

}
