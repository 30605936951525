import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgetForm: FormGroup;
  submitted = false;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private service: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("Forgot Password");
    this.forgetForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });
  }
  get f() { return this.forgetForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.forgetForm.invalid) {
      return;
    }

    this.service.post('ApplicationUser/ForgotPwd', this.forgetForm.value).subscribe(
      res => {
        if (res.status == "Success") {

          this.toastr.success('Success!', 'Email Sent successfully!');
          this.router.navigate(['/login']);
        }
        else if (res.status == "IdentityError") {
          this.toastr.error(res.message[0].description, res.message[0].code);
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
  }

}
