export interface Comment {
  id: number | string;
  reqType: string;
  note: string;
  userid: string;
  createddate: Date;
}

export function createComment(params: Partial<Comment>) {
  return {
    ...params
  } as Comment;
}
