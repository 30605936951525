import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AttachmentService } from 'src/app/shared/components/attachment/state/attachment.service';
import { NameConstant, returnMail } from 'src/app/shared/models/name-constant';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';

@Component({
  selector: 'app-return-mail-action',
  templateUrl: './return-mail-action.component.html',
  styleUrls: ['./return-mail-action.component.css']
})
export class ReturnMailActionComponent implements OnInit {
  componentId: NameConstant = returnMail;
  @Output() closeEmitter = new EventEmitter();

  constructor(private attachmentService: AttachmentService, private patientQuery: PatientQuery) { }

  ngOnInit(): void {
    const bdosid = this.patientQuery.getActive()?.find(r => true)?.bdosid;
    this.attachmentService.loadAttachment(returnMail, bdosid);
    
  }

  onClickClose(){
    this.closeEmitter.emit();
  }

}
