export interface NsnSimpleDropDownData{
    id: string | number;
    displayValue: string;
}

export function createNsnSimpleDropDownData(params: Partial<NsnSimpleDropDownData>) {
    return {
      ...params
    } as NsnSimpleDropDownData;
  }
  
export interface NsnSimpleDropdown{
  selectedId: string | number;
  nsnSimpleDropDownDataList: NsnSimpleDropDownData[];
}

export function createNsnSimpleDrodown(selectedId: string, nsnSimpleDropDownDataList: NsnSimpleDropDownData[]){
  return {
    selectedId: selectedId,
    nsnSimpleDropDownDataList: nsnSimpleDropDownDataList
  }
}