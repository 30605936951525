import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { managementReportLine, NameConstant } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { createManagementReport, ManagementReport } from '../../state/management-report/management-report.model';
import { ManagementReportQuery } from '../../state/management-report/management-report.query';
import { ManagementReportService } from '../../state/management-report/management-report.service';
import { createManagementReportLine, ManagementReportLine } from './management-report-line';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-management-report-line',
  templateUrl: './management-report-line.component.html',
  styleUrls: ['./management-report-line.component.css']
})
export class ManagementReportLineComponent implements OnInit {

  @Input() selFromDate;
  @Input() selToDate;
  @Input() reportBy;

  componentName: NameConstant = managementReportLine;
  userColoumns: ColDef[] = [];
  facilityColoumns: ColDef[] = [];
  activeEntity: ManagementReport;
  activeEntityId$: Observable<string>;

  datasource$: Observable<ManagementReportLine[]>;
  loading: boolean;
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalRows: number;
  destroy$ = new Subject();
  constructor(
    private managementReportService: ManagementReportService,
    private managementReportQuery: ManagementReportQuery,
    private toaster: ToastrService,
    private apiService: ApiService,
    private commonUtil: CommonService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.initUserColoumns();
    this.initFacilityColoumns();
    this.initDataSource();
    this.activeEntityId$ = this.managementReportQuery.selectActiveId().pipe(map(res => res?.toString()));
    this.commonUtil.setTitle(this.componentName.value);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
  }
  back() {
    const activeId = this.managementReportQuery.getActiveId();
    this.managementReportService.toggleActive(String(activeId));
  }

  initDataSource() {
    this.loading = true;
    this.activeEntity = this.managementReportQuery.getActive();
    
    let apiName;
    let requestBody;
    console.log("report", this.reportBy);

    if (this.reportBy === 'user') {

      apiName = "Reports/ManagementByTeamUser";
      requestBody= {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "pageNum": this.currentPage,
        "pageSize": this.itemsPerPage,
        "userId": this.activeEntity.id
      };
    } else if (this.reportBy === 'facility') {
      apiName = "Reports/ManagementByTeamFacilityId";
      requestBody= {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "pageNum": this.currentPage,
        "pageSize": this.itemsPerPage,
        "facilityId": this.activeEntity.id
      };
    } else {
      console.log('no selection matches!!');
      return;
    }
    
    this.datasource$ = this.apiService.post(apiName, requestBody)
      .pipe(map(res => {
        if (res.status === 'Success') {
          this.loading = false;
          const entities: ManagementReportLine[] = res.data.map(r => createManagementReportLine({
            userId: r.userId,
            user: r.user,
            facilityID: r.facilityID,
            facilityName: r.facilityName,
            mrn: r.mrn,
            importDate: r.importDate,
            setBilledDate: r.setBilledDate,
            totalCount: r.totalcount
          }));
          return entities;
        } else {
          this.loading = false;
          this.toaster.error("Unexpected Error!!", res?.message);
        }
      }, err => {
        this.loading = false;
        this.toaster.error("Unexpected Error!!", err);
      }));

    this.datasource$.pipe(takeUntil(this.destroy$)).subscribe(entities => {
      this.totalRows = entities.find(e => true)?.totalCount;
      console.log("totalRows", this.totalRows);
    })
  }

  initUserColoumns() {
    this.userColoumns = [...this.userColoumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "user", displayName: "User", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "mrn", displayName: "MRN", isDataCol: true })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "importDate", displayName: "Loaded to Navigator", isDataTypeDate: true })];
    this.userColoumns = [...this.userColoumns, createColDef({ name: "setBilledDate", displayName: "Set Billed Date", isDataTypeDate: true })];

  }

  initFacilityColoumns() {
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "id", displayName: "", isIdCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "facilityName", displayName: "Facility Name", isDataCol: true, isDataTypeDate: false })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "mrn", displayName: "MRN", isDataCol: true })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "importDate", displayName: "Loaded to Navigator", isDataTypeDate: true })];
    this.facilityColoumns = [...this.facilityColoumns, createColDef({ name: "setBilledDate", displayName: "Set Billed Date", isDataTypeDate: true })];

  }
  pageChange(event$) {
    this.currentPage = event$;
    this.initDataSource();
  }

  onSearch() {
    this.initDataSource();
  }


  onExport(){
    this.activeEntity = this.managementReportQuery.getActive();
    
    let apiName;
    let requestBody;
    console.log("report", this.reportBy);

    if (this.reportBy === 'user') {

      apiName = "Reports/DownloadManagementByTeamUser";
      requestBody= {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "userId": this.activeEntity.id
      };
    } else if (this.reportBy === 'facility') {
      apiName = "Reports/DownloadManagementByTeamFacilityId";
      requestBody= {
        "fromDate": this.selFromDate,
        "toDate": this.selToDate,
        "facilityId": this.activeEntity.id
      };
    } else {
      console.log('no selection matches!!');
      return;
    }
 
    this.apiService.post4File(apiName, requestBody).subscribe(res => {
     // saveAs(res, this.componentName.value+".xlsx");
      let blob:any = new Blob([res], { type: 'text/json; charset=utf-8' });
      const fileName = this.reportBy === 'user'?  'user' : 'facilityName'; 
      saveAs(blob, this.reportBy+"_"+this.activeEntity[fileName]+'.xlsx');
    }, (error) => {
      this.toaster.error(error, 'Error!');
    });
  }

}
