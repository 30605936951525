<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">

            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Deposit Log </h1>

                </div>
                <div class="card-body">

                    <div class="row">
                        <div class="col-lg-6 col-md-6">

                            <div class="align-content-center">
                                <h6 class="texr-color-blue">Deposit From Daily Reconciliation</h6>
                            </div>

                            <div class="table-responsive data-list dl_table_datalist1">
                                <table class="table dl_table_datalist" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Date</th>
                                            <th>Batch</th>
                                            <th>Checks</th>
                                            <th>EFT</th>
                                            <th>Credit</th>
                                            <th>Care</th>
                                            <th>Cash</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>

                                        </tr>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>

                                        </tr>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>


                                        </tr>



                                    </tbody>
                                </table>
                            </div>




                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="align-content-center">
                                <h6 class="texr-color-blue">Enter Form Vision batch Report</h6>
                            </div>
                            <div class="table-responsive data-list dl_table_datalist1">
                                <table class="table dl_table_datalist" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Date</th>
                                            <th>Batch</th>
                                            <th>Checks</th>
                                            <th>EFT</th>
                                            <th>Credit</th>
                                            <th>Care</th>
                                            <th>Cash</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>


                                        </tr>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>


                                        </tr>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>



                                        </tr>



                                    </tbody>
                                </table>
                            </div>




                        </div>
                    </div>
                    <div class="row topgapp">




                        <div class="col-lg-6 col-md-6">
                            <div class="align-content-center">
                                <h6 class="texr-color-blue">Totals</h6>
                            </div>
                            <div class="table-responsive data-list">
                                <table class="table dl_table_datalist" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>

                                            <th>Checks</th>
                                            <th>EFT</th>
                                            <th>Credit card</th>
                                            <th>Care Credit</th>
                                            <th>Cash</th>
                                            <th>Total</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>

                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$3850000.00</td>

                                        </tr>




                                    </tbody>
                                </table>
                            </div>




                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="align-content-center">
                                <h6 class="texr-color-blue">Totals</h6>
                            </div>
                            <div class="table-responsive data-list">
                                <table class="table dl_table_datalist" id="dataTable" width="100%" cellspacing="0">
                                    <thead>
                                        <tr>

                                            <th>Month</th>
                                            <th>Date</th>
                                            <th>Total Payments</th>
                                            <th>Total Refunds<br>
                                                (Enter as Positive)</th>
                                            <th>Total Adjustments</th>
                                            <th>Net Deposit<br> Total PAS</th>
                                            <th>Variance</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>Jan</td>
                                            <td>01/22/20</td>
                                            <td>123</td>
                                            <td>$2000000.00</td>
                                            <td>$1000000.00</td>
                                            <td>$500000.00</td>
                                            <td>$250000.00</td>

                                        </tr>



                                    </tbody>
                                </table>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
            <!--end of 1-->

            <!--end of 2-->





        </div>
    </div>
</div>
<!-- /.container-fluid -->