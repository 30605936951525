import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { Physician } from './physician.model';

export interface PhysicianState extends EntityState<Physician, number>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'physicians' })
export class PhysicianStore extends EntityStore<PhysicianState> {

  constructor() {
    super(initialState);
  }

}
