<ngx-spinner bdColor="rgba(0, 0, 0, 0.05)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
    <p style="color: white"> Loading... </p>
</ngx-spinner>

<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                     <!-- Start of form -->
                   
                   
                    <div class="top-action-container">
                        <div class="item-facility">
                            <select class="custom-select dashboard-calnedar-patient1" 
                            [(ngModel)]="facilityID"
                            (change)="getPatientsList()"
                                >
                                <option selected value="0" >All Facilities</option>
                                <option [value]="facility.facilityId" *ngFor="let facility of facilities">{{facility.name}}</option>
                            </select>
                        </div>
                        <div class="item-category">
                            <select class="custom-select dashboard-calnedar-patient1" 
                            [(ngModel)]="patientId"
                            (change)="getDateOfSurgeryList()"
                                >
                                <option selected value="0" >Select Patients</option>
                                <option [value]="patient.patientID" *ngFor="let patient of patients">{{patient.name}}</option>
                            </select>
                        </div>
                        <div class="item-category">
                            <select class="custom-select dashboard-calnedar-patient1" 
                            [(ngModel)]="dateOfSurgery"
                                >
                                <option selected value="0" >Select Date of surgery</option>
                                <option [value]="dateOfSurgery.dos" *ngFor="let dateOfSurgery of dateOfSurgeries">{{dateOfSurgery.date}}</option>
                            </select>
                        </div>
                        <div class="item-review-cases" style="padding-left: 20px;">
                            <button type="submit" 
                            (click)="loadAuditData()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav"><i class="fa fa-search fa-fw"></i>Search</button>
                        </div>
                    </div> 


    <!-- End of Form -->
                </div>
                <!-- Start of table -->
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                       
                    </div>
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">{{col}}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th *ngFor="let col of columns">{{col}}</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                 *ngFor='let auditLog of auditLogs | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'
                                >
                                    <td>
                                        <!-- {{i+1}} -->
                                        {{(i+1) + ((p-1)*pageValue)}} 
                                    </td>
                                    <!-- <td>{{data.userName}}</td> -->
                                    <td>{{auditLog.username}}</td>
                                    <td>{{auditLog.adt}}</td>
                                    <td>{{auditLog.auditEventType}}</td>
                                    <td>{{auditLog.auditEventDescription}}</td>
                                    <td>{{auditLog.updateFrom}}</td>
                                   
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
                <!-- End of table -->



            </div>
        </div>
    </div>
</div>
