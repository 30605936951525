<div class="">
  <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="fileSidenav" class="sidenav shadow width-560 active">
      <div class="card-header d-flex flex-row align-items-center justify-content-between  card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Payer Data Import</h6>
        <a (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
          <i class="fas fa-times fa-sm"></i>
        </a>
      </div>
      <div class="card-body">
        <div class="pb-3 form-fields">
          <h4 class="my-3">Upload File</h4>
          <form action="">
            <div class="row">
              <div class="form-group col-12">
                <input type="file" accept=".csv" class="form-control-file py-1" (change)="uploadFile($event)" id="patientFile">
              </div>
            </div>
          </form>
          <div class="csv-file-text">
            <p>
              <span class="head-csv">CSV File Import Format</span>
              SourceSystemName,  Payer_SystemKey, 
              Payer_Name, Payer_Type, Payer_FinancialClass, Active
            </p>
            <p class="download-csv-file">
              <a href="javascript:void(0)" (click)="downloadfile()"><i class="fa fa-file-csv"></i>
                Download Sample File</a>
            </p>
          </div>
          <div class="text-right">
            <button type="button" (click)="onClickClose()"
              class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
            <button type="button" (click)="addRecord()" class="btn btn-primary btn-patient-box">Submit</button>
          </div>
        </div>
        <div class="note-text">
          <ul>
            <li>Import the Payer master when the zip file containing the payer master csv file is not of 0 kb.</li>
            <!--<li>If there is any error in the upload, error log will show the line it failed for the user to fix and
              reuplaod.</li> -->
          </ul>
        </div>
      </div>
    </div>
    </div>