import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { operatingReport, pathology, pricing , highApproval, highApprovalInternal, blockReport, codingQuery, otherMisc, inCoding, variance, authReview, codingExpert} from 'src/app/shared/models/name-constant';
import { CurrentCaseRequest } from './current-case-request.model';
import { CurrentCaseRequestStore, CurrentCaseRequestState } from './current-case-request.store';

@Injectable({ providedIn: 'root' })
export class CurrentCaseRequestQuery extends QueryEntity<CurrentCaseRequestState> {

  hasAnyRequest$: Observable<boolean> = this.selectCount().pipe(map(res => res && res > 0));
  
  constructor(protected store: CurrentCaseRequestStore) {
    super(store);
  }

  buildRequestWithFlags(){
    return {
    "Op": this.hasEntity(operatingReport.id),
    "Pathology": this.hasEntity(pathology.id),
    "Pricing": this.hasEntity(pricing.id),
    "clientHigh": this.hasEntity(highApproval.id),
    "NSNHigh": this.hasEntity(highApprovalInternal.id),
    "BlockReport": this.hasEntity(blockReport.id),
    "codingquery": this.hasEntity(codingQuery.id),
    "otherMisc": this.hasEntity(otherMisc.id),
    "InCoding": this.hasEntity(inCoding.id),
    "Variance": this.hasEntity(variance.id),
    "AuthReview": this.hasEntity(authReview.id),
    "CodingExpert": this.hasEntity(codingExpert.id)
    }
  }

  selectCurrentRequestNotSubmitted(): Observable<CurrentCaseRequest[]>{
    return this.selectAll({
      filterBy: entity => !entity.alreadySubmitted
    });
  }

  getCurrentRequestNotSubmitted(): CurrentCaseRequest[] {
    return this.getAll({
      filterBy: entity => !entity.alreadySubmitted
    });
  }

  hasAnyUnsavedCurrentRequest(): boolean{
    const unsavedRequests =  this.getCurrentRequestNotSubmitted();
    return unsavedRequests && unsavedRequests.length > 0;
  }

   hasCurrentRequestType(id: string | number): string{
    return this.hasEntity(id) ? "true" : "false";
   }
}
