import { Injectable } from '@angular/core';
import { ID, QueryEntity } from '@datorama/akita';
import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { flatMap, map, tap } from 'rxjs/operators';
import { UsersQuery } from 'src/app/modules/admin/manage-users/state/users/users.query';
import { ApiService } from 'src/app/shared/services/api.service';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { PhysicianQuery } from 'src/app/shared/state/physician/physician.query';
import { CustomQueue } from './custom-queue.model';
import { CustomQueueStore, CustomQueueState } from './custom-queue.store';

@Injectable({ providedIn: 'root' })
export class CustomQueueQuery extends QueryEntity<CustomQueueState> {

  constructor(
    protected store: CustomQueueStore,
    private apiService: ApiService,
   ) {
    super(store);
  }

  selectAllInactive(): Observable<CustomQueue[]>{
    const all$ = this.selectAll();
    const active$: Observable<CustomQueue[]> = this.selectActive();
    const inactive$ = combineLatest([all$, active$]).pipe(
      map(([all, active]) => this.filterInactive(all, active))
    );
    return inactive$;
  }

  getAllInactive(): CustomQueue[]{
    return this.getAll({
      filterBy: e => !this.getActiveId().includes(e.id)
    })
  }

  private filterInactive(all: CustomQueue[], active: CustomQueue[]): CustomQueue[]{
    return  all.filter((el) => !this.isActive(el, active))
   }
   
   private isActive(queue: CustomQueue, active: CustomQueue[]): boolean{
     if(!active || active.length === 0 )
       return false;

       console.log('***isActive***', active);
       if(!Array.isArray(active))
           active = [active];
           
      console.log('***isActive***', active);
    
       return active.some(a => a.id === queue.id);
    
   }
}
