import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { createNSNDropdownConfig, NSNDropdownConfig } from 'src/app/shared/components/nsn-dropdown/nsn-dropdown.component.types';
import { ColDef, createColDef } from 'src/app/shared/models/col-def.model';
import { httpPOSTRequestMethod, NameConstant, toolsQaImplantLogComponent } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { CaseRequestInfoQuery } from '../../patient-action/case-request/state/case-request-info/case-request-info.query';
import { createImplantLogPayer } from '../state/implant-log-payer/implant-log-payer.model';
import { ImplantLogPayerQuery } from '../state/implant-log-payer/implant-log-payer.query';
import { ImplantLogPayerService } from '../state/implant-log-payer/implant-log-payer.service';
import { createToolsQaImplantLog, ToolsQaImplantLog } from '../state/tools-qa-implant-log/tools-qa-implant-log.model';
import { ToolsQaImplantLogQuery } from '../state/tools-qa-implant-log/tools-qa-implant-log.query';
import { ToolsQaImplantLogService } from '../state/tools-qa-implant-log/tools-qa-implant-log.service';
import * as fileSaver from 'file-saver';
// import { type } from 'os';
@Component({
  selector: 'app-tools-qa-implant-log',
  templateUrl: './tools-qa-implant-log.component.html',
  styleUrls: ['./tools-qa-implant-log.component.css'],
  providers: [ NgxSpinnerService ]
})
export class ToolsQaImplantLogComponent implements OnInit, OnDestroy {
  componentName: NameConstant = toolsQaImplantLogComponent;
  ToolsQaImplantLogForm: FormGroup;
  dropdownConfig;
  selectedInsurance;
  submitted: boolean;
  loading: boolean;
  isEdit: boolean = false;
  coloumns: ColDef[] = [];
  toolsQaImplanLogList$: Observable<ToolsQaImplantLog[]>;
  unitPriceSub: Subscription;
  taxAndFreightSub: Subscription;
  totalCostSub: Subscription;
  chargeAmountSub: Subscription;
  quantitySub: Subscription;
  upSub: Subscription;
  formSub: Subscription;
  chargeFactorWhenTotalCostLessThan1000: number = 4;
  chargeFactorWhenTotalCostgreatorThanOrEq1000: number = 2.5;
  sumUnitPrice$: Observable<string>;
  sumTaxAndFrieght$: Observable<string>;
  sumTotalCost$: Observable<string>;
  sumChargeAmount$: Observable<string>;
  sumDueFromPayer$: Observable<string>;
  primaryInsurance: string;
 
  saveSubscription: Subscription;
  bindGridSub: Subscription;
  bindGridImplantLogPayerSub: Subscription;
  saveImaplantLogPayerSub: Subscription;
  caseRequestInfoSub: Subscription;
  updateSub: Subscription;
  delSub: Subscription;

  insuranceDrodownList;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: ApiService,
    private toolsQaImplantLogService: ToolsQaImplantLogService,
    private toolsQaImplantLogQuery: ToolsQaImplantLogQuery,
    private currencyPipe: CurrencyPipe,
    // private decimalPipe:DecimalPipe,
    private caseRequestInfoQuery: CaseRequestInfoQuery,
    private implantLogPayerService: ImplantLogPayerService,
    private implanrLogPayerQuery: ImplantLogPayerQuery,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.spinner.show();
    this.initDropdownConfig();
    this.loadInsuranceDropdwon({ pageNum: 1, pageSize: 25, SearchValue: "" });
    this.initImplantLogPayerStore();
    this.initForm();
    this.initColoumns();
    this.toolsQaImplanLogList$ = this.toolsQaImplantLogQuery.selectAll().pipe(map(res => {
      console.log(res,"at response 84")
      return res.map(r => createToolsQaImplantLog({
        id: r.id,
        toolsImplantLogId: r.toolsImplantLogId,
        claimLine: r.claimLine,
        hcpcs: r.hcpcs,
        description: r.description,
        unitPrice: this.currencyPipe.transform(r.unitPrice, 'USD', 'symbol', '1.2-2'),
        taxAndFreight: this.currencyPipe.transform(r.taxAndFreight, 'USD', 'symbol', '1.2-2'),
        totalCost: this.currencyPipe.transform(r.totalCost, 'USD', 'symbol', '1.2-2'),
        quantity: r.quantity,
        up: r.up,
        // up  : this.decimalPipe.transform(r.up, '2,2-2'),  
        chargeAmount: this.currencyPipe.transform(r.chargeAmount, 'USD', 'symbol', '1.2-2'),
        dueFromPayer: this.currencyPipe.transform(r.dueFromPayer, 'USD', 'symbol', '1.2-2')
      }));
    }));
    // console.log(typeof(up))
    this.updateTotalCost();
    this.updateChargeAmount();
    this.updateDueFromPayer();
    this.applyCurrencyPipe();

    this.sumUnitPrice$ = this.toolsQaImplantLogQuery.selectAll().pipe(map(res => {
      const sumUnitPrice = res.map(item => {
        return Number(item.unitPrice)
      }).reduce((prev, next) => prev + next, 0);
      return this.currencyPipe.transform(sumUnitPrice, 'USD', 'symbol', '1.2-2');
    }));

    this.sumTaxAndFrieght$ = this.toolsQaImplantLogQuery.selectAll().pipe(map(res => {
      const sumTaxAndFrieght = res.map(item => {
        return Number(item.taxAndFreight)
      }).reduce((prev, next) => prev + next, 0);
      return this.currencyPipe.transform(sumTaxAndFrieght, 'USD', 'symbol', '1.2-2');
    }));

    this.sumTotalCost$ = this.toolsQaImplantLogQuery.selectAll().pipe(map(res => {
      const sumtotalCost = res.map(item => {
        return Number(item.totalCost)
      }).reduce((prev, next) => prev + next, 0);
      return this.currencyPipe.transform(sumtotalCost, 'USD', 'symbol', '1.2-2');
    }));

    this.sumChargeAmount$ = this.toolsQaImplantLogQuery.selectAll().pipe(map(res => {
      const totalCharge = res.map(item => {
        return Number(item.chargeAmount)
      }).reduce((prev, next) => prev + next, 0);
      return this.currencyPipe.transform(totalCharge, 'USD', 'symbol', '1.2-2');
    }));

    this.sumDueFromPayer$ = this.toolsQaImplantLogQuery.selectAll().pipe(map(res => {
      const totalDueFromPayer = res.map(item => {
        return Number(item.dueFromPayer)
      }).reduce((prev, next) => prev + next, 0);
      return this.currencyPipe.transform(totalDueFromPayer, 'USD', 'symbol', '1.2-2')
    }));
  }

  ngOnDestroy() {
    if (this.unitPriceSub) {
      this.unitPriceSub.unsubscribe();
    }
    if (this.taxAndFreightSub) {
      this.taxAndFreightSub.unsubscribe();
    }

    if (this.totalCostSub) {
      this.totalCostSub.unsubscribe();
    }

    if (this.chargeAmountSub) {
      this.chargeAmountSub.unsubscribe();
    }

    if (this.quantitySub) {
      this.quantitySub.unsubscribe();
    }
    if (this.upSub) {
      this.upSub.unsubscribe();
    }
    if (this.formSub) {
      this.formSub.unsubscribe();
    }

    if (this.saveSubscription) {
      this.saveSubscription.unsubscribe();
    }

    if (this.bindGridSub) {
      this.bindGridSub.unsubscribe();
    }

    if (this.bindGridImplantLogPayerSub) {
      this.bindGridImplantLogPayerSub.unsubscribe();
    }

    if (this.caseRequestInfoSub) {
      this.caseRequestInfoSub.unsubscribe();
    }

    if (this.saveImaplantLogPayerSub) {
      this.saveImaplantLogPayerSub.unsubscribe();
    }

    if(this.updateSub){
      this.updateSub.unsubscribe();
    }

    if(this.delSub){
      this.delSub.unsubscribe();
    }
  }

  private getDecimalFromtext(text){
    return String(text).replace(/[^0-9.]/g, "")
    .replace(/\.{2,}/g,'.')
    .replace(/^0*([^0]\d*\.\d{1,2}).*/g, "$1");
  }
  applyCurrencyPipe() {
    this.formSub = this.ToolsQaImplantLogForm.valueChanges.subscribe(form => {
      if (form.unitPrice) {
        this.ToolsQaImplantLogForm.patchValue({
          unitPrice: this.getDecimalFromtext(form.unitPrice)
        }, { emitEvent: false });
      }

      if (form.taxAndFreight) {
        this.ToolsQaImplantLogForm.patchValue({
          taxAndFreight: this.getDecimalFromtext(form.taxAndFreight)
        }, { emitEvent: false });
      }

      if(form.quantity){
        this.ToolsQaImplantLogForm.patchValue({
          quantity: String(form.quantity).split(".")[0]
        }, { emitEvent: false });
      }

      if(form.up){
        this.ToolsQaImplantLogForm.patchValue({
          up : Number (form.up)
          // up: String(form.up).split(".")[0]
        }, { emitEvent: false });
      }

      if(form.claimLine){
        this.ToolsQaImplantLogForm.patchValue({
          claimLine: String(form.claimLine).split(".")[0]
        }, { emitEvent: false });
      }

      if (form.chargeAmount) {
        this.ToolsQaImplantLogForm.patchValue({
          chargeAmount: this.getDecimalFromtext(form.chargeAmount)
        }, { emitEvent: false });
      }

      if (form.dueFromPayer) {
        this.ToolsQaImplantLogForm.patchValue({
          dueFromPayer: this.getDecimalFromtext(form.dueFromPayer)
        }, { emitEvent: false });
      }
    });
  }

  initForm() {
    this.ToolsQaImplantLogForm = this.fb.group({
      claimLine: ['', Validators.required],
      hcpcs: ['', Validators.required],
      description: [''],
      unitPrice: ['', Validators.required],
      taxAndFreight: [''],
      totalCost: [{ value: '', disabled: true }, Validators.required],
      quantity: ['', Validators.required],
      up: ['', [Validators.pattern(/^\-?[0-9]+(?:\.[0-9]{1,2})?$/),Validators.required]],
      chargeAmount: ['', Validators.required],
      dueFromPayer: ['', Validators.required],
    });
  }
  get f() { return this.ToolsQaImplantLogForm.controls; }
  
  
  onc(){
    console.log(this.ToolsQaImplantLogForm )
  }

  initColoumns() {
    this.coloumns = [...this.coloumns, createColDef({ name: "claimLine", displayName: "Claim Line #"})];
    this.coloumns = [...this.coloumns, createColDef({ name: "hcpcs", displayName: "HCPCS" })];
    this.coloumns = [...this.coloumns, createColDef({ name: "description", displayName: "Description" })];
    this.coloumns = [...this.coloumns, createColDef({ name: "unitPrice", displayName: "Unit Price", isNumber: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "taxAndFreight", displayName: "Tax & Freight", isNumber: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "totalCost", displayName: "Total Cost", isNumber: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "quantity", displayName: "QTY", isNumber: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "up", displayName: "up", isNumber: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "chargeAmount", displayName: "Charge Amt.", isNumber: true })];
    this.coloumns = [...this.coloumns, createColDef({ name: "dueFromPayer", displayName: "Due From Payer", isNumber: true })];
    //this.coloumns = [...this.coloumns, createColDef({ name: "", displayName: "Action", isAction: true })];
  }

  saveIsunrancePatientMapping(requestBody){
    this.saveImaplantLogPayerSub = this.apiService.post("PaymentPlan/ImplantLogSave", requestBody).subscribe(ressave => {
      if (ressave.status === 'Success') {
        const responsedata = ressave.data ? ressave.data : ressave.datat;
        const entities = responsedata.map(r => createImplantLogPayer({
          id: r.id,
          bdosid: r.bdosid,
          payerId: r.payerId
        }));
        this.implantLogPayerService.set(entities);
      } else {
        this.toastr.error("server Error", ressave);
      }
    }, err => {
      this.toastr.error("Unexpected Error", err);
    });
  }

  initImplantLogPayerStore() {
    this.spinner.show();
    this.caseRequestInfoSub = this.caseRequestInfoQuery.selectAll().pipe(map(res => res.find(r => true))).subscribe(caseReqInfo => {
          const requestBody = { Bdosid: caseReqInfo.id, PayerName: caseReqInfo.dataDosDetails.insuranceProvider };
          this.bindGridImplantLogPayerSub = this.apiService.post("PaymentPlan/ImplantLogBindGrid", requestBody).subscribe(res => {
            this.spinner.hide();
            if (res.status === 'Success') {
              if (res.data.length === 0) {
                console.log("No response recieved for PaymentPlan/ImplantLogBindGrid with"+ JSON.stringify(requestBody));
              } else {
                const entities = res.data.map(r => createImplantLogPayer({
                  id: r.id,
                  bdosid: r.bdosid,
                  payerId: r.payerId,
                  payerName: r.payer_Name
                }));
                this.implantLogPayerService.set(entities);
                const payer = entities.find(e => true);
                this.primaryInsurance = payer.payerName;
                this.initStore(payer.id);
              }
            } else {
              this.toastr.error("server Error", res);
              this.implantLogPayerService.setError(res);
            }
          }, err => {
            this.spinner.hide();
            this.toastr.error("Unexpected Error", err);
            this.implantLogPayerService.setError(err);
          });
        
      }, err => {
          this.spinner.hide();
      });
  }

  initStore(toolsImplantLogId) {
    if(!toolsImplantLogId){
      console.log("No toolsImplantLogId is present so cannot load implant log details");
      return;
    }
    this.bindGridSub = this.apiService.post("PaymentPlan/ImplantLogDetailsBindGrid", { ToolsImplantLogId: toolsImplantLogId }).subscribe(res => {
      if (res.status === 'Success') {
        console.log(res.data,"res at 346")
        const entities = res.data.map(r => createToolsQaImplantLog({
          id: r.id,
          toolsImplantLogId: r.toolsImplantLogId,
          claimLine: r.claimLineNo,
          hcpcs: r.hcpcs,
          description: r.descp,
          unitPrice: r.unitPrice,
          taxAndFreight: r.taxFreight,
          totalCost: r.totalCost,
          quantity: r.quantity,
          up: r.up,
          chargeAmount: r.chargeAmount,
          dueFromPayer: r.dueAmount
        }));

        this.toolsQaImplantLogService.set(entities);
      } else {
        this.toastr.error("Error!!", res.message);
      }
    }, err => {
      this.toastr.error("Unexpected Error!!", err?.message);
    });
  }
  private getUnitPrice() {
    return this.ToolsQaImplantLogForm.get("unitPrice").value;
  }

  private getTaxAndFrieght() {
    return this.ToolsQaImplantLogForm.get("taxAndFreight").value;
  }

  getQuantity(): number {
    const anyValue = this.ToolsQaImplantLogForm.get("quantity").value;
    return Number(anyValue);
  }

  getup(): number {
    const anyValue = this.ToolsQaImplantLogForm.get("up").value;
    return Number(anyValue);
  }

  getTotalCost(): number {
    const anyValue = this.ToolsQaImplantLogForm.get("totalCost").value;
    return Number(anyValue);
  }

  private updateTotalCost() {
    this.unitPriceSub = this.ToolsQaImplantLogForm.get("unitPrice").valueChanges.subscribe(unitPrice => {
      const taxAndFrieght = this.getTaxAndFrieght();
      this.setTotalCost(unitPrice, taxAndFrieght);
    });

    this.taxAndFreightSub = this.ToolsQaImplantLogForm.get("taxAndFreight").valueChanges.subscribe(taxAndFrieght => {
      const unitPrice = this.getUnitPrice();
      this.setTotalCost(unitPrice, taxAndFrieght);
    });
  }

  private updateChargeAmount() {
    this.totalCostSub = this.ToolsQaImplantLogForm.get("totalCost").valueChanges.subscribe(totalCost => {
      const quantity = this.getQuantity();
      const up = this.getup();
      console.log(up);
      this.setChargeAmount(totalCost, quantity,up);
    });

    this.quantitySub = this.ToolsQaImplantLogForm.get("quantity").valueChanges.subscribe(quantity => {
      const totalCost = this.getTotalCost();
      const up = this.getup();
      console.log(up);
      this.setChargeAmount(totalCost, quantity,up);
    });
    this.upSub = this.ToolsQaImplantLogForm.get("up").valueChanges.subscribe(up => {
      const totalCost = this.getTotalCost();
      const quantity = this.getQuantity();
      // console.log(up);
      console.log(up);
      this.setChargeAmount(totalCost, quantity,up);
    });
  }

  private updateDueFromPayer(): void {
    this.chargeAmountSub = this.ToolsQaImplantLogForm.get("chargeAmount").valueChanges.subscribe(chargeAmountWithDollor => {
      this.setDueFromPayer(chargeAmountWithDollor);
    });
  }

  private setDueFromPayer(chargeAmount: number) {
    this.ToolsQaImplantLogForm.get("dueFromPayer").setValue(chargeAmount);
  }
  private setTotalCost(unitPrice: number, taxAndFrieght: number) {
    const totalCost = Number(unitPrice) + Number(taxAndFrieght);
    const decimarlNumber = this.getDecimalFromtext(String(totalCost));
    this.ToolsQaImplantLogForm.get("totalCost").setValue(decimarlNumber);
  }

  private setChargeAmount(totalCost: number, quantity: number,up: number) {
    let chargeAmount;
    let percent;
    console.log("Set Change Amount");
    console.log(up);
    /*
    if (totalCost < 1000) {
      chargeAmount = totalCost * this.chargeFactorWhenTotalCostLessThan1000 * quantity;
    } else {

      chargeAmount = totalCost * this.chargeFactorWhenTotalCostgreatorThanOrEq1000 * quantity;
    }
    */
     
    chargeAmount = totalCost * quantity;
    percent = (chargeAmount * up )/ 100;
    chargeAmount = chargeAmount + percent;

    this.ToolsQaImplantLogForm.get("chargeAmount").setValue(chargeAmount);
  }

  onInsuranceChange(event$) {
    console.log("onInsuranceChange", event$);
    const bdosid = this.caseRequestInfoQuery.getBdosid();
    const requestBody = {
      "BDOSID": bdosid,
      "PayerId": event$.value.id
    }
    this.saveIsunrancePatientMapping(requestBody);
  }

  onSubmit() {
    // add validation
    this.submitted = true;
    this.loading = true;
    console.log("on submit", this.ToolsQaImplantLogForm);
    if (this.ToolsQaImplantLogForm.invalid) {
      this.loading = false;
      return;
    }

    const claimLine = this.ToolsQaImplantLogForm.get("claimLine").value;
    const hcpcs = this.ToolsQaImplantLogForm.get("hcpcs").value;
    const description = this.ToolsQaImplantLogForm.get("description").value;
    const unitPrice = this.getUnitPrice();
    const taxAndFreight = this.getTaxAndFrieght();
    const totalCost = this.getTotalCost();
    const quantity = this.getQuantity();
    const up = this.getup();
    const chargeAmount = this.ToolsQaImplantLogForm.get("chargeAmount").value;
    const dueFromPayer = this.ToolsQaImplantLogForm.get("dueFromPayer").value;
    let toolsQaImplantLog;
    if(this.isEdit){
      const entity = this.toolsQaImplantLogQuery.getActive().find(e  => true);
      toolsQaImplantLog = createToolsQaImplantLog({
        id: entity.id,
        toolsImplantLogId: entity.toolsImplantLogId,
        claimLine: claimLine,
        hcpcs: hcpcs,
        description: description,
        unitPrice: unitPrice,
        taxAndFreight: taxAndFreight,
        totalCost: totalCost,
        quantity: quantity,
        up: up,
        chargeAmount: chargeAmount,
        dueFromPayer: dueFromPayer
      });
    }else{
      const toolsImplantLogId = this.implanrLogPayerQuery.getAll().find(e => true).id;
      if(!toolsImplantLogId){
        this.toastr.warning("Please select insurance first");
        return;
      }
      toolsQaImplantLog = createToolsQaImplantLog({
        toolsImplantLogId: toolsImplantLogId,
        claimLine: claimLine,
        hcpcs: hcpcs,
        description: description,
        unitPrice: unitPrice,
        taxAndFreight: taxAndFreight,
        totalCost: totalCost,
        quantity: quantity,
        up: up,
        chargeAmount: chargeAmount,
        dueFromPayer: dueFromPayer
      });
    }
    this.saveReccord(toolsQaImplantLog);
  }

  private saveReccord(toolsQaImplantLog: ToolsQaImplantLog) {
    let requestBody;
    if(this.isEdit){
        requestBody = {
        id: toolsQaImplantLog.id,
        ToolsImplantLogId: toolsQaImplantLog.toolsImplantLogId,
        ClaimLineNo: toolsQaImplantLog.claimLine,
        HCPCS: toolsQaImplantLog.hcpcs,
        Descp: toolsQaImplantLog.description,
        UnitPrice: toolsQaImplantLog.unitPrice,
        TaxFreight: toolsQaImplantLog.taxAndFreight,
        TotalCost: toolsQaImplantLog.totalCost,
        Quantity: toolsQaImplantLog.quantity,
        up: toolsQaImplantLog.up,
        ChargeAmount: toolsQaImplantLog.chargeAmount,
        DueAmount: toolsQaImplantLog.dueFromPayer
      };
    }else{
      requestBody = {
        ToolsImplantLogId: toolsQaImplantLog.toolsImplantLogId,
        ClaimLineNo: toolsQaImplantLog.claimLine,
        HCPCS: toolsQaImplantLog.hcpcs,
        Descp: toolsQaImplantLog.description,
        UnitPrice: toolsQaImplantLog.unitPrice,
        TaxFreight: toolsQaImplantLog.taxAndFreight,
        TotalCost: toolsQaImplantLog.totalCost,
        Quantity: toolsQaImplantLog.quantity,
        up: toolsQaImplantLog.up,
        ChargeAmount: toolsQaImplantLog.chargeAmount,
        DueAmount: toolsQaImplantLog.dueFromPayer
      };
    }
     
    this.saveSubscription = this.apiService.post("PaymentPlan/ImplantLogDetailsSave", requestBody).subscribe(res => {
      if (res.status === 'Success') {
        this.toastr.success('Success', 'Saved Successfully');
        this.loading = false;
        this.submitted = false;
        const dataList = res.data ? res.data : res.datat;
        const implantLogId = dataList.find(d => true)?.toolsImplantLogId;
        if(implantLogId){
          this.initStore(implantLogId);
        }else{
          this.toastr.error("unable to fetch the toolsImplantLogId from response of save operation so cannot load impalnt log table");
        }
        this.ToolsQaImplantLogForm.reset();
      
        if(this.isEdit){
          this.isEdit = false;
          this.toolsQaImplantLogService.removeActive();
        }
         const entityWithId = createToolsQaImplantLog({
          id: res.id,
          toolsImplantLogId: res.toolsImplantLogId,
          claimLine: res.claimLineNo,
          hcpcs: res.hcpcs,
          description: res.descp,
          unitPrice: res.unitPrice,
          taxAndFreight: res.taxFreight,
          totalCost: res.totalCost,
          quantity: res.quantity,
          up: res.up,
          chargeAmount: res.chargeAmount,
          dueFromPayer: res.dueAmount
        })
        
        this.toolsQaImplantLogService.add(entityWithId);
      } else {
        this.toastr.error("Unexpected error", res.message);
        this.loading = false;
        this.submitted = false;
      }
    }, err => {
      this.toastr.error("Unexpected error", err);
      this.loading = false;
      this.submitted = false;
    });
  }



  onClickClose() {
    this.toolsQaImplantLogService.removeActive();
    this.ToolsQaImplantLogForm.reset();
    this.isEdit = false;
  }

  loadInsuranceDropdwon(requestBody){
    this.spinner.show();
    // this.insuranceDrodownList = data;
    this.apiService.post("FacilityPayer/BindGrid", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.spinner.hide();
        this.insuranceDrodownList = res.data;
      }else{
        this.spinner.hide();
        this.toastr.error("Unexpected Error", res?.message);        
      }
    }, err => {
      this.spinner.hide();
      this.toastr.error("Unexpected Error", err?.message);
    });
  }
  onSearchChange(event$){
    console.log("onSearchChange", event$);
    if(new String(event$).length % 3 === 0){
      this.loadInsuranceDropdwon({ pageNum: 1, pageSize: 25, SearchValue: event$ });
    }
  }
  editRow(inToolsQaImplantLog: ToolsQaImplantLog){
    console.log("EditRow", inToolsQaImplantLog);
    const toolsQaImplantLog = this.toolsQaImplantLogQuery.getEntity(inToolsQaImplantLog.id);
    this.ToolsQaImplantLogForm.get("claimLine").setValue(toolsQaImplantLog.claimLine);
    this.ToolsQaImplantLogForm.get("hcpcs").setValue(toolsQaImplantLog.hcpcs);
    this.ToolsQaImplantLogForm.get("description").setValue(toolsQaImplantLog.description);
    this.ToolsQaImplantLogForm.get("unitPrice").setValue(toolsQaImplantLog.unitPrice);
    this.ToolsQaImplantLogForm.get("taxAndFreight").setValue(toolsQaImplantLog.taxAndFreight);
    this.ToolsQaImplantLogForm.get("totalCost").setValue(toolsQaImplantLog.totalCost);
    this.ToolsQaImplantLogForm.get("quantity").setValue(toolsQaImplantLog.quantity);
    this.ToolsQaImplantLogForm.get("up").setValue(toolsQaImplantLog.up);
    this.ToolsQaImplantLogForm.get("chargeAmount").setValue(toolsQaImplantLog.chargeAmount);
    this.ToolsQaImplantLogForm.get("dueFromPayer").setValue(toolsQaImplantLog.dueFromPayer);
    this.isEdit = true;
    this.toolsQaImplantLogService.active(toolsQaImplantLog.id);
  }

  delRow(toolsQaImplantLog: ToolsQaImplantLog){
    this.spinner.show();
    const requestBody = {
      "id": toolsQaImplantLog.id
    }
    this.delSub = this.apiService.post("PaymentPlan/ImplantLogDetailsDelete", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.spinner.hide();
        this.toolsQaImplantLogService.remove(toolsQaImplantLog.id);
      }else{
        this.toastr.error("Server Error", res.message);
        this.spinner.hide();
      }
    }, err => {
      this.toastr.error("Unexpected Error", err);
      this.spinner.hide();
    });
  }

  onDownload(){
    const bdosid = this.caseRequestInfoQuery.getBdosid();
    const requestBody = { Bdosid: bdosid };
    this.apiService.downloadFileWithBody("PaymentPlan/DownloadImplantLog", requestBody).subscribe(response => {
			let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
			//window.open(url);
			//window.location.href = response.url;
			fileSaver.saveAs(blob, 'Implant Log File.xlsx');
		}), error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
   
    
  
  }
  
  initDropdownConfig(){
  this.dropdownConfig = {
    displayKey: "payer_Name", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: '300px', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: "payer_Name", // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }
}
}
