import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DrClientComponent } from './dr-client/dr-client.component';
import { DrNsnComponent } from './dr-nsn/dr-nsn.component';
import { DepositLogComponent } from './deposit-log/deposit-log.component';
import { GoalTrackerComponent } from './goal-tracker/goal-tracker.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const routes: Routes = [{
  path: '',
  children: [
    {
      path: '',
      redirectTo: '/dashboard',
      pathMatch: 'full'
    },
    {
      path: 'dashboard',
      component: DashboardComponent,
    },
    {
      path: 'dr-client',
      component: DrClientComponent,
    },
    {
      path: 'dr-nsn',
      component: DrNsnComponent,
    },
    {
      path: 'deposit-log',
      component: DepositLogComponent,
    },
    {
      path: 'goal-tracker',
      component: GoalTrackerComponent,
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PostingRoutingModule { }
