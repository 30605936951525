<!-- Begin Page Content -->
<div class="container-fluid">

    <!-- Content Row -->
    <div class="row counters">
        <div class="col-12 mb-4">

            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size  ml-4">Import Physicians </h1>
                    <a *ngIf="privileges.importPhysiciansAdd" href="javascript:void(0)" (click)='showFormModal()' id="closeFileSidenav"
                        class="btn btn-primary btn-patient-box openMySidenav  mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Import Physicians
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list appointments-list">
                        <div class="row">
                            <div class="col-sm-12 col-md-4">
                                <div class="myTables_length"><label>Show <select name="myTable_length"
                                            aria-controls="dataTable" (change)="onEntryChange($event)"
                                            class="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries</label></div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="myTables_length">
                                    <label>Filter Physician </label>
                                    <select name="myTable_length" [(ngModel)]="physicianFilter"
                                            class="form-control form-control-sm" (change)="onPhysicianFilterChange()">
                                            <option value="All Physician">All Physician</option>
                                            <option value="Active Physician">Active Physician</option>
                                            <option value="Inactive Physician">Inactive Physician</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4">
                                <div class="myTables_filter">
                                    <label>Search:<input type="search" [(ngModel)]="searchValue" name="searchValue"
                                    class="form-control form-control-sm" (keyup.enter)="search()"
                                    placeholder="Enter Keyword">
                                    </label>
                                </div>
                                <!-- (keyup)="sortTable($event)" -->
                            </div>
                        </div>
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>Physician Name</th>
                                    <th>Facility Acronym</th>
                                    <th>NPI</th>
                                    <th *ngIf="privileges.importPhysiciansStatus">Status</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>Physician Name</th>
                                    <th>Facility Acronym</th>
                                    <th>NPI</th>
                                    <th *ngIf="privileges.importPhysiciansStatus">Status</th>
                                    <th>Date</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of importPhysicianList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>{{data.fullName}}</td>
                                    <td>{{data.facility.split('-')[0]}}</td>
                                    <td>{{data.systemKey}}</td>
                                    <td *ngIf="privileges.importPhysiciansStatus">
                                        <label class="custom-switch">
                                            <input type="checkbox" (click)="delPhysician(data)"
                                                [checked]="data.active === 1">
                                            <span class="slider"></span>
                                        </label>
                                    </td>
                                    <td>{{data.importDate | date:'MM/dd/yyyy HH:mm'}}</td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls *ngIf="importPhysicianList && importPhysicianList.length !== 0" class="float-right" (pageChange)="pageChange($event); p=$event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-import-physician-form *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (addedEmitter)="onAddRecord($event)">
</app-import-physician-form>
<!-- Sidenav-->


<!--  -->