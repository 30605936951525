import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginLayoutComponent } from './layouts/login-layout/login-layout.component';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { LoginComponent } from './modules/login/login.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { ChildGuard } from './shared/guards/child.guard';
import { AdminModule } from './modules/admin/admin.module';
import { PatientsModule } from './modules/patients/patients.module';
import { ChangePasswordComponent } from './modules/user/change-password/change-password.component';
import { UserModule } from './modules/user/user.module';
import { MastersModule } from './modules/masters/masters.module';
import { SettingsModule } from './modules/settings/settings.module';
import { KbModule } from './modules/kb/kb.module';
import { PostingModule } from './modules/posting/posting.module';
import { BillingDashboardModule } from './modules/billing-dashboard/billing-dashboard.module';
import { CenterModule } from './modules/center/center.module';
import { ArModule } from './modules/ar/ar.module';
import { NewDashboardComponent } from './modules/new-dashboard/new-dashboard.component';
import { ReviewCasesModule } from './modules/review-cases/review-cases.module';
import { ReportsModule } from './modules/reports/reports.module';
import { CodingInquiryModule } from './modules/coding-inquiry/coding-inquiry.module';
import { AuditlogsComponent } from './modules/auditlogs/auditlogs.component';
import { RoleGuard } from './shared/guards/role-guard';
import { adminRole, billerRole, coderRole } from './shared/models/name-constant';


const routes: Routes = [
  {
    path: '',
    component: LoginLayoutComponent,
    children: [{
      path: 'login',
      component: LoginComponent
    },
    {
      path: '',
      redirectTo: '/dashboard',
      pathMatch: 'full'
    },
    {
      path: 'user',
      loadChildren: () => UserModule
    }],
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [{
      path: 'dashboard',
      component: NewDashboardComponent,
      canActivate: [AuthGuard],
    
      // canActivate: [AuthGuard, RoleGuard],
      // data: {roles: [ billerRole.value, adminRole.value ] }
    },
    {
      path: 'admin',
      loadChildren: () => AdminModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'change-password',
      component: ChangePasswordComponent,
       canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'patient',
      loadChildren: () => PatientsModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'review-cases',
      loadChildren: () => ReviewCasesModule,
      // canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'coding-inquiry',
      loadChildren: () => CodingInquiryModule,
      // canActivate: [AuthGuard],
      // canActivate: [AuthGuard, RoleGuard],
      // data: {roles: [ coderRole.value ] }
    },
    {
      path: 'reports',
      loadChildren: () => ReportsModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'masters',
      loadChildren: () => MastersModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'settings',
      loadChildren: () => SettingsModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'kb',
      loadChildren: () => KbModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'posting',
      loadChildren: () => PostingModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'd',
      loadChildren: () => BillingDashboardModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'center',
      loadChildren: () => CenterModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'ar',
      loadChildren: () => ArModule,
      canActivate: [AuthGuard],
      // canActivateChild: [ChildGuard]
    },
    {
      path: 'auditlogs',
      component: AuditlogsComponent,
      canActivate: [AuthGuard],
    },
    
    // {
    //   path: 'test',
    //   component: TestComponent
    // }
  ],
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled', preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

// , { enableTracing: true }
