import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BatchReviewCasesComponent } from './batch-review-cases/batch-review-cases.component';
import { RouterModule } from '@angular/router';
import { ReviewCasestRoutingModule } from './review-cases-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BillingDashboardModule } from '../billing-dashboard/billing-dashboard.module';
import { FormsModule } from '@angular/forms';
import { ReviewedCasesComponent } from './reviewed-cases/reviewed-cases.component';



@NgModule({
  declarations: [BatchReviewCasesComponent, ReviewedCasesComponent],
  imports: [
    ReviewCasestRoutingModule,
    CommonModule,
    SharedModule,
    BillingDashboardModule,
    FormsModule
  ],
  exports: [
    RouterModule,
  ]
})
export class ReviewCasesModule { }
