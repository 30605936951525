<!-- Begin Page Content -->
<div class="container-fluid" *ngIf="(activeEntityId$ | async)">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="row">
                        <div class="col">
                            <button type="button" 
                            (click)="back()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav">
                            <i class="fas fa-long-arrow-alt-left"></i> Back</button>
                        </div>
                    </div>
                    <div class="row" style="float: right;">
                        <div class="col">
                            <button type="button" (click)="onExport()"
                            class="btn btn-primary mr-3 btn-patient-box closeMySidenav">Export</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'mrn' || col?.name === 'importDate' || col?.name === 'setBilledDate'  }"
                                    >{{col.displayName}}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns"
                                    [ngClass]="{'txt-align-right': col?.name === 'mrn' || col?.name === 'importDate' || col?.name === 'setBilledDate' }"
                                    >{{col.displayName}}</th>
                                </tr>
                            </tfoot>
                            <ng-container *ngIf="datasource$ | async as datasource">
                                <tr *ngFor="let data of datasource | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;"
                                    style="color: #333;
                            font-weight: 400;">
                                    <td>{{(i+1) + ((currentPage-1)*itemsPerPage)}}</td>
                                    <td>{{data.facilityName}}</td>
                                    <td class="txt-align-right">{{data.mrn}}</td>
                                    <td class="txt-align-right">{{data.importDate | date: 'MM/dd/yyyy'}}</td>
                                    <td class="txt-align-right" *ngIf="data.setBilledDate !== '1900-01-01T00:00:00'">{{data.setBilledDate | date: 'MM/dd/yyyy'}}</td>
                                    <td *ngIf="data.setBilledDate === '1900-01-01T00:00:00'"></td>
                                </tr>
                            </ng-container>
                        </table>
                        <pagination-controls class="float-right"
                            (pageChange)="pageChange($event); currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>