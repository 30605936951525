import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api.service';
import { CodingStatus } from '../state/coding-status.model';
import { CodingStatusQuery } from '../state/coding-status.query';

@Component({
  selector: 'app-coding-status-edit',
  templateUrl: './coding-status-edit.component.html',
  styleUrls: ['./coding-status-edit.component.css']
})
export class CodingStatusEditComponent implements OnInit {
  AddCodingStatusForm: FormGroup;
  loading: boolean;
  submitted: boolean;
  @Output() closeEmitter = new EventEmitter();
  activeCodingStatus: CodingStatus;

  addSub: Subscription;
  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private apiService: ApiService,
    private codingStatusQuery: CodingStatusQuery
  ) { }

  ngOnInit(): void {
    this.activeCodingStatus = this.codingStatusQuery.getActive();
    this.initForm();
  }

  ngOnDestroy(){
    if(this.addSub){
      this.addSub.unsubscribe();
    }
  }

  initForm() {
    this.AddCodingStatusForm = this.fb.group({
      inquirystatus: [ this.activeCodingStatus.inquirystatus, [Validators.required]],
      priority: [ this.activeCodingStatus.priority, [Validators.required]],    
    });
  }
  get f() { return this.AddCodingStatusForm.controls; }


  onClickClose() {
    this.closeEmitter.emit();
  }

  onSubmit(){
    this.submitted = true;
    this.loading = true;
    console.log("on submit", this.AddCodingStatusForm);
    if(this.AddCodingStatusForm.invalid){
      this.loading = false;
      return;
    }

    const inquirystatus = this.AddCodingStatusForm.get("inquirystatus").value;
    const priority = this.AddCodingStatusForm.get("priority").value;

    let requestBody = {
      id: this.activeCodingStatus.id,
      InquiryStatus: inquirystatus,
      Priority: priority
    }
    this.addSub = this.apiService.post("InquiryStatus/SaveInquiryStatus", requestBody).subscribe(res => {
      if(res.status === 'Success'){
        this.loading = false;
        this.submitted = false;
        this.toastr.success("Status updated!!")
        this.onClickClose();
      }else{
        this.loading = false;
        this.submitted = false;
        this.toastr.error("Server Error");
      }
    }, error => {
      this.loading = false;
      this.submitted = false;
      this.toastr.error("Unaexpected Error");
    });

  }

}
