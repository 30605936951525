import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID } from '@datorama/akita';
import { tap } from 'rxjs/operators';
import { ToolsQaImplantLog } from './tools-qa-implant-log.model';
import { ToolsQaImplantLogStore } from './tools-qa-implant-log.store';

@Injectable({ providedIn: 'root' })
export class ToolsQaImplantLogService {

  constructor(private toolsQaImplantLogStore: ToolsQaImplantLogStore, private http: HttpClient) {
  }


  set(entities: ToolsQaImplantLog[]) {
    this.toolsQaImplantLogStore.set(entities);
  }

  reset(){
    this.toolsQaImplantLogStore.set([]);
  }
  add(toolsQaImplantLog: ToolsQaImplantLog) {
    this.toolsQaImplantLogStore.add(toolsQaImplantLog);
  }

  update(id, toolsQaImplantLog: Partial<ToolsQaImplantLog>) {
    this.toolsQaImplantLogStore.update(id, toolsQaImplantLog);
  }
  active(id: number | string){
    this.toolsQaImplantLogStore.setActive([id]);
  }

  removeActive(){
    this.toolsQaImplantLogStore.setActive([]);
  }

  remove(id: ID) {
    this.toolsQaImplantLogStore.remove(id);
  }

}
