import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { combineLatest, merge, Observable } from 'rxjs';
import { filter, map, scan } from 'rxjs/operators';
import { Group } from './group.model';
import { GroupsStore, GroupsState } from './groups.store';

@Injectable({ providedIn: 'root' })
export class GroupsQuery extends QueryEntity<GroupsState> {

  constructor(protected store: GroupsStore) {
    super(store);
  }

  selectAllInactive(): Observable<Group[]>{
    const all$ = this.selectAll();
    const active$ = this.selectActive();
    const inactive$ = combineLatest([all$, active$]).pipe(
      map(([all, active]) => this.filterInactive(all, active))
    );
    return inactive$;
  }

  getAllInactive(): Group[]{
    return this.getAll({
      filterBy: e => !this.getActiveId().includes(e.id)
    })
  }

  filterInactive(all: Group[], active: Group[]): Group[]{
   return  all.filter((el) => !this.isActive(el, active))
  }
  
  isActive(group: Group, active: Group[]): boolean{
    return active?.some(a => a.id === group.id);
  }
}
