<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Fee Schedule</h1>
                    <a *ngIf="privileges.feeScheduleAdd" href="javascript:void(0);" (click)='showFormModal()' id="closeFileSidenav"
                        class="btn btn-primary btn-patient-box openMySidenav  mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Import Fee Schedule
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list appointments-list">
                        <div class="row">
                            <div class="col-sm-12 col-md-6">
                                <div class="myTables_length"><label>Show <select name="myTable_length"
                                            aria-controls="dataTable" (change)="onEntryChange($event)"
                                            class="custom-select custom-select-sm form-control form-control-sm">
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select> entries</label></div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div class="myTables_filter"><label>Search:<input type="search"
                                            [(ngModel)]="searchValue" class="form-control form-control-sm"
                                            (keyup.enter)="search()" placeholder="Facility Name/CPT Code"></label></div>
                            </div>
                        </div>

                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Facility Name</th>
                                    <th>CPT Code</th>
                                    <th>CPT Description</th>
                                    <th>Rate</th>
                                    <th>Valid From</th>
                                    <th *ngIf="privileges.feeScheduleDelete || privileges.feeScheduleEdit">Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Facility Name</th>
                                    <th>CPT Code</th>
                                    <th>CPT Description</th>
                                    <th>Rate</th>
                                    <th>Valid From</th>
                                    <th *ngIf="privileges.feeScheduleDelete || privileges.feeScheduleEdit">Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of importfeeSchedule | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>
                                        <!-- {{i+1}} -->
                                        {{(i+1) + ((p-1)*pageValue)}} 
                                    </td>
                                    <td><a href="javascript:void(0)" (click)='showDetailModal(data)'>{{data.facilityName}}</a></td>
                                    <td>{{data.cpt}}</td>
                                    <td style="width: 40%;">{{data.cptDesc}}</td>
                                    <td>${{data.rate}}</td>
                                    <td>{{data.validFrom | date:'MM/dd/yyyy'}}</td>
                                    <td *ngIf="privileges.feeScheduleDelete || privileges.feeScheduleEdit">
                                        <a *ngIf="privileges.feeScheduleEdit" href="javascript:void(0)" (click)="editfeeSchedule(data)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>
                                        <a *ngIf="privileges.feeScheduleDelete" href="javascript:void(0)" (click)="delfeeSchedule(data.feeScheduleID)"
                                            class="btn btn-danger icon-btn"><i class="fa fa-trash-alt" title="Delete"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>


                        <div class="row">
                            <div class="col-sm-12 col-md-5">
                                <!-- <div>Showing 1 to 1 of 1 entries</div> -->
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <pagination-controls class="paging_simple_numbers"
                                    (pageChange)="pageChange($event); p=$event">
                                </pagination-controls>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-facility-details  [facility_id]="facility_id" *ngIf=shouldShowDetailModal (closeEmitter)="onCloseFormModal()">
</app-facility-details>
<app-fee-schedule-form *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (addedEmitter)="onAddRecord($event)">
</app-fee-schedule-form>
<!-- Sidenav-->

<!-- Sidenav-->
<app-fee-schedule-edit [formData]="formData" *ngIf=shouldShowEditFormModal (editEmitter)="onEditRecord($event)"
    (closeEmitter)="onCloseFormModal()">
</app-fee-schedule-edit>
<!-- Sidenav-->