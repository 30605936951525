<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>
<div class="card-body pad-lef-zero pad-bottom-zero">
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-6">
                    <label for="assignAll">
                        <label>Select Insurance</label>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-9">
                    <ngx-select-dropdown [config]="dropdownConfig" [options]="insuranceDrodownList"
                        (change)="onInsuranceChange($event)" (searchChange)="onSearchChange($event)"
                        autoClearSearch="true" [multiple]="false" [(ngModel)]="primaryInsurance">
                    </ngx-select-dropdown>
                    <!-- <span *ngIf="submitted && !selectedInsurance" class="invalid-feedback">
                            Insurance is mandatory!!
                        </span>   -->
                </div>

            </div>
        </div>
    </div>
</div>
<form class="pb-3 form-fields" [formGroup]="ToolsQaImplantLogForm" *ngIf="ToolsQaImplantLogForm">
    <div class="card-body pad-lef-zero pad-right-zero">
        <div class="row" style="padding-bottom: 10px;">
            <div class="col"><label>Implant Log</label></div>
        </div>
        <div class="table-responsive" style="overflow-x: scroll;">
            <table class="table" id="dataTable" width="100%" cellspacing="0">
                <thead>
                    <tr style="color: #333;">
                        <th *ngFor="let col of coloumns" [ngClass]="{'text-align-right' : col.isNumber }">
                            <div *ngIf="!col.isAction">{{col.displayName}}</div>
                        </th>
                    </tr>
                </thead>

                <tr *ngFor="let row of (toolsQaImplanLogList$ | async)" style="color: #333;
                    font-weight: 400;">
                    <td *ngFor="let col of coloumns;" [ngClass]="{'text-align-right' : col.isNumber }">
                        <div *ngIf="col.name === 'description'" title="{{row[col.name]}}">
                            {{row['description'] | truncate:10}}
                        </div>
                        <div *ngIf="col.name === 'claimLine'">
                            <a href="javascript:void(0);" (click)='editRow(row)'>
                                {{row[col.name]}}
                            </a>
                        </div>
                        <div *ngIf="col.name === 'dueFromPayer'">
                            <div class="row">
                                <div class="col-12">
                                    {{row[col.name]}}
                                    <a href="javascript:void(0);" (click)="delRow(row)" class="closeFileSidenav"
                                        style="padding-left: 2px;" id="closeFileSidenav">
                                        <i class="fas fa-times fa-sm" style="color: red;"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            *ngIf="col.name !== 'description' && col.name !== 'claimLine' && col.name !== 'dueFromPayer'">
                            {{row[col.name]}}
                        </div>

                    </td>
                </tr>
                <tfoot>
                    <tr style="color: #333;">
                        <th>Total:</th>
                        <th></th>
                        <th></th>
                        <th class="text-align-right">{{sumUnitPrice$ | async}}</th>
                        <!-- <th class="text-align-right">{{sumTaxAndFrieght$ | async}}</th> -->
                        <th class="text-align-right"></th>
                        <th class="text-align-right">{{sumTotalCost$ | async}}</th>
                        <th></th>
                        <th class="text-align-right">{{sumChargeAmount$ | async}}</th>
                        <th class="text-align-right">{{sumDueFromPayer$ | async}}</th>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="row align-center" style="padding-top: 2px;" *ngIf="(toolsQaImplanLogList$ | async)?.length !== 0">
            <button type="button" (click)="onDownload()" class="btn btn-primary btn-patient-box">
                Download Implant Log
            </button>
        </div>
    </div>
    <div class="card-body pad-lef-zero" style="padding-top: 0px;">
        <div class="row row-pad-5" style="padding-bottom: 20px;">
            <div class="col-md-6 mt-2" *ngIf="isEdit"><label>Edit Implant Log</label></div>
            <div class="col-md-6 mt-2" *ngIf="!isEdit"><label></label></div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6 mt-2 col-max-wid"> <label class="font-wt-100">Claim Line #</label></div>
            <div class="col-md-6"><input type="number" formControlName="claimLine" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.claimLine.errors }">
                <span *ngIf="submitted && f.claimLine.errors && f.claimLine.errors.required" class="invalid-feedback">
                    is mandatory!!
                </span>
            </div>

        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100" class="font-wt-100">HCPCS</label></div>
            <div class="col-md-6"><input type="text" formControlName="hcpcs" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.hcpcs.errors }">
                <span *ngIf="submitted && f.hcpcs.errors && f.hcpcs.errors.required" class="invalid-feedback">
                    is mandatory!!
                </span>
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">Description</label></div>
            <div class="col-md-6">
                <input type="text" formControlName="description" class="form-control">
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">Unit Price ($)</label></div>
            <div class="col-md-6"><input type="text" formControlName="unitPrice" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.unitPrice.errors }">
                <span *ngIf="submitted && f.unitPrice.errors && f.unitPrice.errors.required" class="invalid-feedback">
                    is mandatory!!
                </span>
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">Tax & Freight ($)</label></div>
            <div class="col-md-6"><input type="text" formControlName="taxAndFreight"  placeholder="Optional" class="form-control">
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">Total Cost ($)</label></div>
            <div class="col-md-6"><input type="text" formControlName="totalCost" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.totalCost.errors }">
                <span *ngIf="submitted && f.totalCost.errors && f.totalCost.errors.required" class="invalid-feedback">
                    is mandatory!!
                </span>
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">QTY</label></div>
            <div class="col-md-6"><input type="number" formControlName="quantity" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.quantity.errors }">
                <span *ngIf="submitted && f.quantity.errors && f.quantity.errors.required" class="invalid-feedback">
                    is mandatory!!
                </span>
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">Markup (%)</label></div>
            <div class="col-md-6"><input type="number" formControlName="up" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.up.errors }" >
                <span *ngIf="submitted && f.up.errors && f.up.errors.required" class="invalid-feedback">
                    is mandatory!!
                </span>
                <span *ngIf="submitted && f.up.errors && f.up.errors.pattern" class="invalid-feedback">
                    is should be less than or equal 2 decimal points!!
                </span>
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6  mt-2 col-max-wid"> <label class="font-wt-100">Charge Amt. ($)</label></div>
            <div class="col-md-6"><input type="number" formControlName="chargeAmount" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.chargeAmount.errors }">
                <span *ngIf="submitted && f.chargeAmount.errors && f.chargeAmount.errors.required"
                    class="invalid-feedback">
                    is mandatory!!
                </span>
                
            </div>
        </div>
        <div class="row row-pad-5">
            <div class="col-md-6 mt-2 col-max-wid"> <label class="font-wt-100">Due From Payer ($)</label></div>
            <div class="col-md-6"><input type="text" formControlName="dueFromPayer" class="form-control" required
                    [ngClass]="{'is-invalid': submitted && f.dueFromPayer.errors }">
                <span *ngIf="submitted && f.dueFromPayer.errors && f.dueFromPayer.errors.required"
                    class="invalid-feedback">
                    is mandatory!!
                </span>
            </div>
        </div>
        <div class="row row-pad-5" style="padding-left:242px;">
            <button type="button" (click)="onClickClose()"
                class="btn btn-secondary btn-width-cmn btn-patient-box closeMySidenav mr-1">Cancel</button>
            <button type="button" (click)="onSubmit()" [disabled]="loading" class="btn btn-primary btn-patient-box">
                <div *ngIf="isEdit">Update</div>
                <div *ngIf="!isEdit">Add</div>
                <i class="fa fa-spinner fa-spin" *ngIf="loading"></i>
            </button>
        </div>
    </div>
</form>