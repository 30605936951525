import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserConfirmationComponent } from './user-confirmation/user-confirmation.component';
import { UserRoutingModule } from './user-routing.module';
import { NgxPaginationModule } from 'ngx-pagination';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { UserregistrationComponent } from './userregistration/userregistration.component';

@NgModule({
  declarations: [
    UserConfirmationComponent,
    ResetPasswordComponent,
    ForgotpasswordComponent,
    UserregistrationComponent
  ],
  imports: [
    CommonModule,
    UserRoutingModule,
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule
  ]
})
export class UserModule { }
