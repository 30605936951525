import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { billerRole, clientRole, coderRole ,clientLiteRole ,codingManagerRole } from 'src/app/shared/models/name-constant';
import { SessionState, SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionQuery extends Query<SessionState> {  
  constructor(protected store: SessionStore) {
    super(store);
  }

  hasClientRole(): Observable<boolean> {
    return this.select().pipe(map(res => res.role === clientRole.value));
  }

  isClientRole(): boolean{
    return this.getValue().role === clientRole.value;
  }

  isClientLiteRole(): boolean{
    return this.getValue().role === clientLiteRole.value;
  }

  isCoderRole(): boolean{
    return this.getValue().role === coderRole.value;
  }

  isCodingManagerRole(): boolean{
    return this.getValue().role === codingManagerRole.value;
  }

  isBillerRole(): boolean{
    return this.getValue().role === billerRole.value;
  }

  getRole(): string {
    return this.getValue().role;
  }
}