import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from '../../../shared/services/api.service';
import { saveAs } from 'file-saver';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap, switchMap, catchError, map } from 'rxjs/operators';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AccessService } from 'src/app/shared/services/access.service';


@Component({
  selector: 'app-patient-action',
  templateUrl: './patient-action.component.html',
  styleUrls: ['./patient-action.component.css']
})
export class PatientActionComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  PatientSummaryDtls: any;
  _formData: any;
  smryIsToggled = false;
  tabHead = "";
  fileSuffix = "";
  stat: any;
  highApprovalinternal: any;
  varStat: any;
  dataQueueInfo: any = {};
  dataQueueInfoTemp: any = {};
  dataQueueDtl: any = {};
  privileges:any;

  clntNsnInfo = false;
  highNsnInfo = false;
  varianceInfo = false;

  unlockReq = false;

  note: string;
  noteType: string;
  noteSelect: string = "General Note";

  patientCPTs: any = [];
  primaryCPT: number;
  totalCost: number = 0;
  billAmount: number = 0;

  searching = false;
  searchFailed = false;
  notFound = false;

  requestArry: any = [];
  cptArry: any = [];
  cptVarArry: any = [];
  CPTCodeListForm: FormArray;
  VarianceListForm: FormArray;
  fileName: any;
  fname: any;
  fileAttachName: any;
  fileByte: any;
  facilityId: number = 0;
  facilityName: string = "";
  uploadFileText: string;
  uploadFile: any;
  PatientsForm: FormGroup;

  tabVal: any = [];
  selectedtabVal: any = [];
  txtApproval = "";
  billedInfo: boolean = true;
  cptAdded: any = [];
  showSetBilledForm:boolean =false;
  showModalForm:boolean=true;
  newdate:any;
  todaydate:any;
  dataQueue = [{ "key": "OP Note", "value": "operatingReport" }, { "key": "Pathology", "value": "pathology" }, { "key": "Pricing", "value": "pricing" },
  { "key": "Block Report", "value": "blockReport" }, { "key": "Coding Query", "value": "codingQuery" }, { "key": "Other/Misc", "value": "otherMisc" },
  { "key": "Client High$$$", "value": "highApproval" }, { "key": "In Coding", "value": "inCoding" }, { "key": " High$$$", "value": "highApprovalInternal" },
  { "key": "Coding Variance Review", "value": "variance" }, { "key": "Auth Review", "value": "authReview" }, { "key": "Coding Expert", "value": "codingExpert" }];


  @Input() set formData(value: any) {
    if (value) {
     
      this._formData = value;
    }
    console.log(this._formData,"formdata");
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private accessService: AccessService,
    private spinner: NgxSpinnerService) {
    this.initForm();
    this.tabVal["CONT0"] = true;
  }

  initForm() {
    this.PatientsForm = this.fb.group({
      txtNote: ["", [Validators.required]],
      reqFile: ["", Validators.required],
      CPTCodeList: this.fb.array([
        this.getCptCodeList()
      ]),
      VarianceList: this.fb.array([
        this.getVarianceList()
      ])
    })
  }

  ngOnInit(): void {
    this.privileges=this.accessService.navbar_access
    this.getPatientHeaderData();
    this.getBilled();
    this.CPTCodeListForm = <FormArray>this.PatientsForm.controls.CPTCodeList;
    this.VarianceListForm = <FormArray>this.PatientsForm.controls.VarianceList;

    this.noteType = "Private";
  }
  showSetBilled(){
    // this.closeEmitter.emit();
    if (this.showSetBilledForm) {
      // this.showModalForm = false;
      this.showSetBilledForm = false;
    }
    else {
    //  this.showModalForm = false
      this.showSetBilledForm = true;
      
     
    }
    this.showModalForm = false
  }
  onCloseFormModal() {
    //this.showModalForm = false
    this.showSetBilledForm = false;
    this.closeEmitter.emit();
  }
  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.showSetBilledForm = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getPatientHeaderData();
      //this.getBillingRulesList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.showSetBilledForm = true;
    }
  }
  
  getCptCodeList() {
    return this.fb.group({
      cpt: ['', Validators.required],
      CPTCode: ['', Validators.required],
      CPTDesc: ['', Validators.required],
      Rate: ['', Validators.required]
    });
  }

  getVarianceList() {
    return this.fb.group({
      nsn_cpt: ['', Validators.required],
      nsn_cpt_code: ['', Validators.required],
      nsn_Desc: ['', Validators.required],
      physician_cpt: ['', Validators.required],
      physician_cpt_code: ['', Validators.required],
      physician_Desc: ['', Validators.required]
    });
  }

  searchVal(term: string) {
    if (term == '') {
      return of([]);
    }

    let data = { "CPTCode": term, "FacilityId": this.facilityId };

    return this.getApiService
      .post("/CreatePatient/GetCPT", data).pipe(
        map(response => response.dataCPTInfo)
      );
  }

  formatter = (x: { cpt: string }) => x.cpt;
  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap(term =>
        this.searchVal(term).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          }))
      ),
      tap(() => this.searching = false)
    );

  onClickClose() {
    this.showModalForm = false;
    this.closeEmitter.emit();
  }
  getBilled() {
    let data = { "BdosID": this._formData.dosId, "facilityId": this._formData.facilityID };

    this.getApiService.post("BillingNew/GetSetBilled", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        console.log(resp.data[0].eodDate,"ed")
        this.newdate=new Date(resp.data[0].eodDate);
        this.todaydate = new Date();
        this.newdate.setMonth(this.newdate.getMonth()+8);
        console.log('newdate',this.newdate,"today date",this.todaydate);
        if(resp.data.length == 0){
          this.billedInfo = true;
        }else{
          this.billedInfo = false;
        }
      }

    }, (err) => {

     // this.billedInfo = false;
      console.log(err);
    })
  }
  getPatientHeaderData() {
    this.spinner.show();
    let data = {
      "Bdosid": this._formData.dosId
    }

    this.getApiService.post("BillingNew/GetPatientsInfo", data).subscribe(res => {
      this.spinner.hide();
      console.log(res,"response");
      if (res.status == "Success") {

        this.PatientSummaryDtls = res;

        this.facilityId = res.dataPatient[0].facilityId;
        this.facilityName = res.dataPatient[0].facilityName;

        this.dataQueueInfo = res.dataQueueInfo[0];

        if (JSON.stringify(this.dataQueueDtl) != '{}') {
          for (let q in this.dataQueueDtl) {
            if (this.dataQueueDtl[q] == true) {
              this.dataQueueInfo[q] = true;
            }
          }
        }
        else {
          for (let val in this.dataQueueInfo) {
            if (this.dataQueueInfo[val] == true) {
              this.dataQueueDtl[val] = true;
              if (this.dataQueueInfo["highApproval"] == true) {
                this.clntNsnInfo = true;
              }
              if (this.dataQueueInfo["highApprovalInternal"] == true) {
                this.highNsnInfo = true;
              }
              if (this.dataQueueInfo["variance"] == true) {
                this.varianceInfo = true;
              }
            }
          }
        }
        if (res.dataCPTDetails.length > 0) {
          this.primaryCPT = res.dataCPTDetails[0].cptCode;
        }

        for (let d of res.dataCPTDetails) {
          if (this.patientCPTs.indexOf(d.cptCode) == -1) {
            this.patientCPTs.push(d.cptCode);
          }
        }

        // if (res.dataDosDetails[0].billed == "Yes") {
        //   this.billedInfo = false;
        // }
        // else if (res.dataDosDetails[0].billed == "No") {
        //   this.billedInfo = true;
        // }
        this.fetch$data();

      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  getFile(fileId, fileName) {
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  setTab(name, val, tabname) {

    if (this.selectedtabVal.indexOf(name) == -1) {

      this.dataQueueInfo[val] = !this.dataQueueInfo[val];
      this.tabVal["CONT0"] = false;
      this.tabVal["CONT1"] = false;
      this.tabVal["CONT2"] = false;
      this.tabVal["CONT3"] = false;

      this.fileName = "";
      this.fileByte = "";
      this.PatientsForm.reset();
      this.totalCost = 0;

      if (this.dataQueueInfo[val] == true) {
        this.tabHead = name;
        this.tabVal[tabname] = true;
        if (tabname == "CONT2" || tabname == "CONT3") {

          if (this.cptArry.length > 0) {

            for (let c = 0; this.cptArry.length > c; c++) {
              const frmCntrl = <FormArray>this.CPTCodeListForm.controls[c];
              frmCntrl.controls["cpt"].setValue(this.cptArry[c]);
              frmCntrl.controls["CPTCode"].setValue(this.cptArry[c].cpt);
              frmCntrl.controls["CPTDesc"].setValue(this.cptArry[c].CPTDesc);
              frmCntrl.controls["Rate"].setValue(this.cptArry[c].Cost);

              this.totalCost += Number(this.cptArry[c].Cost);
            }
          }
          else {
            this.fetchDollarData(name, val, tabname);
          }
        }

        if ('operatingReport' != val) {
          this.dataQueueInfoTemp['operatingReport'] = true;
        }
        if ('pathology' != val) {
          this.dataQueueInfoTemp['pathology'] = true;
        }
        if ('pricing' != val) {
          this.dataQueueInfoTemp['pricing'] = true;
        }
        if ('blockReport' != val) {
          this.dataQueueInfoTemp['blockReport'] = true;
        }
        if ('codingQuery' != val) {
          this.dataQueueInfoTemp['codingQuery'] = true;
        }
        if ('otherMisc' != val) {
          this.dataQueueInfoTemp['otherMisc'] = true;
        }
        if ('inCoding' != val) {
          this.dataQueueInfoTemp['inCoding'] = true;
        }
        if ('authReview' != val) {
          this.dataQueueInfoTemp['authReview'] = true;
        }
        if ('codingExpert' != val) {
          this.dataQueueInfoTemp['codingExpert'] = true;
        }
        if ('highApproval' != val) {
          this.dataQueueInfoTemp['highApproval'] = true;
        }
        if ('highApprovalInternal' != val) {
          this.dataQueueInfoTemp['highApprovalInternal'] = true;
        }
        if ('variance' != val) {
          this.dataQueueInfoTemp['variance'] = true;
        }
      }
      else {
        this.tabHead = "";
        this.tabVal["CONT0"] = true;
        this.dataQueueInfoTemp['operatingReport'] = false;
        this.dataQueueInfoTemp['pathology'] = false;
        this.dataQueueInfoTemp['pricing'] = false;
        this.dataQueueInfoTemp['blockReport'] = false;
        this.dataQueueInfoTemp['codingQuery'] = false;
        this.dataQueueInfoTemp['otherMisc'] = false;
        this.dataQueueInfoTemp['inCoding'] = false;
        this.dataQueueInfoTemp['authReview'] = false;
        this.dataQueueInfoTemp['codingExpert'] = false;
        this.dataQueueInfoTemp['highApproval'] = false;
        this.dataQueueInfoTemp['highApprovalInternal'] = false;
        this.dataQueueInfoTemp['variance'] = false;
      }
    }
  }


  setRequest() {
    var txtNote = this.PatientsForm.controls["txtNote"].value;
    let v = "";

    let flextn = "";
    if (this.fileName != null && this.fileName.name != undefined) {
      v = this.fileName.name;
      flextn = v.split('.')[1];
    }

    var req = {};
    if (this.tabHead == "In Coding" && (this.fname != undefined || this.fname != null)) {
      req = { "_requestType": this.tabHead, "_requestNote": txtNote, "_requestFileName": this.fname + '.' + flextn, "_requestFile": this.fileName };
    }
    else {
      req = { "_requestType": this.tabHead, "_requestNote": txtNote, "_requestFileName": v, "_requestFile": this.fileName };
    }
    this.requestArry.push(req);

    let dat = this.dataQueue.find(x => x.key == this.tabHead);
    this.dataQueueDtl[dat.value] = true;

    if (this.tabHead == "Client High$$$" || this.tabHead == " High$$$") {

      for (let d = 0; d < this.CPTCodeListForm.length; d++) {
        let frmcntrl = <FormArray>this.CPTCodeListForm.controls[d];
        if (frmcntrl.controls["cpt"].value != "" && frmcntrl.controls["cpt"].value != null) {
          if (this.cptAdded.indexOf(frmcntrl.controls["CPTCode"].value) == -1) {
            var cptDtls = { "cpt": frmcntrl.controls["CPTCode"].value, "CPTDesc": frmcntrl.controls["CPTDesc"].value, "Cost": frmcntrl.controls["Rate"].value };
            this.cptArry.push(cptDtls);
            this.cptAdded.push(frmcntrl.controls["CPTCode"].value);
          }
        }
      }
    }

    if (this.tabHead == "Coding Variance Review") {
      for (let d = 0; d < this.VarianceListForm.length; d++) {
        let frmcntrl = <FormArray>this.VarianceListForm.controls[d];
        if (frmcntrl.controls["nsn_cpt"].value != "" && frmcntrl.controls["nsn_cpt"].value != null) {
          var cptVarDtls = { "CPT": frmcntrl.controls["nsn_cpt_code"].value, "CPTDesc": frmcntrl.controls["nsn_Desc"].value, "CPTAlt": frmcntrl.controls["physician_cpt_code"].value, "CPTAltDesc": frmcntrl.controls["physician_Desc"].value };
          this.cptVarArry.push(cptVarDtls);
        }
      }
    }

    this.selectedtabVal.push(this.tabHead);
    this.fileName = null;
    this.fname = null;
    this.fileByte = "";
    this.PatientsForm.controls["txtNote"].setValue("");
    this.PatientsForm.controls["reqFile"].setValue("");
    this.tabVal["CONT0"] = true;
    this.tabVal["CONT1"] = false;
    this.tabVal["CONT2"] = false;
    this.tabVal["CONT3"] = false;

    this.dataQueueInfoTemp['operatingReport'] = false;
    this.dataQueueInfoTemp['pathology'] = false;
    this.dataQueueInfoTemp['pricing'] = false;
    this.dataQueueInfoTemp['blockReport'] = false;
    this.dataQueueInfoTemp['codingQuery'] = false;
    this.dataQueueInfoTemp['otherMisc'] = false;
    this.dataQueueInfoTemp['inCoding'] = false;
    this.dataQueueInfoTemp['authReview'] = false;
    this.dataQueueInfoTemp['codingExpert'] = false;
    this.dataQueueInfoTemp['highApproval'] = false;
    this.dataQueueInfoTemp['highApprovalInternal'] = false;
    this.dataQueueInfoTemp['variance'] = false;

  }

  fileChanged(event) {
    if (this.tabHead == 'In Coding' && this.fileSuffix != "") {
      let data = { "BDOSID": this._formData.bdosid, "applyFileSuffixForInCoding": this.fileSuffix };

      this.getApiService.post("BillingNew/GetStandardFileName", data).subscribe(res => {
        if (res.status == "Success") {
          this.fname = res.standardFileName;
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
    }

    this.fileName = event.target.files[0];

  }

  fileAttachChanged(event) {
    this.fileAttachName = event.target.files[0];
  }

  onSelectItem(event, id) {
    let total = 0;

    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    frmCntrl.controls["CPTCode"].setValue(event.item.cpt);
    frmCntrl.controls["CPTDesc"].setValue(event.item.cptDesc);
    frmCntrl.controls["Rate"].setValue(event.item.rate);

    for (let d = 0; this.CPTCodeListForm.controls.length > d; d++) {
      let fcntrl = <FormArray>this.CPTCodeListForm.controls[d];
      total += fcntrl.controls["Rate"].value;
    }
    this.totalCost = Number(total);
  }

  onVarianceSelectItem(event, id, type) {
    const frmCntrl = <FormArray>this.VarianceListForm.controls[id];
    if (type == 'nsn') {
      frmCntrl.controls["nsn_Desc"].setValue(event.item.cptDesc);
    }
    else if (type == 'physician') {
      frmCntrl.controls["physician_Desc"].setValue(event.item.cptDesc);
    }


  }

  chng(id) {
    const frmCntrl = <FormArray>this.CPTCodeListForm.controls[id];
    let vl = frmCntrl.controls["cpt"].value;

    try {
      if (vl.cpt == undefined) {

        this.searching = false;
        frmCntrl.controls["cpt"].setValue("");
        frmCntrl.controls["CPTCode"].setValue("");
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: true
        });
      }
      else {
        frmCntrl.controls["CPTCode"].setErrors({
          notFound: false
        });
        frmCntrl.controls["CPTCode"].setValue(vl.cpt);
      }
    }
    catch {
      console.log("catch");
      frmCntrl.controls["cpt"].setValue("");
      frmCntrl.controls["CPTCode"].setValue("");
      frmCntrl.controls["CPTCode"].setErrors({
        notFound: true
      });
    }
  }

  addCptCodeList() {
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    control.push(this.getCptCodeList());
  }

  removeCptCodeList(id: number) {
    const control = <FormArray>this.PatientsForm.controls['CPTCodeList'];
    const frmCntrl = <FormArray>control.controls[id];
    var rt = frmCntrl.controls["Rate"].value;
    this.totalCost = this.totalCost - Number(rt);
    control.removeAt(id);
  }

  addVarianceList() {
    const control = <FormArray>this.PatientsForm.controls['VarianceList'];
    control.push(this.getVarianceList());
  }

  removeVarianceList(id: number) {
    const control = <FormArray>this.PatientsForm.controls['VarianceList'];
    control.removeAt(id);
  }

  chngVariance(id, type) {
    const frmCntrl = <FormArray>this.VarianceListForm.controls[id];
    let vl = frmCntrl.controls[type].value;
    console.log(vl.cpt);
    try {
      if (vl == undefined) {

        if (type == 'nsn_cpt') {
          this.searching = false;
          frmCntrl.controls["nsn_cpt"].setValue("");
          frmCntrl.controls["nsn_cpt_code"].setValue("");
          frmCntrl.controls["nsn_Desc"].setValue("");
          frmCntrl.controls["nsn_cpt"].setErrors({
            notFound: true
          });
        }
        else if (type == 'physician_cpt') {
          this.searching = false;
          frmCntrl.controls["physician_cpt"].setValue("");
          frmCntrl.controls["physician_cpt_code"].setValue("");
          frmCntrl.controls["physician_Desc"].setValue("");
          frmCntrl.controls["physician_cpt"].setErrors({
            notFound: true
          });
        }
      }
      else {
        if (type == 'nsn_cpt') {
          frmCntrl.controls["nsn_cpt"].setErrors({
            notFound: false
          });
          frmCntrl.controls["nsn_cpt"].setValue(vl);
          // frmCntrl.controls["nsn_cpt_code"].setValue(vl);
        }
        else if (type == 'physician_cpt') {
          frmCntrl.controls["physician_cpt"].setErrors({
            notFound: false
          });
          frmCntrl.controls["physician_cpt"].setValue(vl);
          // frmCntrl.controls["physician_cpt_code"].setValue(vl);
        }
      }
    }
    catch {
      console.log("catch");
      if (type == 'nsn_cpt') {
        frmCntrl.controls["nsn_cpt"].setValue("");
        frmCntrl.controls["nsn_cpt_code"].setValue("");
        frmCntrl.controls["nsn_cpt"].setErrors({
          notFound: true
        });

      }
      else if (type == 'physician_cpt') {
        frmCntrl.controls["physician_cpt"].setValue("");
        frmCntrl.controls["physician_cpt_code"].setValue("");
        frmCntrl.controls["physician_cpt"].setErrors({
          notFound: true
        });

      }
    }
  }

  onVarSelectItem(event, id, type) {

    const frmCntrl = <FormArray>this.VarianceListForm.controls[id];

    if (type == 'nsn_cpt') {
      frmCntrl.controls["nsn_cpt"].setValue(event.item.cpt);
      frmCntrl.controls["nsn_cpt_code"].setValue(event.item.cpt);
      frmCntrl.controls["nsn_Desc"].setValue(event.item.cptDesc);
    }
    else if (type == 'physician_cpt') {
      frmCntrl.controls["physician_cpt"].setValue(event.item.cpt);
      frmCntrl.controls["physician_cpt_code"].setValue(event.item.cpt);
      frmCntrl.controls["physician_Desc"].setValue(event.item.cptDesc);
    }
  }

  onSubmit() {
    if (this.requestArry.length > 0) {
      this.spinner.show();
      let frmData = new FormData();
      frmData.append("BdosID", this._formData.bdosid);
      frmData.append("facilityId", this.facilityId.toString());
      frmData.append("Op", this.dataQueueInfo['operatingReport']);
      frmData.append("Pathology", this.dataQueueInfo['pathology']);
      frmData.append("Pricing", this.dataQueueInfo['pricing']);

      frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
      frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
      frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
      frmData.append("InCoding", this.dataQueueInfo['inCoding']);

      frmData.append("AuthReview", this.dataQueueInfo['authReview']);
      frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);
      frmData.append("txtNotes", "");

      if (this.cptArry.length == 0) {
        frmData.append("clientHigh", "false");
        frmData.append("NSNHigh", "false");
      }
      else {
        frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
        frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
      }

      if (this.cptVarArry.length == 0) {
        frmData.append("Variance", "false");
      }
      else {
        frmData.append("Variance", this.dataQueueInfo['variance']);
      }

      for (let d = 0; d < this.cptArry.length; d++) {
        frmData.append("DollarRequests[" + d + "].CPT", this.cptArry[d].cpt);
        frmData.append("DollarRequests[" + d + "].CPTDesc", this.cptArry[d].CPTDesc);
        frmData.append("DollarRequests[" + d + "].Cost", this.cptArry[d].Cost);
      }

      for (let f = 0; f < this.cptVarArry.length; f++) {
        console.log(this.cptVarArry[f]);
        frmData.append("RequestedVariance[" + f + "].CPT", this.cptVarArry[f].CPT);
        frmData.append("RequestedVariance[" + f + "].CPTDesc", this.cptVarArry[f].CPTDesc);
        frmData.append("RequestedVariance[" + f + "].CPTAlt", this.cptVarArry[f].CPTAlt);
        frmData.append("RequestedVariance[" + f + "].CPTAltDesc", this.cptVarArry[f].CPTAltDesc);
      }

      for (let e = 0; e < this.requestArry.length; e++) {
        frmData.append("RFI[" + e + "]._requestType", this.requestArry[e]._requestType);
        frmData.append("RFI[" + e + "]._requestNote", this.requestArry[e]._requestNote);
        frmData.append("RFI[" + e + "]._requestFileName", this.requestArry[e]._requestFileName);
        frmData.append("RFI[" + e + "]._rfiFile", this.requestArry[e]._requestFile);
      }

      if (this.dataQueueInfo['highApproval'] == true || this.dataQueueInfo['highApprovalInternal'] == true) {
        frmData.append("RequestDollarChanges", "true");
      }
      else {
        frmData.append("RequestDollarChanges", "false");
      }

      if (this.dataQueueInfo['variance'] == true) {
        frmData.append("RequestVarianceChanges", "true");
      }
      else {
        frmData.append("RequestVarianceChanges", "false");
      }

      frmData.append("IsHighApprovalInternal", this.dataQueueInfo['highApprovalInternal']);
      frmData.append("facilityName", this.facilityName);
      frmData.append("MRN", this.PatientSummaryDtls.dataPatient[0].mrn);
      frmData.append("RequestDollarStatus", "");
      frmData.append("RequestDollarComments", "");
      frmData.append("RequestVarianceStatus", "");
      frmData.append("RequestVarianceComments", "");

      this.getApiService.uploadFiles("BillingNew/Save", frmData).subscribe(res => {
        this.spinner.hide();
        if (res.status == "Success") {
          this.toastr.success("Saved Successfully!!", "Success");
          this.PatientsForm.reset();
          this.onClickClose();
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      });

    }
    else {
      return;
    }
  }

  deleteAll() {
    if (this.CPTCodeListForm.length > 1) {
      var ttlrw = this.CPTCodeListForm.length;
      for (let d = 0; d < ttlrw; d++) {
        this.CPTCodeListForm.removeAt(0);
      }
      // this.addCptCodeList();
    }
  }

  delChanges(reqType) {

    let dat = this.dataQueue.find(x => x.key == reqType);

    this.dataQueueDtl[dat.value] = false;
    this.dataQueueInfo[dat.value] = false;

    let e = this.selectedtabVal.findIndex(x => x == reqType);
    this.selectedtabVal.splice(e, 1);

    let d = this.requestArry.findIndex(x => x._requestType == reqType);
    this.requestArry.splice(d, 1);
  }

  uploadAttachment() {

    if (this.fileAttachName != null && this.fileAttachName.name != undefined) {
      let frmData = new FormData();
      frmData.append("BdosID", this._formData.bdosid);
      frmData.append("Op", this.dataQueueInfo['operatingReport']);
      frmData.append("Pathology", this.dataQueueInfo['pathology']);
      frmData.append("Pricing", this.dataQueueInfo['pricing']);
      frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
      frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
      frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
      frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
      frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
      frmData.append("InCoding", this.dataQueueInfo['inCoding']);
      frmData.append("Variance", this.dataQueueInfo['variance']);
      frmData.append("AuthReview", this.dataQueueInfo['authReview']);
      frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);

      frmData.append("txtNotes", (this.uploadFileText || ""))
      frmData.append("file", this.fileAttachName, this.fileAttachName.name);
      frmData.append("Filetype", "Attached File");

      this.getApiService.uploadFiles("BillingNew/Upload", frmData).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success("Uploaded Successfully!!", "Success");
          this.getPatientHeaderData();
          this.fileAttachName = "";
          this.uploadFile = null;
          this.uploadFileText = "";
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });

    }
  }

  addNotes() {
    let queueInfo = this.dataQueueInfo;
    queueInfo["BdosID"] = this._formData.bdosid;
    queueInfo["Notestypes"] = this.noteType;
    queueInfo["NotesCat"] = this.noteSelect;
    queueInfo["quickNotes"] = this.note;

    this.getApiService.post("BillingNew/AddNote", queueInfo).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Note added Successfully!!", "Success");
        this.getPatientHeaderData();
        this.noteType = "Private";
        this.note = "";
        this.noteSelect = "General Note";
      }
      else {
        this.toastr.error(res.message, 'Error!');
        this.noteType = "Private";
        this.note = "";
        this.noteSelect = "General Note";
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
      this.noteType = "Private";
      this.note = "";
      this.noteSelect = "General Note";
    });
  }

  caseCancelled() {
    let queueInfo = this.dataQueueInfo;
    queueInfo["BdosID"] = this._formData.bdosid;

    this.getApiService.post("BillingNew/CaseCancelled", queueInfo).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Case cancelled Successfully!!", "Success");
        this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

  }

  setBilled(type) {

    let frmData = new FormData();
    frmData.append("BdosID", this._formData.bdosid);
    frmData.append("facilityId", this.facilityId.toString());
    frmData.append("Op", this.dataQueueInfo['operatingReport']);
    frmData.append("Pathology", this.dataQueueInfo['pathology']);
    frmData.append("Pricing", this.dataQueueInfo['pricing']);
    frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
    frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
    frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
    frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
    frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
    frmData.append("InCoding", this.dataQueueInfo['inCoding']);
    frmData.append("Variance", this.dataQueueInfo['variance']);
    frmData.append("AuthReview", this.dataQueueInfo['authReview']);
    frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);
    frmData.append("SetBilledType", type);
    frmData.append("claimAmount", this.billAmount.toString());
    frmData.append("status", "Billed");
    frmData.append("txtNotes", "");

    for (let d = 0; d < this.cptArry.length; d++) {
      frmData.append("DollarRequests[" + d + "].CPT", this.cptArry[d].cpt);
      frmData.append("DollarRequests[" + d + "].CPTDesc", this.cptArry[d].CPTDesc);
      frmData.append("DollarRequests[" + d + "].Cost", this.cptArry[d].Cost);
    }

    for (let e = 0; e < this.requestArry.length; e++) {
      frmData.append("RFI[" + e + "]._requestType", this.requestArry[e]._requestType);
      frmData.append("RFI[" + e + "]._requestNote", this.requestArry[e]._requestNote);
      frmData.append("RFI[" + e + "]._requestFileName", this.requestArry[e]._requestFileName);
      frmData.append("RFI[" + e + "]._rfiFile", this.requestArry[e]._requestFile);
    }

    if (this.dataQueueInfo['highApproval'] == true || this.dataQueueInfo['highApprovalInternal'] == true) {
      frmData.append("RequestDollarChanges", "true");
    }
    else {
      frmData.append("RequestDollarChanges", "false");
    }

    if (this.dataQueueInfo['variance'] == true) {
      frmData.append("RequestVarianceChanges", "true");
    }
    else {
      frmData.append("RequestVarianceChanges", "false");
    }

    frmData.append("IsHighApprovalInternal", this.dataQueueInfo['highApprovalInternal']);
    frmData.append("facilityName", this.facilityName);
    frmData.append("MRN", this.PatientSummaryDtls.dataPatient[0].mrn);
    frmData.append("RequestDollarStatus", "");
    frmData.append("RequestDollarComments", "");
    frmData.append("RequestVarianceStatus", "");
    frmData.append("RequestVarianceComments", "");

    this.getApiService.uploadFiles("BillingNew/SetBilled", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Billed Successfully!!", "Success");
        this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  deleteCase() {
    let data = { "BdosID": this._formData.bdosid };

    this.getApiService.post("BillingNew/Delete", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Case Deleted Successfully!!", "Success");
        this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  unlockRequest() {
    this.dataQueueDtl['operatingReport'] = false;
    this.dataQueueDtl['pathology'] = false;
    this.dataQueueDtl['pricing'] = false;
    this.dataQueueDtl['highApproval'] = false;
    this.dataQueueDtl['highApprovalInternal'] = false;
    this.dataQueueDtl['blockReport'] = false;
    this.dataQueueDtl['codingQuery'] = false;
    this.dataQueueDtl['otherMisc'] = false;
    this.dataQueueDtl['inCoding'] = false;
    this.dataQueueDtl['variance'] = false;
    this.dataQueueDtl['authReview'] = false;
    this.dataQueueDtl['codingExpert'] = false;
  }

  reqCancel() {
    this.tabVal["CONT0"] = true;
    this.tabVal["CONT1"] = false;
    this.tabVal["CONT2"] = false;
    this.tabVal["CONT3"] = false;

    let dat = this.dataQueue.find(x => x.key == this.tabHead);
    this.PatientsForm.reset();
    if (this.dataQueueDtl[dat.value] != true) {
      this.dataQueueInfo[dat.value] = false;
    }

    this.dataQueueInfoTemp['operatingReport'] = false;
    this.dataQueueInfoTemp['pathology'] = false;
    this.dataQueueInfoTemp['pricing'] = false;
    this.dataQueueInfoTemp['blockReport'] = false;
    this.dataQueueInfoTemp['codingQuery'] = false;
    this.dataQueueInfoTemp['otherMisc'] = false;
    this.dataQueueInfoTemp['inCoding'] = false;
    this.dataQueueInfoTemp['authReview'] = false;
    this.dataQueueInfoTemp['codingExpert'] = false;
    this.dataQueueInfoTemp['highApproval'] = false;
    this.dataQueueInfoTemp['highApprovalInternal'] = false;
    this.dataQueueInfoTemp['variance'] = false;
  }

  setStatus(stat) {
    let frmData = new FormData();
    frmData.append("BdosID", this._formData.bdosid);
    frmData.append("facilityId", this.facilityId.toString());
    frmData.append("Op", this.dataQueueInfo['operatingReport']);
    frmData.append("Pathology", this.dataQueueInfo['pathology']);
    frmData.append("Pricing", this.dataQueueInfo['pricing']);


    frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
    frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
    frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
    frmData.append("InCoding", this.dataQueueInfo['inCoding']);

    frmData.append("AuthReview", this.dataQueueInfo['authReview']);
    frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);
    frmData.append("txtNotes", "");

    for (let d = 0; d < this.cptArry.length; d++) {
      frmData.append("DollarRequests[" + d + "].CPT", this.cptArry[d].cpt);
      frmData.append("DollarRequests[" + d + "].CPTDesc", this.cptArry[d].CPTDesc);
      frmData.append("DollarRequests[" + d + "].Cost", this.cptArry[d].Cost);
    }

    if (this.requestArry.length > 0) {
      for (let e = 0; e < this.requestArry.length; e++) {
        frmData.append("RFI[" + e + "]._requestType", this.requestArry[e]._requestType);
        frmData.append("RFI[" + e + "]._requestNote", this.requestArry[e]._requestNote);
        frmData.append("RFI[" + e + "]._requestFileName", this.requestArry[e]._requestFileName);
        frmData.append("RFI[" + e + "]._rfiFile", this.requestArry[e]._requestFile);
      }
    }
    else {
      frmData.append("RFI[0]._requestType", this.tabHead);
      frmData.append("RFI[0]._requestNote", "");
      frmData.append("RFI[0]._requestFileName", "");
      frmData.append("RFI[0]._rfiFile", "");
    }


    if (this.tabHead == "Client High$$$" || this.tabHead == ' High$$$') {
      frmData.append("RequestDollarChanges", "false");
      if (this.tabHead == "Client High$$$") {
        frmData.append("clientHigh", "false");
      }
      else {
        frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
      }
      if (this.tabHead == ' High$$$') {
        frmData.append("NSNHigh", "false");
      }
      else {
        frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
      }
    }

    if (this.tabHead == "Coding Variance Review") {
      frmData.append("RequestVarianceChanges", "false");
      frmData.append("Variance", "false");
    }
    else {
      frmData.append("Variance", this.dataQueueInfo['variance']);
    }


    if (this.dataQueueInfo['highApproval'] == true || this.dataQueueInfo['highApprovalInternal'] == true) {
      frmData.append("RequestDollarStatus", stat);
      frmData.append("RequestDollarComments", this.txtApproval);
      frmData.append("RequestVarianceStatus", "");
      frmData.append("RequestVarianceComments", "");
    }
    else if (this.dataQueueInfo['variance'] == true) {
      frmData.append("RequestDollarStatus", "");
      frmData.append("RequestDollarComments", "");
      frmData.append("RequestVarianceStatus", stat);
      frmData.append("RequestVarianceComments", this.txtApproval);
    }
    else {
      frmData.append("RequestDollarStatus", "");
      frmData.append("RequestDollarComments", "");
      frmData.append("RequestVarianceStatus", "");
      frmData.append("RequestVarianceComments", "");
    }


    frmData.append("IsHighApprovalInternal", this.dataQueueInfo['highApprovalInternal']);
    frmData.append("facilityName", this.facilityName);
    frmData.append("MRN", this.PatientSummaryDtls.dataPatient[0].mrn);


    this.getApiService.uploadFiles("BillingNew/Save", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("Saved Successfully!!", "Success");
        this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  unSetBilled() {

    let frmData = new FormData();
    frmData.append("BdosID", this._formData.bdosid);
    frmData.append("facilityId", this.facilityId.toString());
    frmData.append("Op", this.dataQueueInfo['operatingReport']);
    frmData.append("Pathology", this.dataQueueInfo['pathology']);
    frmData.append("Pricing", this.dataQueueInfo['pricing']);
    frmData.append("clientHigh", this.dataQueueInfo['highApproval']);
    frmData.append("NSNHigh", this.dataQueueInfo['highApprovalInternal']);
    frmData.append("BlockReport", this.dataQueueInfo['blockReport']);
    frmData.append("codingquery", this.dataQueueInfo['codingQuery']);
    frmData.append("otherMisc", this.dataQueueInfo['otherMisc']);
    frmData.append("InCoding", this.dataQueueInfo['inCoding']);
    frmData.append("Variance", this.dataQueueInfo['variance']);
    frmData.append("AuthReview", this.dataQueueInfo['authReview']);
    frmData.append("CodingExpert", this.dataQueueInfo['codingExpert']);
    frmData.append("status", "Unbilled");
    frmData.append("txtNotes", "");

    for (let d = 0; d < this.cptArry.length; d++) {
      frmData.append("DollarRequests[" + d + "].CPT", this.cptArry[d].cpt);
      frmData.append("DollarRequests[" + d + "].CPTDesc", this.cptArry[d].CPTDesc);
      frmData.append("DollarRequests[" + d + "].Cost", this.cptArry[d].Cost);
    }

    for (let e = 0; e < this.requestArry.length; e++) {
      frmData.append("RFI[" + e + "]._requestType", this.requestArry[e]._requestType);
      frmData.append("RFI[" + e + "]._requestNote", this.requestArry[e]._requestNote);
      frmData.append("RFI[" + e + "]._requestFileName", this.requestArry[e]._requestFileName);
      frmData.append("RFI[" + e + "]._rfiFile", this.requestArry[e]._requestFile);
    }

    if (this.dataQueueInfo['highApproval'] == true || this.dataQueueInfo['highApprovalInternal'] == true) {
      frmData.append("RequestDollarChanges", "true");
    }
    else {
      frmData.append("RequestDollarChanges", "false");
    }

    if (this.dataQueueInfo['variance'] == true) {
      frmData.append("RequestVarianceChanges", "true");
    }
    else {
      frmData.append("RequestVarianceChanges", "false");
    }

    frmData.append("IsHighApprovalInternal", this.dataQueueInfo['highApprovalInternal']);
    frmData.append("facilityName", this.facilityName);
    frmData.append("MRN", this.PatientSummaryDtls.dataPatient[0].mrn);
    frmData.append("RequestDollarStatus", "");
    frmData.append("RequestDollarComments", "");
    frmData.append("RequestVarianceStatus", "");
    frmData.append("RequestVarianceComments", "");

    this.getApiService.uploadFiles("BillingNew/UnSetBilled", frmData).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success("UnBilled Successfully!!", "Success");
        this.PatientsForm.reset();
        this.onClickClose();
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  fetchDollarData(name, val, tabname) {

    this.tabVal["CONT0"] = false;
    this.tabVal["CONT1"] = false;
    this.tabVal["CONT2"] = false;
    this.tabVal["CONT3"] = false;

    this.fileName = "";
    this.fileByte = "";
    this.PatientsForm.reset();
    this.totalCost = 0;
    this.fetch$data();
    this.tabHead = name;
    this.tabVal[tabname] = true;

  }

  fetch$data() {
    let data = { "BdosID": this._formData.bdosid };


    // if (this.dataQueueInfo['highApprovalInternal'] == true || this.dataQueueInfo['highApproval'] == true) {
    this.getApiService.uploadFiles("BillingNew/GetHighDollarTabData", data).subscribe(res => {
      if (res.status == "Success") {

        if (res.dataHighTab.length > 0) {
          var ttlrw = this.CPTCodeListForm.length;
          for (let d = 0; d < ttlrw; d++) {
            this.CPTCodeListForm.removeAt(0);
          }
        }

        for (let d of res.dataHighTab) {
          let cntrl = this.getCptCodeList();
          cntrl.controls["cpt"].setValue(d);
          cntrl.controls["CPTCode"].setValue(d.cpt);
          cntrl.controls["CPTDesc"].setValue(d.cptDesc);
          cntrl.controls["Rate"].setValue(d.cost);

          this.totalCost += d.cost;
          this.CPTCodeListForm.push(cntrl);
        }

        if (res.dataHighTabStaus != undefined && res.dataHighTabStaus.length > 0) {

          if (res.dataHighTabStaus[0].wasHighApprovalInternal == 1) {
            this.dataQueueInfo['highApprovalInternal'] = true;
            this.dataQueueDtl['highApprovalInternal'] = true;
          }
          else {
            this.dataQueueInfo['highApproval'] = true;
            this.dataQueueDtl['highApproval'] = true;
          }

          this.stat = res.dataHighTabStaus[0].requestStatus;
          this.highApprovalinternal = res.dataHighTabStaus[0].wasHighApprovalInternal;
        }
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });

    // }
    // else if (this.dataQueueInfo['variance'] == true) {
    this.getApiService.uploadFiles("BillingNew/GetVarianceTabData", data).subscribe(res => {
      if (res.status == "Success") {
        if (res.dataVarianceTab.length > 0) {
          var ttlrw = this.VarianceListForm.length;
          for (let d = 0; d < ttlrw; d++) {
            this.VarianceListForm.removeAt(0);
          }
        }

        for (let d of res.dataVarianceTab) {
          let cntrl = this.getVarianceList();

          let e = { cpt: d.cptAlt, cptDesc: d.cptAltDesc };
          cntrl.controls["nsn_cpt"].setValue(d);
          cntrl.controls["nsn_cpt_code"].setValue(d.cpt);
          cntrl.controls["nsn_Desc"].setValue(d.cptDesc);
          cntrl.controls["physician_cpt"].setValue(e);
          cntrl.controls["physician_cpt_code"].setValue(d.cptAlt);
          cntrl.controls["physician_Desc"].setValue(d.cptAltDesc);

          this.VarianceListForm.push(cntrl);
        }

        if (res.dataVarianceTabStaus != undefined && res.dataVarianceTabStaus.length > 0) {
          this.varStat = res.dataVarianceTabStaus[0].requestStatus;
        }
      }
      else {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
    // }
  }

}
