import { Component, OnInit,EventEmitter,Output,Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.css']
})
export class CustomFieldFormComponent implements OnInit {
  
  @Output() closeEmitter = new EventEmitter();
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  formFlag: string = "Add";
  _formData: any;
  custom_field: string;
  fields: any[] = [];
  submitted = false;
  CustomFieldMasterForm: FormGroup;
  @Input() set formData(value: any) {
    
    if (value != null && value != undefined) {
      this._formData = value;
      this.formFlag = "Edit";
    // this.getCategories();
     
     this.editForm();
    }
    else {
      this.formFlag = "Add";
      this.initForm();
    }
  }
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }
  initForm() {
    this.CustomFieldMasterForm = this.fb.group({
      id: ["",],
      Name: ["", [Validators.required]],
      
    })
  }
  editForm() {
   // this.CategoryMasterForm.controls['Name'].setValue(this._formData.categoryName);
    this.CustomFieldMasterForm = this.fb.group({
      id: [this._formData.id, [Validators.required]],
      Name: [this._formData.fieldName, [Validators.required]],
     
    });
   
    // this.CategoryMasterForm.patchValue({
    //   Name: this._formData.categoryName
    // })
   // console.log(this.CategoryMasterForm,"catmaster");
  }
  get f() { return this.CustomFieldMasterForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.addRecord();
    // if (this.formFlag == "Add") {
    //   this.addRecord();
    // }
    // else if (this.formFlag == "Edit") {
    //   this.editRecord();
    // }

  }
  addRecord() {
    const formdata = this.CustomFieldMasterForm.getRawValue();
    let frmData = new FormData();
    var data:any;
    if (this.formFlag == "Edit") {
     // frmData.append("Id", this.CategoryMasterForm.controls["Id"].value);
       data = {"id" : this.CustomFieldMasterForm.controls["id"].value,"FieldName" : this.CustomFieldMasterForm.controls["Name"].value}
    }else{
      data = {"FieldName" : this.CustomFieldMasterForm.controls["Name"].value}
    }
    
    this.getApiService.post("CustomField/SaveCustomField", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
       
        this.CustomFieldMasterForm.reset();
        this.closeEmitter.emit();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }
  onClickClose() {
    this.closeEmitter.emit();
  }

}
