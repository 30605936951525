import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { Cpt } from './cpt.model';

export interface CptState extends EntityState<Cpt, number>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'cpt', idKey: 'cpt' })
export class CptStore extends EntityStore<CptState> {

  constructor() {
    super(initialState);
  }

}
