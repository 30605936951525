import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AccessService } from '../services/access.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  array:any;
  admin:string;
  constructor(private router: Router, private accessService: AccessService) {}

  canActivate(
  
    router: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    status = localStorage.getItem('loginStatus');
    this.array=JSON.parse(localStorage.getItem("privilegearray"))
    // console.log(this.array,"array");
    if(status== 'true' && 
    this.array?.includes("accessAll")){
      return true;
    } else if (
      status == 'true' &&
      this.array?.includes(router.routeConfig.path)
    ) {
      return true;
    }
    else {
      // console.log('working here');
      this.router.navigate(['/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
