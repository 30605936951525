<!-- Sidenav-->
<div id="mySidenav5" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Daily Reconciliation</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav5" id="closeMySidenav5">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">

        <form class="pb-3 form-fields">
            <div class="row">
                <div class="form-group frm-margin-btm col-12 label_space_new">
                    <label for="inputFirstName">DOS</label>
                    <p class="font100 mt-1">13/03/2020</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 label_space_new">
                    <label for="inputFirstName">Search Patient </label>
                </div>
            </div>
            <div class="row">
                <div class="form-group remove-botttom-space col-12">
                    <div class="input-group">
                        <input type="text" class="form-control" id="inputFirstName" aria-describedby="FirstNameHelp"
                            placeholder="Last Name, First Name" required>
                    </div>
                </div>

            </div>

            <h4>Facility</h4>
            <div class="row">
                <div class="form-group remove-botttom-space col-12">
                    <div class="input-group">
                        <input type="text" class="form-control" id="inputFirstName" aria-describedby="FirstNameHelp"
                            placeholder="Search Facility" required>
                    </div>
                </div>
                <!--<div class="form-group col-6">
                    <div class="input-group">
                        <input type="text" class="form-control" id="exampleMobileNumber" aria-describedby="MobileNumberHelp"
                            placeholder="Phone Number">
                    </div>
                </div>
                <div class="form-group col-6">		 
                    <input type="text" class="form-control" id="inputSSNNo" aria-describedby="SSNNoHelp"
                        placeholder="SSN No" required>
                </div>-->
            </div>
            <h4>Physician Information</h4>
            <div class="row">
                <div class="form-group col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" id="exampleInputFirstName"
                            aria-describedby="FirstNameHelp" placeholder="First Name">
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <div class="input-group">
                        <input type="text" class="form-control" id="exampleInputLastName"
                            aria-describedby="LastNameHelp" placeholder=" Last Name">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group remove-botttom-space col-md-6">
                    <h4>MRN</h4>
                    <div class="input-group">

                        <input type="text" class="form-control" id="exampleInputHouseNo" aria-describedby="HouseNoHelp"
                            placeholder="MRN">
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-md-6">
                    <h4>Case</h4>
                    <div class="input-group">

                        <input type="text" class="form-control" id="exampleInputLocality"
                            aria-describedby="LocalityHelp" placeholder="Case">
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-md-6">
                    <h4>Paitent Resp</h4>
                    <div class="input-group">
                        <input type="text" class="form-control" id="exampleCity" aria-describedby="CityHelp"
                            placeholder="$0.00">
                    </div>
                </div>
                <div class="form-group remove-botttom-space col-md-6">
                    <h4>Paitent Paid</h4>
                    <div class="input-group">
                        <input type="text" class="form-control" id="exampleState" aria-describedby="StateHelp"
                            placeholder="$0.00">
                    </div>
                </div>
                <div class="form-group  col-md-6">
                    <h4>Mode</h4>
                    <div class="input-group">

                        <select class="custom-select form-control">
                            <option selected="" disabled="">select</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>

                    </div>
                </div>
                <div class="form-group  col-md-6">
                    <h4>Payer Type</h4>
                    <div class="input-group">

                        <select class="custom-select form-control">
                            <option selected="" disabled="">select</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>

                    </div>
                </div>
                <div class="form-group frm-margin-btm col-md-12">

                    <textarea class="form-control" id="name" aria-describedby="FirstNameHelp" placeholder="Comment"
                        required></textarea>
                </div>
            </div>


            <div class="pb-3 text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                    <button [attr.disabled]=" submit == 'Submiting' ? true : null" id="bt" 
                    class="btn btn-primary btn-patient-box"type="submit">
                        {{submit}}  
                         <span *ngIf="submit == 'Submiting' " class="fas fa-spinner fa-spin "></span></button>
                <!-- <button type="submit" class="btn btn-primary btn-patient-box">Submit</button> -->
            </div>
        </form>
    </div>
</div>

<!-- Sidenav-->