<div class="user-login fullheight fullheight-pad">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div>
                    <img src="assets/img/hitechhealth-logo.png" alt="" class="img-fluid py-3" />
                </div>
                <div class="">
                    <h1 class="mb-4">Register to your account</h1>
                </div>
                <form class="" [formGroup]="registrationForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" placeholder="Full Name" autocomplete="false" name="FullName"
                                formControlName="FullName" class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.FullName.errors }" />
                            <div *ngIf="submitted && f.FullName.errors" class="invalid-feedback">
                                <div *ngIf="f.FullName.errors.required">*Name is required</div>
                                <div *ngIf="f.FullName.errors.pattern">*Enter valid name</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="email" placeholder="Email Address" autocomplete="false"
                                formControlName="UserName" class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.UserName.errors }" />
                            <div *ngIf="submitted && f.UserName.errors" class="invalid-feedback">
                                <div *ngIf="f.UserName.errors.required">*Email Address is required</div>
                                <div *ngIf="f.UserName.errors.email">*Email Address must be valid</div>
                                <!-- <div *ngIf="f.UserName.errors.UserName">*Email Address must be valid</div> -->
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="password" placeholder="Password" autocomplete="false" name="password"
                                formControlName="Password" class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.Password.errors }" />
                            <div *ngIf="submitted && f.Password.errors" class="invalid-feedback">
                                <div *ngIf="f.Password.errors.required">*Password is required</div>
                                <div *ngIf="f.Password.errors.minlength">*Password must be at least 6 characters</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="password" placeholder="Confirm Password" formControlName="ConfirmPassword"
                                class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.ConfirmPassword.errors }" />
                            <div *ngIf="submitted && f.ConfirmPassword.errors" class="invalid-feedback">
                                <div *ngIf="f.ConfirmPassword.errors.required">*Confirm Password is required</div>
                                <div *ngIf="f.ConfirmPassword.errors.minlength">*Confirm Password must be at least 6
                                    characters</div>
                                <div *ngIf="f.ConfirmPassword.errors.mustMatch">*Password and Confirm Password must
                                    match</div>
                            </div>
                            <!-- <input type="password" class="form-control form-control-user" id="exampleInputPassword"
                                placeholder="Confirm Password">   (mousedown)="checkMatching()"
                                (focusout)="checkMatching()" (blur)="checkMatching()" -->
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="input-group">
                            <input type="text" placeholder="Invitation Code" formControlName="InvitationCode"
                                class="form-control form-control-user form-control-color"
                                [ngClass]="{ 'is-invalid': submitted && f.InvitationCode.errors }" />
                            <div *ngIf="submitted && f.InvitationCode.errors" class="invalid-feedback">
                                <div *ngIf="f.InvitationCode.errors.required">*Invitation Code is required</div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-primary btn-user btn-block login-btn-user">Register</button>
                </form>
                <div class="">
                    <p><a routerLink="/login"><i class="fa fa-arrow-left back-arrow"></i>Back to Login</a></p>
                </div>
                <div class="">
                    <p>By Register your account, you agree to HITech Health Solutions <a href="#"
                            class="text-underline">Terms & Conditions</a> and <a href="#" class="text-underline">Privacy
                            Policy</a></p>
                </div>
            </div>
        </div>
    </div>
</div>