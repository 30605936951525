import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommandType } from '../dash-detail/dash-detail.types';
@Component({
  selector: 'app-eod',
  templateUrl: './eod.component.html',
  styleUrls: ['./eod.component.css']
})
export class EodComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  eodCount: 0;
  holdCount: 0;
  FacilityList: any;
  p: any = {};
  commandtype: string;
  caseBilledCommandType = CommandType.CaseBilled.toLowerCase();
  closeDaybtnEnableStatus = false;
  buttonUndoEODVisibleStatus = false;
  eodArry: any[];
  eodComplete: any[];
  searchValue: string = "";
  pageValue: any = 10;
  totalCurrentRows: any = 0;
  totalCompletedRows: any = 0;
  pagenumber: any = 1;
  facilityID: any;
  formData: any = null;
  Sno: number = 0;
  closeDayButton: boolean = false;
  showConfirmation: boolean = false;
  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private patientService: PatientService,
    private commonUtil: CommonService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.commonUtil.setTitle("EOD");
    // this.facilityID = this.route.snapshot.params.id;
    this.getFacilityInfo();
    this.retainandsetdefaultfacility();
    this.getEodArry("Current", 0);
    this.getEodArry("Complete", 0);

  }
  retainandsetdefaultfacility() {
    let facility = localStorage.getItem("dashboardFacilityId")
    // Here facility id number  is used instead of FacilityId in other api's thats why we used slice method
    facility = facility.slice(9)
    console.log(facility, "facility in EOD")
    

    if (facility && facility != '0') {
      this.facilityID = facility;
      console.log(facility, "facility in EOD2")
    } else {
      this.facilityID = "0"
    }
  }

  showFormModal(data) {
    this.patientService.setAndActivate([data]);
    this.formData = data;
    
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
      
  }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
    // this.getFacilityInfo();
    // this.retainandsetdefaultfacility();
    this.getEodArry("Current", 0);
    this.getEodArry("Complete", 0);
    // this.getFacilityInfo();
    //  this.searchValue="";
    // this.search()
   

    // window.location.pathname=("d/eod/0")
    // this.getEodArry("Current", 0);
    // this.getEodArry("Complete", 0);
    this.p[this.commandtype] = 1;
  }
  focus(){
    this.searchValue=""
  }

  getFacilityInfo() {
    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
        console.log(this.FacilityList, "facility list in eod")
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getEodArry(type, tCll) {
    console.log(type);

    this.Sno = 0;
    let date = new Date();
    let cdt = (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear();
    let data = {};
    let url = "";
    
    if (type == "Current") {
      url = "EOD/BindEODGridData";
      data = {
        "facilityId": this.facilityID,
        "EODdate": cdt,
        "EODType": "Current",
        "pageNum": this.pagenumber,
        "pageSize": this.pageValue,
        "PatientName": this.searchValue.trim()
      }
    }
    else if (type == "Complete") {
      url = "EOD/GetCompletedEODPatientList";
      data = {
        "facilityId": this.facilityID,
        "EODdate": cdt,
        "pageNum": this.pagenumber,
        "pageSize": this.pageValue,
        "PatientName": this.searchValue.trim()
      }
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {

        if (type == "Current") {
          if (tCll == 0) {
            if (res.dataClaimBindGrid.length > 0) {
              this.totalCurrentRows = res.dataClaimBindGrid[0].totalcount;
            }
            else {
              this.totalCurrentRows = 0;
            }
            this.eodCount = res.eodCount;
            this.holdCount = res.holdQueueCount;
            this.closeDaybtnEnableStatus = res.closeDaybtnEnableStatus;
            this.buttonUndoEODVisibleStatus = res.buttonUndoEODVisibleStatus;
          }

          this.eodArry = res.dataClaimBindGrid;
        }
        else if (type == "Complete") {
          if (res.dataCompletedEODPatientList.length > 0) {
            if (tCll == 0) {
              this.totalCompletedRows = res.dataCompletedEODPatientList[0].totalcount;
            } else {
              this.totalCompletedRows = 0;
            }
            this.eodComplete = res.dataCompletedEODPatientList;
          }
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onEntryChange(event, type) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.eodComplete = [];
    this.getEodArry(type, 1);
  }

  pageChange(event, type) {
    this.pagenumber = event;
    this.Sno = 0;
    this.getEodArry('Current', 1)
    // this.getEodArry(type, 1);
  }


  onFacilityChange() {
    // this.p = 1;
    this.changeFacility();
    this.retainandsetdefaultfacility();

    this.Sno = 0;
    this.pagenumber = 1;
    this.getEodArry("Current", 0);
    this.getEodArry("Complete", 0);

  }
  changeFacility() {
    if (this.facilityID != 0) {
      localStorage.setItem("dashboardFacilityId", "facility_" + this.facilityID)
    } else {
      localStorage.setItem("dashboardFacilityId", this.facilityID)
    }

  }

  getSnoVal() {
    return this.Sno += 1;

  }
  closeDay() {
    this.showConfirmation = true;

  }
  confirmNoClick() {
    this.showConfirmation = false;
    this.closeEmitter.emit();
  }
  confirmYesClick() {

    this.spinner.show();

    this.showConfirmation = false;
    let data = {};
    this.getApiService.post("EOD/SaveEODSummary", data).subscribe(res => {
      if (res.status == "Success") {
        this.spinner.hide();
        //this.toastr.success(res.message, 'Success!');
        // this.getEodArry("Complete", 0);
        window.location.reload();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
    // routerLink="/d/closeday/{{facilityID}}"
    // this.router.navigate(['/d/closeday/'+this.facilityID]);
  }
  search() {
    console.log(this.searchValue,this.searchValue.length)
    if (this.searchValue.length==0) {
      window.location.pathname=("d/eod/0")
      // window.location.reload();
      // window.location.pathname=(`d/eod/${}`)
      // this.router.navigate(['/d/eod/0'])
    } else {
      this.p = 1;
      this.retainandsetdefaultfacility();
      this.getEodArry("Current", 0);
    }
  }

}
