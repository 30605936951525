<!-- Sidebar -->
<ul *ngIf="isClientRole() || isClientLiteRole()" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
   id="accordionSidebar">
   <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/center/dashboard">
      <img src="assets/img/hitechhealth-logo.png" class="img-fluid" alt="NSN Revenue Resources">
   </a>
   <li  class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/center/dashboard">
         <i class="fas fa-fw fa-home"></i>
         <span>Dashboard</span>
      </a>
   </li>
   <!-- <li *ngIf="navbar.dashboard" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fas fa-fw fa-home"></i>
         <span>Dashboard</span>
      </a>
   </li> -->
   <li *ngIf="navbar.billing" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-file-invoice"></i>
         <span>Billing</span>
      </a>
   </li>
   <li *ngIf="navbar.coding" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCodingInquiry"
         aria-expanded="true" aria-controls="collapseCodingInquiry">
         <i title="Coding Review" class="fas fa-check-double"></i>
         <span>Coding <i *ngIf="countStatus" class="fa fa-exclamation" style="color: #ff0000;"></i></span>
      </a>
      <div id="collapseCodingInquiry" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/coding-inquiry/coding-review" [routerLinkActive]="['active']">-
               <span>Coding Review </span>
            </a>
            <a class="collapse-item" routerLink="/coding-inquiry/coding-reviewed" [routerLinkActive]="['active']">-
               <span>Coding Reviewed</span>
            </a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.posting" class="nav-item">
      <a class="nav-link collapsed" routerLink="/posting/dashboard" data-toggle="collapse" data-target="#collapseOne"
         aria-expanded="true" aria-controls="collapseOne">
         <i class="fa fa-file-invoice"></i>
         <span>Posting</span>
      </a>
      <div id="collapseOne" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <!-- <a class="collapse-item" routerLink="/posting/dashboard" [routerLinkActive]="['active']">- Dashboard</a> -->
            <a class="collapse-item" routerLink="/posting/dr-client" [routerLinkActive]="['active']">- DR-Client</a>
            <!-- <a class="collapse-item" routerLink="/posting/dr-nsn" [routerLinkActive]="['active']">- DR-NSN</a> -->
            <a class="collapse-item" routerLink="/posting/deposit-log" [routerLinkActive]="['active']">- Deposit Log</a>
            <a class="collapse-item" routerLink="/posting/goal-tracker" [routerLinkActive]="['active']">- Goal
               Tracker</a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.collections" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-compass "></i>
         <span>Collections</span>
      </a>
   </li>
   <li *ngIf="navbar.ar" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/ar/dashboard">
         <i class="fa fa-compress-alt"></i>
         <span>AR</span>
      </a>
   </li>
   <li *ngIf="navbar.refunds" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-archive"></i>
         <span>Refunds</span>
      </a>
   </li>
   <li *ngIf="navbar.patient" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a  class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePatients"
         aria-expanded="true" aria-controls="collapsePatients">
         <i class="fa fa-user"></i>
         <span>Patients</span>
      </a>
      <div id="collapsePatients" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.patients" class="collapse-item" routerLink="/patient/manage-patient" [routerLinkActive]="['active']">-
               <span>Patients</span>
            </a>
            <a *ngIf="navbar.assignCases" class="collapse-item" routerLink="/patient/assign-case" [routerLinkActive]="['active']">-
               <span>Assign Cases</span>
            </a>
         </div>
      </div>
   </li>
   <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-question-circle"></i>
         <span>QA</span>
      </a>
   </li> -->
   <li *ngIf="navbar.qa" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBatchReviewCases"
         aria-expanded="true" aria-controls="collapseBatchReviewCases">
         <i class="fa fa-question-circle"></i>
         <span>QA</span>
      </a>
      <div id="collapseBatchReviewCases" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/review-cases/batch-review-cases" [routerLinkActive]="['active']">-
               <span>Case Review</span>
            </a>
            <a class="collapse-item" routerLink="/review-cases/reviewed-cases" [routerLinkActive]="['active']">-
               <span>Reviewed Cases</span>
            </a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.reports" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
         aria-expanded="true" aria-controls="collapseTwo">
         <i class="fa fa-newspaper"></i>
         <span>Reports</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.productivityByUser" class="collapse-item" routerLink="/reports/user-productivity-report">Productivity By User</a>
            <a *ngIf="navbar.productivityByFacility" class="collapse-item" routerLink="/reports/facility-report">Productivity By Facility</a>
            <a *ngIf="navbar.caseQueue" class="collapse-item" routerLink="/reports/case-queue-facility-report">Case Queue</a>
            <a *ngIf="navbar.management" class="collapse-item" routerLink="/reports/management-report">Management</a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.kb" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/kb/knowledge-base">
         <i class="fa fa-book"></i>
         <span>Knowledge Base</span>
      </a>
   </li>
   <li *ngIf="navbar.masters" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
         aria-controls="collapseThree">
         <i class="fa fa-database"></i>
         <span>Masters</span>
      </a>
      <div id="collapseThree" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/masters/facility-masters" [routerLinkActive]="['active']">-
               Facilities</a>
            <a class="collapse-item" routerLink="/masters/group" [routerLinkActive]="['active']">-
               Facility Group</a>
            <a class="collapse-item" routerLink="/masters/category" [routerLinkActive]="['active']">-
               File Category</a>
            <a class="collapse-item" routerLink="/masters/undo-setbill-reason" [routerLinkActive]="['active']">-
               Unset Billed Reason</a>
            <a class="collapse-item" routerLink="/masters/queue" [routerLinkActive]="['active']">-
               Custom Queue</a>
            <a class="collapse-item" routerLink="/masters/custom-field" [routerLinkActive]="['active']">-
                  Custom Field</a>
            <a class="collapse-item" routerLink="/masters/paper-claim" [routerLinkActive]="['active']">-
               Paper Claim</a>
            <a class="collapse-item" routerLink="/masters/coding-status" [routerLinkActive]="['active']">-
                  Coding Status</a>
               
         </div>
      </div>
   </li>
   <li *ngIf="navbar.settings" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
         aria-controls="collapseFour">
         <i class="fa fa-cog"></i>
         <span>Settings</span>
      </a>
      <div id="collapseFour" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/settings/emailNotification" [routerLinkActive]="['active']">- Email
               Notifications</a>
            <!-- <a class="collapse-item" href="#">- List2</a>
            <a class="collapse-item" href="#">- List3</a> -->
         </div>
      </div>
   </li>
   <li *ngIf="navbar.admin" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="javascript:void(0)" data-toggle="collapse" data-target="#collapseFive"
         aria-expanded="true" aria-controls="collapseFive">
         <i class="fa fa-user-shield"></i>
         <span>Admin</span>
      </a>
      <div id="collapseFive" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.manageUsers" class="collapse-item" routerLink="/admin/manageusers" [routerLinkActive]="['active']">- Manage Users</a>
            <a *ngIf="navbar.importPayers" class="collapse-item" routerLink="/masters/import-payers" [routerLinkActive]="['active']">-
               Import Payers</a>
            <a *ngIf="navbar.importPhysicians" class="collapse-item" routerLink="/masters/import-physicians" [routerLinkActive]="['active']">-
               Import Physicians</a>
            <a *ngIf="navbar.importPatients" class="collapse-item" routerLink="/admin/importpatient" [routerLinkActive]="['active']">- Import
               Patients</a>
            <a *ngIf="navbar.billingRules" class="collapse-item" routerLink="/admin/billingrules" [routerLinkActive]="['active']">- Billing
               Rules</a>
            <a *ngIf="navbar.feeSchedule" class="collapse-item" routerLink="/admin/feeschedule" [routerLinkActive]="['active']">- Fees Schedule</a>
            <a *ngIf="navbar.EOD" class="collapse-item" routerLink="/admin/eodmaintenance" [routerLinkActive]="['active']">- EOD
               Maintenance</a>
         </div>
      </div>
   </li>
   <li  class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" href="#" data-toggle="modal" data-target="#logoutModal">
         <i class="fa fa-sign-out-alt"></i>
         <span>Logout</span>
      </a>
   </li>
</ul>

<ul *ngIf="isCoderRole() || isCodingManagerRole()" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
   id="accordionSidebar">
   <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/dashboard">
      <img src="assets/img/hitechhealth-logo.png" class="img-fluid" alt="NSN Revenue Resources">
   </a>
  
   <li *ngIf="navbar.dashboard" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fas fa-fw fa-home"></i>
         <span>Dashboard</span>
      </a>
   </li>
   <li *ngIf="navbar.billing" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-file-invoice"></i>
         <span>Billing</span>
      </a>
   </li>
   <li *ngIf="navbar.coding" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCodingInquiry"
         aria-expanded="true" aria-controls="collapseCodingInquiry">
         <i title="Coding Review" class="fas fa-check-double"></i>
         <span>Coding <i *ngIf="countStatus" class="fa fa-exclamation" style="color: #ff0000;"></i></span>
      </a>
      <div id="collapseCodingInquiry" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/coding-inquiry/coding-review" [routerLinkActive]="['active']">-
               <span>Coding Review </span>
            </a>
            <a class="collapse-item" routerLink="/coding-inquiry/coding-reviewed" [routerLinkActive]="['active']">-
               <span>Coding Reviewed</span>
            </a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.posting" class="nav-item">
      <a class="nav-link collapsed" routerLink="/posting/dashboard" data-toggle="collapse" data-target="#collapseOne"
         aria-expanded="true" aria-controls="collapseOne">
         <i class="fa fa-file-invoice"></i>
         <span>Posting</span>
      </a>
      <div id="collapseOne" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <!-- <a class="collapse-item" routerLink="/posting/dashboard" [routerLinkActive]="['active']">- Dashboard</a> -->
            <a class="collapse-item" routerLink="/posting/dr-client" [routerLinkActive]="['active']">- DR-Client</a>
            <!-- <a class="collapse-item" routerLink="/posting/dr-nsn" [routerLinkActive]="['active']">- DR-NSN</a> -->
            <a class="collapse-item" routerLink="/posting/deposit-log" [routerLinkActive]="['active']">- Deposit Log</a>
            <a class="collapse-item" routerLink="/posting/goal-tracker" [routerLinkActive]="['active']">- Goal
               Tracker</a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.collections" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-compass "></i>
         <span>Collections</span>
      </a>
   </li>
   <li *ngIf="navbar.ar" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/ar/dashboard">
         <i class="fa fa-compress-alt"></i>
         <span>AR</span>
      </a>
   </li>
   <li *ngIf="navbar.refunds" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-archive"></i>
         <span>Refunds</span>
      </a>
   </li>
   <li *ngIf="navbar.patient" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a  class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePatients"
         aria-expanded="true" aria-controls="collapsePatients">
         <i class="fa fa-user"></i>
         <span>Patients</span>
      </a>
      <div id="collapsePatients" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.patients" class="collapse-item" routerLink="/patient/manage-patient" [routerLinkActive]="['active']">-
               <span>Patients</span>
            </a>
            <a *ngIf="navbar.assignCases" class="collapse-item" routerLink="/patient/assign-case" [routerLinkActive]="['active']">-
               <span>Assign Cases</span>
            </a>
         </div>
      </div>
   </li>
   <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-question-circle"></i>
         <span>QA</span>
      </a>
   </li> -->
   <li *ngIf="navbar.qa" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBatchReviewCases"
         aria-expanded="true" aria-controls="collapseBatchReviewCases">
         <i class="fa fa-question-circle"></i>
         <span>QA</span>
      </a>
      <div id="collapseBatchReviewCases" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/review-cases/batch-review-cases" [routerLinkActive]="['active']">-
               <span>Case Review</span>
            </a>
            <a class="collapse-item" routerLink="/review-cases/reviewed-cases" [routerLinkActive]="['active']">-
               <span>Reviewed Cases</span>
            </a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.reports" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
         aria-expanded="true" aria-controls="collapseTwo">
         <i class="fa fa-newspaper"></i>
         <span>Reports</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.productivityByUser" class="collapse-item" routerLink="/reports/user-productivity-report">Productivity By User</a>
            <a *ngIf="navbar.productivityByFacility" class="collapse-item" routerLink="/reports/facility-report">Productivity By Facility</a>
            <a *ngIf="navbar.caseQueue" class="collapse-item" routerLink="/reports/case-queue-facility-report">Case Queue</a>
            <a *ngIf="navbar.management" class="collapse-item" routerLink="/reports/management-report">Management</a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.kb" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/kb/knowledge-base">
         <i class="fa fa-book"></i>
         <span>Knowledge Base</span>
      </a>
   </li>
   <li *ngIf="navbar.masters" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
         aria-controls="collapseThree">
         <i class="fa fa-database"></i>
         <span>Masters</span>
      </a>
      <div id="collapseThree" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/masters/facility-masters" [routerLinkActive]="['active']">-
               Facilities</a>
            <a class="collapse-item" routerLink="/masters/group" [routerLinkActive]="['active']">-
               Facility Group</a>
            <a class="collapse-item" routerLink="/masters/category" [routerLinkActive]="['active']">-
               File Category</a>
            <a class="collapse-item" routerLink="/masters/undo-setbill-reason" [routerLinkActive]="['active']">-
               Unset Billed Reason</a>
            <a class="collapse-item" routerLink="/masters/queue" [routerLinkActive]="['active']">-
               Custom Queue</a>
            <a class="collapse-item" routerLink="/masters/custom-field" [routerLinkActive]="['active']">-
                  Custom Field</a>
            <a class="collapse-item" routerLink="/masters/paper-claim" [routerLinkActive]="['active']">-
               Paper Claim</a>
            <a class="collapse-item" routerLink="/masters/coding-status" [routerLinkActive]="['active']">-
                  Coding Status</a>
               
         </div>
      </div>
   </li>
   <li *ngIf="navbar.settings" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
         aria-controls="collapseFour">
         <i class="fa fa-cog"></i>
         <span>Settings</span>
      </a>
      <div id="collapseFour" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/settings/emailNotification" [routerLinkActive]="['active']">- Email
               Notifications</a>
            <!-- <a class="collapse-item" href="#">- List2</a>
            <a class="collapse-item" href="#">- List3</a> -->
         </div>
      </div>
   </li>
   <li *ngIf="navbar.admin" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="javascript:void(0)" data-toggle="collapse" data-target="#collapseFive"
         aria-expanded="true" aria-controls="collapseFive">
         <i class="fa fa-user-shield"></i>
         <span>Admin</span>
      </a>
      <div id="collapseFive" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.manageUsers" class="collapse-item" routerLink="/admin/manageusers" [routerLinkActive]="['active']">- Manage Users</a>
            <a *ngIf="navbar.importPayers" class="collapse-item" routerLink="/masters/import-payers" [routerLinkActive]="['active']">-
               Import Payers</a>
            <a *ngIf="navbar.importPhysicians" class="collapse-item" routerLink="/masters/import-physicians" [routerLinkActive]="['active']">-
               Import Physicians</a>
            <a *ngIf="navbar.importPatients" class="collapse-item" routerLink="/admin/importpatient" [routerLinkActive]="['active']">- Import
               Patients</a>
            <a *ngIf="navbar.billingRules" class="collapse-item" routerLink="/admin/billingrules" [routerLinkActive]="['active']">- Billing
               Rules</a>
            <a *ngIf="navbar.feeSchedule" class="collapse-item" routerLink="/admin/feeschedule" [routerLinkActive]="['active']">- Fees Schedule</a>
            <a *ngIf="navbar.EOD" class="collapse-item" routerLink="/admin/eodmaintenance" [routerLinkActive]="['active']">- EOD
               Maintenance</a>
         </div>
      </div>
   </li>
   <li  class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" href="#" data-toggle="modal" data-target="#logoutModal">
         <i class="fa fa-sign-out-alt"></i>
         <span>Logout</span>
      </a>
   </li>
</ul>

<ul *ngIf="( !isClientRole() && !isClientLiteRole() )  && (!isCoderRole() && !isCodingManagerRole() )" class="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
   id="accordionSidebar">
   <a class="sidebar-brand d-flex align-items-center justify-content-center" routerLink="/dashboard">
      <img src="assets/img/hitechhealth-logo.png" class="img-fluid" alt="NSN Revenue Resources">
   </a>
   <li *ngIf="navbar.dashboard" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fas fa-fw fa-home"></i>
         <span>Dashboard</span>
      </a>
   </li>
   <li *ngIf="navbar.billing" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-file-invoice"></i>
         <span>Billing</span>
      </a>
   </li>
   <li *ngIf="navbar.coding" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseCodingInquiry"
         aria-expanded="true" aria-controls="collapseCodingInquiry">
         <i title="Coding Review" class="fas fa-check-double"></i>
         <span>Coding <i *ngIf="countStatus" class="fa fa-exclamation" style="color:#ff0000;"></i></span>
      </a>
      <div id="collapseCodingInquiry" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/coding-inquiry/coding-review" [routerLinkActive]="['active']">-
               <span>Coding Review </span>
            </a>
            <a class="collapse-item" routerLink="/coding-inquiry/coding-reviewed" [routerLinkActive]="['active']">-
               <span>Coding Reviewed</span>
            </a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.posting" class="nav-item">
      <a class="nav-link collapsed" routerLink="/posting/dashboard" data-toggle="collapse" data-target="#collapseOne"
         aria-expanded="true" aria-controls="collapseOne">
         <i class="fa fa-file-invoice"></i>
         <span>Posting</span>
      </a>
      <div id="collapseOne" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <!-- <a class="collapse-item" routerLink="/posting/dashboard" [routerLinkActive]="['active']">- Dashboard</a> -->
            <a class="collapse-item" routerLink="/posting/dr-client" [routerLinkActive]="['active']">- DR-Client</a>
            <!-- <a class="collapse-item" routerLink="/posting/dr-nsn" [routerLinkActive]="['active']">- DR-NSN</a> -->
            <a class="collapse-item" routerLink="/posting/deposit-log" [routerLinkActive]="['active']">- Deposit Log</a>
            <a class="collapse-item" routerLink="/posting/goal-tracker" [routerLinkActive]="['active']">- Goal
               Tracker</a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.collections" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-compass "></i>
         <span>Collections</span>
      </a>
   </li>
   <li *ngIf="navbar.ar" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/ar/dashboard">
         <i class="fa fa-compress-alt"></i>
         <span>AR</span>
      </a>
   </li>
   <li *ngIf="navbar.refunds" class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-archive"></i>
         <span>Refunds</span>
      </a>
   </li>
   <li *ngIf="navbar.patient" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a  class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePatients"
         aria-expanded="true" aria-controls="collapsePatients">
         <i class="fa fa-user"></i>
         <span>Patients</span>
      </a>
      <div id="collapsePatients" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.patients" class="collapse-item" routerLink="/patient/manage-patient" [routerLinkActive]="['active']">-
               <span>Patients</span>
            </a>
            <a *ngIf="navbar.assignCases" class="collapse-item" routerLink="/patient/assign-case" [routerLinkActive]="['active']">-
               <span>Assign Cases</span>
            </a>
         </div>
      </div>
   </li>
   <!-- <li class="nav-item">
      <a class="nav-link" routerLink="/dashboard">
         <i class="fa fa-question-circle"></i>
         <span>QA</span>
      </a>
   </li> -->
   <li *ngIf="navbar.qa" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseBatchReviewCases"
         aria-expanded="true" aria-controls="collapseBatchReviewCases">
         <i class="fa fa-question-circle"></i>
         <span>QA</span>
      </a>
      <div id="collapseBatchReviewCases" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/review-cases/batch-review-cases" [routerLinkActive]="['active']">-
               <span>Case Review</span>
            </a>
            <a class="collapse-item" routerLink="/review-cases/reviewed-cases" [routerLinkActive]="['active']">-
               <span>Reviewed Cases</span>
            </a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.reports" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
         aria-expanded="true" aria-controls="collapseTwo">
         <i class="fa fa-newspaper"></i>
         <span>Reports</span>
      </a>
      <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.productivityByUser" class="collapse-item" routerLink="/reports/user-productivity-report">Productivity By User</a>
            <a *ngIf="navbar.productivityByFacility" class="collapse-item" routerLink="/reports/facility-report">Productivity By Facility</a>
            <a *ngIf="navbar.caseQueue" class="collapse-item" routerLink="/reports/case-queue-facility-report">Case Queue</a>
            <a *ngIf="navbar.management" class="collapse-item" routerLink="/reports/management-report">Management</a>
         </div>
      </div>
   </li>
   <li *ngIf="navbar.kb" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/kb/knowledge-base">
         <i class="fa fa-book"></i>
         <span>Knowledge Base</span>
      </a>
   </li>
   <li *ngIf="navbar.masters" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
         aria-controls="collapseThree">
         <i class="fa fa-database"></i>
         <span>Masters</span>
      </a>
      <div id="collapseThree" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/masters/facility-masters" [routerLinkActive]="['active']">-
               Facilities</a>
            <a class="collapse-item" routerLink="/masters/group" [routerLinkActive]="['active']">-
               Facility Group</a>
            <a class="collapse-item" routerLink="/masters/category" [routerLinkActive]="['active']">-
               File Category</a>
            <a class="collapse-item" routerLink="/masters/undo-setbill-reason" [routerLinkActive]="['active']">-
               Unset Billed Reason</a>
            <a class="collapse-item" routerLink="/masters/queue" [routerLinkActive]="['active']">-
               Custom Queue</a>
            <a class="collapse-item" routerLink="/masters/custom-field" [routerLinkActive]="['active']">-
                  Custom Field</a>
            <a class="collapse-item" routerLink="/masters/paper-claim" [routerLinkActive]="['active']">-
               Paper Claim</a>
            <a class="collapse-item" routerLink="/masters/coding-status" [routerLinkActive]="['active']">-
                  Coding Status</a>
               
         </div>
      </div>
   </li>
   <li *ngIf="navbar.settings" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
         aria-controls="collapseFour">
         <i class="fa fa-cog"></i>
         <span>Settings</span>
      </a>
      <div id="collapseFour" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a class="collapse-item" routerLink="/settings/emailNotification" [routerLinkActive]="['active']">- Email
               Notifications</a>
            <!-- <a class="collapse-item" href="#">- List2</a>
            <a class="collapse-item" href="#">- List3</a> -->
         </div>
      </div>
   </li>
   <li *ngIf="navbar.admin" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link collapsed" href="javascript:void(0)" data-toggle="collapse" data-target="#collapseFive"
         aria-expanded="true" aria-controls="collapseFive">
         <i class="fa fa-user-shield"></i>
         <span>Admin</span>
      </a>
      <div id="collapseFive" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
         <div class="py-2 collapse-inner rounded">
            <a *ngIf="navbar.manageUsers" class="collapse-item" routerLink="/admin/manageusers" [routerLinkActive]="['active']">- Manage Users</a>
            <a *ngIf="navbar.importPayers" class="collapse-item" routerLink="/masters/import-payers" [routerLinkActive]="['active']">-
               Import Payers</a>
            <a *ngIf="navbar.importPhysicians" class="collapse-item" routerLink="/masters/import-physicians" [routerLinkActive]="['active']">-
               Import Physicians</a>
            <a *ngIf="navbar.importPatients" class="collapse-item" routerLink="/admin/importpatient" [routerLinkActive]="['active']">- Import
               Patients</a>
            <a *ngIf="navbar.billingRules" class="collapse-item" routerLink="/admin/billingrules" [routerLinkActive]="['active']">- Billing
               Rules</a>
            <a *ngIf="navbar.feeSchedule" class="collapse-item" routerLink="/admin/feeschedule" [routerLinkActive]="['active']">- Fees Schedule</a>
            <a *ngIf="navbar.EOD" class="collapse-item" routerLink="/admin/eodmaintenance" [routerLinkActive]="['active']">- EOD
               Maintenance</a>
         </div>
      </div>
   </li>

   <li *ngIf="navbar.auditLog" class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" routerLink="/auditlogs">
         <i class="fa fa-book"></i>
         <span>Audit Logs</span>
      </a>
   </li>

   <li  class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <a class="nav-link" href="#" data-toggle="modal" data-target="#logoutModal">
         <i class="fa fa-sign-out-alt"></i>
         <span>Logout</span>
      </a>
   </li>

</ul>
<!-- End of Sidebar -->