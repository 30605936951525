import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-drpp-client-form',
  templateUrl: './drpp-client-form.component.html',
  styleUrls: ['./drpp-client-form.component.css']
})
export class DrppClientFormComponent implements OnInit {
  submit:any="Submit"
  @Output() closeEmitter = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  onClickClose() {
    
    this.closeEmitter.emit();
  }

}
