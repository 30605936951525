<div class="row cr-row-expand" *ngIf="showExpand">
    <button type="button" class="btn btn-secondary btn-sm btn-round" (click)="toggleExapnd()">
        <i class="fas fa-chevron-up"></i>
    </button>
</div>
<div class="card" style="position: unset;" *ngIf="showExpand">
    <div class="card-body">
        <div class="row">
            <div class="col-md-9">
                <div class="form-group frm-margin-btm">
                    <label for="inputFirstName" class="comment-heading-case">Notes</label>
                    <textarea class="form-control form-control1" [(ngModel)]="note" required rows="4"
                        cols="3"></textarea>
                </div>
            </div>
            <div class="form-group frm-margin-btm variance-lbl frm-padng-top col-md-3">
                <input type="radio" id="hold" name="note" value="Private" [(ngModel)]="noteType">
                <label for="private">Private</label><br>
                <input type="radio" id="reject" name="note" value="Public" [(ngModel)]="noteType">
                <label for="public">Public</label>
            </div>
        </div>
        <div class="row">
            <div class="form-group frm-margin-btm casecattab col-md-12" style="padding-top: 0px;">
                <div class="input-group cr-gen-note">
                    <select class="custom-select form-control" [(ngModel)]="noteSelect">
                        <option selected Value="General Note">General Note</option>
                        <ng-container *ngFor="let d of currentCaseRequestList$ | async">
                            <option [value]="d.value">{{d.value}}</option>
                        </ng-container>


                    </select>
                </div>
                <button type="button" (click)="addNotes()" class="btn btn-primary btn-patient-box">Add</button>
            </div>
        </div>
    </div>
</div>

<div class="row cr-row-expand" *ngIf="!showExpand">
    <button type="button" class="btn btn-secondary btn-sm btn-round" (click)="toggleExapnd()">
        <i class="fas fa-chevron-down"></i>
    </button>
</div>
<div class="card" style="position: unset;" *ngIf="!showExpand">
    <div class="card-body">
        <div class="row">
            <div class="col-md-9">
                <div class="form-group frm-margin-btm">
                    <label for="inputFirstName" class="comment-heading-case">Notes</label>
                </div>
            </div>
        </div>
    </div>
</div>
