<div class="container-fluid">
    <div class="row counters">
        <div class="col-12 mb-4">
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">Coding Status List</h1>
                    <a href="javascript:void(0);" (click)="openAddForm()" id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        Add Coding Status
                    </a>
                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns">{{col.displayName}}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr style="color: #333;">
                                    <th *ngFor="let col of coloumns">{{col.displayName}}</th>
                                </tr>
                            </tfoot>
                            <ng-container *ngIf="(dataSource$ | async) as dataSource">
                            <tr *ngFor="let data of dataSource | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage, totalItems: totalRows}; let i = index;" style="color: #333;
                            font-weight: 400;">
                                <td> {{(i+1) + ((currentPage-1)*itemsPerPage)}} </td>
                                <td>{{data.inquirystatus}}</td>
                                <td>
                                    <label class="custom-switch">
                                    <input type="checkbox" (click)="toggleStatus(data)"
                                        [checked]="data.status === 'Active'">
                                    <span class="slider"></span>
                                </label>
                            </td>
                                <td>{{data.priority}}</td>
                                <td>
                                    <a href="javascript:void(0)" (click)="openEditForm(data)"
                                        class="btn btn-success icon-btn openMySidenav"><i
                                            class="fa fa-edit" title="Edit"></i></a>&nbsp;
                                            
                                </td>
                            </tr>
                            </ng-container>
                        </table>
                        <pagination-controls 
                        class="float-right"
                        (pageChange)="pageChange($event); currentPage = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-coding-status-add *ngIf ="shouldShowAddForm" (closeEmitter)="onCloseFormModal()"></app-coding-status-add>
<app-coding-status-edit *ngIf ="shouldShowEditForm" (closeEmitter)="onCloseFormModal()"></app-coding-status-edit>
