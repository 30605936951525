import { RequestType } from "../../enums/request-type";

export interface CurrentCaseRequest {
  id: number | string;
  value: string;
  displayName: string;
  type: RequestType;
  comment: string;
  file: any;
  fileName: string;
  lcdReview: boolean;
  scsreview: boolean;
  unlistedReview: boolean;
  otherMiscReview: boolean;
  alreadySubmitted: boolean;
  isModified: boolean;
  isApproved: boolean;
}

export function createCurrentCaseRequest(params: Partial<CurrentCaseRequest>) {
  return {
    ...params
  } as CurrentCaseRequest;
}
