import { Component, OnInit,EventEmitter,Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  FacilityMasterArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  categories: any[] = [];
  searchValue: string = "";
  category_id:any;
  CategoryMasterForm: FormGroup;
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.getCategories();
  }
  sortListCategory(orderBy,sortDir){
    //let  data = { "FacilityId":this.edit_id,"pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue,"OrderBy":orderBy,  "SortDir":sortDir };
    let  data = {"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("FileCategory/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.categories = resp.data;
        this.totalRows = resp.data[0].totalcount;
      

      }

    }, (err) => {
      console.log(err);
    })
}
  editCategoryMaster(data) {
  
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
      this.getCategories();
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.categories = [];
    this.getCategories();
  }

  pageChange(event) {
    this.pagenumber = event;
    this.getCategories();
  }
  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getCategories();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }


  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getCategories();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }
  getCategories() {
    if (this.searchValue != '' && this.pagenumber != 1) {
      this.pagenumber = 1;
      this.p = 1;
    }
    
    let  data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };
    // let data = { "pageNum": 1, "pageSize": 100 };

    this.getApiService.post("FileCategory/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.categories = resp.data;
        this.totalRows = resp.data[0].totalcount;
      

      }

    }, (err) => {
      console.log(err);
    })
  }
  showFormModal() {
    this.formData = null;

    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }
  delCategoryMaster(delRow) {

    let data = { "id": delRow.id, "deleted": !delRow.deleted };

    this.getApiService.post("FileCategory/DeleteCategory", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getCategories();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
  }
}
