<div class="card cr-card-brdr">
    <div class="card-body cr-card-body">
        <div class="row">
            <div class="col-md-12">
                <p class="high-section-textt cr-label">
                    {{componentName.value}} Request
                </p>
            </div>
        </div>
        <div class="row">
            <div class="form-group frm-margin-btm col-md-12">
                <textarea class="form-control" [(ngModel)]="comment" placeholder="Comment" required></textarea>
            </div>
            <div class="form-group col-md-12">
                <input type="file" class="form-control-file py-1" (change)="fileChanged($event)">
            </div>
            <div class="form-group col-md-12" *ngIf="isAttachedFileVisible">
                <a href="javascript:void(0);"  (click)="downloadFile()">{{fileName}}</a>
            </div>
        </div>
        <div class="row" style="padding-right: 15px;">
            <div  class="col-md-12 frm-margin-btm btn-remove-pdng">
                <div class="text-right">
                    <button *ngIf="privileges.dQueue" type="button" (click)="removeTab()"
                        class="btn btn-secondary  btn-patient-box closeMySidenav mr-1">Cancel</button>
                    <button *ngIf="((isClientView$ | async) === false) && privileges.dQueue" type="button" (click)="addRequestToCurrentState()"
                        class="btn btn-primary  btn-patient-box">Queue</button>
                    <button *ngIf="isClientView$ | async" type="button" (click)="addRequestToCurrentState()"
                        class="btn btn-primary  btn-patient-box">Satisfy</button>
                </div>
            </div>
        </div>
    </div>
</div>