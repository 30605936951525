import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-import-physician-form',
  templateUrl: './import-physician-form.component.html',
  styleUrls: ['./import-physician-form.component.css']
})
export class ImportPhysicianFormComponent implements OnInit {
  @Output() addedEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  filetoUpload;
  constructor( private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  addRecord() {
    let formData = new FormData();
    console.log('Uploading FIle', this.filetoUpload);
    formData.append("file", this.filetoUpload, this.filetoUpload.name);
    this.getApiService.uploadFiles('Physician/UploadFile', formData).subscribe((resp: any) => {
      if(resp.status === 'Success'){
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      });
    }else if(resp.status === 'Error'){
      this.toastr.error(resp.status, resp.message);
      console.log(resp.message);
    }else{
      this.toastr.error('Unknown Status of Response', resp.status);
    }

    }, (err) => {
      console.log(err);
    })
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  onClickClose() {
    this.closeEmitter.emit();
  }

  uploadFile(fileInput) {
    this.filetoUpload = fileInput.target.files[0];
  }

  downloadfile() {
    try{
      let downloadUrl = this.getApiService.webUrl + "uploads/ImportPhysician.csv";
      window.location.href=downloadUrl;
      }catch(e){
        console.log(e);
      }
  }

}
