import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../shared/services/api.service';
import { CommonService } from '../../shared/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from './session/session.service';
import { SessionQuery } from './session/session.query';
import { ShareDataService } from 'src/app/shared/services/share-data.service';
import { AccessService } from 'src/app/shared/services/access.service';
import { any } from 'cypress/types/bluebird';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  submitted = false;
  loginButton = 'Log in';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private sessionService: SessionService,
    private sessionQuery: SessionQuery,
    private shareddataservice: ShareDataService,
    private accessservices: AccessService
  ) {}

  ngOnInit(): void {
    this.settingtimeout();
    console.log(this.accessservices.access_array, 'arrayin ngoninit');
    this.commonUtil.setTitle('Login');
    this.loginForm = this.formBuilder.group({
      UserName: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      Password: ['', [Validators.required, Validators.minLength(4)]],
    });
  }

  get f() {
    return this.loginForm.controls;
  }

  settingtimeout() {
    let data: any;
    this.getApiService
      .post('ApplicationUser/GetConfigSettings', data)
      .subscribe((resp: any) => {
        console.log(resp);
        if (resp.status == 'Success') {
          resp.message.forEach((element) => {
            if (element.configKey == 'warningTimeInSeconds') {
              localStorage.setItem('warningtime', element.configValue);
              // this.warningtime = element.configValue;
            }
            if (element.configKey == 'allowedIdleTimeInSeconds') {
              localStorage.setItem('logouttime', element.configValue);
              // this.logouttime = element.configValue;
            }
          });
        }
      });
  }

  onSubmit() {
    this.submitted = true;

    if (this.loginForm.invalid) {
      return;
    }
    this.loginButton = 'Logging';
    this.getApiService
      .post('ApplicationUser/Login', this.loginForm.value)
      .subscribe(
        (res) => {
          console.log(res, 'response');
          if (res != undefined && res.status == 'Success') {
            localStorage.setItem('idleTime', 'true');
            let data: any;
            res.privileges?.map((element) => {
              this.accessservices.access_array.push(element.name);
            });
            localStorage.setItem(
              'privilegearray',
              JSON.stringify(this.accessservices.access_array)
            );
            localStorage.setItem('currentUser', JSON.stringify(res));
            localStorage.setItem('loginStatus', 'true');
            localStorage.setItem('loggedUser', res.username);
            localStorage.setItem('loggedUserName', res.fullName);

            this.sessionService.updateUser({
              token: res.token,
              username: res.username,
              email: res.email,
              role: res.role,
              fullName: res.fullName,
            });
            this.toastr.success('Login Successfully!', 'Success!');
            if (
              this.sessionQuery.isClientRole() ||
              this.sessionQuery.isClientLiteRole()
            ) {
              // localStorage.setItem('loggedUserName', res.fullName);
              // this.router.navigate(['/center/dashboard']);
              window.location.pathname = '/center/dashboard';
            } else if (this.sessionQuery.isCoderRole()) {
              // else if (this.sessionQuery.isCoderRole() || this.sessionQuery.isCodingManagerRole){
              // this.router.navigate(['/coding-inquiry/coding-review']);
              // this.router.navigate(['/dashboard']);
              window.location.pathname = '/dashboard';
              // window.location.reload();
            } else {
              // this.router.navigate(['/dashboard']);
              window.location.pathname = '/dashboard';
              // window.location.reload();
            }
          } else if (res.status == 'Error') {
            this.loginButton = 'Log in';
            this.toastr.error(res.message, 'Error!');
          }
        },
        (error) => {
          this.loginButton = 'Log in';
          localStorage.clear();
          this.toastr.error(error, 'Error!');
        }
      );
  }
}
