<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="row rowwrp">
                        <div class="col-md-4">
                            <select class="custom-select dashboard-calnedar-patient1" [(ngModel)]="facilityID">
                                <option selected value="0">All Facilities</option>
                                <option [value]="f.id" *ngFor="let f of FacilityList">{{f.name}}</option>
                            </select>
                        </div>
                        <div class="col-md-4">
                            <select class="custom-select dashboard-calnedar-patient">
                                <option value="">Status</option>
                            </select>
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="fromDate">
                        </div>
                        <div class="col-md-2">
                            <input type="text" class="form-control" #dp="bsDatepicker" bsDatepicker
                                [bsConfig]="{ showWeekNumbers:false }" [(bsValue)]="toDate">
                        </div>

                    </div>
                
                    <div class="row rowwrp">
                        <div class="col-md-10">
                            <input id="searchPatient" [(ngModel)]="searchValue" type="text" name="searchPatient"
                                placeholder="Search Patient" (keyup.enter)="getPaitentArrayList()">
                        </div>
                        <div class="col-md-2">
                            <a href="javascript:void(0)"> <i class="fas fa-file-excel"></i></a>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th></th>
                                    <th>Facility <i (click)="sortList('client', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('client', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>MRN <i (click)="sortList('mrn', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('mrn', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Patient <i (click)="sortList('name', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('name', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <!-- <th>Provider</th> -->
                                    <th>DoS <i (click)="sortList('dateOfSurgery', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('dateOfSurgery', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <!-- <th>Primary CPT</th> -->
                                    <th>Insurance <i (click)="sortList('insuranceProvider', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('insuranceProvider', 'desc')"
                                            class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Amount <i (click)="sortList('amount', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('amount', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Time <i (click)="sortList('timeCol', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('timeCol', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Comment</th>
                                    <th>Last Comment Date <i (click)="sortList('lcd', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('lcd', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <!-- <th>Quick Access</th> -->
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th></th>
                                    <th>Facility <i (click)="sortList('client', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('client', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>MRN <i (click)="sortList('mrn', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('mrn', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Patient <i (click)="sortList('name', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('name', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <!-- <th>Provider</th> -->
                                    <th>DoS <i (click)="sortList('dateOfSurgery', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('dateOfSurgery', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <!-- <th>Primary CPT</th> -->
                                    <th>Insurance <i (click)="sortList('insuranceProvider', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('insuranceProvider', 'desc')"
                                            class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Amount <i (click)="sortList('amount', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('amount', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Time <i (click)="sortList('timeCol', 'asc')" class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('timeCol', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <th>Comment</th>
                                    <th>Last Comment Date <i (click)="sortList('lcd', 'asc')"
                                            class="fas fa-arrow-up mr-1"></i>
                                        <i (click)="sortList('lcd', 'desc')" class="fas fa-arrow-down"></i>
                                    </th>
                                    <!-- <th>Quick Access</th> -->
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of allpatientQdata | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>
                                        <div>{{i+1}}</div>
                                    </td>
                                    <td><input type="checkbox"></td>
                                    <td data-toggle="tooltip" data-placement="top" title="{{(data?.client)}}">
                                        {{(data?.client?.length>20)? (data?.client |
                                        slice:0:23):(data?.client)}}<br>
                                        {{(data?.client?.length>20)? (data?.client | slice:23:40):""}}
                                        {{(data?.client?.length>40)? (data?.client | slice:40:40)+'...':""}}
                                    </td>
                                    <td>{{data?.mrn}}</td>
                                    <td>{{data?.name}}<br>
                                        <!-- <span class="span-grey" *ngIf="data?.mrn != ''">
                                                MRN#</span> -->
                                    </td>
                                    <!-- <td></td> -->
                                    <td>{{data?.dateOfSurgery | date: 'MM/dd/yyyy'}}<br>
                                        <span class="span-grey">{{data?.physician}}</span>
                                    </td>
                                    <!-- <td>{{data?.primartCPT}}</td> -->
                                    <td data-toggle="tooltip" data-placement="top"
                                        title="{{(data?.insuranceProvider)}}">
                                        {{(data?.insuranceProvider?.length>15)? (data?.insuranceProvider |
                                        slice:0:15)+'..':(data?.insuranceProvider)}}<br>
                                        <span class="span-grey" *ngIf="data?.primartCPT != ''">
                                            CPT#{{data?.primartCPT}}</span>
                                    </td>
                                    <td><i title="In Coding" class="fa fa-code icon-color-blue mr-2"
                                            *ngIf="data?.inCoding > 0 || data?.returnedFor == 'In Coding'"></i>
                                        <i title="Returned Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                            *ngIf="data?.codingReturned > 0"></i>
                                        <i title="Returned" class="fa fa-caret-square-left icon-color-blue mr-2"
                                            *ngIf="data?.returned > 0"></i>
                                        <i title="Op Note" class="fa fa-envelope-open-text icon-color-blue mr-2"
                                            *ngIf="data?.opReport > 0 || data?.returnedFor == 'Op Note'"></i>
                                        <i title="Pathology" class="fa fa-clipboard-check icon-color-blue mr-2"
                                            *ngIf="data?.pathology > 0 || data?.returnedFor == 'Pathology'"></i>
                                        <i title="Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                            *ngIf="data?.coding > 0 || data?.returnedFor == 'Coding Query'"></i>
                                        <i title="Pricing" class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                            *ngIf="data?.pricing > 0 || data?.returnedFor == 'Pricing'"></i>
                                        <i title="High$$$" class="fa fa-dollar-sign icon-color-blue mr-2"
                                            *ngIf="data?.high$$$ > 0 || data?.returnedFor == 'NSN High$$$' || data?.returnedFor == 'Client High$$$'"></i>
                                        <i title="Block" class="fa fa-stop-circle icon-color-blue mr-2"
                                            *ngIf="data?.block > 0 || data?.returnedFor == 'Block Report'"></i>
                                        <i title="Variance" class="fa fa-exclamation-triangle icon-color-blue mr-2"
                                            *ngIf="data?.variance > 0 || data?.returnedFor == 'Variance'"></i>
                                        <i title="Other Misc" class="fa fa-info-circle icon-color-blue mr-2"
                                            *ngIf="data?.otherMisc > 0 || data?.returnedFor == 'Other/Misc'"></i>
                                    </td>
                                    <td>{{data?.timeCol}} <div *ngIf="data?.timeCol != null"> <span
                                                class="span-grey">Days</span></div>
                                    </td>
                                    <td data-toggle="tooltip" data-placement="top" title="{{(data?.lastNote)}}">
                                        {{(data?.lastNote?.length>17)? (data?.lastNote |
                                        slice:0:17):(data?.lastNote)}}<br>
                                        {{(data?.lastNote?.length>17)? (data?.lastNote | slice:17:34):""}}
                                        {{(data?.lastNote?.length>34)? (data?.lastNote | slice:34:34)+'...':""}}
                                    </td>
                                    <!-- <td>
                                            <a href="javascript:void(0)" (click)="showQAATFormModal(data)"
                                                class="icon-color-blue openMySidenav"><i title="Attachments"
                                                    class="fa fa-paperclip quickicn "></i></a>
                                            <a href="javascript:void(0)" (click)="showQAOPFormModal(data)"
                                                class="icon-color-darkgreen openMySidenav"><i title="Op Note"
                                                    class="fa fa-sticky-note quickicn"></i></a>
                                            <a href="javascript:void(0)" (click)="showQAICFormModal(data)"
                                                class="icon-color-yellow openMySidenav"><i title="Coding"
                                                    class="fa fa-file-code quickicn"></i></a>
                                            <i title="Tools" class="fas fa-tools quickicn icon-color-pink"></i>
                                            
                                        </td> -->
                                    <td>
                                        <!-- <a [routerLink]="['/d/patientDtls', (data?.facilityId || 0), (data?.mrn || 0)]"
                                                class="icon-color-darkgreen openMySidenav mr-2"><i
                                                    title="Patient View" class="fa fa-eye"></i></a> -->
                                        <a href="javascript:void(0)" class="icon-color-orange openMySidenav"><i
                                                title="Action" class="fas fa-comment-alt" (click)="showFormModal(data)"></i></a>
                                        <!--  -->
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>

                        <ng-template #elseBlock>There were no records matching your selection. Please try again.
                        </ng-template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->

<app-patient-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()">
</app-patient-form>
<!-- Sidenav-->