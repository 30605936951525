import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Tab } from './tab.model';

export interface TabsState extends EntityState<Tab>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'tabs' })
export class TabsStore extends EntityStore<TabsState> {

  constructor() {
    super();
  }

}
