import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { isNullOrUndefined } from 'util';

@Component({
  selector: 'app-eod-maintenance-form',
  templateUrl: './eod-maintenance-form.component.html',
  styleUrls: ['./eod-maintenance-form.component.css']
})
export class EodMaintenanceFormComponent implements OnInit {
  EODMaintenanceForm: FormGroup;
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  filetoUpload;
  formFlag: string = "Add";
  reasonString: string = "Reason";
  typeString: string = "Reason Type";
  istemplateVisible: boolean = false;
  _formData: any;
  submitted = false;

  @Input() set formData(value: any) {

    if (!isNullOrUndefined(value)) {
      this._formData = value;
      this.formFlag = "Edit";
      this.editForm();
    }
    else {
      this.formFlag = "Add";
      this.initForm();
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService) { }

  ngOnInit(): void { }

  get f() { return this.EODMaintenanceForm.controls; }

  onSubmit() {
    this.submitted = true;
    console.log(this.EODMaintenanceForm.invalid);
    if (this.EODMaintenanceForm.invalid) {
      return;
    }
    if (this.formFlag == "Add") {
      this.addRecord();
    }
    else if (this.formFlag == "Edit") {
      this.editRecord();
    }

  }

  addRecord() {

    const formdata = this.EODMaintenanceForm.getRawValue();

    let relativeUrl = "";
    let data = formdata;

    switch (data.mode) {
      case "Hold":
        relativeUrl = "EODMaintenance/SaveHoldReason";
        data = { "HoldType": data.type, "HoldReason": data.reason };
        break;
      case "Reject":
        relativeUrl = "EODMaintenance/SaveRejectReason";
        data = { "RejectType": data.type, "RejectReason": data.reason };
        break;
      case "Followup":
        relativeUrl = "EODMaintenance/SaveFolloUpReason";
        data = { "message": data.message, "Option": data.type, "template": data.reason };
        break;

    }

    this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.EODMaintenanceForm.reset();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })

  }

  editRecord() {

    const formdata = this.EODMaintenanceForm.getRawValue();

    let relativeUrl = "";
    let data = formdata;

    switch (data.mode) {
      case "Hold":
        relativeUrl = "EODMaintenance/SaveHoldReason";
        data = { "id": data.id, "HoldType": data.type, "HoldReason": data.reason };
        break;
      case "Reject":
        relativeUrl = "EODMaintenance/SaveRejectReason";
        data = { "id": data.id, "RejectType": data.type, "RejectReason": data.reason };
        break;
      case "Followup":
        relativeUrl = "EODMaintenance/SaveFolloUpReason";
        data = { "id": data.id, "message": data.message, "Option": data.type, "template": data.reason };
        break;

    }

    this.getApiService.post(relativeUrl, data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.EODMaintenanceForm.reset();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })

  }

  onCancel() {
    this.closeEmitter.emit();
  }

  modeSelected(event) {
    if (event.target.value == "Followup") {
      this.reasonString = "Template";
      this.typeString = "Option";
      this.istemplateVisible = true;
      this.EODMaintenanceForm.controls["message"].setValue("");
    }
    else {
      this.reasonString = "Reason";
      this.typeString = "Reason Type";
      this.istemplateVisible = false;
      this.EODMaintenanceForm.controls["message"].setValue("  ");
    }
  }

  initForm() {
    this.EODMaintenanceForm = this.fb.group({
      type: ["", [Validators.required]],
      reason: ["", Validators.required],
      message: ["", Validators.required],
      mode: ["", Validators.required],

    })
  }

  editForm() {

    if (this._formData.tableType == "Followup") {
      this.EODMaintenanceForm = this.fb.group({
        id: [this._formData.id, [Validators.required]],
        type: [this._formData.type, [Validators.required]],
        reason: [this._formData.reason, Validators.required],
        message: [this._formData.message, [Validators.required]],
        mode: [this._formData.tableType, Validators.required],
      })

      this.reasonString = "Template";
      this.typeString = "Option";
      this.istemplateVisible = true;
    }
    else if (this._formData.tableType == "Hold") {
      this.EODMaintenanceForm = this.fb.group({
        id: [this._formData.id, [Validators.required]],
        type: [this._formData.type, [Validators.required]],
        reason: [this._formData.reason, Validators.required],
        message: [this._formData.message,],
        mode: [this._formData.tableType, Validators.required],
      })

      this.reasonString = "Reason";
      this.typeString = "Reason Type";
      this.istemplateVisible = false;
    }
    else if (this._formData.tableType == "Reject") {
      this.EODMaintenanceForm = this.fb.group({
        id: [this._formData.id, [Validators.required]],
        type: [this._formData.type, [Validators.required]],
        reason: [this._formData.reason, Validators.required],
        template: [this._formData.message,],
        mode: [this._formData.tableType, Validators.required],
      })

      this.reasonString = "Reason";
      this.typeString = "Reason Type";
      this.istemplateVisible = false;
    }
  }

  // updateRecord() {

  //   const data = this.EODMaintenanceForm.getRawValue();
  //   console.log(data);
  //   // this.getApiService.post("FeeSchedule/UpdateFeeSchedule", data).subscribe((resp: any) => {
  //   //   if (resp.status == "Success") {
  //   //     this.EODMaintenanceForm.reset();
  //   //   }
  //   //   this.editEmitter.emit({
  //   //     'status': true,
  //   //     'data': resp
  //   //   })

  //   // }, (err) => {
  //   //   console.log(err);
  //   // })
  // }

  onClickClose() {
    this.closeEmitter.emit();
  }

  elmBlankVal(frmcntrl) {
    let val = this.EODMaintenanceForm.controls[frmcntrl].value;
    this.EODMaintenanceForm.controls[frmcntrl].setValue(val.trim());
  }

}
