export interface Facility {
  id:  string;
  name: string;
  deleted: boolean;
  region: string;
  abbr: string;
  shortName: string;
  createdDate: Date;
  updatedDate: Date;
  groupID: number;
  acrnym: string;
  groupname: string;
  totalcount: number;
}

export function createFacility(params: Partial<Facility>) {
  return {
    id: params.id,
    name: params.name,
    deleted: params.deleted,
    region: params.region,
    abbr: params.abbr,
    shortName: params.shortName,
    createdDate: params.createdDate,
    updatedDate: params.updatedDate,
    groupID: params.groupID,
    acrnym: params.acrnym,
    groupname: params.groupname,
    totalcount: params.totalcount
  } as Facility;
}
