import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ID, transaction } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { bdosid, highApproval, highApprovalInternal, na, variance } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { BulkActionRequestStatus, createBulkActionRequestStatus } from './bulk-action-request-status.model';
import { BulkActionRequestStatusStore } from './bulk-action-request-status.store';

@Injectable({ providedIn: 'root' })
export class BulkActionRequestStatusService {

  constructor(
    private bulkActionRequestStatusStore: BulkActionRequestStatusStore, 
    private apiService: ApiService,
    private patientQuery: PatientQuery,
    private toast: ToastrService
    ) {
  }


  get(): Subscription {  
    return this.patientQuery.selectActiveId().subscribe(bdosidList => {
      console.log('Active patient bdosid ', bdosidList);
      this.bulkActionRequestStatusStore.set([]);
      bdosidList.forEach(bdosid => {

      let data = { BdosID: bdosid };
      this.apiService.post("BillingNew/GetHighDollarTabData", data).subscribe(res => {
        if(res.status === "Success"){
          if(!res.dataHighTabStaus || !res.dataHighTabStaus[0]){
            this.addDefaultState(bdosid.toString(), na.id);
            return;
          }
          const isNSNHigh$$$ = res.dataHighTabStaus[0].wasHighApprovalInternal;
          if(isNSNHigh$$$ === undefined || isNSNHigh$$$ === null){
            this.addDefaultState(bdosid.toString(), na.id);
            return;
          }

          const caseRequestId = isNSNHigh$$$ === 0 ? highApproval.id : highApprovalInternal.id;
          const requestStatus = res.dataHighTabStaus[0].requestStatus;

          this.bulkActionRequestStatusStore.add(createBulkActionRequestStatus({
            id: bdosid.toString()+"_"+caseRequestId,
            caseRequestId: caseRequestId,
            bdosid: bdosid.toString(),
            status: requestStatus
          }));

        }else{
        this.toast.error("Error", res.message);
      }
    }, err => {
        this.toast.error("Unexpected Error!!", err?.message);
    });
    
      this.apiService.post("BillingNew/GetVarianceTabData", data).subscribe(res => {
        if(res.status === "Success"){
          const caseRequestId = variance.id;
          let requestStatus;
          if(!res ||  !res.dataVarianceTabStaus || !res.dataVarianceTabStaus[0] || !res.dataVarianceTabStaus[0].requestStatus || res.dataVarianceTabStaus[0].requestStatus === null){
            requestStatus = na.id;
          }else{
            requestStatus = res.dataVarianceTabStaus[0].requestStatus;
          }
         
          this.bulkActionRequestStatusStore.add(createBulkActionRequestStatus({
            id: bdosid.toString()+"_"+caseRequestId,
            caseRequestId: caseRequestId,
            bdosid: bdosid.toString(),
            status: requestStatus
          }));

        }else{
        this.toast.error("Error", res.message);
      }
      }, err => {
        this.toast.error("Unexpected Error!!", err?.message);
      })
    
    });
  
  });
  }
  @transaction()
  private addDefaultState(bdosid: string, caseRequestId: string){
    this.bulkActionRequestStatusStore.add(createBulkActionRequestStatus({
      id: bdosid + "_"+ caseRequestId,
      bdosid: bdosid,
      caseRequestId: caseRequestId,
      status: na.id
    }))
  }
  @transaction()
  add(bulkActionRequestStatus: BulkActionRequestStatus) {
    this.bulkActionRequestStatusStore.add(bulkActionRequestStatus);
  }
  @transaction()
  update(id, bulkActionRequestStatus: Partial<BulkActionRequestStatus>) {
    this.bulkActionRequestStatusStore.update(id, bulkActionRequestStatus);
  }
  @transaction()
  remove(id: ID) {
    this.bulkActionRequestStatusStore.remove(id);
  }

}
