export interface Cpt {
  cpt: string;
  cptDesc: string;
}

export function createCpt(params: Partial<Cpt>) {
  return {
    ...params
  } as Cpt;
}
