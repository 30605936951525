<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-700  active">
       <app-patient-info-header (closeEmitter)="onClickClose()"></app-patient-info-header>
       <div class="card-body">
        <app-show-attachment 
        [label] ="'History'"
        [callerId]="componentId"
        ></app-show-attachment>
       </div>
    </div>
</div>