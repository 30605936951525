<!-- Begin Page Content -->
<div class="container-fluid">
  <!-- Content Row -->
  <div class="row counters">
    <!-- Content Column -->
    <div class="col-12 mb-4">
      <!-- Project Card Example -->
      <div class="card shadow mb-4">
        <div
          class="
            card-header
            py-3
            d-flex
            flex-row
            align-items-center
            justify-content-between
          "
        >
          <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">
            Patient List
          </h1>
          <a
            *ngIf="privileges.addPatient"
            href="javascript:void(0);"
            (click)="showFormModal()"
            
            id="openMySidenav"
            class="btn btn-primary btn-patient-box openMySidenav mr-4"
            role="button"
          >
            <i class="fas fa-plus fa-sm font-icn-color"></i>
            Add Patient
          </a>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-sm-12 col-md-6">
              <div class="myTables_length">
                <label
                  >Show
                  <select
                    name="myTable_length"
                    aria-controls="dataTable"
                    (change)="onEntryChange($event)"
                    class="
                      custom-select custom-select-sm
                      form-control form-control-sm
                    "
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  entries</label
                >
              </div>
            </div>
            <div class="col-sm-12 col-md-6">
              <div class="myTables_filter">
                <label
                  >Search:<input
                    type="search"
                    [(ngModel)]="searchValue"
                    (keyup.enter)="search()"
                    value=""
                    onfocus="this.value=''"
                    class="form-control form-control-sm"
                    placeholder="Patient/Facility/MRN"
                /></label>
              </div>
              <!-- (keyup)="sortTable($event)" -->
            </div>
          </div>
          <div class="table-responsive data-list">
            <table class="table" id="dataTable" width="100%" cellspacing="0">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Patient</th>
                  <th>Facility</th>
                  <!-- <th>DOB</th> -->
                  <th>MRN</th>
                  <th>Case</th>
                  <th>Provider</th>
                  <th>DOS</th>
                  <th>Insurance</th>
                  <th>Group #</th>
                  <!-- <th>Insurance Id</th> -->
                  <!-- <th>PT Code</th>
                                    <th>Comments</th> -->
                  <th *ngIf="privileges.patientActionView || privileges.patientActionManage || privileges.patientActionEdit ">Action</th>
                </tr>
              </thead>
              <tfoot>
                <tr>
                  <th>#</th>
                  <th>Patient</th>
                  <th>Facility</th>
                  <th>MRN</th>
                  <th>Case</th>
                  <th>Provider</th>
                  <th>DOS</th>
                  <th>Insurance</th>
                  <th>Group #</th>
                  <!-- <th>Insurance Id</th> -->
                  <!-- <th>PT Code</th>
                                    <th>Comments</th> -->
                  <th *ngIf="privileges.patientActionView || privileges.patientActionManage || privileges.patientActionEdit ">Action</th>
                </tr>
              </tfoot>
              <tbody>
                <tr
                  *ngFor="
                    let data of PatientsArry
                      | paginate
                        : {
                            itemsPerPage: pageValue,
                            currentPage: p,
                            totalItems: totalRows
                          };
                    let i = index
                  "
                >
                  <td>
                    <!-- {{(i+1) }} -->
                    {{ i + 1 + (p - 1) * pageValue }}
                  </td>
                  <td>{{ data.patientName }}</td>
                  <td>
                    <a
                      href="javascript:void(0)"
                      (click)="showDetailModal(data)"
                    >
                      {{ data.facilityName }}
                    </a>
                  </td>
                  <td>{{ data.mrn }}</td>
                  <td>{{ data.caseId }}</td>
                  <td>{{ data.physician }}</td>
                  <td>{{ data.dateOfSurgery | date: "MM/dd/yyyy" }}</td>
                  <td>{{ data.insuranceProvider }}</td>
                  <td>{{ data.groupNumber }}</td>
                  <!-- <td>{{data.insuranceID}} </td> -->
                  <!-- <td>{{data.cptCode}} <i class="fa fa-plus"></i> </td>
                                    <td> </td> -->
                  <!-- <td>New Patient</td> -->

                  <td *ngIf="privileges.patientActionView || privileges.patientActionManage || privileges.patientActionEdit ">
                    <a *ngIf="privileges.patientActionView"
                      href="javascript:void(0)"
                      [routerLink]="['/d/patientDtls', (data?.facilityID || 0), (data?.mrn || 0)]"
                      class="btn btn-primary icon-btn openMySidenav" 
                      ><i title="Patient View" class="fa fa-eye"></i
                    ></a>
                    <!-- <a href="javascript:void(0)" (click)="showFormPVModal()"
                                            class="btn btn-primary icon-btn openMySidenav1"><i class="fas fa-clipboard"
                                                aria-hidden="true"></i></a> -->
                    <a *ngIf="privileges.patientActionManage"
                      href="javascript:void(0)"
                      (click)="showPatientFormModal(data)"
                      class="btn btn-primary icon-btn openMySidenav1"
                      ><i class="fas fa-clipboard" title="Set Billed" aria-hidden="true"></i
                    ></a>
                    <a 
                      href="javascript:void(0)" *ngIf="privileges.patientActionEdit"
                      (click)="editPatients(data)"
                      class="btn btn-primary icon-btn openMySidenav"
                      ><i class="fa fa-edit" title="Edit" aria-hidden="true"></i
                    ></a>
                    <!-- <a href="javascript:void(0)" class="btn btn-danger icon-btn"><i
                                                class="fa fa-trash-alt" title="Delete"></i></a> -->
                    <!-- <a href="javascript:void(0)" class="icon-color-orange openMySidenav"><i
                                                    title="Action" class="fas fa-comment-alt" (click)="showPatientFormModal(data)"></i></a> -->

                   <a  href="javascript:void(0)"
                    *ngIf=" (data.currentStatus == 'EoD' ) &&  (data.claimType == 'paper' || data.claimType == 'Paper')"
                     class="icon-color-orange openMySidenav"><i
                    class="fas fa-undo" title="Return Mail" style="padding-right: 6px;"
                      (click)="showReturnMail(data)"></i>  
                        </a>
                          <span *ngIf="(data.currentStatus != 'EoD')"  >
                        <a *ngIf="privileges.patientActionView || privileges.patientActionManage || privileges.patientActionEdit " href="javascript:void(0)"
                         class="icon-color-orange openMySidenav">
                         
                         <i class="fas fa-th-large" title="CPT Codes" style="padding-right: 6px;"
                          (click)="showCptCodes(data);editPatients(data)"></i>  
                            </a>
                          </span>
                  </td>
                </tr>
              </tbody>
              
            
            </table>
            <h5 class="text-center" *ngIf="!PatientsArry?.length">*Please search with Patient Name,Facility or MRN*</h5>
            <pagination-controls
              class="float-right"
              (pageChange)="pageChange($event); p = $event"
            >
            </pagination-controls> 
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- /.container-fluid -->
<app-unset-billed-action [formData]="formData"  *ngIf="shouldShowReturnMailModal" (closeEmitter)="onCloseFormModal()">
</app-unset-billed-action>
<!-- Sidenav-->
<!-- <app-patient-action [formData]="formData" *ngIf=shouldShowPatientFormModal (closeEmitter)="onClosePatientFormModal()">
</app-patient-action> -->

<app-case-request
  [formData]="formData"
  *ngIf="shouldShowPatientFormModal"
  (closeEmitter)="onClosePatientFormModal()"
>
</app-case-request>

<app-facility-details
  [facility_id]="facility_id"
  *ngIf="shouldShowDetailModal"
  (closeEmitter)="onCloseFormModal()"
>
</app-facility-details>
<app-patients-form
  [formData]="formData"
  *ngIf="shouldShowFormModal"
  (closeEmitter)="onCloseFormModal()"
  (editEmitter)="onEditRecord($event)"
  (addedEmitter)="onAddRecord($event)"
>
</app-patients-form>
<!-- Sidenav-->

<!-- Sidenav-->
<app-patient-view
  *ngIf="shouldShowPVFormModal"
  (closeEmitter)="onCloseFormPVModal()"
>
</app-patient-view>
<!-- Sidenav-->

<!-- Sidenav-->
<app-cpt-codes-form *ngIf="shouldShowCptCodeModal" [formData]="formData"  (editEmitter)="onEditRecord($event)"
(addedEmitter)="onAddRecord($event)"  (closeEmitter)="onCloseFormModal()"></app-cpt-codes-form>
<!-- Sidenav-->