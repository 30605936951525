export interface NsnGrid {
  id: number | string;
  data: any;
}

export function createNsnGrid(id: string | number, data) {
  return {
    id: id,
    data: data
  } as NsnGrid;
}
