import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';
import { CenterRoutingModule } from './center-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PatientListComponent } from './patient-list/patient-list.component';
import { PatientRequestComponent } from './patient-request/patient-request.component';
import { ChartsModule } from 'ng2-charts';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BillingDashboardModule } from '../billing-dashboard/billing-dashboard.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [DashboardComponent, PatientListComponent, PatientRequestComponent],
  imports: [
    CommonModule,
    CenterRoutingModule,
    BsDatepickerModule,
    DatepickerModule,
    ChartsModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgbModule,
    BillingDashboardModule,
    SharedModule,
    NgxSpinnerModule
  ]
})
export class CenterModule { }
