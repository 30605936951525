import { Component, OnInit, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { GroupsService } from 'src/app/shared/state/groups/groups.service';
import { Subscription } from 'rxjs';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { AccessService } from 'src/app/shared/services/access.service';

@Component({
  selector: 'app-billing-rules',
  templateUrl: './billing-rules.component.html',
  styleUrls: ['./billing-rules.component.css']
})
export class BillingRulesComponent implements OnInit, AfterViewInit, OnDestroy {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  shouldShowFormModal: boolean = false;
  formData: any = null;
  billingRulesList: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  shouldShowDetailModal:boolean=false;
  facility_id:any;
  privileges:any;
  subscrption: Subscription= new Subscription();

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private accessServices : AccessService,
    private groupService: GroupsService) { }

  ngOnInit(): void {
    this.privileges= this. accessServices.navbar_access
    this.commonUtil.setTitle("Billing Rules");
    this.getBillingRulesList();
   
  }
  ngAfterViewInit() {
    const groupSub = this.groupService.get().subscribe();
    this.subscrption.add(groupSub);
  }

  ngOnDestroy(){
    if(this.subscrption){
      this.subscrption.unsubscribe();
    }
  }
  showFormModal() {
    this.formData = null;
    console.log(this.shouldShowFormModal);
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }
  showDetailModal(data){
    console.log(data,"data");
   // this.formData = data;
    this.facility_id = data.facilityId;
    console.log("facility", this.facility_id)
    //console.log("this.shouldShowDetailModal : ",this.shouldShowDetailModal,this.facility_id);
    if (this.shouldShowDetailModal) {
      this.shouldShowDetailModal = false;
    }
    else {
      this.shouldShowDetailModal = true;
    }
  }

  onCloseFormModal() {
    this.shouldShowFormModal = false;
    this.shouldShowDetailModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getBillingRulesList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getBillingRulesList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  editbillingRules(data) {
    this.formData = data;
   // console.log(this.formData,"formData");
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  getBillingRulesList() {
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };

    this.getApiService.post("Rules/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {

        this.billingRulesList = res.dataGridList;
        this.totalRows = res.dataGridList[0].totalcount;
        // this.pageValue = 10;
        console.log('billingRulesList -> ', this.billingRulesList);
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.billingRulesList = [];
    this.getBillingRulesList();
  }

  delbillingRules(ruleName) {
    let data = { "RuleName": ruleName };
    this.getApiService.post("Rules/DeleteRules", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getBillingRulesList();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  
  pageChange(event) {
    this.pagenumber = event;
    this.getBillingRulesList();
  }




}
