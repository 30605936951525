import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CaseQueueFacilityReportComponent } from './case-queue-facility-report/case-queue-facility-report.component';
import { FacilityReportComponent } from './facility-report/facility-report.component';
import { ManagementReportComponent } from './management-report/management-report.component';
import { UserProductivityReportComponent } from './user-productivity-report/user-productivity-report.component';
const routes: Routes = [
  {
    path: '',
    children:[
      {
        path: '',
        redirectTo: '/reports',
        pathMatch: 'full'
      },
      {
        path: 'user-productivity-report',
        component: UserProductivityReportComponent,
      },
      {
        path: 'case-queue-facility-report',
        component: CaseQueueFacilityReportComponent
      },
      {
        path: 'management-report',
        component: ManagementReportComponent
      },
      {
        path: 'facility-report',
        component: FacilityReportComponent
      }
    ]
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
