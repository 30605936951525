import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { MultiDataSet, Label, ThemeService } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { saveAs } from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
@Component({
  selector: 'app-patient-detail',
  templateUrl: './patient-detail.component.html',
  styleUrls: ['./patient-detail.component.css']
})
export class PatientDetailComponent implements OnInit {


  facilityID: number;
  bdosId: number;
  mrn: number;

  codedCptHighlightIds: any = [];
  billedCptHighlightIds: any = [];


  noofdays: number = 0;
  totalBilling: any = 0;
  daysinbilling: any = 0;
  allNotes = true;
  insStat = 'Primary';

  dataPatientInfo: any = [];
  dataCharges: any = [];
  dataOutstanding: any = [];
  dataPInsuranceInfo: any = [];
  dataSInsuranceInfo: any = [];
  dataScheduledCpt: any = [];
  dataBilledCPT: any = [];
  dataCaseFlow: any = [];
  dataHistoryInfo: any = [];
  dataFilesInfo: any = [];
  dataDosInfo: any = [];
  uniqueRfiList: string;
  caseFlowStatus: string;
  ScheduledCptCodes: any = [];
  under48: any = [];
  eod : any = [];
  under48Hrs: any;
  _formData: any;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          let sum = 0;
          let dataArr = ctx.chart.data.datasets[0].data;
          for (let d of dataArr) {
            sum += Number(d);
          }
          let calPercentage = (value * 100 / sum);
          if (isNaN(calPercentage)) {
            return "";
          }
          else {
            let percentage = calPercentage.toFixed(2) + "%";
            return percentage;
          }
        },
        // anchor: 'end',
        // offset: 10,
        // rotation: 45,
        color: '#000',
      },
    },
  };
  public pieChartLabels: Label[] = [];
  public pieChartData: number[] = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [pluginDataLabels];
  public pieCharttColors = [
    {
      backgroundColor: ['#fec001', '#4474c5', '#5b9ad5', '#72ab49', '#9f460d', '#a4a3aa', "#4b77a9",
        "#5f255f", '#fec001', '#4474c5', '#5b9ad5', '#72ab49', '#9f460d', '#a4a3aa', "#4b77a9",],
    },
  ];
  patientID: number;


  @Input() set formData(value: any) {

    if (value != null && value != undefined) {
      this._formData = value;
      console.log("this._formData");
      // this.getCptInfo();



    }
  }

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    // this.bdosid = this.route.snapshot.params.bdosid;
    this.mrn = this.route.snapshot.params.mrn;
    this.facilityID = this.route.snapshot.params.id;
    this.getPatientDOSdata();
    // this.getCptInfo();
  }





  getPatientDOSdata() {
    let data = {
      "MRN": this.mrn,
      "ClientFacilityId": this.facilityID
    }

    this.getApiService.post("PatientInfoView/PatientDOSData", data).subscribe(res => {
      if (res.status == "Success") {
        this.dataDosInfo = res.dataDosInfo;
        this.bdosId = res.dataDosInfo[0].bdosid;
        this.getPatientInfo(0);
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  getPatientInfo(event) {
    this.spinner.show();
    let data = {};

    if (event == 0) {
      data = {
        "MRN": this.mrn,
        "ClientFacilityId": this.facilityID,
        "BdosID": this.bdosId
      }
    }
    else {
      data = {
        "BdosID": event.target.value
      }
    }

    this.getApiService.post("PatientInfoView/PatientInfoViewData", data).subscribe(res => {
      if (res.status == "Success") {
        console.log(res.dataPatientInfo[0].patientID, res.dataPatientInfo[0].bdosid, "p,b")
        this.getCptInfo(res.dataPatientInfo[0].patientID, res.dataPatientInfo[0].bdosid);
        this.spinner.hide();
        this.pieChartData = [];
        this.pieChartLabels = [];
        this.totalBilling = "0";
        this.patientID = res.PatientID;
        // this.bdosId= res.BDOSID;
        this.dataPatientInfo = res.dataPatientInfo[0];
        this.dataCharges = res.dataCharges;
        this.dataOutstanding = res.dataOutstanding;
        this.dataPInsuranceInfo = res.dataPInsuranceInfo;
        this.dataSInsuranceInfo = res.dataSInsuranceInfo;
        // console.log( this.dataSInsuranceInfo,"indetailed")
        this.dataScheduledCpt = res.dataScheduledCpt;
        this.dataBilledCPT = res.dataBilledCPT;
        this.dataCaseFlow = res.dataCaseFlow;
        this.dataHistoryInfo = res.dataHistoryInfo;
        console.log(res.dataHistoryInfo);
        this.dataFilesInfo = res.newFilesList;
        this.under48 = res.under48[0];
        this.eod = res.eod;
        //this.under48Hrs = this.under48?.under48.sp
        /*
        for(let i = 0 ; i < this.dataHistoryInfo.length ; i++){
          console.log(this.dataHistoryInfo[i].multipleFiles);
          if(this.dataHistoryInfo[i].auditEventDescription == 'EoD'){
            this.dataHistoryInfo[i].multipleFiles = res.dataHistoryInfo[i].multipleFiles.split(',');
          }
        }
       */
        this.uniqueRfiList = res.uniqueRfiList;
        this.caseFlowStatus = res.caseFlowStatus;
        let piChartData = [];
        let piChartLabel = [];



        // if (res.dataCaseFlow[0].opReport != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].opReport));
        //   piChartLabel.push('Op Report');
        // }
        // if (res.dataCaseFlow[0].pathology != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].pathology));
        //   piChartLabel.push('Path Report');
        // }
        // if (res.dataCaseFlow[0].pricing != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].pricing));
        //   piChartLabel.push('Pricing');
        // }
        // if (res.dataCaseFlow[0].block != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].block));
        //   piChartLabel.push('Block Report');
        // }
        // if (res.dataCaseFlow[0].overdue != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].overdue));
        //   piChartLabel.push('< 48 Hours');
        // }
        // if (res.dataCaseFlow[0].codingRFI != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].codingRFI));
        //   piChartLabel.push('CodingRFI');
        // }

        // if (res.dataCaseFlow[0].otherMisc != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].otherMisc));
        //   piChartLabel.push('Other/Misc');
        // }

        // if (res.dataCaseFlow[0].clientHigh$$$ != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].clientHigh$$$));
        //   piChartLabel.push('Client High$$$');
        // }

        // if (res.dataCaseFlow[0].variance != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].variance));
        //   piChartLabel.push('Variance');
        // }
        // if (res.dataCaseFlow[0].inCoding != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].inCoding));
        //   piChartLabel.push('In Coding');
        // }
        // if (res.dataCaseFlow[0].nsnHigh$$$ != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].nsnHigh$$$));
        //   piChartLabel.push('NSN High$$$');
        // }
        // if (res.dataCaseFlow[0].authReview != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].authReview));
        //   piChartLabel.push('Auth Review');
        // }
        // if (res.dataCaseFlow[0].codingExpert != 0) {
        //   piChartData.push(this.convertTime(res.dataCaseFlow[0].codingExpert));
        //   piChartLabel.push('Coding Expert');
        // }
        this.pieChartData = piChartData;
        this.pieChartLabels = piChartLabel;


        let timeinminutes: any = (this.convertTime(res.dataCaseFlow[0]?.opReport) + this.convertTime(res.dataCaseFlow[0]?.pathology) + this.convertTime(res.dataCaseFlow[0]?.pricing) + this.convertTime(res.dataCaseFlow[0]?.block) + this.convertTime(res.dataCaseFlow[0]?.overdue) +
          this.convertTime(res.dataCaseFlow[0]?.codingRFI) + this.convertTime(res.dataCaseFlow[0]?.otherMisc) + this.convertTime(res.dataCaseFlow[0]?.clientHigh$$$) + this.convertTime(res.dataCaseFlow[0]?.variance) +
          this.convertTime(res.dataCaseFlow[0]?.inCoding) + this.convertTime(res.dataCaseFlow[0]?.nsnHigh$$$) + this.convertTime(res.dataCaseFlow[0]?.authReview) + this.convertTime(res.dataCaseFlow[0]?.codingExpert) + this.convertTime(res.under48[0]?.under48) + this.convertTime(res.dataCaseFlow[0]?.codingQuery || "0.0")).toFixed(2);

        if (timeinminutes == NaN) {
          timeinminutes = 0;
        }
        let th = Math.floor(Number(timeinminutes / 60))
        let tm = Math.ceil(Number(timeinminutes % 60))


        this.totalBilling = `${th}h:${tm}m`

        if (isNaN(Number(th)) || isNaN(Number(tm))) {
          this.totalBilling = "0";
        }
        let td = Math.floor(Number(th / 24))

        let tdh = (th % 24)

        let thd = Math.ceil(Number(tdh))

        this.daysinbilling = `${td}d:${thd}h:${tm}m`
        if (isNaN(Number(td)) || isNaN(Number(thd)) || isNaN(Number(tm))) {
          this.daysinbilling = "0";
        }

        // if (isNaN(Number(this.totalBilling))) {
        //   this.totalBilling = "0";
        // }

      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  getCptInfo(patientId, bdosId) {
    //console.log("Iam displaying");
    //console.log(this.patientID,this.bdosId,"here")
    let relativeUrl = "CreatePatient/GetPatientCptInfo/" + patientId + "/" + bdosId;
    //console.log("Iam displaying");
    this.getApiService.get(relativeUrl).subscribe((resp: any) => {

      this.ScheduledCptCodes = resp;

      let ScheduledCptCodesList = [];
      let CodedCptCodesList = [];
      let BilledCptCodesList = [];

      this.ScheduledCptCodes.forEach((item, index) => {
        console.log(item);
        if(item.cptType == 0) {
          CodedCptCodesList.push(item);
        } else if (item.cptType == 1) {
          BilledCptCodesList.push(item);
        } else if (item.cptType == 2) {
          ScheduledCptCodesList.push(item);
        }
      });

      let CountArray = [];
      console.log(ScheduledCptCodesList,"cpt ");
      CountArray.push(CodedCptCodesList.length);
      CountArray.push(BilledCptCodesList.length);
      CountArray.push(ScheduledCptCodesList.length);
      

      let max = 0;
      CountArray.map(e => {
        if (max < e) {
          max = e
        }
      })
      console.log(max,"max",CountArray)

      
      for(let i = 0; i < ScheduledCptCodesList.length ; i++){
        let scheduleCode = 0;
        let codedCode = 0;
        let billedCode = 0;

        if(ScheduledCptCodesList.length > i){
          scheduleCode = Number(ScheduledCptCodesList[i].cptCode);
        }

        if(BilledCptCodesList.length > i){
          billedCode = Number(BilledCptCodesList[i].cptCode);
        }

        if(CodedCptCodesList.length > i){
          codedCode = Number(CodedCptCodesList[i].cptCode);
        }

       
        if(scheduleCode == billedCode){
          
          this.billedCptHighlightIds.push(BilledCptCodesList[i].id);
        }
     
        console.log('comparing');
        console.log(codedCode);
        console.log(billedCode);
      
        if(scheduleCode == codedCode){
         
          this.codedCptHighlightIds.push(CodedCptCodesList[i].id);
       
      }
      
      }

      console.log(this.billedCptHighlightIds);
      console.log(this.codedCptHighlightIds);
      console.log(resp);


    }, (err) => {
      console.log(err);
    })
  }

  getFile(fileId, fileName) {
    this.spinner.show();
    let data = { "FileId": fileId };
    this.getApiService.post4File("BillingNew/DownloadFile", data).subscribe(res => {
      saveAs(res, fileName);
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  convertTime(datVal) {

    if (!datVal) {
      return 0;
    }
    let datArry = datVal?.split(":");
    let timeinminutes = (Number(datArry[0]) * 60 + Number(datArry[1]))
    console.log(timeinminutes, "timeinminutes")
    // let timeinminutes = (Number(datArry[0] + '.' + datArry[1])) * 60;
    return timeinminutes
  }
}