<div class="container">
  <div class="row justify-content-md-start" style="margin-top: 13px !important;">
        <div class="col col-lg-3" *ngIf = "!tableMenuconfig.simpleDropdown[1].hidden">
          <select  class="table-menu-config-simple-dropdown"  
          (ngModelChange)="simpleDropdownChanged()" [compareWith]="compare" [(ngModel)] = "selectedDropdownOne">
              <option *ngFor="let data of (simpleDropdownListOne$ | async).nsnSimpleDropDownDataList" [ngValue]="data">{{data.displayValue}}</option>
          </select>
        </div>
        <div class="col col-lg-3" *ngIf ="!tableMenuconfig.actionButton.hidden">
          <button href="javascript:void(0);" [disabled] = "(actionEnabled$ | async) === false"
          class="btn btn-primary btn-patient-box mr-4" (click) = "notify()">
          <i class="fas fa-plus fa-sm font-icn-color"></i>
          {{ tableMenuconfig.actionButton.label}}
          </button>
        </div>
        <div class="col col-lg-3" *ngIf = "!tableMenuconfig.searchBar.hidden">
          <input id="tableConfigSearchBar"  type="text"  [ngClass] = "{ 
              'table-menu-config-search-rf ' : tableMenuconfig.searchBar.position === RF 
             }"
                 placeholder="{{tableMenuconfig.searchBar.placeholder}}" (keyup.enter)="search()" [(ngModel)]="searchValue">
        </div>
  </div>
</div>