import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router, NavigationStart } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { DashDetailHelper } from '../billing-dashboard/dash-detail/helpers/dash-detail.helper';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { createFacility } from 'src/app/shared/state/facilities/facility.model';
import { createPatient } from 'src/app/shared/state/patient/patient.model';
import { AccessService } from 'src/app/shared/services/access.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
//import { PatientActionComponent } from '../billing-dashboard/patient-action/patient-action.component'
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { CommandType } from '../billing-dashboard/dash-detail/dash-detail.types';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit {
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();
  @Output() showCaseRequestFormEmitter = new EventEmitter<any>();
  shouldShowFormModal: boolean = false;
  shouldShowDetailModal:boolean =false;
  shouldShowPVFormModal: boolean = false;
  shouldShowReturnMailModal: boolean = false;
  shouldShowCptCodeModal: boolean = false;
  caseBilledCommandType = CommandType.CaseBilled.toLowerCase();
  commadnSelection$: Observable<string>;
  formData: any = null;
  PatientsArry: any[];
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  facility_id:any;
  shouldShowPatientFormModal:boolean =false;
  privileges:any;
  patientData:any;

  dashDetailHelper: DashDetailHelper = new DashDetailHelper();
  // selectionService: any;
  selectedCommand: string;

  constructor(private getApiService: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private patientService: PatientService,
    private facilityService: FacilityService,
    private route: ActivatedRoute,
    public accessService:AccessService,
    private spinner:NgxSpinnerService,
    private activatedRoute:ActivatedRoute,
    private selectionService :SelectionService
    ) {

      
      this.activatedRoute.queryParamMap.subscribe((data:any)=>{
        if(data?.params?.search){
          this.searchValue = data.params.search;
          this.pagenumber = 0;
          this.p = 0;
          this.getPatientsList();
        }
        
      })
     }

  ngOnInit(): void {
    // console.log(this.accessService.navbar_access,"in patients")
    this.privileges=this.accessService.navbar_access;
    // console.log(this.privileges,"in patients")
    this.commonUtil.setTitle("Patients");
    if (!localStorage.getItem('foo')) { 
      localStorage.setItem('foo', 'no reload') 
      
      setTimeout("refreshPage();", 1000); {
        location.reload();
        };

    } else {
      localStorage.removeItem('foo') 
    }
    
  }

  showReturnMail(data) {
    console.log('return mail data in patient', data);
    this.selectionService.changeSelection(CommandType.ReturnedMail);
    this.selectedCommand = CommandType.ReturnedMail;
    this.formData = data;
    if (this.shouldShowReturnMailModal) {
      this.shouldShowReturnMailModal = false;
    }
    else {
      this.shouldShowReturnMailModal = true;
    }
  }

  showCptCodes(data){
    this.patientData = data;
    if (this.shouldShowCptCodeModal) {
      this.shouldShowCptCodeModal = false;
    }
    else {
      this.shouldShowCptCodeModal = true;
    }
  }


  showFormModal() {
    this.formData = null;

    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
    }
    else {
      this.shouldShowFormModal = true;
    }
  }
  showPatientFormModal(data) {
    const patient = createPatient({
      mrn: data?.mrn,
      totalcount: data?.totalcount,
      facilityId: data?.facilityID,
      name: data?.patientName,
      dob: data?.dob,
      dateOfSurgery: data?.dateOfSurgery,
      insuranceProvider: data?.insuranceProvider,
      physician: data?.physician,
      bdosid: data.dosId
    })
    this.patientService.setAndActivate([patient]);
    const facility = createFacility({ id: data.facilityID, name: data.facilityName });
    this.facilityService.set([facility]);
    this.facilityService.setActive([facility.id]);
    this.formData = data;
    console.log(this.formData,'data',this.shouldShowPatientFormModal);
    if (this.shouldShowPatientFormModal) {
      this.shouldShowPatientFormModal = false;
      this.patientService.reset();
      this.facilityService.reset();
    }
    else {
      this.shouldShowPatientFormModal = true;
    }
  }
  onClosePatientFormModal() {
    this.shouldShowPatientFormModal = false;
    this.patientService.reset();
    this.facilityService.reset();
  }
  
  showDetailModal(data){
    console.log(data);
   this.formData = data;
    this.facility_id = data.facilityID;
    console.log(this.formData)
    //console.log("this.shouldShowDetailModal : ",this.shouldShowDetailModal,this.facility_id);
    if (this.shouldShowDetailModal) {
      this.shouldShowDetailModal = false;
    }
    else {
      this.shouldShowDetailModal = true;
    }
  }

  

  onCloseFormModal() {
    this.shouldShowCptCodeModal = false;
    this.shouldShowFormModal = false;
    this.shouldShowDetailModal=false;
    this.shouldShowReturnMailModal = false;
  }

  showFormPVModal() {
    this.formData = null;

    if (this.shouldShowPVFormModal) {
      this.shouldShowPVFormModal = false;
    }
    else {
      this.shouldShowPVFormModal = true;
    }
  }

  onCloseFormPVModal() {
    this.shouldShowPVFormModal = false;
  }

  onAddRecord(event) {
    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getPatientsList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }


  onEditRecord(event) {

    if (event.data.status == "Success") {
      this.shouldShowFormModal = false;
      this.toastr.success(event.data.message, 'Success!');
      this.getPatientsList();
    }
    else if (event.data.status == "Error") {
      this.toastr.error(event.data.message, 'Error!');
      this.shouldShowFormModal = true;
    }
  }

  getPatientsList() {
    
    if (this.searchValue != '' && this.pagenumber == 0) {
      this.pagenumber = 1;
      this.p = 1;
    }
    
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "PatientName": this.searchValue.trim() };
    
    this.spinner.show();
    this.getApiService.post("CreatePatient/BindGridData", data).subscribe(res => {
      
      
      if (res.status == "Success") {
        this.spinner.hide();
        this.PatientsArry = res.data;
        console.log(this.PatientsArry,"patients array")
        this.totalRows = res.data[0]?.totalcount;
      }
      else if (res.status == "Error") {
        this.spinner.hide();
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

  editPatients(data) {
    this.formData = data;
    if (this.shouldShowFormModal) {
      this.shouldShowFormModal = false;
      this.getPatientsList();
    }
    else {
      this.shouldShowFormModal = true;
    }
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 0;
    this.PatientsArry = [];
    this.getPatientsList();
  }

  showCaseRequestFormModal(data){
    this.showCaseRequestFormEmitter.emit(data);
  }



  pageChange(event) {
    this.pagenumber = event;
    this.getPatientsList();
  }

  search() {
    this.pagenumber = 0;
    this.p = 0;
    this.getPatientsList();
  }

}