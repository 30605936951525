<!-- Begin Page Content -->
<div class="container-fluid">
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">User List</h1>
                    <a *ngIf="privileges.manageUsersAdd" href="javascript:void(0);" (click)='showFormModal()' id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <!-- <i class="fas fa-plus fa-sm font-icn-color"></i> -->
                        Manage User
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="search()"
                                        placeholder="User Name/Email Id"></label></div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">{{col}}</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th *ngFor="let col of columns">{{col}}</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr
                                    *ngFor='let data of UserMasterArry | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
                                    <td>
                                        <!-- {{i+1}} -->
                                        {{(i+1) + ((p-1)*pageValue)}} 
                                    </td>
                                    <!-- <td>{{data.userName}}</td> -->
                                    <td>{{data.fullName}}</td>
                                    <td>{{data.email}}</td>
                                    <td>{{data.assigneeEmail}}</td>
                                    <td>{{data.reportingMangerEmail}}</td>
                                    <td>{{data.role}}</td>
                                    <td>
                                        <label class="custom-switch">
                                            <input type="checkbox" (click)="delUserMaster(data)"
                                                [checked]="data.isEnabled === 'Active'">
                                            <span class="slider"></span>
                                        </label>
                                        <!-- data.isEnabled -->
                                    </td>
                                    <!-- <td>{{data.created_date}}</td> -->
                                    <td>
                                        <a *ngIf="data.isEnabled === 'Inactive' &&  privileges.manageUsersStatus"   href="javascript:void(0)" (click)="migrateUser(data)"
                                            class="btn btn-primary icon-btn openMySidenav" >
                                                <i class="fas fa-share-square" title="Share Profile"></i>
                                        </a>
                                        <a *ngIf="privileges.manageUsersEdit" href="javascript:void(0)" (click)="editUserMaster(data)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                        </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="col-lg-6 mb-4 d-none"> -->

    </div>
</div>
<!-- /.container-fluid -->

<!-- Sidenav-->
<app-manage-users-form [formData]="formData" *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"
    (editEmitter)="onEditRecord($event)" (addedEmitter)="onAddRecord($event)">
</app-manage-users-form>
<!-- Sidenav-->
<app-copy-user-form *ngIf = "(formSelection$ | async) === 'copy' && !shouldShowFormModal"></app-copy-user-form>