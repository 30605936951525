export class NSNAccordian{
  name: string;
  value: string; // key
  count: number;
  order: number;
  facilityId?: string | number;
  searchValue?: string;
}

export function createNSNAccordian(params: Partial<NSNAccordian>) {
  return {
    ...params
  } as NSNAccordian;
}