<div class="container-fluid">
    <div class="row counters">
        <div class="col-12 mb-4">
            <div class="card shadow  mb-4">
                <div class="card-header">
                    <div class="flex-container-action-xs flex-container-action-sm flex-container-action-m flex-container-action-l flex-container-action-xl">
                        <div class="item-action-blank">

                        </div>
                        <div class="item-action-batch-review-case-btn">
                         
                        </div>
                        <div class="item-action-search">
                            <input id="searchPatient" type="text" name="searchPatient" [(ngModel)]="searchValue" (keyup.enter)="search()" 
                                placeholder="Search">
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <app-nsn-grid *ngIf="displayNsnGrid"
                    [callerId]="componentName"
                    [nsnGridConfigEvent]="nsnGridConfigSubject.asObservable()"
                    [coloumns]="coloumns"
                    [isDataSourceProvided]="false"
                    [paginationControlId]="componentName.id"
                    (showQAOPFormEmitter)="showQAOPFormModal($event)"
                    (showQAICFormEmitter)="showQAICFormModal($event)"
                    (showQAATFormEmitter)="showQAATFormModal($event)"
                    (showToolsFormEmitter)="showToolsFormModal($event)"
                    (showCaseRequestFormEmitter)= "showCaseRequestFormModal($event)"
                    (onPageChange)="pageChange($event)"
                    ></app-nsn-grid>

                    <app-custom-accordian
                    *ngIf="displayAccordian"
                    [callerId]="componentName"
                    [config]= "customAccordianConfig"
                    [customAccordianConfigEvent]="customAccordianConfigSubject.asObservable()"
                    [coloumns]="coloumns"
                    (showQAOPFormEmitter)="showQAOPFormModal($event)"
                    (showQAICFormEmitter)="showQAICFormModal($event)"
                    (showQAATFormEmitter)="showQAATFormModal($event)"
                    (showToolsFormEmitter)="showToolsFormModal($event)"
                    (showCaseRequestFormEmitter)= "showCaseRequestFormModal($event)"
                    ></app-custom-accordian>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Sidenav-->
<app-opnote-qa [formData]="formData" *ngIf="shouldShowQAOPFormModal" (closeEmitter)="onCloseFormModal()">
</app-opnote-qa>
<!-- Sidenav-->

<!-- Sidenav-->
<app-incoding-qa [formData]="formData" *ngIf="shouldShowQAICFormModal" (closeEmitter)="onCloseFormModal()">
</app-incoding-qa>
<!-- Sidenav-->

<!-- Sidenav-->
<app-attachments-qa [formData]="formData" *ngIf="shouldShowQAATFormModal" (closeEmitter)="onCloseFormModal()">
</app-attachments-qa>
<!-- Sidenav-->

<!-- Sidenav-->
<app-tools-qa [formData]="formData" *ngIf=shouldShowToolsModal (closeEmitter)="onCloseFormModal()">
</app-tools-qa>
<!-- Sidenav-->
<app-case-request  [commandType]= "componentName" [formData]="formData" *ngIf="shouldShowCaseRequestFormModal" (closeEmitter)="onCloseFormModal()">
</app-case-request>