import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { User } from 'src/app/modules/admin/manage-users/state/users/user.model';
import { UsersQuery } from 'src/app/modules/admin/manage-users/state/users/users.query';
import { UsersService } from 'src/app/modules/admin/manage-users/state/users/users.service';
import { ShareDataService } from 'src/app/shared/services/share-data.service';
import { Facility } from 'src/app/shared/state/facilities/facility.model';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';
import { SelectionService } from 'src/app/shared/state/selection/selection.service';
import { AssignCaseService } from '../state/assign-case.service';

@Component({
  selector: 'app-assign-case-form',
  templateUrl: './assign-case-form.component.html',
  styleUrls: ['./assign-case-form.component.css']
})
export class AssignCaseFormComponent implements OnInit {

  activePatients$: Observable<Patient[]>;
  usernameList$: Observable<User[]>;
  config;
  assignCaseRequest: Map<number, string> = new Map();
  activeFacility: Facility;
  AssignCaseForm: FormGroup;
  activePatient: Patient[] = [];
  constructor(
    private selectionService: SelectionService,
    private patientQuery: PatientQuery,
    private patientService: PatientService,
    private userQuery: UsersQuery,
    private assignCaseService: AssignCaseService,
    private facilityQuery: FacilityQuery,
    private userService: UsersService ,
    private toastr: ToastrService,
    ) { }

  ngOnInit(): void {
    this.activePatients$ = this.patientQuery.selectActive();
    this.activePatients$.subscribe(res => this.activePatient = res);
    this.config = this.initialiseDropDownConfig('userName');
    this.usernameList$ = this.userQuery.selectAll();
    this.activeFacility = this.facilityQuery.getActive().find(r => true);// find first 
    this.userService.bindFacilityUsers(this.activeFacility.id).subscribe();
    this.initForm();
  }

  initForm() {
    let ac = {};
    for(let i = 0; i < this.activePatient.length; i++){
      ac[this.activePatient[i].bdosid] = new FormControl('', Validators.required);
    }
    this.AssignCaseForm = new FormGroup(ac);
   // this.AssignCaseForm.addControl('assignAll', new FormControl('', Validators.required));
    
  }

  onClickClose(){
    this.selectionService.changeSelection('');
    console.log
  }

  removeActive(data){
    this.patientService.toggleActive(data.bdosid);
    this.assignCaseRequest.delete(data.bdosid);
  }

  onUserChange(event$){
   if(event$.value){
      this.activePatient.map(q => { 
        this.AssignCaseForm.controls[q.bdosid].setValue(event$.value);
      });
    }
    console.log(this.AssignCaseForm.controls);
  }
  hasAssignCaseMapping(): boolean{
    return true;
  }
  onSubmit(){
    if(!this.AssignCaseForm.valid){
      this.toastr.error('please fix the errors');
      return; 
    }
    Object.keys(this.AssignCaseForm.controls).forEach(key => {
      this.assignCaseRequest.set(Number(key), this.AssignCaseForm.controls[key].value.id);
    });
    this.assignCaseService.add(this.assignCaseRequest);
  }
  initialiseDropDownConfig(drpDownFor: string){
    return {
      displayKey: drpDownFor, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: '15rem', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      innerWidth: '16rem',
      placeholder: 'Select User', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search User', // label thats displayed in search input,
      searchOnKey: drpDownFor, // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }
}
