<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size  ml-4">{{ componentName }} </h1>
                    <button *ngIf="privileges.assignCasesAdd" (click)='openForm()' id="closeFileSidenav"
                        [disabled] = "(actionEnabled$ | async) === false"
                        class="btn btn-primary btn-patient-box openMySidenav  mr-4">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        {{ componentName }}
                    </button>
                </div>
                <div class="card-body">
                    <div class="table-responsive data-list" style="min-height: 29rem;">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="myTables_length" style="width: 16rem;">
                            <!-- <select class="custom-select"
                            name="nsn_ssign_case_facility" 
                            id="nsn_ssign_case_facility"
                            (ngModelChange)="facilityChange($event)" [compareWith]="compareFacility" [(ngModel)] = "selectedFacility">
                                <option style="width: 300px !important; overflow: hidden;" *ngFor="let data of (facility$ | async)" [ngValue]="data">{{data.name}}</option>
                            </select> -->
                            <ngx-select-dropdown [config]="configForFacility" (change)="facilityChange($event)"
                            [(ngModel)] = "selectedFacility"
                            [options]="facility$ | async" style="width: 100%;"
                            autoClearSearch="true" [multiple]="false">
                        </ngx-select-dropdown>
                        </div>
                        </div>
                          <div class="col-md-4">
                            <div class="myTables_length" style="width: 16rem;">
                            <!-- <select class="custom-select"
                            (ngModelChange)="categoryItemChange($event)" [compareWith]="compareCategory" [(ngModel)] = "selectedCategoryItem">
                                <option *ngFor="let data of (categoryItem$ | async)" [ngValue]="data">{{data.name}}</option>
                            </select> -->
                            <ngx-select-dropdown [config]="configForCategoryItem" (change)="categoryItemChange($event)"
                            [(ngModel)] = "selectedCategoryItem"
                            [options]="categoryItem$ | async" style="width: 100%;"
                            autoClearSearch="true" [multiple]="false">
                            </ngx-select-dropdown>
                            </div>
                          </div>
                          <div class="col-md-4">                            
                            <input id="tableConfigSearchBar" class="assign-case-search" type="text" placeholder="Patient Name | MRN" (keyup.enter)="searchPatient()" [(ngModel)]="searchValue">
                        </div>
                    </div>

                            <table class="table"  id="dataTable" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Facility
                                        </th>
                                        <th>MRN 
                                        </th>
                                        <th>Patient
                                        </th>
                                        <th>DoS 
                                        </th>
                                        <th>Insurance 
                                        </th>
                                        <th>Active Queue</th>
                                        <th>Age 
                                        </th>
                                        <th>Comment</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Facility 
                                        </th>
                                        <th>MRN 
                                        </th>
                                        <th>Patient 
                                        </th>
                                        <th>DoS 
                                        </th>
                                        <th>Insurance 
                                        <th>Active Queue</th>
                                        <th>Age 
                                        </th>
                                        <th>Comment</th>
                                    </tr>
                                </tfoot>
                                <tbody>
                                    <tr
                                    *ngFor='let data of (patients$ | async) | paginate: { itemsPerPage: pageSize, currentPage: p, totalItems: totalRows }; let i = index'>
                                        
                                    <td>
                                        {{(i+1) + ((p-1)*pageSize)}} 
                                    </td>
                                    <td>
                                        <input type="checkbox" ngModel (ngModelChange)="onCheckboxChange(data)" [checked]="isActive(data)">
                                    </td>
                                    <td>{{data.client}}</td>
                                    <td>{{data.mrn}}</td>
                                    <td>{{data.name}}</td>
                                    <td>{{data.dateOfSurgery}}</td>
                                    <td>{{data.insuranceProvider}}</td>
                                    <td>{{data.queueName}}</td>
                                    <td>{{data.dob}}</td>
                                    <td>{{data.lastNote}}</td>
                                    </tr>

                                </tbody>
                            </table>
                            <pagination-controls class="float-right" (pageChange)="pageChange($event); p = $event">
                            </pagination-controls>
                    
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>

<app-assign-case-form *ngIf="isAssignFormEnabled$ | async"></app-assign-case-form>