<!-- Begin Page Content -->
<div class="container-fluid">
    <div class="container1">
        <!-- Daily Progress-->
        <div class="daily-progress case-id-part">
            <div class="row">
                <div class="col-md-5">
                    <div class="">
                        <p class="case-text">Case Id 832640009 <i class="fa fa-file-text" aria-hidden="true"></i> </p>
                    </div>
                    <div class="case-detail-new">
                        <p class="case-det-text"><i class="fa fa-user"></i> Anderson,Jenefirr (12345)</p>
                        <p class="case-det-text"><i class="fa fa-building" aria-hidden="true"></i> New York Center</p>
                        <div class="clearfix"></div>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="right-cols-bar">
                        <ul>
                            <li>
                                <p class="case-icon"><i class="fa fa-list fa-color-blue" aria-hidden="true"></i></p>
                                <p class="icon-title"> QA Score: <span>50</span></p>
                                <p class="more-button"><a href="#">More</a></p>
                            </li>
                            <li>
                                <p class="case-icon"><i class="fa fa-star fa-color-blue"></i></p>
                                <p class="icon-title"><span><b>Top:10</b></span></p>
                            </li>
                            <li>
                                <p class="case-icon"><i class="fa fa-calendar fa-color-blue"></i></p>
                                <p class="icon-title"> Age: <span>52</span></p>
                            </li>
                            <li class="arror-part">
                                <p class="case-icon"><i class="fa fa-exclamation-triangle fa-color-blue"></i></p>
                                <div class="right-this">
                                    <p class="icon-title"> Status:</p>
                                    <p class="text-rght">Settlement Declined - Pre</p>
                                    <p class="text-rght"> Payment - Follow Up Needed</p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>


            <div class="down-row daily-progress1">
                <div class="row daily-progress1">
                    <div class="col-md-5">
                        <div class="case-detail-new">
                            <p class="case-text-cpt"> Primary CPT 12345</p>
                            <p class="case-text-cpt-second"> Primary CPTs 722965, 854854, 7854214</p>
                        </div>
                    </div>
                    <div class="col-md-7 update-text">
                        <p class="up-text1">Last Update 06/06/2019, Indal Advanshi</p>
                        <p class="up-text2">View Case on Navigator ></p>

                    </div>
                    <!-- daily Progreess End-->
                </div>
                <!-- daily Progreess End-->
            </div>
            <!-- Daily Progress-->

            <!-- container end-->
            <!-- /.container-fluid -->
        </div>
        <!-- Sugery Center Section-->
        <div class="container-fluid cont-mrg">
            <div class="container1">
                <div class="row advance-surgery-center">
                    <div class="col-md-3 surgery-row">
                        <p class="advance-section-text">CPT Description</p>
                    </div>

                    <div class="col-md-5">
                        <p class="head-text">Total Case Charges <strong>$121,839</strong></p>
                    </div>


                    <div class="plus-icon-tab" style="padding: 0px;">
                        <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,1)"><i
                                *ngIf="selectedIndex != 1" class="fa fa-plus"></i>
                            <i *ngIf="selectedIndex == 1" class="fa fa-minus"></i>
                        </p>
                    </div>
                </div>

                <div class="row widget-dashboard" id="show-data" *ngIf="selectedIndex == 1">
                    <div class="col-md-12">
                        <div class="table-outer">
                            <table class="table" width="100%" cellspacing="0" role="grid"
                                aria-describedby="dataTable_info" style="width: 100%;">
                                <thead>
                                    <tr role="row">
                                        <th>Primary CPT</th>
                                        <th>Description</th>
                                        <th>Cost</th>

                                    </tr>
                                </thead>

                                <tbody>
                                    <tr role="row">
                                        <td>123456</td>
                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been
                                            the industry's standard dummy </td>
                                        <td>75,000.00</td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <!--====222222222======-->
                        <div class="table-outer">
                            <table class="table" width="100%" cellspacing="0" role="grid"
                                aria-describedby="dataTable_info" style="width: 100%;">
                                <thead>
                                    <tr role="row">
                                        <th>Additional</th>
                                        <th> </th>
                                        <th>Additional</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr role="row">
                                        <td>123456</td>
                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been
                                            the industry's standard </td>
                                        <td>75,000.00</td>
                                    </tr>

                                    <tr role="row">
                                        <td>123456</td>
                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been
                                            the </td>
                                        <td>75,000.00</td>
                                    </tr>


                                    <tr role="row">
                                        <td>123456</td>
                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum </td>
                                        <td>75,000.00</td>
                                    </tr>


                                    <tr role="row">
                                        <td>123456</td>
                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been
                                            the industry's standard </td>
                                        <td>75,000.00</td>
                                    </tr>

                                    <tr role="row">
                                        <td>123456</td>
                                        <td>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been
                                            the industry's standard dummy text </td>
                                        <td>75,000.00</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>





                        <!-- Sugery Center Section End-->
                    </div>
                </div>
            </div>
        </div>
        <!-- container end-->

        <!-- 2222 -->
        <div class="container-fluid cont-mrg">
            <div class="container1">
                <div class="row advance-surgery-center">
                    <div class="col-md-4 surgery-row">
                        <p class="advance-section-text">Case Management</p>
                    </div>

                    <div class="plus-icon-tab" style="padding: 0px;">
                        <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,2)"><i
                                *ngIf="selectedIndex != 2" class="fa fa-plus"></i>
                            <i *ngIf="selectedIndex == 2" class="fa fa-minus"></i>
                        </p>
                    </div>
                </div>

                <div class="row widget-dashboard" id="show-data-02" *ngIf="selectedIndex == 2">


                    <div class="col-md-12">
                        <form class="pb-3 form-fields">

                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group remove-botttom-space">
                                        <label>Status<p class="lbl-text-rght">06/01/2020 4:30PM <a href="#">Log<i
                                                        class="fa fa-angle-right" aria-hidden="true"></i></a></p>
                                        </label>
                                        <select class="custom-select form-control">
                                            <option selected="">Settlement Declined</option>
                                            <option value="1">Settlement Declined 01</option>
                                            <option value="2">Settlement Declined 02</option>
                                        </select>
                                    </div>

                                    <div class="form-group remove-botttom-space">
                                        <label for="inputFirstName">Assigned To</label>
                                        <select class="custom-select form-control">
                                            <option selected="">Danny Brown</option>
                                            <option value="1">Danny Brown 01</option>
                                            <option value="2">Danny Brown 02</option>
                                        </select>
                                    </div>

                                </div>

                                <div class="col-md-6">
                                    <div class="row document-folder">
                                        <div class="col-md-5">
                                            <div class="doc-file-formate">
                                                <label>Documents</label>
                                                <figure><img src="assets/img/doc.jpg"><input type="file"><small
                                                        class="file-name">Doe,Jane_op Note</small></figure>
                                            </div>
                                        </div>

                                        <div class="col-md-7">
                                            <div class="case-hostory">
                                                <label>Case History</label>
                                                <div class="case-hostory-box-outer">
                                                    <ul>
                                                        <li>Case moved in-Coding by System 10:15AM</li>
                                                        <li>Op note Attached 06-05-2020 10:15AM</li>
                                                        <li class="document-link"><a href="#"><img
                                                                    src="assets/img/doc.jpg">Doe,Jane_op Note.doc</a>
                                                        </li>
                                                        <li>Case created 06/05/2020 8:15AM</li>

                                                    </ul>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>



                                <div class="col-md-12 list-outer-bar">
                                    <label>Internal Comments(3)</label>
                                    <div class="list-point with-bg" style="margin:0;">
                                        <div class="left-contant">
                                            <!-- <i class="fa fa-sticky-note" aria-hidden="true"></i> -->
                                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                                industry. Lorem Ipsum has been the industry's standard dummy text ever
                                                since&gt;</p>
                                        </div>

                                        <div class="right-contant">
                                            <p class="right-date">1/2/2020 4:32PM</p>
                                            <p class="status-bar">indal Adasi</p>
                                        </div>
                                    </div>
                                    <div class="more-list-point"><button class="morecontantbtn"><i
                                                class="fa fa-chevron-down" aria-hidden="true"></i></button></div>
                                </div>
                            </div>
                        </form>
                        <!-- Sugery Center Section End-->
                    </div>
                </div>
            </div>
        </div>


        <!-- 3333  -->
        <div class="container-fluid cont-mrg">
            <div class="container1">
                <div class="row advance-surgery-center">
                    <div class="col-md-8 surgery-row">
                        <p class="advance-section-text">Case Notes(9)</p>
                    </div>
                    <div class="plus-icon-tab">
                        <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,3)"><i
                                *ngIf="selectedIndex != 3" class="fa fa-plus"></i>
                            <i *ngIf="selectedIndex == 3" class="fa fa-minus"></i>
                        </p>
                    </div>
                </div>

                <div class="row widget-dashboard" id="show-data-03" *ngIf="selectedIndex == 3">
                    <div class="col-md-12 list-outer-bar">
                        <div class="list-point">
                            <div class="left-contant">
                                <i class="fa fa-sticky-note" aria-hidden="true"></i>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                    Ipsum has been the industry's standard dummy text ever since></p>
                            </div>

                            <div class="right-contant">
                                <p class="right-date">1/2/2020 4:32PM</p>
                                <p class="status-bar">indal Adasi</p>
                            </div>
                        </div>




                        <!-- <div class="more-list-point"><button class="morecontantbtn"><i class="fa fa-chevron-down" aria-hidden="true"></i></button></div> -->


                    </div>
                </div>
            </div>
        </div>

        <!-- 444  -->
        <div class="container-fluid cont-mrg">
            <div class="container1">
                <div class="row advance-surgery-center">
                    <div class="col-md-4 surgery-row">
                        <p class="advance-section-text">Case Management</p>
                    </div>

                    <div class="col-md-4">
                        <p class="head-text">Balance <strong>$75,405.00</strong></p>
                    </div>

                    <div class="col-md-4">
                        <p class="head-text">Service Date <strong>03/06/2019</strong></p>
                    </div>


                    <div class="plus-icon-tab" style="padding: 0px;">
                        <p class="advance-icon-text plus-sign-bx expand-data" (click)="toggle(this,4)"><i
                                *ngIf="selectedIndex != 4" class="fa fa-plus"></i>
                            <i *ngIf="selectedIndex == 4" class="fa fa-minus"></i>
                        </p>
                    </div>
                </div>

                <div class="row widget-dashboard" id="show-data-04" *ngIf="selectedIndex == 4">
                    <div class="table-outer">
                        <table class="table dataTable" width="100%" cellspacing="0" role="grid"
                            aria-describedby="dataTable_info" style="width: 100%;">
                            <thead>
                                <tr role="row">
                                    <th>Type</th>
                                    <th>Bill Date</th>
                                    <th>Billed</th>
                                    <th>Paid</th>
                                    <th>Payer</th>
                                    <th>Policy ID</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr role="row">
                                    <td>ABCD</td>
                                    <td>Smith John</td>
                                    <td>01/22/19</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>Marshall MD</td>
                                </tr>
                                <tr role="row">
                                    <td>ABCD</td>
                                    <td>Smith John</td>
                                    <td>01/22/19</td>
                                    <td>1001</td>
                                    <td>100</td>
                                    <td>Marshall MD</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>



        <div class="button-row-bottom p-4">
            <button type="button" routerLink="/patient" class="btn btn-secondary mr-1 btn-patient-box">Cancel</button>
            <button type="submit" class="btn btn-primary btn-patient-box">Submit</button>
        </div>

        <!-- container fluid advcance secttion end-->
        <!-- End of Content Wrapper -->
    </div>
</div>