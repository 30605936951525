import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { UserProductivity } from './user-productivity.model';

export interface UserProductivityState extends EntityState<UserProductivity>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user-productivity', idKey: 'userid' })
export class UserProductivityStore extends EntityStore<UserProductivityState> {

  constructor() {
    super();
  }

}
