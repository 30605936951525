import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from 'src/app/shared/services/common.service';
import { AccessService } from 'src/app/shared/services/access.service';

import { Router } from '@angular/router';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  monthNumber: number;
  yearNumber: number;
  monthName: string;
  DashboardArry: any[];
  DashboardHeaderArry: any[];
  selectedIndex = -1;
  endDate: any;
  eom: number;
  DashboardClientArry: any[];

  totalPriorityItems: number = 0;
  totalActionItems: number = 0;
timer:any
 

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private accessservices:AccessService,
    private commonUtil: CommonService,
    private router:Router) { }

  ngOnInit(): void {
    
    this.commonUtil.setTitle("Dashboard");
    this.monthNumber = new Date().getMonth() + 1;
    this.yearNumber = new Date().getFullYear();
    
    this.getDashboardList();
  //  this.timeOut();
  }

  getDashboardList() {
    let data = { "MonthNumber": this.monthNumber, "YearNumber": this.yearNumber };

    this.getApiService.post("Billing/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {
        this.DashboardArry = res.datagrid;
        this.DashboardHeaderArry = res.datagridHeader;
        this.DashboardClientArry = res.dataClientList;
      
        for (let d of res.datagridHeader) {
          this.totalActionItems += Number(d.actionitems);
          this.totalPriorityItems += Number(d.priorityItems);
        }

        this.getMonthName();
      }
      else if (res.status == "Error") {
        if (res.message == "Invalid User") {
          localStorage.clear();
          window.location.href = "/login";

        }
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  toggle(evt, index) {
    if (this.selectedIndex != index) {
      this.selectedIndex = index;
    } else {
      this.selectedIndex = -1;
    }

  }

  getMonthName() {

    var month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    this.monthName = month[this.monthNumber - 1];

    this.getEndDate();

  }


  getEndDate() {
    let curMonth = new Date().getMonth() + 1;

    if (curMonth == this.monthNumber) {
      this.endDate = new Date().getDate();
    } else {
      let dt = new Date(this.yearNumber, this.monthNumber, 0).toString();
      this.endDate = new Date(dt).getDate();
    }

    let mnthDate = new Date(this.yearNumber, curMonth, 0).toString();
    let curEndDate = new Date(mnthDate).getDate();

    this.eom = curEndDate - this.endDate;

  }
  
  



}

 
