import { Injectable } from '@angular/core';
import { EntityState, EntityStore, MultiActiveState, StoreConfig } from '@datorama/akita';
import { CaseRequest } from './case-request.model';

export interface CaseRequestState extends EntityState<CaseRequest, number | string>, MultiActiveState {}

const initialState = {
  active: [],
};

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'case-request' })
export class CaseRequestStore extends EntityStore<CaseRequestState> {

  constructor() {
    super(initialState);
  }

}
