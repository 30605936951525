<!-- Begin Page Content -->
<div class="container-fluid">
  <i onclick="window.history.go(-1)" class="fa fa-arrow-left fa-2x" aria-hidden="true"></i>
  <section class="biller_new_first mb-3">
    <div class="container-fluid pr-0 pl-0 biller_play">
      <!-- <a [routerLink]="['/d/patientDtls', (data?.facilityId || 0), (data?.mrn || 0)]"
            class="icon-color-black openMySidenav mr-2"><i
            class="fa fa-arrow-left fa-2x" aria-hidden="true" ></i></a> -->

      <div class="row">
        <div class="col-md-3 pl-0">
          <h4 class="mb-0">MRN {{ dataPatientInfo?.mrn }}</h4>
          <select (change)="getPatientInfo($event)">
            <option *ngFor="let a of dataDosInfo" [value]="a.bdosid">
              {{ a.dos | date: "MM/dd/yyyy" }}
            </option>
          </select>
          <h5 class="name_p">
            <i class="fas fa-user user_icon"></i>
            {{ dataPatientInfo?.patientName }}
          </h5>
          <h5 class="name_p">
            <i class="fa fa-building user_icon"></i>
            {{ dataPatientInfo?.facilityName }}
          </h5>
          <p>Days Until Follow-Up:<span class="pra_span"> 0 Days</span></p>
          <div class="row">
            <div class="col-md-5">
              <p>
                Charges:<span class="pra_span_blue">
                  ${{ dataCharges[0]?.value || 0 | number }}</span>
              </p>
            </div>
            <div class="col-md-7 pr-0">
              <p>
                Biller:<span class="pra_span">
                  {{ dataPatientInfo?.biller }}</span>
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <p>Payments:<span class="pra_span_blue"> N/A</span></p>
            </div>
            <div class="col-md-7 pr-0">
              <p>Payment Poster:<span class="pra_span"> N/A</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <p>Adjustments:<span class="pra_span_blue"> N/A</span></p>
            </div>
            <div class="col-md-7 pr-0">
              <p>AR:<span class="pra_span"> N/A</span></p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-5">
              <p class="pra_span_blue a_p" href="#">Denials</p>
            </div>
            <div class="col-md-7 pr-0">
              <p class="pra_span_blue a_p" href="#">History</p>
            </div>
          </div>
          <p>
            Outstanding:<span class="pra_span_red">
              ${{ dataOutstanding[0]?.outstandingValue || 0 | number }}</span>
          </p>
          <div *ngIf="eod != null">
            <p>
              Electronic Claim No:<span >
                {{eod.claim}}
                </span>
            </p>
            <p>
              Waystar No:<span>
                </span>
            </p>
          </div>
         

        </div>
        <div class="col-md-4 biller_play">
          <div class="row">
            <div class="col-md-6">
              <h5>Personal Information</h5>
              <select class="pat-detail-select-width-full">
                <option>Patient</option>
              </select>
              <div class="pat-detail-pad-bottom">
                <p>
                  Address:<span class="pra_span">
                    {{ dataPatientInfo?.address1 || "" }}
                    {{ dataPatientInfo?.address2 || "" }}</span>
                </p>
                <p>
                  City:<span class="pra_span">
                    {{ dataPatientInfo?.city || "" }}</span>
                </p>
                <p>
                  State:<span class="pra_span">
                    {{ dataPatientInfo?.state || "" }}</span>
                </p>
                <p>
                  Zip:<span class="pra_span">
                    {{ dataPatientInfo?.zip || "" }}</span>
                </p>
              </div>
              <div class="pat-detail-pad-bottom">
                <p>
                  Phone:<span class="pra_span">
                    {{ dataPatientInfo?.phonenumber || "" }}</span>
                </p>
                <p>Email:<span class="pra_span"></span></p>
              </div>
              <div>
                <p>
                  DOB:<span class="pra_span">
                    {{ dataPatientInfo?.dob || "" }}</span>
                </p>
              </div>
              <!-- <p>SSN:<span class="pra_span"> {{dataPatientInfo?.ssnNo || ""}}</span></p> -->
            </div>

            <div class="col-md-6">
              <h5>Insurance Information</h5>
              <select (change)="insStat = $event.target.value" class="pat-detail-select-width-full">
                <option value="Primary">Primary</option>
                <option *ngFor="let z of dataSInsuranceInfo" value="{{ z.insuranceType }}">
                  {{ z.insuranceType }}
                </option>
              </select>
              <div *ngIf="insStat == 'Primary'">
                <p>
                  Primary:<span class="pra_span">
                    {{ dataPInsuranceInfo[0]?.insuranceProvider || "" }}</span>
                </p>
                <p>Phone:<span class="pra_span"></span></p>
                <p>Fax:<span class="pra_span"></span></p>
                <p>Group Category:<span class="pra_span"> </span></p>
                <p>
                  Insurance ID:<span class="pra_span">
                    {{ dataPInsuranceInfo[0]?.insuranceId || "" }}</span>
                </p>
                <p>
                  Group #:<span class="pra_span">
                    {{ dataPInsuranceInfo[0]?.groupNumber || "" }}</span>
                </p>
                <p>Contract:<span class="pra_span"> </span></p>
                <!-- <p>Waystar Status:<span class="pra_span_green"> Enrolled</span></p> -->
                <p>Website:<span class="pra_span"></span></p>
                <p>Rules:<span class="pra_span"></span></p>
              </div>


              <div *ngFor="let z of dataSInsuranceInfo">
                <div *ngIf="insStat == z.insuranceType">
                  <p>
                    {{ z.insuranceType }}:<span class="pra_span">
                      {{ z.insuranceProvider }}</span>
                  </p>
                  <p>Phone:<span class="pra_span"> </span></p>
                  <p>Fax:<span class="pra_span"> </span></p>
                  <p>Group Category:<span class="pra_span"> </span></p>
                  <p>
                    Insurance ID:<span class="pra_span">
                      {{ z.insuranceId }}</span>
                  </p>
                  <p>
                    Group #:<span class="pra_span"> {{ z.groupNumber }}</span>
                  </p>
                  <p>Contract:<span class="pra_span"> </span></p>
                  <!-- <p>Waystar Status:<span class="pra_span_green"> Enrolled</span></p> -->
                  <p>Website:<span class="pra_span"></span></p>
                  <p>Rules:<span class="pra_span"></span></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-5 biller_play biller_play_icon">
          <div class="row">
            <div class="col-md-6">
              <div class="row">
                <div class="col-md-4 pr-0 text-center">
                  <div class="ic">
                    <i class="fas fa-shopping-bag"></i>
                    <p class="mb-0">QA Score<span class="pra_span">50</span></p>
                    <span class="pra_span_blue">More></span>
                  </div>
                </div>
                <div class="col-md-4 pr-0 text-center">
                  <div class="ic">
                    <i class="fas fa-star"></i>
                    <p class="pra_span">Top: 10</p>
                  </div>
                </div>
                <div class="col-md-4 pr-0 text-center">
                  <div class="ic">
                    <i class="fa fa-calendar" aria-hidden="true"></i>
                    <p>
                      Age:<span class="pra_span">
                        {{ dataPInsuranceInfo[0]?.age || 0 }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="ic pl-2 pb-3" style="height: auto;">
                <div class="row">
                  <div class="col-md-2">
                    <i class="fas fa-shopping-bag"></i>
                  </div>
                  <div class="col-md-10">
                    <p class="mb-0">Status</p>
                    <span class="pra_span">
                      <p>

                        <span>{{uniqueRfiList}} </span>

                      </p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col-md-6 pr-0">
              <div class="ic_2 height-77">
                <div class="row">
                  <div class="col-md-12">
                    <i class="fas fa-shopping-bag"></i><span>Tools and Templates</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="ic_2 ic_2_not pt-0 pl-0 height-77">
                <div class="row">
                  <div class="col-md-12">
                    <p class="pat-detail-last-update-txt">
                      Last Update
                      {{ dataPatientInfo?.lastUpdated | date: "short" }},{{
                      dataPatientInfo?.biller
                      }}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label class="pra_span_blue pat-detail-font-size-15"><span>Report Issue</span>
                      <i class="fa fa-flag" aria-hidden="true"
                        style="color: red; padding-left: 10px; font-size: 15px"></i>
                    </label>
                    <select>
                      <option>Please Select</option>
                    </select>
                  </div>

                  <div class="col-md-6">
                    <label class="pra_span_blue pat-detail-font-size-15"><span>Give Kudos</span>
                      <i class="fa fa-star" aria-hidden="true" style="
                          color: yellow;
                          padding-left: 10px;
                          font-size: 15px;
                        "></i>
                    </label>
                    <select>
                      <option>Please Select</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

  <section class="biller_center_item">
    <div class="container">
      <div class="row">
        <div class="
            col-md-12
            justify-content-center
            align-items-center
            d-flex
            text-center
          ">
          <p class="active">Billing</p>
          <p>Payment Posting</p>
          <p>Accounts Receivable</p>
          <p>Legal</p>
          <p>Collection</p>
          <p>Case History</p>
        </div>
      </div>
    </div>
  </section>

  <section class="biller_three_col mb-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <div class="row">
            <div class="col-md-12">
              <div class="cpt pad-1">
                <h4>CPT</h4>
              </div>
            </div>
            <div class="col-md-12">
              <div class="bg-white cpt_white" style="max-height: 375px; overflow-y: scroll">
                <div class="row">
                  <div class="col-md-4 biller_col_4">
                    <p class="pra_span">Scheduled</p>
                    <div *ngFor="let d of ScheduledCptCodes">

                      <span *ngIf="d.cptType == 2">{{ d.cptCode }}</span>
                    </div>
                  </div>
                  <div class="col-md-4 biller_col_4">
                    <p class="pra_span">Coded</p>
                    <div *ngFor="let d of ScheduledCptCodes">
                      <!-- <span *ngIf="d.cptType == 2">{{ d.cptCode }}</span> -->
                      <div *ngIf="d.cptType == 0" [ngClass]="{'text-warning' : !codedCptHighlightIds.includes(d.id)}">{{ d.cptCode }}</div>
                    </div>

                  </div>
                  <div class="col-md-4 biller_col_4">
                    <p class="pra_span">Billed</p>
                    <div *ngFor="let d of ScheduledCptCodes">
                      <!-- <span *ngIf="d.cptType == 2">{{ d.cptCode }}</span> -->
                      <div *ngIf="d.cptType == 1" [ngClass]="{'text-success' : !billedCptHighlightIds.includes(d.id)}">{{ d.cptCode }}</div>
                    </div>
                    <!-- <p class="pra_span_green">l1232</p> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col case_text">
          <div class="case_flow pad-1">
            <div class="row">
              <div class="col-md-12">
                <h4>Case flow</h4>
              </div>
            </div>
          </div>
          <div class="bg-white cpt_white">
            <div class="row">
              <div class="col-md-12 case_h5">
                <h4>
                  Status:<span class="pra_span_blue">{{caseFlowStatus}}</span>
                </h4>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <!-- <p>
                  Ready to Bill:<span class="pra_span"> 00:00</span>
                  <span class="pra_span_grey pra_span_pad_left_20"
                    >(Times in HH:MM)</span
                  >
                </p> -->
                <p>
                  Client High$$$ :<span class="pra_span pra_span_red">
                    {{ dataCaseFlow[0]?.clientHigh$$$ || "00:00" }}</span>
                </p>
                <p>
                   High$$$ :<span class="pra_span pra_span_red">
                    {{ dataCaseFlow[0]?.nsnHigh$$$ || "00:00" }}</span>
                </p>
                <p>
                  In Coding:<span class="pra_span pra_span_yellow">
                    {{ dataCaseFlow[0]?.inCoding || "00:00" }}</span>
                </p>
                <p>
                  Path Report:<span class="pra_span">
                    {{ dataCaseFlow[0]?.pathology || "00:00" }}</span>
                </p>
                <p>
                  Pricing:<span class="pra_span">
                    {{ dataCaseFlow[0]?.pricing || "00:00" }}</span>
                </p>
                <p>
                  Op Report:
                  <span class="pra_span">
                    {{ dataCaseFlow[0]?.opReport || "00:00" }}</span>
                </p>
                <!-- <p>Pricing:<span class="pra_span"> {{dataCaseFlow[0]?.pricing || 0}}</span></p> -->
                <p>
                  48 Hours:
                  <span class="pra_span">
                    {{ under48?.under48 || "00:00" }}</span>
                  <!-- <span class="pra_span" *ngIf="under48?.under48Hrs < 49">
                   
                    {{ under48?.under48 }}
                  </span>
                  <span class="pra_span" *ngIf="under48?.under48Hrs > 48">
                  
                    48:00
                  </span> -->

                </p>
                <p>
                  Block Report:<span class="pra_span">
                    {{ dataCaseFlow[0]?.block || "00:00"}}</span>
                </p>
                <p>
                  Coding Query:<span class="pra_span">
                    {{ dataCaseFlow[0]?.codingQuery || "00:00" }}</span>
                </p>
                <p>
                  Other/Misc:<span class="pra_span">
                    {{ dataCaseFlow[0]?.otherMisc || "00:00" }}</span>
                </p>

                <p>
                  Variance:<span class="pra_span">
                    {{ dataCaseFlow[0]?.variance || "00:00" }}</span>
                </p>
                <p>
                  Auth Review:<span class="pra_span">
                    {{ dataCaseFlow[0]?.authReview || "00:00" }}
                  </span>
                </p>
                <p>
                  Coding Expert:<span class="pra_span">
                    {{ dataCaseFlow[0]?.codingExpert || "00:00" }}</span>
                </p>
                <p>
                  Time in Billing:<span class="pra_span pra_span_red">
                    {{ totalBilling }}</span>
                </p>
                <p>
                  Days in Billing:<span class="pra_span pra_span_red">
                    {{daysinbilling}}</span>
                </p>
              </div>
              <!-- <div class="col-md-6 pl-0">
                                <canvas width="100%" height="90%" baseChart [data]="pieChartData"
                                    [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions"
                                    [colors]="pieCharttColors" [legend]="false">
                                </canvas>
                            </div> -->
            </div>
          </div>
        </div>

        <div class="col">
          <div class="cpt pad-1">
            <h4>Notes</h4>
          </div>
          <div class="container pat-detail-container">
            <ul class="nav nav-pills">
              <li class="pat-detail-all-width">
                <a class="nav-link active pat-detail-text-align-center" data-toggle="pill" href="#allNotes">
                  <span class="pra_span_no_color">All</span></a>
              </li>
              <li class="pat-detail-internal-width">
                <a class="nav-link pat-detail-tab-text-align" data-toggle="pill" href="#internal">
                  <span class="pra_span_no_color">Internal</span></a>
              </li>
              <li class="pat-detail-history-width">
                <a class="nav-link pat-detail-tab-text-align" data-toggle="pill" href="#history">
                  <span class="pra_span_no_color">History</span></a>
              </li>
            </ul>

            <div class="tab-content pat-detail-tab-scroll">
              <div id="allNotes" class="tab-pane fade in active show">
                <div *ngFor="let e of dataHistoryInfo">
                  <div *ngIf="
                      (e.billerNote != '' && e.billerNote != 'null') ||
                      (e.clientNote != '' && e.clientNote != 'null')
                    ">
                    <p class="mb-0">{{ e.billerNote || e.clientNote }}</p>
                    <p class="pra_span">{{ e.billerTime || e.clientTime }}</p>
                  </div>
                </div>
              </div>

              <div id="internal" class="tab-pane fade">
                <div *ngFor="let e of dataHistoryInfo">
                  <div *ngIf="
                      e.auditEventDescription == 'Note' &&
                      e.auditEventType == 'Note_Private'
                    ">
                    <p class="mb-0">{{ e.billerNote || e.clientNote }}</p>
                    <p class="pra_span">{{ e.billerTime || e.clientTime }}</p>
                  </div>
                </div>
              </div>

              <div id="history" class="tab-pane fade">
                <div *ngFor="let c of dataHistoryInfo">
                  <div *ngIf="
                      ((c.billerNote != '' && c.billerNote != 'null') ||
                        (c.clientNote != '' && c.clientNote != 'null')) &&
                      c.auditEventDescription != 'Note'
                    ">
                    <p class="mb-0">{{ c.billerNote || c.clientNote }}</p>
                    <p class="mb-0 pra_span_blue" *ngIf="c.contentFileName != null">
                      <a href="javascript:void(0)" (click)="getFile(c.id, c.contentFileName)">{{ c.contentFileName
                        }}</a>
                    </p>
                    <p class="pra_span">{{ c.billerTime || c.clientTime }}</p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="row">
            <div class="col-md-12">
              <div class="cpt pad-1">
                <h4>Attachments</h4>
              </div>
            </div>
            <div class="col-md-12">
              <div class="bg-white cpt_white attachments_div" style="max-height: 375px; overflow-y: scroll">
                <!-- <div class="row">
                                    <div class="col-md-1">
                                        <input type="checkbox" name="">
                                    </div>
                                    <div class="col-md-11">
                                        <p class="pra_span">Select All</p>
                                    </div>

                                </div> -->
                <div *ngFor="let d of dataHistoryInfo">
                  <div class="row" *ngIf="d.contentFileName != null && d.contentFileName != ''">
                    <!-- <div class="col-md-1">
                                            <input type="checkbox" name="">
                                        </div> -->
                    <div class="col-md-1">
                      <i class="fa fa-file-pdf"></i>
                    </div>
                    <div class="col-md-7">
                      <p class="mb-0 pra_span">{{ d.auditEventDescription }}</p>
                      <p class="mb-0 pra_span_blue">
                        <a href="javascript:void(0)" (click)="getFile(d.id, d.contentFileName)">{{ d.contentFileName
                          }}</a>
                      </p>
                      <p class="mb-0">{{ d.billerTime || d.clientTime }}</p>
                    </div>
                    <div class="
                        col-md-3
                        justify-content-center
                        align-items-center
                        d-flex
                      ">
                      <p class="pra_span mb-0"></p>
                      <!-- 256 KB -->
                    </div>
                  </div>




                </div> <!-- End of HistoryInfo -->

                <!-- <div class="row">
                                    <div class="col-md-1">
                                        <input type="checkbox" name="">
                                    </div>
                                    <div class="col-md-1">
                                        <i class="fa fa-file-pdf"></i>
                                    </div>
                                    <div class="col-md-7">
                                        <p class="mb-0 pra_span">Returned Coding</p>
                                        <p class="mb-0 pra_span_blue">Jennifer Anderson Coding.pdf</p>
                                        <p class="mb-0">08/28/2020 3:52 PM</p>
                                    </div>
                                    <div class="col-md-3 justify-content-center align-items-center d-flex">
                                        <p class="pra_span mb-0">256 KB</p>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-md-1">
                                        <input type="checkbox" name="">
                                    </div>
                                    <div class="col-md-1">
                                        <i class="fa fa-file-word"></i>
                                    </div>
                                    <div class="col-md-7">
                                        <p class="mb-0 pra_span">Returned Coding</p>
                                        <p class="mb-0 pra_span_blue">Jennifer Anderson Coding.pdf</p>
                                        <p class="mb-0">08/28/2020 3:52 PM</p>
                                    </div>
                                    <div class="col-md-3 justify-content-center align-items-center d-flex">
                                        <p class="pra_span mb-0">256 KB</p>
                                    </div>

                                </div> -->

                <!-- <div class="row mt-2">
                                    <div class="col-md-12 fel_p">
                                        <p class="pra_span bor_p">Open</p>
                                        <p class="pra_span bor_p">Download</p>
                                        <p class="pra_span no_bor">Print</p>
                                    </div>
                                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>