export interface ManagementReport {
  id: string;
  user: string;
  totalopencasesbfr: number;
  totalopencasestilldate: number;
  totalcasesadded: number;
  totalsetbilledcases: number;
  facilityName: string;
  totalcount: number;
}

export function createManagementReport(params: Partial<ManagementReport>) {
  return {
    ...params
  } as ManagementReport;
}
