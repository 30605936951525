import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { ManagementReportStore, ManagementReportState } from './management-report.store';

@Injectable({ providedIn: 'root' })
export class ManagementReportQuery extends QueryEntity<ManagementReportState> {

  constructor(protected store: ManagementReportStore) {
    super(store);
  }

}
