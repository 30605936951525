export interface ManagementReportLine {
    userId: string;
    user: string;
    facilityID: number;
    facilityName: string;
    mrn: string;
    importDate: string;
    setBilledDate: string;
    totalCount: number;
  }
  
  export function createManagementReportLine(params: Partial<ManagementReportLine>) {
    return {
      ...params
    } as ManagementReportLine;
  }
  