import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { BulkActionRequestStatus } from './bulk-action-request-status.model';
import { BulkActionRequestStatusStore, BulkActionRequestStatusState } from './bulk-action-request-status.store';

@Injectable({ providedIn: 'root' })
export class BulkActionRequestStatusQuery extends QueryEntity<BulkActionRequestStatusState> {

  constructor(protected store: BulkActionRequestStatusStore) {
    super(store);
  }
}
