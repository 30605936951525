<!-- Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">{{ formFlag }} Notification</h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <form class="pb-3 form-fields" [formGroup]="ENForm">
            <div class="row">
                <label>Event : {{ eventName }}</label>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="EmailTo" class="required">To</label>
                    <input type="text" class="form-control" formControlName="EmailTo" placeholder="Email To"
                        [ngClass]="{ 'is-invalid': submitted && f.EmailTo.errors }" required>
                    <div *ngIf="submitted && f.EmailTo.errors" class="invalid-feedback">
                        <div *ngIf="f.EmailTo.errors.required">*Email To is required</div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="EmailCc">Cc</label>
                    <input type="text" class="form-control" formControlName="EmailCc" placeholder="Email Cc">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-12">
                    <label for="EmailBcc">Bcc</label>
                    <input type="text" class="form-control" formControlName="EmailBcc" placeholder="Email Bcc">
                </div>
            </div>
            <div class="text-right">
                <button type="button" (click)="onClickClose()"
                    class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                <button type="button" (click)="addRecord()" class="btn btn-primary btn-patient-box">Submit</button>
            </div>
        </form>
    </div>
</div>
</div>
<!-- Sidenav-->