import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EmailNotificationComponent } from './email-notification/email-notification.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'emailNotification',
        component: EmailNotificationComponent
      },
      {
        path: '',
        redirectTo: '/emailNotification',
        pathMatch: 'full'
      },
    ],
  }]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
