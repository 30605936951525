import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Facility } from 'src/app/shared/state/facilities/facility.model';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { CaseRequestCptQuery } from '../state/case-request-cpt/case-request-cpt.query';
import { CaseRequestCptService } from '../state/case-request-cpt/case-request-cpt.service';
import {
  bdosid,
  facilityId,
  operatingReport,
  pathology, pricing,
  blockReport,
  codingQuery,
  otherMisc,
  inCoding,
  authReview,
  codingExpert,
  highApproval,
  highApprovalInternal,
  variance,
  NameConstant,
  nsnCpt,
  physicianCpt,
  cpt,
  implantLog,
  invoice,
} from 'src/app/shared/models/name-constant';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { CaseRequestQuery } from '../state/case-request/case-request.query';
import { CaseRequestService } from '../state/case-request/case-request.service';
import { CurrentCaseRequest } from '../state/current-case-request/current-case-request.model';
import { CurrentCaseRequestQuery } from '../state/current-case-request/current-case-request.query';
import { CurrentCaseRequestService } from '../state/current-case-request/current-case-request.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { CaseRequestInfoQuery } from '../state/case-request-info/case-request-info.query';
import { QueueInfo } from '../state/case-request-info/case-request-info.model';

@Component({
  selector: 'app-current-case-request',
  templateUrl: './current-case-request.component.html',
  styleUrls: ['./current-case-request.component.css']
})
export class CurrentCaseRequestComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  currentCaseRequest$: Observable<CurrentCaseRequest[]>;
  cardHeaderTitle: string = "Current Case Request(s)";
  hasAnyCurrentCaseRequest$: Observable<boolean>;
  hasAnyCurrentCaseRequestToSubmit$: Observable<boolean>;
  FacilityList$: Observable<Facility[]>;
  hasActivePatients$: Observable<boolean>;
  nsnCpt: NameConstant = nsnCpt;
  physicianCpt: NameConstant = physicianCpt;

  constructor(
    private currentCaseRequestService: CurrentCaseRequestService,
    private currentCaseRequestQuery: CurrentCaseRequestQuery,
    private caseRequestService: CaseRequestService,
    private caseRequestCptService: CaseRequestCptService,
    private caseRequestCptQuery: CaseRequestCptQuery,
    private patientQuery: PatientQuery,
    private facilityQuery: FacilityQuery,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private facilityService: FacilityService,
    private caseRequestInfoQuery: CaseRequestInfoQuery
  ) { }

  ngOnInit(): void {
    this.FacilityList$ = this.facilityQuery.selectAll();
    this.currentCaseRequest$ = this.currentCaseRequestQuery.selectAll();
    this.hasAnyCurrentCaseRequest$ = this.currentCaseRequestQuery.selectCurrentRequestNotSubmitted().pipe(map(res => res.length > 0));
    this.hasAnyCurrentCaseRequestToSubmit$ = this.currentCaseRequestQuery.selectAll().pipe(map(res => {
      const reqs = res.filter(r => (!r.alreadySubmitted || (r.alreadySubmitted && r.isModified)));
      return reqs.length > 0;
    }))

    this.facilityService.bindDashboardFacilityData().subscribe();
    this.FacilityList$ = this.facilityQuery.selectAll();
   
  
    this.hasActivePatients$ = this.patientQuery.selectActiveId().pipe(map(res => res && res.length > 0 ));

    //check if facility is already selected.
    //this.checkAndSetDefaultFacility()

  }
  
  removeCurrenRequest(cr: CurrentCaseRequest) {
    if (cr.alreadySubmitted && cr.isModified) {
      this.currentCaseRequestService.update(cr.id, { isModified: false })
    } else {
      this.currentCaseRequestService.remove(cr.id);
    }
    if (!cr.alreadySubmitted) {
      this.caseRequestService.toggleActive(cr.id);
    }
    const ids = this.caseRequestCptQuery.findCptOfRequestNotSubmitted(cr.id.toString()).map(e => e.id);
    this.caseRequestCptService.removeAll(ids);
  }

  removeAllCurrenRequest() {
    const activeIds = this.currentCaseRequestQuery.getAll({
      filterBy: entity => !entity.alreadySubmitted
    }).map(cr => cr.id);

    this.caseRequestService.toggleAllActive(activeIds);
    this.currentCaseRequestService.removeAllGiven(activeIds);
    const ids = this.caseRequestCptQuery.findAllCptNotSubmitted().map(e => e.id);
    this.caseRequestCptService.removeAll(ids);
  }

  confirmRequest() {
    const confirmCaseRequest = this.currentCaseRequestQuery.getAll();
    console.log('Confirm Case Request', confirmCaseRequest);
    this.onSubmit();
  }

  private hasCurrentRequestType(id: string | number): string {
    return this.currentCaseRequestQuery.hasEntity(id) ? "true" : "false";
  }

  private getRequestFlagForClient(queueInfo: QueueInfo, id: string | number): string {

    if (this.currentCaseRequestQuery.hasEntity(id)) {
      return "true";
    }

    switch (id) {
      case operatingReport.id: return queueInfo.operatingReport ? "false" : "true";
      case pathology.id: return queueInfo.pathology ? "false" : "true";
      case pricing.id: return queueInfo.pricing ? "false" : "true";
      case blockReport.id: return queueInfo.blockReport ? "false" : "true";
      case codingQuery.id: return queueInfo.codingQuery ? "false" : "true";
      case otherMisc.id: return queueInfo.otherMisc ? "false" : "true";
      case inCoding.id: return queueInfo.inCoding ? "false" : "true";
      case authReview.id: return queueInfo.authReview ? "false" : "true";
      case codingExpert.id: return queueInfo.codingExpert ? "false" : "true";
      case highApproval.id: return queueInfo.highApproval ? "false" : "true";
      case highApprovalInternal.id: return queueInfo.highApprovalInternal ? "false" : "true";
      case variance.id: return queueInfo.variance ? "false" : "true";
      case implantLog.id: return queueInfo.implantLog ? "false" : "true";
      case invoice.id: return queueInfo.invoice ? "false" : "true";
    }

  }

  private getRequestFlagForRequestTobeSatisfiedByNSN(queueInfo: QueueInfo, id: string | number): string {
    const request: CurrentCaseRequest = this.currentCaseRequestQuery.getEntity(id);

    if (request) {
      return request.isModified ? "true" : "false";
    }

    switch (id) {
      case inCoding.id: return queueInfo.inCoding ? "true" : "false";
      case authReview.id: return queueInfo.authReview ? "true" : "false";
      case codingExpert.id: return queueInfo.codingExpert ? "true" : "false";
      case highApprovalInternal.id: return queueInfo.highApprovalInternal ? "true" : "false";
    }

  }

  private setRequestFlagForBiller(requestName: NameConstant, frmData, queueInfo: QueueInfo) {
    const clientRole = this.caseRequestInfoQuery.isClientRole();
    if (clientRole) {
      frmData.append(requestName.commandName, this.getRequestFlagForClient(queueInfo, requestName.id));
    } else {
      frmData.append(requestName.serverValue, this.hasCurrentRequestType(requestName.id));
    }
  }

  onSubmit() {
    const clientRole = this.caseRequestInfoQuery.isClientRole();
    const queueInfo: QueueInfo = this.caseRequestInfoQuery.getQueueInfo();
    if (this.currentCaseRequestQuery.getCount() > 0) {
      const activePatient = this.patientQuery.getActive().find(p => true);
      console.log(activePatient);
      console.log(activePatient.facilityId, "active patient")

      let activeFacility = this.facilityQuery.getEntity(activePatient.facilityId);
      console.log("---->", this.facilityQuery.getEntity(activePatient.facilityId))

      if (!activeFacility) {
        const fac_id = "facility_" + activePatient.facilityId.toString();
        console.log(fac_id);
        activeFacility = this.facilityQuery.getAll().find(f => f.id === fac_id);
        console.log(activeFacility);
        console.log(this.facilityQuery.getAll())
      }

   
      const caseRequestInfo = this.caseRequestInfoQuery.getEntity(activePatient.bdosid);
      let frmData = new FormData();
      frmData.append(bdosid.serverValue, activePatient.bdosid.toString());
      frmData.append(facilityId.id, caseRequestInfo.dataPatient.facilityId?.toString());
      frmData.append(cpt.serverValue, caseRequestInfo?.dataCPTDetails[0]?.cptCode);

      this.setRequestFlagForBiller(implantLog, frmData, queueInfo);
      this.setRequestFlagForBiller(invoice, frmData, queueInfo);
      if (clientRole) {
        frmData.append(operatingReport.serverValue, this.getRequestFlagForClient(queueInfo, operatingReport.id));
      } else {
        frmData.append(operatingReport.serverValue, this.hasCurrentRequestType(operatingReport.id));
      }

      if (clientRole) {
        frmData.append(pathology.value, this.getRequestFlagForClient(queueInfo, pathology.id));
      } else {
        frmData.append(pathology.value, this.hasCurrentRequestType(pathology.id));
      }

      if (clientRole) {
        frmData.append(pricing.value, this.getRequestFlagForClient(queueInfo, pricing.id));
      } else {
        frmData.append(pricing.value, this.hasCurrentRequestType(pricing.id));
      }

      if (clientRole) {
        frmData.append(blockReport.serverValue, this.getRequestFlagForClient(queueInfo, blockReport.id));
      } else {
        frmData.append(blockReport.serverValue, this.hasCurrentRequestType(blockReport.id));
      }

      if (clientRole) {
        frmData.append(codingQuery.serverValue, this.getRequestFlagForClient(queueInfo, codingQuery.id));
      } else {
        frmData.append(codingQuery.serverValue, this.hasCurrentRequestType(codingQuery.id));
      }

      if (clientRole) {
        frmData.append(otherMisc.id, this.getRequestFlagForClient(queueInfo, otherMisc.id));
      } else {
        frmData.append(otherMisc.id, this.hasCurrentRequestType(otherMisc.id));
      }

      if (clientRole) {
        frmData.append(inCoding.serverValue, this.getRequestFlagForClient(queueInfo, inCoding.id));
      } else {
        const request = this.currentCaseRequestQuery.getEntity(inCoding.id);
        const isModified = request ? (request.isModified ? true : false) : false;
        if (isModified) {
          frmData.append(inCoding.serverValue, "false");
        } else {
          frmData.append(inCoding.serverValue, this.hasCurrentRequestType(inCoding.id));
        }
      }

      if (clientRole) {
        frmData.append(authReview.serverValue, this.getRequestFlagForClient(queueInfo, authReview.id));
      } else {
        const request = this.currentCaseRequestQuery.getEntity(authReview.id);
        const isModified = request ? (request.isModified ? true : false) : false;
        if (isModified) {
          frmData.append(authReview.serverValue, "false");
        } else {
          frmData.append(authReview.serverValue, this.hasCurrentRequestType(authReview.id));
        }
      }

      if (clientRole) {
        frmData.append(codingExpert.serverValue, this.getRequestFlagForClient(queueInfo, codingExpert.id));
      } else {
        const request = this.currentCaseRequestQuery.getEntity(codingExpert.id);
        const isModified = request ? (request.isModified ? true : false) : false;
        if (isModified) {
          frmData.append(codingExpert.serverValue, "false");
        } else {
          frmData.append(codingExpert.serverValue, this.hasCurrentRequestType(codingExpert.id));
        }
      }

      frmData.append("txtNotes", "");
      const nsnHighApprovalRequest = this.currentCaseRequestQuery.getEntity(highApprovalInternal.id);
      const isNsnHighApprovalModified = nsnHighApprovalRequest ? (nsnHighApprovalRequest.isModified ? true : false) : false;
      const isNsnHighApprovalApproved = nsnHighApprovalRequest ? (nsnHighApprovalRequest.isApproved ? true : false) : false;

      const cptArry = this.caseRequestCptQuery.getAllCpt();
      if (clientRole) {
        frmData.append("clientHigh", this.getRequestFlagForClient(queueInfo, highApproval.id));
        frmData.append("NSNHigh", this.getRequestFlagForClient(queueInfo, highApprovalInternal.id));
      } else {
        if (cptArry.length == 0) {
          frmData.append("clientHigh", "false");
          frmData.append("NSNHigh", "false");
        } else if (isNsnHighApprovalModified) {
          frmData.append("clientHigh", this.hasCurrentRequestType(highApproval.id));
          frmData.append("NSNHigh", "false");
        }
        else {
          frmData.append("clientHigh", this.hasCurrentRequestType(highApproval.id));
          frmData.append("NSNHigh", this.hasCurrentRequestType(highApprovalInternal.id));
        }
      }
      const cptVarArry = this.caseRequestCptQuery.getAllVariance();
      if (clientRole) {
        frmData.append("Variance", this.getRequestFlagForClient(queueInfo, variance.id));
      } else {
        if (cptVarArry.length == 0) {
          frmData.append("Variance", "false");
        }
        else {
          frmData.append("Variance", this.hasCurrentRequestType(variance.id));
        }
      }
      for (let d = 0; d < cptArry.length; d++) {
        frmData.append("DollarRequests[" + d + "].CPT", cptArry[d].cptCode);
        frmData.append("DollarRequests[" + d + "].CPTDesc", cptArry[d].cptDescription);
        frmData.append("DollarRequests[" + d + "].Cost", cptArry[d].rate.toString());
      }
      console.log(cptVarArry);
      for (let f = 0; f < (cptVarArry.length) / 2; f++) {
        const cptVarAtIndex = cptVarArry.filter(cptVar => cptVar.uiIndex === f);
        const nsnVarCpt = cptVarAtIndex.find(cptVarIndex => cptVarIndex.codingType === nsnCpt.id)
        const physicianVarCpt = cptVarAtIndex.find(cptVarIndex => cptVarIndex.codingType === physicianCpt.id)
        frmData.append("RequestedVariance[" + f + "].CPT", nsnVarCpt.cptCode);
        frmData.append("RequestedVariance[" + f + "].CPTDesc", nsnVarCpt.cptDescription);
        frmData.append("RequestedVariance[" + f + "].CPTAlt", physicianVarCpt.cptCode);
        frmData.append("RequestedVariance[" + f + "].CPTAltDesc", physicianVarCpt.cptDescription);
        //frmData.append("RequestedVariance[" + f + "].Cost", nsnVarCpt.rate.toString().valueOf() + physicianVarCpt.rate.toString().valueOf());
      }

      const currentRequestList = this.currentCaseRequestQuery.getAll();
      for (let e = 0; e < currentRequestList.length; e++) {
        console.log('currentRequestList', currentRequestList[e]);
        frmData.append("RFI[" + e + "]._requestType", currentRequestList[e].value);

        if (currentRequestList[e].comment) {
          frmData.append("RFI[" + e + "]._requestNote", currentRequestList[e].comment);
        }
        if (currentRequestList[e].fileName) {
          frmData.append("RFI[" + e + "]._requestFileName", currentRequestList[e].fileName);
        }
        if (currentRequestList[e].file) {
          frmData.append("RFI[" + e + "]._rfiFile", currentRequestList[e].file);
        }

      }

      if (this.currentCaseRequestQuery.hasEntity(highApproval.id) || this.currentCaseRequestQuery.hasEntity(highApprovalInternal.id)) {
        frmData.append("RequestDollarChanges", "true");
      }
      else {
        frmData.append("RequestDollarChanges", "false");
      }

      if (this.currentCaseRequestQuery.hasEntity(variance.id)) {
        frmData.append("RequestVarianceChanges", "true");
      }
      else {
        frmData.append("RequestVarianceChanges", "false");
      }


      if (isNsnHighApprovalModified) {
        frmData.append("IsHighApprovalInternal", "true");
      } else {
        frmData.append("IsHighApprovalInternal", this.currentCaseRequestQuery.hasEntity(highApprovalInternal.id) + "");
      }


      frmData.append("facilityName", activeFacility.name);
      frmData.append("MRN", activePatient.mrn);
      if (clientRole) {
        const currentCaseRequestHighApproval = this.currentCaseRequestQuery.getEntity(highApproval.id);
        if (currentCaseRequestHighApproval) {
          const statusHigh$$$ = currentCaseRequestHighApproval.isApproved ? "Approved" : "Rejected";
          frmData.append("RequestDollarStatus", statusHigh$$$);
          frmData.append("RequestDollarComments", currentCaseRequestHighApproval.comment);
        } else {
          frmData.append("RequestDollarStatus", "");
          frmData.append("RequestDollarComments", "");
        }
        const currentCaseRequestVariance = this.currentCaseRequestQuery.getEntity(variance.id);

        if (currentCaseRequestVariance) {
          const statusHighVariance = currentCaseRequestVariance.isApproved ? "Approved" : "Rejected";
          frmData.append("RequestVarianceStatus", statusHighVariance);
          frmData.append("RequestVarianceComments", currentCaseRequestVariance.comment);
        } else {
          frmData.append("RequestVarianceStatus", "");
          frmData.append("RequestVarianceComments", "");
        }
      } else {
        if (isNsnHighApprovalModified) {
          const statusHighInternal$$$ = isNsnHighApprovalApproved ? "Approved" : "Rejected";
          frmData.append("RequestDollarStatus", statusHighInternal$$$);
          frmData.append("RequestDollarComments", nsnHighApprovalRequest.comment);
        } else {
          frmData.append("RequestDollarStatus", "");
          frmData.append("RequestDollarComments", "");
        }

        frmData.append("RequestVarianceStatus", "");
        frmData.append("RequestVarianceComments", "");
      }

      this.getApiService.uploadFiles("BillingNew/Save", frmData).subscribe(res => {
        if (res.status == "Success") {
          this.toastr.success("Saved Successfully!!", "Success");
          //   this.onClickClose();
          this.closeEmitter.emit();
        }
        else {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });

    }
    else {
      return;
    }
  }
}
