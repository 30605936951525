<!-- automatic logout-->
<!-- <div> 
    <ngx-idle-timeout
      *ngIf="idleTimer"
      [allowedIdleTimeInSeconds]="10"
      [message]="'Your application is going to logout within 10 Sec !'"
      [warningTimeInSeconds]="10"
    >
    </ngx-idle-timeout>
   </div> -->
   <!-- Topbar -->
<nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button id="sidebarToggleTop" class="btn btn-link rounded-circle">
        <i class="fa fa-bars"></i>
    </button>
    <!-- Topbar Search -->
    <form class="d-none form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div class="input-group">
            <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                aria-label="Search" aria-describedby="basic-addon2">
            <div class="input-group-append">
                <button class="btn btn-primary" type="button">
                    <i class="fa fa-search fa-sm"></i>
                </button>
            </div>
        </div>
    </form>
    <div class="mr-auto font-weight-normal text-gray-800 text-font-size">

        <head style="display: block;">{{heading}}</head>
    </div>
    <!-- Search option on Dashboard -->
    
    <div class="col-md-4">
        <form [formGroup]="reactiveForm">
            <div class="input-group" *ngIf="navbar.patient" >
                <div class="form-outline"  >
                  <input id="search-button" (keyup.enter)="search(); navigateToPatients(searchRef.value)" type="search" autocomplete="off" formControlName="data"
                   onfocus="this.value=''" #searchRef placeholder="Patient/Facility/MRN"
                    class="form-control"/>
                    
                </div>
        
                <button id="search-button" type="button" [disabled]="reactiveForm.invalid" (click)="search(); navigateToPatients(searchRef.value)" class="col-sm"  class="btn btn-primary">
                  <i class="fas fa-search"></i>
                </button>
              </div>
    </form>
    </div>

    <div class="clock-space">
        <small id="clock"></small>
        <!-- <p class="datetime-dasbaord">Thur 7 May 5:30 PM</p> -->
    </div>
    <!-- Topbar Navbar -->
    <ul class="navbar-nav">
        <!-- Nav Item - Search Dropdown (Visible Only XS) -->
        <li class="nav-item dropdown no-arrow d-sm-none">
            <a class="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-search fa-fw"></i>
            </a>
            <!-- Dropdown - Messages -->
            <div class="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                aria-labelledby="searchDropdown">
                <form class="form-inline mr-auto w-100 navbar-search">
                    <div class="input-group">
                        <input type="text" class="form-control bg-light border-0 small" placeholder="Search for..."
                            aria-label="Search" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-primary" type="button">
                                <i class="fa fa-search fa-sm"></i>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </li>
        <!-- Nav Item - User Information -->
        <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                <i class="fa fa-user fa-color-black"></i><span
                    class="ml-2 d-none d-lg-inline text-gray-600 small">{{username}}</span><i
                    class="fa fa-caret-down ml-2"></i>
            </a>
            <!-- Dropdown - User Information -->
            <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <!-- <a class="dropdown-item" href="personel-info.html">
                    <i class="fa fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                    Profile
                </a> -->
                <a class="dropdown-item" routerLink="/change-password">
                    <i class="fa fa-key fa-sm fa-fw mr-2 text-gray-400"></i>
                    Password
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript:void(0)" data-toggle="modal" data-target="#logoutModal">
                    <i class="fa fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                    Logout
                </a>
            </div>
        </li>
    </ul>
</nav>
<!-- End of Topbar -->