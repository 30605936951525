import { Injectable } from '@angular/core';
import { SessionState, SessionStore } from './session.store';

@Injectable({ providedIn: 'root' })
export class SessionService {
    constructor(
        private sessionStore: SessionStore
    ) { }

    updateUser(sessionState: SessionState){
        this.sessionStore.update({ 
            username: sessionState.username,
            email: sessionState.email,
            role: sessionState.role,
            token: sessionState.token,
            fullName: sessionState.fullName
        })
    }

    destroy(){
        this.sessionStore.destroy();
    }
}