import { Component, OnInit ,EventEmitter ,Output} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/shared/services/api.service';
import { FacilityService } from 'src/app/shared/state/facilities/facility.service';
import { Observable, Subject } from 'rxjs';
import { Facility } from 'src/app/shared/state/facilities/facility.model';
import { FacilityQuery } from 'src/app/shared/state/facilities/facility.query';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auditlogs',
  templateUrl: './auditlogs.component.html',
  styleUrls: ['./auditlogs.component.css']
})
export class AuditlogsComponent implements OnInit {

  
  //Dependent Dropdown paramenters
  facilityID: any="0";
  patientId : number = 0;
  dateOfSurgery : any = "0";
  patients : any = [];
  facilities : any = [];
  dateOfSurgeries : any = [];
 
  auditLogs : any = [];

  //Table Paramenters
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  columns: any = ["Sno","Username","AuditDateTime","AuditEventType","AuditEventTypeDesription","UpdatedFrom"];
  p: number = 1;
  @Output() closeEmitter = new EventEmitter();

  FacilityList$: Observable<Facility[]>;

  constructor(
    private spinner: NgxSpinnerService,
    private facilityService: FacilityService,
    private facilityQuery: FacilityQuery,
    private getApiService: ApiService,
    private toastr: ToastrService,
    ) { }

  ngOnInit():void{
    this.spinner.show();
    this.facilityService.bindDashboardFacilityData().subscribe();
    this.FacilityList$ = this.facilityQuery.selectAll();
    this.getFacilitiesList();
  }

  getFacilitiesList(){
    this.getApiService.get('BillingNew/GetAllFacilites').subscribe(
      (res) => {
        if (res.status == 'Success') {
         this.facilities = res.facilities;
         this.spinner.hide();
          // Temp Graph Data Ends
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
      
  }

  getPatientsList(){
    this.spinner.show();
    let data = {
      Id: this.facilityID
    };
    if(this.facilityID < 1) {
      this.toastr.error("Please select facility to list patients.", 'Error!');
      this.spinner.hide();
      this.patients = [];
      return;
    }
    this.getApiService.post('BillingNew/GetPatientsListById', data).subscribe(
      (res) => {
        if (res.status == 'Success') {
        
         this.patients = res.patients;
         this.spinner.hide();
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );

  }

  getDateOfSurgeryList(){
    this.spinner.show();
    let data = {
      Id: this.patientId
    };

    this.getApiService.post('BillingNew/GetDateOfSurgeriesByPatientID',data).subscribe(
      (res) => {
        if (res.status == 'Success') {
         this.dateOfSurgeries = res.dateOfSurgeries;
         console.log(this.dateOfSurgeries);
        } else if (res.status == 'Error') {
          this.spinner.hide();
          if (res.message == 'Invalid User') {
            localStorage.clear();
            window.location.href = '/login';
          }
          this.toastr.error(res.message, 'Error!');
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error, 'Error!');
      }
    );
    this.spinner.hide();
  }

  onEntryChange(event) {
    let value = event.target.value;
    this.pageValue = value;
    this.pagenumber = 1;
    this.loadAuditData();
  }

  loadAuditData(){

    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue , "facilityID":this.facilityID , "patientID":this.patientId,"dateOfSurgery":this.dateOfSurgery };
    this.getApiService.post("BillingNew/GetAuditLogData", data).subscribe(res => {
      if (res.status == "Success") {
        this.auditLogs = res.auditLogs;
        this.totalRows = res.auditLogs[0]?.totalcount;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  pageChange(event){
    this.pagenumber = event;
    this.loadAuditData();
  }

}
