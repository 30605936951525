import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ColDef } from '../../models/col-def.model';
import { codingReview, codingReviewed, httpGetRequestMethod, httpPOSTRequestMethod, na, NameConstant } from '../../models/name-constant';
import { ApiService } from '../../services/api.service';
import { createNsnGridConfig, NsnGridConfig } from '../nsn-grid/nsn-grid.component.types';
import { AttributeConfig, createCustomAccordian, CustomAccordian, CustomAccordianConfig } from './custom-accordian.component.type';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-custom-accordian',
  templateUrl: './custom-accordian.component.html',
  styleUrls: ['./custom-accordian.component.css'],
})
export class CustomAccordianComponent implements OnInit, OnDestroy {
  @Input() callerId: NameConstant;
  @Input() config: CustomAccordianConfig;
  @Input() customAccordianConfigEvent: Observable<CustomAccordianConfig>;
  @Input() coloumns: ColDef[];
  @Output() showQAATFormEmitter = new EventEmitter<any>();
  @Output() showQAOPFormEmitter = new EventEmitter<any>();
  @Output() showQAICFormEmitter = new EventEmitter<any>();
  @Output() showToolsFormEmitter = new EventEmitter<any>();
  @Output() showCaseRequestFormEmitter = new EventEmitter<any>();
  @Output() onPageChange = new EventEmitter<any>();
  nsnGridConfigSubject: BehaviorSubject<NsnGridConfig>;
  customAccordianList: CustomAccordian[] = [];
  categorySub: Subscription;
  accordianSublist: Subscription [] = [];
  private customAccordianConfigEventSubscription: Subscription;
  
  constructor(
    private apiService: ApiService,
    private spinner :NgxSpinnerService,

  ) { }

  ngOnInit(): void {
    if(this.customAccordianConfigEvent){
      this.customAccordianConfigEventSubscription = this.customAccordianConfigEvent.subscribe( event => this.buildAccordian(event));
    }
    this.nsnGridConfigSubject = new BehaviorSubject<NsnGridConfig>(undefined);    
  }

  ngOnDestroy() {
    if(this.categorySub){
      this.categorySub.unsubscribe();
    }

    if(this.accordianSublist){
      this.accordianSublist.map(sub => {
        if(sub){
          sub.unsubscribe();
        }
      });
    }

    if(this.customAccordianConfigEventSubscription){
      this.customAccordianConfigEventSubscription.unsubscribe();
    }
  }

  buildAccordian(customAccordianConfig: CustomAccordianConfig){
    this.customAccordianList = [];
    if(!customAccordianConfig){
      console.log("config is not defined", customAccordianConfig);
      return;
    }

    if(!customAccordianConfig.categoryConfig){
      console.log("category config is not defined", customAccordianConfig.categoryConfig);
      return;
    }

    if(customAccordianConfig.categoryConfig.httpRequestType === httpPOSTRequestMethod){
      this.spinner.show();
      this.categorySub = this.apiService.post(customAccordianConfig.categoryConfig.api, customAccordianConfig.categoryConfig.body).subscribe(res => {
        if(!res || res.status !== 'Success'){
          this.spinner.hide();
          console.log("res of  api"+ customAccordianConfig.categoryConfig.api, res);
          return;
        }

        res.data.map(r => {
            console.log("building accordian for ", r);
            if(customAccordianConfig.httpRequestType === httpPOSTRequestMethod){
              this.spinner.show();
              let requestBody = { pageNum: "1", pageSize: "10", searchValue: customAccordianConfig.searchValue };
              customAccordianConfig.bodyAttributeMap.forEach((attrConfig: AttributeConfig, attrKey: string) => {
                if(attrKey){
                  console.log(attrKey,"attrkey")
                  if(attrConfig && attrConfig.isInputProvided){
                    requestBody[attrKey] = attrConfig.value;
                  }else{
                    requestBody[attrKey] = r[attrConfig.value];
                  }
                }
              });
              this.spinner.show()
              const tempSub = this.apiService.post(customAccordianConfig.api, requestBody).subscribe(acordianRes => {
                if(!acordianRes || acordianRes.status !== 'Success'){
                  this.spinner.hide();
                  console.log("acordianRes of  api"+ customAccordianConfig.api, acordianRes);
                  return;        
                }

                if(!acordianRes.data ||  acordianRes.data.length === 0){
                  this.spinner.hide();
                  console.log("no response for api"+ customAccordianConfig.api, requestBody);
                  return;
                }
                //const values = customAccordianConfig.accordianNameAttrKeyList.map( attr => acordianRes.data[0][attr]);
                const accordianName = this.buildAccordianName(customAccordianConfig.accordianNameAttrKeyMap, acordianRes.data[0], requestBody);
                const count = acordianRes.data[0][customAccordianConfig.accordianCountAttrKey];
                const id = requestBody[customAccordianConfig.accordianIdAttrKey];
                this.customAccordianList = [...this.customAccordianList, createCustomAccordian(id, accordianName, count, false, 1, customAccordianConfig.api, requestBody,  customAccordianConfig.httpRequestType)];
              });

              this.accordianSublist = [...this.accordianSublist, tempSub];
            }
        });
      }); 
    }else if(customAccordianConfig.categoryConfig.httpRequestType === httpGetRequestMethod){
      this.spinner.show();
      this.categorySub = this.apiService.get(customAccordianConfig.categoryConfig.api).subscribe(inres => {
        if(!inres || inres.status !== 'Success'){
          this.spinner.hide();
          console.log("inres of  api"+ customAccordianConfig.categoryConfig.api, inres);
          return;
        }

        let res;
        if(customAccordianConfig.categoryConfig.api === "InquiryStatus/BindInquiryStatusDropdown"){
        // if(true){  
        let coding = { ...inres.data[0]};
          coding.id = 'CodingExpert';
          coding.inquirystatus = 'CodingExpert';
          coding.priority = 2000;

          let incoding = { ...inres.data[0]};
          incoding.inquirystatus = 'InCoding';
          incoding.id = 'InCoding';
          incoding.priority = 1000;
          res = [ coding, incoding, ...inres.data ];
        }else{
          res = inres.data;
        }

        res.map(r => {
            console.log("building accordian for ", r);
            if(customAccordianConfig.httpRequestType === httpPOSTRequestMethod){
              let requestBody = { pageNum: "1", pageSize: "10", searchValue: customAccordianConfig.searchValue };
              let accordianAPi;
              if((customAccordianConfig.categoryConfig.api === "InquiryStatus/BindInquiryStatusDropdownWithCoding") && (r.id === 'CodingExpert' || r.id === 'InCoding')){
               console.log(customAccordianConfig,"consoloi")
               requestBody["PatientName"] =customAccordianConfig.searchValue;
                requestBody["CommandName"] = r.id;
                requestBody["ClientFacilityId"] = 0;
                accordianAPi = "BillingNew/GetPatientsByStatusNew";
              }else{
                customAccordianConfig.bodyAttributeMap.forEach((attrConfig: AttributeConfig, attrKey: string) => {
                  if(attrKey){
                    console.log(attrKey,"attrkey")
                    if(attrConfig && attrConfig.isInputProvided){
                      requestBody[attrKey] = attrConfig.value;
                    }else{
                      requestBody[attrKey] = r[attrConfig.value];
                    }
                  }
                });
                accordianAPi = customAccordianConfig.api;
              }
              this.spinner.show();
              const tempSub = this.apiService.post(accordianAPi, requestBody).subscribe(acordianRes => {
                // console.log(accordianAPi,requestBody)
                
                
                if(!acordianRes || acordianRes.status !== 'Success'){
                  console.log("acordianRes of  api"+ accordianAPi, acordianRes);
                  this.spinner.hide();
                  return;        
                }

                if(!acordianRes.data ||  acordianRes.data.length === 0){
                  console.log("no response for api"+ accordianAPi, requestBody);
                  this.spinner.hide();
                  return;
                }
                this.spinner.hide();
                //const values = customAccordianConfig.accordianNameAttrKeyList.map( attr => acordianRes.data[0][attr]);
                var accordianName = this.buildAccordianName(customAccordianConfig.accordianNameAttrKeyMap, acordianRes.data[0], requestBody);
                if(accordianName == "InCoding"){
                  accordianName = "In Coding"
                } else if(accordianName == "CodingExpert"){
                  accordianName = "Coding Expert"
                } 
                const count = acordianRes.data[0][customAccordianConfig.accordianCountAttrKey];
                const id = requestBody[customAccordianConfig.accordianIdAttrKey];
                const rank = customAccordianConfig.accordianRankAttrKey === na.id ? 1 : r[customAccordianConfig.accordianRankAttrKey];
                this.customAccordianList = [...this.customAccordianList, createCustomAccordian(id, accordianName, count, false, rank, accordianAPi, requestBody,  customAccordianConfig.httpRequestType)];
                this.customAccordianList =  this.customAccordianList.sort((a, b) => (a.rank > b.rank) ? 1 : -1);
                console.log("customAccordianList", this.customAccordianList);
              });

              this.accordianSublist = [...this.accordianSublist, tempSub];
              console.log("customAccordianList", this.customAccordianList);
            }
        });
      }); 
    }

  }

  buildAccordianName(accordianNameAttrKeyMap: Map<string, string>, acordianRes, acordianrequestBody): string {
    if(this.callerId === codingReview || this.callerId === codingReviewed){
      if(acordianrequestBody["CommandName"]){
        const accordianName =  acordianrequestBody["CommandName"];
        return accordianName;
      }
      if(!accordianNameAttrKeyMap.get("isPresentInRequest")){
        return acordianRes[accordianNameAttrKeyMap.get("name")];
      }else{
        return acordianrequestBody[accordianNameAttrKeyMap.get("name")];
      }
    }

    let name = '';
    accordianNameAttrKeyMap.forEach((value: string, key: string) => {
      if(name === ''){
        //first time
        name = key+": "+ acordianRes[value];
      }else{
        name = name+"  |  "+key+": "+ acordianRes[value];
      }
  });
  console.log("Accordian name ", name);
  return name;
  }
  onClickPlus(nsnAccordian: CustomAccordian){
    this.inactivateAll();
    nsnAccordian.isActive = true;
    const nsnGridConfig = createNsnGridConfig({ 
      api: nsnAccordian.api,
      apiBody: nsnAccordian.requestBody,
      method: nsnAccordian.method,
      isTotalItemKeyPresentInDataSource: true,
      totalItemKey: 'totalcount',
      responseRowKey: 'bdosid'
    } );
    this.nsnGridConfigSubject.next(nsnGridConfig);
  }

  private inactivateAll(){
    this.customAccordianList.map( acc => acc.isActive = false);
  }
  onClickMinus(nsnAccordian: CustomAccordian){
    nsnAccordian.isActive = false;
  }

  showQAOPFormModal(data) {
    this.showQAOPFormEmitter.emit(data);
  }

  showQAICFormModal(data) {
   this.showQAICFormEmitter.emit(data);
  }

  showQAATFormModal(data) {
    this.showQAATFormEmitter.emit(data);
  }

  showToolsFormModal(data) {
   this.showToolsFormEmitter.emit(data);
  }

  showCaseRequestFormModal(data){
    this.showCaseRequestFormEmitter.emit(data);
  }

  pageChange(event) {
    // if(!this.isDataSourceProvided){
    //   this.onPageChange.emit(event);
    // }else{
    //   this.currentPage = event;
    // }
  }
}
