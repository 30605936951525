<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between patient-header">
                    <div class="top-action-container">
                        <div class="item-facility">
                            <select class="custom-select dashboard-calnedar-patient1" style="width: 15rem; margin-right: 5rem;"
                            [(ngModel)]="activeFacility"
                            (ngModelChange)="onFacilityChange()" 
                            [compareWith]="compareFacility"> 
                            <option *ngFor="let f of (facilities$ | async)" [ngValue]="f">{{f.name}}</option>
                            </select>
                        </div>
                        <div class="item-category">
                            <select class="custom-select dashboard-calnedar-patient1" style="width: 200px;"
                            [(ngModel)]="activeCategoryItem"
                            (ngModelChange)="onCategoryItemChange()" 
                            [compareWith]="compareCategoryItem"> 
                            <option *ngFor="let c of (categoryItem$ | async)" [ngValue]="c">{{c.name}}</option>
                            </select>
                        </div>
                        <div class="item-bulk-action">

                        </div>
                        <div class="item-review-cases">
                            <button class="btn btn-primary btn-sm" type="submit"
                            (click)="startReviewCases()" [disabled]="(hasActivePatients$ | async) === false">Review
                            Cases</button>
                        </div>
                        <div class="item-month">

                        </div>
                        <div class="item-year">

                        </div>
                        <div class="item-blank"></div>
                        <div class="item-search">
                            <input id="searchPatient" [(ngModel)]="searchValue" type="text" name="searchPatient"
                            placeholder="Search Patient"
                            (keyup.enter)="searchPatient()"
                            >
                        </div>
                        <div class="item-export">
                            <a href="javascript:void(0)" (click)="exportData()"> <i class="fas fa-file-excel"></i></a>
                        </div>
                    </div>
                   
                </div>
                <div class="card-body">
                    <app-nsn-accordian *ngIf="(activeSelection$ | async) === defaultCategoryItemValue"
                    [skipCategory]="defaultCategoryItemValue"
                    [callerId]="componentId"
                    [isChildAccordian]= "false"
                    ></app-nsn-accordian>

                        <div class="table-responsive data-list" *ngIf="(activeSelection$ | async) !== defaultCategoryItemValue">
                            <table class="table" id="dataTable" width="100%" cellspacing="0">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Facility<i (click)="sortList('client', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('client', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>MRN <i (click)="sortList('mrn', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('mrn', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Patient <i (click)="sortList('name', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('name', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Provider</th> -->
                                        <th>DoS <i (click)="sortList('dateOfSurgery', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('dateOfSurgery', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Primary CPT</th> -->
                                        <th>Insurance <i (click)="sortList('insuranceProvider', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('insuranceProvider', 'desc')"
                                                class="fas fa-arrow-down"></i></th>
                                        <th>Active Queue</th>
                                        <th>Age <i (click)="sortList('timeCol', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('timeCol', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Comment</th>
                                        <th>Quick Access</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tfoot>
                                    <tr>
                                        <th>#</th>
                                        <th></th>
                                        <th>Facility <i (click)="sortList('client', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('client', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>MRN <i (click)="sortList('mrn', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('mrn', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Patient <i (click)="sortList('name', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('name', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Provider</th> -->
                                        <th>DoS <i (click)="sortList('dateOfSurgery', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('dateOfSurgery', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <!-- <th>Primary CPT</th> -->
                                        <th>Insurance <i (click)="sortList('insuranceProvider', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('insuranceProvider', 'desc')"
                                                class="fas fa-arrow-down"></i></th>
                                        <th>Active Queue</th>
                                        <th>Age <i (click)="sortList('timeCol', 'asc')"
                                                class="fas fa-arrow-up mr-1"></i>
                                            <i (click)="sortList('timeCol', 'desc')"
                                                class="fas fa-arrow-down"></i>
                                        </th>
                                        <th>Comment</th>
                                        <th>Quick Access</th>
                                        <th>Action</th>
                                    </tr>
                                </tfoot>
                                <tr
                                *ngFor='let data of (patients$ | async) | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: totalRows }; let i = index'>
            
                                <td>
                                    
                                    {{(i+1) + ((pagenumber-1)*pageValue)}} 
                                </td>
                                <td><input type="checkbox" (click)="selectRow(data)" [checked]="isRowActive(data)"></td>
                                <td data-toggle="tooltip" 
                                style="word-wrap: break-word;width: 200px;"
                                data-placement="top"
                                    title="{{(data?.client)}}">
                                    <a href="javascript:void(0)" (click)='showDetailModal(data)'>
                                    <!-- {{(data?.client?.length>20)? (data?.client | slice:0:23):(data?.client)}}<br>
                                    {{(data?.client?.length>20)? (data?.client | slice:23:40):""}}
                                    {{(data?.client?.length>40)? (data?.client | slice:40:40)+'...':""}} -->
                                        {{data?.client}}
                                </a>
                                </td>
                                <td>{{data?.mrn}}</td>
                                <td>{{data?.name}}<br>
                                    <!-- <span class="span-grey" *ngIf="data?.mrn != ''">
                                        MRN#</span> -->
                                </td>
            
                                <!-- <td></td> -->
                                <td>{{data?.dateOfSurgery | date: 'MM/dd/yyyy'}}<br>
                                    <span class="span-grey">{{data?.physician}}</span></td>
                                <!-- <td>{{data?.primartCPT}}</td> -->
                                <td data-toggle="tooltip" data-placement="top"
                                    title="{{(data?.insuranceProvider)}}">
                                    {{(data?.insuranceProvider?.length>15)? (data?.insuranceProvider | slice:0:15)+'..':(data?.insuranceProvider)}}<br>
                                    <span class="span-grey" *ngIf="data?.primartCPT != ''">
                                        CPT#{{data?.primartCPT}}</span>
                                </td>
                                <td><i title="In Coding" class="fa fa-code icon-color-blue mr-2"
                                        *ngIf="data?.inCoding > 0 || data?.returnedFor == 'In Coding'"></i>
                                    <i title="Returned Coding"
                                        class="fa fa-undo-alt icon-color-blue mr-2"
                                        *ngIf="data?.codingReturned > 0"></i>
                                    <i title="Returned"
                                        class="fa fa-caret-square-left icon-color-blue mr-2"
                                        *ngIf="data?.returned > 0"></i>
                                    <i title="Op Note"
                                        class="fa fa-envelope-open-text icon-color-blue mr-2"
                                        *ngIf="data?.opReport > 0 || data?.returnedFor == 'Op Note'"></i>
                                    <i title="Pathology"
                                        class="fa fa-clipboard-check icon-color-blue mr-2"
                                        *ngIf="data?.pathology > 0 || data?.returnedFor == 'Pathology'"></i>
                                    <i title="Coding" class="fa fa-undo-alt icon-color-blue mr-2"
                                        *ngIf="data?.coding > 0 || data?.returnedFor == 'Coding Query'"></i>
                                    <i title="Pricing"
                                        class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                        *ngIf="data?.pricing > 0 || data?.returnedFor == 'Pricing'"></i>
                                    <i title="High$$$"
                                        class="fa fa-dollar-sign icon-color-blue mr-2"
                                        *ngIf="data?.high$$$ > 0 || data?.returnedFor == 'NSN High$$$' || data?.returnedFor == 'Client High$$$'"></i>
                                    <i title="Block" class="fa fa-stop-circle icon-color-blue mr-2"
                                        *ngIf="data?.block > 0 || data?.returnedFor == 'Block Report'"></i>
                                    <i title="Variance"
                                        class="fa fa-exclamation-triangle icon-color-blue mr-2"
                                        *ngIf="data?.variance > 0 || data?.returnedFor == 'Variance'"></i>
                                    <i title="Other Misc"
                                        class="fa fa-info-circle icon-color-blue mr-2"
                                        *ngIf="data?.otherMisc > 0 || data?.returnedFor == 'Other/Misc'"></i>
                                    <i title="{{implantLog.value}}"
                                        class="fas fa-clipboard-list icon-color-blue mr-2"
                                        *ngIf="data?.implantLog > 0 || data?.returnedFor == implantLog.value"></i>
                                    <i title="{{invoice.value}}"
                                        class="fa fa-file-invoice-dollar icon-color-blue mr-2"
                                        *ngIf="data?.invoice > 0 || data?.returnedFor == invoice.value"></i>
                                    </td>
                                <td
                                data-toggle="tooltip" 
                                                    data-placement="top"
                                                    title="Import Date : {{(data?.importDate)}}"
                                >{{data?.timeCol}} <div *ngIf="data?.timeCol != null"> <span
                                            class="span-grey">Days</span></div>
                                </td>
                                <td data-toggle="tooltip" style="word-wrap: break-word;width: 200px;" data-placement="top"
                                    title="{{(data?.lastNote)}}">
                                    <!-- {{(data?.lastNote?.length>17)? (data?.lastNote | slice:0:17):(data?.lastNote)}}<br>
                                    {{(data?.lastNote?.length>17)? (data?.lastNote | slice:17:34):""}}
                                    {{(data?.lastNote?.length>34)? (data?.lastNote | slice:34:34)+'...':""}} -->
                                    {{(data?.lastNote?.length>0)? (data?.lastNote | slice:0:50)+'...':""}}
                                </td>
                                <td>
                                    <a href="javascript:void(0)" (click)="showQAATFormModal(data)"
                                        class="icon-color-blue openMySidenav"><i title="Attachments"
                                            class="fa fa-paperclip quickicn "></i></a>
                                    <a href="javascript:void(0)" (click)="showQAOPFormModal(data)"
                                        class="icon-color-darkgreen openMySidenav"><i
                                            title="Op Note"
                                            class="fa fa-sticky-note quickicn"></i></a>
                                    <a href="javascript:void(0)" (click)="showQAICFormModal(data)"
                                        class="icon-color-yellow openMySidenav"><i title="Coding"
                                            class="fa fa-file-code quickicn"></i></a>
                                    <a href="javascript:void(0)" (click)="showToolsFormModal(data)"
                                        class="icon-color-yellow openMySidenav"> <i title="Tools"
                                            class="fas fa-tools quickicn icon-color-pink"></i></a>
                                    <!-- <i class="fa fa-file-invoice quickicn icon-color-darkgreen"></i>
                                    <i class="fa fa-briefcase quickicn icon-color-yellow"></i>
                                    <i class="fa fa-laptop-code quickicn icon-color-pink"></i> -->
                                </td>
                                <td>
                                    <a [routerLink]="['/d/patientDtls', (data?.facilityId || 0), (data?.mrn || 0)]"
                                        class="icon-color-darkgreen openMySidenav mr-2"><i
                                            title="Patient View" class="fa fa-eye"></i></a>
                                    <a href="javascript:void(0)"
                                        class="icon-color-orange openMySidenav mr-2"><i
                                            class="fas fa-clock" title="Follow Up"
                                            (click)="showFormModal(data)"></i></a>
                                    <a href="javascript:void(0)"
                                        class="icon-color-orange openMySidenav"><i
                                            title="Action" class="fas fa-comment-alt"
                                            (click)="showCaseRequestFormModal(data)"></i></a>
                                </td>
                            </tr>
                                <tbody>
                                </tbody>
                            </table>
                            <pagination-controls class="float-right"
                            (pageChange)="pageChange($event); p = $event">
                            </pagination-controls>
                        </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-facility-details  [facility_id]="facility_id" *ngIf=shouldShowDetailModal (closeEmitter)="onCloseFormModal()">
</app-facility-details>

<!-- Sidenav-->
<app-bulk-action  *ngIf=shouldShowBulkActionModal (closeEmitter)="onCloseFormModal()">
</app-bulk-action>
<!-- Sidenav-->

<app-create-batch-review-cases *ngIf=shouldShowReviewCasesModal (closeEmitter)="onCloseFormModal()"></app-create-batch-review-cases>

<app-case-request [commandType]="activeCategoryItem.value" [formData]="formData" *ngIf="shouldShowCaseRequestFormModal"
    (closeEmitter)="onCloseFormModal()"></app-case-request>

<!-- Sidenav-->
<app-critical-item-action *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()" (submitEmitter) = "onSubmitSuccess()">
</app-critical-item-action>
<!-- Sidenav-->
<!-- Sidenav-->
<app-return-mail-action *ngIf=shouldShowReturnActionFormModal (closeEmitter)="onCloseFormModal()">
</app-return-mail-action>
<!-- Sidenav-->

<!-- Sidenav-->
<app-attachments-qa [formData]="formData" *ngIf=shouldShowQAATFormModal (closeEmitter)="onCloseFormModal()">
</app-attachments-qa>
<!-- Sidenav-->
<!-- Sidenav-->
<app-opnote-qa [formData]="formData" *ngIf=shouldShowQAOPFormModal (closeEmitter)="onCloseFormModal()">
</app-opnote-qa>
<!-- Sidenav-->
<!-- Sidenav-->
<app-incoding-qa [formData]="formData" *ngIf=shouldShowQAICFormModal (closeEmitter)="onCloseFormModal()">
</app-incoding-qa>
<!-- Sidenav-->
<!-- Sidenav-->
<app-tools-qa [formData]="formData" *ngIf=shouldShowToolsModal (closeEmitter)="onCloseFormModal()">
</app-tools-qa>
<!-- Sidenav-->