import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facility-group',
  templateUrl: './facility-group.component.html',
  styleUrls: ['./facility-group.component.css']
})
export class FacilityGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
