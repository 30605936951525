<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">{{headerName}}</h1>
                    <a href="javascript:void(0);" (click)='onClickAdd()' id="openMySidenav"
                        class="btn btn-primary btn-patient-box openMySidenav mr-4" role="button">
                        <i class="fas fa-plus fa-sm font-icn-color"></i>
                        {{addBtnLbl}}
                    </a>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_length"><label>Show <select name="myTable_length"
                                        aria-controls="dataTable" (change)="onEntryChange($event)"
                                        class="custom-select custom-select-sm form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries</label></div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div class="myTables_filter"><label>Search:<input type="search" [(ngModel)]="searchValue"
                                        class="form-control form-control-sm" (keyup.enter)="search()"
                                        placeholder="Queue Name"></label></div>
                            <!-- (keyup)="sortTable($event)" -->
                        </div>
                    </div>
                    <div class="table-responsive data-list" *ngIf="(customQueueList$ | async) as customQueueList">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th *ngFor="let col of columns">{{col.name}}
                                        <i *ngIf="col.sortable" class="fas fa-arrow-up mr-1"
                                            (click)="sortListQueue(col.sortFieldName,'asc')"></i>
                                        <i *ngIf="col.sortable" class="fas fa-arrow-down"
                                            (click)="sortListQueue(col.sortFieldName,'desc')"></i>
                                    </th>
                                </tr>
                            </thead>

                            <tfoot>
                                <tr>
                                    <th *ngFor="let col of columns">{{col.name}}
                                        <i *ngIf="col.sortable" class="fas fa-arrow-up mr-1"
                                            (click)="sortListQueue(col.sortFieldName,'asc')"></i>
                                        <i *ngIf="col.sortable" class="fas fa-arrow-down"
                                            (click)="sortListQueue(col.sortFieldName,'desc')"></i>
                                    </th>
                                </tr>
                            </tfoot>
                            <tbody>

                                <tr *ngFor='let customQueue of customQueueList | paginate: { itemsPerPage: pageValue, currentPage: p, totalItems: customQueueList[0]?.totalcount }; let i = index'>
                                    
                                    <td>{{customQueue.queueName}}</td>
                                    <td>{{customQueue.description}}</td>
                                    <td>
                                        <label class="custom-switch">
                                            <input type="checkbox" (click)="deleteQueue(customQueue)"
                                                [checked]="customQueue.isActive === 1">
                                            <span class="slider"></span>
                                        </label>
                                    </td>
                                    <td>
                                        <a href="javascript:void(0)" (click)="onEditRecord(customQueue)"
                                            class="btn btn-success icon-btn openMySidenav"><i
                                                class="fa fa-edit" title="Edit"></i></a>

                                    </td>
                                </tr>

                            </tbody>
                        </table>
                        <pagination-controls *ngIf="customQueueList.length > 0" class="float-right" (pageChange)="pageChange($event); p = $event">
                      </pagination-controls>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- /.container-fluid -->

<!-- Sidenav-->
<app-custom-queue-form  *ngIf="(shouldShowFormModal$ | async) === 'Add'" >
</app-custom-queue-form>
<app-custom-queue-edit-form  *ngIf="(shouldShowFormModal$ | async) === 'Edit'" >
</app-custom-queue-edit-form>
<!-- Sidenav-->