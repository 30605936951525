import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { isNullOrUndefined } from 'util';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as fileSaver from 'file-saver';
import { NgxSpinnerService } from 'ngx-spinner';
import { UniqueAccronymValidator } from '../../validators/unique-accronym.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-facility-master-form',
  templateUrl: './facility-master-form.component.html',
  styleUrls: ['./facility-master-form.component.css']
})
export class FacilityMasterFormComponent implements OnInit {
  config = {
    displayKey: "userName", //if objects array passed which key to be displayed defaults to description
    search: true, //true/false for the search functionlity defaults to false,
    height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
    placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
    customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
    limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
    moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
    noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
    searchPlaceholder: 'Search', // label thats displayed in search input,
    searchOnKey: 'userName', // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
    clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
    inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
  }
  p: number = 1;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  usernameList:any;
  searchValue: string = "";
  logo:any;
  imageUrl:string='';
  fullname:any;
  fileName:any;
 
  @ViewChild('addresstext') addresstext: any;
  submit:any="Submit"
  FacilityMasterForm: FormGroup;
  attachmentForm:FormGroup;
  CustomFieldForm:FormGroup;
  ContactDetailForm:FormGroup;
  notificationForm:FormGroup;
  @Output() addedEmitter = new EventEmitter();
  @Output() editEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  facilityGroupArry:any;
  fields:any;
  categoryAttachment:any;
  selectedValue=0;
  fieldArr=[];
  facilityfields:any;
  facilityGroupNew=[];
  facilityfieldArr=[];
  facilityGroup=[];
  categories=[];
  categoryArray:any;
  formFlag: string = "Add";
  _formData: any;
  phone: string;
  submitted = false;
  edit_id :any;
  user_id:any;
  returnEmailAdd: string = "";
  returnEmailMsg: string = "";
  loading: boolean;
  authreviewEmailAdd: string = "";
  authreviewEmailMsg: string = "";

  codingEmailAdd: string = "";
  codingEmailMsg: string = "";
  contactbtn:string="Add";
  updateShow:boolean =false;
  public phoneCodeMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public dolMask = [/[1-9]/, /\d/, /\d/,/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public tinCodeMask = ['(', /[1-9]/, /\d/, /\d/, ')', '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public tanCodeMask = [/[1-9]/, /\d/, /\d/, /\d/, /\d/, /\d/];
  fileAttachName: any;
  notificatioData: any = [];
  contactDetails:any;
  notificationDetails:any;
  baseUrl: string = environment.baseUrl;
  Notification:boolean = false;
  ReturnNotification:boolean = false;
  AuthReview:boolean = false;
  CodingExpert:boolean=false;
  High:boolean=false;
  notificationbtn = 'Add';
  updateNotificationShow = false;
  nuserName="";
  nemail="";
  phone_new_len:any;
  tollfree_new_len:any;
  fax_new_len:any;
  npi_new_len:any;
  tin_new_len:any;
  ptan_new_len:any;
  rrptan_new_len:any;
  dol_new_len :any;
  medicaid_new_len :any; 
  validatephone:boolean = false;
  validatetollfree:boolean = false;
  validatefax:boolean = false;
  validateNpi:boolean = false;
  validateTin:boolean = false;
  validatePtan:boolean = false;
  validateRRPtan:boolean = false;
  validateDol:boolean = false;
  validateMedicaid:boolean = false;
  
  src:any;
  @Input() set formData(value: any) {

    if (value != null && value != undefined) {
      this._formData = value;
      this.formFlag = "Edit";
      this.getData();
    }
    else {
      this.formFlag = "Add";
    }
  }

  constructor(
    private fb: FormBuilder,
    private getApiService: ApiService,
    private route : ActivatedRoute,
    private router : Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService) {
    this.fieldinitForm();
    this.attachmentinitForm();
    this.contactinitForm();
    this.notificationinitForm();
  }

  ngOnInit(): void { 
   
    //console.log();
    this.edit_id =this.route.snapshot.paramMap.get('id');
    if(this.edit_id != '' && this.edit_id != null && this.edit_id != undefined){
      this.formFlag = "Edit";
      
      this.getData();
    }else{
      this.formFlag = "Add";
    }
    
    this.initForm();
    this.getfacilityGroup();
    this.getCategories();
    this.getCustomFields();
    this.getContactDetails();
    this.getNotificationDetails();
    this.getFacilityCustomData();
    this.getFacilityAttachmentData();
    this.getfacilityDataGroup();
    this.getUserMaster();
  }
  validatephonefn(event){
    let phone_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    if(phone_new.length > 0 && phone_new.length !== 10 ){
      console.log(phone_new.length,"len")
      
      this.validatephone = true;
    }else{
      console.log(phone_new.length,"len11")
      this.validatephone = false;
    }
    
  }
  validatefaxfn(event){
    let fax_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    if(fax_new.length > 0 && fax_new.length < 10){
      this.validatefax = true;
     
    }else{
      this.validatefax = false;
    }
    
  }
  validatetollfreefn(event){
    let tollfree_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    if(tollfree_new.length > 0 && tollfree_new.length < 10){
      this.validatetollfree = true;
      
    }else{
      this.validatetollfree = false;
    }
    
  }
  validatenpifn(event){
    let npi_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    console.log('ddg');
    if(npi_new.length > 0 && npi_new.length < 10){
      this.validateNpi = true;
    
    }else{
      this.validateNpi = false;
    }
    
  }


  validatetinfn(event){
    let tin_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    if(tin_new.length > 0 && tin_new.length !== 9 ){
     
      this.validateTin = true;
      
    }else{
    
      this.validateTin = false;
    }
    
  }
  validateptanfn(event){
    let ptan_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    if(ptan_new.length > 0 && ptan_new.length < 6){
      this.validatePtan = true;
    
    }else{
      this.validatePtan = false;
    }
    
  }
  validaterrtanfn(event){
    let trrtan_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    if(trrtan_new.length > 0 && trrtan_new.length < 6){
      this.validateRRPtan = true;
     
    }else{
      this.validateRRPtan = false;
    }
    
  }
  validatedolfn(event){
    let dol_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
   
    if(dol_new.length > 0 && dol_new.length < 10){
      this.validateDol = true;
     
    }else{
      this.validateDol = false;
    }
    
  }

  validatemedicaidfn(event){
    let medi_new  = event.target.value.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
   
    if(medi_new.length > 0 && medi_new.length < 6){
      this.validateMedicaid = true;
     
    }else{
      this.validateMedicaid = false;
    }
    
  }
 
    

  getfacilityGroup() {
    let data = { };

    this.getApiService.post("FacilityGroup/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityGroupArry = resp.data;
         
      }

    }, (err) => {
      console.log(err);
    })
  }
  downloadFile(data){
    this.getApiService.downloadFile("FacilityMaster/DownloadFile?filename="+data.fileName).subscribe(response => {
			let blob:any = new Blob([response], { type: 'text/json; charset=utf-8' });
			const url = window.URL.createObjectURL(blob);
			fileSaver.saveAs(blob, data.fileName);
		}), error => console.log('Error downloading the file'),
                 () => console.info('File downloaded successfully');
   
    
  }
  getUserMaster() {
    let usernameListArr =[];
    let notificationArr = [];
    notificationArr = this.notificationDetails;
    let data = { "facilityID": this.edit_id};
    this.getApiService.post("FacilityNotification/BindUsers", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.usernameList = resp.data;
       
      }

    }, (err) => {
      console.log(err);
    })
  }
  selectionChanged(event) {
    if (event.value != undefined) {
      this._formData = event.value;
      console.log(event.value,'value ');
      this.notificationForm.controls["Name"].setValue(this._formData.fullName);
      this.fullname = this._formData.fullName;
      this.user_id = this._formData.id;
    }
    else {
      this.notificationinitForm();
    }

  }
  getfacilityDataGroup() {
    let id:any;
  //  this.facilityGroup.forEach(group => {
      // id =group.id;
      let data = {
        "pageNum":"1",
        "pageSize":"10"
        //"OrderBy":"",
        //"SortDir":""
    };
      this.getApiService.post("FacilityGroup/BindGridData",data).subscribe((resp: any) => {

        if (resp.status == "Success") {
          this.facilityGroupArry = resp.data;
          this.facilityGroupNew.push(this.facilityGroupArry);
      

        }

      }, (err) => {
        console.log(err);
      })
  
  }
  deleteContacts(id) {
  
    let data = { "id": id };

    this.getApiService.post("FacilityContact/DeleteFacilityContact", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
       // this.getFacilityCustomData();
        window.location.reload();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  deleteNotification(id) {
  
    let data = { "id": id };

    this.getApiService.post("FacilityNotification/DeleteFacilityNotification", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
       // this.getFacilityCustomData();
        window.location.reload();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  delCustomFieldMaster(id) {
  
    let data = { "id": id };

    this.getApiService.post("CustomField/DeleteFacilityCustomField", data).subscribe(res => {
      if (res.status == "Success") {
        this.toastr.success(res.message, 'Success!');
        this.getFacilityCustomData();
        window.location.reload();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  getCustomFields() {
    let data = { "pageNum": 1, "pageSize": 10 };

    this.getApiService.post("CustomField/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.fields = resp.data;
        this.fields.forEach(cat => {
          if(cat.isActive == 1){
            this.fieldArr.push(cat);
          }
        
        });
       console.log(this.fieldArr,"fieldArr");
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  getContactDetails() {
    let data = { "facilityID": this.edit_id };
    // if (this.searchValue != '' && this.pagenumber != 1) {
    //   this.pagenumber = 1;
    //   this.p = 1;
    // }
    
    //   data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue };
    this.getApiService.post("FacilityContact/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.contactDetails = resp.data;
       

      }

    }, (err) => {
      console.log(err);
    })
  }
  sortListContactDetails(orderBy,sortDir){
    //let  data = { "FacilityId":this.edit_id,"pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": this.searchValue,"OrderBy":orderBy,  "SortDir":sortDir };
    let  data = { "FacilityId":this.edit_id,"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("FacilityContact/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.contactDetails = resp.data;
      
      }
  }, (err) => {
    console.log(err);
  })
}
sortListNotificationDetails(orderBy,sortDir){
  let data = { "facilityID": this.edit_id,"OrderBy":orderBy,  "SortDir":sortDir };

  this.getApiService.post("FacilityNotification/BindGridData", data).subscribe((resp: any) => {
    // console.log(resp,'resp');
    if (resp.status == "Success") {
      this.notificationDetails = resp.data;
    }

  }, (err) => {
    console.log(err);
  })
}
sortListFacilityCustomData(orderBy,sortDir){
  let data = { "FacilityId":this.edit_id,"OrderBy":orderBy,  "SortDir":sortDir };
    this.getApiService.post("CustomField/GetFacilityCustomField", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityfields = resp.data;
      }

    }, (err) => {
      console.log(err);
    })
}
sortFacilityAttachmentData(orderBy,sortDir){
  let data = { "FacilityId":this.edit_id,"OrderBy":orderBy,  "SortDir":sortDir };
  this.getApiService.post("FacilityMaster/GetFacilityAttachment", data).subscribe((resp: any) => {
    if (resp.status == "Success") {
      this.categoryAttachment = resp.data;
    }

  }, (err) => {
    console.log(err);
  })
}
  getNotificationDetails(){
    let data = { "facilityID": this.edit_id };
    this.getApiService.post("FacilityNotification/BindGridData", data).subscribe((resp: any) => {
      // console.log(resp,'resp');
      if (resp.status == "Success") {
        this.notificationDetails = resp.data;
      }
    }, (err) => {
      console.log(err);
    })
  }

  contactinitForm(){
    this.ContactDetailForm = this.fb.group({
      id: ["",],
      Title: ["", [Validators.required]],
      Name: ["", [Validators.required]],
      Email:["", [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Phone:["", [Validators.required]]
    })
  }

  notificationinitForm(){
    this.notificationForm = this.fb.group({
      id: ["",],
      Email:["", [Validators.required]],
      Name: ["", ],
      Notification:["", ],
      ReturnNotification: ["", ],
      AuthReview:["", ],
      CodingExpert:["",],
      High:["",]
    })
  }

  fieldinitForm() {
    this.CustomFieldForm = this.fb.group({
      id: ["",],
      fieldName: ["", [Validators.required]],
      customValue: ["", [Validators.required]],
    })
  }

  attachmentinitForm() {
    this.attachmentForm = this.fb.group({
      id: ["",],
      Category: ["", [Validators.required]],
      Comment:["", [Validators.required]]
    })
  }

  editContacts(data){
    this.contactbtn = 'Update';
    this.updateShow = true;
    this.ContactDetailForm = this.fb.group({
      Id: [data.id],
      Title: [data.title, [Validators.required]],
      Name: [data.name, [Validators.required]],
      Email:[data.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Phone:[data.phone, [Validators.required]]
    })
  }

  editNotification(data){
    this.notificationbtn = 'Update';
    this.updateNotificationShow = true;
    this.notificationForm = this.fb.group({
      Id: [data.id],
       Name: [data.fullName,],
      // Email:[data.email, [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      Notification:[data.notification],
      ReturnNotification: [data.returnNotification, [Validators.required]],
      AuthReview:[data.authReview, [Validators.required]],
      CodingExpert:[data.codingExpert, [Validators.required]],
      High:[data.highDollar, [Validators.required]]
    })

    this.nuserName = data.email;
    this.nemail =data.email;
    this.user_id = data.userID;
    this.Notification= data.notification;
    this.ReturnNotification=data.returnNotification;
    this.CodingExpert = data.codingExpert;
    this.High = data.highDollar;
    this.AuthReview=data.authReview;
    // this.usernameList.push(data.email);
  }
  contactDetailSubmit(){
    this.submitted = true;
    if(this.ContactDetailForm.invalid){
      return;
    }
    let data:any;
    let Title = this.ContactDetailForm.controls["Title"].value;
    let Email= this.ContactDetailForm.controls["Email"].value;
    let Name = this.ContactDetailForm.controls["Name"].value;
    let Phone= this.ContactDetailForm.controls["Phone"].value;
    if (this.contactbtn == "Update") {
      let Id = this.ContactDetailForm.controls["Id"].value;
       data = {"FacilityId":this.edit_id,"Title" : Title,"Name":Name,"Email":Email,"Phone":Phone,"Id":Id}
    }else{
       data = {"FacilityId":this.edit_id,"Title" : Title,"Name":Name,"Email":Email,"Phone":Phone}
    }
  
  this.getApiService.post("FacilityContact/SaveFacilityContacts", data).subscribe((resp: any) => {
    if (resp.status == "Success") {
      window.location.reload();
      this.ContactDetailForm.reset();
      // this.closeEmitter.emit();
    }else{
      this.ContactDetailForm.reset();
      this.toastr.error(resp.message, 'Error!');
    }

  }, (err) => {
    console.log(err);
  })
  }
 
  notificationDetailSubmit(){
    this.submitted = true;
    let data:any;
    let notification:any;let returnNot:any;let authreview:any;let codingexpert:any;let highdoller:any;
    this.Notification?notification=1:notification=0;
    this.ReturnNotification?returnNot=1:returnNot=0;
    this.CodingExpert?codingexpert=1:codingexpert=0;
    this.High?highdoller=1:highdoller=0;
    this.AuthReview?authreview=1:authreview=0;
    if (this.notificationbtn == "Update") {
      let Id = this.notificationForm.controls["Id"].value;
      console.log("Notification",this.Notification,"ReturnNotification",returnNot,"AuthReview",authreview,"CodingExpert",codingexpert,"HighDollar",highdoller,"Id",Id);
      data = {"FacilityId":this.edit_id,"UserID":this.user_id,"Name":this.nuserName,"Email":this.nemail,"Notification":notification,"ReturnNotification":returnNot,"AuthReview":authreview,"CodingExpert":codingexpert,"HighDollar":highdoller,"Id":Id}
    }else{
      let Email= this.notificationForm.controls["Email"].value;
      let Name = this.notificationForm.controls["Name"].value;
      data = {"FacilityId":this.edit_id,"UserID":this.user_id,"Name":Name,"Email":Email,"Notification":notification,"ReturnNotification":returnNot,"AuthReview":authreview,"CodingExpert":codingexpert,"HighDollar":highdoller}
    }
    // let data = {"FacilityId":this.edit_id,"UserID":this.user_id,"Name":Name,"Email":Email,"Notification":notification,"ReturnNotification":returnNot,"AuthReview":authreview,"CodingExpert":codingexpert,"HighDollar":highdoller}
  
  
  this.getApiService.post("FacilityNotification/SaveFacilityNotification", data).subscribe((resp: any) => {
    if (resp.status == "Success") {
      this.notificationForm.reset();
      window.location.reload();
      // this.closeEmitter.emit();
    }else{
      this.notificationForm.reset();
      this.toastr.error(resp.message, 'Error!');
    }

  }, (err) => {
    console.log(err);
  })
  }

  attachmentFormSubmit(){
    const formdata = this.attachmentForm.getRawValue();
    let frmData = new FormData();
    if (this.fileAttachName != undefined && this.fileAttachName != []) {
      frmData.append("file", this.fileAttachName, this.fileAttachName.name);
    }
    frmData.append("FacilityId", this.edit_id);
    frmData.append("Comment", this.attachmentForm.controls["Comment"].value);
    frmData.append("CategoryId", this.attachmentForm.controls["Category"].value);

    this.getApiService.uploadFiles("FacilityMaster/SaveFacilityAttachment", frmData).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.attachmentForm.reset();
        window.location.reload();
      }
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })

    }, (err) => {
      console.log(err);
    })
  }
  fieldSubmit(){
    this.submitted = true;
    let fieldName = this.CustomFieldForm.controls["fieldName"].value;
    let fieldValue= this.CustomFieldForm.controls["customValue"].value;
    let data = {"FacilityId":this.edit_id,"FieldId" : fieldName,"FieldValue":fieldValue}
  this.getApiService.post("CustomField/SaveFacilityCustomField", data).subscribe((resp: any) => {
    if (resp.status == "Success") {
     
      this.CustomFieldForm.reset();
      window.location.reload();
      // this.closeEmitter.emit();
    }

  }, (err) => {
    console.log(err);
  })
  }
  getCategories() {
    let data = { "pageNum": 1, "pageSize": 100 };

    this.getApiService.post("FileCategory/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.categoryArray = resp.data;
        this.categoryArray.forEach(cat => {
          if(cat.isActive == 1){
            this.categories.push(cat);
          }
        
        });
       // console.log(this.categories,"catgories");
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  getFacilityCustomData() {
    let data = { "FacilityId":this.edit_id };
    this.getApiService.post("CustomField/GetFacilityCustomField", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.facilityfields = resp.data;
        console.log(this.facilityfields,"facilityfields");
      }

    }, (err) => {
      console.log(err);
    })
  }
  getFacilityAttachmentData() {
   // let data = { "pageNum": 1, "pageSize": 10 };
   let data = { "FacilityId":this.edit_id };
    this.getApiService.post("FacilityMaster/GetFacilityAttachment", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.categoryAttachment = resp.data;
        // this.facilityfields.forEach(el => {
        //   if(cat.isActive == 1){
        //     this.facilityfieldArr.push(cat);
        //   }
        
        // });
       console.log(this.categoryAttachment,"facilityfields");
        // this.selectedFacilities = resp.data;

      }

    }, (err) => {
      console.log(err);
    })
  }
  
  get f() { return this.FacilityMasterForm.controls; }
  //get g() { return this.CustomFieldForm.controls; }
  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  onSubmit() {
    // window.location.reload();
    this.submitted = true;
    this.loading = true;
  
    this.addRecord();

  }

  addRecord() {

    
    let phone = this.FacilityMasterForm.controls["txtPhone"].value;
    let fax = this.FacilityMasterForm.controls["txtFax"].value;
    let tollfree = this.FacilityMasterForm.controls["txtTollFree"].value;
    let npi = this.FacilityMasterForm.controls["txtNPI"].value;
    
    let txtTIN = this.FacilityMasterForm.controls["txtTIN"].value;
    let txtPTAN = this.FacilityMasterForm.controls["txtPTAN"].value;
    let txtRRPTAN = this.FacilityMasterForm.controls["txtRRPTAN"].value;
    let txtDOL = this.FacilityMasterForm.controls["txtDOL"].value;
    let txtMedicaid = this.FacilityMasterForm.controls["txtMedicaid"].value;
 

    let phone_new  = phone.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let fax_new  = fax.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let tollfree_new  = tollfree.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let npi_new  = npi.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");

    let ptan_new  = txtPTAN.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let tin_new  = txtTIN.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let rrtan_new  = txtRRPTAN.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let dol_new  = txtDOL.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");
    let medicaid_new  = txtMedicaid.replace(/[^\w\s]/gi, '').replace(/ /g,'').replace(/_/g, "");

    this.phone_new_len = phone_new.length; 
    this.tollfree_new_len = tollfree_new.length; 
    this.fax_new_len = fax_new.length; 
    this.npi_new_len = npi_new.length; 

    this.tin_new_len = tin_new.length; 
    this.ptan_new_len = ptan_new.length; 
    this.rrptan_new_len = rrtan_new.length; 
    this.dol_new_len = dol_new.length; 
    this.medicaid_new_len = medicaid_new.length; 

    console.log(this.phone_new_len,this.tollfree_new_len,this.npi_new_len, this.fax_new_len,this.tin_new_len ,this.ptan_new_len,this.rrptan_new_len,this.dol_new_len,this.medicaid_new_len,"length")
    this.validatephone =true;
    this.validatefax =true;
    this.validatetollfree =true;
    this.validateNpi = true;
    this.validateTin = true;
    this.validatePtan = true;
    this.validateRRPtan = true;
    this.validateDol = true;
    this.validateMedicaid = true;
    console.log(this.FacilityMasterForm,"data");
    if(this.phone_new_len > 0  && this.phone_new_len !== 10 ){
      return;
   
    }
    if(this.fax_new_len > 0 && this.fax_new_len !== 10 ){
      return;
  
    }
    if(this.tollfree_new_len > 0 && this.tollfree_new_len !== 10){
      return;
  
    }
    if(this.npi_new_len > 0 && this.npi_new_len !== 10){
      
      return;
  
    }

    if(this.tin_new_len > 0  && this.tin_new_len !== 9 ){
      return;
   
    }
    if(this.ptan_new_len > 0  && this.ptan_new_len !== 6 ){
      return;
   
    }
    if(this.rrptan_new_len > 0  && this.rrptan_new_len !== 6 ){
      return;
   
    }
    if(this.dol_new_len > 0  && this.dol_new_len !== 10 ){
      return;
   
    }
    if(this.medicaid_new_len > 0 && this.medicaid_new_len !== 6 ){
      return;
  
    }
    

    if (this.FacilityMasterForm.invalid) {
      return;
  }
    //const formdata = this.FacilityMasterForm.getRawValue();
    let frmData = new FormData();
    let textaddress1='';
    let txtCity1='';
    let txtState1='';
    let txtZip1='';
    
   // let time = Date.now() ;
    if (this.formFlag == "Edit") {
      
      frmData.append("Id", this.FacilityMasterForm.controls["Id"].value);
    }
    if (this.fileAttachName != undefined && this.fileAttachName != []) {
        frmData.append("File", this.fileAttachName, this.fileAttachName.name);
    }
    if(this.FacilityMasterForm.controls["txtCity"].value == undefined || this.FacilityMasterForm.controls["txtCity"].value == ''){
      txtCity1 ='';
    }else{
      txtCity1 = this.FacilityMasterForm.controls["txtCity"].value;
    }
    if(this.FacilityMasterForm.controls["txtZip"].value == undefined || this.FacilityMasterForm.controls["txtZip"].value == ''){
      txtZip1 ='';
    }else{
      txtZip1 = this.FacilityMasterForm.controls["txtZip"].value;
    }
    if(this.FacilityMasterForm.controls["txtState"].value == undefined || this.FacilityMasterForm.controls["txtState"].value == ''){
      txtState1 ='';
    }else{
      txtState1 = this.FacilityMasterForm.controls["txtState"].value;
    }
    if(this.FacilityMasterForm.controls["txtAddress1"].value == undefined || this.FacilityMasterForm.controls["txtAddress1"].value == ''){
      textaddress1 ='';
      // txtCity1='';
      // txtState1='';
      // txtZip1='';
    }else{
      textaddress1 =this.FacilityMasterForm.controls["txtAddress1"].value;
    }
    this.submit="Submiting"
    frmData.append("Name", this.FacilityMasterForm.controls["Name"].value);
    frmData.append("Deleted", (this.FacilityMasterForm.controls["Deleted"].value || 'false'));
    frmData.append("Region", this.FacilityMasterForm.controls["Region"].value);
    frmData.append("Abbr", this.FacilityMasterForm.controls["Abbr"].value);
    frmData.append("Acrnym", this.FacilityMasterForm.controls["Acrnym"].value);
    frmData.append("ShortName", this.FacilityMasterForm.controls["ShortName"].value);
    frmData.append("Coding", this.FacilityMasterForm.controls["Coding"].value);
    frmData.append("GroupId", this.FacilityMasterForm.controls["Group"].value);

    // frmData.append("createdDate", this.FacilityMasterForm.controls["CreatedDate"].value);
    // frmData.append("updatedDate", this.FacilityMasterForm.controls["UpdatedDate"].value);

    frmData.append("txtPhone", this.FacilityMasterForm.controls["txtPhone"].value);
    frmData.append("txtFax", this.FacilityMasterForm.controls["txtFax"].value);
    frmData.append("txtAddress1", textaddress1);
    frmData.append("txtAddress2", '');
    frmData.append("txtCity", txtCity1);
    frmData.append("txtState", txtState1);
    frmData.append("txtZip", txtZip1);
    frmData.append("txtTollFree", this.FacilityMasterForm.controls["txtTollFree"].value);
    frmData.append("txtNPI", this.FacilityMasterForm.controls["txtNPI"].value);
    frmData.append("txtTIN", this.FacilityMasterForm.controls["txtTIN"].value);
    frmData.append("txtPTAN", this.FacilityMasterForm.controls["txtPTAN"].value);
    frmData.append("txtRRPTAN", this.FacilityMasterForm.controls["txtRRPTAN"].value);
    frmData.append("txtDOL", this.FacilityMasterForm.controls["txtDOL"].value);
    frmData.append("txtMedicaid", this.FacilityMasterForm.controls["txtMedicaid"].value);
  
    
    this.getApiService.uploadFiles("FacilityMaster/SaveFacilities", frmData).subscribe((resp: any) => {
      if (resp.status == "Success") {
        
        if(this.formFlag == "Add"){
          this.FacilityMasterForm.reset();
        }
        this.toastr.success(resp.message, 'Success!');
       // window.location.reload();
      }
      // else if (resp.status == "Error") {
        
     
      //   this.toastr.error(resp.message, 'Error!');
      //  // window.location.reload();
      // }
   
     this.router.navigate(['/masters/facility-masters']);
      this.addedEmitter.emit({
        'status': true,
        'data': resp
      })
      

    }, (err) => {
      console.log(err);
    })
  }

  onCancel() {
    this.closeEmitter.emit();
  }

  initForm() {
    
    this.FacilityMasterForm = this.fb.group({
      Name: ["", [Validators.required]],
      Deleted: ["",],
      Region: ["",[Validators.required]],
      File: ["",],
      Abbr: ["",[Validators.required]],
      Acrnym: ["", [Validators.required], UniqueAccronymValidator.createValidator(this.getApiService)],
      Group:["", [Validators.required]],
      ShortName: ["",[Validators.required]],
      Coding: ["External",[Validators.required]],
      createdDate: ["",],
      updatedDate: ["",],
      txtPhone: ["",],
      txtFax: ["",],
      txtAddress1: ["",],
      txtAddress2: ["",],
      txtCity: ["", ],
      txtState: ["",],
      txtZip: ["",],
      txtTollFree: ["",],
      txtNPI: ["", ],
      txtTIN: ["", ],
      txtPTAN: ["",],
      txtRRPTAN: ["", ],
      txtDOL: ["",],
      txtMedicaid: ["", ],
  
      // txtRPName: ["", [Validators.required]],
      // txtRPEmail: ["", [Validators.required]],
      // txtRDName: ["", [Validators.required]],
      // txtRDEmail: ["", [Validators.required]],
      // txtAdminName: ["", [Validators.required]],
      // txtAdminEmail: ["", [Validators.required]],
      // txtBOMName: ["", [Validators.required]],
      // txtBOMEmail: ["", [Validators.required]],
      // txtBillingModel: ["", [Validators.required]],
      // txtContracts: ["", [Validators.required]],
      // txtCustomFieldName1: ["", [Validators.required]],
      // txtCustomFieldValue1: ["", [Validators.required]],
      // txtCustomFieldName2: ["", [Validators.required]],
      // txtCustomFieldValue2: ["", [Validators.required]],
      // txtCustomFieldName3: ["", [Validators.required]],
      // txtCustomFieldValue3: ["", [Validators.required]],
      // txtCustomFieldName4: ["", [Validators.required]],
      // txtCustomFieldValue4: ["", [Validators.required]],
      // txtCustomFieldName5: ["", [Validators.required]],
      // txtCustomFieldValue5: ["", [Validators.required]],
      // chkNSNHighApproval: ["", [Validators.required]],
      // txtNSNHighDollarEmail1: ["", [Validators.required]],
      // txtNSNHighDollarEmail2: ["", [Validators.required]],
      // txtNSNHighDollarEmail3: ["", [Validators.required]]
    });
    // if(this.isAcronymReadOnly()){
    //   this.FacilityMasterForm.controls["Acrnym"];
    // }
      //this.FacilityMasterForm.controls["Acrnym"].disable();
     
  }
  onClickClose() {
    this.closeEmitter.emit();
  }
  onc(){
    console.log(this.FacilityMasterForm)
  }

  fileChanged(event) {
    this.fileAttachName = event.target.files[0];
    let reader = new FileReader();
    reader.onload=(event:any)=>{
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileAttachName);
  }

  getData() {
   
   // let data = { "id": this._formData.id };
   let data = { "id": this.edit_id };
   
    this.getApiService.post("FacilityMaster/GetFacilityDetails", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        this.submit="Submiting"
       // this.imageUrl ='';
       console.log('Get Facility Details');
        // this.selectedValue = resp.data.groupId;
        if (resp.data.length > 0) {
         
          let dt = resp.data[0];
          this.logo = dt.logo;
          console.log('Fetch Data');
          console.log(dt);
          console.log(dt.coding);
          this.FacilityMasterForm = this.fb.group({
            Id: [dt.id, [Validators.required]],
            Name: [(dt.name || ''), [Validators.required]],
            Deleted: [(dt.deleted || ''), ],
            Region: [(dt.region || ''),[Validators.required]],
            Abbr: [(dt.abbr || ''), [Validators.required]],
            Acrnym: [(dt.acrnym || ''), [Validators.required]],
            Group:[(dt.groupId || ''), [Validators.required]],
            File: ["", ],
            ShortName: [(dt.shortName || ''), [Validators.required]],
            Coding:[(dt.coding || ''), [Validators.required]],
            createdDate: [(dt.createdDate || ''), ],
            updatedDate: [(dt.updatedDate || ''), ],
            txtPhone: [(dt.phone || ''), ],
            txtFax: [(dt.fax || ''),],
            txtAddress1: [(dt.address1 || ''), ],
            txtAddress2: [(dt.address2 || ''),],
            txtCity: [(dt.city || ''),],
            txtState: [(dt.state || ''), ],
            txtZip: [(dt.zip || ''), ],
            txtTollFree: [(dt.tollFree || ''), ],
            txtNPI: [(dt.npi || ''), ],
            txtTIN: [(dt.tin || ''),],
            txtPTAN: [(dt.ptan || ''), ],
            txtRRPTAN: [(dt.rrptan || ''),],
            txtDOL: [(dt.dol || ''), ],
            txtMedicaid: [(dt.medicaid || ''),],
            // txtRPName: [(dt.rpName || ''), [Validators.required]],
            // txtRPEmail: [(dt.rpEmail || ''), [Validators.required]],
            // rvpName: [(dt.rvpName || ''), [Validators.required]],
            // rvpEmail: [(dt.rvpEmail || ''), [Validators.required]],
            // txtRDName: [(dt.rdName || ''), [Validators.required]],
            // txtRDEmail: [(dt.rdEmail || ''), [Validators.required]],
            // txtAdminName: [(dt.adminName || ''), [Validators.required]],
            // txtAdminEmail: [(dt.adminEmail || ''), [Validators.required]],
            // txtBOMName: [(dt.bomName || ''), [Validators.required]],
            // txtBOMEmail: [(dt.bomEmail || ''), [Validators.required]],
            // txtBillingModel: [(dt.billingModel || ''), [Validators.required]],
            // txtContracts: [(dt.contracts || ''), [Validators.required]],
            // txtCustomFieldName1: [(dt.customFieldName1 || ''), [Validators.required]],
            // txtCustomFieldValue1: [(dt.customFieldValue1 || ''), [Validators.required]],
            // txtCustomFieldName2: [(dt.customFieldName2 || ''), [Validators.required]],
            // txtCustomFieldValue2: [(dt.customFieldValue2 || ''), [Validators.required]],
            // txtCustomFieldName3: [(dt.customFieldName3 || ''), [Validators.required]],
            // txtCustomFieldValue3: [(dt.customFieldValue3 || ''), [Validators.required]],
            // txtCustomFieldName4: [(dt.customFieldName4 || ''), [Validators.required]],
            // txtCustomFieldValue4: [(dt.customFieldValue4 || ''), [Validators.required]],
            // txtCustomFieldName5: [(dt.customFieldName5 || ''), [Validators.required]],
            // txtCustomFieldValue5: [(dt.customFieldValue5 || ''), [Validators.required]],
            // chkNSNHighApproval: [((dt.highApprovalInternal || false) || ''), [Validators.required]],
            // txtNSNHighDollarEmail1: [(dt.highApprovalEmail1 || ''), [Validators.required]],
            // txtNSNHighDollarEmail2: [(dt.highApprovalEmail2 || ''), [Validators.required]],
            // txtNSNHighDollarEmail3: [(dt.highApprovalEmail3 || ''), [Validators.required]]
          });
          this.fileName = dt.fileName;
          
          if( this.fileName != null){
            this.imageUrl = this.baseUrl+"/FacilityLogo/" + this.fileName;
          }else if(this.logo != null && this.fileName != null){
          
            this.imageUrl = this.baseUrl+"/FacilityLogo/" + this.fileName;
          }else if(this.logo != null && this.fileName == null){
           
            this.imageUrl = this.baseUrl+"/FacilityLogo/" + this.edit_id + ".jpg";
          }else{
          
            this.imageUrl ='';
          }
          this.getNotification("Return");
          this.getNotification("AuthReview");
          this.getNotification("CodingExpert");
          
        }
      }
    }, (err) => {
      console.log(err);
    })
  }

  getNotification(type) {

    if (type == "Return") {
      this.returnEmailAdd = "";
      this.returnEmailMsg = "";
    }
    else if (type == "AuthReview") {
      this.authreviewEmailAdd = "";
      this.authreviewEmailMsg = "";
    }
    else if (type == "CodingExpert") {
      this.codingEmailAdd = "";
      this.codingEmailMsg = "";
    }

    //let data = { "FacilityId": this._formData.id, "NotificationType": type };
    let data = { "FacilityId": this.edit_id, "NotificationType": type };
    this.getApiService.post("FacilityMaster/BindNotifications", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        console.log(resp.data.length);
        if (resp.data.length > 0) {

          if (type == "Return") {
            this.returnEmailAdd = resp.data[0].notificationEmailAddress;
            this.returnEmailMsg = resp.data[0].notificationMessage;
          }
          else if (type == "AuthReview") {
            this.authreviewEmailAdd = resp.data[0].notificationEmailAddress;
            this.authreviewEmailMsg = resp.data[0].notificationMessage;
          }
          else if (type == "CodingExpert") {
            this.codingEmailAdd = resp.data[0].notificationEmailAddress;
            this.codingEmailMsg = resp.data[0].notificationMessage;
          }
        }
      }
      else {
        this.toastr.error(resp.message, 'Error!');
      }
    }, (err) => {
      console.log(err);
    })
  }

  saveNotification(type) {
    let data = {};

    if (type == "Return") {
      data = {
        "FacilityId": this._formData.id,
        "EmailAdd": this.returnEmailAdd,
        "EmailMsg": this.returnEmailMsg,
        "Notification": type
      };
    }
    else if (type == "AuthReview") {
      data = {
        "FacilityId": this._formData.id,
        "EmailAdd": this.authreviewEmailAdd,
        "EmailMsg": this.authreviewEmailMsg,
        "Notification": type
      };
    }
    else if (type == "CodingExpert") {
      data = {
        "FacilityId": this._formData.id,
        "EmailAdd": this.codingEmailAdd,
        "EmailMsg": this.codingEmailMsg,
        "Notification": type
      };
    }

    this.getApiService.post("FacilityMaster/SaveNotifications", data).subscribe((resp: any) => {
      if (resp.status == "Success") {
        // this.toastr.success("Notification saved successfully", 'Success!');
      }
      else {
        this.toastr.error(resp.message, 'Error!');
      }
    }, (err) => {
      console.log(err);
    })
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement, {
      componentRestrictions: { country: 'US' },
      types: ['geocode']  // 'establishment' / 'address' / 'geocode'
    });
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      console.log(place)
      let emitData = {}
      for (let i = 0; i < place.address_components.length; i++) {
        var addressType = place.address_components[i].types[0];
        switch (addressType) {
          case 'street_number':
            emitData['address1'] = place.address_components[i]['short_name'] ;
            break;
          case 'route':
            emitData['address1'] = emitData['address1']+" "+place.address_components[i]['long_name'];
            break;
          case 'locality':
            emitData['city'] = place.address_components[i]['long_name'];
            break;
          case 'postal_code':
            emitData['zip'] = place.address_components[i]['short_name'];
            break;
          case 'administrative_area_level_1':
            emitData['state'] = place.address_components[i]['short_name'];
            break;
        }
      }
      this.FacilityMasterForm.controls['txtAddress1'].setValue(emitData['address1'])
      this.FacilityMasterForm.controls['txtAddress2'].setValue(emitData['address2'])
      this.FacilityMasterForm.controls['txtCity'].setValue(emitData['city'])
      this.FacilityMasterForm.controls['txtZip'].setValue(emitData['zip'])
      this.FacilityMasterForm.controls['txtState'].setValue(emitData['state'])
    })
  }

  isAcronymReadOnly(){
    return this.formFlag === "Edit";
  }
}
