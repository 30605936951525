import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-eod-archive',
  templateUrl: './eod-archive.component.html',
  styleUrls: ['./eod-archive.component.css']
})
export class EodArchiveComponent implements OnInit {
  FacilityList: any;
  archieveDateList: any;
  facilityID: any = 0;
  dateID: any = 0;
  facilityName: string = "";
  dateSelected: string = "";
  dataEODSummary: any = [];
  dataEODSummaryDetails: any = [];
  dataEODSummaryOnHold: any = [];
  PendingPatientList: any = [];
  variancettl = 0;
  varReason = "";
  confComments = "";
  Showfname = false;
  smryIsToggled = false;

  constructor(private getApiService: ApiService,
    private toastr: ToastrService,
    private commonUtil: CommonService,
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.facilityID = this.route.snapshot.params.id;
    this.getFacilityInfo();

  }

  getFacilityInfo() {
    this.getApiService.get("CreatePatient/BindFacilityData").subscribe(res => {
      if (res.status == "Success") {
        this.FacilityList = res.data;
        if (res.data[0] != undefined) {
          if (this.facilityID == 0) {
            this.facilityID = res.data[0].id;
          }
          let val = this.FacilityList.find(x => x.id == this.facilityID);
          this.facilityName = val.name;

        }
        this.getEODArchive();
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  onFacilityChange() {
    let val = this.FacilityList.find(x => x.id == this.facilityID);
    this.facilityName = val.name;
    this.getEODArchive();
    // this.getSummary();
  }

  getEODArchive() {

    let url = "EOD/BindEODArchiveDates";
    let data = {
      "facilityId": this.facilityID
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.archieveDateList = res.data;
        if (res.data[0] != undefined) {
          this.dateID = res.data[0].eoddate;
          this.getSummary();
        }
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }


  getSummary() {
    let url = "EOD/LoadCloseDayValues";
    let data = {
      "facilityId": this.facilityID,
      "EODdate": this.dateID
    }
    if (this.dateID != 0) {
      this.getApiService.post(url, data).subscribe(res => {
        if (res.status == "Success") {
          if (res.dataGrid.toString() != "{}") {
            this.dataEODSummary = res.dataGrid.table;
            this.dataEODSummaryDetails = res.dataGrid.table1;
            this.dataEODSummaryOnHold = res.dataGrid.table2;
            if (res.dataGrid.table[0] != undefined) {
              this.varReason = res.dataGrid.table[0].varianceReason;
              this.confComments = res.dataGrid.table[0].comments;
            }
            this.getPendingPatientList();

          }
        }
        else if (res.status == "Error") {
          this.toastr.error(res.message, 'Error!');
        }
      }, (error) => {
        this.toastr.error(error, 'Error!');
      });
    }
  }

  getPendingPatientList() {
    let url = "EOD/Get48HPendingPatientList";
    let data = {
      "facilityId": this.facilityID,
      // "EODdate": this.dateID
    }

    this.getApiService.post(url, data).subscribe(res => {
      if (res.status == "Success") {
        this.PendingPatientList = res.data48HPendingPatientList;
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }

  showPatientDetail() {
    this.smryIsToggled = !this.smryIsToggled;
  }

  getFile(fileId, fileName) {
    this.spinner.show();
    let data = { "BDOSID": fileId };

    this.getApiService.post4File("EOD/DownloadFile", data).subscribe(res => {

      if (res.type != "application/json") {
        saveAs(res, fileName);
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error, 'Error!');
    });
  }

}
