import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SessionService } from 'src/app/modules/login/session/session.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor(private router: Router,
    private toastr: ToastrService,
    private sessionService: SessionService
    ) { }

  ngOnInit(): void {
  }

  logout() {
    try {
      localStorage.clear();
      this.sessionService.destroy();
      window.location.href = "/login";
      // this.router.navigate(['/login']);
    }
    catch (e) {
      console.log(e);
    }
  }

}
