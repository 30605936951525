import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { ColDef } from '../../models/col-def.model';
import { codingReview, codingReviewed, httpPOSTRequestMethod, NameConstant } from '../../models/name-constant';
import { ApiService } from '../../services/api.service';
import { createNsnGridConfig, NsnGridConfig } from './nsn-grid.component.types';
import { createNsnGrid } from './state/nsn-grid.model';
import { NsnGridQuery } from './state/nsn-grid.query';
import { NsnGridService } from './state/nsn-grid.service';

@Component({
  selector: 'app-nsn-grid',
  templateUrl: './nsn-grid.component.html',
  styleUrls: ['./nsn-grid.component.css']
})
export class NsnGridComponent implements OnInit, OnDestroy {
  @Input() callerId: NameConstant;
  @Input() nsnGridConfigEvent: Observable<NsnGridConfig>;
  @Input() initNsnGridConfig: NsnGridConfig;
  @Input() coloumns: ColDef[];
  // this defined from where to show data if 
  // true means table data is provided as input if fals means data needs to be brinf from api based on config obj
  @Input() isDataSourceProvided: boolean; 
  @Input() dataSource: any[];
  private nsnGridConfigSubscription: Subscription;
  @Input() paginationControlId: string;
  @Output() closeEmitter = new EventEmitter();
  @Output() showQAATFormEmitter = new EventEmitter<any>();
  @Output() showQAOPFormEmitter = new EventEmitter<any>();
  @Output() showQAICFormEmitter = new EventEmitter<any>();
  @Output() showToolsFormEmitter = new EventEmitter<any>();
  @Output() showCaseRequestFormEmitter = new EventEmitter<any>();
  @Output() onPageChange = new EventEmitter<any>();
  // pagination controls
  currentPage: number = 1;  
  itemsPerPage: number = 10;
  totalRows: number;
  dataSourceSub: Subscription;
  nsnGridConfig: NsnGridConfig;
  constructor(
    private apiService: ApiService,
    private toaster: ToastrService,
    private nsnGridService: NsnGridService,
    private nsnGridQuery: NsnGridQuery
  ) { }

  ngOnInit(): void {
    console.log("Coloumn list ", this.coloumns);
    console.log("dataSource ", this.dataSource);
    console.log("isDataSourceProvided ", this.isDataSourceProvided);
    console.log(this.initNsnGridConfig,"grid config")
    this.nsnGridConfigEvent.subscribe(i=>{
      console.log(i,"i")
    })
    if(!this.isDataSourceProvided){
      // this.updateGrid(this.initNsnGridConfig);
      if(this.nsnGridConfigEvent){
        this.nsnGridConfigSubscription = this.nsnGridConfigEvent.subscribe( event => this.updateGrid(event));
        this.totalRows = this.dataSource?.length;
      }
    }else{
      this.totalRows = this.dataSource?.length;
    }
    console.log("totalRows", this.totalRows);
  }
  
  ngOnDestroy() {
    if(this.nsnGridConfigSubscription){
      this.nsnGridConfigSubscription.unsubscribe();
    }
    if(this.dataSourceSub){
      this.dataSourceSub.unsubscribe();
    }
  }

  updateGrid(nsnGridConfig: NsnGridConfig){
    if(!this.isDataSourceProvided){
      nsnGridConfig.apiBody.pageNum = this.currentPage ;
      console.log(this.currentPage)
      this.buildDataSource(nsnGridConfig);
    }
  }

  buildDataSource(nsnGridConfig: NsnGridConfig){
    console.log("Building Datasource using", nsnGridConfig);
    if(nsnGridConfig.method === httpPOSTRequestMethod){
      this.dataSourceSub = this.apiService.post(nsnGridConfig.api, nsnGridConfig.apiBody).subscribe( res=> {
        if(res.status === 'Success'){

          if(this.callerId === codingReview || this.callerId === codingReviewed){
            this.dataSource = res.data.map(d => { 
              const newData = { ...d };
              if(!newData.inquirystatus){
                newData.inquirystatus =  nsnGridConfig.apiBody.CommandName;
              }
              console.log(newData,"nsn-grid-data")
              return newData;
            });
          }else{
            this.dataSource = res.data;
          }
          this.setStore(this.dataSource, nsnGridConfig);
          console.log("dataSource", this.dataSource);
          // this.totalRows = this.dataSource.length;

          if(nsnGridConfig.isTotalItemKeyPresentInDataSource){
            if(res.data && res.data.length > 0){
              this.totalRows = res.data[0][nsnGridConfig.totalItemKey];
            }else{
              console.warn("unable to evaluate total count for pagination as server return empty list");
            }
          }else{
            this.toaster.error("please provide isTotalItemKeyPresentInObject and totalItemKey in gridConfig");
          }
        }
      });
    }
  }

  setStore(dataSource, nsnGridConfig: NsnGridConfig){
    this.nsnGridConfig = nsnGridConfig;
    const nsnGridEntityList = dataSource.map(d => createNsnGrid(d[nsnGridConfig.responseRowKey], d ));
    this.nsnGridService.set(nsnGridEntityList);
  }

  onClickCheckbox(data: any){
    console.log("onClickCheckbox", data);
    const id = data[this.nsnGridConfig.responseRowKey];
    if(this.nsnGridQuery.hasActive(id)){
      this.nsnGridService.removeActive(id);
    }else{
      this.nsnGridService.addActive(id);
    }
  }

  isRowChecked(data: any): boolean{
    //console.log("isRowChecked", data);
    return false;
  }

  onCloseFormModal(){
    
    this.closeEmitter.emit();
  }


  showQAOPFormModal(data) {
    this.showQAOPFormEmitter.emit(data);
  }

  showQAICFormModal(data) {
   this.showQAICFormEmitter.emit(data);
  }

  showQAATFormModal(data) {
    this.showQAATFormEmitter.emit(data);
  }

  showToolsFormModal(data) {
   this.showToolsFormEmitter.emit(data);
  }

  showCaseRequestFormModal(data){
    this.showCaseRequestFormEmitter.emit(data);
  }

  pageChange(event) {
    this.currentPage = event;
    if(!this.isDataSourceProvided){
      this.onPageChange.emit(event);
    }else{
      this.currentPage = event;
    }
    console.log(this.currentPage,"173")
    this.nsnGridConfigSubscription = this.nsnGridConfigEvent.subscribe( event => this.updateGrid(event));
  }

}
