<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
    <div id="mySidenav" class="sidenav shadow width-560 active">
        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-scale-pulse" [fullScreen]="false">
            <p style="color: white"> Loading... </p>
        </ngx-spinner>
        <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
            <h4 class="m-0 font-weight-bold text-primary">{{ (activeUser$ | async)?.userName }}</h4>
            <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav" id="closeMySidenav">
                <i class="fas fa-times fa-sm"></i>
            </a>
        </div>
        <div class="card-body">
            <form class="pb-3 form-fields" [formGroup]="CopyUserForm" *ngIf="CopyUserForm">
                <div class="row">
                    <div class="form-group col-12">
                        <label for="copyProfileTo" class="required">Copy profile to</label>
                        <div class="input-group">
                            <ngx-select-dropdown [config]="config" (change)="selectionChanged($event)"
                                [options]="usernameList$ | async" style="width: 100%;" formControlName="copyProfileTo"
                                autoClearSearch=" true" [multiple]="false">
                            </ngx-select-dropdown>
                        </div>
                    </div>
                </div>
                    <div class="table-responsive data-list">
                        <div class="row" style="margin-bottom: 10px !important;">
                            <div class="m-0 font-weight-bold col-6">Copy Queue</div>
                        </div>
                       
                        <div class="row" *ngFor="let elem of queue">
                            <div class="form-group col-6">
                                <label style="font-weight: normal;">{{elem.queueName}}</label>
                           </div>
                            <div class="form-group col-6">
                                <div class="input-group">
                                    <ngx-select-dropdown [config]="config" [options]="usernameList$ | async"
                                    style="width: 100%;" formControlName="{{elem.id}}" autoClearSearch=" true"
                                    [multiple]="false">
                                    </ngx-select-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-right">
                        <button type="button" (click)="onClickClose()"
                            class="btn btn-secondary mr-3 btn-patient-box closeMySidenav">Cancel</button>
                        <button type="button" (click)="openModal(template)" class="btn btn-primary btn-patient-box">Submit</button>
                    </div>
            </form>
        </div>
    </div>
</div>
<br><br>
<ng-template #template>
  <div class="modal-body text-center">
    <p>Do you want to confirm?</p>
    <button type="button" class="btn btn-default" (click)="confirm()" >Yes</button>
    <button type="button" class="btn btn-primary" (click)="decline()" >No</button>
  </div>
</ng-template>