<!-- Begin Page Content -->
<div class="container-fluid">
    <!-- Content Row -->
    <div class="row counters">
        <!-- Content Column -->
        <div class="col-12 mb-4">
            <!-- Project Card Example -->
            <div class="card shadow  mb-4">
                <!-- <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                    <h1 class="m-0 font-weight-bold text-primary head-font-size ml-4">{{facilityName}}
                        {{currentMonth}}</h1>
                    <select class="col-md-2 form-control closeFileSidenav mr-4" (change)="onChangeSelect($event)"
                        [(ngModel)]="commandtype">
                        <option *ngFor="let data of CategoryArry" value="{{data.value}}">{{data.name}}</option>
                    </select>

                </div> -->
                <div class="card-body">
                    <div class="table-responsive data-list">
                        <table class="table" id="dataTable" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Facility</th>
                                    <th>Patient</th>
                                    <th>MRN</th>
                                    <th>DoS</th>
                                    <th>Received</th>
                                    <th>FileName</th>
                                    <th>Quick Action</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tfoot>
                                <tr>
                                    <th>#</th>
                                    <th>Facility</th>
                                    <th>Patient</th>
                                    <th>MRN</th>
                                    <th>DoS</th>
                                    <th>Received</th>
                                    <th>FileName</th>
                                    <th>Quick Action</th>
                                    <th>Action</th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <a class="btn btn-danger mr-1" href="javascript:void(0)" data-toggle="modal"
                                            data-target="#removeModal">
                                            Remove
                                        </a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>ABCD</td>
                                    <td>Will Smith</td>
                                    <td>5645</td>
                                    <td>09/10/2020</td>
                                    <td>09/12/2020 6:15 AM</td>
                                    <td>1_ABCD_092220_2200_Smith.Will_OP.doc</td>
                                    <td>
                                        <i title="Exception" class="fa fa-exclamation-circle icon-color-blue mr-1"></i>
                                    </td>
                                    <td><button class="btn btn-secondary mr-1" (click)='showFormModal()'>Select</button>
                                        <button class="btn btn-danger mr-1">Remove</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-test-side-nav *ngIf=shouldShowFormModal (closeEmitter)="onCloseFormModal()"></app-test-side-nav>

<div class="modal fade" id="removeModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Remove File?</h5>
                <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                Remove file from exception queue?
            </div>
            <div class="modal-footer">
                <button class="btn btn-primary" type="button" data-dismiss="modal">Yes</button>
                <button class="btn btn-secondary" type="button" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<!-- /.container-fluid -->