<!-- Sidenav-->
<!-- Comment Section Sidenav-->
<div class="">
    <div class="bg-blur" (click)="onClickClose()"></div>
<div id="mySidenav1" class="sidenav shadow width-560 active">
    <div class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor">
        <h6 class="m-0 font-weight-bold text-primary">Betty Copper
            <span class="head-text-user">HITech Surgery Center</span>
        </h6>
        <a href="javascript:void(0);" (click)="onClickClose()" class="closeMySidenav1" id="closeMySidenav1">
            <i class="fas fa-times fa-sm"></i>
        </a>
    </div>
    <div class="card-body">
        <form class="pb-3 form-fields">
            <div class="container tab-remove">
                <ul class="nav nav-tabs nav-border patient-tab-pad">
                    <li class="user-menu-style"><a class="active" data-toggle="tab" href="#Patient">Patient</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Request">Request</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#High">High$$$</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Variance">Variance</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Notes">Notes</a></li>
                    <li class="user-menu-style"><a data-toggle="tab" href="#Attachments">Attachments</a></li>
                </ul>
                <div class="tab-content">
                    <div id="Patient" class="tab-pane fade in active show">
                        <div class="row">
                            <div class="form-group col-3">
                                <p class="patient-tab-above">DOS</p>
                                <p class="patient-tab1-below">01/22/2020</p>
                            </div>
                            <div class="form-group col-3">
                                <p class="patient-tab-above">DOS</p>
                                <p class="patient-tab1-below">01/22/2020</p>
                            </div>
                            <div class="form-group col-3">
                                <p class="patient-tab-above">DOS</p>
                                <p class="patient-tab1-below">01/22/2020</p>
                            </div>
                        </div>
                        <div class="row back-color-comnt">
                            <div class="form-group col-md-3">
                                <p class="patient-tab-above text-section-cmt">CPT</p>
                            </div>
                            <div class="form-group col-md-4">
                                <p class="patient-tab-above">CPT Descprtion</p>
                            </div>
                        </div>
                        <div class="row patient-center-space">
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <input type="checkbox" id="" name="" value="" class="billing-chkbox patient-chkbox">
                                <label for="inputFirstName" class="billing-lblbox">27535</label>
                                <p class="ipt1">Open treatment of tibial fracture, proximal (plateau);
                                    Unicondylar, includes internal fixation, when performed
                                </p>
                            </div>
                            <div class="form-group col-md-12 frm-margin-btm data-bottom">
                                <input type="checkbox" id="" name="" value="" class="billing-chkbox patient-chkbox">
                                <label for="inputFirstName" class="billing-lblbox">27535</label>
                                <p class="ipt1">Arthroscopy, knee, diagnostic, with or without synovial biopsy (separate
                                    procedure)</p>
                            </div>
                        </div>
                        <div class="row margin-bil">
                            <div class="col-md-9">
                                <p class="txt-billing-rle">Billing Rule Check (if applicable)</p>
                            </div>
                            <div class="col-md-3">
                                <button type="submit"
                                    class="btn btn-primary btn-patient-box btn_size_new patient-btn-color">APPROVE</button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-left">
                                    <button type="button"
                                        class="btn btn-secondary btn_size_new btn-patient-box patient-btn-color1 closeMySidenav mr-1"><i
                                            class="fa fa-times" aria-hidden="true"></i>
                                        CASE CANCELLED</button>
                                    <button type="button"
                                        class="btn btn-primary btn_size_new btn-patient-box patient-btn-color2"><i
                                            class="fa fa-check" aria-hidden="true"></i>
                                        SET BILLED</button>
                                </div>
                            </div>
                            <div class="col-md-6 btn-remove-pdng">
                                <div class="text-right">
                                    <button type="button" (click)="onClickClose()"
                                        class="btn btn-secondary btn-patient-box btn_size_new closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn_size_new btn-patient-box btn_size_new">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- tab 2-->
                    <div id="Request" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group frm-margin-btm col-md-12">
                                <div class="input-group">
                                    <select class="custom-select form-control">
                                        <option selected>Select Request</option>
                                        <option Value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                        <option value="4">Four</option>
                                        <option value="5">Five</option>
                                        <option value="6">Six</option>
                                        <option value="7">Seven</option>
                                        <option value="8">Eight</option>
                                        <option value="9">Nine</option>
                                        <option value="10">Ten</option>
                                    </select>
                                </div>
                            </div>
                            <div class="form-group frm-margin-btm col-md-12">
                                <label for="inputFirstName" class="lbl-font-600">Comments</label>
                                <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                    required></textarea>
                            </div>
                            <div class="form-group col-md-12">
                                <input type="file" class="form-control-file py-1" id="exampleFileUpload">
                            </div>
                        </div>





                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-left">
                                    <button type="button"
                                        class="btn btn-secondary btn-patient-box btn_size_new patient-btn-color1 closeMySidenav mr-1"><i
                                            class="fa fa-times" aria-hidden="true"></i>
                                        CASE CANCELLED</button>
                                    <button type="button"
                                        class="btn btn-primary btn-patient-box btn_size_new patient-btn-color2"><i
                                            class="fa fa-check" aria-hidden="true"></i>
                                        SET BILLED</button>
                                </div>
                            </div>
                            <div class="col-md-6 btn-remove-pdng">
                                <div class="text-right">
                                    <button type="button" (click)="onClickClose()"
                                        class="btn btn-secondary btn-patient-box btn_size_new closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                                </div>
                            </div>
                        </div>
                        <p class="comment-heading">History</p>
                        <div class="row history-btm-border">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-4">
                                <a href="#" class="approve-link-cmt"><i class="fa fa-check"
                                        aria-hidden="true"></i>Approve</a>
                                <a href="#" class="delete-link-cmt"><i class="fa fa-trash"
                                    title="Delete"   aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-4">
                                <a href="#" class="approve-link-cmt"><i class="fa fa-check"
                                        aria-hidden="true"></i>Approve</a>
                                <a href="#" class="delete-link-cmt"><i class="fa fa-trash"
                                    title="Delete"   aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                    </div>
                    <!-- tab 2 end-->
                    <!-- tab 3 -->
                    <div id="High" class="tab-pane fade">
                        <div class="row">
                            <div class="col-md-8">
                                <p class="high-section-textt">High Dollar Total</p>
                            </div>
                            <div class="col-md-4">
                                <p class="high-sect-amount">$1,500.00</p>
                            </div>
                        </div>
                        <div class="row highrow">
                            <div class="col-lg-3 col-md-6">
                                <p>CPT</p>
                            </div>
                            <div class="col-lg-5 col-md-6">
                                <p>CPT Descrption</p>
                            </div>
                            <div class="col-lg-2 col-md-6">
                                <p>$$$</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group remove-botttom-space align-input-user col-lg-3 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputCPTCode"
                                        aria-describedby="CPTCodeHelp" placeholder="CPT Code">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-5 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputProvider"
                                        aria-describedby="ProviderHelp" placeholder="There are many variations of">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputAmount"
                                        aria-describedby="AmountHelp" placeholder="$500.00">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                                <div class="input-group">
                                    <button type="submit" class="btn btn-primary btn-color-minus btn-patient-box btn_size_new"> <i
                                            class="fas fa-minus fa-sm font-icn-color"></i>Remove</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group remove-botttom-space align-input-user col-lg-3 col-md-6">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputCPTCode"
                                        aria-describedby="CPTCodeHelp" placeholder="CPT Code">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-5 col-md-6">

                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputProvider"
                                        aria-describedby="ProviderHelp" placeholder="There are many variations of">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">

                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputAmount"
                                        aria-describedby="AmountHelp" placeholder="$500.00">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">
                                <div class="input-group">
                                    <button type="submit" class="btn btn-primary btn-color-minus btn-patient-box btn_size_new"> <i
                                            class="fas fa-minus fa-sm font-icn-color"></i>Remove</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group remove-botttom-space align-input-user col-lg-3 col-md-6">
                                <div class="input-group">

                                    <input type="text" class="form-control" id="exampleInputCPTCode"
                                        aria-describedby="CPTCodeHelp" placeholder="CPT Code">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-5 col-md-6">

                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputProvider"
                                        aria-describedby="ProviderHelp" placeholder="There are many variations of">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">

                                <div class="input-group">
                                    <input type="text" class="form-control" id="exampleInputAmount"
                                        aria-describedby="AmountHelp" placeholder="$500.00">
                                </div>
                            </div>
                            <div class="form-group remove-botttom-space align-input-user col-lg-2 col-md-6">

                                <div class="input-group">
                                    <button type="submit" class="btn btn-primary btn-add-user btn-patient-box btn_size_new"> <i
                                            class="fas fa-plus fa-sm font-icn-color"></i>Add</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group frm-margin-btm col-md-12">
                                <label for="inputFirstName" class="lbl-font-600">High $$$ Request</label>
                                <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                    placeholder="Comment" required></textarea>
                            </div>
                            <div class="form-group col-md-12">
                                <input type="file" class="form-control-file py-1" id="exampleFileUpload">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-left">
                                    <button type="button"
                                        class="btn btn-secondary btn-patient-box btn_size_new patient-btn-color1 closeMySidenav mr-1"><i
                                            class="fa fa-times" aria-hidden="true"></i>
                                        CASE CANCELLED</button>
                                    <button type="button"
                                        class="btn btn-primary btn-patient-box btn_size_new patient-btn-color2"><i
                                            class="fa fa-check" aria-hidden="true"></i>
                                        SET BILLED</button>
                                </div>
                            </div>
                            <div class="col-md-6 btn-remove-pdng">
                                <div class="text-right">
                                    <button type="button" (click)="onClickClose()"
                                        class="btn btn-secondary btn-patient-box btn_size_new closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                                </div>
                            </div>
                        </div>
                        <p class="comment-heading">History</p>
                        <div class="row">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-4">
                                <a href="#" class="approve-link-cmt"><i class="fa fa-check"
                                        aria-hidden="true"></i>Approve</a>
                                <a href="#" class="delete-link-cmt"><i  title="Delete"
                                        aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                        <div class="col-md-12 comment-mrg">
                            <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                placeholder="Approve Comments" required></textarea>
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                        </div>
                    </div>
                    <!-- tab 3 end-->
                    <!-- tab 4 -->
                    <div id="Variance" class="tab-pane fade">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row back-color-comnt">
                                    <div class="form-group col-md-3">
                                        <p class="patient-tab-above">CPT</p>
                                    </div>
                                    <div class="form-group col-md-9">
                                        <p class="patient-tab-above variance-text">CPT Descprtion</p>
                                    </div>
                                </div>
                                <div class="row variance-row-mrg">
                                    <div class="form-group col-md-12 frm-margin-btm data-bottom">

                                        <label for="inputFirstName" class="billing-lblbox">27535</label>
                                        <p class="ipt1">It is long established fact that</p>
                                    </div>
                                    <div class="form-group col-md-12 frm-margin-btm data-bottom">

                                        <label for="inputFirstName" class="billing-lblbox">27535</label>
                                        <p class="ipt1">It is long established fact that</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row back-color-comnt">
                                    <div class="form-group col-md-3">
                                        <p class="patient-tab-above">CPT</p>
                                    </div>
                                    <div class="form-group col-md-9">
                                        <p class="patient-tab-above">CPT Descprtion</p>
                                    </div>
                                </div>
                                <div class="row variance-row-mrg">
                                    <div class="form-group col-md-12 frm-margin-btm data-bottom">

                                        <label for="inputFirstName" class="billing-lblbox">27535</label>
                                        <p class="ipt1">It is long established fact that</p>
                                    </div>
                                    <div class="form-group col-md-12 frm-margin-btm data-bottom">

                                        <label for="inputFirstName" class="billing-lblbox">27535</label>
                                        <p class="ipt1">It is long established fact that</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group frm-margin-btm col-md-12">
                                <label for="inputFirstName">Variance Request</label>
                                <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                    required></textarea>
                            </div>
                            <div class="form-group frm-margin-btm col-md-12">
                                <input type="file" class="form-control-file py-1" id="exampleFileUpload">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-left">
                                    <button type="button"
                                        class="btn btn-secondary btn-patient-box btn_size_new patient-btn-color1 closeMySidenav mr-1"><i
                                            class="fa fa-times" aria-hidden="true"></i>
                                        CASE CANCELLED</button>
                                    <button type="button"
                                        class="btn btn-primary btn-patient-box btn_size_new patient-btn-color2"><i
                                            class="fa fa-check" aria-hidden="true"></i>
                                        SET BILLED</button>
                                </div>
                            </div>
                            <div class="col-md-6 btn-remove-pdng">
                                <div class="text-right">
                                    <button type="button" (click)="onClickClose()"
                                        class="btn btn-secondary btn-patient-box btn_size_new closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                                </div>
                            </div>
                        </div>

                        <p class="comment-heading">History</p>
                        <div class="row">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-8">
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-4">
                                <a href="#" class="approve-link-cmt"><i class="fa fa-check"
                                        aria-hidden="true"></i>Approve</a>
                                <a href="#" class="delete-link-cmt"><i class="fa fa-trash"
                                    title="Delete"    aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                        <div class="col-md-12 comment-mrg">
                            <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                placeholder="Approve Comments" required></textarea>
                        </div>
                        <div class="text-right">
                            <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                        </div>
                    </div>
                    <!-- tab 4 end-->
                    <!-- tab 5 -->
                    <div id="Notes" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group frm-margin-btm col-md-12">
                                <label for="inputFirstName">Add Note</label>
                                <textarea class="form-control" id="name" aria-describedby="FirstNameHelp"
                                    required></textarea>
                            </div>
                            <div class="form-group frm-margin-btm variance-lbl col-md-4">
                                <input type="radio" id="hold" name="hold" value="Private">
                                <label for="private">Private</label>
                                <input type="radio" id="reject" name="reject" value="Public">
                                <label for="public">Public</label><br>
                            </div>
                            <div class="form-group frm-margin-btm col-md-8">
                                <div class="input-group">
                                    <select class="custom-select form-control">
                                        <option selected>Select Request</option>
                                        <option Value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                        <option value="4">Four</option>
                                        <option value="5">Five</option>
                                        <option value="6">Six</option>
                                        <option value="7">Seven</option>
                                        <option value="8">Eight</option>
                                        <option value="9">Nine</option>
                                        <option value="10">Ten</option>
                                    </select>
                                </div>
                            </div>

                            <div class="form-group frm-margin-btm col-md-12">
                                <input type="file" class="form-control-file py-1" id="exampleFileUpload">
                            </div>
                        </div>



                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-left">
                                    <button type="button"
                                        class="btn btn-secondary btn-patient-box btn_size_new patient-btn-color1 closeMySidenav mr-1"><i
                                            class="fa fa-times" aria-hidden="true"></i>
                                        CASE CANCELLED</button>
                                    <button type="button"
                                        class="btn btn-primary btn-patient-box btn_size_new patient-btn-color2"><i
                                            class="fa fa-check" aria-hidden="true"></i>
                                        SET BILLED</button>
                                </div>
                            </div>
                            <div class="col-md-6 btn-remove-pdng">
                                <div class="text-right">
                                    <button type="button" (click)="onClickClose()"
                                        class="btn btn-secondary btn-patient-box btn_size_new closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                                </div>
                            </div>
                        </div>
                        <p class="comment-heading">History</p>
                        <div class="row history-btm-border">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-9">
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-3">
                                <a href="#" class="delete-link-cmt float-del-right"><i class="fa fa-trash"
                                    title="Delete"     aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-9">
                                <p class="history-filelnk"><i class="fa fa-paperclip" aria-hidden="true"></i>File Name
                                </p>
                            </div>
                            <div class="col-md-3 ">

                                <a href="#" class="delete-link-cmt float-del-right"><i class="fa fa-trash"
                                    title="Delete"   aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                    </div>
                    <!-- tab 5 end-->
                    <!-- tab 6 -->
                    <div id="Attachments" class="tab-pane fade">
                        <div class="row">
                            <div class="form-group frm-margin-btm col-md-12">
                                <h4 class="my-3">Upload File</h4>
                                <input type="file" class="form-control-file py-1" id="exampleFileUpload">
                            </div>
                            <div class="form-group frm-margin-btm col-md-12">
                                <div class="input-group">
                                    <select class="custom-select form-control">
                                        <option selected>Select Request</option>
                                        <option Value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                        <option value="4">Four</option>
                                        <option value="5">Five</option>
                                        <option value="6">Six</option>
                                        <option value="7">Seven</option>
                                        <option value="8">Eight</option>
                                        <option value="9">Nine</option>
                                        <option value="10">Ten</option>
                                    </select>
                                </div>
                            </div>

                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="text-left">
                                    <button type="button"
                                        class="btn btn-secondary btn-patient-box btn_size_new patient-btn-color1 closeMySidenav mr-1"><i
                                            class="fa fa-times" aria-hidden="true"></i>
                                        CASE CANCELLED</button>
                                    <button type="button"
                                        class="btn btn-primary btn-patient-box btn_size_new patient-btn-color2"><i
                                            class="fa fa-check" aria-hidden="true"></i>
                                        SET BILLED</button>
                                </div>
                            </div>
                            <div class="col-md-6 btn-remove-pdng">
                                <div class="text-right">
                                    <button type="button" (click)="onClickClose()"
                                        class="btn btn-secondary btn-patient-box btn_size_new closeMySidenav mr-1">Cancel</button>
                                    <button type="submit" class="btn btn-primary btn-patient-box btn_size_new">Submit</button>
                                </div>
                            </div>
                        </div>
                        <p class="comment-heading">History</p>
                        <div class="row history-btm-border">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-9">
                                <p class="history-filelnk"><i class="fa fa-paperclip"
                                        aria-hidden="true"></i>facilityName_Opnote_0112011.pdf</p>
                            </div>
                            <div class="col-md-3">
                                <a href="#" class="delete-link-cmt float-del-right"><i class="fa fa-trash"
                                    title="Delete"     aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-9">
                                <p class="history-head">Nick La</p>
                                <p class="history-dttttime">Mon 12 Feb 2:30 PM</p>
                            </div>
                            <div class="col-md-3">
                                <p class="histor-text-right"><i class="fa fa-tag" aria-hidden="true"></i> OpNote</p>
                            </div>
                            <div class="col-md-12">
                                <p class="history-detailed">Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry.
                                    Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when
                                    an unknown printer
                                    took a galley of type and scrambled it to make a type specimen book.
                                </p>
                            </div>
                            <div class="col-md-9">
                                <p class="history-filelnk"><i class="fa fa-paperclip"
                                        aria-hidden="true"></i>facilityName_Opnote_0112011.pdf</p>
                            </div>
                            <div class="col-md-3">

                                <a href="#" class="delete-link-cmt float-del-right"><i class="fa fa-trash"
                                    title="Delete"  aria-hidden="true"></i>Delete</a>
                            </div>
                        </div>


                    </div>
                    <!-- tab 6 end-->
                </div>
            </div>
        </form>
    </div>
</div>
<!-- Comment Section Sidenav -->