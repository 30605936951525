import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApiService } from 'src/app/shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import * as fileSaver from 'file-saver';
import {CurrencyPipe} from '@angular/common'
import { Observable } from 'rxjs';
import { BillReason } from 'src/app/modules/masters/undo-setbill-reason/state/bill-reason.model';
import { BillReasonService } from 'src/app/modules/masters/undo-setbill-reason/state/bill-reason.service';
import { BillReasonQuery } from 'src/app/modules/masters/undo-setbill-reason/state/bill-reason.query';
@Component({
  selector: 'app-claim-type-action',
  templateUrl: './claim-type-action.component.html',
  styleUrls: ['./claim-type-action.component.css']
})
export class ClaimTypeActionComponent implements OnInit {
  config;
  billReasonList$: Observable<BillReason[]>;
  
  @Output() closeEmitter = new EventEmitter();
  @Output() addedEmitter = new EventEmitter();
  _formData:any;
  @Input() set formData(value: any) {
    if (value) {
     
      this._formData = value;
    }
  }
  billReasonSubmitted: boolean = false;
  p: number = 1;
  pageValue: any = 10;
  totalRows: any;
  pagenumber: any = 1;
  snoList: any = 1;
  searchValue: string = "";
  billingRulesList:any;
  overrideClaim:boolean=false;
  showClaimConfirmation:boolean =false;
  showUpload:boolean = false;
  claims=[];
  showOverrideReason:boolean = false;
  billingRuleDetails:boolean =false;
  noValue:boolean = false;
  checked:boolean = false;
  claim_type='';
  setBilled:any;
  fileAttachName=[];
  checkedCheckbox=[];
  userName='';
  claimConfirmation:any;
  claimValue:any;
  claimType:any;
  showpaperclaim:boolean =false;
  setBilledForm:FormGroup;
  reasonForm:FormGroup;
  paperclaim=[];
  claimResponse=[];
  showbutton:boolean = true;
  showConfirmation:boolean = false;
  showSecondConfirmation:boolean = false;
  validateFile:boolean = false;
  validateFileMsg:any;
  constructor(private fb: FormBuilder,
    private getApiService: ApiService,
    private toastr: ToastrService,
    private currencyPipe:CurrencyPipe,
    private billReasonQuery: BillReasonQuery,
    private billReasonService: BillReasonService
   ) { }

  ngOnInit(): void {
    //this.getClaims();
    this.initForm(); 
    this.initReasonForm();
    this.getBilled();
    this.getAttachment();
    this.config = this.initialiseDropDownConfig('reason');
    this.billReasonService.setStoreForDropdown().subscribe(res => console.log('Loading dropdown...'));
    this.billReasonList$ = this.billReasonQuery.selectAll();
  }
  get f() { return this.setBilledForm.controls; }
  initForm() {
    this.setBilledForm = this.fb.group({
      BilledId: ["",],
      checkbill:["",],
      ClaimType: ["",],
      OverrideReason: ["", ],
      ClaimConfirmation: ["",],
      fileName: ["",],
      PaperClaim: ["", ],
      Charges: ["", [Validators.required]],
    })
  }

  initReasonForm() {
    this.reasonForm = this.fb.group({
      reason: ["",[Validators.required]],
      Comment:["",[Validators.required]],
      
    })
  }
  transformAmount(event){
    let amount = this.setBilledForm.controls['Charges'].value;
    if (isNaN(amount) && !(amount.match(/^\$/)) || amount <= 0)
    {
      this.setBilledForm.controls['Charges'].setValue("");
      this.setBilledForm.controls['Charges'].setErrors({'incorrect': true});
      this.toastr.error("Charge Amount should be greater than zero.", 'Error!');
      return false;
    }else {
    this.claimValue = this.currencyPipe.transform(amount, 'USD');
    // Remove or comment this line if you dont want to show the formatted amount in the textbox.
    event.target.value = this.claimValue;
    }
  }
  checkbill(){
    this.checked = !this.checked;
    // if(this.checked === false){
    //   this.setBilledForm.disable();
    // }
  }
  selectionChanged(event) {
  
      for  (var i =  0; i <  event.target.files.length; i++)  {  
        let extension = event.target.files[i].name.split('.').pop();
        if(extension == "xlsx" || extension == "xlsm" || extension == "xlsb" || extension == "xltx" || extension == "csv"){
          this.validateFile = true;
          this.validateFileMsg = "File extension "+extension+ " is not allowed.";
          this.fileAttachName.pop();
         
          return;
        }else{
          this.validateFile = false;
          this.fileAttachName.push(event.target.files[i]);
        }
        //this.fileAttachName.push(event.target.files[i]);
      }
    
  }
  changeSelection(event){
    let index = event.target.id;
    if(event.target.checked){
      this.claims[index]['checked']="checked";
    }else if(!event.target.checked){
      this.claims[index]['checked']="";
    }
  }

  reasonChanged(event$){
    this.reasonForm.controls['reason'].setValue(event$.value);
  }

  confirmYesClick(){
    this.showConfirmation = false;
    this.showSecondConfirmation = true;
    // this.closeEmitter.emit();
  }
  confirmNoClick(){
    this.showConfirmation = false;
  }
  confirmSubmitClick(){
    this.billReasonSubmitted = true;
    if(!this.reasonForm.valid){
      this.toastr.error('please correct the errors');
      return;
    }
    const billReason: BillReason = this.reasonForm.controls['reason'].value;
    
    let data = {
      "BdosID": this._formData.dosId, 
      "facilityId": this._formData.facilityID,
      "ReasonId": billReason.id,
      "Comments": this.reasonForm.controls['Comment'].value
     }
    this.getApiService.post("BillingNew/UnSetBilled", data).subscribe(res => {
        if(res.status == "Success"){
          this.toastr.success('Undo set billed successfully completed', 'Success!');
          this.closeEmitter.emit();
          window.location.reload();
        }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
    this.closeEmitter.emit();
  }
  confirmCancelClick(){
    this.showSecondConfirmation = false;
  }
  undoSetBilled(){
    this.showConfirmation = true;
   // let data = {"BdosID": this._formData.dosId, "facilityId": this._formData.facilityID }
    // this.getApiService.post("BillingNew/UnSetBilled", data).subscribe(res => {
    //     if(res.status == "Success"){
    //       this.toastr.success('Data Unset successfully', 'Success!');
    //       this.closeEmitter.emit();
    //       window.location.reload()
    //     }
    // }, (error) => {
    //   this.toastr.error(error, 'Error!');
    // });
   
  }
  onSubmit(){
   
    const formdata = this.setBilledForm.getRawValue();
    let frmData = new FormData();
    
    if(!this.setBilledForm.valid){
      return;
    }
    if(this.setBilledForm.controls['PaperClaim'].value == 'paper'){
      if (this.fileAttachName != undefined && this.fileAttachName != []) {
      
        if(!this.setBilledForm.controls['PaperClaim'].value){
          return;
        }
      }
    }
     
      for  (var i =  0; i <  this.fileAttachName.length; i++)  {  
        frmData.append("FileAttachment["+i+"].file",  this.fileAttachName[i]);
        frmData.append("FileAttachment["+i+"].fileName",  this.fileAttachName[i].name);
      }
     
   
      let addcheckboxdata = [];
      this.claims.forEach(element => {
        if(element.checked){
          this.checkedCheckbox.push(element.checked)
          addcheckboxdata.push(element)
        }
      });
      let checkbox:any;
      for (var j = 0 ; j < addcheckboxdata.length; j++){
        if(addcheckboxdata[j].checked == "checked"){
         checkbox= addcheckboxdata[j].id;
         frmData.append("PaperClaimAttachment["+j+"].PaperClaimAttachmentId",checkbox );
        }
      }
    
      // for (var j = 0 ; j < this.checkedCheckbox.length; j++){
      //   this.checkedCheckbox[j] = 1;
      //   frmData.append("PaperClaimAttachment["+j+"].PaperClaimAttachmentId",this.checkedCheckbox[j] );
      // }
      let chargesAmt = (this.setBilledForm.controls["Charges"].value).replace(',', '').replace('$','');
      frmData.append("BdosID", this._formData.dosId);
      frmData.append("facilityId", this._formData.facilityID);
      frmData.append("SetBilledType", this.setBilledForm.controls["ClaimType"].value);
      frmData.append("claimAmount", chargesAmt);
      frmData.append("claim", this.setBilledForm.controls["ClaimConfirmation"].value);
      frmData.append("overridereason", this.setBilledForm.controls["OverrideReason"].value);
      this.getApiService.uploadFiles("BillingNew/SetBilled", frmData).subscribe((resp: any) => {
        if (resp.status == "Success") {
          this.toastr.success('Data saved successfully', 'Success!');
          this.closeEmitter.emit();
        }
        
          // this.addedEmitter.emit({
          //   'status': true,
          //   'message':'Data saved successfully',
          //   'data': resp
          // })
       // this.onClickClose();
          
     //   }
      
        

      }, (err) => {
        console.log(err);
      })
  }
  overRideClaim(){
    this.overrideClaim = true;
    this.noValue = false;
    this.showOverrideReason = false
    this.showUpload = false
    this.showClaimConfirmation = false
  }
  async getClaims() {
    if (this.searchValue != '' && this.pagenumber != 1) {
      this.pagenumber = 1;
      this.p = 1;
    }
    
    let  data = { "pageNum": this.pagenumber, "pageSize": this.pageValue };
    // let data = { "pageNum": 1, "pageSize": 100 };

     await this.getApiService.post("PaperClaim/BindGridData", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.claimResponse = resp.data;
        return this.claimResponse;

      }

    }, (err) => {
      console.log(err);
    })
  }

  async getBilled() {
  
    let data = { "BdosID": this._formData.dosId, "facilityId": this._formData.facilityID };

     await this.getApiService.post("BillingNew/GetSetBilled", data).subscribe(async (resp: any) => {
  
      if (resp.status == "Success") {
        if(resp.data.length == 0){
          this.showbutton = false;
        }
        this.showpaperclaim = true;
       // this.setBilled = resp.attachment;
        this.userName = resp.data[0].actionBy;
        console.log(this.userName,"username")
        this.claimType = resp.data[0].type;
        this.claimConfirmation = resp.data[0].claim;
    
        if(this.claimType == ' Electronic' || this.claimType == 'Electronic' || this.claimType == 'electronic'){
         
          this.claimType = 'Electronic'
          this.showClaimConfirmation = true;
         
          this.setBilledForm.get('ClaimConfirmation').setValue(resp.data[0].claim);
        }else if(this.claimType == 'Paper' || this.claimType == 'paper'){
          this.claimType = 'Paper'
          this.showUpload = true;
          this.paperclaim = resp.paperclaim;
          let newclaim:any;
          newclaim = await this.getApiService.post("PaperClaim/BindGridData", data).toPromise();

          let activePaperClaims : any;
          activePaperClaims =  await this.getApiService.post("PaperClaim/GetBillingPaperClaims", data).subscribe((resp: any) => {
            if (activePaperClaims.status == "Success") {
              this.paperclaim = activePaperClaims.data;
            }
          });
        

          newclaim.data.forEach(element => {
            if(element.isActive == 1){
            this.claims.push(element);
            }
          });
        
          for(let i =0;i < this.claims.length;i++ ){
            for(let j=0 ; j<this.paperclaim.length ;j++){
              if(parseInt(this.claims[i].id) == parseInt(this.paperclaim[j].paperclaimid) ){
                console.log('active console.')
                console.log(this.paperclaim[j].paperclaimid);
                console.log(this.claims[i].id);
                this.claims[i]['checked']="checked";
              }
            }
          }

        }else{
          this.noValue = true;
          this.claimType = 'Self'
        }
        this.setBilledForm.get('ClaimType').setValue(this.claimType);
        this.claimValue = this.currencyPipe.transform(resp.data[0].claimValue, 'USD');
        this.setBilledForm.get('Charges').setValue(this.claimValue);
       
      
      }

    }, (err) => {
      console.log(err);
    })
  }

   getAttachment() {
    console.log('working')
    let data = { "BdosID": this._formData.dosId, "facilityId": this._formData.facilityID };

      this.getApiService.post("BillingNew/GetSetBilledAttachment", data).subscribe(async (resp: any) => {
  
      if (resp.status == "Success") {
        
        this.setBilled = resp.data;
        console.log(this.setBilled,"setbilled")
       
      
      }

    }, (err) => {
      console.log(err);
    })
  }



  downloadFile(file){
    let data = {"FileId": file.id,"BdosID": this._formData.dosId, "facilityId": this._formData.facilityID }
    this.getApiService.post4File("BillingNew/DownloadBillingFile", data).subscribe(res => {
      fileSaver.saveAs(res, file.filename);
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
 
    
  }
  deleteFile(file){
    let data = { "id": file.id,"BdosID": this._formData.dosId, "facilityId": this._formData.facilityID};

    this.getApiService.post("BillingNew/DeleteBilledFiles", data).subscribe((resp: any) => {

      if (resp.status == "Success") {
        this.getApiService.post("BillingNew/GetSetBilledAttachment", data).subscribe(async (res: any) => {
        
          if (res.status == "Success") {
           // if(res.attachment.length !== 0){
              this.setBilled = res.data
           // }
          }
          });
        this.toastr.success(resp.message, 'Success!');
     
      }

    }, (err) => {
      console.log(err);
    })
  }
  selectClaimType(event){
  
    this.setBilledForm.controls["ClaimType"].setValidators( [Validators.required]);
    if(event.target.value == 'electronic'){
      this.setBilledForm.controls["ClaimConfirmation"].setValidators( [Validators.required]);
      
      this.showClaimConfirmation = true
      this.showUpload = false
     
     
    }else if(event.target.value == 'paper'){
      this.setBilledForm.controls["fileName"].setValidators( [Validators.required]);
      this.setBilledForm.controls["PaperClaim"].setValidators( [Validators.required]);
      this.showUpload = true
      this.showClaimConfirmation = false
   
    }else{
      this.noValue = true;
      this.showOverrideReason = false
      this.showUpload = false
      this.showClaimConfirmation = false
    }
   
  }
  getBillingRulesList() {
    
    let facility = this._formData.client;
    let data = { "pageNum": this.pagenumber, "pageSize": this.pageValue, "SearchValue": facility };
    
    this.getApiService.post("Rules/BindGridData", data).subscribe(res => {
      if (res.status == "Success") {

        this.billingRulesList = res.dataGridList;
        if(res.dataGridList.length !== 0){
        this.billingRuleDetails = true;
        }
        if(this.billingRulesList[0].paper == 1){
          
          this.showUpload = true;
       
        } if(this.billingRulesList[0].electronic == 1){
          // this.showOverrideReason = true
          this.showClaimConfirmation = true
          //this.setBilledForm.controls["checkbill"].setValidators( [Validators.required]);

        }
     
      }
      else if (res.status == "Error") {
        this.toastr.error(res.message, 'Error!');
      }
    }, (error) => {
      this.toastr.error(error, 'Error!');
    });
  }
  onClickClose() {
  
    this.closeEmitter.emit();
  }

  initialiseDropDownConfig(drpDownFor: string){
    return {
      displayKey: drpDownFor, //if objects array passed which key to be displayed defaults to description
      search: true, //true/false for the search functionlity defaults to false,
      height: 'auto', //height of the list so that if there are more no of items it can show a scroll defaults to auto. With auto height scroll will never appear
      placeholder: 'Select', // text to be displayed when no item is selected defaults to Select,
      customComparator: () => { }, // a custom function using which user wants to sort the items. default is undefined and Array.sort() will be used in that case,
      limitTo: Option.length, // a number thats limits the no of options displayed in the UI similar to angular's limitTo pipe
      moreText: 'more', // text to be displayed whenmore than one items are selected like Option 1 + 5 more
      noResultsFound: 'No results found!', // text to be displayed when no items are found while searching
      searchPlaceholder: 'Search', // label thats displayed in search input,
      searchOnKey: drpDownFor, // key on which search should be performed this will be selective search. if undefined this will be extensive search on all keys
      clearOnSelection: false, // clears search criteria when an option is selected if set to true, default is false
      inputDirection: 'ltr' // the direction of the search input can be rtl or ltr(default)
    }
  }
}
