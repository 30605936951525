<div class="card" style="margin-top: 5px;">
  <div class="card-header current-request-header">
    <div class="row">
      <div class="col-md-8">
        {{cardHeaderTitle}}
      </div>
      <div class="col-md-4 current-request-close-all-lbl" *ngIf="hasAnyCurrentCaseRequest$ | async">
        Remove All
        <i class="fas fa-times fa-sm cr-close" style="float: right;" (click)="removeAllCurrenRequest()">
        </i>
      </div>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div *ngFor="let cr of currentCaseRequest$ | async" style="padding-right: 5px;">
        <button type="button" class="btn btn-primary btn-sm btn-current-rquest" [disabled]="(cr.alreadySubmitted && !cr.isModified)">{{ cr.value }}
            <i class="fas fa-times fa-sm cr-close" *ngIf="((hasAnyCurrentCaseRequest$ | async) && !cr.alreadySubmitted) || cr.isModified" (click)="removeCurrenRequest(cr)"></i>
        </button>
      </div>
    </div>
    <div class="row row-confirm-current-request" *ngIf="hasAnyCurrentCaseRequestToSubmit$ | async">
      <div class="col-md-12">
        <button type="button" class="btn btn-primary btn-sm btn-patient-box btn-current-request" (click)="confirmRequest()">Submit</button>
      </div>
    </div>
  </div>
</div>