
<div *ngIf="smryIsToggled" class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
    <div class="row case-row">
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-4">
                    <p class="head-name-case">MRN </p>
                </div>
                <div class="col-md-8">
                    <p class="head-name-case">{{(caseRequestInfo$ | async)?.dataPatient.mrn}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 head-det-case">
                    <p class="head-det-case"><i class="fa fa-user mr-1"></i>
                        <span>{{(caseRequestInfo$ | async)?.dataPatient.patientName}}<span
                                *ngIf="(caseRequestInfo$ | async)?.dataPatient?.gender != null">({{(caseRequestInfo$ | async)?.dataPatient.gender}})</span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 head-det-case">
                    <p class="head-det-case">{{(caseRequestInfo$ | async)?.dataPatient.facilityName}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <p class="head-det-case">Primary CPT:</p>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="head-det-case" *ngIf="(caseRequestInfo$ | async)?.dataCPTDetails?.length > 0">{{(caseRequestInfo$ | async)?.dataCPTDetails[0].cptCode}}</p>
                        </div>
                    </div>
                    <div class="row" *ngIf = "hasImplantLog$ | async">
                        <div class="col-md-12">
                            <i class="fa fa-paperclip head-det-case">
                                <p><a href="javascript:void(0)"
                                    (click)="getImplantLogFile()" >Implant Log</a></p>
                            </i>
                        </div>
                    </div>
                    <div class="row" *ngIf = "hasInvoice$ | async">
                        <div class="col-md-12">
                            <i class="fa fa-paperclip head-det-case">
                                <p><a href="javascript:void(0)"
                                    (click)="getInvoiceFile()" >Invoice</a>
                                </p>
                                
                            </i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 head-det-case">
                    <p class="head-det-case">Related CPTs:</p>
                </div>
                <div class="col-md-6 head-det-case">
                    <p class="head-det-case">
                        <span *ngFor="let d of (caseRequestInfo$ | async)?.dataCPTDetails">
                            <span *ngIf="d.cptCode != (caseRequestInfo$ | async)?.dataCPTDetails[0].cptCode">
                                {{d.cptCode}},
                            </span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 head-det-case">
                    <p class="head-det-case"> <i class="fas fa-tools mr-1"></i> Tools and Templates</p>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <p class="head-name-case">Personal Information</p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Address</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.address1}}
                            {{(caseRequestInfo$ | async)?.dataPatient.address2}}
                        </span>
                    </p>
                </div>

            </div>

            <!-- <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'"> -->
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">City</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.city}}</span></p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">State</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.state}}
                            {{(caseRequestInfo$ | async)?.dataPatient.zip}}</span></p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Phone No</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.phonenumber}}</span></p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">DOB</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.dob | date: "MM/dd/yyyy"}}</span>
                    </p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">SSN No</span><span
                            *ngIf="PatientSummaryDtls?.dataPatient[0]?.ssnNo != null"
                            class="head-det-cse1">#{{(caseRequestInfo$ | async)?.dataPatient.ssnNo}}</span></p>
                </div>

            </div>

            <!-- </div> -->
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-10">
                    <p class="head-name-case">Insurance</p>
                </div>
                <div class="col-md-2 head-det-case" *ngIf="!isBulkAction()">
                    <a (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
                        <i class="fas fa-times fa-sm"></i>
                      </a>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2" style="margin-bottom: 20px;"><span
                            class="head-det-cse2">Primary</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.insuranceProvider}}</span>
                    </p>
                </div>

            </div>

            <!-- <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'"> -->
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Group #</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.groupNumber}}</span>
                    </p>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Insurance Id</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.insuranceId}}</span>
                    </p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Physician</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.physician}}</span>
                    </p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">DOS</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.dateOfSurgery}}</span>
                    </p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Billed</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.billed}}</span></p>
                </div>

            </div>
        </div>
    </div>
</div>
<div class="row cr-row-expand" *ngIf="smryIsToggled">
    <button type="button" class="btn btn-secondary btn-sm btn-round" (click)="showPatientDetail()">
        <i class="fas fa-chevron-up"></i>
    </button>
</div>
<!-- Showing only preview -->

<div *ngIf="!smryIsToggled" class="card-header d-flex flex-row align-items-center justify-content-between card-header-bckcolor card-header-pdng">
    <div class="row case-row">
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-4">
                    <p class="head-name-case">MRN </p>
                </div>
                <div class="col-md-8">
                    <p class="head-name-case">{{(caseRequestInfo$ | async)?.dataPatient.mrn}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 head-det-case">
                    <p class="head-det-case"><i class="fa fa-user mr-1"></i>
                        <span>{{(caseRequestInfo$ | async)?.dataPatient.patientName}}<span
                                *ngIf="(caseRequestInfo$ | async)?.dataPatient?.gender != null">({{(caseRequestInfo$ | async)?.dataPatient.gender}})</span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 head-det-case">
                    <p class="head-det-case">{{(caseRequestInfo$ | async)?.dataPatient.facilityName}}</p>
                </div>
            </div>
            <!-- </div> -->
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-12">
                    <p class="head-name-case">Personal Information</p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Address</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.address1}}
                            {{(caseRequestInfo$ | async)?.dataPatient.address2}}
                        </span>
                    </p>
                </div>

            </div>

            <!-- <div id="show-data" [style.display]="smryIsToggled ? 'block' : 'none'"> -->
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">City</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataPatient.city}}</span></p>
                </div>

            </div>
            
            <!-- </div> -->
        </div>
        <div class="col-md-4">
            <div class="row">
                <div class="col-md-10">
                    <p class="head-name-case">Insurance</p>
                </div>
                <div class="col-md-2 head-det-case" *ngIf="!isBulkAction()">
                    <a (click)="onClickClose()" class="closeFileSidenav" id="closeFileSidenav">
                        <i class="fas fa-times fa-sm"></i>
                      </a>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2" style="margin-bottom: 20px;"><span
                            class="head-det-cse2">Primary</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.insuranceProvider}}</span>
                    </p>
                </div>

            </div>
            <div class="row">
                <div class="col-md-12">
                    <p class="head-det-case2"><span class="head-det-cse2">Group #</span><span
                            class="head-det-cse1">{{(caseRequestInfo$ | async)?.dataDosDetails?.groupNumber}}</span>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="row cr-row-expand" *ngIf="!smryIsToggled">
    <button type="button" class="btn btn-secondary btn-sm btn-round" (click)="showPatientDetail()">
        <i class="fas fa-chevron-down"></i>
    </button>
</div>