import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { Patient } from 'src/app/shared/state/patient/patient.model';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { PatientService } from 'src/app/shared/state/patient/patient.service';

@Component({
  selector: 'app-critical-item-action',
  templateUrl: './critical-item-action.component.html',
  styleUrls: ['./critical-item-action.component.css']
})
export class CriticalItemActionComponent implements OnInit {
  @Output() closeEmitter = new EventEmitter();
  @Output() submitEmitter = new EventEmitter();
  activePatient: Patient;
  followupDate;
  minDate = new Date();
  comment: string;
  submitted: boolean = false;
  followUpHistories : any = [];
  constructor(
    private getApiService: ApiService,
    private toastr: ToastrService,
    private patientQuery: PatientQuery,
  ) { }

  ngOnInit(): void {
    this.activePatient = this.patientQuery.getActive().find(r => true);
    this.getAllFollowUpHistory();
  }

  getAllFollowUpHistory(){
    let data = {
      BdosID: this.activePatient.bdosid
    };
    this.getApiService.post("BillingNew/GetFollowUpHistory", data).subscribe(res => {
      if (res.status == "Success") {
        this.followUpHistories = res.data;
      }
    });
  }

  onClickClose() {
    this.closeEmitter.emit();
  }
  addRecord() {
    this.submitted = true;
    const datepipe = new DatePipe('en-US');
    const fomattedDate = datepipe.transform(this.followupDate, 'MM/dd/yyyy');
    // const myDate = new Date();
    let today = Date.now()
    let formattedtoday = datepipe.transform(today, 'MM/dd/yyyy');
    console.log(formattedtoday,"today",fomattedDate,"fomattedDate")
    
      if(fomattedDate < formattedtoday){
        this.toastr.error("Please enter current or future date.", 'Error!');
        this.followupDate = "";
        return;
      }
   
    if (!fomattedDate || !this.comment) {
      return;
    }


    let data = {
      BdosID: this.activePatient.bdosid,
      FollowupDate: fomattedDate,
      Comments: this.comment ? this.comment : ""
    };

    this.getApiService.post("BillingNew/SaveFollowup", data).subscribe(res => {
      if (res.status !== 'Success') {
        this.toastr.error('Failed to save data', res.message);
        this.onClickClose();
        return;
      }

      this.toastr.success("Success!!", "Data saved!!");
      this.submitEmitter.emit();
    });
  }
  

  
}
