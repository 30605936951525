import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { ImportPatientsModule } from './import-patients/import-patients.module';
import { FeeScheduleModule } from './fee-schedule/fee-schedule.module';
import { BillingRulesModule } from './billing-rules/billing-rules.module';
import { EodMaintenanceModule } from './eod-maintenance/eod-maintenance.module';
import { ManageUsersModule } from './manage-users/manage-users.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { ImportpayersComponent } from '../admin/importpayers/importpayers.component';
//import { ImportPayerFormComponent } from './import-payer-form/import-payer-form.component';

@NgModule({
  declarations: [
   // ImportpayersComponent, ImportPayerFormComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule,
    ImportPatientsModule,
    FeeScheduleModule,
    BillingRulesModule,
    EodMaintenanceModule,
    ManageUsersModule,
    NgbModule
  ]
})
export class AdminModule { }
