import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { clientRole } from 'src/app/shared/models/name-constant';
import { CaseRequestHelper } from '../../helper/case-request.helper';
import { FileHistoryInfo, QueueInfo } from './case-request-info.model';
import { CaseRequestInfoStore, CaseRequestInfoState } from './case-request-info.store';

@Injectable({ providedIn: 'root' })
export class CaseRequestInfoQuery extends QueryEntity<CaseRequestInfoState> {
  caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  constructor(protected store: CaseRequestInfoStore) {
    super(store);
  }

  getCaseRequestHistoryWhenClientNoteOrBillerNoteIsPresent(): Observable<FileHistoryInfo[]>{
    return this.selectFirst().pipe(map(res => res && res.dataFileHistoryInfo?.filter(d => d.billerNote != '' || d.clientNote != '' || d.coderNote != '')))
  }

  getCaseRequestHistoryWhenContentFileNameIsPresent(): Observable<FileHistoryInfo[]>{
    return this.selectFirst().pipe(map(res => res && res.dataFileHistoryInfo?.filter(d => d.contentFileName != null)))
  }

  hasImplantLog(): Observable<boolean>{
    return this.selectFirst().pipe(map( res => this.caseRequestHelper.hasImplantLog(res?.dataPricingInfo)));
  }

  hasInvoice(): Observable<boolean> {
    return this.selectFirst().pipe(map( res => this.caseRequestHelper.hasInvoice(res?.dataPricingInfo)));
  }

  getPrimaryCpt(): string{
    return this.getAll().find(cr => true).dataCPTDetails[0].cptCode;
  }

  selectQueueInfo(): Observable<QueueInfo>{
    return this.selectAll().pipe(map(res => {
      if(res && res[0]){
        return res[0].dataQueueInfo
      }
    }));
  }

  getQueueInfo(): QueueInfo{
    return this.getAll().find(e => true)?.dataQueueInfo;
  }

  isClientRole(): boolean{
    return this.getAll().find(e => true).userRole === clientRole.value;
  }

  hasClientRole(): Observable<boolean> {
    return this.selectFirst().pipe(map(res => res && (res.userRole === clientRole.value)));
  }

  getBdosid(){
    return this.getAll().find(e => true)?.id;
  }

  getPrimaryInsurance(){
    return this.getAll().find(e => true)?.dataDosDetails.insuranceProvider;
  }

  selectBdosid(): Observable<string | number>{
    return this.selectFirst().pipe(map(res => res?.id));
  }
}
