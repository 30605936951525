import { Injectable } from '@angular/core';
import { ID, transaction } from '@datorama/akita';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { clientRole, highApproval, highApprovalInternal, na, NameConstant, nsnCpt, physicianCpt, variance } from 'src/app/shared/models/name-constant';
import { ApiService } from 'src/app/shared/services/api.service';
import { PatientQuery } from 'src/app/shared/state/patient/patient.query';
import { CaseRequestService } from '../case-request/case-request.service';
import { CurrentCaseRequestService } from '../current-case-request/current-case-request.service';
import { CaseRequestCpt, CaseRequestVarCpt, createCaseRequestCpt, createCaseRequestVarCpt } from './case-request-cpt.model';
import { CaseRequestCptQuery } from './case-request-cpt.query';
import { CaseRequestCptStore } from './case-request-cpt.store';

@Injectable({ providedIn: 'root' })
export class CaseRequestCptService {

  constructor(
    private caseRequestCptStore: CaseRequestCptStore, 
    private caseRequestCptQuery: CaseRequestCptQuery,
    private apiService: ApiService, 
    private patienrQuery: PatientQuery, 
    private toast: ToastrService,
    private caseRequestService: CaseRequestService
    ) {
  }

  add(caseRequestCpt: CaseRequestCpt) {
    this.caseRequestCptStore.add(caseRequestCpt);
  }

  reset(){
    this.caseRequestCptStore.set([]);
  }

  update(id, caseRequestCpt: Partial<CaseRequestCpt>) {
    this.caseRequestCptStore.update(id, caseRequestCpt);
  }

  remove(id: ID) {
    this.caseRequestCptStore.remove(id);
  }

  removeAll(ids: ID[]){
    this.caseRequestCptStore.remove(ids);
  }

  @transaction()
  removeAllByRequestId(id: string | number){
    const ids = this.caseRequestCptQuery.findCptByRequestId(id.toString()).map(cr => cr.id);
    this.removeAll(ids);
  }

  @transaction()
  removeAllByRequestIdAndPatientId(id: string | number, bdosid: string | number){
    const ids = this.caseRequestCptQuery.getCptByRequestAndPatientId(id, bdosid).map(cr => cr.id);
    this.removeAll(ids);
  }

  removeAllByComponentId(id: string | number){
    const ids = this.caseRequestCptQuery.findCptByComponentId(id.toString()).map(cr => cr.id);
    this.removeAll(ids);
  }

  @transaction()
  removeCptAtUIIndex(index: number, requestId: string | number):  void{
    const entityIds = this.caseRequestCptQuery.getAll({
      filterBy: entity => entity.uiIndex === index && entity.requestId === requestId
    }).map(e => e.id);
    this.removeAll(entityIds);
  }

  fetchAndLoadHigh$$$CptDetails(role: string, bdosid: string | number, isBulkAction: boolean){
    this.caseRequestCptStore.setLoading(true);
    console.log('fetchAndLoadHigh', role );
    let data = { BdosID: bdosid };
    this.apiService.post("BillingNew/GetHighDollarTabData", data).subscribe(res => {
      if(res.status === "Success"){
        let crCpts: CaseRequestCpt[] = [];
        if(!res.dataHighTabStaus || !res.dataHighTabStaus[0]){
          //this.toast.error("data high tab status cannot be undefined");
          return;
        }
         const isNSNHigh$$$ = res.dataHighTabStaus[0].wasHighApprovalInternal;
        if(isNSNHigh$$$ === undefined || isNSNHigh$$$ === null){
          this.toast.error("unable to determine the request type from high $$$ api");
          return;
        }
        const id = isNSNHigh$$$ === 0 ? highApproval.id : highApprovalInternal.id;
        console.log(id,"ID")
        for (let index = 0; index < res.dataHighTab.length; index++) {
          const element = res.dataHighTab[index];
          console.log(element,"element")
          const crCpt = this.builCptDetails(element, isBulkAction, id, index, bdosid);
          console.log(crCpt,"CPT listing in loop")
          crCpts = [...crCpts, crCpt]; 
        }
         
        this.caseRequestCptStore.add(crCpts);
        console.log(this.caseRequestCptStore,"after")
        this.caseRequestCptStore.setLoading(false);
        if(res.dataHighTabStaus && res.dataHighTabStaus[0]){
          this.caseRequestService.update(id, { isHigh$$$orVarRequestStatus: res.dataHighTabStaus[0].requestStatus, alreadySubmitted: role !== clientRole.value });
        }
      }else{
        this.toast.error("Unexpected Error", res.message);
        this.caseRequestCptStore.setLoading(false);
      }
    }, err => {
      this.toast.error("Error!!", err?.message);
      this.caseRequestCptStore.setLoading(false);
    })
  }

  private builCptDetails(element, isBulkAction: boolean, id: string, index: number, bdosid: string | number): CaseRequestCpt{
    
    if(isBulkAction){
      return createCaseRequestCpt({
        id: id+"_"+element.cpt+"_"+bdosid+"_"+index,
        requestId: id,
        cptCode: element.cpt,
        cptDescription: element.cptDesc,
        rate: element.cost,
        codingType: na.id,
        notes: element.notes,
        uiIndex: index,
        approved: (element.approved === true || element.approved === 1) ? true : false,
        alreadySubmitted: true,
        bdosid: bdosid
      });
    }else{
      return createCaseRequestCpt({
        id: id+"_"+element.cpt+"_"+index,
        requestId: id,
        cptCode: element.cpt,
        cptDescription: element.cptDesc,
        rate: element.cost,
        codingType: na.id,
        notes: element.notes,
        uiIndex: index,
        approved: (element.approved === true || element.approved === 1) ? true : false,
        alreadySubmitted: true
      });
    }
  }

  selectAllVariance(): Observable<CaseRequestVarCpt[]>{
    
    let payload = { BdosID: this.patienrQuery.getAll().find(e => true).bdosid };
    return this.apiService.post("BillingNew/GetVarianceTabData", payload).pipe(map(res => {
      if(res.status === "Success"){
        return res.dataVarianceTab.map(e => createCaseRequestVarCpt({
          cptCode: e.cpt, 
          cptDescription: e.cptDesc,
          cptAltCode: e.cptAlt,
          cptAltDescription: e.cptAltDesc
        }));
      }else{
        this.toast.error("Unexpected Error", res.message);
        return of();
      }
    }));
  }

  selectAllVarianceForPatient(bdosid: string | number): Observable<CaseRequestVarCpt[]>{
    
    let payload = { BdosID: bdosid };
    return this.apiService.post("BillingNew/GetVarianceTabData", payload).pipe(map(res => {
      if(res.status === "Success"){
        return res.dataVarianceTab.map(e => createCaseRequestVarCpt({
          cptCode: e.cpt, 
          cptDescription: e.cptDesc,
          cptAltCode: e.cptAlt,
          cptAltDescription: e.cptAltDesc
        }));
      }else{
        this.toast.error("Unexpected Error", res.message);
        return of();
      }
    }));
  }


  fetchAndLoadVarianceCptDetails(role: string, bdosid: string | number, isBulkAction: boolean){
    const request = variance;
    this.caseRequestCptStore.setLoading(true);
    let payload = { BdosID: this.patienrQuery.getAll().find(e => true).bdosid };
    this.apiService.post("BillingNew/GetVarianceTabData", payload).subscribe(res => {
      if(res.status === "Success"){
      
        for (let index = 0; index < res.dataVarianceTab.length; index++) {
          let data = res.dataVarianceTab[index];
          const nsnRequestCpt = this.buildCptDetailsForVariance(nsnCpt.id, isBulkAction, bdosid, data, index );
          const physicianRequestCpt = this.buildCptDetailsForVariance(physicianCpt.id, isBulkAction, bdosid, data, index );
          
          this.caseRequestCptStore.add([nsnRequestCpt, physicianRequestCpt]);
          this.caseRequestCptStore.setLoading(false);
        }
        if(res.dataVarianceTabStaus && res.dataVarianceTabStaus[0] && res.dataVarianceTabStaus[0].requestStatus){
          this.caseRequestService.update(request.id, { isHigh$$$orVarRequestStatus: res.dataVarianceTabStaus[0].requestStatus, alreadySubmitted: role !== clientRole.value });
        }
      }else{
        this.toast.error("Unexpected Error", res.message);
        this.caseRequestCptStore.setLoading(false);
      }
    }, err => {
      this.toast.error("Error!!", err?.message);
      this.caseRequestCptStore.setLoading(false);
    })
  }

  private buildCptDetailsForVariance(codingType: string, isBulkAction: boolean, bdosid: string | number, data: any, index: number): CaseRequestCpt{
    const cptCode = codingType === nsnCpt.id ? data.cpt : data.cptAlt;
    const cptDescription = codingType === nsnCpt.id ? data.cptDesc : data.cptAltDesc;
    const request = variance;
    
    if(isBulkAction){
      return createCaseRequestCpt({
        id: request.id+"_"+cptCode+"_"+codingType+"_"+bdosid,
        requestId: request.id,
        cptCode: cptCode,
        cptDescription: cptDescription,
        codingType: codingType,
        notes: data.notes,
        uiIndex: index,
        approved: (data.approved === true || data.approved === 1) ? true : false,
        alreadySubmitted: true,
        bdosid: bdosid
      });
    }else{
      return createCaseRequestCpt({
        id: request.id+"_"+cptCode+"_"+codingType,
        requestId: request.id,
        cptCode: cptCode,
        cptDescription: cptDescription,
        codingType: codingType,
        notes: data.notes,
        uiIndex: index,
        approved: (data.approved === true || data.approved === 1) ? true : false,
        alreadySubmitted: true
      });
    }
  }
}
