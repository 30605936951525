import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CaseRequestHelper } from '../../helper/case-request.helper';
import { CaseRequest } from './case-request.model';
import { CaseRequestStore, CaseRequestState } from './case-request.store';

@Injectable({ providedIn: 'root' })
export class 
CaseRequestQuery extends QueryEntity<CaseRequestState> {
  private caseRequestHelper: CaseRequestHelper = new CaseRequestHelper();
  constructor(protected store: CaseRequestStore) {
    super(store);
  }

  selectClientCaseRequest(): Observable<CaseRequest[]>{
    return this.selectAll().pipe(map(caseRequestList => caseRequestList.filter(cr => this.caseRequestHelper.isClientCaseRequest(cr.id))));
  }

  selectNSNCaseRequest(): Observable<CaseRequest[]>{
    return this.selectAll().pipe(map(caseRequestList => caseRequestList.filter(cr => this.caseRequestHelper.isNSNCaseRequest(cr.id))));
  }

  selectCptCaseRequestStatus(id: string | number): Observable<String>{
    return this.selectEntity(id).pipe(map(cr => cr?.isHigh$$$orVarRequestStatus));
  }
}

