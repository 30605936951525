import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PatientsComponent } from './patients.component';
import { AssignCaseComponent } from './assign-cases/assign-case/assign-case.component';
import { PatientBillingComponent } from './patient-billing/patient-billing.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: '/manage-patient',
        pathMatch: 'full'
      },
      {
        path: 'manage-patient',
        component: PatientsComponent,
      },
      {
        path: 'assign-case',
        component: AssignCaseComponent,
      },
      {
        path: 'biller',
        component: PatientBillingComponent
      }
    ]
  }]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
